// @ts-nocheck
import { Component } from "react";
import { inject } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";

import { GridItem } from "../../components/Grid";
import { SimpleCard } from "../../components/Card";
import PriceContainer from "../../components/Price/PriceContainer";
import Badge from "../../components/Badge/Badge";

// Translate
import Translate from "../../components/I18n/Translate";

// Utils
import { textNewLineToBr } from "../../utils/StringUtils";

// Style
const styles = {
  comboSuggestionsItemContainer: {
    margin: 0,
    marginTop: 16,
    padding: 8,
    cursor: "pointer",
  },
  comboSuggestionsTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  comboSuggestionsDescription: {
    textAlign: "center",
    marginBottom: 10,
  },
};

@inject("ordersStore")
class ComboSuggestionItem extends Component {
  addToOrder = () => {
    this.props.ordersStore.addProductToSelectedOrder(this.props.combo);
  };
  render() {
    const { classes, combo } = this.props;
    return (
      <GridItem xs={12} sm={6} md={4} onClick={this.addToOrder}>
        <SimpleCard
          classNames={{
            card: classes.comboSuggestionsItemContainer,
          }}
        >
          <p className={classes.comboSuggestionsTitle}>{combo.name}</p>
          <p className={classes.comboSuggestionsDescription}>
            {textNewLineToBr(combo.description)}
          </p>
          <PriceContainer
            price={combo.price}
            offerPrice={combo.offerPrice}
            actualPriceColor="#333"
            containerStyle={{
              fontSize: "1.2em",
              textAlign: "center",
            }}
          />
          {combo.daysToExpire > 0 && (
            <Badge
              color="info"
              style={{
                marginTop: 6,
                display: "block",
              }}
            >
              <Translate
                id="Product@daysToExpireMessage"
                variables={{ daysToExpire: `${combo.daysToExpire}` }}
              >
                válido por $daysToExpire dias
              </Translate>
            </Badge>
          )}
        </SimpleCard>
      </GridItem>
    );
  }
}

export default withStyles(styles)(ComboSuggestionItem);
