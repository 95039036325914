// @ts-nocheck

import numeral from "numeral";
import PropTypes from "prop-types";

// Configure numeral library Locale to Portuguese Brazil
numeral.register("locale", "pt-br", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "mil",
    million: "milhões",
    billion: "b",
    trillion: "t",
  },
  ordinal: function(number) {
    return "º";
  },
  currency: {
    symbol: "R$",
  },
});
numeral.locale("pt-br");

export const formatPriceToText = (price, showSymbol) => {
  const format = showSymbol ? "$ 0,0.00" : "0,0.00";
  return numeral(price).format(format);
};

const Price = ({ className, style, children, showSymbol = true }) => (
  <span className={className} style={style}>
    {formatPriceToText(children, showSymbol)}
  </span>
);

Price.propTypes = {
  className: PropTypes.string,
  children: PropTypes.number.isRequired,
  showSymbol: PropTypes.bool,
};

export default Price;
