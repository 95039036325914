// @ts-nocheck
import { Fragment } from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import withPageAllowedRules from "./withPageAllowedRules";

import ListGroupsContainer from "./groupsPage/ListGroupsContainer";

const GroupsPage = () => (
  <Fragment>
    <PageTitle id="Menu@groups">Grupos</PageTitle>
    <ListGroupsContainer />
  </Fragment>
);

export default withPageAllowedRules({
  component: GroupsPage,
  pageAllowedRule: "groups",
});
