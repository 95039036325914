import ConfirmModal from "./ConfirmModal";
import { FC } from "react";

import InfoLabel from "../Label/InfoLabel";

// Translate
import {
  ConfirmActionString,
  DeleteString,
  CancelString,
  DeleteHintString,
} from "../I18n/CommonStrings";

interface IConfirmDeleteModalProps {
  deleteLabel?: string | JSX.Element;
  message?: string | JSX.Element;
  didDelete: () => void;
  didCancel: () => void;
}

const ConfirmDeleteModal: FC<IConfirmDeleteModalProps> = ({
  deleteLabel,
  message,
  didDelete,
  didCancel,
}) => (
  <ConfirmModal
    isOpen
    title={<ConfirmActionString />}
    didConfirm={didDelete}
    didCancel={didCancel}
    confirmLabel={deleteLabel || <DeleteString />}
    cancelLabel={<CancelString />}
    cancelButtonColor="transparent"
    confirmButtonColor="danger"
    confirmLeft
  >
    <InfoLabel style={{ margin: "16px 0" }}>
      {message ? message : <DeleteHintString />}
    </InfoLabel>
  </ConfirmModal>
);

export default ConfirmDeleteModal;
