// @ts-nocheck


import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";
import { Button } from "../../components/Button";
import { GridContainer, GridItem } from "../../components/Grid";

import { labelForDataRange } from "../../components/Charts/DateRange";

// Styles
const styles = {
  savedChartStats: {
    fontWeight: 300,
    marginBottom: 0,
  },
};

const CameraSavedChartView = ({
  classes,
  localizedString,
  localizedPlural,
  titleForChartType,
  selectedGroupsList,
  dateRange,
  label,
  isEditing,
  didWantToEdit,
  didWantToDelete,
}) => {
  const isMultipleGroups = Array.isArray(selectedGroupsList);
  return (
    <GridContainer style={{ cursor: isEditing ? "initial" : "pointer" }}>
      <GridItem xs={12}>
        <p style={{ fontWeight: "bold" }}>{label}</p>
        <p className={classes.savedChartStats}>
          {localizedString({
            id: "Metrics@selectChartStepTitle",
            defaultString: "Relatório",
          })}
          : {titleForChartType}
        </p>
        <p className={classes.savedChartStats}>
          {localizedString({
            id: "Metrics@selectGroupStepTitle",
            defaultString: "Grupo",
          })}
          :{" "}
          {isMultipleGroups
            ? localizedPlural({
                id: "Metrics@savedChartGroupsCount",
                value: selectedGroupsList.length,
                zero: "nenhum grupo selecionado",
                one: "# grupo selecionado",
                other: "# grupos selecionados",
              })
            : selectedGroupsList.label}
        </p>
        <p className={classes.savedChartStats}>
          {localizedString({
            id: "Metrics@dateRangeStepTitle",
            defaultString: "Período",
          })}
          : {labelForDataRange(dateRange, localizedString)}
        </p>
      </GridItem>
      {isEditing && (
        <GridItem xs={12} sm={6}>
          <Button color="danger" small justIcon onClick={didWantToDelete}>
            <i className="fas fa-trash-alt" />
          </Button>
          <Button
            color="primary"
            small
            justIcon
            style={{ marginLeft: 8 }}
            onClick={didWantToEdit}
          >
            <i className="fas fa-pen" />
          </Button>
        </GridItem>
      )}
    </GridContainer>
  );
};

export default withLocalized(withStyles(styles)(CameraSavedChartView));
