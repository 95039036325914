// @ts-nocheck

import classnames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

const style = {
  grid: {
    margin: "0 -15px",
    width: "calc(100% + 30px)",
    // '&:before,&:after':{
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after':{
    //   clear: 'both',
    // }
  },
};

function GridContainer({ ...props }) {
  const { classes, children, className, justify, ...rest } = props;
  const gridClassName = classnames({
    [classes.grid]: true,
    [className]: className,
  });
  return (
    <Grid container justifyContent={justify}  {...rest} className={gridClassName}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
