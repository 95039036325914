// @ts-nocheck

import { action, autorun, computed, observable } from "mobx";
import moment from "moment";

import { savedMetricsReportsRef } from "../FirebaseStore/FirebaseStore";

import DateRange, {
  didChangeDateRange,
} from "../../components/Charts/DateRange";

import Translate from "../../components/I18n/Translate";

import { EInternalEvents } from "../../store/InternalEventsStore/InternalEventsStore";

// Utils
import { objectListToCsv } from "../../utils/ArrayUtils";
import {
  endOfCurrentWeekMoment,
  getNowMoment,
  startOfCurrentWeekMoment,
} from "../../utils/DateUtils";
import { defaultAdditionalDataJson } from "./utils";

export const WifiChartType = Object.freeze({
  accessByGroup: "access-by-group",
  visitors: "visitors",
  hourlyTraffic: "hourly-traffic",
});

export const CameraChartType = Object.freeze({
  accessByGroup: "access-by-group",
  hourlyTraffic: "hourly-traffic",
  visitors: "visitors",
});

export const ModalType = Object.freeze({
  addSavedChart: "addSavedChart",
  editSavedChart: "editSavedChart",
  manageSavedCharts: "manageSavedCharts",
  chooseNewChartOption: "chooseNewChartOption",
  showAdditionalChartData: "showAdditionalChartData",
});

export const MetricsStoreType = Object.freeze({
  camera: "camera",
  wifi: "wifi",
});

class MetricsReportStore {
  storeType;

  @observable mainChartView = null;
  @observable compareChartView = null;

  @observable userSavedCharts = [];

  @observable
  additionalChart = null;

  @observable
  modal = {
    isOpen: false,
    type: null,
  };

  @observable
  selectedSavedChartToEdit = null;

  constructor(
    storeType,
    authStore,
    contractStore,
    i18nStore,
    groupsStore,
    internalEventsStore,
    notificationsStore,
    modboxAPIService
  ) {
    this.storeType = storeType;
    this.authStore = authStore;
    this.contractStore = contractStore;
    this.i18nStore = i18nStore;
    this.groupsStore = groupsStore;
    this.notificationsStore = notificationsStore;
    this.modboxAPIService = modboxAPIService;

    this.setInitialValues();
    this.observeChart("mainChartView");
    this.observeChart("compareChartView");

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });

    autorun(() => {
      if (this.savedChartsRef) {
        this.getUserSavedCharts();
      }
      this.setUserSavedCharts([]);
    });
  }

  isWifiType = () => this.storeType === MetricsStoreType.wifi;
  isCameraType = () => this.storeType === MetricsStoreType.camera;

  @action
  setInitialValues = () => {
    this.mainChartView = this.defaultChart();
    this.additionalChart = this.defaultChart();
  };

  @action
  clearStore = () => {
    this.resetChartView("mainChartView");
    this.resetChartView("additionalChart");
    this.compareChartView = null;
    this.modal = {
      isOpen: false,
      type: null,
      title: null,
    };
    this.selectedSavedChartToEdit = null;
  };

  @action
  updateChartView = (chartViewId, newData) => {
    this[chartViewId] = {
      ...this[chartViewId],
      chartData: null,
      ...newData,
    };
  };

  @action
  setUserSavedCharts = (newValue) => {
    this.userSavedCharts = newValue;
  };

  updateContractGroupsList = async () => {
    await this.groupsStore.getGroupsList();
    if (this.filteredGroupsList.length === 1) {
      this.updateChartView("mainChartView", {
        selectedGroupsList: this.getSingleGroupForChartType(
          this.mainChartView.type
        ),
      });
    }
  };

  @computed
  get getGroupIfUnique() {
    if (this.filteredGroupsList.length === 1) {
      return this.filteredGroupsList[0];
    }
    return null;
  }

  getSingleGroupForChartType = (type) => {
    const group = this.getGroupIfUnique;
    if (!group) return null;
    const groupObject = {
      label: group.name,
      value: group.token,
    };

    return this.isSingleGroupForChartType(type) ? groupObject : [groupObject];
  };

  @computed
  get filteredGroupsList() {
    return this.groupsStore.groupsList.filter(
      ($0) => this.isCameraType() || $0.hasActiveNetwork
    );
  }

  @computed
  get savedChartsRef() {
    if (this.authStore.isAuthenticated && this.contractStore.selectedContract) {
      return savedMetricsReportsRef.child(
        `${this.authStore.currentUser.token}/${this.contractStore.selectedContract.hash}/${this.storeType}`
      );
    }
    return null;
  }

  observeChart = (chartViewId) => {
    autorun(
      () => {
        if (this[chartViewId] !== null) {
          const {
            fromDate,
            toDate,
            fromHour,
            toHour,
            weekDays,
            selectedGroupsList,
            type: chartType,
            targetAudience,
            macaddressRule,
            connectionTimeLimit,
            connectionType,
          } = this[chartViewId];
          if (fromDate && toDate && selectedGroupsList) {
            this.getChartDataForView(
              chartViewId,
              fromDate,
              toDate,
              fromHour,
              toHour,
              weekDays,
              selectedGroupsList,
              chartType,
              targetAudience,
              macaddressRule,
              connectionTimeLimit,
              connectionType
            );
          } else {
            action(() => {
              this[chartViewId].chartData = {};
            });
          }
        }
      },
      { name: `${chartViewId} -> getChartDataForView`, delay: 1000 }
    );
  };

  defaultChart = () => ({
    wizardCurrentStep: 0,
    chartData: null,
    isFetching: false,
    type: this.isWifiType()
      ? WifiChartType.accessByGroup
      : CameraChartType.accessByGroup,
    dateRange: DateRange.currentWeek,
    fromDate: startOfCurrentWeekMoment(),
    toDate: endOfCurrentWeekMoment(),
    fromHour: null,
    toHour: null,
    weekDays: [],
    selectedGroupsList: this.getSingleGroupForChartType(
      this.isWifiType()
        ? WifiChartType.accessByGroup
        : CameraChartType.accessByGroup
    ),
    targetAudience: "all",
    macaddressRule: "all",
    connectionTimeLimit: null,
    connectionType: null,
    error: null,
  });

  @action
  didWantToStartNewChart = () => {
    this.modal = {
      isOpen: true,
      type: ModalType.chooseNewChartOption,
      title: (
        <Translate id="Metrics@chooseNewChartOptionTitle">
          Escolha uma opção
        </Translate>
      ),
    };
  };

  @action
  didShowAdditionalChart = () => {
    this.modal = {
      isOpen: true,
      type: ModalType.showAdditionalChartData,
      title: null,
    };
  };

  @action
  didWantToManageSavedCharts = () => {
    this.modal = {
      isOpen: true,
      type: ModalType.manageSavedCharts,
      title: (
        <Translate id="Metrics@manageSavedChartsTitle">
          Gerenciar relatórios favoritos
        </Translate>
      ),
    };
  };

  @action
  didCloseModal = () => {
    this.modal.isOpen = false;
    setTimeout(
      action(() => {
        this.modal.type = null;
      }),
      300
    );
  };

  @action
  onChangeSelectedChartName = (newValue) => {
    if (this.selectedSavedChartToEdit) {
      this.selectedSavedChartToEdit.label = newValue;
    }
  };

  didChangeChartWizardCurrentStep = (chartViewId) =>
    action((newStep) => {
      this[chartViewId].wizardCurrentStep = newStep;
    });

  isSingleGroupForChartType = (type) => {
    if (this.isWifiType()) {
      switch (type) {
        case WifiChartType.accessByGroup:
          return false;
        case WifiChartType.visitors:
        case WifiChartType.hourlyTraffic:
        default:
          return true;
      }
    } else if (this.isCameraType()) {
      switch (type) {
        case CameraChartType.accessByGroup:
          return false;
        case CameraChartType.visitors:
        case CameraChartType.hourlyTraffic:
        default:
          return true;
      }
    }
    return false;
  };

  @action
  resetChartView = (chartViewId) => {
    this[chartViewId] = {
      ...this.defaultChart(),
    };
  };

  @action
  addCompareChart = () => {
    this.compareChartView = this.defaultChart();
  };

  @action
  didWantToSaveNewChart = (chartViewId) => {
    const {
      type,
      dateRange,
      selectedGroupsList,
      targetAudience,
      macaddressRule,
      connectionTimeLimit,
      connectionType,
    } = this[chartViewId];

    this.modal = {
      isOpen: true,
      type: ModalType.addSavedChart,
      title: (
        <Translate id="Metrics@saveNewChartModelTitle">
          Salvar relatório como favorito
        </Translate>
      ),
    };

    this.selectedSavedChartToEdit = {
      label: "",
      type,
      dateRange,
      selectedGroupsList,
      targetAudience,
      macaddressRule,
      connectionTimeLimit,
      connectionType,
    };
  };

  @action
  saveChartView = async () => {
    if (!this.selectedSavedChartToEdit) return null;

    if (this.selectedSavedChartToEdit.label.length < 4) {
      this.notificationsStore.addSnackbarNotification({
        message: (
          <Translate id="Metrics@invalidChartNameMessage">
            O nome precisa ter pelo menos 4 caracteres.
          </Translate>
        ),
        timeout: 4000,
        color: "danger",
      });
    } else {
      try {
        if (this.selectedSavedChartToEdit.id) {
          await this.savedChartsRef
            .child(this.selectedSavedChartToEdit.id)
            .update({
              ...this.selectedSavedChartToEdit,
              id: null,
            });
        } else {
          await this.savedChartsRef.push(this.selectedSavedChartToEdit);
        }
        this.notificationsStore.addSnackbarNotification({
          message: (
            <Translate id="Metrics@savedNewChartModelSuccessMessage">
              Salvo com sucesso!
            </Translate>
          ),
          timeout: 4000,
          color: "success",
        });
      } catch (error) {
        window.debugError("error in saveChartView", error);
      }
      this.didCloseModal();
      this.selectedSavedChartToEdit = null;
    }
  };

  didWantToEditSavedChart = (savedChart) => {
    this.selectedSavedChartToEdit = savedChart;
    this.modal = {
      isOpen: true,
      type: ModalType.editSavedChart,
      title: (
        <Translate id="Metrics@editSavedChartTitle">
          Editar relatório favorito
        </Translate>
      ),
    };
  };

  didWantToDeleteSavedChart = (savedChartId) => {
    this.savedChartsRef.child(savedChartId).remove();
  };

  getUserSavedCharts() {
    this.savedChartsRef.orderByChild("label").on("value", (snapshot) => {
      let list = [];
      if (snapshot.exists()) {
        snapshot.forEach((savedChartSnapshot) => {
          list.push({
            id: savedChartSnapshot.key,
            ...savedChartSnapshot.val(),
          });
        });
        this.setUserSavedCharts([...list]);
      } else {
        this.setUserSavedCharts([]);
      }
    });
  }

  titleForChartType = (type) => {
    if (this.isWifiType()) {
      switch (type) {
        case WifiChartType.accessByGroup:
          return (
            <Translate id="Connectivity@accessByGroupTitle">
              Acessos por grupos
            </Translate>
          );
        case WifiChartType.visitors:
          return (
            <Translate id="Connectivity@visitorsChartTitle">
              Perfil dos visitantes
            </Translate>
          );
        case WifiChartType.hourlyTraffic:
        default:
          return (
            <Translate id="Connectivity@hourlyTrafficChartTitle">
              Horário de pico
            </Translate>
          );
      }
    } else if (this.isCameraType()) {
      switch (type) {
        case CameraChartType.accessByGroup:
          return (
            <Translate id="Camera@accessByGroupTitle">
              Acessos por grupos
            </Translate>
          );
        case CameraChartType.visitors:
          return (
            <Translate id="Camera@visitorsChartTitle">
              Perfil dos visitantes
            </Translate>
          );
        case CameraChartType.hourlyTraffic:
        default:
          return (
            <Translate id="Camera@hourlyTrafficChartTitle">
              Horário de pico
            </Translate>
          );
      }
    }
    return "";
  };

  fileNameForChartType = (type) => {
    const { localizedString, dateFormatToExportFileName } = this.i18nStore;
    let name = "";

    if (this.isWifiType()) {
      switch (type) {
        case WifiChartType.accessByGroup:
          name = localizedString({
            id: "Connectivity@accessByGroupExportFileName",
            defaultString: "acessos-por-grupo",
          });
          break;
        case WifiChartType.visitors:
          name = localizedString({
            id: "Connectivity@visitorsChartExportFileName",
            defaultString: "perfil-dos-visitantes",
          });
          break;
        case WifiChartType.hourlyTraffic:
        default:
          name = localizedString({
            id: "Connectivity@hourlyTrafficChartExportFileName",
            defaultString: "horario-de-pico",
          });
          break;
      }
    } else if (this.isCameraType()) {
      switch (type) {
        case CameraChartType.accessByGroup:
          name = localizedString({
            id: "Camera@accessByGroupExportFileName",
            defaultString: "acessos-por-grupo",
          });
          break;
        case CameraChartType.visitors:
          name = localizedString({
            id: "Camera@visitorsChartExportFileName",
            defaultString: "perfil-dos-visitantes",
          });
          break;
        case CameraChartType.hourlyTraffic:
        default:
          name = localizedString({
            id: "Camera@hourlyTrafficChartExportFileName",
            defaultString: "horario-de-pico",
          });
          break;
      }
    }

    return `${name}_${getNowMoment().format(dateFormatToExportFileName)}`;
  };

  didSelectSavedChart = (chartViewId) => (savedChart) => {
    const { value, ...rest } = savedChart;
    didChangeDateRange(savedChart.dateRange, (dateRange, fromDate, toDate) => {
      this.updateChartView(chartViewId, {
        ...rest,
        dateRange,
        fromDate,
        toDate,
        wizardCurrentStep: this.isWifiType ? 4 : 3,
      });
    });
  };

  onChangeChartTypeForView = (chartViewId) => (_, selectedType) => {
    this.updateChartView(chartViewId, {
      selectedGroupsList: this.getSingleGroupForChartType(selectedType),
      type: selectedType,
    });
  };

  onChangeGroupsListForView = (chartViewId) => (value) => {
    this.updateChartView(chartViewId, {
      selectedGroupsList:
        Array.isArray(value) && value.length < 1 ? null : value,
    });
  };

  onChangeDateRangeForView = (chartViewId) => (_, value) => {
    didChangeDateRange(value, (dateRange, fromDate, toDate) => {
      this.updateChartView(chartViewId, {
        dateRange,
        fromDate,
        toDate,
      });
    });
  };
  onChangeFromDateForView = (chartViewId) => (_, value) => {
    const toDate = this[chartViewId].toDate.isBefore(value)
      ? value
      : this[chartViewId].toDate;
    this.updateChartView(chartViewId, {
      fromDate: value,
      toDate,
    });
  };
  onChangeToDateForView = (chartViewId) => (_, value) => {
    const fromDate = this[chartViewId].fromDate.isAfter(value)
      ? value
      : this[chartViewId].fromDate;
    this.updateChartView(chartViewId, {
      fromDate,
      toDate: value,
    });
  };
  onChangeFromHourForView = (chartViewId) => (fromHour) => {
    this.updateChartView(chartViewId, {
      fromHour,
    });
  };
  onChangeToHourForView = (chartViewId) => (toHour) => {
    this.updateChartView(chartViewId, {
      toHour,
    });
  };
  onChangeWeekDaysForView = (chartViewId) => (weekDays) => {
    this.updateChartView(chartViewId, {
      weekDays,
    });
  };

  allowToContinue = (chartViewId) => {
    if (this[chartViewId]) {
      switch (this[chartViewId].wizardCurrentStep) {
        case 1:
          return this[chartViewId].selectedGroupsList !== null;
        case 2:
          const { fromDate, toDate } = this[chartViewId];
          return fromDate !== null && toDate !== null;
        default:
          return true;
      }
    }
    return false;
  };

  allowToExportData = (chartViewId) => {
    if (this[chartViewId]) {
      const { chartData, type } = this[chartViewId];
      if (this.isWifiType()) {
        switch (type) {
          case WifiChartType.visitors:
            return false;
          case WifiChartType.accessByGroup:
          case WifiChartType.hourlyTraffic:
          default:
            return chartData !== null;
        }
      } else if (this.isCameraType()) {
        switch (type) {
          case CameraChartType.visitors:
            return false;
          case CameraChartType.accessByGroup:
          case CameraChartType.hourlyTraffic:
          default:
            return chartData !== null;
        }
      }
    }
    return false;
  };

  disabledStepsForView = (chartViewId) => {
    if (this[chartViewId]) {
      const hasSelectedGroup = this[chartViewId].selectedGroupsList !== null;
      switch (this[chartViewId].wizardCurrentStep) {
        case 0:
        case 1:
          return hasSelectedGroup ? [] : [2, 3];
        case 2:
          const { fromDate, toDate } = this[chartViewId];
          return fromDate !== null && toDate !== null ? [] : [3];
        default:
          return [];
      }
    }
    return [];
  };

  @action
  getChartDataForView = (
    chartViewId,
    fromDate,
    toDate,
    fromHour,
    toHour,
    weekDays,
    selectedGroupsList,
    chartType,
    targetAudience,
    macaddressRule,
    connectionTimeLimit,
    connectionType
  ) => {
    this[chartViewId].isFetching = true;

    if (selectedGroupsList.length < 1) return false;

    const groupsList = Array.isArray(selectedGroupsList)
      ? selectedGroupsList.map(($0) => $0.value)
      : [selectedGroupsList.value];

    const options = {
      hourlyTraffic: false,
      accessByGroup: false,
      peopleStatistics: false,
      connectionsPerType: false,
      peopleByGroup: false,
      accessByDeviceVendor: false,
      facesByGroup: false,
    };

    let promise = null;

    if (this.isWifiType()) {
      if (chartType === WifiChartType.accessByGroup) {
        options.accessByGroup = true;
      } else if (chartType === WifiChartType.hourlyTraffic) {
        options.hourlyTraffic = true;
      } else if (chartType === WifiChartType.visitors) {
        options.peopleStatistics = true;
        options.connectionsPerType = true;
        options.accessByDeviceVendor = true;
        options.peopleByGroup = true;
      }

      promise = this.requestMetricsWifi(
        fromDate.format("X"),
        toDate.format("X"),
        fromHour,
        toHour,
        weekDays,
        groupsList,
        options,
        targetAudience,
        macaddressRule,
        connectionTimeLimit,
        connectionType
      );
    } else if (this.isCameraType()) {
      if (chartType === CameraChartType.accessByGroup) {
        options.accessByGroup = true;
      } else if (chartType === CameraChartType.hourlyTraffic) {
        options.hourlyTraffic = true;
      } else if (chartType === CameraChartType.visitors) {
        options.facesByGroup = true;
      }

      promise = this.requestMetricsCamera(
        fromDate.format("X"),
        toDate.format("X"),
        fromHour,
        toHour,
        weekDays,
        groupsList,
        options
      );
    }

    if (promise) {
      promise
        .then(
          action((result) => {
            this[chartViewId].chartData = result;
            this[chartViewId].error = null;
            this[chartViewId].isFetching = false;
          })
        )
        .catch(
          action(
            action((error) => {
              if (error && error.statusCode && error.statusCode === 404) {
                this[chartViewId].error = this.i18nStore.localizedString({
                  id: "Chart@nothingFound",
                  defaultString: "Nenhum resultado para os filtros atuais.",
                });
              }
              this[chartViewId].chartData = null;
              this[chartViewId].isFetching = false;
            })
          )
        );
    }
  };

  requestAdditionalChartDataForWifiHourlyTraffic = (
    chartViewId,
    dayOfWeek,
    hourOfTheDay,
    valueForSelectedPeriod
  ) => {
    if (!chartViewId || valueForSelectedPeriod < 1) {
      this.resetChartView("additionalChart");
      return;
    }
    const {
      fromDate,
      toDate,
      targetAudience,
      macaddressRule,
      connectionTimeLimit,
      connectionType,
      selectedGroupsList,
    } = this[chartViewId];
    const groupsList = Array.isArray(selectedGroupsList)
      ? selectedGroupsList.map(($0) => $0.value)
      : [selectedGroupsList.value];
    const fromHour = `${hourOfTheDay}:00`;
    const toHour = `${hourOfTheDay + 1 < 24 ? hourOfTheDay + 1 : 0}:00`;
    this.requestMetricsWifi(
      fromDate.format("X"),
      toDate.format("X"),
      fromHour,
      toHour,
      [dayOfWeek],
      groupsList,
      {
        peopleByGroup: true,
      },
      targetAudience,
      macaddressRule,
      connectionTimeLimit,
      connectionType
    )
      .then(
        action((result) => {
          this.additionalChart = {
            ...this.defaultChart(),
            ...this[chartViewId],
            fromHour,
            toHour,
            chartData: result,
            type: CameraChartType.visitors,
          };
          this.didShowAdditionalChart();
        })
      )
      .catch(
        action((_) => {
          this.additionalChart = {
            ...this.defaultChart(),
            ...this[chartViewId],
            fromHour,
            toHour,
            chartData: defaultAdditionalDataJson(
              this.authStore.ageRanges,
              "people_by_group"
            ),
            type: CameraChartType.visitors,
            isDisabled: true,
          };
          this.didShowAdditionalChart();
        })
      );
  };

  requestAdditionalChartDataForCameraHourlyTraffic = (
    chartViewId,
    dayOfWeek,
    hourOfTheDay,
    valueForSelectedPeriod
  ) => {
    if (!chartViewId || valueForSelectedPeriod < 1) {
      this.resetChartView("additionalChart");
      return;
    }
    const { fromDate, toDate, selectedGroupsList } = this[chartViewId];
    const groupsList = Array.isArray(selectedGroupsList)
      ? selectedGroupsList.map(($0) => $0.value)
      : [selectedGroupsList.value];
    const fromHour = `${hourOfTheDay}:00`;
    const toHour = `${hourOfTheDay + 1 < 24 ? hourOfTheDay + 1 : 0}:00`;
    this.requestMetricsCamera(
      fromDate.format("X"),
      toDate.format("X"),
      fromHour,
      toHour,
      [dayOfWeek],
      groupsList,
      { facesByGroup: true }
    )
      .then(
        action((result) => {
          this.additionalChart = {
            ...this.defaultChart(),
            ...this[chartViewId],
            fromHour,
            toHour,
            chartData: result,
            type: CameraChartType.visitors,
          };
          this.didShowAdditionalChart();
        })
      )
      .catch(
        action((_) => {
          this.additionalChart = {
            ...this.defaultChart(),
            ...this[chartViewId],
            fromHour,
            toHour,
            chartData: defaultAdditionalDataJson(
              this.authStore.ageRanges,
              "faces_by_group"
            ),
            type: CameraChartType.visitors,
            isDisabled: true,
          };
          this.didShowAdditionalChart();
        })
      );
  };

  requestMetricsWifi = (
    fromDate,
    toDate,
    fromHour,
    toHour,
    weekDays,
    groupsList,
    dataType,
    targetAudience,
    macaddressRule,
    connectionTimeLimit,
    connectionType
  ) => {
    if (!dataType) return;
    const accessByGroup = dataType.accessByGroup
      ? dataType.accessByGroup
      : false;
    const connectionsPerType = dataType.connectionsPerType
      ? dataType.connectionsPerType
      : false;
    const hourlyTraffic = dataType.hourlyTraffic
      ? dataType.hourlyTraffic
      : false;
    const peopleStatistics = dataType.peopleStatistics
      ? dataType.peopleStatistics
      : false;
    const accessByDeviceVendor = dataType.accessByDeviceVendor
      ? dataType.accessByDeviceVendor
      : false;
    const peopleByGroup = dataType.peopleByGroup
      ? dataType.peopleByGroup
      : false;

    return new Promise(async (resolve, reject) => {
      this.modboxAPIService
        .requestMetricsWifi({
          fromDate,
          toDate,
          fromHour,
          toHour,
          weekDays,
          groupsList,
          accessByGroup,
          connectionsPerType,
          hourlyTraffic,
          peopleStatistics,
          accessByDeviceVendor,
          peopleByGroup,
          targetAudience,
          macaddressRule,
          connectionTimeLimit,
          connectionType,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          } else {
            reject(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  requestMetricsCamera = (
    fromDate,
    toDate,
    fromHour,
    toHour,
    weekDays,
    groupsList,
    dataType
  ) => {
    if (!dataType) return;
    const accessByGroup = dataType.accessByGroup
      ? dataType.accessByGroup
      : false;
    const hourlyTraffic = dataType.hourlyTraffic
      ? dataType.hourlyTraffic
      : false;
    const facesByGroup = dataType.facesByGroup ? dataType.facesByGroup : false;

    return new Promise(async (resolve, reject) => {
      this.modboxAPIService
        .requestMetricsCamera({
          fromDate,
          toDate,
          fromHour,
          toHour,
          weekDays,
          groupsList,
          accessByGroup,
          hourlyTraffic,
          facesByGroup,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          } else {
            reject(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _prepareChartDataToExport = (chartData, chartType) => {
    const { dateFormatWithFourDigitsYear } = this.i18nStore;
    if (this.isWifiType()) {
      if (chartType === WifiChartType.accessByGroup) {
        const data = chartData.access_by_group;
        let list = [];
        Object.keys(data).forEach((groupName) => {
          const values = data[groupName];
          const groupTotal = values.total;
          Object.keys(values.per_days).forEach((dayString) => {
            list.push({
              groupName,
              groupTotal,
              day: moment(dayString).format(dateFormatWithFourDigitsYear),
              dayTotal: values.per_days[dayString],
            });
          });
        });
        return list;
      }
      if (chartType === WifiChartType.hourlyTraffic) {
        const data = chartData.hourly_traffic;
        return Object.keys(data).map((dayWeekString) => {
          const columns = {};
          Object.keys(data[dayWeekString]).forEach((hourString) => {
            columns[hourString] = data[dayWeekString][hourString];
          });
          return {
            ...columns,
            dayOfTheWeek: dayWeekString,
          };
        });
      }
    } else if (this.isCameraType()) {
      if (chartType === CameraChartType.accessByGroup) {
        const data = chartData.access_by_group;
        let list = [];
        Object.keys(data).forEach((groupName) => {
          const values = data[groupName];
          const groupTotal = values.total;
          Object.keys(values.per_days).forEach((dayString) => {
            list.push({
              groupName,
              groupTotal,
              day: moment(dayString).format(dateFormatWithFourDigitsYear),
              dayTotal: values.per_days[dayString],
            });
          });
        });
        return list;
      }
      if (chartType === CameraChartType.hourlyTraffic) {
        const data = chartData.hourly_traffic;
        return Object.keys(data).map((dayWeekString) => {
          const columns = {};
          Object.keys(data[dayWeekString]).forEach((hourString) => {
            columns[hourString] = data[dayWeekString][hourString];
          });
          return {
            ...columns,
            dayOfTheWeek: dayWeekString,
          };
        });
      }
    }
    return null;
  };

  onChangeTargetAudience = (chartViewId) => (newValue) => {
    this.updateChartView(chartViewId, {
      targetAudience: newValue,
    });
  };

  onChangeMacaddressRule = (chartViewId) => (newValue) => {
    this.updateChartView(chartViewId, {
      macaddressRule: newValue,
    });
  };
  onChangeConnectionTimeLimit = (chartViewId) => (timeLimit) => {
    this.updateChartView(chartViewId, {
      connectionTimeLimit: timeLimit,
    });
  };
  onChangeConnectionType = (chartViewId) => (connectionType) => {
    this.updateChartView(chartViewId, {
      connectionType,
      targetAudience: "all",
      macaddressRule: "all",
    });
  };

  _exportToCsv = (list, fileName) => {
    const csvString = objectListToCsv(list);
    let element = document.createElement("a");
    const file = new Blob([csvString], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.csv`;
    element.click();
  };

  exportChartViewToCsv = (chartViewId) => {
    if (this[chartViewId]) {
      const { chartData, type } = this[chartViewId];
      if (!chartData) return false;

      const chartDataToExport = this._prepareChartDataToExport(chartData, type);
      if (chartDataToExport) {
        this._exportToCsv(chartDataToExport, this.fileNameForChartType(type));
      }
    }
  };
}

export default MetricsReportStore;
