import { action, computed, observable } from "mobx";

import { ContractAvailableResources } from "../store/ContractStore/ContractStore";

class ContractModel {
  label;
  name;
  hash;
  activationDate;
  isMasterContract;
  isAffiliateContract;
  @observable isActive;
  @observable isChecked;
  path;
  fileServer;
  minio_server;
  msgServer;
  licenseNumber;
  apiKey;
  @observable licences;
  @observable storage;
  @observable sms;
  @observable wifiAuthentication;
  @observable cameraFaces;
  @observable email;
  company;
  @observable resources;
  @observable rules;
  @observable plan;

  constructor({ isSimplyAsEmployee, ...contract }) {
    this.setModel({ isSimplyAsEmployee, ...contract });
    this.updateUsageData(contract);
  }
  is_checked;

  @action
  setModel = ({ isSimplyAsEmployee, ...contract }) => {
    this.isChecked = contract.is_checked;

    const contractName = contract.contract_name;
    this.isMasterContract = contract.master === "True";
    this.isAffiliateContract = contract.free === "True";

    let label = contractName;

    if (isSimplyAsEmployee && !this.isChecked) {
      label += " ✖️";
    }

    this.label = label;
    this.name = contractName;
    this.hash = contract["contract_hash"];
    this.activationDate = contract["activation_date"];
    this.isActive = contract.status;
    this.path = contract.path;
    this.fileServer = contract["file_server"];
    this.minio_server = contract["minio_server"];
    this.msgServer = contract["msg_server"];
    this.licenseNumber = contract["license_number"];
    this.apiKey = contract["api_key"];
    this.plan = contract["plan"];
    this.resources = {
      accessAutonomidia: contract.resource_access_autonomidia,
      administrative: contract.access_administrative,
      adsEarnings: contract.resource_earn_with_ads,
      advertising: contract.resource_advertising,
      aiContentSuggestion: contract.resource_ai_content_suggestion,
      camera: contract.resource_camera,
      clock: contract.resource_clock_display,
      complementaryContent: contract.resource_complementary_content,
      connectivity: contract.resource_connectivity,
      dynamicContent: contract.resource_dynamic_content,
      developer: contract.resource_developer,
      equipmentMonitor: contract.resource_equipment_monitoring,
      footerMessages: contract.resource_footer_messages_display,
      hdmiIntegration: contract.resource_hdmi_integration,
      humanSupport: contract.resource_human_support,
      informative: contract.resource_informative,
      ledPanelSupport: contract.resource_led_panel_support,
      liveOnlineContent: contract.resource_live_online_content,
      // INFO: a mudança da referência a location não foi feita por uma impossibilidade do backend
      // Atenção: Está diferente do que foi feito no arquivo ModboxAPIService
      groupControl: contract.resource_location_playlist_control,
      locationControl: contract.resource_location_playlist_control,
      logo: contract.resource_logo_display,
      playlist: contract.resource_playlist,
      playlistSchedule: contract.resource_playlist_scheduling,
      queueManager: contract.resource_queue_manager,
      videoReports: contract.resource_video_reports,
      videowall: contract.resource_videowall,
      viewerCounting: contract.resource_viewer_counting,
    };
    this.rules = {
      devices: contract.access_devices,
      // INFO: a mudança da referencia a location não foi feita por uma impossibilidade do backend
      // Atenção: Está diferente do que foi feito no arquivo ModboxAPIService
      groups: contract.access_locations,
      locations: contract.access_locations,
      informative: contract.access_informative,
      connectivity: contract.access_connectivity,
      administrative: contract.access_administrative,
      camera: contract.access_camera,
      advertising: contract.access_advertising,
      api: contract.access_api,
      support: contract.access_support,
    };
  };

  @computed
  get isPro() {
    return this.plan.name === "PRO";
  }

  @action
  setIsCheckedAndPlan = ({ isChecked, plan }) => {
    this.isChecked = isChecked;
    this.plan = plan;
  };

  @action
  updateUsageData = (data) => {
    this.licences = {
      limit: data.licences_limit,
      usage: data.licences_usage,
    };
    this.storage = {
      limit: parseInt(data.storage_limit, 10) || 0,
      usage: parseInt(data.storage_usage, 10) || 0,
    };
    this.sms = {
      limit: parseInt(data.sms_limit, 10),
      usage: parseInt(data.sms_usage, 10),
    };
    this.wifiAuthentication = {
      limit: parseInt(data.auth_limit, 10),
      usage: parseInt(data.auth_usage, 10),
    };
    this.cameraFaces = {
      limit: parseInt(data.faces_limit, 10),
      usage: parseInt(data.faces_usage, 10),
    };
    this.email = {
      limit: parseInt(data.email_limit, 10),
      usage: parseInt(data.email_usage, 10),
    };
  };

  hasAccessTo = (resource) => {
    const matchResources = ContractAvailableResources.includes(resource)
      ? this.resources[resource]
      : true;
    return matchResources && this.rules[resource];
  };

  exportToFirebase = () => ({
    name: this.name,
    hash: this.hash,
    isMasterContract: this.isMasterContract,
    isAffiliateContract: this.isAffiliateContract,
    isActive: this.isActive,
    isChecked: this.isChecked,
    resources: this.resources,
    rules: this.rules,
  });
}

export default ContractModel;
