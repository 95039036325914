// @ts-nocheck
import { Component, Fragment, createRef } from "react";
import Dropzone from "react-dropzone";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

import ImageCropper from "../ImageCropper/ImageCropper";
import Button from "../Button/Button";
import LoadingView from "../LoadingView/LoadingView";

import Translate from "../I18n/Translate";

import { extractImageFileExtensionFromBase64 } from "../../utils/ImageUtils";

// Styles
import DragDropImage from "../../assets/img/drag-drop.png";

import { primaryColor, successColor } from "../../assets/jss/app-js-styles";
const styles = {
  loadingContainer: {
    width: "100%",
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: { textAlign: "center" },
  icon: {
    maxWidth: 50,
    marginBottom: 10,
  },
  dropzone: {
    position: "relative",
    width: "100%",
    padding: 16,
    minHeight: 300,
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  progress: {
    fontSize: "1.2em",
    display: "flex",
    fontWeight: 400,
    margin: 0,
  },
  percentSymbol: {
    fontSize: "0.6em",
    marginLeft: 2,
  },
  defaultSrcImage: {
    maxWidth: "100%",
  },
};

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSrc: props.src,
      imgSrc: null,
      fileExtension: null,
      isDragging: false,
      isUploading: false,
      isUploadingProgress: null,
      isFinished: false,
    };

    this.cropperRef = createRef();
  }

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(
          "This file is not allowed. " + currentFileSize + " bytes is too large"
        );
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        alert("This file is not allowed. Only images are allowed.");
        return false;
      }
      return true;
    }
  };

  handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles);
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener(
          "load",
          () => {
            const myResult = myFileItemReader.result;
            this.setState({
              imgSrc: myResult,
              fileExtension: extractImageFileExtensionFromBase64(myResult),
            });
          },
          false
        );

        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  handleUploadClick = (event) => {
    if (event) event.preventDefault();

    if (!this.cropperRef.current) {
      return;
    }

    const { fileExtension } = this.state;
    const base64 = this.cropperRef.current
      .getCroppedCanvas()
      .toDataURL(`image/${fileExtension}`);

    this.setState(
      {
        isUploading: true,
      },
      () => {
        this.props.didCrop(
          {
            base64,
            fileExtension,
          },
          ({ status, progress, error, ...rest }) => {
            if (status === "uploading") {
              this.setState({
                isUploadingProgress: (progress * 100).toFixed(1),
              });
            } else if (status === "error") {
              this.setState({
                isUploading: false,
                isUploadingProgress: null,
                hasError: error,
              });
            } else if (status === "finished") {
              this.setState(
                {
                  isFinished: true,
                },
                () => {
                  this.props.didFinish({
                    isEditing: this.props.src !== null,
                    fileExtension,
                    ...rest,
                  });
                }
              );
            }
          }
        );
      }
    );
  };

  didCancel = (event) => {
    if (event) event.preventDefault();

    if (this.props.didCancel) {
      this.props.didCancel();
    }

    this.setState({
      imgSrc: null,
      fileExtension: null,
      isDragging: false,
      isUploading: false,
      isUploadingProgress: null,
      isFinished: false,
    });
  };

  didUpdate = () => {
    if (this.props.didUpdate) {
      this.props.didUpdate();
    }

    this.didCancel();
  };

  didWantToSendNewImage = () => {
    this.setState({
      defaultSrc: null,
    });
  };

  render() {
    const { classes } = this.props;

    if (this.state.isFinished) {
      return (
        <div className={classes.loadingContainer}>
          <i
            className="fas fa-check"
            style={{ fontSize: "3em", color: successColor }}
          />
        </div>
      );
    }
    if (this.state.isUploading) {
      const { isUploadingProgress } = this.state;
      return (
        <div className={classes.loadingContainer}>
          {isUploadingProgress && (
            <p className={classes.progress}>
              {isUploadingProgress}
              <span className={classes.percentSymbol}>
                <i className="fas fa-percent" />
              </span>
            </p>
          )}
          <LoadingView />
        </div>
      );
    }

    if (this.state.defaultSrc) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              src={this.state.defaultSrc}
              alt=""
              className={classes.defaultSrcImage}
            />
            <Button
              color="primary"
              small
              outline
              onClick={this.didWantToSendNewImage}
            >
              <Translate id="Logotype@sendNewImageButton">
                Enviar nova imagem
              </Translate>{" "}
              <i className="fas fa-image" />
            </Button>
          </Grid>
          {this.props.children && (
            <Grid item xs={12}>
              {this.props.children}
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Button color="default" outline block onClick={this.didCancel}>
              <i className="fas fa-chevron-left" />{" "}
              <Translate id="Common@cancel">Cancelar</Translate>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button color="primary" block onClick={this.didUpdate}>
              <Translate id="Common@update">Atualizar</Translate>{" "}
              <i className="fas fa-save" />
            </Button>
          </Grid>
        </Grid>
      );
    }

    const { imgSrc, isDragging } = this.state;
    const cropOptions = {
      resizable: this.props.resizable,
      aspectRatio: this.props.aspectRatio,
    };

    return (
      <div>
        {imgSrc ? (
          <Fragment>
            <ImageCropper
              ref={this.cropperRef}
              src={imgSrc}
              alt=""
              {...cropOptions}
            />
            {this.props.children && (
              <Grid container alignItems="center" style={{ marginTop: 30 }}>
                <Grid item xs>
                  {this.props.children}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button color="default" outline block onClick={this.didCancel}>
                  <i className="fas fa-chevron-left" />{" "}
                  <Translate id="Common@cancel">Cancelar</Translate>
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button color="success" block onClick={this.handleUploadClick}>
                  <Translate id="Common@submit">Enviar</Translate>{" "}
                  <i className="fas fa-cloud-upload" />
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <Dropzone
            onDrop={this.handleOnDrop}
            accept={acceptedFileTypes}
            multiple={false}
            maxSize={imageMaxSize}
            className={classes.dropzone}
            onDragEnter={(_) => {
              this.setState({ isDragging: true });
            }}
            onDragLeave={(_) => {
              this.setState({ isDragging: false });
            }}
            style={{
              borderColor: isDragging ? primaryColor : "rgb(102, 102, 102)",
            }}
          >
            {isDragging ? (
              <Fragment>
                <p className={classes.text} style={{ color: primaryColor }}>
                  <strong>Solte a imagem aqui!</strong>
                </p>
                <i
                  className="far fa-level-down"
                  style={{ fontSize: 40, color: primaryColor }}
                />
              </Fragment>
            ) : (
              <Fragment>
                <img src={DragDropImage} alt="Ícone" className={classes.icon} />
                <p className={classes.text}>
                  Arraste e <strong>solte a imagem aqui</strong>
                  <br />
                  ou clique para adicionar do seu dispositivo.
                </p>
              </Fragment>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}

ImageUpload.defaultProps = {
  src: null,
};

export default withStyles(styles)(ImageUpload);
