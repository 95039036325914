// @ts-nocheck
import { Component, ReactElement } from "react";
import classnames from "classnames";
import Select from "react-select";
import PropTypes from "prop-types";

import { withLocalized } from "../HOC";

import withStyles from "@material-ui/core/styles/withStyles";

import { FormControl, InputLabel } from "@material-ui/core";

import LoadingView from "../LoadingView/LoadingView";

import { SelectPlaceholderString } from "../I18n/CommonStrings";

// Style
import customInputStyle from "../../assets/jss/js-styles/components/customInputStyle";

interface IInputSelectProps {
  localizedString?: any;
  classes?: any;
  name: string;
  label?: string | ReactElement;
  value: any;
  disabled?: boolean;
  options: any[];
  placeholder?: string;
  labelProps?: any;
  formControlProps?: any;
  formControlStyles?: any;
  hasError?: boolean;
  hasSuccess?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  searchable?: boolean;
  autoFocus?: boolean;
  isClearable?: boolean;
  noOptionsMessage?: string;
  isRequired?: boolean;
  isLoading?: boolean;
  labelKey?: string;
  valueKey?: string;
  filterOption?: (option: any, rawInput: string) => boolean;
  customOptionLabel?: (option: object) => JSX.Element;
  withPortal?: boolean;
  onChange: (name: string, value: any) => void;
}

@withLocalized
class InputSelect extends Component<IInputSelectProps> {
  onChangeSelectInput = (selectedOption: any) => {
    const { name, onChange } = this.props;

    onChange(name, selectedOption);
  };

  render() {
    const {
      classes,
      label,
      name,
      placeholder,
      labelProps,
      formControlProps,
      hasError,
      hasSuccess,
      fullWidth,
      value,
      multiple,
      searchable,
      autoFocus,
      isClearable,
      noOptionsMessage,
      isRequired,
      isLoading,
      disabled,
      options,
      labelKey,
      valueKey,
      filterOption,
      customOptionLabel,
      withPortal,
      formControlStyles,
    } = this.props;
    let formControlClasses: any;
    if (formControlProps !== undefined) {
      formControlClasses = classnames(
        formControlProps.className,
        classes.formControl
      );
    } else {
      formControlClasses = classes.formControl;
    }
    const labelClasses = classnames({
      [" " + classes.labelRootError]: hasError,
      [" " + classes.labelRootSuccess]: hasSuccess && !hasError,
    });

    const optionLabel = (option: object) => option[labelKey];

    return (
      <FormControl
        {...formControlProps}
        fullWidth={fullWidth}
        disabled={disabled}
        className={formControlClasses}
        style={{
          cursor: disabled ? "not-allowed" : "inherit",
          ...formControlStyles,
        }}
      >
        {label && (
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            htmlFor={name}
            {...labelProps}
            shrink={true}
          >
            {label}
          </InputLabel>
        )}
        {isLoading ? (
          <div style={{ width: "fit-content" }}>
            <LoadingView height={36}></LoadingView>
          </div>
        ) : (
          <Select
            name={name}
            value={value}
            isMulti={multiple}
            isSearchable={searchable}
            placeholder={placeholder || <SelectPlaceholderString />}
            required={isRequired}
            options={options}
            autoFocus={autoFocus}
            noOptionsMessage={() =>
              noOptionsMessage ||
              this.props.localizedString({
                id: "Common@nothingFound",
                defaultString: "Nada encontrado",
              })
            }
            onChange={this.onChangeSelectInput}
            getOptionLabel={customOptionLabel ? customOptionLabel : optionLabel}
            getOptionValue={(option) => option[valueKey]}
            filterOption={filterOption}
            isClearable={isClearable}
            isDisabled={disabled}
            menuPortalTarget={
              withPortal ? document.getElementById("modal-root") : undefined
            }
            styles={{
              control: (provided) => ({
                ...provided,
                marginTop: 2,
                minHeight: 30,
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: withPortal ? 99999999 : "inherit",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                padding: 4,
              }),
              clearIndicator: (base) => ({
                ...base,
                padding: 4,
              }),
              multiValue: (base) => ({
                ...base,
              }),
              valueContainer: (base) => ({
                ...base,
                padding: "0px 6px",
              }),
              input: (base) => ({
                ...base,
                margin: 0,
                padding: 0,
              }),
            }}
          />
        )}
      </FormControl>
    );
  }

  static defaultProps = {
    fullWidth: true,
    searchable: true,
    isRequired: false,
    labelKey: "label",
    valueKey: "value",
    formControlStyles: {},
  };

  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    formGroup: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    formControlStyles: PropTypes.object,
    hasError: PropTypes.bool,
    hasSuccess: PropTypes.bool,
    hidden: PropTypes.bool,
    isRequired: PropTypes.bool,
    isLoading: PropTypes.bool,
    multiple: PropTypes.bool,
    searchable: PropTypes.bool,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    withPortal: PropTypes.bool,
    noOptionsMessage: PropTypes.string,
  };
}

export default withStyles(customInputStyle)(InputSelect);
