// @ts-nocheck
import { Component } from "react";
import { observer } from "mobx-react";

import { withLocalized } from "../../components/HOC";
import { GridContainer } from "../../components/Grid";

import MetricChartSectionTitle from "../../components/Charts/MetricChartSectionTitle";
import MetricChartBody from "../../components/Charts/MetricChartBody";

import HourlyTrafficChartWidget from "./HourlyTrafficChartWidget";
import { isObject } from "../../utils/Utils";

import { timeKeys } from "../../utils/CalendarUtils";

// Translate
import Translate from "../../components/I18n/Translate";

@withLocalized
@observer
class HourlyTrafficChartContainer extends Component {
  prepareChartData = (result) => {
    if (!result) return null;

    const { localizedString } = this.props;

    const data = result.hourly_traffic;
    if (!isObject(data) || Object.keys(data).length < 1) return null;

    let highestValue = 0;
    let lowerValue = Number.MAX_SAFE_INTEGER;
    let busiestTimeHourString = "";
    let busiestTimeDayString = "";
    let busiestDayOfTheWeekString = "";
    let lessBusyTimeHourString = "";
    let lessBusyTimeDayString = "";
    let lessBusyDayOfTheWeekString = "";

    const sumHourlyTrafficPerDayOfWeek = {
      sun: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
    };

    Object.keys(data).forEach((dayString) => {
      const dayValueList = data[dayString];
      Object.keys(dayValueList).forEach((hourString) => {
        const hourValue = dayValueList[hourString];

        // Find the busiest hour
        if (hourValue > highestValue) {
          highestValue = hourValue;
          busiestTimeHourString = hourString;
          busiestTimeDayString = dayString;
        }

        // Find the less busy hour
        if (hourValue > 0 && hourValue < lowerValue) {
          lowerValue = hourValue;
          lessBusyTimeHourString = hourString;
          lessBusyTimeDayString = dayString;
        }

        // sum hourly traffic per day of week
        sumHourlyTrafficPerDayOfWeek[dayString] += hourValue;
      });
    });

    // Reset highestValue & lowerValue
    highestValue = 0;
    lowerValue = Number.MAX_SAFE_INTEGER;

    // Find the busiest & less busy day of the week
    Object.keys(sumHourlyTrafficPerDayOfWeek).forEach((dayString) => {
      const value = sumHourlyTrafficPerDayOfWeek[dayString];
      if (value > highestValue) {
        highestValue = value;
        busiestDayOfTheWeekString = dayString;
      }
      if (value > 0 && value < lowerValue) {
        lowerValue = value;
        lessBusyDayOfTheWeekString = dayString;
      }
    });

    return {
      ...data,
      busiestTimeHour: localizedString({
        id: timeKeys[busiestTimeHourString],
        defaultString: busiestTimeHourString,
      }),
      busiestTimeDay: localizedString({
        id: `Date@${busiestTimeDayString}`,
        defaultString: busiestTimeDayString,
      }),
      busiestDayOfTheWeek: localizedString({
        id: `Date@${busiestDayOfTheWeekString}`,
        defaultString: busiestDayOfTheWeekString,
      }),
      lessBusyTimeHour: localizedString({
        id: timeKeys[lessBusyTimeHourString],
        defaultString: lessBusyTimeHourString,
      }),
      lessBusyTimeDay: localizedString({
        id: `Date@${lessBusyTimeDayString}`,
        defaultString: lessBusyTimeDayString,
      }),
      lessBusyDayOfTheWeek: localizedString({
        id: `Date@${lessBusyDayOfTheWeekString}`,
        defaultString: lessBusyDayOfTheWeekString,
      }),
    };
  };

  getHourLabels = () => {
    const { localizedString } = this.props;
    const labels = {};
    for (let index = 0; index < 24; index++) {
      labels[`${index}`] = localizedString({
        id: timeKeys[`${index}`],
        defaultString: index,
      });
    }
    return labels;
  };

  render() {
    const {
      isFetching,
      error,
      chartData: result,
      groupsList,
      datePeriod,
    } = this.props;
    const chartData = this.prepareChartData(result);

    return (
      <GridContainer justify="center">
        <MetricChartSectionTitle
          groupsList={groupsList}
          datePeriod={datePeriod}
        >
          <Translate id="Connectivity@hourlyTrafficChartTitle">
            Horário de pico
          </Translate>
        </MetricChartSectionTitle>
        <MetricChartBody isFetching={isFetching} hasError={error}>
          <HourlyTrafficChartWidget
            hourLabels={this.getHourLabels()}
            chartData={chartData}
            didSelectChartItem={this.props.didSelectChartItem}
          />
        </MetricChartBody>
      </GridContainer>
    );
  }
}

export default HourlyTrafficChartContainer;
