// @ts-nocheck
import { observable, computed, action, autorun } from "mobx";

// Model
import UploadMediaModel from "../../Model/UploadMediaModel";
import StreamingMediaModel from "../../Model/StreamingMediaModel";
import ContentMediaModel from "../../Model/ContentMediaModel";
import ExtrasMediaModel from "../../Model/ExtrasMediaModel";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";
import Translate from "../../components/I18n/Translate";

export const MediaTypeEnum = Object.freeze({
  UPLOAD: "uploads",
  STREAMING: "streamings",
  CONTENT: "contents",
  EXTRAS: "extras",
});

export const ContentMediaTypeEnum = Object.freeze({
  RSS: "rss",
  SCREENSHOT: "screenshot",
});

class MediaLibraryStore {
  @observable uploadsList = [];
  @observable streamingsList = [];
  @observable contentsList = [];
  @observable extrasList = [];
  @observable isFetching = false;
  @observable searchString = "";
  @observable selectedTags = [];
  @observable languageFilter = "all";

  constructor(
    i18nStore,
    tagsStore,
    internalEventsStore,
    notificationsStore,
    modboxAPIService,
    modboxFileService
  ) {
    this.i18nStore = i18nStore;
    this.tagsStore = tagsStore;
    this.internalEventsStore = internalEventsStore;
    this.notificationsStore = notificationsStore;
    this.modboxAPIService = modboxAPIService;
    this.modboxFileService = modboxFileService;

    autorun(() => {
      this.setLanguageFilter(
        this.i18nStore.currentLanguage === "en" ? "en" : "all"
      );
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.mediaTagWasDeleted,
      observer: this,
      callback: (deletedToken) => {
        const tagsList = this.selectedTags.filter(
          ({ token }) => token !== deletedToken
        );
        this.setSelectedTags(tagsList);
      },
    });
  }

  @action
  clearStore = () => {
    this.uploadsList = [];
    this.streamingsList = [];
    this.contentsList = [];
    this.resetMediasSelectedQty();
    this.searchString = "";
    this.selectedTags = [];
  };

  @action setUploadsList = (newValue) => {
    this.uploadsList = newValue;
  };
  @action setStreamingsList = (newValue) => {
    this.streamingsList = newValue;
  };
  @action setContentsList = (newValue) => {
    this.contentsList = newValue;
  };
  @action setExtrasList = (newValue) => {
    this.extrasList = newValue;
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };
  @action
  setSearchString = (newValue) => {
    this.searchString = newValue;
  };

  @action
  setSelectedTags = (tags) => {
    this.selectedTags = tags || [];
  };

  updateMediasList = async (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);

    await this.getUploadsList();
    await this.getStreamingsList();
    await this.getContentsList();
    if (this.extrasList.length < 1) {
      await this.getExtrasList();
    }

    this.setIsFetching(false);
  };

  @action
  setLanguageFilter = (newValue) => {
    this.languageFilter = newValue;
  };

  @computed
  get selectedTagsTokens() {
    return this.selectedTags.map(({ token }) => token);
  }

  @computed
  get filteredMediaList() {
    const list = [
      ...this.uploadsList,
      ...this.streamingsList,
      ...this.contentsList,
      ...this.filteredExtrasList,
    ];

    const listFilteredByTags =
      this.selectedTags.length < 1
        ? list
        : list.filter(({ token }) => {
            const tags = this.tagsStore.getMediaTags(token);
            return tags.some((t) => this.selectedTagsTokens.includes(t));
          });

    if (this.searchString.length < 1) return listFilteredByTags;
    return didSearchList(
      this.searchString,
      listFilteredByTags,
      (mediaItem, textIncludesSearchString) =>
        textIncludesSearchString(mediaItem.name)
    );
  }

  saveSelectedMediasForList = (listName) => {
    const selectedMediasBackup = {};
    if (this[listName].length > 0) {
      this[listName]
        .filter((mediaItem) => mediaItem.selectedQty > 0)
        .forEach((mediaItem) => {
          selectedMediasBackup[mediaItem.token] = mediaItem.selectedQty;
        });
    }
    return selectedMediasBackup;
  };

  @action
  getUploadsList = () => {
    return new Promise((resolve) => {
      let newUploadsList = [];

      const selectedMediasBackup = this.saveSelectedMediasForList(
        "uploadsList"
      );

      this.modboxAPIService
        .requestFiles()
        .then((response) => {
          if (response.status === 200) {
            response.data.files.forEach((mediaItemData) => {
              newUploadsList.push({
                ...new UploadMediaModel(mediaItemData, this.modboxFileService),
                selectedQty: selectedMediasBackup[mediaItemData.token]
                  ? selectedMediasBackup[mediaItemData.token]
                  : 0,
              });
            });
          }
        })
        .catch((error) => {
          if (error && error.statusCode === 429) {
            setTimeout(this.getUploadsList, 1200);
          }
        })
        .then(() => {
          this.setUploadsList(newUploadsList);
          resolve();
        });
    });
  };

  @action
  getStreamingsList = () => {
    return new Promise((resolve) => {
      let newStreamingsList = [];

      const selectedMediasBackup = this.saveSelectedMediasForList(
        "streamingsList"
      );

      setTimeout(() => {
        this.modboxAPIService
          .requestStreamings()
          .then((response) => {
            if (response.status === 200) {
              response.data.streamings.forEach((streamingData) => {
                newStreamingsList.push({
                  ...new StreamingMediaModel(streamingData),
                  selectedQty: selectedMediasBackup[streamingData.token]
                    ? selectedMediasBackup[streamingData.token]
                    : 0,
                });
              });
            }
          })
          .catch((error) => {
            if (error && error.statusCode === 429) {
              setTimeout(this.getStreamingsList, 1700);
            }
          })
          .then(() => {
            this.setStreamingsList(newStreamingsList);
            resolve();
          });
      }, 500);
    });
  };

  @action
  getContentsList = () => {
    return new Promise((resolve) => {
      let newContentsList = [];

      const selectedMediasBackup = this.saveSelectedMediasForList(
        "contentsList"
      );

      setTimeout(() => {
        this.modboxAPIService
          .requestContents()
          .then((response) => {
            if (response.status === 200) {
              response.data.contents.forEach((contentData) => {
                newContentsList.push({
                  ...new ContentMediaModel(contentData),
                  selectedQty: selectedMediasBackup[contentData.token]
                    ? selectedMediasBackup[contentData.token]
                    : 0,
                });
              });
            }
          })
          .catch((error) => {
            if (error && error.statusCode === 429) {
              setTimeout(this.getContentsList, 1800);
            }
          })
          .then(() => {
            this.setContentsList(newContentsList);
            resolve();
          });
      }, 500);
    });
  };

  @action
  getExtrasList = () => {
    return new Promise((resolve) => {
      let newExtrasList = [];

      setTimeout(() => {
        this.modboxAPIService
          .requestChannels()
          .then((response) => {
            if (response.status === 200) {
              response.data.channels.forEach((extraMediaData) => {
                newExtrasList.push({
                  ...new ExtrasMediaModel(extraMediaData),
                  selectedQty: 0,
                });
              });
            }
          })
          .catch((error) => {
            if (error && error.statusCode === 429) {
              setTimeout(this.getExtrasList, 2000);
            }
          })
          .then(() => {
            this.setExtrasList(
              newExtrasList.sort(dynamicSortByProperty("name"))
            );
            resolve();
          });
      }, 500);
    });
  };

  @computed
  get filteredExtrasList() {
    if (this.languageFilter === "all") return this.extrasList;

    return this.extrasList.filter(
      ({ language }) => language === this.languageFilter
    );
  }

  updateTaggedMediasList = (mediasList, tags) => {
    const { mediaPreferences } = this.tagsStore;
    let taggedMedia = { ...mediaPreferences.taggedMedia };
    mediasList.forEach((token) => {
      taggedMedia[`media_${token}`] = tags;
    });
    this.tagsStore.updateMediaPreferences({
      ...mediaPreferences,
      taggedMedia,
    });
  };

  submitFiles = (files, tags, isVertical, isFade, isCustom, isVideowall) => {
    if (files.length < 1) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitFiles({
          files: files.map((file) => ({
            name: file.name,
            md5: file.fileHash,
            duration: file.duration,
            is_image: file.is_image,
            isVertical,
            isFade,
            isCustom, // LED
            isVideowall,
          })),
        })
        .then(() => {
          this.getUploadsList().then(() => {
            resolve();
          });

          this.updateTaggedMediasList(
            files.map(({ fileHash }) => fileHash),
            tags.map(({ token }) => token)
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateSingleMediaItem = async ({ type, token, name, expireTime }) => {
    try {
      const response = await this.modboxAPIService.updateMedia({
        mediaList: [
          {
            media_type: type,
            token: token,
            name: name,
            expire_time: expireTime,
          },
        ],
      });

      if (type === MediaTypeEnum.UPLOAD) {
        this.getUploadsList();
      }
      if (type === MediaTypeEnum.STREAMING) {
        this.getStreamingsList();
      }
      if (type === MediaTypeEnum.CONTENT) {
        this.getContentsList();
      }
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });

      Promise.resolve();
    } catch (error) {
      if (error && error.message) {
        this.notificationsStore.addSnackbarNotification({
          message: error.message,
          color: "danger",
        });
      }
      Promise.reject();
    }
  };

  resetMediasSelectedQty = () => {
    const resetSelectedQtyMap = (item) => ({
      ...item,
      selectedQty: 0,
    });
    this.setUploadsList(this.uploadsList.map(resetSelectedQtyMap));
    this.setStreamingsList(this.streamingsList.map(resetSelectedQtyMap));
    this.setContentsList(this.contentsList.map(resetSelectedQtyMap));
    this.setExtrasList(this.extrasList.map(resetSelectedQtyMap));
  };

  @computed
  get getSelectedMedias() {
    const filterSelectedQtyMoreThanZero = (item) => item.selectedQty > 0;
    return [
      ...this.uploadsList.filter(filterSelectedQtyMoreThanZero),
      ...this.streamingsList.filter(filterSelectedQtyMoreThanZero),
      ...this.contentsList.filter(filterSelectedQtyMoreThanZero),
      ...this.extrasList.filter(filterSelectedQtyMoreThanZero),
    ];
  }

  @action
  loopListToChangeSelectedQty = (listName, props) => {
    let newList = [];
    this[listName].forEach((mediaItem) => {
      if (mediaItem.token === props.token) {
        newList.push({
          ...mediaItem,
          selectedQty: props.selectedQty,
        });
      } else {
        newList.push(mediaItem);
      }
    });
    this[listName] = newList;
  };

  didChangeMediaSelectedQty = (props) => {
    switch (props.type) {
      case MediaTypeEnum.UPLOAD:
        this.loopListToChangeSelectedQty("uploadsList", props);
        break;

      case MediaTypeEnum.STREAMING:
        this.loopListToChangeSelectedQty("streamingsList", props);
        break;

      case MediaTypeEnum.CONTENT:
        this.loopListToChangeSelectedQty("contentsList", props);
        break;

      default:
        this.loopListToChangeSelectedQty("extrasList", props);
        break;
    }
  };

  didDeleteMedia = async (deletedMediaItem) => {
    const filterDeletedMedia = ({ token }) => token !== deletedMediaItem.token;

    if (deletedMediaItem.type === MediaTypeEnum.UPLOAD) {
      this.setUploadsList(this.uploadsList.filter(filterDeletedMedia));
    } else if (deletedMediaItem.type === MediaTypeEnum.STREAMING) {
      this.setStreamingsList(this.streamingsList.filter(filterDeletedMedia));
    } else if (deletedMediaItem.type === MediaTypeEnum.CONTENT) {
      this.setContentsList(this.contentsList.filter(filterDeletedMedia));
    }

    try {
      const response = await this.modboxAPIService.deleteMedia({
        token: deletedMediaItem.token,
        mediaType: deletedMediaItem.type,
      });
      if (response.status === 200) {
        this.notificationsStore.addSnackbarNotification({
          message: (
            <Translate id="Informative@deletedMediaMessage">
              {response.data.message}
            </Translate>
          ),
          color: "success",
        });
      }
    } catch (e) {
      console.log("Error trying to delete media", e)
    }

    this.internalEventsStore.notify(EInternalEvents.mediaWasDeleted, {
      deletedMediaToken: deletedMediaItem.token,
    });
  };

  submitStreaming = (streaming, tags) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitStreamings(streaming)
        .then((response) => {
          const { message, streaming_token } = response.data;
          this.getStreamingsList().then(() => {
            this.notificationsStore.addSnackbarNotification({
              message,
              color: "success",
            });
            this.updateTaggedMediasList(
              [streaming_token],
              tags.map(({ token }) => token)
            );
            resolve();
          });
        })
        .catch((error) => {
          if (error && error.message) {
            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              color: "danger",
            });
          }
          reject();
        });
    });
  };

  submitContent = (content, tags) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitContent(content)
        .then((response) => {
          const { message, content_token } = response.data;
          this.getContentsList().then(() => {
            this.notificationsStore.addSnackbarNotification({
              message,
              color: "success",
            });
            this.updateTaggedMediasList(
              [content_token],
              tags.map(({ token }) => token)
            );
            resolve();
          });
        })
        .catch((error) => {
          if (error && error.message) {
            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              color: "danger",
            });
          }
          reject();
        });
    });
  };

  getMediaOnListByToken = (list, token) => {
    const resultList = list.filter((mediaItem) => mediaItem.token === token);
    if (resultList.length > 0) {
      return resultList[0];
    }
    return null;
  };

  getWithToken = (mediaType, token) => {
    if (mediaType === MediaTypeEnum.UPLOAD && this.uploadsList.length > 0) {
      return this.getMediaOnListByToken(this.uploadsList, token);
    } else if (
      mediaType === MediaTypeEnum.STREAMING &&
      this.streamingsList.length > 0
    ) {
      return this.getMediaOnListByToken(this.streamingsList, token);
    } else if (
      mediaType === MediaTypeEnum.CONTENT &&
      this.contentsList.length > 0
    ) {
      return this.getMediaOnListByToken(this.contentsList, token);
    }
    return null;
  };

  requestFile = async (token: string) => {
    try {
      const response = await this.modboxAPIService.requestFile(token);
      if (response.status === 200) {
        const mediaModel = new UploadMediaModel(
          response.data,
          this.modboxFileService
        );

        return mediaModel;
      }
      return null;
    } catch (error) {
      window.debugError("error in requestFile", error);
      return null;
    }
  };

  uploadMediaDidFinishProcessingVideo = async (token: string) => {
    const mediaModel = await this.requestFile(token);
    return mediaModel?.isProcessingFinished || false;
  };
}

export default MediaLibraryStore;
