// @ts-nocheck
import { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import { Button } from "../Button";

// Translate
import Translate from "../I18n/Translate";

// Style
const useStyles = makeStyles({
  container: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pageText: {
    margin: "0 20px",
  },
});

const CustomButton = ({ children, isDisabled, onClick }) => (
  <Button
    small
    justIcon
    color="primary"
    outline={isDisabled}
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </Button>
);

const Paginate = ({ itemsList, itemsPerPage, children }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const itemsCount = itemsList.length;
  const isFirstPage = currentPage === 1;
  const totalPages = Math.ceil(itemsCount / itemsPerPage);
  const isLastPage = currentPage === totalPages;

  const goToPreviousPage = () => {
    if (!isFirstPage) setCurrentPage(currentPage - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setCurrentPage(currentPage + 1);
  };
  const goToFirstPage = () => setCurrentPage(1);
  const goToLastPage = () => setCurrentPage(totalPages);

  let startIndex = 0;
  if (currentPage === 2) {
    startIndex = itemsPerPage;
  } else if (currentPage > 2) {
    const indexToMultiply = currentPage - 1;
    startIndex = indexToMultiply * itemsPerPage;
  }

  const endIndex = startIndex + itemsPerPage;

  return (
    <Fragment>
      {children({
        slicedList: itemsList.slice(startIndex, endIndex),
      })}
      {totalPages > 1 && (
        <div className={classes.container}>
          {totalPages > 2 && (
            <CustomButton isDisabled={isFirstPage} onClick={goToFirstPage}>
              <i className="fas fa-chevron-double-left" />
            </CustomButton>
          )}
          <CustomButton isDisabled={isFirstPage} onClick={goToPreviousPage}>
            <i className="fas fa-chevron-left" />
          </CustomButton>
          <p className={classes.pageText}>
            <Translate id="Table@pageText">Página</Translate> {currentPage}{" "}
            <Translate id="Table@ofText">de</Translate> {totalPages}
          </p>
          <CustomButton isDisabled={isLastPage} onClick={goToNextPage}>
            <i className="fas fa-chevron-right" />
          </CustomButton>
          {totalPages > 2 && (
            <CustomButton isDisabled={isLastPage} onClick={goToLastPage}>
              <i className="fas fa-chevron-double-right" />
            </CustomButton>
          )}
        </div>
      )}
    </Fragment>
  );
};

Paginate.defaultProps = {
  itemsPerPage: 10,
};

Paginate.propTypes = {
  itemsList: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default Paginate;
