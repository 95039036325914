// @ts-nocheck
import { observable, computed, action, reaction } from "mobx";
import { v4 as uuid } from "uuid";
import _debounce from "lodash/debounce";

import TerminalModel from "../../Model/TerminalModel";

import { EAppRoutes } from "../../routes/AppRoutes";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

import {
  IRequestTerminalsParams,
  ETerminalConnectionStatus,
  ERequestTerminalMetadata,
  ETerminalStatus,
  ETerminalAlertType,
} from "../../types/Terminal";

class TerminalsStore {
  lastUsedFilters: IRequestTerminalsParams;

  @observable isFetching: boolean = false;
  @observable isFetchingPaginate: boolean = false;
  @observable didFirstRequest: boolean = false;
  @observable searchString: string = "";
  @observable hasTerminalsOnFirstRequest: boolean = false;
  @observable terminalsList: TerminalModel[] = [];
  @observable totalDevices = 0;

  ongoingRequests: { [key: string]: string } = {};

  subscribers: Array<any> = [];

  constructor(
    authStore,
    contractStore,
    internalEventsStore,
    notificationsStore,
    routingStore,
    modboxAPIService
  ) {
    this.authStore = authStore;
    this.contractStore = contractStore;
    this.notificationsStore = notificationsStore;
    this.routingStore = routingStore;
    this.modboxAPIService = modboxAPIService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: (isSelected: boolean) => {
        this.clearStore();
        if (isSelected) {
          this.resetFilters();
        }
      },
    });
    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });

    this.setSearchString(this.requestTerminalsFilters?.search || "");

    reaction(
      () => this.searchString,
      (searchString) => this.updateSearchStringQuerySearch(searchString)
    );
  }

  @action
  loadFiltersFromLocalStorage = (): IRequestTerminalsParams | null => {
    try {
      const filtersJSON = localStorage.getItem("terminalFilters");
      if (filtersJSON) {
        const filters: IRequestTerminalsParams = JSON.parse(filtersJSON);
        this.routingStore.pushObjectToSearchQuery(filters);
        return filters;
      }
    } catch (error) {
      console.error("Error loading filters from local storage:", error);
    }
    return null;
  };

  @action
  saveFiltersToLocalStorage = (filters: IRequestTerminalsParams) => {
    try {
      const filtersJSON = JSON.stringify(filters);
      localStorage.setItem("terminalFilters", filtersJSON);
    } catch (error) {
      console.error("Error saving filters to local storage:", error);
    }
  };

  @action
  clearStore = (): void => {
    this.didFirstRequest = false;
    this.hasTerminalsOnFirstRequest = false;
    this.terminalsList = [];
    this.totalDevices = 0;
  };

  @action
  setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };
  @action
  setIsFetchingPaginate = (isFetching: boolean) => {
    this.isFetchingPaginate = isFetching;
  };
  @action
  setDidFirstRequest = (hasTerminals: boolean) => {
    this.didFirstRequest = true;
    this.hasTerminalsOnFirstRequest = hasTerminals;
  };
  @action
  setSearchString = (newValue: string) => {
    if (newValue !== this.searchString) {
      this.setIsFetching(true);
    }
    this.searchString = newValue;
  };

  @action
  setTerminalsList = (terminalsList: TerminalModel[]) => {
    this.terminalsList = terminalsList;
  };
  @action
  setTotalDevices = (newValue: number) => {
    this.totalDevices = newValue;
  };

  @computed
  get hasTerminal(): boolean {
    return this.totalDevices > 0;
  }

  @computed
  get requestTerminalsFilters(): IRequestTerminalsParams {
    const searchQuery = this.routingStore.searchQuery;

    return {
      page: searchQuery.page ? parseInt(searchQuery.page, 10) : 1,
      limit: searchQuery.limit ? parseInt(searchQuery.limit, 10) : 10,
      connection: searchQuery.connection
        ? ETerminalConnectionStatus[searchQuery.connection]
        : ETerminalConnectionStatus.all,
      status: searchQuery.status
        ? ETerminalStatus[searchQuery.status]
        : ETerminalStatus.enabled,
      search: searchQuery.search || "",
      alerts: searchQuery.alerts || [],
      orderBy: searchQuery.orderBy || "name ASC",
    };
  }

  @computed
  get currentPage(): number {
    return this.requestTerminalsFilters.page || 1;
  }
  @computed
  get sortedBy(): { column: string; asc: boolean } {
    let column = "name";
    let asc: true;
    if (this.requestTerminalsFilters.orderBy) {
      const orderSplit = this.requestTerminalsFilters.orderBy.split(" ");
      column = orderSplit[0];
      asc = orderSplit[1] === "ASC";
    }
    return { column, asc };
  }
  @computed
  get devicesPerPage(): number {
    return this.requestTerminalsFilters.limit || 10;
  }
  @computed
  get filterByStatus(): ETerminalStatus {
    return this.requestTerminalsFilters.status || ETerminalStatus.all;
  }
  @computed
  get filterByConnectionStatus(): ETerminalConnectionStatus {
    return (
      this.requestTerminalsFilters.connection || ETerminalConnectionStatus.all
    );
  }
  @computed
  get filterByAlerts(): ETerminalAlertType[] {
    return this.requestTerminalsFilters.alerts || [];
  }

  subscribe = (subscriber: any) => {
    this.subscribers.push(subscriber);
  };
  unsubscribe = (subscriber: any) => {
    this.subscribers = this.subscribers.filter((s) => s !== subscriber);
  };

  setFilterByConnectionStatus = (connection: ETerminalConnectionStatus) => {
    this.setQuerySearch({
      connection,
      page: 1,
    });
  };
  setFilterByStatus = (status: ETerminalStatus) => {
    this.setQuerySearch({
      status,
      page: 1,
    });
  };
  setFilterByAlerts = (alerts: ETerminalAlertType[] | null) => {
    this.setQuerySearch({
      alerts: alerts || [],
      page: 1,
    });
  };

  onChangePaginate = (currentPage: number, pageSize: number) => {
    if (currentPage === this.currentPage && pageSize === this.devicesPerPage) {
      return;
    }
    this.setQuerySearch(
      {
        page: currentPage,
        limit: pageSize,
      },
      true
    );
  };

  onChangeOrderBy = (column: string, asc: boolean) => {
    this.setQuerySearch(
      {
        orderBy: `${column} ${asc ? "ASC" : "DESC"}`,
      },
      true
    );
  };

  updateSearchStringQuerySearch = _debounce((searchString: string) => {
    this.setQuerySearch({
      search: searchString,
      page: 1,
    });
  }, 400);

  setQuerySearch = (
    {
      page,
      limit,
      search,
      status,
      connection,
      alerts,
      orderBy,
    }: IRequestTerminalsParams,
    fetchingOnlyPaginate = false
  ) => {
    if (this.subscribers.length < 1) {
      return;
    }

    const searchParam =
      search?.length >= 0 ? search : this.requestTerminalsFilters.search;

    const connectionParam =
      connection || this.requestTerminalsFilters.connection;

    const filters = {
      search: searchParam.length > 0 ? searchParam : undefined,
      page: page || this.requestTerminalsFilters.page,
      limit: limit || this.requestTerminalsFilters.limit,
      status: this.authStore.currentUserCanSupport
        ? status || this.requestTerminalsFilters.status
        : undefined,
      connection:
        connectionParam !== ETerminalConnectionStatus.all
          ? connectionParam
          : undefined,
      alerts: alerts || this.requestTerminalsFilters.alerts,
      orderBy: orderBy || this.requestTerminalsFilters.orderBy,
    };
    this.saveFiltersToLocalStorage(filters);
    this.routingStore.pushObjectToSearchQuery(filters);

    if (fetchingOnlyPaginate) {
      this.setIsFetchingPaginate(true);
      this.getTerminalsListWithoutAnimation(false);
    } else {
      this.getTerminalsList();
    }
  };

  resetFilters = (): void => {
    this.setQuerySearch({
      page: 1,
      limit: 10,
      search: "",
      status: ETerminalStatus.enabled,
      connection: ETerminalConnectionStatus.all,
      alerts: [],
    });
    // this.setSearchString("");
  };

  requestTerminals = _debounce(
    async (
      options: IRequestTerminalsParams = {},
      callback: (terminalsList: TerminalModel[], totalDevices: number) => void,
      shouldIgnoreCache: boolean = false
    ) => {
      if (
        !shouldIgnoreCache &&
        JSON.stringify(options) === JSON.stringify(this.lastUsedFilters)
      ) {
        callback(this.terminalsList, this.totalDevices);
        return;
      }

      // Every time we call this function we sign it with a token to prevent previous calls from completing callback
      const token = uuid();
      this.lastUsedFilters = options;

      let tempList: TerminalModel[] = [];
      let totalDevices = 0;

      try {
        // set current ongoing request with token
        this.ongoingRequests["requestTerminals"] = token;

        const response = await this.modboxAPIService.requestTerminals({
          ...options,
          contractHash: this.routingStore.pathname.includes(
            EAppRoutes.SIMPLYAS + "/admin"
          )
            ? undefined
            : this.contractStore.selectedContract.hash,
        });
        if (response.status === 200) {
          if (!this.didFirstRequest) {
            this.setDidFirstRequest(
              response.data.not_filtered_totals &&
                response.data.not_filtered_totals > 0
            );
          }
          totalDevices = response.data.totals;

          response.data.terminals.forEach(($0) => {
            const terminal = new TerminalModel($0);
            tempList.push(terminal);
          });
        }
      } catch (error) {
        window.debugError("error in requestTerminals", error);
      } finally {
        // only call callback if this is the last signed token
        if (this.ongoingRequests.requestTerminals === token) {
          callback(tempList, totalDevices);

          // clear ongoingRequests
          this.ongoingRequests.requestTerminals = null;
        }
      }
    },
    1000
  );

  getTerminalsList = (shouldIgnoreCache: boolean = false) => {
    this.setIsFetching(true);
    this.requestTerminals(
      this.requestTerminalsFilters,
      (list, totalDevices) => {
        this.setTerminalsList(list);
        this.setTotalDevices(totalDevices);

        this.setIsFetching(false);
      },
      shouldIgnoreCache || !this.didFirstRequest
    );

    if (!this.didFirstRequest) {
      this.requestTerminals.flush();
    }
  };

  getTerminalsListWithoutAnimation = (fireImmediately?: boolean = true) => {
    this.requestTerminals(
      this.requestTerminalsFilters,
      (list, totalDevices) => {
        this.setTerminalsList(list);
        this.setTotalDevices(totalDevices);

        this.setIsFetchingPaginate(false);
      },
      true
    );
    if (fireImmediately) {
      this.requestTerminals.flush();
    }
  };

  sendCommandToTerminal = (data) => {
    this.modboxAPIService.sendCommandToTerminal(data).then((response) => {
      if (response.status === 200) {
        if (response.data.message) {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
          });
        } else if (response.data.error) {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.error,
            color: "danger",
          });
        }
      }
    });
  };
  sendCommandToListOfTerminals = async (
    locationToken: string,
    command: string
  ) => {
    const response = await this.modboxAPIService.sendCommandToListOfTerminals(
      locationToken,
      command
    );
    if (response.status === 200) {
      if (response.data.message) {
        this.notificationsStore.addSnackbarNotification({
          message: response.data.message,
        });
      } else if (response.data.error) {
        this.notificationsStore.addSnackbarNotification({
          message: response.data.error,
          color: "danger",
        });
      }
    }
  };

  update = (updatedTerminalData) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .updateTerminal(updatedTerminalData)
        .then((response) => {
          this.getTerminalsListWithoutAnimation();
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          if (error && error.statusCode === 409) {
            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              color: "danger",
            });
          }
          reject(error);
        });
    });
  };

  toggleTerminalScreenRotate = (terminal: TerminalModel) => {
    const newValue = !terminal.isVerticalScreen;
    terminal.setIsVerticalScreen(newValue);
    this.modboxAPIService
      .updateTerminalScreenRotate({
        token: terminal.token,
        screenRotate: newValue,
      })
      .catch((error) => {
        window.debugError("error in updateTerminalScreenRotate", error);
      });
  };

  getTerminalFromListWithToken = (token: string) => {
    if (this.terminalsList.length > 0) {
      return this.terminalsList.find((terminal) => terminal.token === token);
    }
    return null;
  };

  requestMetricsTerminal = async (token: string) => {
    let list = [];
    try {
      const response = await this.modboxAPIService.requestMetricsTerminal(
        token
      );
      if (response.status === 200) {
        list = response.data;
      }
    } finally {
      return Promise.resolve(list);
    }
  };

  requestTerminal = async (
    token: string,
    metadata:
      | ERequestTerminalMetadata
      | ERequestTerminalMetadata[] = ERequestTerminalMetadata.basic
  ): Promise<TerminalModel> => {
    try {
      const response = await this.modboxAPIService.requestTerminal({
        token,
        metadata,
      });
      if (response.status === 200) {
        return new TerminalModel(response.data);
      }
      return null;
    } catch (error) {
      debugError("error in requestTerminal", error);
      return null;
    }
  };

  requestListOfTerminals = async (
    tokensList: string[],
    metadata:
      | ERequestTerminalMetadata
      | ERequestTerminalMetadata[] = ERequestTerminalMetadata.basic
  ): Promise<TerminalModel[]> => {
    let tokensToSearch = [...tokensList];
    let list: TerminalModel[] = [];

    tokensList.forEach((token) => {
      const device = this.getTerminalFromListWithToken(token);
      if (device) {
        list.push(device);
        tokensToSearch = tokensToSearch.filter(($0) => $0 !== token);
      }
    });

    if (tokensToSearch.length < 1) {
      return list;
    }

    try {
      const response = await this.modboxAPIService.requestListOfTerminals({
        tokensList: tokensToSearch,
        metadata,
      });
      if (response.status === 200) {
        list = response.data.map(($0: any) => new TerminalModel($0));
      }
    } catch (error) {
      debugError("error in requestListOfTerminals", error);
    } finally {
      return list;
    }
  };

  disconnectFromGroup = (token: string) => {
    return this.update({
      token,
      groupToken: null,
    });
  };

  requestTerminalsNotConnectedToGroup = async (): Promise<TerminalModel[]> => {
    let list: TerminalModel[] = [];
    try {
      const response = await this.modboxAPIService.requestTerminalsNotConnectedToGroup();
      if (response.status === 200 && response.data) {
        list = response.data.map((data: any) => new TerminalModel(data));
      }
      return null;
    } catch (error) {
      debugError("error in requestTerminalsNotConnectedToGroup", error);
    } finally {
      return list;
    }
  };
}

export default TerminalsStore;
