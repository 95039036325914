// @ts-nocheck
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

import _debounce from "lodash/debounce";

import { isProductionServer } from "../../utils/EnvUtils";

const developmentConfig = {
  apiKey: "AIzaSyCqe4GIa43ERZn_3IV08J8-CQ6vS2MmL9A",
  authDomain: "modboxdev.firebaseapp.com",
  databaseURL: "https://modboxdev.firebaseio.com",
  projectId: "modboxdev",
  storageBucket: "modboxdev.appspot.com",
  messagingSenderId: "1005663936089",
};

const productionConfig = {
  apiKey: "AIzaSyCXhO8tVYTM9zy8kAtjyB6Qr8v_b2EhFFE",
  authDomain: "modbox-auth.firebaseapp.com",
  databaseURL: "https://modbox-auth.firebaseio.com",
  projectId: "modbox-auth",
  storageBucket: "modbox-auth.appspot.com",
  messagingSenderId: "386140260021",
};

if (!firebase.apps.length) {
  firebase.initializeApp(
    isProductionServer ? productionConfig : developmentConfig
  );
}

export const auth = firebase.auth(); //the Firebase auth namespace
const database = firebase.database(); //the real-time database

export const storage = firebase.storage();

export const rootRef = database.ref();
export const connectedRef = database.ref(".info/connected");
export const analyticsRef = database.ref("analytics");
export const versionControlRef = database.ref("versionControl");
export const settingsRef = database.ref("settings/webApp");
export const i18nRef = database.ref("i18n/webApp");
export const affiliateUserRef = database.ref("affiliateUser");
export const authUsersRef = database.ref("authUsers");
export const usersRef = database.ref("users");
export const affiliatesRef = database.ref("affiliates");
export const contractsRef = database.ref("contracts");
export const ordersRef = database.ref("orders");
export const productsRef = database.ref("products");
export const chatRef = database.ref("chat");
export const chatMessagesRef = database.ref("chatMessages");
export const userChatsListRef = database.ref("userChatsList");
export const savedMetricsReportsRef = database.ref("savedMetricsReports");
export const serverTimestamp = firebase.database.ServerValue.TIMESTAMP;

export const deleteStorageFile = (fullPath, filename) => {
  return storage
    .ref()
    .child(`${fullPath}/${filename}`)
    .delete();
};

export const deleteStorageFolderContents = async (path) => {
  const ref = storage.ref(path);
  try {
    const folder = await ref.listAll();
    await Promise.all(
      folder.items.map(async (fileRef) => {
        await deleteStorageFile(ref.fullPath, fileRef.name);
        return Promise.resolve();
      })
    );
    await Promise.all(
      folder.prefixes.map(async (folderRef) => {
        await deleteStorageFolderContents(folderRef.fullPath);
        return Promise.resolve();
      })
    );
    return Promise.resolve();
  } catch (error) {
    window.debugError("deleteStorageFolderContents ERROR", error);
    return Promise.reject(error);
  }
};

export const uploadImageFile = async (
  { fullPath, base64, fileExtension, ...rest },
  callback
) => {
  const storageRef = storage.ref();
  const fileRef = storageRef.child(fullPath);

  const uploadTask = fileRef.putString(base64, "data_url", {
    contentType: `image/${fileExtension}`,
  });

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = snapshot.bytesTransferred / snapshot.totalBytes;
      callback({ status: "uploading", progress });
    },
    (error) => {
      callback({ status: "error", error });
      window.debugError("UPLOAD ERROR code", error.code);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;

        case "storage/canceled":
          // User canceled the upload
          break;

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
        callback({ status: "finished", downloadUrl, ...rest });
      });
    }
  );
};

export const saveComponentErrorLog = (log) => {
  database.ref("logs/reactComponentsWebApp").push({
    ...log,
    dateTimestamp: serverTimestamp,
  });
};

export const saveLog = (name, params = null) => {
  database.ref("logs/webApp").push({
    name,
    dateTimestamp: serverTimestamp,
    params,
  });
};

let firebaseScheduledUpdates = {};

const submitScheduledUpdates = _debounce(async () => {
  // save backup to clear the variable for now
  const backup = { ...firebaseScheduledUpdates };
  firebaseScheduledUpdates = {};

  try {
    await rootRef.update(backup);
  } catch (error) {
    window.debugError("error in submitScheduledUpdates", error);
    // return the backup to try again later...
    firebaseScheduledUpdates = {
      ...backup,
    };

    submitScheduledUpdates();
  }
}, 10000);

export const saveSupportOperatorLog = ({
  contractHash,
  userToken,
  apiMethod,
  requestData,
}: {
  contractHash: string;
  userToken: string;
  apiMethod: string;
  requestData: any;
}) => {
  if (!contractHash) {
    return;
  }
  const ref = database.ref(`logs/supportOperator/${contractHash}`).push();

  firebaseScheduledUpdates[
    `logs/supportOperator/${contractHash}/${ref.key}`
  ] = {
    userToken,
    requestAt: serverTimestamp,
    apiMethod,
    requestData,
  };

  submitScheduledUpdates();
};

const FirebaseStore = {
  database,
  serverTimestamp,
};

export default FirebaseStore;
