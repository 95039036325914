// @ts-nocheck

import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

import Tooltip from "../../components/Tooltip/Tooltip";

// UTILS
import { IMAGE_PATH } from "../../utils/EnvUtils";

// Style
import { successColor, dangerColor } from "../../assets/jss/app-js-styles";
const styles = {
  container: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: 64,
    width: 64,
    margin: 4,
    position: "relative",
  },
  title: {
    fontSize: 10,
  },
  icon: {
    color: successColor,
    position: "absolute",
    top: 5,
    right: 20,
    fontSize: 20,
    opacity: 0,
  },
  checkIcon: {
    color: successColor,
  },
  banIcon: {
    color: dangerColor,
  },
  isSelectedIcon: {
    opacity: 1,
  },
};

const GroupsPageDeviceItem = ({
  classes,
  token,
  name,
  tooltipTitle,
  iconType = "check",
  didSelect,
  isSelected = false,
}) => (
  <Tooltip
    title={tooltipTitle}
    disableFocusListener={!tooltipTitle}
    disableHoverListener={!tooltipTitle}
    disableTouchListener={!tooltipTitle}
  >
    <div
      className={classes.container}
      style={{
        background: `url("${IMAGE_PATH}/tv.png")`,
      }}
      onClick={() => didSelect({ token, name })}
    >
      <span className={classes.title}>{name}</span>
      <i
        className={classnames({
          fa: true,
          "fa-check": iconType === "check",
          "fa-ban": iconType === "ban",
          [classes.icon]: true,
          [classes.checkIcon]: iconType === "check",
          [classes.banIcon]: iconType === "ban",
          [classes.isSelectedIcon]: isSelected,
        })}
      />
    </div>
  </Tooltip>
);

export default withStyles(styles)(GroupsPageDeviceItem);
