import { Helmet } from "react-helmet";
import { withLocalized } from "../HOC";

import { ReactNode } from "react";
import { isSimplyasServer } from "../../utils/EnvUtils";

type PageTitleProps = {
  localizedString?: any;
  id?: string;
  children: ReactNode;
};

const PageTitle = ({ localizedString, id, children }: PageTitleProps) => (
  <Helmet>
    <title>
      {`${
        id
          ? localizedString({
              id,
              defaultString: children,
            })
          : children
      }${isSimplyasServer ? " - Modbox" : ""}`}
    </title>
  </Helmet>
);
export default withLocalized(PageTitle);
