// @ts-nocheck
import { observable, action } from "mobx";
import moment from "moment";

// Utils
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";

import I18nStore from "../I18nStore/I18nStore";
import WordPressAPIService from "../../services/WordPressAPIService";
import DiscourseAPIService from "../../services/DiscourseAPIService";

import PostModel from "../../Model/PostModel";

import PostPlaceholderImage from "../../assets/img/post-placeholder.jpg";

class InternalNewsStore {
  @observable isFetching = false;
  @observable posts: PostModel[] = [];

  i18nStore: I18nStore;
  wordPressAPIService: WordPressAPIService;
  discourseAPIService: DiscourseAPIService;

  constructor(
    i18nStore: I18nStore,
    wordPressAPIService: WordPressAPIService,
    discourseAPIService: DiscourseAPIService
  ) {
    this.i18nStore = i18nStore;
    this.wordPressAPIService = wordPressAPIService;
    this.discourseAPIService = discourseAPIService;
  }

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setPosts = (newList: PostModel[]) => {
    this.posts = newList;
  };

  getPosts = async () => {
    this.setIsFetching(true);
    const promises = [
      this.getDiscoursePosts(),
      this.getWordPressPosts(),
      this.getInternalPosts(),
    ];
    const arrayOfArrays = await Promise.all(promises);
    const posts = [].concat.apply([], arrayOfArrays);

    this.setPosts(posts.sort(dynamicSortByProperty("date", false)));

    this.setIsFetching(false);
  };

  fixWordPressPortugueseCategoryLink = (slug: string, link: string) => {
    const availablePortugueseCategories = [
      "cases",
      "dicas",
      "internet",
      "noticias",
      "televisores",
    ];
    if (availablePortugueseCategories.includes(slug)) {
      return link.replace("/modbox", "/modbox/pt");
    }
    return link;
  };

  getWordPressPosts = (): Promise<PostModel[]> => {
    if (this.i18nStore.currentLanguage !== "pt") return Promise.resolve([]);

    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.wordPressAPIService.getPosts();
        if (result.status === 200 && result.data) {
          let posts = [];
          await Promise.all(
            result.data.map(async (postData: any) => {
              const featuredMediaResult = await this.wordPressAPIService.getFeaturedMedia(
                postData.featured_media
              );
              const featuredMedia =
                featuredMediaResult.status === 200 && featuredMediaResult.data
                  ? featuredMediaResult.data
                  : null;
              const categoryResult = await this.wordPressAPIService.getCategory(
                postData.categories[0]
              );
              const category =
                categoryResult.status === 200 && categoryResult.data
                  ? categoryResult.data
                  : null;

              const post = new PostModel({
                id: postData.id,
                title: postData.title.rendered,
                date: moment(postData.date),
                excerpt: postData.excerpt.rendered,
                imageUrl: featuredMedia && featuredMedia.source_url,
                link: postData.link,
                category: {
                  id: category.slug,
                  title: category && category.name,
                  link:
                    category &&
                    this.fixWordPressPortugueseCategoryLink(
                      category.slug,
                      category.link
                    ),
                },
              });

              posts.push(post);
            })
          );

          resolve(posts);
        } else {
          resolve([]);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  getDiscoursePostsFromCategory = async ({
    categoryId,
    categoryName,
  }: {
    categoryId: string;
    categoryName: string;
  }): Promise<PostModel[]> => {
    let list: PostModel[] = [];
    try {
      const response: any = await this.discourseAPIService.getTopicsFromCategory(
        categoryId
      );
      if (response.status === 200) {
        const topics = response.data?.topic_list?.topics || [];

        topics.forEach((topic: any) => {
          // The following block of code is optional.
          // The purpose is to ignore the "About the X category" post
          if (topic.title.includes("Sobre a categoria")) {
            return;
          }

          list.push(
            new PostModel({
              id: `${topic.id}`,
              title: topic.title,
              date: moment(topic.created_at),
              excerpt: topic.excerpt,
              imageUrl: topic.image_url || PostPlaceholderImage,
              link: `https://be.simplyas.com/t/${topic.slug}/${topic.id}`,
              category: {
                id: categoryId,
                title: categoryName,
                link: `https://be.simplyas.com/c/${categoryId}`,
              },
            })
          );
        });
      }
    } catch (error) {
      window.debugError("error in getDiscoursePosts", error);
    }

    return list;
  };
  getDiscoursePosts = async (): Promise<PostModel[]> => {
    return [
      ...(await this.getDiscoursePostsFromCategory({
        categoryId: "novidades",
        categoryName: "Anúncios e novidades",
      })),
    ];
  };

  getInternalPosts = (): Promise<PostModel[]> => {
    return Promise.resolve([]);
  };
}

export default InternalNewsStore;
