// @ts-nocheck
import { Component, Fragment } from "react";
import { observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import { GridContainer } from "../components/Grid";
import { Card, CardBody } from "../components/Card";
import UsageGridWidget from "../components/Widgets/UsageGridWidget";

import SelectMediaContainer from "./informativeSetPlaylistPage/SelectMediaContainer";

@observer
class MediaLibraryPage extends Component {
  render() {
    return (
      <Fragment>
        <PageTitle id="Menu@mediaLibrary">Biblioteca de mídias</PageTitle>
        <Card style={{ marginTop: 0 }}>
          <CardBody style={{ padding: "0 16px" }}>
            <GridContainer>
              <UsageGridWidget type="storage" />
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <SelectMediaContainer justMediaLibrary>
            {({ body }) => <CardBody>{body}</CardBody>}
          </SelectMediaContainer>
        </Card>
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: MediaLibraryPage,
  pageAllowedRule: "informative",
});
