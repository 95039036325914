import { inject, observer } from "mobx-react";
import { FC } from "react";

import SearchBar from "../SearchBar/SearchBar";

import TerminalsStore from "../../store/TerminalsStore/TerminalsStore";

interface IDevicesSearchBarProps {
  terminalsStore?: TerminalsStore;
}

const DevicesSearchBar: FC<IDevicesSearchBarProps> = ({ terminalsStore }) => {
  return (
    <SearchBar
      value={terminalsStore.searchString}
      onChange={terminalsStore.setSearchString}
    />
  );
};

export default inject("terminalsStore")(observer(DevicesSearchBar));
