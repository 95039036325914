// @ts-nocheck

import Translate from "./Translate";

export const NameString = () => <Translate id="Common@name">Nome</Translate>;
export const EmailString = () => (
  <Translate id="Common@email">E-mail</Translate>
);
export const PhoneString = () => (
  <Translate id="Common@phone">Telefone</Translate>
);
export const IdiomString = () => (
  <Translate id="Common@idiom">Idioma</Translate>
);
export const TemperatureString = () => (
  <Translate id="Common@temperature">Temperatura</Translate>
);
export const MetricString = () => (
  <Translate id="Common@metric">Unidade de medida</Translate>
);
export const TimezoneString = () => (
  <Translate id="Common@timezone">Timezone</Translate>
);
export const CancelString = () => (
  <Translate id="Common@cancel">Cancelar</Translate>
);

export const DeleteString = () => (
  <Translate id="Common@delete">Apagar</Translate>
);

export const AddString = () => <Translate id="Common@add">Adicionar</Translate>;
export const EditString = () => <Translate id="Common@edit">Editar</Translate>;
export const DuplicateString = () => (
  <Translate id="Common@duplicate">Duplicar</Translate>
);

export const UpdateString = () => (
  <Translate id="Common@update">Atualizar</Translate>
);
export const SetupString = () => (
  <Translate id="Common@setup">Configurar</Translate>
);
export const ViewString = () => (
  <Translate id="Common@view">Visualizar</Translate>
);

export const CloseString = () => (
  <Translate id="Common@close">Fechar</Translate>
);

export const SaveString = () => <Translate id="Common@save">Salvar</Translate>;

export const NewString = () => <Translate id="Common@new">Novo</Translate>;

export const BackString = () => <Translate id="Common@back">Voltar</Translate>;
export const AllString = () => <Translate id="Common@all">Todos</Translate>;

export const ConfirmActionString = () => (
  <Translate id="Common@confirmAction">Confirmar esta ação</Translate>
);

export const RandomString = () => (
  <Translate id="Common@random">Randômico</Translate>
);
export const MuteString = () => <Translate id="Common@mute">Mudo</Translate>;
export const BackgroundColorString = () => (
  <Translate id="Common@backgroundColor">Cor do fundo</Translate>
);
export const FontColorString = () => (
  <Translate id="Common@fontColor">Cor da fonte</Translate>
);
export const TransparentString = () => (
  <Translate id="Common@transparent">Transparência</Translate>
);
export const IsOnString = () => <Translate id="Common@isOn">Ligado</Translate>;
export const IsActiveString = () => (
  <Translate id="Common@isActive">Habilitado</Translate>
);
export const IsInactiveString = () => (
  <Translate id="Common@isInactive">Inativo</Translate>
);
export const LabelHintString = () => (
  <Translate id="Common@labelHint">Para identificação no sistema.</Translate>
);

export const DeleteHintString = () => (
  <Translate id="Common@deleteHintMessage">
    Esta ação não poderá ser desfeita.
  </Translate>
);

export const SearchString = () => (
  <Translate id="Common@search">buscar...</Translate>
);

export const SecondsString = () => (
  <Translate id="Common@secondsString">segundos</Translate>
);

export const MinutesString = () => (
  <Translate id="Common@minutesString">minutos</Translate>
);
export const DaysString = () => (
  <Translate id="Common@daysString">dias</Translate>
);

export const FormErrorString = () => (
  <Translate id="Common@formErrorMessage">
    Existem erros no formulário. Por favor verifique.
  </Translate>
);

export const EmailErrorString = () => (
  <Translate id="Common@emailErrorMessage">
    O e-mail informado não é válido.
  </Translate>
);

export const PreviousString = () => (
  <Translate id="Common@previous">Anterior</Translate>
);
export const NextString = () => <Translate id="Common@next">Próximo</Translate>;
export const LoadingString = () => (
  <Translate id="Common@loading">Aguarde...</Translate>
);
export const NothingFoundString = () => (
  <Translate id="Common@nothingFound">Nada encontrado</Translate>
);
export const SelectPlaceholderString = () => (
  <Translate id="Common@selectPlaceholder">selecione...</Translate>
);
export const ToCopyActionString = () => (
  <Translate id="Common@toCopyActionString">Clique para copiar</Translate>
);
export const SubmitString = () => (
  <Translate id="Common@submit">Enviar</Translate>
);

export const SundayString = () => (
  <Translate id="Date@sunday">Domingo</Translate>
);
export const MondayString = () => (
  <Translate id="Date@monday">Segunda-feira</Translate>
);
export const TuesdayString = () => (
  <Translate id="Date@tuesday">Terça-feira</Translate>
);
export const WednesdayString = () => (
  <Translate id="Date@wednesday">Quarta-feira</Translate>
);
export const ThursdayString = () => (
  <Translate id="Date@thursday">Quinta-feira</Translate>
);
export const FridayString = () => (
  <Translate id="Date@friday">Sexta-feira</Translate>
);
export const SaturdayString = () => (
  <Translate id="Date@saturday">Sábado</Translate>
);
export const YesterdayString = () => (
  <Translate id="Date@yesterday">Ontem</Translate>
);

export const UnlimitedPlanString = () => (
  <Translate id="Common@unlimitedPlan">ilimitado</Translate>
);
export const CriticalStateMessage = () => (
  <Translate id="DeviceAlert@criticalStateMessage">Estado crítico</Translate>
);

export const IssueIdentifiedStateMessage = () => (
  <Translate id="DeviceAlert@issueIdentifiedStateMessage">
    Problema identificado
  </Translate>
);
export const NormalStateMessage = () => (
  <Translate id="DeviceAlert@normalStateMessage">Condição normal</Translate>
);

// Months
// export const JanuaryShortString = localizedString({
//   id: "Month@januaryShort",
//   defaultString: "Jan"
// });
// export const FebruaryShortString = localizedString({
//   id: "Month@februaryShort",
//   defaultString: "Fev"
// });
// export const MarchShortString = localizedString({
//   id: "Month@marchShort",
//   defaultString: "Mar"
// });
// export const AprilShortString = localizedString({
//   id: "Month@aprilShort",
//   defaultString: "Abr"
// });
// export const MayShortString = localizedString({
//   id: "Month@mayShort",
//   defaultString: "Mai"
// });
// export const JuneShortString = localizedString({
//   id: "Month@juneShort",
//   defaultString: "Jun"
// });
// export const JulyShortString = localizedString({
//   id: "Month@julyShort",
//   defaultString: "Jul"
// });
// export const AugustShortString = localizedString({
//   id: "Month@augustShort",
//   defaultString: "Ago"
// });
// export const SeptemberShortString = localizedString({
//   id: "Month@septemberShort",
//   defaultString: "Set"
// });
// export const OctoberShortString = localizedString({
//   id: "Month@octoberShort",
//   defaultString: "Out"
// });
// export const NovemberShortString = localizedString({
//   id: "Month@novemberShort",
//   defaultString: "Nov"
// });
// export const DecemberShortString = localizedString({
//   id: "Month@decemberShort",
//   defaultString: "Dez"
// });
// export const JanuaryString = localizedString({
//   id: "Month@januaryFull",
//   defaultString: "Janeiro"
// });
// export const FebruaryString = localizedString({
//   id: "Month@februaryFull",
//   defaultString: "Fevereiro"
// });
// export const MarchString = localizedString({
//   id: "Month@marchFull",
//   defaultString: "Março"
// });
// export const AprilString = localizedString({
//   id: "Month@aprilFull",
//   defaultString: "Abril"
// });
// export const MayString = localizedString({
//   id: "Month@mayFull",
//   defaultString: "Maio"
// });
// export const JuneString = localizedString({
//   id: "Month@juneFull",
//   defaultString: "Junho"
// });
// export const JulyString = localizedString({
//   id: "Month@julyFull",
//   defaultString: "Julho"
// });
// export const AugustString = localizedString({
//   id: "Month@augustFull",
//   defaultString: "Agosto"
// });
// export const SeptemberString = localizedString({
//   id: "Month@septemberFull",
//   defaultString: "Setembro"
// });
// export const OctoberString = localizedString({
//   id: "Month@octoberFull",
//   defaultString: "Outubro"
// });
// export const NovemberString = localizedString({
//   id: "Month@novemberFull",
//   defaultString: "Novembro"
// });
// export const DecemberString = localizedString({
//   id: "Month@decemberFull",
//   defaultString: "Dezembro"
// });

// export const MonthsOfTheYearShort = [
//   JanuaryShortString,
//   FebruaryShortString,
//   MarchShortString,
//   AprilShortString,
//   MayShortString,
//   JuneShortString,
//   JulyShortString,
//   AugustShortString,
//   SeptemberShortString,
//   OctoberShortString,
//   NovemberShortString,
//   DecemberShortString
// ];

// export const MonthsOfTheYearFull = [
//   JanuaryString,
//   FebruaryString,
//   MarchString,
//   AprilString,
//   MayString,
//   JuneString,
//   JulyString,
//   AugustString,
//   SeptemberString,
//   OctoberString,
//   NovemberString,
//   DecemberString
// ];
