// @ts-nocheck

import classnames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

const style = {
  grid: {
    padding: "0 15px !important",
  },
};

const GridItem = ({ classes, children, removePadding, className, ...rest }) => (
  <Grid
    item
    {...rest}
    className={classnames({
      [classes.grid]: !removePadding,
      [className]: className.length > 0,
    })}
  >
    {children}
  </Grid>
);

GridItem.defaultProps = {
  removePadding: false,
  className: "",
};

export default withStyles(style)(GridItem);
