class MediaModel {
  type: string;
  name: string;
  token: string;
  imageUrl: string;
  isProcessingFinished: boolean;
  isVertical: boolean;
  isCustom: boolean;
  isImage?: boolean;
  duration?: string;

  constructor(type: string, data: any, imageUrl: string) {
    this.type = type;
    this.name = data.name;
    this.token = data.token;
    this.isProcessingFinished =
      data.media_ready !== undefined ? data.media_ready : false;
    this.isVertical = data.isVertical !== undefined ? data.isVertical : false;
    this.isCustom = data.isCustom !== undefined ? data.isCustom : false;
    this.isImage = data.isImage !== undefined ? data.isImage : false;
    this.duration = data.duration !== undefined ? data.duration : false;
    this.setImageUrl(imageUrl);
  }

  setImageUrl(url: string) {
    this.imageUrl = url;
  }
}

export default MediaModel;
