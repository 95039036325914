// @ts-nocheck
import { action, observable } from "mobx";

import { SOUND_PATH } from "../../utils/EnvUtils";

export const FEEDBACK_SOUND = Object.freeze({
  Expand: "expand",
  Collapse: "collapse",
  Tab: "tab",
  Error: "error",
  Notification: "notification",
  NewMessage: "new-message",
  SendChatMessage: "send-chat-message",
});

class SoundStore {
  @observable
  feedbackSound = null;

  getSoundPath = (soundName) => {
    return `${SOUND_PATH}/${soundName}.m4a`;
  };

  @action
  playFeedbackSound = (soundName, retryIfBusy = true) => {
    // If already playing a sound set timer to try again later
    if (!this.feedbackSound) {
      this.feedbackSound = this.getSoundPath(soundName);
    } else if (retryIfBusy) {
      setTimeout(() => {
        this.playFeedbackSound(soundName);
      }, 100);
    }
  };

  @action
  removeFeedbackSound = () => {
    this.feedbackSound = null;
  };
}

export default SoundStore;
