// @ts-nocheck

export const FontAwesomeIcon = ({ icon }) => <i className={icon} />;

export const RssSquareIcon = () => <FontAwesomeIcon icon="fas fa-rss-square" />;
export const InstagramIcon = () => <FontAwesomeIcon icon="fab fa-instagram" />;
export const LinkIcon = () => <FontAwesomeIcon icon="fas fa-link" />;
export const BoltIcon = () => <FontAwesomeIcon icon="fas fa-bolt" />;
export const FireIcon = () => <FontAwesomeIcon icon="fas fa-fire" />;
export const SignalIcon = () => <FontAwesomeIcon icon="fas fa-signal" />;
export const TerminalIcon = () => <FontAwesomeIcon icon="fas fa-terminal" />;
export const ExclamationTriangleIcon = () => (
  <FontAwesomeIcon icon="fas fa-exclamation-triangle" />
);

export const SyncIcon = () => <FontAwesomeIcon icon="fas fa-sync" />;
export const PowerOffIcon = () => <FontAwesomeIcon icon="fas fa-power-off" />;
export const EraserIcon = () => <FontAwesomeIcon icon="fas fa-eraser" />;
export const DownloadIcon = () => <FontAwesomeIcon icon="fas fa-download" />;
export const UploadIcon = () => <FontAwesomeIcon icon="fas fa-upload" />;
export const GlobeIcon = () => <FontAwesomeIcon icon="fas fa-globe" />;
export const EllipsisHIcon = () => <FontAwesomeIcon icon="fas fa-ellipsis-h" />;
export const CheckIcon = () => <FontAwesomeIcon icon="fas fa-check" />;
export const BanIcon = () => <FontAwesomeIcon icon="fas fa-ban" />;
export const RemoveIcon = () => <FontAwesomeIcon icon="fas fa-minus" />;

export const HddIcon = () => <FontAwesomeIcon icon="fas fa-hdd" />;
export const FolderOpenIcon = () => (
  <FontAwesomeIcon icon="fas fa-folder-open" />
);
export const TVIcon = () => <FontAwesomeIcon icon="fas fa-tv" />;
export const WindowMaximizeIcon = () => (
  <FontAwesomeIcon icon="fas fa-window-maximize" />
);
export const PlugIcon = () => <FontAwesomeIcon icon="fas fa-plug" />;
export const LanguageIcon = () => <FontAwesomeIcon icon="fas fa-language" />;
export const CloudDownloadAltIcon = () => (
  <FontAwesomeIcon icon="fas fa-cloud-download-alt" />
);
export const SitemapIcon = () => <FontAwesomeIcon icon="fas fa-sitemap" />;
export const TerminalsIcon = () => <FontAwesomeIcon icon="fas fa-hdd" />;
export const PlaceIcon = () => <FontAwesomeIcon icon="fas fa-layer-group" />;
export const TvIcon = () => <FontAwesomeIcon icon="fas fa-tv" />;
export const BusinessCenterIcon = () => (
  <FontAwesomeIcon icon="fas fa-briefcase" />
);
export const CameraIcon = () => <FontAwesomeIcon icon="fas fa-video" />;
export const BullhornIcon = () => <FontAwesomeIcon icon="fas fa-bullhorn" />;
export const CodeIcon = () => <FontAwesomeIcon icon="fas fa-code" />;
export const SupportIcon = () => <FontAwesomeIcon icon="fas fa-comments" />;
export const AddIcon = () => <FontAwesomeIcon icon="fas fa-plus" />;
export const EditIcon = () => <FontAwesomeIcon icon="fas fa-pen" />;
export const WifiIcon = () => <FontAwesomeIcon icon="fas fa-wifi" />;
export const KeyIcon = () => <FontAwesomeIcon icon="fas fa-key" />;
export const VideoIcon = () => <FontAwesomeIcon icon="fas fa-video" />;
export const MapPinIcon = () => <FontAwesomeIcon icon="fas fa-map-pin" />;
export const SaveIcon = () => <FontAwesomeIcon icon="fas fa-save" />;
export const DeleteIcon = () => <FontAwesomeIcon icon="fas fa-trash-alt" />;
export const DuplicateIcon = () => <FontAwesomeIcon icon="fas fa-clone" />;
export const ChevronLeftIcon = () => (
  <FontAwesomeIcon icon="fas fa-chevron-left" />
);
export const WhatsAppIcon = () => <FontAwesomeIcon icon="fab fa-whatsapp" />;
export const EmailIcon = () => <FontAwesomeIcon icon="far fa-envelope" />;
