
import { inject } from "mobx-react";

import CopyToClipboard from "../../CopyToClipboard/CopyToClipboard";
import Translate from "../../I18n/Translate";
import DeviceInfoColumn from "./DeviceInfoColumn";

import TerminalModel from "../../../Model/TerminalModel";
import NotificationsStore from "../../../store/NotificationsStore/NotificationsStore";
import { textMaxLength } from "../../../utils/StringUtils";

interface DeviceAdvancedDataProps {
  notificationsStore?: NotificationsStore;
  renderListItem: any;
  terminal: TerminalModel;
}

const DeviceAdvancedData = ({
  notificationsStore,
  renderListItem,
  terminal,
}: DeviceAdvancedDataProps) => {
  const { servers, contract, hostname, token, isLending } = terminal;

  const Link = ({ label, children }: { label: string; children: string }) => (
    <a href={children} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  );

  const didCopy = () => {
    //@ts-ignore
    notificationsStore.addSnackbarNotification({
      message: (
        <Translate id="Developer@copySuccessMessage">
          Pronto! Agora cole com CTRL + V
        </Translate>
      ),
    });
  };

  return (
    <DeviceInfoColumn>
      {renderListItem({
        icon: "hand-holding-box",
        primary: isLending ? (
          <Translate id="Device@itIsALendingEquipment">
            É um equipamento em comodato
          </Translate>
        ) : (
          <Translate id="Device@notALendingEquipment">Não é comodato</Translate>
        ),
        secondary: (
          <Translate id="Device@isLendingLabel">Status comodato</Translate>
        ),
      })}

      {renderServersLinks()}

      {contract && (
        <CopyToClipboard text={contract.path} onCopy={didCopy}>
          {renderListItem({
            icon: "folder-open",
            primary: textMaxLength(contract.path, 20),
            secondary: (
              <Translate id="Device@contractPath">
                Caminho do contrato
              </Translate>
            ),
          })}
        </CopyToClipboard>
      )}

      {hostname && (
        <CopyToClipboard
          text={hostname.replace("modbox", "").replace(".local", "")}
          onCopy={didCopy}
        >
          {renderListItem({
            icon: "key",
            primary: textMaxLength(token, 20),
            secondary: (
              <Translate id="Device@tokenLabel">Token do dispositivo</Translate>
            ),
          })}
        </CopyToClipboard>
      )}
    </DeviceInfoColumn>
  );

  function renderServersLinks() {
    if (!servers) {
      return null;
    }

    return (
      <>
        {renderListItem({
          icon: "envelope",
          primary: (
            <Link label={servers.msg}>{`https://${servers.msg}:15672`}</Link>
          ),
          secondary: (
            <Translate id="Device@msgServer">Servidor de mensagens</Translate>
          ),
        })}
        {renderListItem({
          icon: "cube",
          primary: (
            <Link label={servers.file}>{`https://${servers.file}/gui`}</Link>
          ),
          secondary: (
            <Translate id="Device@fileServer">Servidor de arquivos</Translate>
          ),
        })}
        {renderListItem({
          icon: "cubes",
          primary: (
            <Link label={servers.update}>
              {`https://${servers.update}:8140/status/v1/services`}
            </Link>
          ),
          secondary: (
            <Translate id="Device@updateServer">
              Servidor de atualização
            </Translate>
          ),
        })}
      </>
    );
  }
};

export default inject("notificationsStore")(DeviceAdvancedData);
