// @ts-nocheck
import axios from "axios";

import { isProductionServer } from "../utils/EnvUtils";

const serverURL = {
  development: "https://us-central1-modboxdev.cloudfunctions.net/api/telegram/",
  production:
    "https://us-central1-modbox-auth.cloudfunctions.net/api/telegram/",
};

const BASE_URL = isProductionServer
  ? serverURL.production
  : serverURL.development;

const API_METHOD = Object.freeze({
  postButterflyAnalytics: "post-butterfly-analytics",
  postChatMessage: "post-chat-message",
});

class TelegramAPIService {
  post = (method, data = {}) => {
    const publicApi = axios.create({
      baseURL: BASE_URL,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return new Promise((resolve, reject) => {
      publicApi
        .post(method, data)
        .then((response) => resolve(response))
        .catch((error) => {
          window.debugError("Telegram API error", error);
          reject(error);
        });
    });
  };

  postChatMessageToThread = (message) => {
    return this.post(API_METHOD.postChatMessage, {
      content: message,
    });
  };

  postButterflyAnalyticsToThread = (content) => {
    return this.post(API_METHOD.postButterflyAnalytics, {
      content,
    });
  };
}

export default TelegramAPIService;
