//@ts-nocheck
import RadioGroup from "../../Form/RadioGroup";
import WizardUploadImage from "../Image";
import { WizarUploadRadioButtonBox, WizardUploadFor2Box } from "../style";
import { WizardUploadComponentProps } from "../type";

const WizardUploadFade = ({
  payload,
  updateRadio,
}: WizardUploadComponentProps) => {
  return (
    <WizardUploadFor2Box>
      <div className="title">
        <h3>Gostaria de adicionar Fade in e Fade out ao seu conteúdo?</h3>
      </div>
      <div className="content">
        <div className="left">
          <p>
            Dica: O efeito de Fade In/Fade Out é um efeito de transição que faz
            com que a imagem ou o vídeo desapareça gradualmente. Isso pode criar
            uma experiência visual mais suave para o espectador.
          </p>
          <WizarUploadRadioButtonBox>
            <RadioGroup
              name="isFade"
              value={payload?.isFade}
              onChange={updateRadio}
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </WizarUploadRadioButtonBox>
        </div>

        <div className="right">
          <WizardUploadImage payload={payload} />
        </div>
      </div>
    </WizardUploadFor2Box>
  );
};

export default WizardUploadFade;
