export enum ENotificationColor {
  primary = "primary",
  success = "success",
  danger = "danger",
  info = "info",
  warning = "warning",
}

export enum ENotificationCategory {
  advertising = "advertising",
  device = "device",
  informative = "informative",
  connectivity = "connectivity",
}

export interface INotificationModel {
  token: string;
  contractHash?: string;
  title: string;
  subtitle?: string;
  isRead: boolean;
  isEnabled: boolean;
  isShelved: boolean;
  createdAt: number;
  deliverAt: number;
  category: ENotificationCategory;
  color: ENotificationColor;
  data?: any;
}

class NotificationModel implements INotificationModel {
  token: string;
  contractHash?: string;
  category: ENotificationCategory;
  title: string;
  subtitle?: string;
  isRead: boolean;
  isEnabled: boolean;
  isShelved: boolean;
  createdAt: number;
  deliverAt: number;
  color: ENotificationColor;
  data?: any;

  constructor(jsonData: any) {
    this.token = jsonData.token;
    this.contractHash = jsonData.contract_hash;
    this.category = ENotificationCategory[jsonData.category];
    this.title = jsonData.title;
    this.subtitle = jsonData.subtitle;
    this.isRead = jsonData.is_read ?? false;
    this.isEnabled = jsonData.is_enabled ?? false;
    this.isShelved = jsonData.is_shelved ?? false;
    this.createdAt = jsonData.created_at;
    this.deliverAt = jsonData.deliver_at;
    this.color = ENotificationColor[jsonData.color];
    this.data = jsonData.data;
  }
}

export default NotificationModel;
