// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import { Component, Fragment } from "react";
import ConfirmModal from "./ConfirmModal";
import Modal from "./Modal";
import ModalTerminalDetails from "./ModalTerminalDetails";

// UTILS
import { IMAGE_PATH } from "../../utils/EnvUtils";

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Button } from "../Button";
import LoadingView from "../LoadingView/LoadingView";

import AddDeviceToGroupContainer from "../../pages/groupsPage/AddDeviceToGroupContainer";

import { GridContainer, GridItem } from "../Grid";

// Translate
import { CancelString, SaveString } from "../I18n/CommonStrings";
import Plural from "../I18n/Plural";
import Translate from "../I18n/Translate";

import GroupModel from "../../Model/GroupModel";
import TerminalModel from "../../Model/TerminalModel";
import AuthStore from "../../store/AuthStore/AuthStore";
import GroupsStore from "../../store/GroupsStore/GroupsStore";
import NotificationsStore from "../../store/NotificationsStore/NotificationsStore";
import RoutingStore from "../../store/RoutingStore/RoutingStore";
import TerminalsStore from "../../store/TerminalsStore/TerminalsStore";
import { ERequestTerminalMetadata } from "../../types/Terminal";

// Utils

// Style
import { grayColor } from "../../assets/jss/app-js-styles";
const styles = {
  media: {
    backgroundColor: "#000",
    backgroundSize: "contain",
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginBottom: 20,
    filter: "grayscale(100%)",
  },
  listItemIcon: {
    width: 26,
    marginRight: 0,
    textAlign: "center",
  },
};

interface IModalGroupDetailsProps {
  classes?: any;
  isOpen: boolean;
  group: GroupModel;
  didCloseModal: () => void;
  didWantToEdit: () => void;
  didWantToDelete: () => void;
}
interface IModalGroupDetailsState {
  isOpenTerminalModal: boolean;
  selectedTerminal: null | TerminalModel;
  isAddingTerminalsToGroup: boolean;
  isManagingDevices: boolean;
  didRequestCriticalCommand: string | null;
  isFetchingDevice: string | null;
  selectedDevicesToDelete: string[];
}

interface InjectedProps extends IModalGroupDetailsProps {
  authStore: AuthStore;
  groupsStore: GroupsStore;
  terminalsStore: TerminalsStore;
  routingStore: RoutingStore;
  notificationsStore: NotificationsStore;
}

@inject(
  "authStore",
  "groupsStore",
  "terminalsStore",
  "routingStore",
  "notificationsStore"
)
@observer
class ModalGroupDetails extends Component<
  IModalGroupDetailsProps,
  IModalGroupDetailsState
> {
  deleteDevicesHintNotificationToken: string | null;

  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  state = {
    isOpenTerminalModal: false,
    selectedTerminal: null,
    isAddingTerminalsToGroup: false,
    isManagingDevices: false,
    didRequestCriticalCommand: null,
    isFetchingDevice: null,
    selectedDevicesToDelete: [],
  };

  componentDidMount = () => {
    const { routingStore } = this.injected;
    if (routingStore.location.pathname.includes("add-devices")) {
      this.didWantToAddTerminal();
    } else if (routingStore.location.pathname.includes("/device/")) {
      this.didWantToOpenDeviceModal(routingStore.lastSubPath);
    }
  };

  componentWillUnmount() {
    this.deleteDevicesHintNotificationToken = null;
  }

  clearDeleteDevicesHintNotification = () => {
    if (this.deleteDevicesHintNotificationToken) {
      this.injected.notificationsStore.removeSnackbarNotification(
        this.deleteDevicesHintNotificationToken
      );
    }
  };

  didWantToRemoveDevices = () => {
    this.setState(
      {
        isManagingDevices: true,
      },
      () => {
        this.deleteDevicesHintNotificationToken = this.injected.notificationsStore.addSnackbarNotification(
          {
            message: (
              <Translate id="Group@deleteDeviceHint">
                Para remover dispositivos do grupo basta seleciona-los e então
                Salvar.
              </Translate>
            ),
            color: "danger",
            timeout: 0,
          }
        );
      }
    );
  };

  didCancelManagingDevices = () => {
    this.setState(
      {
        isManagingDevices: false,
        selectedDevicesToDelete: [],
      },
      () => {
        this.clearDeleteDevicesHintNotification();
      }
    );
  };

  deviceIsSelectedToDelete = (deviceToken: string) => {
    return this.state.selectedDevicesToDelete.includes(deviceToken);
  };

  didFinishManagingDevices = () => {
    this.clearDeleteDevicesHintNotification();
    this.injected.groupsStore.updateGroup({
      ...this.props.group,
      terminalsList: this.props.group.terminalsList.filter(
        (terminal) => !this.deviceIsSelectedToDelete(terminal.token)
      ),
    });

    this.props.didCloseModal();
  };

  didSelectTerminal = async (terminalToken: string) => {
    if (this.state.isManagingDevices) {
      if (this.deviceIsSelectedToDelete(terminalToken)) {
        this.setState({
          selectedDevicesToDelete: this.state.selectedDevicesToDelete.filter(
            (deviceToken) => deviceToken !== terminalToken
          ),
        });
      } else {
        this.setState({
          selectedDevicesToDelete: [
            ...this.state.selectedDevicesToDelete,
            terminalToken,
          ],
        });
      }
    } else {
      this.didWantToOpenDeviceModal(terminalToken);
    }
  };

  didWantToOpenDeviceModal = async (token: string) => {
    this.setState(
      {
        isFetchingDevice: token,
      },
      async () => {
        const selectedTerminal = await this.injected.terminalsStore.requestTerminal(
          token,
          ERequestTerminalMetadata.full
        );

        if (selectedTerminal) {
          this.setState({
            isOpenTerminalModal: true,
            isFetchingDevice: null,
            selectedTerminal,
          });
          const { routingStore } = this.injected;
          if (!routingStore.location.pathname.includes("/device/")) {
            routingStore.pushSubPath(`device/${token}`);
          }
        } else {
          this.setState({
            isFetchingDevice: null,
          });
          this.injected.notificationsStore.addSnackbarNotification({
            message: (
              <Translate id="Device@loadingDeviceDataErrorMessage">
                Erro inesperado, não foi possível exibir informações do
                dispositivo neste momento.
              </Translate>
            ),
            color: "warning",
          });
        }
      }
    );
  };

  didCloseTerminalModal = () => {
    this.setState(
      {
        isOpenTerminalModal: false,
      },
      () => {
        setTimeout(() => {
          this.setState((prevState) => {
            this.injected.routingStore.removeSubPath(
              prevState.selectedTerminal ? 2 : 1
            );
            return {
              selectedTerminal: null,
              isAddingTerminalsToGroup: false,
            };
          });
        }, 200);
      }
    );
  };

  didWantToAddTerminal = () => {
    this.setState(
      {
        isOpenTerminalModal: true,
        isAddingTerminalsToGroup: true,
      },
      () => {
        const { routingStore } = this.injected;
        if (!routingStore.location.pathname.includes("add-devices")) {
          routingStore.pushSubPath("add-devices");
        }
      }
    );
  };

  didFinishAddingDevices = () => {
    this.props.didCloseModal();
  };

  sendCommandToAllDevices = async (command: string) => {
    this.props.didCloseModal();
    const tokensList = this.props.group.terminalsList.map(
      ({ token }: TerminalModel) => token
    );
    await this.injected.terminalsStore.sendCommandToListOfTerminals(
      tokensList,
      command
    );
    this.injected.groupsStore.getGroupsList();
  };

  requestCriticalCommand = (command: string) => () =>
    this.setState({
      didRequestCriticalCommand: command,
    });
  didCancelConfirmation = () =>
    this.setState({
      didRequestCriticalCommand: null,
    });

  didCloseModal = () => {
    this.clearDeleteDevicesHintNotification();
    this.props.didCloseModal();
  };

  renderTerminalButton = ({
    token,
    color,
    name,
    category,
    isActive,
  }: TerminalModel) => {
    const { isManagingDevices, isFetchingDevice } = this.state;

    return (
      <Button
        key={token}
        color={this.deviceIsSelectedToDelete(token) ? "danger" : "transparent"}
        style={{
          color: !isActive ? grayColor : isManagingDevices ? "#333" : color,
        }}
        isLoading={isFetchingDevice === token}
        onClick={() => this.didSelectTerminal(token)}
      >
        <i
          className={`fas fa-${
            !isActive ? "ban" : category === "standard" ? "hdd" : "server"
          }`}
          style={{ marginRight: 16 }}
        />{" "}
        {name}
      </Button>
    );
  };

  renderIcon = (iconName: string) => <i className={`fas fa-${iconName}`} />;

  renderListItem = ({
    button,
    divider,
    disabled,
    onClick,
    icon,
    primary,
    secondary,
    primaryTypographyProps,
  }) => (
    <ListItem
      button={button}
      divider={divider}
      disabled={disabled}
      dense
      onClick={onClick}
    >
      <ListItemIcon classes={{ root: this.props.classes.listItemIcon }}>
        {this.renderIcon(icon)}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={primaryTypographyProps}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );

  render() {
    const {
      classes,
      isOpen,
      group,
      didWantToEdit,
      didWantToDelete,
    } = this.props;
    const {
      isOpenTerminalModal,
      selectedTerminal,
      isAddingTerminalsToGroup,
      isManagingDevices,
      didRequestCriticalCommand,
    } = this.state;

    const { terminalsList } = group;
    const devicesQuantity = terminalsList.length;

    return (
      <Fragment>
        {didRequestCriticalCommand && (
          <ConfirmModal
            isOpen
            title={
              <Translate id="Common@confirmAction">
                Confirmar esta ação
              </Translate>
            }
            didCancel={this.didCancelConfirmation}
            didConfirm={() =>
              this.sendCommandToAllDevices(didRequestCriticalCommand)
            }
          >
            <Translate id="Device@confirmSendCommandMessage">
              Atenção! Tem certeza que deseja seguir com esse comando?
            </Translate>
          </ConfirmModal>
        )}
        {selectedTerminal && (
          <ModalTerminalDetails
            isOpen={isOpenTerminalModal}
            didCloseModal={this.didCloseTerminalModal}
            terminal={selectedTerminal}
          />
        )}
        {isAddingTerminalsToGroup && (
          <Modal
            isOpen={isOpenTerminalModal}
            modalTitle={
              <Translate id="Group@addDevicesButton">
                Adicionar dispositivos
              </Translate>
            }
            didCloseModal={this.didCloseTerminalModal}
          >
            <AddDeviceToGroupContainer
              group={group}
              didCancel={this.didCloseTerminalModal}
              didSave={this.didFinishAddingDevices}
            />
          </Modal>
        )}
        <Modal
          isOpen={isOpen}
          didCloseModal={this.didCloseModal}
          modalTitle={
            group && (
              <Plural
                id="Group@titleForModal"
                value={devicesQuantity}
                variables={{ groupName: group.name }}
                zero="$groupName"
                one="$groupName - # dispositivo"
                other="$groupName - # dispositivos"
              />
            )
          }
        >
          {group ? (
            <GridContainer>
              <GridItem xs={12} sm={6} style={{ marginBottom: 10 }}>
                <div
                  className={classes.media}
                  style={{
                    backgroundImage: `url("${IMAGE_PATH}/${group.icon}.jpeg")`,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <List>
                  {isManagingDevices ? (
                    <Fragment>
                      {this.renderListItem({
                        button: true,
                        divider: true,
                        onClick: this.didCancelManagingDevices,
                        icon: "chevron-left",
                        primary: <CancelString />,
                      })}
                      {this.renderListItem({
                        button: true,
                        divider: true,
                        disabled: this.state.selectedDevicesToDelete < 1,
                        onClick: this.didFinishManagingDevices,
                        icon: "save",
                        primary: <SaveString />,
                      })}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {this.renderListItem({
                        button: true,
                        divider: true,
                        onClick: this.didWantToAddTerminal,
                        icon: "plus-circle",
                        primary: (
                          <Translate id="Group@addDevicesButton">
                            Adicionar dispositivos
                          </Translate>
                        ),
                      })}
                      {this.renderListItem({
                        button: true,
                        divider: true,
                        disabled: devicesQuantity < 1,
                        onClick: this.didWantToRemoveDevices,
                        icon: "minus-circle",
                        primary: (
                          <Translate id="Group@removeDevicesButton">
                            Remover dispositivos
                          </Translate>
                        ),
                      })}
                      {this.renderListItem({
                        button: true,
                        divider: true,
                        onClick: didWantToEdit,
                        icon: "edit",
                        primary: (
                          <Translate id="Group@editLabelButton">
                            Editar grupo
                          </Translate>
                        ),
                      })}
                      {!this.props.group.isDefault &&
                        this.renderListItem({
                          button: true,
                          divider: true,
                          onClick: didWantToDelete,
                          icon: "trash-alt",
                          primary: (
                            <Translate id="Group@deleteLabelButton">
                              Apagar grupo
                            </Translate>
                          ),
                        })}
                    </Fragment>
                  )}
                </List>
              </GridItem>
            </GridContainer>
          ) : (
            <LoadingView />
          )}
          {devicesQuantity > 0 && (
            <Fragment>
              <Divider />
              <GridContainer>
                <GridItem xs={12} style={{ textAlign: "center" }}>
                  {terminalsList.map((terminal: TerminalModel) =>
                    this.renderTerminalButton(terminal)
                  )}
                </GridItem>
              </GridContainer>
            </Fragment>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ModalGroupDetails);
