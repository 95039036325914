//@ts-nocheck
import { when } from "mobx";
import { inject, observer } from "mobx-react";
import { Component, Fragment } from "react";

import { SimpleCard } from "../../components/Card";
import { GridContainer, GridItem } from "../../components/Grid";
import SearchBar from "../../components/SearchBar/SearchBar";

import LoadingView from "../../components/LoadingView/LoadingView";
import Modal from "../../components/Modal/Modal";
import ModalGroupDetails from "../../components/Modal/ModalGroupDetails";

import InfoLabel from "../../components/Label/InfoLabel";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import Paginate from "../../components/Paginate/Paginate";

import AddGroupButton from "./AddGroupButton";
import GroupForm from "./GroupForm";
import GroupItemContainer from "./GroupItemContainer";

import Plural from "../../components/I18n/Plural";
import Translate from "../../components/I18n/Translate";

import GroupModel from "../../Model/GroupModel";
import AlfredStore from "../../store/AlfredStore/AlfredStore";
import GroupsStore from "../../store/GroupsStore/GroupsStore";
import LocationStore from "../../store/LocationStore/LocationStore";
import RoutingStore from "../../store/RoutingStore/RoutingStore";

import { EAppRoutes } from "../../routes/AppRoutes";

interface IListGroupsContainerProps {}

enum ModalType {
  add = "add",
  show = "show",
  edit = "edit",
  delete = "delete",
}

interface IListGroupsContainerState {
  activeModalType?: ModalType | null;
  selectedGroupToken?: string | null;
  selectedGroup?: GroupModel | null;
  isOpenModal?: boolean;
}

interface InjectedProps extends IListGroupsContainerProps {
  alfredStore: AlfredStore;
  groupsStore: GroupsStore;
  locationStore: LocationStore;
  routingStore: RoutingStore;
}

@inject("alfredStore", "locationStore", "groupsStore", "routingStore")
@observer
class ListGroupsContainer extends Component<
  IListGroupsContainerProps,
  IListGroupsContainerState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  state = {
    activeModalType: null,
    selectedGroupToken: null,
    selectedGroup: null,
    isOpenModal: false,
  };

  componentDidMount() {
    const {
      alfredStore,
      locationStore,
      groupsStore,
      routingStore,
    } = this.injected;

    locationStore.getLocationsList();
    groupsStore.getGroupsList();

    setTimeout(() => {
      alfredStore.addActions({
        id: "groupsActions",
        label: "Grupo",
        options: [
          {
            id: "add",
            title: "Adicionar",
            subtitle: "Crie um novo grupo",
            icon: "plus",
            callback: this.didWantToAddNewGroup,
          },
        ],
      });
    }, 1000);

    const [token, modalType] = routingStore.location.pathname
      .replace(`${EAppRoutes.GROUPS}/`, "")
      .replace(EAppRoutes.GROUPS, "")
      .split("/");

    if (token && modalType) {
      when(
        () => !groupsStore.isFetching,
        () => this.didSelectGroup(token, ModalType[modalType])
      );
    } else if (token === "add") {
      this.setState({
        activeModalType: ModalType.add,
        isOpenModal: true,
      });
    }
  }

  componentDidUpdate(
    prevProps: IListGroupsContainerProps,
    prevState: IListGroupsContainerState
  ) {
    if (
      (prevState.selectedGroupToken === this.state.selectedGroupToken &&
        prevState.activeModalType === this.state.activeModalType) ||
      this.injected.routingStore.location.pathname.includes(
        this.state.selectedGroupToken
      )
    ) {
      return;
    }
    const { selectedGroupToken, activeModalType } = this.state;

    let url = EAppRoutes.GROUPS + "/";
    if (selectedGroupToken) {
      url += `${selectedGroupToken}/${activeModalType}`;
    } else if (activeModalType) {
      url += activeModalType;
    }

    this.injected.routingStore.push(url);
  }

  componentWillUnmount() {
    this.injected.alfredStore.removeActions("groupsActions");
  }

  didWantToAddNewGroup = () => {
    this.setState({
      activeModalType: ModalType.add,
      isOpenModal: true,
    });
  };

  didSelectGroup = (
    selectedGroupToken: string,
    activeModalType = ModalType.show
  ) => {
    const selectedGroup = this.injected.groupsStore.getWithToken(
      selectedGroupToken
    );
    if (selectedGroup) {
      this.setState({
        selectedGroupToken,
        selectedGroup,
        activeModalType,
        isOpenModal: true,
      });
    } else {
      this.injected.routingStore.push(EAppRoutes.GROUPS);
    }
  };

  didWantToEditGroup = () => {
    this.closeModalAndSetNewState({
      activeModalType: ModalType.edit,
      isOpenModal: true,
    });
  };

  didWantToDeleteGroup = () => {
    this.closeModalAndSetNewState({
      activeModalType: ModalType.delete,
      isOpenModal: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      activeModalType: null,
      selectedGroupToken: null,
      selectedGroup: null,
      isOpenModal: false,
    });
  };

  didDeleteGroup = () => {
    this.injected.groupsStore.deleteGroup(this.state.selectedGroupToken);
    this.didCancelDeleteAction();
  };

  closeModalAndSetNewState = (newState: IListGroupsContainerState) => {
    this.setState(
      {
        isOpenModal: false,
      },
      () => setTimeout(() => this.setState(newState), 200)
    );
  };

  didCloseModal = () => {
    this.closeModalAndSetNewState({
      activeModalType: null,
      selectedGroupToken: null,
      selectedGroup: null,
    });
  };

  render() {
    if (this.injected.groupsStore.isFetching) {
      return <LoadingView />;
    }

    const {
      activeModalType,
      selectedGroupToken,
      selectedGroup,
      isOpenModal,
    } = this.state;
    const { groupsStore } = this.injected;
    const { filteredGroupsList: groups, groupsList } = groupsStore;

    return (
      <Fragment>
        {selectedGroup && activeModalType === ModalType.delete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteGroup}
            didCancel={this.didCancelDeleteAction}
            deleteLabel={
              <Translate id="GroupsPage@confirmDeleteButton">
                Sim, apagar grupo!
              </Translate>
            }
            message={
              <Translate
                id="GroupsPage@deleteQuestion"
                // @ts-ignore
                variables={{ groupName: selectedGroup.name }}
              >
                Você realmente quer apagar <strong>$groupName</strong>?
              </Translate>
            }
          />
        )}
        {[ModalType.add, ModalType.edit].includes(activeModalType) && (
          <Modal
            isOpen={isOpenModal}
            didCloseModal={this.didCloseModal}
            maxWidth="sm"
            modalTitle={
              activeModalType === ModalType.add ? (
                <Translate id="GroupsPage@addModalTitle">
                  Adicionar grupo
                </Translate>
              ) : (
                <Translate id="GroupsPage@editModalTitle">
                  Editar grupo
                </Translate>
              )
            }
          >
            {activeModalType && (
              <GroupForm
                // @ts-ignore
                group={selectedGroup}
                didCancel={this.didCloseModal}
                didSave={this.didCloseModal}
                didWantToDelete={this.didWantToDeleteGroup}
              />
            )}
          </Modal>
        )}
        {selectedGroupToken && activeModalType === ModalType.show && (
          <ModalGroupDetails
            group={selectedGroup}
            isOpen={isOpenModal}
            didWantToEdit={this.didWantToEditGroup}
            didWantToDelete={this.didWantToDeleteGroup}
            didCloseModal={this.didCloseModal}
          />
        )}
        <GridContainer>
          {groupsList.length > 5 && (
            <GridItem xs={12}>
              <SimpleCard cardStyle={{ marginTop: 0 }}>
                <SearchBar
                  label="pesquise pelo nome do grupo ou da localidade"
                  value={groupsStore.searchString}
                  onChange={groupsStore.setSearchString}
                />
              </SimpleCard>
            </GridItem>
          )}
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={12} sm={4} md={3} lg={3}>
                <AddGroupButton onClick={this.didWantToAddNewGroup} />
              </GridItem>
              <Paginate itemsList={groups} itemsPerPage={11}>
                {({ slicedList }) =>
                  slicedList.map((group: GroupModel) => (
                    <GridItem key={group.token} xs={12} sm={4} md={3} lg={3}>
                      <GroupItemContainer
                        group={group}
                        didWantToOpenGroupModal={this.didSelectGroup}
                      />
                    </GridItem>
                  ))
                }
              </Paginate>
            </GridContainer>
          </GridItem>
          {groupsList.length > 5 && (
            <GridItem xs={12} style={{ textAlign: "center", marginTop: 30 }}>
              <InfoLabel hideIcon>
                <Plural
                  // @ts-ignore
                  id="GroupsPage@groupsQtyLabel"
                  value={groups.length}
                  zero="Nenhum grupo encontrado"
                  one="# grupo"
                  other="# grupos"
                />
              </InfoLabel>
            </GridItem>
          )}
        </GridContainer>
      </Fragment>
    );
  }
}

export default ListGroupsContainer;
