// @ts-nocheck
import { Component, Fragment } from "react";
import { reaction } from "mobx";

import { inject, observer } from "mobx-react";

import { withLocalized } from "../HOC";

import { GridContainer, GridItem } from "../Grid";
import { SimpleCard } from "../Card";
import UsageGridWidget from "../Widgets/UsageGridWidget";
import { InputSelect } from "../Form";
import LoadingView from "../LoadingView/LoadingView";
import Button from "../Button/Button";

import UpdateDevicesProgressCounter from "./UpdateDevicesProgressCounter";
import DevicesGridView from "./DevicesGridView";
import DevicesTableView from "./DevicesTableView";
import DevicesSearchBar from "./DevicesSearchBar";

import Modal from "../Modal/Modal";
import ModalTerminalDetails from "../Modal/ModalTerminalDetails";

import InfoLabel from "../Label/InfoLabel";
import Heading from "../Heading/Heading";
import HowToActivateDevice from "../HowTo/HowToActivateDevice";

import Translate from "../I18n/Translate";
import Plural from "../I18n/Plural";

import {
  ETerminalConnectionStatus,
  ETerminalStatus,
  ETerminalAlertType,
  ERequestTerminalMetadata,
} from "../../types/Terminal";

import { EDisplayMode } from "../../store/SettingsStore/SettingsStore";
import TerminalModel from "../../Model/TerminalModel";
import AuthStore from "../../store/AuthStore/AuthStore";
import ContractStore from "../../store/ContractStore/ContractStore";
import TerminalsStore from "../../store/TerminalsStore/TerminalsStore";
import RoutingStore from "../../store/RoutingStore/RoutingStore";
import SettingsStore from "../../store/SettingsStore/SettingsStore";

import { EAppRoutes } from "../../routes/AppRoutes";

interface IDevicesListPageContainerProps {
  localizedString?: any;
  group?: any;
  isSelectorView?: boolean;
  ignoredTokens?: string[];
  selectedTokens?: string[];
  didSelectDevice?: (terminal: TerminalModel) => void;
}

interface InjectedProps extends IDevicesListPageContainerProps {
  authStore: AuthStore;
  contractStore: ContractStore;
  terminalsStore: TerminalsStore;
  routingStore: RoutingStore;
  settingsStore: SettingsStore;
}

interface IDevicesListPageContainerState {
  isOpenModal: boolean;
  selectedTerminal: TerminalModel | null;
}

@withLocalized
@inject(
  "authStore",
  "contractStore",
  "terminalsStore",
  "routingStore",
  "settingsStore"
)
@observer
class DevicesListPageContainer extends Component<
  IDevicesListPageContainerProps,
  IDevicesListPageContainerState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  _isMounted: boolean = false;

  statusOptions: Array<{ label: any; value: ETerminalStatus }>;
  connectionStatusOptions: Array<{
    label: any;
    value: ETerminalConnectionStatus;
  }>;
  alertOptions: Array<{
    label: any;
    value: ETerminalAlertType;
  }>;

  state = {
    isOpenModal: false,
    selectedTerminal: null,
  };

  constructor(props: IDevicesListPageContainerProps) {
    super(props);

    const { localizedString } = props;

    this.statusOptions = [
      {
        label: localizedString({ id: "Common@all", defaultString: "Todos" }),
        value: ETerminalStatus.all,
      },
      {
        label: localizedString({
          id: "Device@enabledDevicesFilterLabel",
          defaultString: "Habilitados",
        }),
        value: ETerminalStatus.enabled,
      },
      {
        label: localizedString({
          id: "Device@disabledDevicesFilterLabel",
          defaultString: "Desabilitados",
        }),
        value: ETerminalStatus.disabled,
      },
    ];
    this.connectionStatusOptions = [
      {
        label: localizedString({ id: "Common@all", defaultString: "Todos" }),
        value: ETerminalConnectionStatus.all,
      },
      {
        label: localizedString({
          id: "Dashboard@connectedDevicesLabel",
          defaultString: "Ligados",
        }),
        value: ETerminalConnectionStatus.online,
      },
      {
        label: localizedString({
          id: "Dashboard@offlineDevicesLabel",
          defaultString: "Desligados",
        }),
        value: ETerminalConnectionStatus.offline,
      },
    ];

    this.alertOptions = [
      {
        label: localizedString({
          id: "Device@criticalAlertOption",
          defaultString: "CRÍTICO",
        }),
        value: ETerminalAlertType.critical,
      },
      {
        label: localizedString({
          id: "Device@powerSupplyInfo",
          defaultString: "Energia",
        }),
        value: ETerminalAlertType.energy,
      },
      {
        label: localizedString({
          id: "Common@temperature",
          defaultString: "Temperatura",
        }),
        value: ETerminalAlertType.temperature,
      },
      {
        label: localizedString({
          id: "Device@diskSpeedInfo",
          defaultString: "Velocidade do disco",
        }),
        value: ETerminalAlertType.diskSpeed,
      },
      {
        label: "Wi-Fi",
        value: ETerminalAlertType.wifi,
      },
    ];
  }

  componentDidMount() {
    this._isMounted = true;

    const { terminalsStore } = this.injected;
    terminalsStore.subscribe(this);

    if (!this.props.isSelectorView || terminalsStore.terminalsList.length < 1) {
      terminalsStore.getTerminalsList(true);
    }

    reaction(
      () =>
        this.injected.routingStore.pathname.includes(
          `${EAppRoutes.DEVICES}/`
        ) && this.selectedToken !== null,
      (hasSelectedToken: boolean) => {
        if (hasSelectedToken) {
          this.requestSelectedDevice();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;

    this.injected.terminalsStore.unsubscribe(this);
  }

  get selectedToken(): string {
    const [token] = this.injected.routingStore.pathname
      .replace(`${EAppRoutes.DEVICES}/`, "")
      .split("/");
    if (token && token.length > 0) {
      return token;
    }
    return null;
  }

  getFilterByStatusLabel = (value: ETerminalStatus) => {
    return this.statusOptions.find(($0) => $0.value === value);
  };
  getFilterByConnectionStatusLabel = (value: ETerminalConnectionStatus) => {
    return this.connectionStatusOptions.find(($0) => $0.value === value);
  };
  getFilterByAlertsLabel = (values: ETerminalAlertType[]) => {
    return this.alertOptions.filter(($0) => values.includes($0.value));
  };

  refreshTerminalsList = () =>
    this.injected.terminalsStore.getTerminalsListWithoutAnimation();

  requestSelectedDevice = () => {
    if (!this._isMounted) return;
    this.setState(
      {
        isOpenModal: true,
      },
      async () => {
        let terminal = this.injected.terminalsStore.getTerminalFromListWithToken(
          this.selectedToken
        );
        if (!terminal) {
          terminal = await this.injected.terminalsStore.requestTerminal(
            this.selectedToken,
            ERequestTerminalMetadata.full
          );
        }
        if (terminal) {
          if (!this._isMounted) return;
          this.setState({
            selectedTerminal: terminal,
          });
        } else {
          this.didCloseTerminalModal();
        }
      }
    );
  };

  didSelectDevice = (terminalToken: string) => () => {
    if (this.props.isSelectorView && this.props.didSelectDevice) {
      const terminal = this.injected.terminalsStore.getTerminalFromListWithToken(
        terminalToken
      );

      this.props.didSelectDevice(terminal);
    } else {
      if (!this._isMounted) return;
      this.setState(
        {
          isOpenModal: true,
        },
        () => {
          this.injected.routingStore.pushKeepingSearch(
            `${EAppRoutes.DEVICES}/${terminalToken}`
          );
        }
      );
    }
  };

  didCloseTerminalModal = () => {
    if (!this._isMounted) return;
    this.setState(
      {
        isOpenModal: false,
      },
      () =>
        setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              isOpenModal: false,
              selectedTerminal: null,
            });
          }
          if (this.selectedToken !== null) {
            this.injected.routingStore.pushKeepingSearch(EAppRoutes.DEVICES);
          }
        }, 200)
    );
  };

  render() {
    const { localizedString, isSelectorView } = this.props;
    const {
      authStore,
      terminalsStore,
      contractStore,
      settingsStore,
    } = this.injected;
    const { currentUserCanSupport } = authStore;

    if (
      !currentUserCanSupport &&
      terminalsStore.didFirstRequest &&
      !terminalsStore.hasTerminalsOnFirstRequest
    ) {
      return (
        <HowToActivateDevice
          title={localizedString({
            id: "DevicesPage@noDevicesFound",
            defaultString: "Nenhum dispositivo encontrado",
          })}
        />
      );
    }

    const { isOpenModal, selectedTerminal } = this.state;
    const { resources } = contractStore.selectedContract;
    const { isGridDisplayMode, isTableDisplayMode } = settingsStore;

    const {
      isFetching,
      terminalsList: devicesList,
      isFetchingPaginate,
      devicesPerPage,
      currentPage,
      totalDevices,
      onChangePaginate,
      onChangeOrderBy,
      sortedBy,
    } = terminalsStore;

    return (
      <GridContainer>
        {selectedTerminal ? (
          <ModalTerminalDetails
            isOpen={isOpenModal}
            didCloseModal={this.didCloseTerminalModal}
            terminal={selectedTerminal}
          />
        ) : isOpenModal ? (
          <Modal isOpen maxWidth="xs">
            <div style={{ margin: "50px 0", textAlign: "center" }}>
              <LoadingView />
            </div>
          </Modal>
        ) : null}
        {!isSelectorView && (
          <GridItem xs={12}>
            <SimpleCard
              cardStyle={{ marginTop: 0 }}
              cardBodyStyle={{ padding: 0 }}
            >
              <UsageGridWidget type="licences" />
            </SimpleCard>
          </GridItem>
        )}
        <GridItem xs={12}>
          <SimpleCard cardStyle={{ marginTop: 0 }}>
            <GridContainer>
              <GridItem xs={12} sm>
                <DevicesSearchBar />
              </GridItem>
              <GridItem xs={12} sm>
                <InputSelect
                  placeholder={localizedString({
                    id: "DevicesPage@filterByConnectionStatusLabel",
                    defaultString: "Filtre por status de conexão",
                  })}
                  name="connectionStatus"
                  value={this.getFilterByConnectionStatusLabel(
                    terminalsStore.filterByConnectionStatus
                  )}
                  options={this.connectionStatusOptions}
                  onChange={(_, status) =>
                    terminalsStore.setFilterByConnectionStatus(status.value)
                  }
                  withPortal
                  formControlStyles={{ margin: 0, padding: 0 }}
                />
              </GridItem>
              {currentUserCanSupport && (
                <GridItem xs={12} sm>
                  <InputSelect
                    placeholder={localizedString({
                      id: "DevicesPage@filterByStatusLabel",
                      defaultString: "Filtre por status",
                    })}
                    name="status"
                    value={this.getFilterByStatusLabel(
                      terminalsStore.filterByStatus
                    )}
                    options={this.statusOptions}
                    onChange={(_, status) =>
                      terminalsStore.setFilterByStatus(status.value)
                    }
                    withPortal
                    formControlStyles={{ margin: 0, padding: 0 }}
                  />
                </GridItem>
              )}
              {!isSelectorView && (
                <GridItem xs={12} sm>
                  <InputSelect
                    placeholder={localizedString({
                      id: "DevicesPage@filterByAlertsLabel",
                      defaultString: "Filtre por alertas",
                    })}
                    name="alerts"
                    value={this.getFilterByAlertsLabel(
                      terminalsStore.filterByAlerts
                    )}
                    options={this.alertOptions}
                    multiple
                    onChange={(_, alerts) =>
                      terminalsStore.setFilterByAlerts(
                        alerts ? alerts.map(({ value }) => value) : []
                      )
                    }
                    withPortal
                    formControlStyles={{ margin: 0, padding: 0 }}
                  />
                </GridItem>
              )}
              {!isSelectorView && (
                <GridItem style={{ textAlign: "right" }}>
                  <Button
                    justIcon
                    small
                    color={isGridDisplayMode ? "info" : "transparent"}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      settingsStore.setDisplayMode(EDisplayMode.grid)
                    }
                  >
                    <i className="far fa-grip-horizontal" />
                  </Button>
                  <Button
                    justIcon
                    small
                    color={isTableDisplayMode ? "info" : "transparent"}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      settingsStore.setDisplayMode(EDisplayMode.table)
                    }
                  >
                    <i className="far fa-list-ul" />
                  </Button>
                </GridItem>
              )}
            </GridContainer>
          </SimpleCard>
        </GridItem>
        {!isSelectorView && (
          <Fragment>
            {isFetching ? (
              <div style={{ height: 14, display: "block" }}>&nbsp;</div>
            ) : (
              <GridItem
                xs={12}
                style={{
                  marginBottom: 10,
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                }}
              >
                <UpdateDevicesProgressCounter
                  callback={this.refreshTerminalsList}
                />
              </GridItem>
            )}
          </Fragment>
        )}
        <GridItem xs={12}>
          <GridContainer alignItems="center">
            {isFetching ? (
              <div style={{ width: "100%", marginTop: 20 }}>
                <LoadingView />
              </div>
            ) : terminalsStore.totalDevices < 1 ? (
              <GridItem xs style={{ textAlign: "center" }}>
                <Heading
                  title={localizedString({
                    id: "DevicesPage@noDevicesFound",
                    defaultString: "Nenhum dispositivo encontrado",
                  })}
                  textAlign="center"
                  category={
                    <Translate id="DevicesPage@searchHint">
                      Você pode alterar sua busca para procurar outros
                      dispositivos.
                    </Translate>
                  }
                />
                <Button
                  color="primary"
                  outline
                  onClick={terminalsStore.resetFilters}
                  small
                >
                  Reiniciar filtros
                </Button>
              </GridItem>
            ) : isTableDisplayMode || isSelectorView ? (
              <GridItem xs={12}>
                <SimpleCard cardStyle={{ marginTop: 0 }}>
                  <DevicesTableView
                    showGroupColumn={resources.groupControl}
                    currentUserCanSupport={currentUserCanSupport}
                    isFetching={isFetching}
                    isFetchingPaginate={isFetchingPaginate}
                    hideAlerts={isSelectorView}
                    devicesPerPage={devicesPerPage}
                    currentPage={currentPage}
                    totalDevices={totalDevices}
                    onChangePaginate={onChangePaginate}
                    devicesList={devicesList}
                    ignoredTokens={this.props.ignoredTokens}
                    selectedTokens={this.props.selectedTokens}
                    didSelect={this.didSelectDevice}
                    sortedBy={sortedBy}
                    onSortedChange={onChangeOrderBy}
                  />
                </SimpleCard>
              </GridItem>
            ) : (
              <DevicesGridView
                displayGroupName={resources.groupControl}
                isFetchingPaginate={isFetchingPaginate}
                devicesPerPage={devicesPerPage}
                currentPage={currentPage}
                totalDevices={totalDevices}
                onChangePaginate={onChangePaginate}
                devicesList={devicesList}
                didSelect={this.didSelectDevice}
              />
            )}
          </GridContainer>
        </GridItem>
        {!isFetching && totalDevices > 5 && (
          <GridItem xs={12} style={{ textAlign: "center", marginTop: 30 }}>
            <InfoLabel hideIcon>
              <Plural
                id="DevicesPage@devicesQtyLabel"
                value={totalDevices}
                zero="Nenhum dispositivo encontrado"
                one="# dispositivo"
                other="# dispositivos"
              />
            </InfoLabel>
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

export default DevicesListPageContainer;
