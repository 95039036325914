// @ts-nocheck
import { Fragment } from "react";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import AdvertisingSendMessageContainer from "./advertising/AdvertisingSendMessageContainer";

const AdvertisingSendMessagePage = () => (
  <Fragment>
    <PageTitle id="Menu@advertisingSendMessage">Enviar mensagem</PageTitle>
    <AdvertisingSendMessageContainer />
  </Fragment>
);

export default withPageAllowedRules({
  component: AdvertisingSendMessagePage,
  pageAllowedRule: "advertising",
});
