import { FC } from "react";
import { primaryColor, dangerColor } from "../../assets/jss/app-js-styles";

interface BooleanIconProps {
  isTrue: boolean;
  trueIcon?: string;
  falseIcon?: string;
}

const BooleanIcon: FC<BooleanIconProps> = ({
  isTrue = false,
  trueIcon = "check",
  falseIcon = "ban",
}) => (
  <i
    className={`fas fa-${isTrue ? trueIcon : falseIcon}`}
    style={{ color: isTrue ? primaryColor : dangerColor }}
  />
);

export default BooleanIcon;
