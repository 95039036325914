// @ts-nocheck
import { Fragment } from "react";
import { inject, observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import { GridContainer, GridItem } from "../../components/Grid";
import { Card, CardBody } from "../../components/Card";
import DatePicker from "../../components/Form/DatePicker";
import { Button } from "../../components/Button";
import LoadingView from "../../components/LoadingView/LoadingView";
import UsageGridWidget from "../../components/Widgets/UsageGridWidget";

import AdvertisingInsufficientCreditsGrid from "../../components/Advertising/AdvertisingInsufficientCreditsGrid";
import AdvertisingMessageTextarea from "../../components/Advertising/AdvertisingMessageTextarea";

// Translate
import Translate from "../../components/I18n/Translate";
import Plural from "../../components/I18n/Plural";

// Utils
import { endOfTheDayMoment } from "../../utils/DateUtils";

// Style
const styles = {
  dateFilterContainer: {
    marginTop: 20,
  },
  gridSection: {},
  calculatingLabel: {
    textAlign: "center",
  },
  statsLabel: {
    textAlign: "center",
  },
  submitContainer: { marginTop: 40 },
  sectionTitle: {
    marginTop: 20,
    fontWeight: 500,
    fontSize: "1.2em",
  },
};

const AdvertisingSendMessageBasicView = observer(
  ({
    classes,
    advertisingStore,
    fromDate,
    toDate,
    customersCount,
    isFetchingCustomers,
    isWaitingForm,
    onChangeDatePicker,
    onSubmit,
    didWantToBuySMS,
    availableCredits,
  }) => {
    const { message } = advertisingStore;
    const canSubmit =
      message.length > 0 &&
      !isFetchingCustomers &&
      availableCredits > 0 &&
      customersCount > 0;

    return (
      <Fragment>
        <Card style={{ marginTop: 0 }}>
          <CardBody style={{ padding: "0 16px" }}>
            <GridContainer>
              <UsageGridWidget type="sms" />
            </GridContainer>
          </CardBody>
        </Card>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ marginTop: 0 }}>
              <CardBody>
                {availableCredits > 0 ? (
                  <GridContainer justify="center">
                    <GridItem xs={12}>
                      <AdvertisingMessageTextarea />
                    </GridItem>
                    <GridItem xs={12} className={classes.dateFilterContainer}>
                      <p className={classes.sectionTitle}>
                        <Translate id="Advertising@basicContractDateFilterDescription">
                          Se preferir você pode alterar o período utilizando os
                          filtros abaixo:
                        </Translate>
                      </p>
                      <GridContainer>
                        <GridItem xs={12} sm={6}>
                          <DatePicker
                            label={
                              <Translate id="Advertising@startDateFilter">
                                Data início
                              </Translate>
                            }
                            name="fromDate"
                            value={fromDate}
                            isValidDate={(currentDate) =>
                              currentDate.isSameOrBefore(toDate)
                            }
                            onChange={onChangeDatePicker}
                            fullWidth
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <DatePicker
                            label={
                              <Translate id="Advertising@endDateFilter">
                                Data fim
                              </Translate>
                            }
                            name="toDate"
                            value={toDate}
                            isValidDate={(currentDate) =>
                              currentDate.isSameOrAfter(fromDate) &&
                              currentDate.isSameOrBefore(endOfTheDayMoment())
                            }
                            onChange={onChangeDatePicker}
                            fullWidth
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>

                    <GridItem xs={12} className={classes.submitContainer}>
                      <Button
                        color="primary"
                        block
                        disabled={!canSubmit}
                        isLoading={isWaitingForm}
                        onClick={onSubmit}
                      >
                        <Translate id="Advertising@sendMessageButtonLabel">
                          Enviar agora
                        </Translate>{" "}
                        {canSubmit ? (
                          <i className="fas fa-paper-plane" />
                        ) : (
                          <i className="fas fa-ban" />
                        )}
                      </Button>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <AdvertisingInsufficientCreditsGrid />
                )}
              </CardBody>
            </Card>
          </GridItem>
          {availableCredits > 0 && (
            <GridItem
              xs={12}
              className={classes.gridSection}
              style={{ textAlign: "center" }}
            >
              {isFetchingCustomers ? (
                <LoadingView height={40} />
              ) : (
                <Fragment>
                  <p className={classes.statsLabel}>
                    <Plural
                      id="Advertising@customersCountPluralDescription"
                      value={customersCount}
                      zero={
                        "Com os filtros atuais ninguém irá receber a mensagem"
                      }
                      one={
                        <span>
                          Com os filtros atuais irá atingir{" "}
                          <strong>apenas uma pessoa</strong>
                        </span>
                      }
                      other={
                        <span>
                          Com os filtros atuais irá atingir{" "}
                          <strong># pessoas</strong>
                        </span>
                      }
                    />{" "}
                    {availableCredits < customersCount ? (
                      <Fragment>
                        <Plural
                          id="Advertising@moreResultsThanCreditsDescription"
                          value={availableCredits}
                          zero={
                            ", porém você não possui créditos atualmente. Utilize o botão abaixo para solicitar."
                          }
                          one={
                            <span>
                              . Utilizando seus créditos,{" "}
                              <strong>apenas uma pessoa</strong> receberá a
                              mensagem.
                            </span>
                          }
                          other={
                            <span>
                              . Utilizando seus créditos,{" "}
                              <strong># pessoas</strong> receberão a mensagem.
                            </span>
                          }
                        />
                      </Fragment>
                    ) : (
                      <Plural
                        id="Advertising@smsCreditPluralDescription"
                        value={availableCredits}
                        zero={", porém você não possui créditos atualmente."}
                        one={
                          <span>
                            e você possui <strong>apenas um crédito</strong>{" "}
                            atualmente.
                          </span>
                        }
                        other={
                          <span>
                            e você possui <strong># créditos</strong>{" "}
                            atualmente.
                          </span>
                        }
                      />
                    )}
                  </p>
                  {availableCredits < customersCount && (
                    <Button small onClick={didWantToBuySMS}>
                      <Translate id="Advertising@didWantToBuySMSCreditsButtonLabel">
                        Solicite créditos aqui
                      </Translate>
                    </Button>
                  )}
                </Fragment>
              )}
            </GridItem>
          )}
        </GridContainer>
      </Fragment>
    );
  }
);

export default inject("advertisingStore")(
  observer(withStyles(styles)(AdvertisingSendMessageBasicView))
);
