// @ts-nocheck
import { Component } from "react";

import { Button } from "../Button";

class NumberEditingWithLabelInput extends Component {
  state = { isEditing: false };

  onChangeValue = ({ target }) => {
    let newValue = parseInt(target.value, 10);

    this.props.onChange(!isNaN(newValue) ? newValue : 0);
  };

  toggleEditing = () => {
    if (!this.props.disabled) {
      this.setState({
        isEditing: !this.state.isEditing,
      });
    }
  };

  render() {
    const { label, value, typeLabel } = this.props;
    const { isEditing } = this.state;
    return (
      <div>
        {label}{" "}
        {isEditing && (
          <input
            type="number"
            onChange={this.onChangeValue}
            value={value}
            style={{
              fontSize: "1.0em",
              padding: "4px 0",
              width: 60,
              textAlign: "right",
              marginRight: 4,
            }}
          />
        )}
        {typeLabel && (
          <strong
            onClick={() => {
              if (!isEditing) {
                this.toggleEditing();
              }
            }}
          >
            {!isEditing ? `${value} ` : ""}
            {typeLabel}
          </strong>
        )}
        <Button
          justIcon
          small
          color={isEditing ? "success" : "warning"}
          disabled={this.props.disabled}
          onClick={this.toggleEditing}
          style={{ marginLeft: 8 }}
        >
          {isEditing ? (
            <i className="fas fa-check" />
          ) : (
            <i className="fas fa-pencil-alt" />
          )}
        </Button>
      </div>
    );
  }
}

export default NumberEditingWithLabelInput;
