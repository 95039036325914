// @ts-nocheck

import { observer } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import { GridContainer, GridItem } from "../../components/Grid";
import { Card, CardBody } from "../../components/Card";
import LoadingView from "../../components/LoadingView/LoadingView";
import Modal from "../../components/Modal/Modal";
import UsageGridWidget from "../../components/Widgets/UsageGridWidget";
import AdvertisingInsufficientCreditsGrid from "../../components/Advertising/AdvertisingInsufficientCreditsGrid";

import AdvancedContractSendMessageWizard from "./AdvancedContractSendMessageWizard";

// Translate
import Translate from "../../components/I18n/Translate";
import Plural from "../../components/I18n/Plural";

// Style
const styles = {
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.4em",
  },
  statsLabel: {
    textAlign: "center",
    fontSize: "1.0em",
  },
};

const AdvertisingSendMessageAdvancedView = observer(
  ({
    classes,
    customersCount,
    smsUsage,
    smsLimit,
    whereFilterGroup,
    fromDate,
    toDate,
    connectedInTheLastValue,
    notConnectedInTheLastValue,
    selectedGroup,
    occurrencesOptions,
    gender,
    ageGroup,
    limitPeople,
    isValid,
    isFetchingCustomers,
    isWaitingForm,
    formWasSubmit,
    onChangeRadioGroup,
    onChangeDatePicker,
    onChangeSelectInput,
    onChangeSelectedGroup,
    onChangeOccurrencesOptions,
    onChangeGenderOption,
    onChangeLimitPeopleSlider,
    onSubmit,
    didWantToBuySMS,
    availableCredits,
  }) => {
    const canSubmit =
      !isFetchingCustomers &&
      availableCredits >= limitPeople &&
      limitPeople > 0;

    return (
      <GridContainer>
        <Modal isOpen={isWaitingForm} hideCloseButton maxWidth="sm">
          <GridContainer justify="center">
            <GridItem xs style={{ textAlign: "center" }}>
              <p>
                <Translate id="Common@loading">Aguarde...</Translate>
              </p>
              <LoadingView height={60} />
            </GridItem>
          </GridContainer>
        </Modal>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ marginTop: 0 }}>
            <CardBody style={{ padding: "0 16px" }}>
              <GridContainer>
                <UsageGridWidget type="sms" />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          {availableCredits < 1 ? (
            <Card style={{ marginTop: 0 }}>
              <CardBody>
                <AdvertisingInsufficientCreditsGrid />
              </CardBody>
            </Card>
          ) : (
            <AdvancedContractSendMessageWizard
              availableCredits={availableCredits}
              customersCount={customersCount}
              smsUsage={smsUsage}
              smsLimit={smsLimit}
              whereFilterGroup={whereFilterGroup}
              fromDate={fromDate}
              toDate={toDate}
              connectedInTheLastValue={connectedInTheLastValue}
              notConnectedInTheLastValue={notConnectedInTheLastValue}
              occurrencesOptions={occurrencesOptions}
              selectedGroup={selectedGroup}
              gender={gender}
              ageGroup={ageGroup}
              limitPeople={limitPeople}
              isValid={isValid}
              isFetchingCustomers={isFetchingCustomers}
              formWasSubmit={formWasSubmit}
              canSubmit={canSubmit}
              onChangeRadioGroup={onChangeRadioGroup}
              onChangeDatePicker={onChangeDatePicker}
              onChangeSelectInput={onChangeSelectInput}
              onChangeSelectedGroup={onChangeSelectedGroup}
              onChangeOccurrencesOptions={onChangeOccurrencesOptions}
              onChangeGenderOption={onChangeGenderOption}
              onChangeLimitPeopleSlider={onChangeLimitPeopleSlider}
              didWantToBuySMS={didWantToBuySMS}
              onSubmit={onSubmit}
            />
          )}
        </GridItem>
        {availableCredits > 0 && isFetchingCustomers && (
          <GridItem xs={12} className={classes.gridSection}>
            <LoadingView height={40} />
          </GridItem>
        )}
        {availableCredits > 0 && !isFetchingCustomers && customersCount > 0 && (
          <GridItem xs={12} className={classes.gridSection}>
            <p className={classes.statsLabel}>
              <Plural
                id="Advertising@customersCountPluralDescription"
                value={limitPeople}
                zero={"Com os filtros atuais ninguém irá receber a mensagem"}
                one={
                  <span>
                    Com os filtros atuais irá atingir{" "}
                    <strong>apenas uma pessoa</strong>
                  </span>
                }
                other={
                  <span>
                    Com os filtros atuais irá atingir <strong># pessoas</strong>
                  </span>
                }
              />{" "}
              <Plural
                id="Advertising@smsCreditPluralDescription"
                value={availableCredits}
                zero={", porém você não possui créditos atualmente."}
                one={
                  <span>
                    e você possui <strong>apenas um crédito</strong> atualmente.
                  </span>
                }
                other={
                  <span>
                    e você possui <strong># créditos</strong> atualmente.
                  </span>
                }
              />
            </p>
          </GridItem>
        )}
      </GridContainer>
    );
  }
);

export default withStyles(styles)(AdvertisingSendMessageAdvancedView);
