// @ts-nocheck
import { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import { Button } from "../Button";
import { Card } from "../Card";

// Style
import wizardStyle from "../../assets/jss/js-styles/components/wizardStyle";

class Wizard extends Component {
  constructor(props) {
    super(props);

    let width;
    if (props.steps.length === 1) {
      width = "100%";
    } else if (window.innerWidth < 600) {
      if (props.steps.length !== 3) {
        width = "50%";
      } else {
        width = 100 / 3 + "%";
      }
    } else {
      if (props.steps.length === 2) {
        width = "50%";
      } else {
        width = 100 / 3 + "%";
      }
    }

    this.state = {
      currentStep: props.currentStep ? props.currentStep : 0,
      nextButton: props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: props.showFinishButton
        ? props.steps.length === 1
          ? true
          : false
        : false,
      width,
      movingTabStyle: {
        transition: "transform 0s",
      },
    };
  }
  componentDidMount() {
    this.navigationStepChange(this.state.currentStep, false);
    window.addEventListener("resize", this.updateWidth);
  }
  componentDidUpdate(prevProps, _) {
    if (prevProps.currentStep !== this.props.currentStep) {
      this.navigationStepChange(this.props.currentStep, false);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }
  updateWidth = () => {
    this.refreshAnimation();
  };
  navigationStepChange = (newStep, shouldNotifyParent = true) => {
    if (shouldNotifyParent && this.props.onChangeCurrentStep) {
      this.props.onChangeCurrentStep(newStep);
    }

    if (this.props.steps) {
      this.setState((state) => {
        if (newStep > state.currentStep && !this.props.allowToContinue) {
          return null;
        }

        return {
          currentStep: newStep,
          nextButton: this.props.steps.length > newStep + 1 ? true : false,
          previousButton: newStep > 0 ? true : false,
          finishButton: this.props.showFinishButton
            ? this.props.steps.length === newStep + 1
              ? true
              : false
            : false,
        };
      }, this.refreshAnimation);
    }
  };
  nextButtonClick = () => {
    this.navigationStepChange(this.state.currentStep + 1);
  };
  previousButtonClick = () => {
    this.navigationStepChange(this.state.currentStep - 1);
  };
  finishButtonClick = () => {
    if (this.props.allowToFinish) {
      this.props.finishButtonClick();
      if (this.props.resetOnFinish) {
        this.navigationStepChange(0);
      }
    }
  };
  refreshAnimation = () => {
    const index = this.state.currentStep;
    const { steps } = this.props;
    const total = steps.length;
    let liWidth = 100 / total;
    const totalSteps = steps.length;
    let moveDistance = this.refs.wizard.children[0].offsetWidth / totalSteps;
    let indexTemp = index;
    let verticalLevel = 0;

    const mobileDevice = window.innerWidth < 600 && total > 3;

    if (mobileDevice) {
      moveDistance = this.refs.wizard.children[0].offsetWidth / 2;
      indexTemp = index % 2;
      liWidth = 50;
    }

    const stepWidth = moveDistance;
    moveDistance = moveDistance * indexTemp;

    const current = index + 1;

    if (current === 1 || (mobileDevice === true && index % 2 === 0)) {
      moveDistance -= 8;
    } else if (
      current === totalSteps ||
      (mobileDevice === true && index % 2 === 1)
    ) {
      moveDistance += 8;
    }

    if (mobileDevice) {
      verticalLevel = parseInt(index / 2, 10);
      verticalLevel = verticalLevel * 38;
    }
    const movingTabStyle = {
      width: stepWidth,
      transform:
        "translate3d(" + moveDistance + "px, " + verticalLevel + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)",
    };
    this.setState({ width: `${liWidth}%`, movingTabStyle });
  };

  render() {
    const { classes, title, subtitle, color, steps } = this.props;
    return (
      <div className={classes.wizardContainer} ref="wizard">
        <Card className={classes.card}>
          {(title || subtitle) && (
            <div className={classes.wizardHeader}>
              <h3 className={classes.title}>{title}</h3>
              <h5 className={classes.subtitle}>{subtitle}</h5>
            </div>
          )}
          <div className={classes.wizardNavigation}>
            <ul
              className={classes.nav}
              style={{ marginTop: this.props.removeNavMargin ? 0 : 20 }}
            >
              {steps.map((prop, key) => {
                const isDisabled = this.props.disabledSteps
                  ? this.props.disabledSteps.includes(key)
                  : false;
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <a
                      className={classnames({
                        [classes.stepsAnchor]: true,
                        [classes.stepsDisabled]: isDisabled,
                      })}
                      href={`#${key}`}
                      onClick={(event) => {
                        if (event) event.preventDefault();
                        if (!isDisabled) this.navigationStepChange(key);
                      }}
                    >
                      {prop.stepName}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div
              className={classes.movingTab + " " + classes[color]}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = classnames({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key,
              });
              return (
                <div className={stepContentClasses} key={key}>
                  {prop.stepComponent}
                </div>
              );
            })}
          </div>
          <div className={classes.footer}>
            {this.state.previousButton && (
              <div className={classes.left}>
                <Button
                  className={this.props.previousButtonClasses}
                  onClick={this.previousButtonClick}
                >
                  {this.props.previousButtonLabel}
                </Button>
              </div>
            )}
            {this.props.actionButtons ? (
              <div className={classes.right}>{this.props.actionButtons}</div>
            ) : (
              <div className={classes.right}>
                {this.state.nextButton && (
                  <Button
                    color={this.props.allowToContinue ? "primary" : "default"}
                    className={this.props.nextButtonClasses}
                    disabled={!this.props.allowToContinue}
                    onClick={this.nextButtonClick}
                  >
                    {this.props.nextButtonLabel}
                  </Button>
                )}
                {this.state.finishButton && (
                  <Button
                    color={this.props.allowToContinue ? "primary" : "default"}
                    className={this.props.finishButtonClasses}
                    disabled={!this.props.allowToFinish}
                    onClick={this.finishButtonClick}
                  >
                    {this.props.finishButtonLabel}
                  </Button>
                )}
              </div>
            )}
            <div className={classes.clearfix} />
          </div>
        </Card>
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "primary",
  previousButtonClasses: "",
  nextButtonClasses: "",
  finishButtonClasses: "",
  allowToContinue: true,
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.node.isRequired,
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
  ]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  previousButtonClasses: PropTypes.string,
  previousButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  nextButtonClasses: PropTypes.string,
  nextButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  finishButtonClasses: PropTypes.string,
  finishButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  finishButtonClick: PropTypes.func,
  showFinishButton: PropTypes.bool,
  allowToContinue: PropTypes.bool,
  allowToFinish: PropTypes.bool,
  resetOnFinish: PropTypes.bool,
  removeNavMargin: PropTypes.bool,
  currentStep: PropTypes.number,
  onChangeCurrentStep: PropTypes.func,
  disabledSteps: PropTypes.arrayOf(PropTypes.number),
  actionButtons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default withStyles(wizardStyle)(Wizard);
