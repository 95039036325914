//@ts-nocheck
import RadioGroup from "../../Form/RadioGroup";
import WizardUploadImage from "../Image";
import { WizarUploadRadioButtonBox, WizardUploadFor2Box } from "../style";

const WizardUploadLed = ({
  payload,
  updateRadio,
}: WizardUploadComponentProps) => {
  return (
    <WizardUploadFor2Box>
      <div className="title">
        <h3>Seu vídeo será exibido em um painel de LED?</h3>
      </div>
      <div className="content">
        <div className="left">
          <p>
            Dica: Os painéis de LED são uma ótima maneira de exibir conteúdo em
            espaços públicos, pois têm boa visibilidade em ambientes claros e
            podem ser vistos de longe. Certifique-se de que o seu conteúdo seja
            compatível com um paínel de LED e escolha uma resolução adequada
            para exibição.
          </p>

          <WizarUploadRadioButtonBox>
            <RadioGroup
              name="isLed"
              value={payload?.isLed}
              onChange={updateRadio}
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </WizarUploadRadioButtonBox>
        </div>
        <div className="right">
          <WizardUploadImage payload={payload} />
        </div>
      </div>
    </WizardUploadFor2Box>
  );
};

export default WizardUploadLed;
