// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";
import { Card, CardContent } from "@material-ui/core";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import { InputText, InputSwitch, SubmitButton } from "../Form";
import { withLocalized } from "../HOC";

import { GridContainer, GridItem } from "../Grid";

// Translate
import Translate from "../I18n/Translate";

// Icons
import { SaveIcon } from "../Icon";

// Utils
import Validate, { ValidationType } from "../Form/Validate";
import { IMAGE_PATH } from "../../utils/EnvUtils";

// Styles
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  card: {
    maxWidth: 120,
    margin: "0 auto",
    border: "4px solid transparent",
  },
  selectedCard: {
    borderColor: primaryColor,
  },
  cardContent: {
    background: "#E2E2E2",
    textAlign: "center",
    padding: "0!important",
  },
  cardTitle: {
    textAlign: "center",
    fontWeight: 500,
    margin: 0,
    padding: "4px 0",
    background: "#fff",
  },
  cardImage: { maxWidth: "100%" },
};

@withLocalized
@inject("mediaLibraryStore", "notificationsStore", "contractStore")
class AddStreamingsWebContentForm extends Component {
  state = {
    url: "",
    name: "",
    isOffline: true,
    isLive: false,
    isValid: {
      url: false,
    },
    isWaitingForm: false,
    formWasSubmit: false,
    description: "web",
    isLiveContent: "offline",
  };

  onChangeStreamingType = (type) => {
    if (type === "hdmi") {
      this.setState({
        description: type,
        name: "",
        isLiveContent: "live",
        isOffline: true,
        isLive: true,
      });
    }
    this.setState({
      description: type,
      name: "",
    });
  };

  onChangeUrlValue = ({ target }) => {
    const url = target.value;
    this.setState({
      url,
      isValid: {
        ...this.state.isValid,
        url: Validate(url, ValidationType.URL),
      },
    });
  };

  onChangeNameValue = ({ target }) => {
    this.setState({
      name: target.value,
    });
  };

  onChangeIsOffline = () => {
    this.setState({
      isOffline: !this.state.isOffline,
      isLive: false,
      isLiveContent: "offline",
    });
  };

  onChangeIsLive = () => {
    this.setState({
      isLive: !this.state.isLive,
      isOffline: false,
      isLiveContent: "live",
    });
  };

  canSubmitForm = () => {
    return this.state.description;
  };

  getFormDataToSubmit = ({
    url,
    name,
    isOffline,
    isLive,
    isLiveContent,
    description,
  }) => {
    return {
      url,
      name,
      description,
      isLive,
      isOffline,
      isLiveContent,
    };
  };

  submitForm = () => {
    this.props.mediaLibraryStore
      .submitStreaming(
        this.getFormDataToSubmit(this.state),
        this.props.selectedTags
      )
      .then(() => {
        this.submitHasSuccess();
      })
      .catch(() => {
        this.submitHasError();
      });
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  onSubmit = (event) => {
    if (event) event.preventDefault();
    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: (
              <Translate id="Streaming@urlErrorMessage">
                Informe uma URL completa, incluindo o protocolo. Exemplo:
                https://www.youtube.com/watch?v=lHB_G_zWTbc
              </Translate>
            ),
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = () => {
    this.props.didSave();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  render() {
    const { classes, contractStore } = this.props;
    const {
      url,
      name,
      isLive,
      isOffline,
      isWaitingForm,
      formWasSubmit,
      isValid,
      description,
    } = this.state;
    const { liveOnlineContent } = contractStore.selectedContract.resources;

    return (
      <GridContainer style={{ width: "100%", margin: 0 }}>
        <GridItem xs={12}>
          <form onSubmit={this.onSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={4}>
                    <Card
                      className={classnames({
                        [classes.card]: true,
                        [classes.selectedCard]: description === "web",
                      })}
                      onClick={() => this.onChangeStreamingType("web")}
                    >
                      <CardContent className={classes.cardContent}>
                        <p className={classes.cardTitle}>Web</p>
                        <img
                          src={`${IMAGE_PATH}/contents/${
                            description === "web" ? "default" : "default-alt"
                          }.jpg`}
                          className={classes.cardImage}
                          alt={
                            <Translate id="Streaming@webImageAlt">
                              Transmissão via web
                            </Translate>
                          }
                        />
                      </CardContent>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>

            {description === "web" && (
              <Fragment>
                <InputText
                  label={
                    <Translate
                      id={
                        liveOnlineContent
                          ? "Streaming@urlLabelWithBroadcast"
                          : "Streaming@urlLabelWithBroadcast"
                      }
                    >
                      {liveOnlineContent
                        ? "Link do Vídeo / Transmissão"
                        : "Link do Vídeo"}
                    </Translate>
                  }
                  placeholder={this.props.localizedString({
                    id: "Streaming@urlHint",
                    defaultString:
                      "ex: https://www.youtube.com/watch?v=eVSIrMW0IFg",
                  })}
                  name="url"
                  hasError={formWasSubmit && !isValid.url}
                  hasSuccess={formWasSubmit && isValid.url}
                  value={url}
                  inputProps={{ autoComplete: "off" }}
                  onChange={this.onChangeUrlValue}
                />
                <GridContainer>
                  <GridItem xs>
                    {liveOnlineContent && (
                      <InputSwitch
                        label={
                          <Translate id="Streaming@isOfflineLabel">
                            Manter cópia local
                          </Translate>
                        }
                        name="isOffline"
                        isActive={isOffline}
                        onChange={this.onChangeIsOffline}
                      />
                    )}
                    {liveOnlineContent && (
                      <InputSwitch
                        label={
                          <Translate id="Streaming@isLiveLabel">
                            É uma transmissão ao vivo
                          </Translate>
                        }
                        name="isLive"
                        isActive={isLive}
                        onChange={this.onChangeIsLive}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </Fragment>
            )}
            <InputText
              label={
                <Translate id="Streaming@nameLabel">
                  Nome do canal (opcional)
                </Translate>
              }
              name="name"
              hasSuccess={formWasSubmit}
              value={name}
              onChange={this.onChangeNameValue}
              maxLength={30}
            />

            <GridContainer>
              <GridItem xs>
                <SubmitButton
                  onClick={this.onSubmit}
                  block
                  large={false}
                  disabled={
                    description === "web" ? url.length < 6 : isLive === false
                  }
                  isLoading={isWaitingForm}
                >
                  <Translate
                    id={
                      liveOnlineContent
                        ? "Streaming@submitButtonLabelWithBroadcast"
                        : "Streaming@submitButtonLabelWithoutBroadcast"
                    }
                  >
                    {liveOnlineContent
                      ? "Incluir vídeo/transmissão"
                      : "Incluir vídeo"}
                  </Translate>{" "}
                  <SaveIcon />
                </SubmitButton>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(AddStreamingsWebContentForm);
