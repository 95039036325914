// @ts-nocheck
import axios from "axios";

// Utils Stores
import { saveLog } from "../store/FirebaseStore/FirebaseStore";

// Utils
import { isProductionServer } from "../utils/EnvUtils";
import { toBase64 } from "../utils/StringUtils";

const serverURL = {
  development:
    "https://us-central1-modboxdev.cloudfunctions.net/api/pagseguro/",
  production:
    "https://us-central1-modbox-auth.cloudfunctions.net/api/pagseguro/",
};

const kBaseUrl = isProductionServer
  ? serverURL.production
  : serverURL.development;

const API_METHOD = Object.freeze({
  checkout: "checkout",
});

class PagSeguroAPIService {
  postMethod = (method, data = {}) => {
    const publicApi = axios.create({
      baseURL: kBaseUrl,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return new Promise((resolve, reject) => {
      publicApi
        .post(method, data)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(this.errorHandlingCenter(method, error));
        });
    });
  };

  saveErrorLog = (logData) => {
    saveLog("pagSeguro API Error", logData);
  };

  errorHandlingCenter = (method, error) => {
    const logData = {
      method,
      originError: JSON.stringify(error),
    };
    this.saveErrorLog({
      ...logData,
    });

    return error;
  };

  getDefaultParams = () => {
    return {
      currency: "BRL",
      shippingAddressRequired: false,
      encoding: "UTF-8",
    };
  };

  serialize = (obj) => {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  sanitizeProductPrice = (price) => {
    return price.toFixed(2);
  };

  // Checkout
  prepareCheckoutParameters = ({
    productsList,
    reference,
    senderEmail,
    senderName,
  }) => {
    let data = {
      ...this.getDefaultParams(),
      reference,
      senderEmail: isProductionServer
        ? senderEmail
        : "c89456274091658506097@sandbox.pagseguro.com.br",
    };

    const splitUserName = senderName.trim().split(" ");
    if (splitUserName.length > 1) {
      data["senderName"] = senderName.trim();
    }

    productsList.forEach((product, index) => {
      const productIndex = index + 1;

      data[`itemId${productIndex}`] = product.id;
      data[`itemDescription${productIndex}`] = product.name;
      data[`itemAmount${productIndex}`] = this.sanitizeProductPrice(
        product.price
      );
      data[`itemQuantity${productIndex}`] = product.quantity;
    });

    return toBase64(this.serialize(data));
  };

  checkout = (parameters) => {
    return this.postMethod(API_METHOD.checkout, {
      parameters,
      account: "modbox",
    });
  };
}

export default PagSeguroAPIService;
