import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles";

import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont,
} from "../../app-js-styles";

const customInputStyle = ({ breakpoints }: Theme) =>
  createStyles({
    disabled: {
      cursor: "not-allowed",
      backgroundColor: "#F4F4F4",
      "&:before": {
        borderColor: "transparent !important",
      },
    },
    underline: {
      "&:hover:not($disabled):before,&:before": {
        borderColor: "#D2D2D2 !important",
        borderWidth: "1px !important",
      },
      "&:after": {
        borderColor: primaryColor,
      },
    },
    underlineError: {
      "&:after": {
        borderColor: dangerColor,
      },
    },
    underlineSuccess: {
      "&:after": {
        borderColor: successColor,
      },
    },
    labelRoot: {
      ...defaultFont,
      color: "#AAAAAA !important",
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "16px",
      lineHeight: "1.42857",
      top: "10px",
      "& + $underline": {
        marginTop: "0px",
      },
    },
    labelRootError: {
      color: dangerColor + " !important",
    },
    labelRootSuccess: {
      color: successColor + " !important",
    },
    feedback: {
      position: "absolute",
      bottom: "3px",
      right: "0",
      zIndex: 2,
      display: "block",
      width: "1em",
      height: "1em",
      textAlign: "center",
      pointerEvents: "none",
    },
    feedbackRight: {
      marginRight: "22px",
    },
    formControl: {
      margin: "0 0 17px 0",
      paddingTop: "27px",
      position: "relative",
      "& svg,& .fab,& .far,& .fal,& .fas": {
        color: "#495057",
      },
    },
    whiteUnderline: {
      "&:hover:not($disabled):before,&:before": {
        backgroundColor: "#FFFFFF",
      },
      "&:after": {
        backgroundColor: "#FFFFFF",
      },
    },
    input: {
      color: "#495057",
      "&,&::placeholder": {
        fontSize: "14px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "400",
        lineHeight: "1.42857",
        opacity: "1",
        [breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
      "&::placeholder": {
        color: "#AAAAAA",
      },
    },
    whiteInput: {
      "&,&::placeholder": {
        color: "#FFFFFF",
        opacity: "1",
      },
    },
  });

export default customInputStyle;
