import { makeStyles } from "@material-ui/styles";
import { FC } from "react";

import withLocalized from "../../../components/HOC/withLocalized";
import Badge from "../../../components/Badge/Badge";
import { SimpleCard } from "../../../components/Card";
import Conditional from "../../../components/Conditional/Conditional";

import QueueManagementControlModel from "../../../Model/QueueManagementControlModel";
import QueueValueView from "./QueueValueView";

const useStyles = makeStyles({
  container: {
    margin: 0,
    marginBottom: 22,
    textAlign: "center",
    cursor: "pointer",
  },
  title: {
    marginTop: 10,
    fontSize: "1.1em",
  },
  valueContainer: {
    margin: 0,
    padding: 8,
    minHeight: 100,
    fontSize: "1.2em",
    border: "4px solid #000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
  },
  valueLine: {
    textAlign: "center",
    margin: 0,
  },
  stats: {
    margin: 2,
  },
});

interface QueueControlItemCardProps {
  localizedString?: any;
  control: QueueManagementControlModel;
  onClick: () => void;
}

const QueueControlItemCard: FC<QueueControlItemCardProps> = ({
  localizedString,
  control,
  onClick,
}) => {
  const classes = useStyles();
  const { name, value, backgroundColor, fontColor } = control;

  let typeText = localizedString({
    id: "Informative@queueControlConstant",
    defaultString: "Constante",
  });
  let color = "warning";
  if (control.isOneVariable) {
    color = "info";
    typeText = localizedString({
      id: "Informative@queueControlOneVariable",
      defaultString: "Um valor",
    });
  } else if (control.isTwoVariables) {
    color = "primary";
    typeText = localizedString({
      id: "Informative@queueControlTwoVariables",
      defaultString: "Dois valores",
    });
  } else if (control.isSequential) {
    color = "success";
    typeText = localizedString({
      id: "Informative@queueControlSequential",
      defaultString: "Sequencial",
    });
  }

  return (
    <SimpleCard classNames={{ card: classes.container }} onClick={onClick}>
      <Badge color={color}>{typeText}</Badge>
      <p className={classes.title}>{name}</p>
      <QueueValueView
        value={value}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
      />
      <Conditional when={control.hasRemoteControl}>
        <p className={classes.stats}>
          <i className="fas fa-chevron-right" />{" "}
          {localizedString({
            id: "Informative@hasRemoteControlLabel",
            defaultString: "Possui controle remoto",
          })}
        </p>
      </Conditional>
      <Conditional when={!control.hasConnectedDevice}>
        <p className={classes.stats} style={{ color: "red" }}>
          <i className="fas fa-chevron-right" />{" "}
          {localizedString({
            id: "Informative@queueControlDontHaveConnectedDeviceLabel",
            defaultString: "Sem conexão à um Dispositivo!",
          })}
        </p>
      </Conditional>
      <Conditional when={control.useInputStream}>
        <p className={classes.stats}>
          <i className="fas fa-chevron-right" /> Agrupado na posição{" "}
          <strong>#{control.inputStreamPosition}</strong>
        </p>
      </Conditional>
    </SimpleCard>
  );
};

export default withLocalized(QueueControlItemCard);
