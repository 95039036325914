// @ts-nocheck

import withStyles from "@material-ui/core/styles/withStyles";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";

import ChatInput from "./ChatInput";

import Translate from "../I18n/Translate";

// Style
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  container: {
    position: "relative",
    backgroundColor: "#f4f7f9",
    width: "100%",
    margin: 0,
    padding: "0 8px",
  },
  editingContainer: {
    width: "calc(100% + 16px)",
    padding: 8,
    background: "#e9e9e9",
    margin: "0 -8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  editingIcon: {
    display: "inline-block",
    color: primaryColor,
    textAlign: "center",
  },
  closeIcon: {
    display: "inline-block",
    textAlign: "center",
  },
  editingBody: {
    display: "inline-block",
    width: "calc(100% - 64px)",
  },
  editLabel: {
    color: primaryColor,
    fontSize: "0.9em",
    fontWeight: 300,
    margin: 0,
  },
  editingMessage: {
    fontWeight: "bold",
    margin: 0,
  },
};

const ChatInputAndButton = ({
  classes,
  message,
  isEditingMessage,
  didCancelEditingMessage,
  onChangeMessage,
  didWantToAddAttachment,
  didSubmitMessage,
}) => (
  <GridContainer alignItems="center" className={classes.container}>
    {isEditingMessage && (
      <div className={classes.editingContainer}>
        <Button
          color="transparent"
          justIcon
          small
          className={classes.editingIcon}
        >
          <i className="fas fa-pen" />
        </Button>
        <div className={classes.editingBody}>
          <p className={classes.editLabel}>
            <Translate id="Chat@editMessageLabel">Editar mensagem</Translate>
          </p>
          <p className={classes.editingMessage}>{isEditingMessage}</p>
        </div>
        <Button
          className={classes.closeIcon}
          color="transparent"
          justIcon
          small
          onClick={didCancelEditingMessage}
        >
          <i className="fas fa-times" />
        </Button>
      </div>
    )}
    <GridItem removePadding>
      <Button
        color="transparent"
        outline
        small
        justIcon
        onClick={didWantToAddAttachment}
      >
        <i className="fas fa-paperclip" />
      </Button>
    </GridItem>
    <GridItem xs>
      <ChatInput message={message} onChangeMessage={onChangeMessage} />
    </GridItem>
    <GridItem removePadding>
      <Button color="primary" small justIcon onClick={didSubmitMessage}>
        <i className="fas fa-paper-plane" />
      </Button>
    </GridItem>
  </GridContainer>
);

export default withStyles(styles)(ChatInputAndButton);
