// @ts-nocheck
import { Component } from "react";

import { withLocalized } from "../../components/HOC";
import { GridContainer, GridItem } from "../../components/Grid";
import { ListItem, ListItemText } from "@material-ui/core";

import { labelForContactType } from "../../utils/ContractBusinessUtils";

@withLocalized
class BusinessContactsForm extends Component {
  renderContractItem = ({ value, description }) => (
    <ListItem>
      <ListItemText primary={value} secondary={description} />
    </ListItem>
  );

  render() {
    const { localizedString, contacts } = this.props;

    return (
      <GridContainer>
        {contacts.map(($0) => (
          <GridItem xs={12} sm={6} md={4} lg={6}>
            {this.renderContractItem({
              value: labelForContactType($0.type, localizedString),
              description: $0.value,
            })}
          </GridItem>
        ))}
      </GridContainer>
    );
  }
}

export default BusinessContactsForm;
