// @ts-nocheck

import { observer } from "mobx-react";

import PieChart from "../../components/Charts/PieChart";
import { GridContainer, GridItem } from "../../components/Grid";
import Tooltip from "../../components/Tooltip/Tooltip";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";

// Translate
import Translate from "../../components/I18n/Translate";

// Style
const styles = {
  chartTitle: {
    fontSize: "1.2em",
    textAlign: "center",
  },
};

const VisitorsPublicityChartWidget = observer(
  ({ classes, localizedString, chartData }) => {
    if (!chartData) return null;
    const {
      connected,
      authenticating,
      authenticated,
    } = chartData.connectionsPerType;

    return (
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Tooltip
            title={
              <Translate id="Connectivity@connectionByTypePublicityBannerTooltip">
                Imagem de boas vindas que você adiciona nas configurações da sua
                Wi-Fi.
              </Translate>
            }
          >
            <h2 className={classes.chartTitle}>
              <Translate id="Connectivity@connectionByTypePublicityBanner">
                Visualização do banner
              </Translate>{" "}
              <i className="fas fa-question-circle" />
            </h2>
          </Tooltip>
          <PieChart
            legend={{
              position: "bottom",
              horizontalAlign: "center",
              fontSize: 12,
            }}
            height={240}
            theme="theme2"
            data={[
              {
                name: localizedString({
                  id: "Connectivity@connectionByTypeDidNotSeeTheBanner",
                  defaultString: "Não visualizou",
                }),
                y: connected,
              },
              {
                name: localizedString({
                  id: "Connectivity@connectionByTypeDidSeeTheBanner",
                  defaultString: "Visualizou",
                }),
                y: authenticating + authenticated,
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <h2 className={classes.chartTitle}>
            <Translate id="Connectivity@accessByDeviceVendorTitle">
              Tipos de dispositivos
            </Translate>
          </h2>
          <PieChart
            legend={{
              position: "bottom",
              horizontalAlign: "center",
              fontSize: 16,
            }}
            height={240}
            theme="theme3"
            data={[
              {
                name: localizedString({
                  id: "Connectivity@accessByDeviceVendorIOS",
                  defaultString: "Apple",
                }),
                y: chartData.accessByDeviceVendor.iOS,
              },
              {
                name: localizedString({
                  id: "Connectivity@accessByDeviceVendorOthers",
                  defaultString: "Outros",
                }),
                y: chartData.accessByDeviceVendor.others,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  }
);

export default withLocalized(withStyles(styles)(VisitorsPublicityChartWidget));
