// @ts-nocheck
import { observable, computed, action } from "mobx";

import LocationModel from "../../Model/LocationModel";

import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class LocationStore {
  @observable isFetching = false;
  @observable locationsList = [];
  @observable searchString = "";

  constructor(internalEventsStore, notificationsStore, modboxAPIService) {
    this.modboxAPIService = modboxAPIService;
    this.notificationsStore = notificationsStore;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });
    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.locationsList = [];
    this.searchString = "";
  };

  @action
  setSearchString = (searchString) => {
    this.searchString = searchString;
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };
  @action
  setLocationsList = (newValue) => {
    this.locationsList = newValue;
  };

  @computed
  get filteredLocationsList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.locationsList,
        (location, textIncludesSearchString) =>
          textIncludesSearchString(location.name)
      );
    }

    return this.locationsList;
  }

  getLocationsList = () => {
    this.setIsFetching(true);
    let newLocationsList = [];

    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .requestLocations()
        .then((response) => {
          if (response.status === 200) {
            response.data.locations.forEach((locationData) => {
              const location = new LocationModel(locationData);
              newLocationsList.push(location);
            });
          }

          const list = newLocationsList.sort(dynamicSortByProperty("name"));
          this.setLocationsList(list);
          this.setIsFetching(false);
          resolve();
        })
        .catch((error) => {
          window.debugError("error in requestLocations", error);
          if (error && error.statusCode === 429) {
            setTimeout(this.getLocationsList, 1000);
          }
          reject();
        });
    });
  };

  @computed
  get getSingleLocation() {
    if (this.locationsList && this.locationsList.length > 0) {
      return this.locationsList[0];
    }
    return null;
  }

  submitLocation = (newLocation) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitLocation(newLocation)
        .then((response) => {
          this.getLocationsList();

          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          if (error && error.statusCode === 409) {
            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              color: "danger",
            });
          }
          reject(error);
        });
    });
  };

  updateLocation = (updatedLocation) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .updateLocation(updatedLocation)
        .then((response) => {
          this.getLocationsList();
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          if (error && error.statusCode === 409) {
            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              color: "danger",
            });
          }
          reject(error);
        });
    });
  };

  deleteLocation = (locationTokenToDelete) => {
    this.modboxAPIService
      .deleteLocation({
        token: locationTokenToDelete,
      })
      .then(() => {
        this.getLocationsList();
        this.notificationsStore.addSnackbarNotification({
          message: this.i18nStore.localizedString({
            id: "Location@deletedMessage",
            defaultString: "Apagou localidade",
          }),
          timeout: 2000,
          color: "danger",
        });
      });
  };

  getWithToken = (token: string) => {
    if (this.locationsList.length > 0) {
      return this.locationsList.find((location) => location.token === token);
    }
    return null;
  };

  requestLocation = async (token: string) => {
    try {
      const response = await this.modboxAPIService.requestLocation(token);
      if (response.status === 200 && response.data && response.data.locations) {
        return new LocationModel(response.data.locations[0]);
      }
    } catch (error) {
      debugError("error in requestLocation", error);
      return null;
    }
  };
}

export default LocationStore;
