// @ts-nocheck
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import Maps from "../../components/Maps/Maps";

// Translate
import Plural from "../../components/I18n/Plural";

// UTILS
import { IMAGE_PATH } from "../../utils/EnvUtils";

// Style
import { primaryColor } from "../../assets/jss/app-js-styles";

const styles = (theme) => ({
  card: {
    width: "100%",
    marginBottom: 16,
    position: "relative",
    cursor: "pointer",
  },
  cardHeader: {
    padding: 0,
    textAlign: "center",
    color: "#fff",
  },
  cardHeaderTitle: {
    fontSize: "1.2em",
    fontWeight: 500,
    color: "#333",
  },
  cardHeaderSubtitle: {
    fontSize: "0.9em",
    marginTop: -2,
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    height: 90,
    textAlign: "center",
  },
  image: {
    maxWidth: "100%",
    margin: "0 auto",
    height: 140,
    filter: "grayscale(100%)",
  },
  actions: {
    display: "flex",
    padding: 4,
    justifyContent: "space-around",
  },
  iconButton: {
    width: 30,
    height: 30,
    fontSize: "1.2em",
    color: "#efefef",
  },
  iconButtonActive: {
    color: `${primaryColor}!important`,
  },
  iconButtonDefaultLocation: {
    color: "#feca57!important",
  },
});

const LocationItemContainer = ({
  classes,
  location,
  didWantToOpenLocationModal,
}) => {
  const {
    token,
    latitude,
    longitude,
    name,
    terminalsList,
  } = location;

  return (
    <Card
      className={classes.card}
      onClick={() => didWantToOpenLocationModal(token)}
    >
      <CardHeader
        classes={{
          title: classes.cardHeaderTitle,
          subheader: classes.cardHeaderSubtitle,
        }}
        className={classes.cardHeader}
        title={name}
        subheader={
          terminalsList?.length > 0 ? (
            <Plural
              id="DevicesPage@devicesQtyLabel"
              value={terminalsList.length}
              one={"# dispositivo"}
              other={"# dispositivos"}
            />
          ) : (
            "-"
          )
        }
      />
      <CardContent style={{ padding: "4px"}}>
        {!!parseFloat(latitude) && !!parseFloat(longitude) ? (
          <Maps
            latitude={latitude}
            longitude={longitude}
            zoom={14}
            height={134}
            disabled
            hideZoomButtons
          />
        ) : (
          <img
            className={classes.image}
            src={`${IMAGE_PATH}/default-locations-map.jpg`}
            alt="mapa"
          />
        )}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(LocationItemContainer);
