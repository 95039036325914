// @ts-nocheck
import { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";
import LoadingView from "../components/LoadingView/LoadingView";

import { Button, LinkButton } from "../components/Button";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../components/HOC";

// core components
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";

// Translate
import Translate from "../components/I18n/Translate";

// Utils Stores
import { OrderStatus } from "../store/OrdersStore/OrdersStore";

// Style
const styles = {
  text: {
    fontSize: "1.2em",
  },
};

// Constant
const PAGSEGURO_TRANSACTION_ID = "?transaction=";

@withLocalized
@inject("ordersStore")
@observer
class PurchaseSuccessPage extends Component {
  state = { isFetching: true, order: null };

  componentDidMount() {
    const urlSearch = this.props.location.search;
    const transactionId = urlSearch.includes(PAGSEGURO_TRANSACTION_ID)
      ? urlSearch.replace(PAGSEGURO_TRANSACTION_ID, "")
      : null;
    if (transactionId) {
      this.props.ordersStore
        .getOrderWithTransactionId(transactionId)
        .then((order) => {
          this.setState({
            isFetching: false,
            order,
          });
        });
    } else {
      this.setState({
        isFetching: false,
      });
    }
  }

  renderPaidOrderSuccess = (order) => {
    let message = (
      <p className={this.props.classes.text}>
        <Translate id="Order@purchaseSuccessMessage">
          Seu pedido está confirmado e já foi liberado!{" "}
          <strong>Aproveite seus novos recursos</strong>
        </Translate>{" "}
        <span role="img" aria-label="emoji">
          😉
        </span>
      </p>
    );
    if (
      order.products.filter(
        ($0) => $0.afterPurchase.otherKey === "upgrade-to-advanced-contract"
      ).length > 0
    ) {
      message = (
        <Fragment>
          <p className={this.props.classes.text}>
            <Translate id="Order@upgradeToAdvancedSuccessMessageOne">
              Seu contrato foi atualizado e agora é do tipo{" "}
              <strong>Avançado!</strong>
            </Translate>{" "}
            <span role="img" aria-label="emoji">
              🤗
            </span>
          </p>
          <p className={this.props.classes.text}>
            <Translate id="Order@upgradeToAdvancedSuccessMessageTwo">
              Fique a vontade para navegar nos novos menus e conheça todos os
              recursos que acabamos de liberar para você.
            </Translate>
          </p>
          <p className={this.props.classes.text}>
            <Translate id="Order@upgradeToAdvancedSuccessMessageThree">
              E você já sabe: em caso de qualquer dúvida ou sugestão estaremos a
              disposição no chat!
            </Translate>
          </p>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <h1>
          <Translate id="PageTitle@purchaseSuccessPage">Obrigado!</Translate>
        </h1>
        {message}
      </Fragment>
    );
  };

  render() {
    const { localizedString } = this.props;
    const { isFetching, order } = this.state;

    if (isFetching) {
      return <LoadingView />;
    }

    if (order) {
      return (
        <Fragment>
          <PageTitle id="PageTitle@purchaseSuccessPage">Obrigado!</PageTitle>
          <GridContainer justify="center">
            <GridItem xs={10} style={{ textAlign: "center" }}>
              <SimpleCard cardStyle={{ paddingBottom: 20 }}>
                {order.status === OrderStatus.WAITING_PAYMENT ? (
                  <Fragment>
                    <p className={this.props.classes.text}>
                      <Translate id="Order@purchaseWaitingPaymentMessage">
                        Estamos aguardando o pagamento do seu pedido.
                      </Translate>
                      <Button
                        color="warning"
                        outline
                        small
                        href={order.paymentUrl}
                      >
                        <Translate id="Order@paymentUrlButtonLabel">
                          Efetuar pagamento
                        </Translate>{" "}
                        <i className="fas fa-credit-card" />
                      </Button>
                    </p>
                  </Fragment>
                ) : order.status === OrderStatus.PAID ? (
                  this.renderPaidOrderSuccess(order)
                ) : (
                  <Fragment>
                    <h1>
                      <Translate id="PageTitle@purchaseSuccessPage">
                        Obrigado!
                      </Translate>
                    </h1>
                    <p className={this.props.classes.text}>
                      <Translate id="Order@purchaseUnderAnalysisMessage">
                        Seu pedido está sendo processado e será liberado assim
                        que recebermos a confirmação do pagamento.
                      </Translate>
                    </p>
                  </Fragment>
                )}
                <LinkButton
                  small
                  color="primary"
                  to="/"
                  style={{ marginRight: 8 }}
                >
                  <Translate
                    id="Order@backToDashboardButtonLabel"
                    variables={{
                      dashboardLabel: localizedString({
                        id: "Menu@dashboard",
                        defaultString: "Dashboard",
                      }),
                    }}
                  >
                    Voltar ao $dashboardLabel
                  </Translate>
                </LinkButton>
                <LinkButton small color="primary" to="/financial#orders">
                  <Translate id="Order@goToFinancialButtonLabel">
                    Meus pedidos
                  </Translate>
                </LinkButton>
              </SimpleCard>
            </GridItem>
          </GridContainer>
        </Fragment>
      );
    }

    return <Redirect to="/" />;
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(PurchaseSuccessPage),
  pageAllowedRule: "affiliate",
});
