import MediaModel from "./MediaModel";

import { IMAGE_PATH } from "../utils/EnvUtils";

import { MediaTypeEnum } from "../store/MediaLibraryStore/MediaLibraryStore";

class ContentMediaModel extends MediaModel {
  creationDate;
  description;
  url;
  updateTime;
  duration;
  expireTime;
  contentType;

  constructor(data) {
    super(
      MediaTypeEnum.CONTENT,
      { ...data, media_ready: "True" },
      `${IMAGE_PATH}/contents/${data.content_type}.jpg`
    );
    this.creationDate = data.creation_date;
    this.description = data.description;
    this.url = data.url;
    this.updateTime = data.update_time;
    this.contentType = data.content_type;
    this.expireTime = data.expire_time || "0";
    this.duration = this.setDurationForContentType(this.contentType, data);
  }

  setDurationForContentType = (contentType, data) => {
    if (contentType === "rss") {
      return "25";
    }
    return data.duration;
  };
}

export default ContentMediaModel;
