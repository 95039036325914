// @ts-nocheck
import { Component, Fragment } from "react";
import { observer } from "mobx-react";
import clone from "lodash/clone";

import useMediaQuery from "../../hooks/useMediaQuery";

// Components
import { GridContainer, GridItem } from "../Grid";
import DateConfigDateInOut from "./resourceScheduleForm/DateConfigDateInOut";
import { ButtonGroup, DatePicker, InputSwitch } from "../Form";
import { withLocalized } from "../HOC";
import { SimpleCard } from "../Card";

// Translate
import {
  SundayString,
  MondayString,
  TuesdayString,
  WednesdayString,
  ThursdayString,
  FridayString,
  SaturdayString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Utils
import {
  getActiveDaysFromArray,
  startOfTheDayMoment,
  endOfTheDayMoment,
} from "../../utils/DateUtils";

const dayWeekSwitch = ({ title, key, value, toggleDayWeek }) => (
  <InputSwitch
    name={key}
    label={title}
    isActive={value}
    onChange={toggleDayWeek}
    labelInVertical
  />
);

const DateConfigDaysWeek = ({ daysWeek, toggleDayWeek }) => {
  const fullScreen = useMediaQuery({ down: "sm" });
  const style = {
    display: fullScreen ? "block" : "flex",
    justifyContent: "space-between",
  };

  const { sun, mon, tue, wed, thu, fri, sat } = daysWeek;
  return (
    <div className="row">
      <div className="col-sm-12" style={style}>
        {dayWeekSwitch({
          title: <SundayString />,
          key: "sun",
          value: sun,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: <MondayString />,
          key: "mon",
          value: mon,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: <TuesdayString />,
          key: "tue",
          value: tue,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: <WednesdayString />,
          key: "wed",
          value: wed,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: <ThursdayString />,
          key: "thu",
          value: thu,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: <FridayString />,
          key: "fri",
          value: fri,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: <SaturdayString />,
          key: "sat",
          value: sat,
          toggleDayWeek,
        })}
      </div>
    </div>
  );
};

@withLocalized
@observer
class ResourceScheduleForm extends Component {
  constructor(props) {
    super(props);

    const { schedule } = props;
    const scheduleTime = schedule.time
      ? clone(schedule.time)
      : ["00:00", "23:59"];
    const [timeStartHour, timeStartMinute] = scheduleTime[0].split(":");
    const [timeEndHour, timeEndMinute] = scheduleTime[1].split(":");

    this.state = {
      dayIn: schedule["day_in"]
        ? schedule["day_in"]
        : startOfTheDayMoment().format("X"),
      dayOut: schedule["day_out"]
        ? schedule["day_out"]
        : endOfTheDayMoment()
            .add(7, "day")
            .format("X"),
      daysWeek: schedule["days_week"]
        ? this.getDaysWeekState(schedule["days_week"])
        : ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
      timeStart: startOfTheDayMoment({
        hour: timeStartHour,
        minute: timeStartMinute,
      }),
      timeEnd: endOfTheDayMoment({
        hour: timeEndHour,
        minute: timeEndMinute,
      }),
      dateConfigType: schedule["day_in"] ? "dateInOut" : "daysWeek",
    };
  }

  getDaysWeekState = (daysWeekArray) => {
    if (daysWeekArray) {
      return getActiveDaysFromArray(daysWeekArray);
    }
    return {
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
    };
  };

  onChangeDateConfigType = (name, selectedValue) => {
    let newState = {
      [name]: selectedValue,
    };
    if (selectedValue === "daysWeek") {
      newState["daysWeek"] = this.getDaysWeekState([
        "dom",
        "seg",
        "ter",
        "qua",
        "qui",
        "sex",
        "sab",
      ]);
    }

    this.setState(newState, this.didSave);
  };

  onChangeHoursPickerTime = (name, newValue) => {
    this.setState(
      {
        [name]: newValue,
      },
      this.didSave
    );
  };

  toggleDayWeek = (day) => {
    this.setState(
      (prevState) => ({
        daysWeek: {
          ...prevState.daysWeek,
          [day]: !prevState.daysWeek[day],
        },
      }),
      this.didSave
    );
  };

  formatDaysWeekToSave = () => {
    const { daysWeek, dateConfigType } = this.state;
    let result = [];

    if (dateConfigType === "daysWeek") {
      const { sun, mon, tue, wed, thu, fri, sat } = daysWeek;

      if (sun) result.push("dom");
      if (mon) result.push("seg");
      if (tue) result.push("ter");
      if (wed) result.push("qua");
      if (thu) result.push("qui");
      if (fri) result.push("sex");
      if (sat) result.push("sab");
    }

    return result;
  };

  onChangeDateIn = ({ dayIn }) => {
    this.setState({ dayIn }, this.didSave);
  };
  onChangeDateOut = ({ dayOut }) => {
    this.setState({ dayOut }, this.didSave);
  };

  didSave = () => {
    const { saveSchedule } = this.props;
    const { dayIn, dayOut, timeStart, timeEnd, dateConfigType } = this.state;

    saveSchedule({
      day_in: dateConfigType === "dateInOut" ? dayIn : null,
      day_out: dateConfigType === "dateInOut" ? dayOut : null,
      days_week: this.formatDaysWeekToSave(),
      time: [timeStart.format("HH:mm"), timeEnd.format("HH:mm")],
    });
  };

  render() {
    const { localizedString } = this.props;
    const {
      dayIn,
      dayOut,
      daysWeek,
      timeStart,
      timeEnd,
      dateConfigType,
    } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <ButtonGroup
            name="dateConfigType"
            value={dateConfigType}
            whiteBackgroundColor
            large
            onChange={this.onChangeDateConfigType}
            options={[
              {
                value: "daysWeek",
                label: localizedString({
                  id: "Schedule@weekdaysSectionTitle",
                  defaultString: "Dias da Semana",
                }),
              },
              {
                value: "dateInOut",
                label: localizedString({
                  id: "Schedule@dateScheduledSectionTitle",
                  defaultString: "Data programada",
                }),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>
          {dateConfigType === "daysWeek" ? (
            <DateConfigDaysWeek
              daysWeek={daysWeek}
              toggleDayWeek={this.toggleDayWeek}
            />
          ) : (
            <DateConfigDateInOut
              dayIn={dayIn}
              dayOut={dayOut}
              onChangeDateIn={this.onChangeDateIn}
              onChangeDateOut={this.onChangeDateOut}
            />
          )}
        </GridItem>
        <Fragment>
          <GridItem xs={12} sm={6}>
            <SimpleCard cardStyle={{ marginTop: 0 }}>
              <DatePicker
                hideDate
                removeMargin
                fullWidth
                label={
                  <Translate id="Schedule@startHour">Hora início</Translate>
                }
                name="timeStart"
                value={timeStart}
                onChange={this.onChangeHoursPickerTime}
              />
            </SimpleCard>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <SimpleCard cardStyle={{ marginTop: 0 }}>
              <DatePicker
                hideDate
                removeMargin
                fullWidth
                label={<Translate id="Schedule@endHour">Hora fim</Translate>}
                name="timeEnd"
                value={timeEnd}
                onChange={this.onChangeHoursPickerTime}
              />
            </SimpleCard>
          </GridItem>
        </Fragment>
      </GridContainer>
    );
  }
}

export default ResourceScheduleForm;
