// @ts-nocheck

import { observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import HeatMapChart from "../../components/Charts/HeatMapChart";
import { GridContainer, GridItem } from "../../components/Grid";

import { withLocalized } from "../../components/HOC";

// Translate
import Translate from "../../components/I18n/Translate";

// Style
import { primaryColor, grayColor } from "../../assets/jss/app-js-styles";
const styles = {
  statsTitle: {
    color: grayColor,
    textAlign: "center",
  },
  statsLabel: {
    color: primaryColor,
    textAlign: "center",
    fontSize: "1.4em",
    fontWeight: "bold",
  },
};

const HourslyTrafficChartWidget = observer(
  ({ classes, localizedString, hourLabels, chartData, didSelectChartItem }) => {
    const getDataForDay = (day) => {
      let series = [];
      for (let index = 0; index < 24; index++) {
        series.push({
          x: hourLabels[`${index}`],
          y:
            chartData === null || chartData === "fetching"
              ? 0
              : chartData[day][`${index}`],
        });
      }
      return series;
    };

    return (
      <GridContainer>
        <GridItem xs={12}>
          <HeatMapChart
            hideTooltip
            height={300}
            width="100%"
            customChartOption={{
              events: {
                dataPointSelection: (_, __, config) => {
                  didSelectChartItem({
                    x: config.dataPointIndex,
                    y: config.seriesIndex,
                  });
                },
              },
            }}
            data={[
              {
                name: localizedString({
                  id: "Date@saturday",
                  defaultString: "Sábado",
                }),
                data: getDataForDay("sat"),
              },
              {
                name: localizedString({
                  id: "Date@friday",
                  defaultString: "Sexta-feira",
                }),
                data: getDataForDay("fri"),
              },
              {
                name: localizedString({
                  id: "Date@thursday",
                  defaultString: "Quinta-feira",
                }),
                data: getDataForDay("thu"),
              },
              {
                name: localizedString({
                  id: "Date@wednesday",
                  defaultString: "Quarta-feira",
                }),
                data: getDataForDay("wed"),
              },
              {
                name: localizedString({
                  id: "Date@tuesday",
                  defaultString: "Terça-feira",
                }),
                data: getDataForDay("tue"),
              },
              {
                name: localizedString({
                  id: "Date@monday",
                  defaultString: "Segunda-feira",
                }),
                data: getDataForDay("mon"),
              },
              {
                name: localizedString({
                  id: "Date@sunday",
                  defaultString: "Domingo",
                }),
                data: getDataForDay("sun"),
              },
            ]}
          />
        </GridItem>
        {chartData && (
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={6} md={3}>
                <p className={classes.statsTitle}>
                  <Translate id="Metrics@busiestTimeLabel">
                    Hora mais movimentada
                  </Translate>
                </p>
                <p className={classes.statsLabel}>{chartData.busiestTimeDay}</p>
                <p className={classes.statsLabel}>
                  {chartData.busiestTimeHour}
                </p>
              </GridItem>
              <GridItem xs={6} md={3}>
                <p className={classes.statsTitle}>
                  <Translate id="Metrics@busiestDayOfTheWeekLabel">
                    Dia mais movimentado da semana
                  </Translate>
                </p>
                <p className={classes.statsLabel}>
                  {chartData.busiestDayOfTheWeek}
                </p>
              </GridItem>
              <GridItem xs={6} md={3}>
                <p className={classes.statsTitle}>
                  <Translate id="Metrics@lessBusyTimeLabel">
                    Hora menos movimentada
                  </Translate>
                </p>
                <p className={classes.statsLabel} style={{ color: grayColor }}>
                  {chartData.lessBusyTimeDay}
                </p>
                <p className={classes.statsLabel} style={{ color: grayColor }}>
                  {chartData.lessBusyTimeHour}
                </p>
              </GridItem>
              <GridItem xs={6} md={3}>
                <p className={classes.statsTitle}>
                  <Translate id="Metrics@lessBusyDayOfTheWeekLabel">
                    Dia menos movimentado da semana
                  </Translate>
                </p>
                <p className={classes.statsLabel} style={{ color: grayColor }}>
                  {chartData.lessBusyDayOfTheWeek}
                </p>
              </GridItem>
            </GridContainer>
          </GridItem>
        )}
      </GridContainer>
    );
  }
);

export default withLocalized(withStyles(styles)(HourslyTrafficChartWidget));
