//@ts-nocheck
import { inject, observer } from "mobx-react";

import { GridItem } from "../../Grid";
import AuthStore from "../../../store/AuthStore/AuthStore";

interface DeviceInfoColumnProps {
  authStore?: AuthStore;
  children: any;
}

const DeviceInfoColumn = ({ authStore, children }: DeviceInfoColumnProps) => {
  const { currentUserCanSupport } = authStore;
  return (
    <GridItem
      xs={12}
      sm={currentUserCanSupport ? 6 : 12}
      md={currentUserCanSupport ? 3 : 4}
    >
      {children}
    </GridItem>
  );
};

export default inject("authStore")(observer(DeviceInfoColumn));
