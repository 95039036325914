export const timeKeys = Object.freeze({
  "0": "Time@zero",
  "1": "Time@one",
  "2": "Time@two",
  "3": "Time@three",
  "4": "Time@four",
  "5": "Time@five",
  "6": "Time@six",
  "7": "Time@seven",
  "8": "Time@eight",
  "9": "Time@nine",
  "10": "Time@ten",
  "11": "Time@eleven",
  "12": "Time@twelve",
  "13": "Time@thirteen",
  "14": "Time@fourteen",
  "15": "Time@fifteen",
  "16": "Time@sixteen",
  "17": "Time@seventeen",
  "18": "Time@eighteen",
  "19": "Time@nineteen",
  "20": "Time@twenty",
  "21": "Time@twentyOne",
  "22": "Time@twentyTwo",
  "23": "Time@twentyThree",
});

export const monthKeys = Object.freeze({
  "1": "Month@januaryFull",
  "2": "Month@februaryFull",
  "3": "Month@marchFull",
  "4": "Month@aprilFull",
  "5": "Month@mayFull",
  "6": "Month@juneFull",
  "7": "Month@julyFull",
  "8": "Month@augustFull",
  "9": "Month@septemberFull",
  "10": "Month@octoberFull",
  "11": "Month@novemberFull",
  "12": "Month@decemberFull",
});
