// @ts-nocheck
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ImageLightbox = ({ images, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div style={{ cursor: "pointer" }} onClick={() => setIsOpen(true)}>
      {children}
      {isOpen && (
        <Lightbox
          mainSrc={images[currentIndex]}
          nextSrc={
            images.length > 1
              ? images[(currentIndex + 1) % images.length]
              : null
          }
          prevSrc={
            images.length > 1
              ? images[(currentIndex + images.length - 1) % images.length]
              : null
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setCurrentIndex((currentIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentIndex((currentIndex + 1) % images.length)
          }
          reactModalStyle={{ overlay: { zIndex: 20000 } }}
        />
      )}
    </div>
  );
};

export default ImageLightbox;
