// @ts-nocheck
import { PureComponent, Children, cloneElement } from "react";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";

class CopyToClipboard extends PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    onCopy: PropTypes.func,
    options: PropTypes.shape({
      debug: PropTypes.bool,
      message: PropTypes.string,
    }),
  };

  static defaultProps = {
    onCopy: undefined,
    options: undefined,
  };

  onClick = (event) => {
    const { text, onCopy, children, options } = this.props;

    const elem = Children.only(children);

    const result = copy(text, options);

    if (onCopy) {
      onCopy(text, result);
    }

    // Bypass onClick if it was present
    if (elem && elem.props && typeof elem.props.onClick === "function") {
      elem.props.onClick(event);
    }
  };

  render() {
    const {
      text: _text,
      onCopy: _onCopy,
      options: _options,
      children,
      ...props
    } = this.props;
    const elem = Children.only(children);

    return (
      <div style={{ cursor: "pointer" }}>
        {cloneElement(elem, { ...props, onClick: this.onClick })}
      </div>
    );
  }
}

export default CopyToClipboard;
