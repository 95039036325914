import axios from "axios";

const BASE_URL = "https://be.simplyas.com/";

class DiscourseAPIService {
  get = (url: string, data = {}) => {
    const publicApi = axios.create({
      baseURL: BASE_URL,
      responseType: "json",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return new Promise((resolve, reject) => {
      publicApi
        .get(url, data)
        .then((response) => resolve(response))
        .catch((error) => {
          window.debugError("Discourse API error", error);
          reject(error);
        });
    });
  };

  getTopicsFromCategory = (categoryId: string) => {
    return this.get(`c/${categoryId}/l/latest`);
  };
}

export default DiscourseAPIService;
