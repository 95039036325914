 
export enum AutonomediaMethods {
  submitMedia = "submitMedia",
}

class AutonomediaService {
  fileServer: string;

  setFileServer(fileServer: string) {
    this.fileServer = fileServer;
  }

  endpointForMethod = (method: AutonomediaMethods): string => {
    const baseUrl = `${this.fileServer}/autonomedia`;      
    return `https://${baseUrl}/${method}`;
  };
}

export default AutonomediaService;
