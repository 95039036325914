export interface ITerminalContract {
  apiKey: string;
  hash: string;
  name: string;
  path: string;
  status: boolean;
  licence: string;
}
export interface ITerminalCompany {
  name: string;
  status: boolean;
}
export interface ITerminalStats {
  connection: string;
  last: string;
  color: string;
  uptime: string;
}
export interface ITerminalOS {
  name: string;
  kernel: string;
}
export interface ITerminalNetwork {
  internet_interface: string;
  dhcp: boolean;
  internet_address: string;
  internet_macaddress: string;
  download: string;
  upload: string;
  local_address: string;
}
export interface ITerminalWifi {
  ssid: string;
  password: string;
  signal: string;
  quality: string;
  hasExternalAntenna: boolean;
}
export interface ITerminalGPS {
  latitude: string;
  longitude: string;
  velocity: string;
  altitude: string;
  hasExternalDevice: boolean;
}
export interface ITerminalCamera {
  hasExternalDevice: boolean;
}
export interface ITerminalHardware {
  model: string;
  temperature: string;
  power_supply: string;
  free_space: string;
  free_mem: string;
  disk_speed: string;
  bluetooth: string;
}
export interface ITerminalScreen {
  size: string;
  rotate: boolean;
  name: string;
  status: string;
}
export interface ITerminalServers {
  msg: string;
  file: string;
  update: string;
}
export interface ITerminalMediaPreferences {
  screen_resolution?: string;
}
export enum ETerminalAlertLevel {
  normal = "normal",
  caution = "caution",
  critical = "critical",
}
export enum ETerminalWifiLevel {
  weak = "weak",
  strong = "strong",
}
export interface ITerminalAlerts {
  temperature: ETerminalAlertLevel;
  energy: ETerminalAlertLevel;
  diskSpeed: ETerminalAlertLevel;
  wifi: ETerminalWifiLevel;
}

export enum ETerminalConnectionStatus {
  all = "all",
  online = "online",
  offline = "offline",
}
export enum ETerminalStatus {
  all = "all",
  enabled = "enabled",
  disabled = "disabled",
}

export enum ETerminalAlertType {
  critical = "critical",
  energy = "energy",
  temperature = "temperature",
  diskSpeed = "disk_speed",
  wifi = "wifi",
}

export interface IRequestTerminalsParams {
  page?: number;
  limit?: number;
  orderBy?: string;
  connection?: ETerminalConnectionStatus;
  status?: ETerminalStatus;
  search?: string;
  alerts?: ETerminalAlertType[];
}

export enum ERequestTerminalMetadata {
  basic = "basic",
  full = "full",
  admin = "admin",
  extras = "extras",
  camera = "camera",
  disabled = "disabled",
  gps = "gps",
  group = "group",
  hardware = "hardware",
  network = "network",
  screen = "screen",
  stats = "stats",
  wifi = "wifi",
  media_preferences = "media_preferences",
}
