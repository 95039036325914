// @ts-nocheck
import _cloneDeep from "lodash/cloneDeep";
import { action, computed, observable } from "mobx";

// Model
import UserModel from "../../Model/UserModel";

import Validate, { ValidationType } from "../../components/Form/Validate";

// Utils Stores
import { didChangeUserToken } from "../../store/ChatStore/ChatStore";
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

import { EAnalyticsEventCategory } from "../../interfaces/IAnalyticsService";

const kCurrentUserTokenKey = "k_current_user_token";

export const USER_KEY = "user";

export const VIEW_MODE = Object.freeze({
  LOGIN: "login",
  VERIFY_PIN: "verifyPin",
});

export const PASSWORD_RECOVERY_URL =
  process.env.REACT_APP_PASSWORD_RECOVERY_URL;

class AuthStore {
  @observable isFetching = false;
  @observable currentUser = null;

  @observable email = "";
  @observable password = "";
  @observable pin = "";
  @observable viewMode = VIEW_MODE.LOGIN;
  @observable recoverSuccess = false;
  @observable formWasSubmit = false;
  @observable isWaitingForm = false;
  @observable isRequestingNewPin = false;

  i18nStore = null;

  constructor(
    internalEventsStore,
    notificationsStore,
    analyticsService,
    modboxAPIService
  ) {
    this.internalEventsStore = internalEventsStore;
    this.notificationsStore = notificationsStore;
    this.analyticsService = analyticsService;
    this.modboxAPIService = modboxAPIService;

    const savedUserToken = window.localStorage.getItem(kCurrentUserTokenKey);

    if (savedUserToken) {
      this.tryToLoginWithSavedUserToken(savedUserToken);
    }
  }

  @action
  clearStore = () => {
    this.email = "";
    this.password = "";
    this.pin = "";
    this.viewMode = VIEW_MODE.LOGIN;
    this.recoverSuccess = false;
    this.formWasSubmit = false;
    this.isWaitingForm = false;
    this.isRequestingNewPin = false;
  };

  @action setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setCurrentUser = (newValue) => {
    this.currentUser = newValue;
  };
  @action
  setEmail = (email) => {
    this.email = email;
  };
  @action
  setPassword = (password) => {
    this.password = password;
  };
  @action
  setPin = (pin) => {
    this.pin = pin.replace(/\D/g, "");
  };
  @action
  setRecoverSuccess = (recoverSuccess) => {
    this.recoverSuccess = recoverSuccess;
  };
  @action
  setFormWasSubmit = (formWasSubmit) => {
    this.formWasSubmit = formWasSubmit;
  };
  @action
  setIsWaitingForm = (isWaitingForm) => {
    this.isWaitingForm = isWaitingForm;
  };
  @action
  setIsRequestingNewPin = (isRequestingNewPin) => {
    this.isRequestingNewPin = isRequestingNewPin;
  };
  @action
  setViewMode = (viewMode) => {
    this.viewMode = viewMode;
  };

  @computed
  get isAuthenticated() {
    return this.currentUser !== null;
  }

  @computed
  get isValid() {
    return {
      email: Validate(this.email, ValidationType.EMAIL),
      password: Validate(this.password, ValidationType.REQUIRED),
    };
  }

  @computed
  get ageRanges() {
    if (!this.isAuthenticated) return [];

    const { webPreferences } = this.currentUser;

    if (webPreferences.age_ranges) {
      return _cloneDeep(webPreferences.age_ranges);
    }
    return [
      { name: "0-12", range: [0, 12] },
      { name: "13-24", range: [13, 24] },
      { name: "25-59", range: [25, 59] },
      { name: "60+", range: [60, 90] },
    ];
  }

  @computed
  get currentUserIsAdmin() {
    return this.isAuthenticated && this.currentUser.isAdmin;
  }
  @computed
  get currentUserIsSupportOperator() {
    return this.isAuthenticated && this.currentUser.isSupportOperator;
  }
  @computed
  get currentUserCanSupport() {
    return this.currentUserIsAdmin || this.currentUserIsSupportOperator;
  }
  @computed
  get currentUserToken() {
    return this.isAuthenticated ? this.currentUser.token : null;
  }
  @computed
  get currentUserName() {
    return this.isAuthenticated ? this.currentUser.name : null;
  }

  @computed
  get canSubmitForm() {
    if (this.viewMode === VIEW_MODE.LOGIN) {
      return this.isValid.email && this.isValid.password;
    }

    if (this.viewMode === VIEW_MODE.VERIFY_PIN) {
      return this.isValid.email && this.pin.length === 4;
    }

    return this.isValid.email;
  }

  changeViewMode = (viewMode) => () => this.setViewMode(viewMode);

  tryToLoginWithSavedUserToken = async (userToken: string) => {
    this.setIsFetching(true);
    await this.getCurrentUserProfile(userToken);
    this.setIsFetching(false);
  };

  getCurrentUserProfile = async (userToken) => {
    try {
      const user = await this.modboxAPIService.requestUser({
        userToken,
      });

      const currentUser = new UserModel({ ...user, token: userToken });
      this.analyticsService.setUser(userToken);
      this.analyticsService.sendEvent({
        category: EAnalyticsEventCategory.user,
        action: "getCurrentUserProfile",
      });
      this.setCurrentUser(currentUser);
      return Promise.resolve();
    } catch (error) {
      window.debugError("Error in getCurrentUserProfile", error);
      this.setCurrentUser(null);
      return Promise.reject(error);
    }
  };

  didLogin = async (user) => {
    const oldUserToken = window.localStorage.getItem("oldUserToken");
    if (oldUserToken) {
      await didChangeUserToken(oldUserToken, user.token);
    }

    window.localStorage.setItem(kCurrentUserTokenKey, user.token);

    const currentUser = new UserModel(user);
    this.setCurrentUser(currentUser);
  };

  saveCurrentUserWebPreferences = (newData) => {
    if (this.isAuthenticated) {
      const newPreferences = {
        ...this.currentUser.webPreferences,
        ...newData,
      };
      this.currentUser.webPreferences = newPreferences;
      return this.modboxAPIService.updateUser({
        ...this.currentUser,
        userToken: this.currentUser.token,
        webPreferences: newPreferences,
      });
    }
  };

  logout = (force = false) => {
    // Clear saved user token
    window.localStorage.removeItem(kCurrentUserTokenKey);

    // Clear selected contract
    window.sessionStorage.removeItem("selectedContract");

    if (force) {
      window.location.href = "/login";
    }

    if (this.isAuthenticated) {
      this.internalEventsStore.notify(EInternalEvents.didLogout);
      this.setCurrentUser(null);
    }

    this.analyticsService.setUser(null);
    this.analyticsService.sendEvent({
      category: EAnalyticsEventCategory.user,
      action: "logout",
    });

    this.clearStore();
  };

  didSubmitForm = (event) => {
    if (event) event.preventDefault();

    if (!this.canSubmitForm) {
      return false;
    }
    const { email, password, pin } = this;

    this.setFormWasSubmit(true);
    this.setIsWaitingForm(true);

    if (this.viewMode === VIEW_MODE.LOGIN) {
      this.modboxAPIService
        .submitLogin({
          email,
          password,
        })
        .then(({ data }) => {
          if (data.jwt_token) {
            const user = {
              ...data.profile,
              jwtToken: data.jwt_token,
            };
            localStorage.setItem(USER_KEY, JSON.stringify(user));
            this.didLogin(user);
          }
        })
        .catch((error) => {
          window.debugError("requestUser ERROR", error);
          this.setIsWaitingForm(false);
        });
    } else if (this.viewMode === VIEW_MODE.VERIFY_PIN) {
      this.modboxAPIService
        .verifyPin({
          email,
          pin,
        })
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.success,
            timeout: 10000,
            color: "success",
          });
          this.setViewMode(VIEW_MODE.LOGIN);
        })
        .catch((error) => {
          if (error && error.statusCode === 404) {
            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              timeout: 10000,
              color: "warning",
            });
          }
          this.setIsWaitingForm(false);
        });
    } else {
      this.modboxAPIService
        .recoverPassword({
          email,
          system_url: window.location.origin.includes("localhost")
            ? "https://lab-modbox.simplyas.com/"
            : window.location.origin,
        })
        .then((response) => {
          if (response.status === 200) {
            this.setRecoverSuccess(true);
            this.setIsWaitingForm(false);

            this.notificationsStore.addSnackbarNotification({
              message: response.data.message,
              timeout: 10000,
              color: "success",
            });
          }
        })
        .catch((error) => {
          if (error && error.statusCode === 409) {
            this.setRecoverSuccess(true);
            this.setIsWaitingForm(false);

            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              timeout: 10000,
              color: "success",
            });
          } else {
            this.setIsWaitingForm(false);
          }
        });
    }
  };

  requestNewPin = (event) => {
    if (event) event.preventDefault();

    this.setIsWaitingForm(true);
    this.setIsRequestingNewPin(true);
    this.modboxAPIService
      .verifyPin({
        email: this.email,
        isRecovery: true,
      })
      .then((response) => {
        this.notificationsStore.addSnackbarNotification({
          message: response.data.success,
          timeout: 10000,
          color: "success",
        });
        this.setIsWaitingForm(false);
        this.setPin("");
        this.setIsRequestingNewPin(false);
      })
      .catch((_) => {
        this.setIsWaitingForm(false);
        this.setIsRequestingNewPin(false);
      });
  };
}

export default AuthStore;
