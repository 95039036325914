// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import { GridContainer, GridItem } from "../../../components/Grid";
import LoadingView from "../../../components/LoadingView/LoadingView";

import withPageAllowedRules from "../../../pages/withPageAllowedRules";
import PageTitle from "../../../components/PageTitle/PageTitle";
import InfoLabel from "../../../components/Label/InfoLabel";
import Plural from "../../../components/I18n/Plural";
import Translate from "../../../components/I18n/Translate";
import SearchBar from "../../../components/SearchBar/SearchBar";
import Modal from "../../../components/Modal/Modal";
import { SimpleCard } from "../../../components/Card";
import { Button } from "../../../components/Button";
import InformativeQueueManagementStore from "../store/InformativeQueueManagementStore";
import ModboxAPIService from "../../../services/ModboxAPIService";
import InternalEventsStore from "../../../store/InternalEventsStore/InternalEventsStore";
import I18nStore from "../../../store/I18nStore/I18nStore";
import NotificationsStore from "../../../store/NotificationsStore/NotificationsStore";

import QueueControlItemCard from "../components/QueueControlItemCard";
import QueueControlForm from "../components/QueueControlForm";

// Styles
import { primaryColor } from "../../../assets/jss/app-js-styles";
import TerminalsStore from "../../../store/TerminalsStore/TerminalsStore";
const styles = createStyles({
  openToConnectContainer: {
    textAlign: "center",
  },
  codeLabel: {
    fontWeight: "bold",
    fontSize: "2em",
    background: primaryColor,
    color: "#fff",
    margin: "12px auto",
    padding: "16px 30px",
    width: "fit-content",
    borderRadius: 20,
  },
  codeExpiresDate: {
    margin: 0,
  },
});

interface IInformativeQueueManagementPageProps {
  classes: any;
}
interface InjectedProps extends IInformativeQueueManagementPageProps {
  internalEventsStore: InternalEventsStore;
  terminalsStore: TerminalsStore;
  notificationsStore: NotificationsStore;
  i18nStore: I18nStore;
  modboxAPIService: ModboxAPIService;
}

@inject(
  "internalEventsStore",
  "terminalsStore",
  "notificationsStore",
  "i18nStore",
  "modboxAPIService"
)
@observer
class InformativeQueueManagementPage extends Component<
  IInformativeQueueManagementPageProps
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  store: InformativeQueueManagementStore;

  constructor(props: IInformativeQueueManagementPageProps) {
    super(props);
    this.store = new InformativeQueueManagementStore(
      this.injected.internalEventsStore,
      this.injected.terminalsStore,
      this.injected.notificationsStore,
      this.injected.i18nStore,
      this.injected.modboxAPIService
    );
  }

  componentDidMount() {
    this.store.getControlsList();

    this.injected.terminalsStore.getTerminalsList();
  }

  didSelectControl = (token: string) => () =>
    this.store.didSelectControl(token);

  renderOpenToConnectControl = () => {
    const { classes } = this.props;
    const { selectedControl, didWantToOpenRemoteConnection } = this.store;

    if (!selectedControl.connectionCode) {
      return <LoadingView />;
    }

    return (
      <div className={classes.openToConnectContainer}>
        {selectedControl.isOpenToConnect ? (
          <Fragment>
            <p>
              <Translate id="Informative@queueControlCodeToConnectLabel">
                Use o código abaixo para conectar o controle remoto
              </Translate>
            </p>
            <p className={classes.codeLabel}>
              {selectedControl.connectionCode}
            </p>
            <p className={classes.codeExpiresDate}>
              {selectedControl.displayTimeToExpire}
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <p>
              <Translate id="Informative@queueExpiredConnectionCodeLabel">
                Tempo para conexão remota encerrado.
              </Translate>
            </p>
            <Button
              color="primary"
              small
              onClick={didWantToOpenRemoteConnection}
            >
              <Translate id="Informative@queueRefreshConnectionCodeButtonLabel">
                Gerar novo código para conexão
              </Translate>
            </Button>
          </Fragment>
        )}
      </div>
    );
  };

  render() {
    const {
      isFetching,
      isOpenModal,
      controlsList,
      filteredControlsList,
      searchString,
      setSearchString,
      isEditingControl,
      isFormOpen,
      isOptionsModal,
      isOpenToConnectModal,
      selectedControl,
    } = this.store;

    const hasConnectedDevice = selectedControl?.hasConnectedDevice || false;

    return (
      <Fragment>
        {isFormOpen && (
          <QueueControlForm store={this.store}>
            {({ body, actions }) => (
              <Modal
                isOpen
                didCloseModal={this.store.didCloseModal}
                fullScreen
                modalTitle={
                  isEditingControl ? (
                    <Translate id="Informative@queueControlAddTitle">
                      Editar controle
                    </Translate>
                  ) : (
                    <Translate id="Informative@queueControlAddTitle">
                      Adicionar controle
                    </Translate>
                  )
                }
                actions={actions}
              >
                {body}
              </Modal>
            )}
          </QueueControlForm>
        )}
        <Modal
          isOpen={isOpenModal && !isFormOpen}
          didCloseModal={this.store.didCloseModal}
          maxWidth={"xs"}
          modalTitle={
            isOpenToConnectModal ? (
              <Translate id="Informative@queueControlCodeToConnectTitle">
                Conectar controle remoto
              </Translate>
            ) : (
              <Translate id="Informative@queueSelectOptionModalTitle">
                Escolha uma opção
              </Translate>
            )
          }
        >
          {isOptionsModal && (
            <Fragment>
              {hasConnectedDevice && (
                <Button
                  color="primary"
                  block
                  onClick={this.store.didWantToOpenRemoteConnection}
                >
                  <Translate id="Informative@queueControlCodeToConnectTitle">
                    Conectar controle remoto
                  </Translate>
                </Button>
              )}
              <Button
                color="success"
                block
                outline
                isLoading={this.store.isFetchingDevice}
                onClick={this.store.didWantToUseControlOnLocalhost}
              >
                <Translate id="Informative@queueControlUseLocalhostButtonLabel">
                  Utilizar controle localmente
                </Translate>{" "}
                <i className="fas fa-external-link" />
              </Button>
              <Button
                color="primary"
                block
                outline
                onClick={this.store.didWantToEditControl}
              >
                <Translate id="Informative@queueControlAddTitle">
                  Editar Controle
                </Translate>{" "}
                <i className="fas fa-pen" />
              </Button>
            </Fragment>
          )}
          {isOpenToConnectModal && this.renderOpenToConnectControl()}
        </Modal>
        <PageTitle id="Menu@informativeQueueManagement">
          Gerenciador de filas
        </PageTitle>
        {controlsList.length > 2 && (
          <GridContainer>
            <GridItem xs={12}>
              <SimpleCard>
                <SearchBar value={searchString} onChange={setSearchString} />
              </SimpleCard>
            </GridItem>
          </GridContainer>
        )}
        <GridContainer style={{ marginBottom: 20 }}>
          <GridItem xs={12}>
            <Button color="primary" onClick={this.store.didWantToAddControl}>
              <Translate id="Common@add">Adicionar</Translate>{" "}
              <i className="fas fa-plus" />
            </Button>
          </GridItem>
        </GridContainer>
        {isFetching ? (
          <LoadingView />
        ) : (
          <GridContainer>
            {controlsList.length > 0 &&
              filteredControlsList.map(
                (control: QueueManagementControlModel) => (
                  <GridItem key={control.token} xs={12} sm={6} md={4} lg={3}>
                    <QueueControlItemCard
                      control={control}
                      onClick={this.didSelectControl(control.token)}
                    />
                  </GridItem>
                )
              )}
            <GridItem xs={12} style={{ textAlign: "center", marginTop: 30 }}>
              <InfoLabel hideIcon>
                <Plural
                  id="Informative@queueManagementControlsCount"
                  value={filteredControlsList.length}
                  zero="Nenhum controle encontrado"
                  one="# controle"
                  other="# controles"
                />
              </InfoLabel>
            </GridItem>
          </GridContainer>
        )}
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(InformativeQueueManagementPage),
  pageAllowedRule: "informative",
});
