// @ts-nocheck
import { observable, computed, action } from "mobx";

// Model
import NetworkModel from "../../Model/NetworkModel";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class NetworksStore {
  @observable dataList = [];
  @observable singleNetwork = null;
  @observable isFetching = false;
  @observable searchString = "";

  constructor(
    contractStore,
    internalEventsStore,
    resourcesStore,
    notificationsStore,
    modboxAPIService,
    modboxFileService
  ) {
    this.contractStore = contractStore;
    this.resourcesStore = resourcesStore;
    this.notificationsStore = notificationsStore;
    this.modboxAPIService = modboxAPIService;
    this.modboxFileService = modboxFileService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.dataList = [];
    this.singleNetwork = null;
    this.searchString = "";
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setDataList = (newValue) => {
    this.dataList = newValue;
  };

  @action
  setSearchString = (searchString) => {
    this.searchString = searchString;
  };
  @action
  setSingleNetwork = (searchString) => {
    this.singleNetwork = searchString;
  };

  @computed
  get filteredDataList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.dataList,
        (network, textIncludesSearchString) =>
          textIncludesSearchString(network.wifiName) ||
          textIncludesSearchString(network.label)
      );
    }

    return this.dataList;
  }

  getDataList = (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);
    let newList = [];

    this.modboxAPIService
      .requestNetworks()
      .then((response) => {
        if (response.status === 200) {
          response.data.networks.forEach((networkData) => {
            const network = new NetworkModel(
              networkData,
              this.modboxFileService
            );
            newList.push(network);
          });
        }
        this.setDataList(newList.sort(dynamicSortByProperty("label")));

        this.setIsFetching(false);
      })
      .catch((error) => {
        window.debugError("error in requestNetworks", error);
        if (error && error.statusCode === 429) {
          setTimeout(this.getDataList, 2000);
        }
      });
  };

  getSingleNetwork = async () => {
    let networkResource = this.resourcesStore.singleResourceRule;

    if (!networkResource || networkResource.resourceType !== "network") {
      this.resourcesStore.clearStore();
      await this.resourcesStore.getResourceRulesList("network", false);
      networkResource = this.resourcesStore.singleResourceRule;
    }

    this.modboxAPIService
      .requestNetworks()
      .then((response) => {
        if (response.status === 200) {
          const networkData = response.data.networks.find(
            ({ network_token }) =>
              network_token === networkResource.resourceToken
          );
          const network = new NetworkModel(networkData, this.modboxFileService);
          this.setSingleNetwork(network);
        }
      })
      .catch((error) => {
        window.debugError("error in requestNetworks", error);
        if (error && error.statusCode === 429) {
          setTimeout(this.getSingleNetwork, 4000);
        }
      });
  };

  updateSingleNetworkResource = (newSingleNetworkResource) => {
    this.resourcesStore.update(newSingleNetworkResource);
  };

  refreshResourcesStore = () => {
    this.resourcesStore.getResourceRulesList("network", false);
  };

  submit = (network) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitNetwork(network)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: this.i18nStore.localizedString({
              id: "Schedule@readyToCreateRulesMessage",
              defaultString:
                "Configure uma regra para começar a utilizar o recurso.",
            }),
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (network) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .updateNetwork(network)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          if (this.contractStore.selectedContract.resources.connectivity) {
            this.refreshResourcesStore();
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  submitEnterprise = (network) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitNetworkEnterprise(network)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: this.i18nStore.localizedString({
              id: "Schedule@readyToCreateRulesMessage",
              defaultString:
                "Configure uma regra para começar a utilizar o recurso.",
            }),
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updateEnterprise = (network) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .updateNetworkEnterprise(network)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          if (this.contractStore.selectedContract.resources.connectivity) {
            this.refreshResourcesStore();
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (network) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .deleteNetwork(network)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          this.refreshResourcesStore();
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getWithToken = (token) => {
    if (this.dataList.length > 0) {
      return this.dataList.find((network) => network.token === token);
    }
    return null;
  };
}

export default NetworksStore;
