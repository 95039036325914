// @ts-nocheck
import { Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../HOC";
import { GridContainer, GridItem } from "../Grid";
import Wizard from "../Wizard/Wizard";

// Translate
import Translate from "../I18n/Translate";

// Utils
import { IMAGE_PATH } from "../../utils/EnvUtils";

// Style
const styles = {
  stepTitle: {
    fontWeight: "bold",
    fontSize: "1.4em",
    textAlign: "center",
  },
  stepComment: {
    fontSize: "1.1em",
    textAlign: "center",
  },
  image: {
    maxWidth: "100%",
  },
};

const ActivateDeviceStep = withLocalized(
  withStyles(styles)(
    ({ i18nStore, localizedString, classes, step, comments }) => (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          {comments}
          <img
            src={`${IMAGE_PATH}/how-to/activate-device-step${step}_${i18nStore.currentLanguage}.jpg`}
            className={classes.image}
            alt={localizedString(
              {
                id: "Device@activateDeviceStep",
                defaultString: "Ativando dispositivo passo $step",
              },
              { step }
            )}
          />
        </GridItem>
      </GridContainer>
    )
  )
);

const HowToActivateDevice = ({ localizedString, classes, title }) => (
  <GridContainer>
    <GridItem xs={12}>
      <Wizard
        title={title}
        subtitle={localizedString({
          id: "Device@howToActivateSubtitle",
          defaultString:
            "Conecte seu primeiro dispositivo seguindo o procedimento abaixo",
        })}
        previousButtonLabel={localizedString({
          id: "Common@previous",
          defaultString: "Anterior",
        })}
        nextButtonLabel={localizedString({
          id: "Common@next",
          defaultString: "Próximo",
        })}
        finishButtonLabel="Done"
        color="primary"
        steps={[
          {
            stepName: localizedString(
              { id: "Device@howToActivateStep", defaultString: "Passo $step" },
              { step: "1" }
            ),
            stepComponent: (
              <ActivateDeviceStep
                step="1"
                comments={
                  <Fragment>
                    <h3 className={classes.stepTitle}>
                      <Translate id="Device@howToActivateStep1Title">
                        Passo 1 - Ligar o dispositivo
                      </Translate>
                    </h3>
                    <p className={classes.stepComment}>
                      <Translate id="Device@howToActivateStep1Description">
                        Para ligar o dispositivo você precisará de uma fonte de
                        energia <strong>OU</strong> um cabo USB com saída
                        Android para ligar direto no USB da TV.
                      </Translate>
                    </p>
                  </Fragment>
                }
              />
            ),
          },
          {
            stepName: localizedString(
              { id: "Device@howToActivateStep", defaultString: "Passo $step" },
              { step: "2" }
            ),
            stepComponent: (
              <ActivateDeviceStep
                step="2"
                comments={
                  <Fragment>
                    <h3 className={classes.stepTitle}>
                      <Translate id="Device@howToActivateStep2Title">
                        Passo 2 - Conectando à TV
                      </Translate>
                    </h3>
                    <p className={classes.stepComment}>
                      <Translate id="Device@howToActivateStep2Description">
                        Conecte o cabo HDMI no dispositivo e em seguida na TV.
                      </Translate>
                    </p>
                  </Fragment>
                }
              />
            ),
          },
          {
            stepName: localizedString(
              { id: "Device@howToActivateStep", defaultString: "Passo $step" },
              { step: "3" }
            ),
            stepComponent: (
              <ActivateDeviceStep
                step="3"
                comments={
                  <Fragment>
                    <h3 className={classes.stepTitle}>
                      <Translate id="Device@howToActivateStep3Title">
                        Passo 3 - Conectar na Wifi do dispositivo
                      </Translate>
                    </h3>
                    <p className={classes.stepComment}>
                      <Translate id="Device@howToActivateStep3Description">
                        Conecte seu smartphone ou computador na rede wifi que o
                        dispositivo acabou de criar automaticamente.
                      </Translate>
                    </p>
                  </Fragment>
                }
              />
            ),
          },
          {
            stepName: localizedString(
              { id: "Device@howToActivateStep", defaultString: "Passo $step" },
              { step: "4" }
            ),
            stepComponent: (
              <ActivateDeviceStep
                step="4"
                comments={
                  <Fragment>
                    <h3 className={classes.stepTitle}>
                      <Translate id="Device@howToActivateStep4Title">
                        Passo 4 - Tipo de conexão do dispositivo
                      </Translate>
                    </h3>
                    <p className={classes.stepComment}>
                      <Translate id="Device@howToActivateStep4Description">
                        Seu dispositivo irá conectar à internet através de um
                        cabo de rede ou através de uma rede Wifi existente?
                      </Translate>
                    </p>
                  </Fragment>
                }
              />
            ),
          },
          {
            stepName: localizedString(
              { id: "Device@howToActivateStep", defaultString: "Passo $step" },
              { step: "5" }
            ),
            stepComponent: (
              <ActivateDeviceStep
                step="5"
                comments={
                  <Fragment>
                    <h3 className={classes.stepTitle}>
                      <Translate id="Device@howToActivateStep5Title">
                        Passo 5 - Siga instruções
                      </Translate>
                    </h3>
                    <p className={classes.stepComment}>
                      <Translate id="Device@howToActivateStep5Description">
                        Continue com as instruções para concluir a configuração
                        do seu dispositivo.
                      </Translate>
                    </p>
                  </Fragment>
                }
              />
            ),
          },
          {
            stepName: localizedString(
              { id: "Device@howToActivateStep", defaultString: "Passo $step" },
              { step: "6" }
            ),
            stepComponent: (
              <ActivateDeviceStep
                step="6"
                comments={
                  <Fragment>
                    <h3 className={classes.stepTitle}>
                      <Translate id="Device@howToActivateStep6Title">
                        Passo 6 - Aguarde instalação
                      </Translate>
                    </h3>
                    <p className={classes.stepComment}>
                      <Translate id="Device@howToActivateStep6Description">
                        Aguarde o processo automático de instalação e
                        configuração do sistema.
                      </Translate>
                    </p>
                  </Fragment>
                }
              />
            ),
          },
        ]}
      />
    </GridItem>
  </GridContainer>
);

export default withLocalized(withStyles(styles)(HowToActivateDevice));
