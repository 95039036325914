// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withPageAllowedRules from "./withPageAllowedRules";
import debounce from "lodash/debounce";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../components/HOC";

import PageTitle from "../components/PageTitle/PageTitle";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import InfoLabel from "../components/Label/InfoLabel";
import UsageGridWidget from "../components/Widgets/UsageGridWidget";
import { DatePicker, InputSelect, ButtonGroup } from "../components/Form";
import Table from "../components/Table/Table";
import LoadingView from "../components/LoadingView/LoadingView";
import LineChart from "../components/Charts/LineChart";

// Translate
import { AllString } from "../components/I18n/CommonStrings";
import Translate from "../components/I18n/Translate";

// Utils
import { monthKeys } from "../utils/CalendarUtils";
import {
  startOfTheDayMoment,
  endOfTheDayMoment,
  stringDateFromUnixDate,
  startOfCurrentMonthMoment,
} from "../utils/DateUtils";

import { displayInteger } from "../utils/NumberUtils";

// Style
import { primaryColor, dangerColor } from "../assets/jss/app-js-styles";
import chartsStyle from "../assets/jss/js-styles/views/chartsStyle";
const styles = (theme) => ({
  ...chartsStyle,
  footerContainer: {
    textAlign: "center",
    marginTop: 16,
    position: "relative",
  },
  syntheticNumber: {
    color: primaryColor,
    fontSize: "1.1em",
    fontWeight: "bold",
  },
});

const colors = ["#9d9fa2", primaryColor, dangerColor, "#000"];
const chartColors = {
  fill: {
    colors: colors,
  },
  markers: {
    colors: colors,
  },
  colors: colors,
};

const allOption = { name: <AllString />, token: "all" };
const hoursOptions = () => {
  let list = [];
  for (let index = 0; index < 24; index++) {
    const label = index < 10 ? `0${index}:00` : `${index}:00`;
    list.push({
      label,
      value: label,
    });
  }
  list.push({
    label: "23:59",
    value: "23:59",
  });
  return list;
};

@withLocalized
@inject("i18nStore", "reportsStore")
@observer
class InformativeReportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportType: "synthetic",
      mediasList: [],
      groupsList: [],
      terminalsList: [],
      isLoadingMediasList: true,
      selectedMedia: null,
      selectedGroup: allOption,
      selectedTerminal: allOption,
      selectedMonth: this.monthsOptions()[0],
      fromDate: startOfTheDayMoment().subtract(1, "days"),
      toDate: endOfTheDayMoment(),
      fromHour: "00:00",
      toHour: "23:59",
      page: 1,
      limit: 10,
      chartData: null,
    };
  }

  componentDidMount() {
    this.props.reportsStore
      .getMediasFilesFromLog()
      .then((result) => {
        this.setState({
          ...result,
          isLoadingMediasList: false,
        });
      })
      .catch((_) => {
        this.setState({
          mediasList: [],
          isLoadingMediasList: false,
        });
      });
  }

  componentWillUnmount() {
    this.requestNewLogData.cancel();
  }

  monthsOptions = () => {
    const { localizedString } = this.props;
    const getMonthOption = (month) => {
      const monthInt = month.format("M");
      return {
        label: localizedString({
          id: monthKeys[monthInt],
          defaultString: month.format("MMMM"),
        }),
        value: month,
      };
    };
    return [
      getMonthOption(startOfCurrentMonthMoment()),
      getMonthOption(startOfCurrentMonthMoment().subtract(1, "months")),
      getMonthOption(startOfCurrentMonthMoment().subtract(2, "months")),
    ];
  };

  onChangeReportType = (_, reportType) => {
    this.setState(
      { reportType, fromHour: "00:00", toHour: "23:59" },
      this.didWantToUpdateLogData
    );
  };

  didWantToUpdateLogData = () => {
    if (this.state.selectedMedia) {
      this.props.reportsStore.isFetching = true;

      this.requestNewLogData();
    }
  };

  requestNewLogData = debounce(() => {
    const {
      reportType,
      selectedMedia,
      fromDate,
      toDate,
      selectedMonth,
      fromHour,
      toHour,
      selectedGroup,
      selectedTerminal,
      page,
      limit,
    } = this.state;
    if (reportType === "synthetic") {
      this.props.reportsStore
        .mediaInsertionSyntheticPeriods({
          media: selectedMedia.md5,
          fromDate: selectedMonth.value.format("X"),
          toDate: toDate.format("X"),
          fromHour,
          toHour,
        })
        .then((response) => {
          this.setState({
            chartData: response,
          });
        })
        .catch(() => {
          this.setState({
            chartData: null,
          });
        });
    } else if (reportType === "details") {
      this.props.reportsStore.getMediaInsertionLogs({
        media: selectedMedia.md5,
        fromDate: fromDate.format("X"),
        toDate: toDate.format("X"),
        fromHour,
        toHour,
        group: selectedGroup.token !== "all" ? selectedGroup.token : null,
        terminal:
          selectedTerminal.token !== "all" ? selectedTerminal.token : null,
        page,
        limit,
      });
    }
  }, 2000);

  setNewStateToUpdateLogData = (newState) => {
    this.setState({ page: 1, ...newState }, this.didWantToUpdateLogData);
  };

  onChangeSelectInput = (name, selectedValue) => {
    this.setNewStateToUpdateLogData({
      [name]: selectedValue,
    });
  };

  onChangeHour = (name, selectedHour) => {
    this.setNewStateToUpdateLogData({
      [name]: selectedHour.value,
    });
  };

  onChangeHourPicker = (name, newValue) => {
    this.setNewStateToUpdateLogData({
      [name]: newValue.format("HH:mm"),
    });
  };

  onChangeFromDate = (_, newValue) => {
    const toDate = this.state.toDate.isBefore(newValue)
      ? newValue
      : this.state.toDate;
    this.setNewStateToUpdateLogData({
      fromDate: newValue,
      toDate,
    });
  };

  onChangeToDate = (_, newValue) => {
    const fromDate = this.state.fromDate.isAfter(newValue)
      ? newValue
      : this.state.fromDate;
    this.setNewStateToUpdateLogData({
      fromDate,
      toDate: newValue,
    });
  };

  renderIcon = (icon, isValid, marginLeft = 0) => (
    <i
      className={`${icon} fa-fw`}
      style={{
        marginLeft,
        color: isValid ? "#4CD366" : "#dcdbdb",
      }}
    />
  );

  didChangePagination = ({ page, pageSize }) => {
    this.setNewStateToUpdateLogData({
      page,
      limit: pageSize,
    });
  };

  renderSyntheticChart = ({ totalInsertions, totalGroups, perDays }) => {
    const { classes, localizedString, localizedPlural } = this.props;
    const periods = {
      dawn: localizedString({
        id: "Period@dawnLabel",
        defaultString: "Madrugada (0-5)",
      }),
      morning: localizedString({
        id: "Period@morningLabel",
        defaultString: "Manhã (6-12)",
      }),
      evening: localizedString({
        id: "Period@eveningLabel",
        defaultString: "Tarde (13-18)",
      }),
      night: localizedString({
        id: "Period@nightLabel",
        defaultString: "Noite (19-23)",
      }),
    };
    return (
      <GridContainer>
        <GridItem xs={12}>
          <InfoLabel
            hideIcon
            large
            style={{ textAlign: "center", marginTop: 20 }}
          >
            <Translate
              id="Metrics@mediaInsertionsSyntheticInfo"
              variables={{
                totalInsertions: localizedPlural({
                  id: "Metrics@mediaInsertionsTotalInsertionsPlural",
                  value: totalInsertions,
                  zero: "-",
                  one: "# vez",
                  other: "# vezes",
                }),
                totalGroups: localizedPlural({
                  id: "Metrics@mediaInsertionsTotalGruposPlural",
                  value: totalGroups,
                  zero: "-",
                  one: "# grupo",
                  other: "# grupos",
                }),
              }}
            >
              A mídia foi exibida{" "}
              <span className={classes.syntheticNumber}>$totalInsertions</span>{" "}
              em <span className={classes.syntheticNumber}>$totalGroups</span>{" "}
              de acordo com o período.
            </Translate>
          </InfoLabel>
        </GridItem>
        <GridItem xs={12} style={{ marginTop: 30 }}>
          <LineChart
            height={400}
            xAxisCategories={Object.keys(perDays)}
            data={Object.entries(periods).map(([periodKey, periodString]) => ({
              name: periodString,
              data: Object.keys(perDays).map((dayString) => {
                const value =
                  perDays[dayString] &&
                  perDays[dayString][periodKey] &&
                  perDays[dayString][periodKey].total
                    ? perDays[dayString][periodKey].total
                    : 0;
                return value;
              }),
            }))}
            customOptions={chartColors}
            customTooltip={{
              y: {
                formatter: (value) => displayInteger(value, false),
              },
            }}
          />
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const {
      classes,
      reportsStore,
      i18nStore,
      localizedString,
      localizedPlural,
    } = this.props;
    const {
      reportType,
      selectedMedia,
      selectedGroup,
      selectedTerminal,
      fromHour,
      toHour,
    } = this.state;
    const { pages, rows, logs } = reportsStore.mediaInsertionLogs;

    return (
      <Fragment>
        <PageTitle id="Menu@informativeMediaReports">
          Relatório de mídias
        </PageTitle>
        <SimpleCard
          cardStyle={{ marginTop: 0 }}
          cardBodyStyle={{ padding: "0 16px" }}
        >
          <GridContainer>
            <UsageGridWidget type="storage" />
          </GridContainer>
        </SimpleCard>
        <SimpleCard cardStyle={{ zIndex: 1000 }}>
          <GridContainer>
            <GridItem xs={12}>
              <ButtonGroup
                label={
                  <Translate id="Metris@mediaInsertionChooseReportTypeLabel">
                    Tipo do relatório
                  </Translate>
                }
                name="reportType"
                value={reportType}
                color="success"
                large
                onChange={this.onChangeReportType}
                options={[
                  {
                    value: "synthetic",
                    label: localizedString({
                      id: "Metrics@mediaInsertionsSyntheticReportType",
                      defaultString: "Sintético",
                    }),
                  },
                  {
                    value: "details",
                    label: localizedString({
                      id: "Metrics@mediaInsertionsDetailsReportType",
                      defaultString: "Analítico",
                    }),
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={reportType === "details" ? 4 : 12}>
              <InputSelect
                label={
                  <Translate id="Metrics@mediaInsertionsSelectMediaLabel">
                    Selecione a mídia
                  </Translate>
                }
                name="selectedMedia"
                value={selectedMedia}
                isLoading={this.state.isLoadingMediasList}
                options={this.state.mediasList}
                onChange={this.onChangeSelectInput}
                labelKey="name"
                valueKey="md5"
              />
            </GridItem>
            {reportType === "details" && (
              <GridItem xs={12} sm={12} md={4}>
                <InputSelect
                  label={
                    <Translate id="Metrics@mediaInsertionsSelectGroupLabel">
                      Grupo
                    </Translate>
                  }
                  name="selectedGroup"
                  value={selectedGroup}
                  isLoading={this.state.isLoadingMediasList}
                  options={[allOption, ...this.state.groupsList]}
                  onChange={this.onChangeSelectInput}
                  labelKey="name"
                  valueKey="token"
                />
              </GridItem>
            )}
            {reportType === "details" && (
              <GridItem xs={12} sm={12} md={4}>
                <InputSelect
                  label={
                    <Translate id="Metrics@mediaInsertionsSelectTerminalLabel">
                      Filtre por terminal
                    </Translate>
                  }
                  name="selectedTerminal"
                  value={selectedTerminal}
                  isLoading={this.state.isLoadingMediasList}
                  options={[allOption, ...this.state.terminalsList]}
                  onChange={this.onChangeSelectInput}
                  labelKey="name"
                  valueKey="token"
                />
              </GridItem>
            )}
            {reportType === "details" && (
              <Fragment>
                <GridItem xs={12} sm={6} md={3}>
                  <DatePicker
                    label={
                      <Translate id="Advertising@startDateFilter">
                        Data início
                      </Translate>
                    }
                    name="fromDate"
                    value={this.state.fromDate}
                    onChange={this.onChangeFromDate}
                    isValidDate={(currentDate) =>
                      currentDate.isSameOrBefore(endOfTheDayMoment())
                    }
                    fullWidth
                    hideTime
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <DatePicker
                    label={
                      <Translate id="Advertising@endDateFilter">
                        Data fim
                      </Translate>
                    }
                    name="toDate"
                    value={this.state.toDate}
                    onChange={this.onChangeToDate}
                    isValidDate={(currentDate) =>
                      currentDate.isSameOrBefore(endOfTheDayMoment())
                    }
                    fullWidth
                    hideTime
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <DatePicker
                    label={
                      <Translate id="Schedule@startHour">Hora início</Translate>
                    }
                    name="fromHour"
                    value={startOfTheDayMoment({
                      hour: fromHour.split(":")[0],
                      minute: fromHour.split(":")[1],
                    })}
                    onChange={this.onChangeHourPicker}
                    fullWidth
                    hideDate
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <DatePicker
                    label={
                      <Translate id="Schedule@endHour">Hora fim</Translate>
                    }
                    name="toHour"
                    value={startOfTheDayMoment({
                      hour: toHour.split(":")[0],
                      minute: toHour.split(":")[1],
                    })}
                    onChange={this.onChangeHourPicker}
                    fullWidth
                    hideDate
                  />
                </GridItem>
              </Fragment>
            )}
            {reportType === "synthetic" && (
              <Fragment>
                <GridItem xs={12} sm={12} md={6}>
                  <InputSelect
                    label={
                      <Translate id="Advertising@selectMonthFilterLabel">
                        Mês
                      </Translate>
                    }
                    name="selectedMonth"
                    value={this.state.selectedMonth}
                    options={this.monthsOptions()}
                    onChange={this.onChangeSelectInput}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                  <InputSelect
                    label={
                      <Translate id="Advertising@fromHourFilter">
                        Hora início
                      </Translate>
                    }
                    name="fromHour"
                    value={{ label: fromHour, value: fromHour }}
                    options={hoursOptions()}
                    onChange={this.onChangeHour}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <InputSelect
                    label={
                      <Translate id="Advertising@toHourFilter">
                        Hora fim
                      </Translate>
                    }
                    name="toHour"
                    value={{ label: toHour, value: toHour }}
                    options={hoursOptions()}
                    onChange={this.onChangeHour}
                  />
                </GridItem>
              </Fragment>
            )}
          </GridContainer>
        </SimpleCard>
        {reportType === "synthetic" && selectedMedia && (
          <SimpleCard cardStyle={{ marginTop: 8, marginBottom: 8 }}>
            {reportsStore.isFetching ? (
              <div style={{ textAlign: "center" }}>
                <LoadingView></LoadingView>
              </div>
            ) : this.state.chartData ? (
              this.renderSyntheticChart(this.state.chartData)
            ) : (
              <InfoLabel style={{ textAlign: "center", margin: "10px 0" }}>
                <Translate id="Connectivity@noReportDataMessage">
                  Não encontramos informações relevantes para exibir neste
                  momento.
                </Translate>
              </InfoLabel>
            )}
          </SimpleCard>
        )}
        {reportType === "details" && selectedMedia && (
          <SimpleCard cardStyle={{ marginTop: 8, marginBottom: 8 }}>
            <GridContainer>
              <GridItem xs={12}>
                <Table
                  didChangePagination={this.didChangePagination}
                  manual
                  totalPages={pages}
                  page={this.state.page}
                  pageSize={this.state.limit}
                  isLoading={reportsStore.isFetching}
                  data={logs.map((log) => {
                    return {
                      ...log,
                      date: stringDateFromUnixDate(
                        log.played_date,
                        `${i18nStore.dateFormatWithFourDigitsYear} ${i18nStore.timeFormatWithSeconds}`
                      ),
                    };
                  })}
                  columns={[
                    {
                      Header: localizedString({
                        id: "Common@date",
                        defaultString: "Data",
                      }),
                      accessor: "date",
                    },
                    {
                      Header: localizedString({
                        id: "Connectivity@group",
                        defaultString: "Grupo",
                      }),
                      accessor: "group_name",
                    },
                    {
                      Header: localizedString({
                        id: "Metrics@device",
                        defaultString: "Dispositivo",
                      }),
                      accessor: "terminal_name",
                      getHeaderProps: () => {
                        return {
                          style: {
                            textAlign: "left",
                          },
                        };
                      },
                      getProps: () => {
                        return {
                          style: {
                            textAlign: "left",
                          },
                        };
                      },
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </SimpleCard>
        )}
        {reportType === "details" && selectedMedia && (
          <GridContainer>
            <GridItem xs={12} className={classes.footerContainer}>
              <InfoLabel hideIcon>
                {localizedPlural({
                  id: "Metrics@mediaInsertionsQuantity",
                  value: rows,
                  zero: "Nenhuma inserção",
                  one: "uma inserção",
                  other: "# inserções",
                })}
              </InfoLabel>
            </GridItem>
          </GridContainer>
        )}
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(InformativeReportPage),
  pageAllowedRule: "informative",
});
