import { showPhoneIfValid } from "../utils/PhoneUtils";

const parseName = (name) => (name !== "null" && name.length > 1 ? name : "");

const parseGender = (gender, localizedString) => {
  if (gender.toLowerCase() === "m") {
    return localizedString({ id: "Common@male", defaultString: "Masculino" });
  }
  if (gender.toLowerCase() === "f") {
    return localizedString({
      id: "Common@female",
      defaultString: "Feminino",
    });
  }

  return "";
};

const parseLanguage = (language, localizedString) => {
  if (language.toLowerCase() === "pt") {
    return localizedString({
      id: "I18n@portuguese",
      defaultString: "Português",
    });
  }
  if (language.toLowerCase() === "en") {
    return localizedString({
      id: "I18n@english",
      defaultString: "Inglês",
    });
  }

  return "";
};

const parsePhone = (phoneNumber) => showPhoneIfValid(phoneNumber, "");

class PeopleModel {
  name;
  gender;
  language;
  phoneNumber;
  socialNetworks;
  email;
  birth;
  profileImgUrl;

  constructor(data, localizedString) {
    this.name = parseName(data.name || "");
    this.gender = parseGender(data.gender || "", localizedString);
    this.language = parseLanguage(data.language || "", localizedString);
    this.phoneNumber = parsePhone(data.number);
    this.socialNetworks = {
      whatsApp: data.whatsapp === "True",
      facebook: data.facebook === "True",
    };
    this.email = data.email || "";
    this.birth = {
      day: data.birth_day || "",
      month: data.birth_month || "",
      year: data.birth_year || "",
    };
    this.profileImgUrl = data.profile_image || null;
  }
}

export default PeopleModel;
