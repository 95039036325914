// @ts-nocheck
import { Component } from "react";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import PropTypes from "prop-types";

const defaultButonStyle = {
  position: "absolute",
  background: "transparent",
  top: 2,
  zIndex: 1000,
  height: 30,
  width: 30,
};

const style = {
  minusButton: {
    ...defaultButonStyle,
    right: 32,
  },
  addButton: {
    ...defaultButonStyle,
    right: 2,
  },
};

class InputNumber extends Component {
  onChangeNumber = (values, event) => {
    const { name, dataset } = event.target;
    const { maxValue } = this.props;

    const value =
      parseInt(values.value, 10) > maxValue ? `${maxValue}` : values.value;

    this.props.onChange({
      name,
      dataset,
      value,
    });
  };

  changedValue = (stringValue, newValue) => {};

  decrementNumber = (event) => {
    event.preventDefault();
    const { value, name, onChange } = this.props;

    let newValue = parseInt(value, 10) - 1;

    if (value === "") {
      newValue = 0;
    }
    if (newValue < 0) {
      newValue = 0;
    }

    onChange({
      name,
      value: newValue.toString(),
    });
  };

  incrementNumber = (event) => {
    event.preventDefault();
    const { value, maxValue, name, onChange } = this.props;
    let newValue = parseInt(value, 10) + 1;

    if (value === "") {
      newValue = 0;
    }
    if (newValue > maxValue) {
      newValue = `${maxValue}`;
    }

    onChange({
      name,
      value: newValue.toString(),
    });
  };

  render() {
    const {
      value,
      maxValue,
      name,
      label,
      icon,
      placeholder,
      isRequired,
      helpText,
      hasError,
      hasSuccess,
    } = this.props;
    return (
      <div
        className={classnames({
          "form-group": true,
          "has-error": hasError,
          "has-success": hasSuccess,
        })}
      >
        <label
          htmlFor={name}
          className="control-label"
          style={{
            position: "relative",
          }}
        >
          {label}{" "}
          {isRequired && (
            <i
              className="fa fa-asterisk"
              style={{
                position: "absolute",
                top: 4,
                right: -12,
                fontSize: 8,
              }}
            />
          )}
        </label>
        <div
          className={classnames({
            "input-group": icon,
          })}
        >
          {icon && (
            <span className="input-group-addon">
              <i className={`fa fa-fw fa-${icon}`} />
            </span>
          )}
          <NumberFormat
            type="text"
            id={name}
            name={name}
            placeholder={placeholder}
            className="form-control"
            value={value}
            data-required={isRequired ? "true" : "false"}
            isNumericString={true}
            decimalSeparator=","
            allowNegative={false}
            onValueChange={this.onChangeNumber}
          />
          <button style={style.minusButton} onClick={this.decrementNumber}>
            <i className="fa fa-chevron-down" />
          </button>
          <button
            style={style.addButton}
            disabled={value === maxValue}
            onClick={this.incrementNumber}
          >
            <i className="fa fa-chevron-up" />
          </button>
        </div>
        {helpText && (
          <span id={`${name}-help-block`} className="help-block">
            <i className="fa fa-info-circle" /> {helpText}
          </span>
        )}
      </div>
    );
  }
}

InputNumber.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  isRequired: PropTypes.bool,
  hasError: PropTypes.bool,
  hasSuccess: PropTypes.bool,
  helpText: PropTypes.string,
};

export default InputNumber;
