// @ts-nocheck


import { withLocalized } from "../HOC";
import { ButtonGroup } from "../Form";

const TargetAudienceFilter = ({
  localizedString,
  targetAudience,
  onChange,
}) => (
  <ButtonGroup
    small
    name="targetAudience"
    value={targetAudience === null ? 0 : targetAudience}
    color="primary"
    onChange={(_: string, selectedValue: number) => onChange(selectedValue)}
    options={[
      {
        value: "internal",
        label: localizedString({
          id: "Metrics@targetAudienceInternalOption",
          defaultString: "Dentro do ambiente",
        }),
      },
      {
        value: "external",
        label: localizedString({
          id: "Metrics@targetAudienceExternalOption",
          defaultString: "Fora do ambiente",
        }),
      },
      {
        value: "all",
        label: localizedString({
          id: "Metrics@targetAudienceAllOption",
          defaultString: "Todos",
        }),
      },
    ]}
  />
);

export default withLocalized(TargetAudienceFilter);
