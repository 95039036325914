// @ts-nocheck


import withStyles from "@material-ui/core/styles/withStyles";
import { InputText } from "../Form";
import { withLocalized } from "../HOC";

// Style
const styles = {
  formControl: {
    padding: "12px 0",
    margin: 0,
  },
};

const ChatInput = withStyles(styles)(
  withLocalized(
    ({ classes, localizedString, label, message, onChangeMessage }) => (
      <InputText
        name="message"
        placeholder={
          label
            ? label
            : localizedString({
                id: "Chat@messagePlaceholder",
                defaultString: "Escreva a mensagem...",
              })
        }
        formControlProps={{ className: classes.formControl }}
        inputProps={{
          autoComplete: "off",
        }}
        value={message}
        onChange={onChangeMessage}
        autoFocus
        multiline
      />
    )
  )
);

export default ChatInput;
