// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import debounce from "lodash/debounce";

import AdvertisingSendMessageAdvancedView from "./AdvertisingSendMessageAdvancedView";
import AdvertisingSendMessageBasicView from "./AdvertisingSendMessageBasicView";

// Translate
import Translate from "../../components/I18n/Translate";

//Utils
import predefinedMessage, {
  PREDEFINED_MESSAGES_KEYS,
} from "../../store/ChatStore/PredefinedMessages";
import { makeCancelable } from "../../utils/PromiseUtils";
import { startOfTheDayMoment, endOfTheDayMoment } from "../../utils/DateUtils";

@inject(
  "authStore",
  "advertisingStore",
  "chatStore",
  "contractStore",
  "groupsStore",
  "notificationsStore"
)
@observer
class AdvertisingSendMessageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    this.props.groupsStore.getGroupsList();

    this.updateCustomersCount();
  }

  componentWillUnmount() {
    this.updateCustomersCount.cancel();
    if (this.updateCustomersCountPromise) {
      this.updateCustomersCountPromise.cancel();
    }
  }

  getDefaultState = () => {
    const { sms, resources: { networking } } = this.props.contractStore.selectedContract;
    return {
      customersCount: 0,
      smsUsage: sms.usage,
      smsLimit: sms.limit,
      whereFilterGroup: networking ? "any" : "aSpecificDate",
      fromDate: startOfTheDayMoment().subtract(7, "days"),
      toDate: endOfTheDayMoment(),
      connectedInTheLastValue: "30",
      notConnectedInTheLastValue: "7",
      selectedGroup: "any",
      selectedDevice: "any",
      phoneNumber: "",
      occurrencesOptions: "any",
      occurrences: "",
      occurrencesUntil: "exactly",
      gender: "all",
      ageGroup: "all",
      limitPeople: 0,
      isFetchingCustomers: true,
      isWaitingForm: false,
      formWasSubmit: false,
      isValid: {
        name: true,
        email: true,
      },
    };
  };

  onChangeRadioGroup = (name, value) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.updateCustomersCount();
      }
    );
  };

  onChangeOccurrencesOptions = (_, selectedValue) => {
    let newState = {
      occurrencesOptions: selectedValue,
      occurrencesUntil: "exactly",
      occurrences: "",
    };
    if (selectedValue.includes("lessThan")) {
      newState = {
        occurrencesOptions: selectedValue,
        occurrencesUntil: "or less",
        occurrences: selectedValue.replace("lessThan", ""),
      };
    } else if (selectedValue.includes("moreThan")) {
      newState = {
        occurrencesOptions: selectedValue,
        occurrencesUntil: "or more",
        occurrences: selectedValue.replace("moreThan", ""),
      };
    }
    this.setState(newState, () => {
      this.updateCustomersCount();
    });
  };

  onChangeGenderOption = (_, selectedValue) => {
    this.setState({ gender: selectedValue }, () => {
      this.updateCustomersCount();
    });
  };

  onChangeSelectInput = ({ target }) => {
    const { name, value } = target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.updateCustomersCount();
      }
    );
  };

  onChangeSelectedGroup = ({ target }) => {
    this.setState(
      {
        selectedGroup: target.value,
        selectedDevice: "any",
      },
      () => {
        this.updateCustomersCount();
      }
    );
  };

  onChangeDatePicker = (name, newValue) => {
    const hoursValue =
      name === "fromDate"
        ? { hour: 0, minute: 0, second: 0, millisecond: 0 }
        : { hour: 23, minute: 59, second: 59, millisecond: 0 };
    this.setState(
      {
        [name]: newValue.set(hoursValue),
      },
      () => {
        this.updateCustomersCount();
      }
    );
  };

  onChangeLimitPeopleSlider = (render, handle, value, un, percent) => {
    const result = parseInt(value[0], 10);

    this.setState({
      limitPeople: result,
    });
  };

  prepareAdvertisingFiltersParams = ({
    whereFilterGroup,
    fromDate,
    toDate,
    connectedInTheLastValue,
    notConnectedInTheLastValue,
    selectedGroup,
    selectedDevice,
    phoneNumber,
    occurrences,
    occurrencesUntil,
    gender,
    ageGroup,
  }) => ({
    connectedInTheLastValue:
      whereFilterGroup === "connectedInTheLast"
        ? connectedInTheLastValue
        : undefined,
    notConnectedInTheLastValue:
      whereFilterGroup === "notConnectedInTheLast"
        ? notConnectedInTheLastValue
        : undefined,
    isOnlineNow: whereFilterGroup === "isOnlineNow" ? true : undefined,
    fromDate:
      whereFilterGroup === "aSpecificDate" ? fromDate.format("X") : undefined,
    toDate:
      whereFilterGroup === "aSpecificDate" ? toDate.format("X") : undefined,
    groupToken:
      selectedGroup !== "any" && selectedDevice === "any"
        ? selectedGroup
        : undefined,
    terminalToken:
      selectedGroup !== "any" && selectedDevice !== "any"
        ? selectedDevice
        : undefined,
    occurrences: occurrences.length > 0 ? occurrences : undefined,
    occurrencesUntil:
      phoneNumber.length > 0 || occurrences.length > 0
        ? occurrencesUntil
        : undefined,
    gender: gender !== "all" ? gender : undefined,
    ageGroup:
      ageGroup !== "all"
        ? this.props.authStore.ageRanges[parseInt(ageGroup, 10)].range
        : undefined,
    phoneNumber: phoneNumber.length > 0 ? phoneNumber : undefined,
  });

  requestAdvertisingMessages = (data) => {
    this.updateCustomersCountPromise = makeCancelable(
      this.props.advertisingStore.requestAdvertisingMessages(data)
    );

    return this.updateCustomersCountPromise.promise;
  };

  updateCustomersCount = debounce(() => {
    if (this.updateCustomersCountPromise) {
      this.updateCustomersCountPromise.cancel();
    }
    this.setState(
      {
        isFetchingCustomers: true,
      },
      () => {
        this.requestAdvertisingMessages(
          this.prepareAdvertisingFiltersParams(this.state)
        )
          .then((data) => {
            this.setState((state) => {
              const customersCount = parseInt(data.customers_count, 10);
              const smsUsage = data.sms_usage
                ? parseInt(data.sms_usage, 10)
                : state.smsUsage;
              const smsLimit = data.sms_limit
                ? parseInt(data.sms_limit, 10)
                : state.smsLimit;

              return {
                isFetchingCustomers: false,
                customersCount,
                smsUsage,
                smsLimit,
                limitPeople: customersCount,
              };
            });
          })
          .catch((error) => {
            window.debugError("error in requestAdvertisingMessages", error);
          });
      }
    );
  }, 1000);

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { advertisingStore, notificationsStore } = this.props;

    if (advertisingStore.message.length < 1) {
      notificationsStore.addSnackbarNotification({
        color: "danger",
        message: (
          <Translate id="Advertising@messageErrorMessage">
            Informe a mensagem que deseja enviar.
          </Translate>
        ),
        timeout: 4000,
      });
      return false;
    }

    this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
      const { smsUsage, smsLimit, limitPeople } = this.state;
      const availableCredits = smsLimit - smsUsage;
      advertisingStore
        .submitAdvertisingMessages({
          ...this.prepareAdvertisingFiltersParams(this.state),
          message: this.props.advertisingStore.message,
          limitPeople:
            limitPeople <= availableCredits ? limitPeople : availableCredits,
        })
        .then((data) => {
          this.setState(
            {
              ...this.getDefaultState(),
              smsUsage: parseInt(data.sms_usage, 10),
              smsLimit: parseInt(data.sms_limit, 10),
            },
            () => {
              this.updateCustomersCount();
              notificationsStore.addSnackbarNotification({
                color: "success",
                message: data.message,
                timeout: 4000,
              });
            }
          );
        });
    });
  };

  didWantToBuySMS = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.chatStore.didWantToStartNewChatWithSubjectAndMessage(
      localizedString({
        id: "ContractLimit@requestSmsCreditsSubject",
        defaultString: "Aumentar créditos SMS",
      }),
      predefinedMessage(
        PREDEFINED_MESSAGES_KEYS.requestSmsCredits,
        localizedString
      )
    );
  };

  render() {
    const { smsLimit, smsUsage } = this.state;
    const availableCredits = smsLimit > smsUsage ? smsLimit - smsUsage : 0;

    const {
      resources,
      isAffiliateContract,
    } = this.props.contractStore.selectedContract;

    if (resources.connectivity) {
      return (
        <AdvertisingSendMessageAdvancedView
          onChangeRadioGroup={this.onChangeRadioGroup}
          onChangeDatePicker={this.onChangeDatePicker}
          onChangeSelectInput={this.onChangeSelectInput}
          onChangeSelectedGroup={this.onChangeSelectedGroup}
          onChangeOccurrencesOptions={this.onChangeOccurrencesOptions}
          onChangeGenderOption={this.onChangeGenderOption}
          onChangeLimitPeopleSlider={this.onChangeLimitPeopleSlider}
          onSubmit={this.onSubmit}
          didWantToBuySMS={this.didWantToBuySMS}
          availableCredits={availableCredits}
          isAffiliateContract={isAffiliateContract}
          {...this.state}
        />
      );
    }

    return (
      <AdvertisingSendMessageBasicView
        onChangeDatePicker={this.onChangeDatePicker}
        onSubmit={this.onSubmit}
        didWantToBuySMS={this.didWantToBuySMS}
        availableCredits={availableCredits}
        isAffiliateContract={isAffiliateContract}
        {...this.state}
      />
    );
  }
}

export default AdvertisingSendMessageContainer;
