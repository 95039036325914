// @ts-nocheck
import { Fragment } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Price from "./Price";

// Style
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  actualPrice: {
    display: "inline-block",
    fontWeight: 500,
  },
  normalPrice: {
    textDecoration: "line-through",
    opacity: 0.6,
    marginRight: 6,
    fontSize: "0.8em",
    display: "inline-block",
  },
};

const PriceContainer = ({
  classes,
  price,
  offerPrice,
  containerStyle,
  actualPriceColor,
}) => {
  const normalPrice = offerPrice ? price : null;
  const actualPrice = offerPrice ? offerPrice : price;

  return (
    <div style={containerStyle}>
      {normalPrice && (
        <Fragment>
          <Price className={classes.normalPrice}>{normalPrice}</Price>
          <i
            className="fas fa-long-arrow-alt-right"
            style={{ marginRight: 6 }}
          />
        </Fragment>
      )}
      <Price
        className={classes.actualPrice}
        style={{ color: actualPriceColor }}
      >
        {actualPrice}
      </Price>
    </div>
  );
};

PriceContainer.defaultProps = {
  containerStyle: {
    fontSize: "2em",
    padding: 30,
    textAlign: "center",
  },
  actualPriceColor: primaryColor,
};

export default withStyles(styles)(PriceContainer);
