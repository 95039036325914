// @ts-nocheck


import { withLocalized } from "../HOC";
import { ButtonGroup } from "../Form";

const ConnectionTypeFilter = ({
  localizedString,
  connectionType,
  onChange,
}) => (
  <ButtonGroup
    small
    name="connectionType"
    value={connectionType === null ? 4 : connectionType}
    color="primary"
    onChange={(_, selectedValue) =>
      onChange(selectedValue === 4 ? null : selectedValue)
    }
    options={[
      {
        value: 0,
        label: localizedString({
          id: "Metrics@connectionTypeAnonymousOption",
          defaultString: "Anônimos",
        }),
      },
      {
        value: 1,
        label: localizedString({
          id: "Metrics@connectionTypeConnectedOption",
          defaultString: "Conectados",
        }),
      },
      {
        value: 2,
        label: localizedString({
          id: "Metrics@connectionTypeAuthenticatedOption",
          defaultString: "Autenticados",
        }),
      },
      {
        value: 4,
        label: localizedString({
          id: "Common@all",
          defaultString: "Todos",
        }),
      },
    ]}
  />
);

export default withLocalized(ConnectionTypeFilter);
