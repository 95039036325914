const ptRssOptions = [
  {
    value: "http://pox.globo.com/rss/g1/",
    label: "G1: Geral",
  },
  {
    value: "http://pox.globo.com/rss/g1/brasil/",
    label: "G1: Notícias do Brasil",
  },
  {
    value: "http://pox.globo.com/rss/g1/economia/",
    label: "G1: Economia",
  },
  {
    value: "http://pox.globo.com/rss/g1/tecnologia/",
    label: "G1: Tecnologia e Games",
  },
  {
    value: "http://pox.globo.com/rss/g1/carros/",
    label: "Autoesporte.com",
  },
  {
    value:
      "http://globoesporte.globo.com/servico/semantica/editorias/plantao/feed.rss",
    label: "Globoesporte.com",
  },
  {
    value: "http://rss.home.uol.com.br/index.xml",
    label: "UOL: Geral",
  },
  {
    value: "http://rss.uol.com.br/feed/noticias.xml",
    label: "UOL: Últimas notícias",
  },
  {
    value: "http://rss.terra.com.br/0,,EI4795,00.xml",
    label: "Terra: Brasil",
  },
  {
    value: "https://www.nasa.gov/rss/dyn/lg_image_of_the_day.rss",
    label: "Nasa",
  },
];
const enRssOptions = [
  { value: "http://feeds.bbci.co.uk/news/rss.xml", label: "BBC: Top Stories" },
  { value: "http://feeds.bbci.co.uk/news/world/rss.xml", label: "BBC: World" },
  {
    value: "http://feeds.bbci.co.uk/news/business/rss.xml",
    label: "BBC: Business",
  },
  {
    value: "http://feeds.bbci.co.uk/news/technology/rss.xml",
    label: "BBC: Technology",
  },
  {
    value: "http://www.nytimes.com/services/xml/rss/nyt/HomePage.xml",
    label: "New York Times: Home",
  },
  {
    value: "http://www.nytimes.com/services/xml/rss/nyt/World.xml",
    label: "New York Times: World",
  },
  {
    value: "http://feeds.nytimes.com/nyt/rss/Business",
    label: "New York Times: Business",
  },
  {
    value: "http://www.nytimes.com/services/xml/rss/nyt/Sports.xml",
    label: "New York Times: Sports",
  },
  { value: "http://feeds.feedburner.com/TechCrunch/", label: "TechCrunch" },
  {
    value: "https://www.nasa.gov/rss/dyn/breaking_news.rss",
    label: "Nasa: Breaking News",
  },
  { value: "http://hbswk.hbs.edu/rss.html", label: "Harvard Business School" },
];

export const getRssOptionsForLanguage = (language) => {
  switch (language) {
    case "pt":
      return ptRssOptions;

    case "en":
      return enRssOptions;

    default:
      return [...ptRssOptions, ...enRssOptions];
  }
};
