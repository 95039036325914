// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import DragDrop from "@uppy/react/lib/DragDrop";
import StatusBar from "@uppy/react/lib/StatusBar";

import { GridContainer, GridItem } from "../Grid";

import LoadingView from "../LoadingView/LoadingView";

import { EMediaTemplate } from "../../types/Media";

import { isObject } from "../../utils/Utils";
import ContractStore from "../../store/ContractStore/ContractStore";
import I18nStore from "../../store/I18nStore/I18nStore";
import NotificationsStore from "../../store/NotificationsStore/NotificationsStore";

import AutonomediaService, {
  AutonomediaMethods,
} from "../../services/AutonomediaService";

interface IUppyFileUploadState {
  uppy: any;
}
interface IUppyFileUploadProps {
  template: EMediaTemplate;
  maxNumberOfFiles?: number;
  minNumberOfFiles?: number;
  mediaDuration?: number;
  resetAfterUpload?: boolean;
  label?: string;
  dropHereText?: string;
  browseText?: string;
  customNote?: string;
  hideDragDropContainer?: boolean;
  height?: string | number;
  width?: string | number;
  hideStatusBar?: boolean;
  allowedFileTypes?: Array<string>;
  additionalMeta?: object;
  didChangeFiles: (filesList: Array<any>) => void;
  changeIsUploadingState?: (newValue: boolean) => void;
  uploadProgressDidChange?: (file: any, progress: number) => void;
}

interface InjectedProps extends IUppyFileUploadProps {
  contractStore: ContractStore;
  i18nStore: I18nStore;
  notificationsStore: NotificationsStore;
  autonomediaService: AutonomediaService;
}

@inject(
  "contractStore",
  "i18nStore",
  "notificationsStore",
  "autonomediaService"
)
@observer
class UppyFileUpload extends Component<
  IUppyFileUploadProps,
  IUppyFileUploadState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  state = { uppy: null };

  componentDidUpdate(prevProps: IUppyFileUploadProps) {
    if (
      prevProps.template !== this.props.template ||
      prevProps.mediaDuration !== this.props.mediaDuration ||
      JSON.stringify(prevProps.additionalMeta) !==
        JSON.stringify(this.props.additionalMeta)
    ) {
      this.resetUppy();
    }
  }

  componentDidMount() {
    this.setUppy();
  }

  componentWillUnmount() {
    this.closeUppy();
  }

  resetUppy = () => {
    this.closeUppy();

    this.setState(
      {
        uppy: null,
      },
      () => {
        this.setUppy();
      }
    );
  };

  changeIsUploadingState = (newState) => {
    if (this.props.changeIsUploadingState) {
      this.props.changeIsUploadingState(newState);
    }
  };

  closeUppy = () => {
    if (this.state.uppy) {
      this.state.uppy.close();
    }
  };

  setUppy = () => {
    const { endpointForMethod } = this.injected.autonomediaService;
    const locale =
      this.injected.i18nStore.currentLanguage === "pt"
        ? {
            strings: {
              creatingAssembly: "Preparando para enviar...",
              preparingUpload: "Preparando para enviar...",
              youCanOnlyUploadX: {
                0: "Você pode enviar %{smart_count} arquivo",
                1: "Você pode enviar até %{smart_count} arquivos",
              },
              youHaveToAtLeastSelectX: {
                0: "Você precisa enviar pelo menos %{smart_count} arquivo",
                1: "Você precisa enviar pelo menos %{smart_count} arquivos",
              },
              exceedsSize: "Esse arquivo excede o tamanho máximo permitido de",
              youCanOnlyUploadFileTypes:
                "Você pode enviar os seguintes tipos de arquivos: %{types}",
              uppyServerError:
                "Erro ao comunicar com o servidor. Por favor tente novamente.",
            },
          }
        : null;
    const {
      maxNumberOfFiles,
      minNumberOfFiles,
      mediaDuration,
      allowedFileTypes,
      template,
      additionalMeta,
    } = this.props;

    const { selectedContract } = this.injected.contractStore;

    let meta = {
      template: template,
      host: selectedContract.fileServer,
      path: selectedContract.path,
      duration: mediaDuration,
      framerate: mediaDuration * 25 - 10,
      minio_server: selectedContract.minio_server,
    };
    if (additionalMeta && isObject(additionalMeta)) {
      meta = {
        ...meta,
        ...additionalMeta,
      };
    }

    const uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: this.maxSizeForTemplate(template),
        maxNumberOfFiles: maxNumberOfFiles,
        minNumberOfFiles: minNumberOfFiles,
        allowedFileTypes: allowedFileTypes,
      },
      meta,
      locale,
      timeout: 0,
    })
      .use(XHRUpload, {
        endpoint: endpointForMethod(AutonomediaMethods.submitMedia),
        method: "post",
        formData: true,
        timeout: 0,
        fieldName: "media_file",
        headers: {
          Authorization: `Basic bW9kYm94LTEuNjpCNjE1M0YxMg==`,
        },
        validateStatus: (statusCode, responseText, response) => {
          window.debugLog("validateStatus -> statusCode", statusCode);
          window.debugLog("validateStatus -> responseText", responseText);
          window.debugLog("validateStatus -> response", response);
          return statusCode === 200;
        },
        getResponseError(responseText, response) {
          return new Error(JSON.parse(responseText).message);
        },
      })
      .on("upload-success", (file, response) => {
        window.debugLog("SUCCESS!");
        window.debugLog("response.status", response.status); // HTTP status code
        window.debugLog("response.body", response.body); // extracted response data
      })
      .on("upload-progress", (file, progress) => {
        if (this.props.uploadProgressDidChange) {
          this.props.uploadProgressDidChange(file, progress);
        }
      })
      .on("upload", (data) => {
        this.changeIsUploadingState(true);
      })
      .on("cancel-all", () => {
        window.debugLog("uppy cancel-all");
        this.changeIsUploadingState(false);
      })
      .on("error", (error) => {
        console.error(error);
        this.changeIsUploadingState(false);
        window.debugError("uppy error uploading", error);
      })
      .on("upload-error", (file, error, response) => {
        uppy.reset();

        this.injected.notificationsStore.addSnackbarNotification({
          color: "warning",
          message: response.body.error ?? "error",
        });

        this.changeIsUploadingState(false);
        window.debugError("uppy error uploading", error);
      })
      .on("info-visible", () => {
        // const info = uppy.getState().info;
        // if (info.type === "error") {
        //   this.injected.notificationsStore.addSnackbarNotification({
        //     color: "warning",
        //     message: info.message,
        //   });
        // }
      })
      .on("complete", (result) => {
        window.debugLog("completed with result", result);
        this.changeIsUploadingState(false);
        let files = [];

        if (Array.isArray(result.successful)) {
          result.successful.forEach((upload) => {
            if (upload.response && upload.response.body) {
              const { media } = upload.response.body;
              console.log({ media });
              files.push({
                name: media.media_original,
                fileHash: media.media_md5,
                is_image: media?.is_image === "true",
                duration:
                  media?.duration ?? (this.props.mediaDuration || undefined),
                // url: media.video_1920x1080,
                url: media.url_media,
              });
            }
          });
          this.props.didChangeFiles(files);
        }

        if (this.props.resetAfterUpload) {
          this.resetUppy();
        }
      });

    this.setState({
      uppy,
    });
  };

  errorMsgMetaForTemplate = (template: EMediaTemplate) => {
    const { localizedString } = this.injected.i18nStore;

    switch (template) {
      case "mediaFiles":
      case EMediaTemplate.bannerWifi:
      case EMediaTemplate.logotype:
        return localizedString({
          id: "Upload@1mbImagesLabel",
          defaultString: "Aceitamos arquivos de imagem com até 1MB.",
        });

      case EMediaTemplate.videoOriginal:
      default:
        return localizedString(
          {
            id: "Upload@mediaFilesLabel",
            defaultString:
              " Aceitamos arquivos de vídeo ou imagem com até $maxFileSize.",
          },
          {
            maxFileSize: "3GB",
          }
        );
    }
  };

  maxSizeForTemplate = (template: EMediaTemplate) => {
    switch (template) {
      case EMediaTemplate.videoOriginal:
        return 3 * 1024 * 1024 * 1024;

      case EMediaTemplate.bannerWifi:
      case EMediaTemplate.logotype:
      default:
        return 1000000;
    }
  };

  getStatusBarLocale = () => {
    if (this.injected.i18nStore.currentLanguage === "pt") {
      return {
        strings: {
          // Shown in the status bar while files are being uploaded.
          uploading: "Enviando...",
          // Shown in the status bar once all files have been uploaded.
          complete: "Terminou!",
          // Shown in the status bar if an upload failed.
          uploadFailed: "Falha ao enviar",
          // Shown next to `uploadFailed`.
          pleasePressRetry: "Pressione repetir para tentar novamente",
          // Shown in the status bar while the upload is paused.
          paused: "Pausado",
          error: "Erro",
          // Used as the label for the button that retries an upload.
          retry: "Repetir",
          // Used as the label for the button that cancels an upload.
          cancel: "Cancelar",
          // Used as the screen reader label for the button that retries an upload.
          retryUpload: "Repetir upload",
          // Used as the screen reader label for the button that pauses an upload.
          pauseUpload: "Pausar upload",
          // Used as the screen reader label for the button that resumes a paused upload.
          resumeUpload: "Continuar upload",
          // Used as the screen reader label for the button that cancels an upload.
          cancelUpload: "Cancelar upload",
          // When `showProgressDetails` is set, shows the number of files that have been fully uploaded so far.
          filesUploadedOfTotal: {
            0: "%{complete} de %{smart_count} arquivo enviado",
            1: "%{complete} de %{smart_count} arquivos enviados",
          },
          // When `showProgressDetails` is set, shows the amount of bytes that have been uploaded so far.
          dataUploadedOfTotal: "%{complete} de %{total}",
          // When `showProgressDetails` is set, shows an estimation of how long the upload will take to complete.
          xTimeLeft: "%{time} restante",
          preparingUpload: "Preparando para enviar...",
          // Used as the label for the button that starts an upload.
          uploadXFiles: {
            0: "Upload %{smart_count} arquivo",
            1: "Upload %{smart_count} arquivos",
          },
          // Used as the label for the button that starts an upload, if another upload has been started in the past
          // and new files were added later.
          uploadXNewFiles: {
            0: "Upload +%{smart_count} arquivo",
            1: "Upload +%{smart_count} arquivos",
          },
        },
      };
    }

    return null;
  };

  render() {
    const {
      label,
      template,
      dropHereText,
      browseText,
      customNote,
    } = this.props;
    const { localizedString } = this.injected.i18nStore;
    const note = customNote || this.errorMsgMetaForTemplate(template);

    const localeStrings = {
      dropHereOr:
        dropHereText ||
        localizedString({
          id: "Upload@dragAndDropMessage",
          defaultString: "Arraste e solte o(s) arquivo(s) aqui ou %{browse}",
        }),
      browse:
        browseText ||
        localizedString({
          id: "Upload@addFromDeviceMessage",
          defaultString: "adicione de seu dispositivo",
        }),
    };

    if (!this.state.uppy) {
      return <LoadingView />;
    }

    return (
      <GridContainer style={{ marginTop: label ? 30 : 0 }}>
        <GridItem xs={12}>
          {label && (
            <label
              style={{
                fontSize: 13,
                fontWeight: 500,
                lineHeight: 1.42857,
                textTransform: "uppercase",
              }}
            >
              {label}
            </label>
          )}
          {!this.props.hideDragDropContainer && (
            <DragDrop
              hidePauseResumeCancelButtons
              uppy={this.state.uppy}
              note={note}
              height={this.props.height}
              width={this.props.width}
              locale={{
                strings: localeStrings,
              }}
            />
          )}
          {!this.props.hideStatusBar && (
            <StatusBar
              uppy={this.state.uppy}
              hideUploadButton={true}
              hideAfterFinish={false}
              hidePauseResumeButton={true}
              showProgressDetails
              locale={this.getStatusBarLocale()}
            />
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

UppyFileUpload.defaultProps = {
  height: "100%",
  width: "100%",
  allowedFileTypes: null,
  additionalMeta: {},
};

export default UppyFileUpload;
