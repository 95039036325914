import { FunctionComponent } from "react";
import PropTypes from "prop-types";

import LoaderRing from "../Animations/LoaderRing/LoaderRing";

import GridFullScreen from "../Grid/GridFullScreen";

interface ILoadingViewProps {
  height?: number;
  fullPageCenter?: boolean;
}

const LoadingView: FunctionComponent<ILoadingViewProps> = ({
  height = 80,
  fullPageCenter = false,
}) => {
  const loader = <LoaderRing height={height} />;
  if (fullPageCenter) {
    return <GridFullScreen>{loader}</GridFullScreen>;
  }
  return loader;
};

LoadingView.propTypes = {
  height: PropTypes.number,
  fullPageCenter: PropTypes.bool,
};

export default LoadingView;
