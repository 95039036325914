// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Hidden } from "@material-ui/core";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";
import { SimpleCard } from "../components/Card";
import { GridContainer, GridItem } from "../components/Grid";
import SearchBar from "../components/SearchBar/SearchBar";
import UsageGridWidget from "../components/Widgets/UsageGridWidget";
import Modal from "../components/Modal/Modal";
import Button from "../components/Button/Button";
import LoadingView from "../components/LoadingView/LoadingView";
import ConnectivityDeviceForm from "../components/AppForm/ConnectivityDeviceForm";

import ConnectivityDevicesListContainer from "./connectivityAllowedDeniedDevicesPage/ConnectivityDevicesListContainer";

// Translate
import { AddString } from "../components/I18n/CommonStrings";
import Translate from "../components/I18n/Translate";

// Styles
import { successColor, dangerColor } from "../assets/jss/app-js-styles";
const styles = {
  sectionTitle: {
    marginTop: "0",
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
};

@inject("groupsStore", "connectivityDevicesStore")
@observer
class ConnectivityAllowedDeniedDevicesPage extends Component {
  state = {
    isOpenModal: false,
    selectedConnectivityDevice: null,
    isBanned: false,
  };

  componentDidMount() {
    this.updateConnectivityDevices();

    this.props.groupsStore.getGroupsList();
  }

  updateConnectivityDevices = () => {
    this.props.connectivityDevicesStore.getDataList();
  };

  didWantToAddNew = (isBanned) => () => {
    this.setState({
      isOpenModal: true,
      isBanned,
    });
  };

  didWantToEdit = (token) => () => {
    const selectedConnectivityDevice = this.props.connectivityDevicesStore.getWithToken(
      token
    );

    this.setState({
      selectedConnectivityDevice,
      isBanned: selectedConnectivityDevice.status === "banned",
      isOpenModal: true,
    });
  };

  didCloseModal = () => {
    this.updateConnectivityDevices();
    this.setState({
      isOpenModal: false,
      selectedConnectivityDevice: null,
    });
  };

  didFindDuplicatedToken = (token) => {
    this.didWantToEdit(token)();
  };

  render() {
    if (this.props.groupsStore.isFetching) {
      return <LoadingView></LoadingView>;
    }

    const { classes, connectivityDevicesStore } = this.props;

    const { selectedConnectivityDevice, isOpenModal } = this.state;
    const {
      dataList,
      allowedDevicesList,
      deniedDevicesList,
    } = connectivityDevicesStore;

    return (
      <Fragment>
        <PageTitle id="Menu@connectivityDevices">Liberados e banidos</PageTitle>

        <Modal
          isOpen={isOpenModal}
          didCloseModal={this.didCloseModal}
          maxWidth="sm"
          modalTitle={
            this.state.isBanned ? (
              <Translate id="ConnectivityDevice@deniedSectionTitle">
                Bloqueados
              </Translate>
            ) : (
              <Translate id="ConnectivityDevice@allowedSectionTitle">
                Liberados
              </Translate>
            )
          }
        >
          <ConnectivityDeviceForm
            connectivityDevice={selectedConnectivityDevice}
            didSave={this.didCloseModal}
            didCancel={this.didCloseModal}
            didDelete={this.didCloseModal}
            isBanned={this.state.isBanned}
            didFindDuplicatedToken={this.didFindDuplicatedToken}
          />
        </Modal>
        <GridContainer>
          <GridItem xs={12}>
            <SimpleCard
              cardStyle={{ marginTop: 0, marginBottom: 16 }}
              cardBodyStyle={{ padding: "0 16px" }}
            >
              <GridContainer>
                <UsageGridWidget type="wifiAuthentication" />
              </GridContainer>
            </SimpleCard>
          </GridItem>
          {dataList.length > 5 && (
            <GridItem xs={12}>
              <SimpleCard cardStyle={{ marginTop: 0 }}>
                <SearchBar
                  value={connectivityDevicesStore.searchString}
                  onChange={connectivityDevicesStore.setSearchString}
                />
              </SimpleCard>
            </GridItem>
          )}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <h4 className={classes.sectionTitle}>
              <Translate id="ConnectivityDevice@allowedSectionTitle">
                Liberados
              </Translate>{" "}
              <i
                className="fas fa-check-circle"
                style={{ color: successColor }}
              />
            </h4>
            <Button
              small
              block
              color="primary"
              onClick={this.didWantToAddNew(false)}
            >
              <AddString /> <i className="fas fa-plus-circle" />
            </Button>

            {allowedDevicesList.length > 0 && (
              <ConnectivityDevicesListContainer
                devicesList={allowedDevicesList}
                didWantToEdit={this.didWantToEdit}
              />
            )}
          </GridItem>
          <GridItem xs={12} sm={6} style={{ position: "relative" }}>
            <Hidden mdDown>
              <div
                style={{
                  position: "absolute",
                  height: "94%",
                  left: 0,
                  width: 2,
                  backgroundColor: "#ddd",
                }}
              />
            </Hidden>
            <h4 className={classes.sectionTitle}>
              <Translate id="ConnectivityDevice@deniedSectionTitle">
                Bloqueados
              </Translate>{" "}
              <i className="fas fa-ban" style={{ color: dangerColor }} />
            </h4>
            <Button
              color="danger"
              small
              block
              onClick={this.didWantToAddNew(true)}
            >
              <AddString /> <i className="fas fa-plus-circle" />
            </Button>

            {deniedDevicesList.length > 0 && (
              <ConnectivityDevicesListContainer
                devicesList={deniedDevicesList}
                didWantToEdit={this.didWantToEdit}
              />
            )}
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(ConnectivityAllowedDeniedDevicesPage),
  pageAllowedRule: "connectivity",
});
