// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import { SortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Carousel } from "react-responsive-carousel";
import { Divider } from "@material-ui/core";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { InputText } from "../../Form";
import UppyFileUpload from "../../Form/UppyFileUpload";
import { GridContainer, GridItem } from "../../Grid";
import LoadingView from "../../LoadingView/LoadingView";
import AdvertisingMessageHelpText from "../../Advertising/AdvertisingMessageHelpText";

import WifiBannerThumbnail from "./WifiBannerThumbnail";

// Transloadit
import TransloaditUppyFileUpload from "../../Form/TransloaditUppyFileUpload";
// Transloadit END

// Translate
import Translate from "../../I18n/Translate";

// Utils
import { IMAGE_PATH, HOST_URL } from "../../../utils/EnvUtils";

import { EMediaTemplate } from "../../../types/Media";

// Styles
import { successColor, boxShadow } from "../../../assets/jss/app-js-styles";
const styles = {
  thumbnail: {
    display: "inline-flex",
    position: "relative",
    width: 100,
    margin: 8,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 100,
  },
  image: {
    maxWidth: "100%",
  },
  smsCustomMessagePreviewContainer: {
    padding: 20,
    paddingTop: 50,
    paddingLeft: 30,
    borderRadius: 8,
    ...boxShadow,
  },
  smsCustomMessagePreview: {
    position: "relative",
    padding: "8px 15px",
    width: "fit-content",
    fontWeight: "bold",
    borderRadius: 6,
    fontSize: 16,
    backgroundColor: successColor,
    color: "#fff",
    "& p": {
      margin: 0,
    },
  },
  messageBubble: {
    content: "",
    position: "absolute",
    border: "10px solid transparent",
    bottom: 0,
    borderBottom: `10px solid ${successColor}`,
    left: -10,
  },
  imagesCarouselPreview: {
    ...boxShadow,
    padding: 10,
    paddingBottom: 40,
    borderRadius: 8,
    backgroundColor: "#fafafa",
  },
  helperClass: {
    zIndex: 2147483647,
  },
};

const SortableList = SortableContainer(
  ({ wifiBanners, didWantToRemoveWifiBanner }) => (
    <div>
      {wifiBanners.map((image, index) => {
        let imageUrl = image?.url;
        if (image?.token?.url_img) {
          imageUrl = image.token.url_img;
        }

        return (
          <WifiBannerThumbnail
            index={index}
            key={index}
            src={imageUrl}
            didWantToRemove={didWantToRemoveWifiBanner(index)}
          />
        );
      })}
    </div>
  )
);

@inject("authStore", "i18nStore", "settingsStore")
@observer
class SmartAuthCustomizationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUploading: false,
    };

    this.defaultBanner = `${IMAGE_PATH}/wifi-banner-default_${props.i18nStore.currentLanguage}.png`;
    this.authPageButtonsImage = `${IMAGE_PATH}/auth-page-buttons.jpg`;
  }

  changeIsUploadingBannerState = (newState) => {
    this.setState({
      isUploading: newState,
    });
  };

  didChangeFilesUpload = (files) => {
    if (files.length > 0) {
      const { fileHash, url } = files[0];
      this.props.didUploadWifiBanner({
        token: fileHash,
        url,
      });
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const result = arrayMove(this.props.wifiBanners, oldIndex, newIndex);
    this.props.didChangeWifiBannersListOrder(result);
  };

  renderAuthUrl = () => {
    const { i18nStore, authUrl, onChangeInputText } = this.props;
    return (
      <GridContainer
        alignItems="center"
        style={{ marginBottom: authUrl.length > 0 ? 0 : 20 }}
      >
        <GridItem xs={12} sm={6} md={7} lg={9}>
          <InputText
            label={
              <Translate id="Connectivity@authUrlLabel">
                Página de autenticação customizada (opcional)
              </Translate>
            }
            name="authUrl"
            isRequired
            value={authUrl}
            onChange={onChangeInputText}
          />
        </GridItem>
        <GridItem xs sm md lg>
          <p style={{ marginLeft: 16 }}>
            <Translate id="Connectivity@authUrlDocumentationHint">
              Consulte a{" "}
              <a
                href={`https://${HOST_URL}/files/captive_documentation_${i18nStore.currentLanguage}.pdf`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: "bold" }}
              >
                documentação completa do recurso
              </a>{" "}
              para saber mais.
            </Translate>
          </p>
        </GridItem>
      </GridContainer>
    );
  };

  renderSMSCustomMessage = () => {
    const { classes, smsCustomMessage, onChangeInputText } = this.props;
    return (
      <GridContainer alignItems="center">
        <GridItem xs={12} sm={6} md={7} lg={9}>
          <InputText
            label={
              <Translate id="Connectivity@smsCustomMessageLabel">
                Mensagem no SMS de autenticação
              </Translate>
            }
            name="smsCustomMessage"
            multiline
            maxLength={100}
            value={smsCustomMessage}
            onChange={onChangeInputText}
            helpText={
              <AdvertisingMessageHelpText
                messageLength={smsCustomMessage.length}
                maxLength={100}
              />
            }
          />
        </GridItem>
        <GridItem xs sm md lg>
          <div className={classes.smsCustomMessagePreviewContainer}>
            <div className={classes.smsCustomMessagePreview}>
              <p style={{ wordBreak: "break-word" }}>
                <Translate
                  id="Connectivity@smsCustomMessagePreview"
                  variables={{ smsCustomMessage }}
                >
                  Informe o código 9999 para conectar na WiFi. $smsCustomMessage
                </Translate>
              </p>
              <span className={classes.messageBubble} />
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  renderImageUploader = (uploadedMax) => {
    return (
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12}>
          {this.props.settingsStore.isUsingTransloadit ? (
            <TransloaditUppyFileUpload
              name="connectivityLogo"
              uploadType="connectivityLogo"
              maxNumberOfFiles={1}
              minNumberOfFiles={1}
              allowedFileTypes={["image/*"]}
              hideDragDropContainer={this.state.isUploading || uploadedMax}
              didChangeFiles={this.didChangeFilesUpload}
              changeIsUploadingState={this.changeIsUploadingBannerState}
              resetAfterUpload
            />
          ) : (
            <UppyFileUpload
              name="connectivityLogo"
              template={EMediaTemplate.bannerWifi}
              maxNumberOfFiles={1}
              minNumberOfFiles={1}
              allowedFileTypes={["image/*"]}
              hideDragDropContainer={this.state.isUploading || uploadedMax}
              didChangeFiles={this.didChangeFilesUpload}
              changeIsUploadingState={this.changeIsUploadingBannerState}
              resetAfterUpload
            />
          )}
          <p style={{ fontStyle: "italic" }}>
            {uploadedMax ? (
              <Translate id="Connectivity@uploadedMaximumAllowedImages">
                Já enviou o máximo de imagens permitido.
              </Translate>
            ) : (
              <Translate id="Connectivity@wifiBannerHint">
                Para melhor enquadramento a imagem deve ser quadrada (ex: 700px
                de largura e 700px de altura).
              </Translate>
            )}
          </p>
        </GridItem>
      </GridContainer>
    );
  };

  renderWifiBanners = () => {
    const { classes, wifiBanners } = this.props;

    return (
      <GridContainer style={{ marginTop: 50 }}>
        <GridItem xs={12}>
          <label
            style={{
              fontSize: 13,
              fontWeight: 500,
              lineHeight: 1.42857,
              textTransform: "uppercase",
            }}
          >
            <Translate id="Connectivity@wifiBannerLabel">
              Imagens de boas vindas
            </Translate>
          </label>
        </GridItem>
        <GridItem xs={12}>
          <GridContainer style={{ marginBottom: 20 }}>
            <GridItem xs={12} style={{ display: "flex", alignItems: "start" }}>
              <SortableList
                wifiBanners={wifiBanners}
                onSortEnd={this.onSortEnd}
                axis={"x"}
                helperClass={classes.helperClass}
                didWantToRemoveWifiBanner={this.props.didWantToRemoveWifiBanner}
                distance={10}
              />
              {this.state.isUploading && (
                <div className={classes.thumbnail}>
                  <LoadingView height={60} />
                </div>
              )}
            </GridItem>
          </GridContainer>
          {this.renderImageUploader(wifiBanners.length > 2)}
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const isEnterprise =
      this.props.authStore.currentUserIsAdmin &&
      this.props.networkType === "enterprise";
    const authUrlIsEmpty = this.props.authUrl.length < 1;
    return (
      <GridContainer>
        <GridItem xs={12}>
          {isEnterprise && this.renderAuthUrl()}
          {isEnterprise && !authUrlIsEmpty && (
            <Divider light style={{ marginBottom: 30 }} />
          )}
          {/* {authUrlIsEmpty && this.renderSMSCustomMessage()} */}
          {authUrlIsEmpty && this.renderWifiBanners()}
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(SmartAuthCustomizationContainer);
