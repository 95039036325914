// @ts-nocheck

import PropTypes from "prop-types";

import MaterialList from "@material-ui/core/List";

const List = ({ children, style }) => (
  <MaterialList style={style}>{children}</MaterialList>
);

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default List;
