// @ts-nocheck
import { Component, Fragment } from "react";
import LoadingView from "../LoadingView/LoadingView";
import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";

import TerminalsToVideoWallDragNDropContainer from "../VideoWall/TerminalsToVideoWallDragNDropContainer";
import { GridListMediaItemWithoutSortable } from "../../pages/informativeSetPlaylistPage/GridListMediaItem";

import withStyles from "@material-ui/core/styles/withStyles";

// Translate
import Translate from "../I18n/Translate";
import Plural from "../I18n/Plural";

// Styles
import { dangerColor } from "../../assets/jss/app-js-styles";
const styles = {};

class SetGroupTerminalsToVideoWall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      terminalsList: [],
      isLoading: true,
    };
  }

  componentDidMount = () => {
    const {
      group,
      terminalsList: resourceTerminalsList,
    } = this.props.resourceRule;

    const unusedGroupTerminals = group.terminalsList.filter(
      (groupTerminal) => !resourceTerminalsList.includes(groupTerminal.token)
    );

    const terminalsList = resourceTerminalsList.map((terminalToken) => ({
      ...group.terminalsList.find(
        (groupTerminal) => groupTerminal.token === terminalToken
      ),
    }));

    this.setState({
      terminalsList: [...terminalsList, ...unusedGroupTerminals],
      isLoading: false,
    });
  };

  didConfirm = () => {
    const { resourceRule } = this.props;
    const { videoWallScreensCount } = resourceRule.playlist;
    this.props.didSetTerminalsListSequence({
      ...resourceRule,
      terminalsList: this.state.terminalsList
        .filter((terminal, index) => index < videoWallScreensCount)
        .map((terminal) => terminal.token),
    });
  };

  didChangeTerminalsListOrder = (newTerminalsList) => {
    this.setState({
      terminalsList: newTerminalsList,
    });
  };

  render() {
    // const { classes } = this.props;
    const { isLoading, terminalsList } = this.state;

    if (isLoading) {
      return <LoadingView />;
    }

    const { playlist } = this.props.resourceRule;
    const videoWallScreensCount = playlist.videoWallScreensCount;

    return (
      <GridContainer style={{ marginTop: 20 }}>
        <GridItem xs={12} style={{ textAlign: "center" }}>
          {videoWallScreensCount > terminalsList.length && (
            <Fragment>
              <p style={{ marginBottom: 0 }}>
                <i className="fas fa-info-circle" />{" "}
                <Plural
                  id="VideoWall@minScreenCountMessage"
                  value={videoWallScreensCount}
                  one={
                    <span>
                      Este vídeo wall precisa de <strong>um dispositivo</strong>
                      .
                    </span>
                  }
                  other={
                    <span>
                      Este Vídeo wall precisa de no mínimo{" "}
                      <strong># dispositivos.</strong>
                    </span>
                  }
                />
              </p>
              <p style={{ color: dangerColor, marginBottom: 0 }}>
                <i className="fas fa-ban" />{" "}
                <Translate id="VideoWall@insufficientDevicesOnGroupMessage">
                  O grupo selecionado{" "}
                  <strong>não possui dispositivos suficientes</strong>.
                </Translate>
              </p>
              <Button
                small
                color="success"
                outline
                onClick={() =>
                  this.props.didSelectAppResourceToEdit(playlist.token)
                }
              >
                <Translate id="Playlist@editPlaylist">
                  Editar programação
                </Translate>{" "}
                <i className="fas fa-pen" />
              </Button>
              <Button
                small
                color="primary"
                outline
                onClick={() =>
                  this.props.didSelectResourceRuleToEdit(
                    this.props.resourceRule.token
                  )
                }
              >
                <Translate id="Playlist@editRule">Editar regra</Translate>{" "}
                <i className="fas fa-pen" />
              </Button>
            </Fragment>
          )}
        </GridItem>

        {videoWallScreensCount <= terminalsList.length && (
          <Fragment>
            <GridItem xs={12}>
              <GridContainer justify="center">
                {playlist.mediasList
                  .filter((_, index) => index < videoWallScreensCount)
                  .map((mediaItem, index) => (
                    <GridListMediaItemWithoutSortable
                      key={`${mediaItem.token}_${index}`}
                      index={index}
                      itemIndex={index}
                      mediaItem={mediaItem}
                      disabled={false}
                      disabledPlaylist={false}
                      randomIsActive={true}
                      isManagingMedia={false}
                      recentlyAdded={false}
                    />
                  ))}
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <p style={{ textAlign: "center" }}>
                <Translate id="VideoWall@devicesSequenceHint">
                  Sequencia dos dispositivos no Vídeo wall
                </Translate>{" "}
                <i className="fas fa-long-arrow-alt-right" />
              </p>
            </GridItem>
            <GridItem xs={12}>
              <TerminalsToVideoWallDragNDropContainer
                terminalsList={terminalsList}
                videoWallScreensCount={videoWallScreensCount}
                didChangeTerminalsListOrder={this.didChangeTerminalsListOrder}
              />
            </GridItem>
            <GridItem xs={12}>
              <Button color="success" block onClick={this.didConfirm}>
                <Translate id="VideoWall@setupTitle">
                  Configurar Vídeo wall
                </Translate>{" "}
                <i className="fas fa-desktop" />
              </Button>
            </GridItem>
          </Fragment>
        )}
      </GridContainer>
    );
  }
}

export default withStyles(styles)(SetGroupTerminalsToVideoWall);
