// @ts-nocheck

import classnames from "classnames";
import { SortableElement } from "react-sortable-hoc";
import withStyles from "@material-ui/core/styles/withStyles";

import { Card, CardContent } from "@material-ui/core";
import IfExistsImage from "../../components/IfExistsImage/IfExistsImage";
import Tooltip from "../../components/Tooltip/Tooltip";

// Style
import { dangerColor, infoColor } from "../../assets/jss/app-js-styles";

const cardTitleDefaultStyle = {
  textAlign: "center",
  fontWeight: 500,
  margin: 0,
  padding: "4px 0",
};

const styles = (theme) => ({
  card: {
    width: 100,
    margin: 4,
    cursor: "move",
    border: "3px solid transparent",
    [theme.breakpoints.down("sm")]: {
      width: 120,
      margin: 12,
    },
  },
  disabledCard: {
    cursor: "no-drop",
  },
  cardToDelete: {
    borderColor: dangerColor,
  },
  recentlyAdded: {
    borderColor: infoColor,
  },
  isManagingMedia: {
    cursor: "pointer",
  },
  cardContent: {
    background: "#E2E2E2",
    textAlign: "center",
    padding: "0!important",
  },
  cardTitle: {
    ...cardTitleDefaultStyle,
    background: "#fff",
  },
  cardTitleSelected: {
    ...cardTitleDefaultStyle,
    background: "#1C85E1",
    color: "#fff",
  },
  cardImage: { maxWidth: "100%", maxHeight: 56 },
  cardImagePlaceholder: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  grayscale: {
    filter: "grayscale(100%)",
  },
});

export const GridListMediaItemWithoutSortable = withStyles(styles)(
  ({
    classes,
    mediaItem,
    didSelect,
    itemIndex,
    randomIsActive,
    disabledPlaylist,
    isManagingMedia,
    recentlyAdded,
  }) => (
    <Card
      className={classnames({
        [classes.card]: true,
        [classes.disabledCard]:
          !isManagingMedia && (disabledPlaylist || randomIsActive),
        [classes.grayscale]: disabledPlaylist && !isManagingMedia,
        [classes.isManagingMedia]: isManagingMedia,
        [classes.cardToDelete]: mediaItem.isSelectedToDelete,
        [classes.recentlyAdded]: recentlyAdded,
      })}
      onClick={() => {
        if (didSelect) {
          didSelect(itemIndex);
        }
      }}
    >
      <CardContent
        className={classnames({
          [classes.cardContent]: true,
          [classes.grayscale]:
            (isManagingMedia && !mediaItem.isSelectedToDelete) ||
            disabledPlaylist,
        })}
      >
        {mediaItem.name.length > 11 ? (
          <Tooltip title={mediaItem.name} noMaxWidth>
            <p className={classes.cardTitle}>
              {mediaItem.name.substring(0, 9).trim()}
              &hellip;
            </p>
          </Tooltip>
        ) : (
          <p className={classes.cardTitle}>{mediaItem.name}</p>
        )}
        {mediaItem.isProcessingFinished ? (
          <img
            src={mediaItem.imageUrl}
            alt={mediaItem.name}
            className={classes.cardImage}
            draggable={false}
          />
        ) : (
          <IfExistsImage
            className={classes.cardImage}
            src={mediaItem.imageUrl}
            placeholderClassName={classes.cardImagePlaceholder}
            alt={mediaItem.name}
          />
        )}
      </CardContent>
    </Card>
  )
);

const GridListMediaItem = SortableElement((props) => (
  <GridListMediaItemWithoutSortable {...props} />
));

export default withStyles(styles)(GridListMediaItem);
