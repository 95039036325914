export enum KEYCODE {
  ENTER = 13,
  SPACE = 32,
  ARROW_RIGHT = 39,
  ARROW_LEFT = 37,
  ARROW_UP = 38,
  ARROW_DOWN = 40,
  ESC = 27,
  A = 65,
  B = 66,
  C = 67,
  D = 68,
  E = 69,
  F = 70,
  G = 71,
  H = 72,
  I = 73,
  J = 74,
  K = 75,
  L = 76,
  M = 77,
  N = 78,
  O = 79,
  P = 80,
  Q = 81,
  R = 82,
  S = 83,
  T = 84,
  U = 85,
  V = 86,
  W = 87,
  X = 88,
  Y = 89,
  Z = 90,
  Zero = 48,
  ONE = 49,
  TWO = 50,
  THREE = 51,
  FOUR = 52,
  FIVE = 53,
  SIX = 54,
  SEVEN = 55,
  EIGHT = 56,
  NINE = 57,
  MINUS = 189,
  PLUS = 187,
  DELETE = 8,
  DOT = 190,
  COMMA = 188,
  TAB = 9,
}

export interface IMappedKeyFunctions {
  [key: number]: (event: KeyboardEvent) => void;
}

type IMappedKeyFn = () => IMappedKeyFunctions;

interface KeyboardEventListener {
  id: any;
  mappedKeyFn: IMappedKeyFn;
  allowRepeat: boolean;
}

class KeyboardEventsService {
  globalListeners: KeyboardEventListener[] = [];
  listeners: KeyboardEventListener[] = [];

  setListener = (id: any, mappedKeyFn: IMappedKeyFn, allowRepeat = false) => {
    this.listeners.push({
      id,
      mappedKeyFn,
      allowRepeat,
    });
  };
  setGlobalListener = (
    id: any,
    mappedKeyFn: IMappedKeyFn,
    allowRepeat = false
  ) => {
    this.globalListeners.push({
      id,
      mappedKeyFn,
      allowRepeat,
    });
  };

  removeListener = (idToRemove: any) => {
    this.listeners = this.listeners.filter(({ id }) => id !== idToRemove);
  };
  removeGlobalListener = (idToRemove: any) => {
    this.globalListeners = this.globalListeners.filter(
      ({ id }) => id !== idToRemove
    );
  };

  tryToCallEventOnListener = (
    event: KeyboardEvent,
    listener: KeyboardEventListener
  ) => {
    if (!event.repeat || listener.allowRepeat) {
      const fn = listener.mappedKeyFn()[event.keyCode];
      if (fn) fn(event);
    }
  };

  notify = (event: KeyboardEvent) => {
    if (this.listeners.length > 0) {
      // always get the last listener
      const listener = this.listeners[this.listeners.length - 1];
      this.tryToCallEventOnListener(event, listener);
    }
    if (this.globalListeners.length > 0) {
      this.globalListeners.forEach(($0) =>
        this.tryToCallEventOnListener(event, $0)
      );
    }
  };
}

export default KeyboardEventsService;
