// @ts-nocheck
import { observable, computed, action, autorun } from "mobx";
import debounce from "lodash/debounce";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";

class TagsStore {
  @observable
  mediaPreferences = {
    tags: [],
    taggedMedia: {},
  };
  @observable
  isFetching = false;
  @observable
  searchString = "";

  constructor(contractStore, notificationsStore, modboxAPIService) {
    this.contractStore = contractStore;
    this.notificationsStore = notificationsStore;
    this.modboxAPIService = modboxAPIService;

    autorun(() => {
      if (this.contractStore.selectedContract) {
        this.requestMediaPreferences();
      } else {
        this.clearStore();
      }
    });
  }

  @action
  clearStore = () => {
    this.mediaPreferences = {
      tags: [],
      taggedMedia: {},
    };
    this.searchString = "";
  };

  @action
  setSearchString = (searchString) => {
    this.searchString = searchString;
  };
  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };
  @action
  setMediaPreferences = (newValue) => {
    this.mediaPreferences = newValue;
  };

  @computed
  get mediaTags() {
    return this.mediaPreferences.tags;
  }

  @computed
  get taggedMedia() {
    return this.mediaPreferences.taggedMedia;
  }

  findTagWithToken = (t) => {
    return this.mediaTags.find(({ token }) => token === t);
  };

  getMediaTags = (token) => {
    return this.taggedMedia ? this.taggedMedia[`media_${token}`] || [] : [];
  };

  requestPreferences = async (type) => {
    try {
      const response = await this.modboxAPIService.requestPreferences(type);
      return response.data;
    } catch (error) {
      window.debugError("error in requestPreferences", error);
      return null;
    }
  };

  requestMediaPreferences = async () => {
    this.setIsFetching(true);
    const result = await this.requestPreferences("media");
    if (result) {
      this.setMediaPreferences({
        ...result,
        tags: result.tags.sort(dynamicSortByProperty("label")),
      });
    }
    this.setIsFetching(false);
  };

  updateMediaPreferences = (preferences) => {
    this.setMediaPreferences(preferences);

    this.updatePreferences("media", preferences);
  };

  updatePreferences = debounce((type, preferences) => {
    this.modboxAPIService.updatePreference(type, preferences).catch((error) => {
      window.debugError("error in updatePreference", error);
    });
  }, 4000);
}

export default TagsStore;
