// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../HOC";
import { Button } from "../Button";
import Modal from "./Modal";
import LoadingView from "../LoadingView/LoadingView";
import { ButtonGroup, InputText } from "../Form";
import Validate, { ValidationType } from "../Form/Validate";
import DatePicker from "../Form/DatePicker";

import { GridContainer, GridItem } from "../Grid";
import TagsContainer from "../Tags/TagsContainer";

// Translate
import {
  UpdateString,
  EditString,
  BackString,
  DeleteString,
  ViewString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

import { MediaTypeEnum } from "../../store/MediaLibraryStore/MediaLibraryStore";

// Utils
import {
  momentDateFromUnixDate,
  startOfTheDayMoment,
} from "../../utils/DateUtils";

// Icon
import { EditIcon, ChevronLeftIcon, DeleteIcon, SaveIcon } from "../Icon";

// Style
const styles = {
  imageContainer: { textAlign: "center" },
  image: { maxWidth: "100%" },
};

@withLocalized
@inject(
  "i18nStore",
  "mediaLibraryStore",
  "notificationsStore",
  "tagsStore",
  "modboxFileService"
)
@observer
class ModalMediaDetails extends Component {
  constructor(props) {
    super(props);
    this.state = this.getStateFrom(props.mediaItem, props.isEditing);
  }

  componentDidMount() {
    this.isMountedAndAlive = true;
    if (!this.state.isMediaReady) {
      this.verifyIfMediaIsReady();
    }
  }

  componentWillUnmount() {
    this.isMountedAndAlive = false;
    if (this.verifyIfMediaIsReadyTimeout) {
      clearTimeout(this.verifyIfMediaIsReadyTimeout);
    }
  }

  getStateFrom = ({ name, expireTime, isProcessingFinished }, isEditing) => ({
    isEditing: isEditing,
    name: name,
    expireTimeFilter:
      expireTime === "0" ? (isEditing ? "setDate" : "undefined") : "setDate",
    expireTime:
      expireTime === "0"
        ? isEditing
          ? this.momentPlusSevenDays()
          : "0"
        : momentDateFromUnixDate(expireTime),
    isMediaReady: isProcessingFinished,
    formWasSubmit: false,
    isWaitingForm: false,
    isValid: {
      name: true,
    },
  });

  verifyIfMediaIsReady = async () => {
    if (!this.isMountedAndAlive) return;

    const isMediaReady = await this.props.mediaLibraryStore.uploadMediaDidFinishProcessingVideo(
      this.props.mediaItem.token
    );

    if (this.isMountedAndAlive) {
      if (isMediaReady) {
        this.setState({
          isMediaReady,
        });
      } else {
        this.verifyIfMediaIsReadyTimeout = setTimeout(
          this.verifyIfMediaIsReady,
          5000
        );
      }
    }
  };

  setEditingView = () => {
    this.setState({
      isEditing: true,
    });
  };
  cancelEditingView = () => {
    this.setState(this.getStateFrom(this.props.mediaItem, false));
  };

  onChangeName = ({ target }) => {
    const { value } = target;

    this.setState({
      name: value,
      isValid: {
        ...this.state.isValid,
        name: Validate(value, ValidationType.REQUIRED),
      },
    });
  };

  momentPlusSevenDays = () => {
    return startOfTheDayMoment({
      hour: 23,
      minute: 59,
    }).add(7, "days");
  };

  onChangeExpireTimeFilter = (_, selectedValue) => {
    this.setState({
      expireTimeFilter: selectedValue,
      expireTime:
        selectedValue === "undefined" ? "0" : this.momentPlusSevenDays(),
    });
  };

  onChangeDatePicker = (name, newValue) => {
    this.setState({
      [name]: newValue,
    });
  };

  canSubmitForm = () => {
    return this.state.isValid.name;
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.canSubmitForm()) {
      this.setState(
        { formWasSubmit: true, isWaitingForm: true },
        this.submitForm
      );
    } else {
      this.setState(
        {
          formWasSubmit: true,
          isWaitingForm: false,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: (
              <Translate id="MediaItem@nameErrorMessage">
                Por favor informe o nome da mídia.
              </Translate>
            ),
            color: "danger",
          });
        }
      );
    }
  };

  submitForm = () => {
    const { name, expireTime } = this.state;
    const formData = {
      name: name,
      expireTime:
        expireTime === "0" ? expireTime : moment(expireTime).format("X"),
    };

    this.props.mediaLibraryStore
      .updateSingleMediaItem({
        ...this.props.mediaItem,
        ...formData,
      })
      .then(() => {
        this.submitHasSuccess();
      })
      .catch(() => {
        this.submitHasError();
      });
  };

  submitHasSuccess = (token) => {
    this.setState(
      {
        isWaitingForm: false,
        isEditing: false,
      },
      () => {
        if (this.props.didUpdate) {
          this.props.didUpdate();
        }
      }
    );
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  getMediaUrl = () => {
    const { mediaItem } = this.props;

    return mediaItem.url;
  };

  onChangeMediaTags = (tags) => {
    const { token } = this.props.mediaItem;
    const { mediaPreferences, updateMediaPreferences } = this.props.tagsStore;
    updateMediaPreferences({
      ...mediaPreferences,
      taggedMedia: {
        ...mediaPreferences.taggedMedia,
        [`media_${token}`]: tags.map(({ token }) => token),
      },
    });
  };

  render() {
    const {
      classes,
      i18nStore,
      tagsStore,
      localizedString,
      mediaItem,
      isOpen,
      didCloseModal,
    } = this.props;
    const tags = tagsStore
      .getMediaTags(mediaItem.token)
      .map(tagsStore.findTagWithToken)
      .filter(($0) => $0);
    const {
      isEditing,
      name,
      expireTimeFilter,
      expireTime,
      formWasSubmit,
      isWaitingForm,
      isValid,
    } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        didCloseModal={didCloseModal}
        modalTitle={
          isEditing ? (
            <Translate id="MediaItem@editingModalTitle">
              Editando mídia
            </Translate>
          ) : (
            name
          )
        }
        maxWidth="sm"
      >
        {isEditing ? (
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                label={
                  <Translate id="MediaItem@nameLabel">Nome da mídia</Translate>
                }
                name="name"
                hasError={formWasSubmit && !isValid.name}
                hasSuccess={formWasSubmit && isValid.name}
                value={name}
                onChange={this.onChangeName}
              />
              <ButtonGroup
                label={
                  <Translate id="MediaItem@expireTimeLabel">
                    Apagar automaticamente
                  </Translate>
                }
                name="expireTimeFilter"
                value={expireTimeFilter}
                small
                onChange={this.onChangeExpireTimeFilter}
                options={[
                  {
                    value: "undefined",
                    label: localizedString({
                      id: "Schedule@never",
                      defaultString: "Nunca",
                    }),
                  },
                  {
                    value: "setDate",
                    label: localizedString({
                      id: "Schedule@setDate",
                      defaultString: "Definir data",
                    }),
                  },
                ]}
              />
              {expireTimeFilter === "setDate" && (
                <DatePicker
                  name="expireTime"
                  value={expireTime}
                  alwaysOpenWithoutInput
                  onChange={this.onChangeDatePicker}
                  isValidDate={(currentDate) =>
                    currentDate.isAfter(startOfTheDayMoment())
                  }
                  fullWidth
                />
              )}
            </GridItem>
            <GridItem xs={12}>
              <TagsContainer
                tagsList={tagsStore.mediaTags}
                selectedTags={tags}
                onChangeSelectedTags={this.onChangeMediaTags}
              />
            </GridItem>
            <GridItem xs={12}>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={4}>
                  <Button block onClick={this.cancelEditingView}>
                    <ChevronLeftIcon /> <BackString />
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Button
                    color="danger"
                    block
                    onClick={() => this.props.didWantToDelete(mediaItem)}
                  >
                    <DeleteString /> <DeleteIcon />
                  </Button>
                </GridItem>
                <GridItem xs={12} sm>
                  <Button
                    color="primary"
                    block
                    isLoading={isWaitingForm}
                    onClick={this.onSubmit}
                  >
                    <UpdateString /> <SaveIcon />
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={12}>
              <TagsContainer
                hideLabel
                tagsList={tagsStore.mediaTags}
                selectedTags={tags}
                isOpen={this.props.wantToEditTags}
                onChangeSelectedTags={this.onChangeMediaTags}
              />
            </GridItem>
            <GridItem xs={12}>
              {mediaItem.duration && (
                <p style={{ marginBottom: 0 }}>
                  <Translate id="MediaItem@durationlabel">Duração</Translate>:{" "}
                  <strong>{mediaItem.duration}s</strong>
                </p>
              )}
              {expireTime !== "0" && (
                <p>
                  <Translate id="MediaItem@expireTimeLabel">
                    Apagar automaticamente
                  </Translate>
                  :{" "}
                  <strong>
                    {expireTime.format(i18nStore.dateFormatWithFourDigitsYear)}
                  </strong>
                </p>
              )}
            </GridItem>
            {mediaItem.type === MediaTypeEnum.UPLOAD &&
              mediaItem.imageUrl.length > 0 && (
                <GridItem xs={12} className={classes.imageContainer}>
                  {this.state.isMediaReady ? (
                    <video width="100%" controls style={{ maxHeight: 400 }}>
                      {/* {!mediaItem.isVertical && (
                        <source
                          src={this.props.modboxFileService.mountMovieUrl({
                            hash: mediaItem.token,
                            isCustom: mediaItem.isCustom,
                          })}
                          type="video/mp4"
                        />
                      )} */}
                      <source
                        // src={this.props.modboxFileService.mountMovieUrl({
                        //   hash: mediaItem.token,
                        //   isVertical: true,
                        //   isCustom: mediaItem.isCustom,
                        // })}
                        src={mediaItem.videoUrl}
                        type="video/mp4"
                      />
                      <img
                        src={mediaItem.imageUrl}
                        alt={mediaItem.name}
                        className={classes.image}
                      />
                    </video>
                  ) : (
                    <LoadingView />
                  )}
                </GridItem>
              )}
            <GridItem xs={12}>
              {(mediaItem.type === MediaTypeEnum.STREAMING ||
                mediaItem.type === MediaTypeEnum.CONTENT) && (
                <Button
                  color="success"
                  block
                  href={this.getMediaUrl()}
                  target="_blank"
                >
                  <ViewString /> <i className="fas fa-external-link-alt" />
                </Button>
              )}
              <Button
                color="primary"
                block
                outline
                small
                onClick={this.setEditingView}
                style={{ marginTop: 20 }}
              >
                <EditString /> <EditIcon />
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </Modal>
    );
  }
}

ModalMediaDetails.defaultProps = {
  isEditing: false,
};

export default withStyles(styles)(ModalMediaDetails);
