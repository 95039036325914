// @ts-nocheck

import withStyles from "@material-ui/core/styles/withStyles";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "../../components/Tooltip/Tooltip";

// Translate
import { NewString } from "../../components/I18n/CommonStrings";

// Icons
import { infoColor } from "../../assets/jss/app-js-styles";
import { CloudUploadIcon } from "../../components/Icon/MaterialIcon";

// Style
const styles = {
  card: {
    width: 150,
    minHeight: 148,
    margin: 16,
    display: "table",
    cursor: "pointer",
    height: "fit-content",
  },
  button: {
    width: "100%",
    height: "100%",
    fontSize: 80,
    borderRadius: 6,
    color: infoColor,
  },
};

const MediaGridAddButton = ({ classes, onClick }) => (
  <Tooltip title={<NewString />} placement="right">
    <div className={classes.card}>
      <IconButton className={classes.button} onClick={onClick}>
        <CloudUploadIcon fontSize="inherit" />
      </IconButton>
    </div>
  </Tooltip>
);

export default withStyles(styles)(MediaGridAddButton);
