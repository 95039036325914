// @ts-nocheck

import animationData from "./MotorcycleLottieAnimation.json";
import LottieAnimation from "../LottieAnimation";

const MotorcycleLottieAnimation = props => (
  <LottieAnimation animationData={animationData} {...props} />
);

export default MotorcycleLottieAnimation;
