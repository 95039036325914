import { ILocation } from "../types/Location";
import { IGroupTerminal } from "../types/Group";
import GroupModel from "./GroupModel";

class LocationModel implements ILocation {
  cnpj: string;
  isDefault: boolean;
  groups?: GroupModel[];
  latitude?: number;
  longitude?: number;
  name: string;
  terminalsList?: IGroupTerminal[];
  token: string;

  constructor(data: any) {
    let groupsList: GroupModel[] = [];
    let totalTerminals: IGroupTerminal[] = [];
    data.groups?.forEach((group) => {
      groupsList.push(new GroupModel(group));
    });

    groupsList.forEach(({ terminalsList }) => {
      terminalsList.forEach((terminal) => totalTerminals.push(terminal));
    });

    this.cnpj = data.cnpj;
    this.isDefault = data.is_default;
    this.groups = groupsList;
    this.latitude = data.latitude ? parseFloat(data.latitude) : null;
    this.longitude = data.longitude ? parseFloat(data.longitude) : null;
    this.name = data.group_name || data.name;
    this.terminalsList = totalTerminals;
    this.token = data.group_token || data.token;
  }
}

export default LocationModel;
