// @ts-nocheck
import { Component, Fragment, createRef } from "react";
import classnames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import { Button } from "../Button";

import TypingAnimation from "../Animations/Typing/Typing";
import MessageItem from "./MessageItem";
import QuestionMessageItem from "./QuestionMessageItem";

// Style
import { primaryColor } from "../../assets/jss/app-js-styles";
import { MESSAGE_TYPE } from "../../store/ChatStore/ChatStore";

const styles = {
  messagesListContainer: {
    backgroundColor: "#fff",
    height: "80vh",
    overflowY: "scroll",
    padding: "20px 0",
    position: "relative",
    bottom: 0,
    margin: 0,
    listStyle: "none",
    gridColumnStart: 2,
    boxShadow: `inset 0px 11px 16px -12px rgba(0,0,0,0.6),
       inset 0px -11px 16px -14px rgba(0,0,0,0.6)`,
  },
  fullHeightContainer: {
    height: "calc(100vh - 220px)",
    maxHeight: "calc(100vh - 220px)",
  },
  typingAnimation: {
    marginTop: 20,
    marginBottom: 20,
  },
  scrollToBottomButton: {
    color: primaryColor,
    backgroundColor: "#EEEEEE!important",
    position: "absolute",
    right: 16,
    bottom: 80,
  },
};

class MessagesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrolledToBottom: true,
    };

    this.chatMessagesListContainer = createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps, prevState) {
    const leftState = this.state.isScrolledToBottom;
    const rightState = prevState.isScrolledToBottom;

    const leftProp = JSON.stringify(this.props.messagesList);
    const rightProp = JSON.stringify(prevProps.messagesList);

    if (
      this.state.isScrolledToBottom &&
      (leftState !== rightState || leftProp !== rightProp)
    ) {
      this.scrollToBottom();
    }

    if (leftState !== rightState) {
      this.props.setIsReading(this.state.isScrolledToBottom);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const leftState = this.state.isScrolledToBottom;
    const rightState = nextState.isScrolledToBottom;

    const leftProp = JSON.stringify(this.props.messagesList);
    const rightProp = JSON.stringify(nextProps.messagesList);

    return (
      leftState !== rightState ||
      leftProp !== rightProp ||
      this.props.isTyping !== nextProps.isTyping
    );
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const element = this.chatMessagesListContainer.current;
    const isScrolledToBottom =
      element.scrollHeight - element.clientHeight <= element.scrollTop + 1;

    this.setState({
      isScrolledToBottom,
    });
  };

  scrollToBottom = () => {
    const element = this.chatMessagesListContainer.current;
    element.scrollTop = element.scrollHeight;
  };

  render() {
    const { classes, messagesList, isTyping, isAdminView } = this.props;

    return (
      <Fragment>
        <ul
          ref={this.chatMessagesListContainer}
          onScroll={this.handleScroll}
          className={classnames({
            [classes.messagesListContainer]: true,
            [classes.fullHeightContainer]: isAdminView,
          })}
        >
          {messagesList.map((message, index) => {
            if (message.type === MESSAGE_TYPE.QUESTION) {
              return <QuestionMessageItem key={message.id} {...message} />;
            }

            const isLastMessage =
              index + 1 < messagesList.length
                ? messagesList[index + 1].author !== message.author
                : true;
            return (
              <MessageItem
                key={message.id}
                isAdminView={isAdminView}
                isLast={isLastMessage}
                {...message}
              />
            );
          })}
          {isTyping && (
            <li className={classes.typingAnimation}>
              <TypingAnimation height={30} />
            </li>
          )}
        </ul>
        {!this.state.isScrolledToBottom && (
          <Button
            justIcon
            color="transparent"
            className={classes.scrollToBottomButton}
            onClick={this.scrollToBottom}
          >
            <i className="fas fa-chevron-circle-down" />
          </Button>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(MessagesList);
