// @ts-nocheck

import { makeStyles } from "@material-ui/styles";
import { ReactElement, FC } from "react";

import Slider from "../Slider/Slider";

// Style
const useStyles = makeStyles({
  container: {
    position: "relative",
    margin: "20px 1px",
    marginTop: 44,
  },
  label: {
    display: "block",
    color: "#AAAAAA !important",
    fontWeight: 500,
    textTransform: "uppercase",
    marginTop: 10,
    fontSize: 12,
    lineHeight: "1.42857",
  },
});

interface IInputSliderProps {
  label?: string | ReactElement;
  name: string;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  renderTooltip?: (value: number) => string;
  onSlide?: ({ name, value }: { name: string; value: number }) => void;
  onChange?: ({ name, value }: { name: string; value: number }) => void;
}

const InputSlider: FC<IInputSliderProps> = ({
  label,
  name,
  value,
  min = 1,
  max = 100,
  step = 1,
  renderTooltip,
  onSlide,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Slider
        values={[value]}
        min={min}
        max={max}
        step={step}
        renderTooltip={renderTooltip}
        onSlide={(
          render: any,
          handle: any,
          value: any,
          un: any,
          percent: any
        ) => {
          if (onSlide) {
            onSlide({
              name: name,
              value: value[0],
            });
          }
        }}
        onChange={(
          render: any,
          handle: any,
          value: any,
          un: any,
          percent: any
        ) => {
          if (onChange) {
            onChange({
              name: name,
              value: value[0],
            });
          }
        }}
      />
      {label && <label className={classes.label}>{label}</label>}
    </div>
  );
};

export default InputSlider;
