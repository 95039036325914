// import { IMAGE_PATH } from "../utils/EnvUtils";

import { MediaTypeEnum } from "../store/MediaLibraryStore/MediaLibraryStore";

class ExtrasMediaModel {
  type = MediaTypeEnum.EXTRAS;
  name;
  token;
  description;
  language;
  imageUrl;
  isLiveContent;

  constructor(data) {
    this.name = data.name;
    this.token = data.token;
    this.description = data.description;
    this.language = data.language;
    this.isLiveContent = data.type === "live" || data.content_type === "live";
    // this.imageUrl = `${IMAGE_PATH}/contents/${data.token}.jpg`;
    this.imageUrl = data?.url_img;
  }
}

export default ExtrasMediaModel;
