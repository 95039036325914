import { observable, action } from "mobx";

import InternalEventsStore, {
  EInternalEvents,
} from "../InternalEventsStore/InternalEventsStore";

import PeopleModel from "../../Model/PeopleModel";
import ContractStore from "../ContractStore/ContractStore";
import I18nStore from "../I18nStore/I18nStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import ModboxAPIService from "../../services/ModboxAPIService";

interface AdvertisingStoreProps {
  contractStore: ContractStore;
  i18nStore: I18nStore;
  notificationsStore: NotificationsStore;
  modboxAPIService: ModboxAPIService;
}

class AdvertisingStore implements AdvertisingStoreProps {
  contractStore: ContractStore;
  i18nStore: I18nStore;
  notificationsStore: NotificationsStore;
  modboxAPIService: ModboxAPIService;

  @observable message: string = "";
  @observable selectedPeople = null;
  @observable isOpenModal = false;

  constructor(
    contractStore: ContractStore,
    i18nStore: I18nStore,
    internalEventsStore: InternalEventsStore,
    notificationsStore: NotificationsStore,
    modboxAPIService: ModboxAPIService
  ) {
    this.contractStore = contractStore;
    this.i18nStore = i18nStore;
    this.notificationsStore = notificationsStore;
    this.modboxAPIService = modboxAPIService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.message = "";
    this.selectedPeople = null;
    this.isOpenModal = false;
  };

  @action
  didSelectPeople = (data: any) => {
    this.isOpenModal = true;
    this.selectedPeople = data;
  };

  @action
  removeSelectedPeople = () => {
    this.isOpenModal = false;
    setTimeout(() => {
      this.selectedPeople = null;
    }, 400);
  };

  @action
  onChangeMessage = ({ target }: { target: any }) => {
    this.message = target.value;
  };

  requestAdvertisingMessages = (params: any) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .requestAdvertisingMessages(params)
        .then((response: any) => resolve(response.data))
        .catch((error) => reject(error));
    });
  };

  submitAdvertisingMessages = (params: any) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitAdvertisingMessages(params)
        .then((response: any) => resolve(response.data))
        .catch((error) => reject(error));
    });
  };

  submitSMS = ({ phonesList }: { phonesList: string[] }) => {
    if (this.message.length < 1) {
      return Promise.reject();
    }

    return new Promise(async (resolve, reject) => {
      this.modboxAPIService
        .submitSMS({
          phonesList,
          message: this.message,
        })
        .then((response: any) => {
          this.message = "";
          const data = response.data;

          this.contractStore.selectedContract.sms = {
            limit: data.sms_limit
              ? parseInt(data.sms_limit, 10)
              : this.contractStore.selectedContract.sms.limit,
            usage: data.sms_usage
              ? parseInt(data.sms_usage, 10)
              : this.contractStore.selectedContract.sms.usage,
          };

          this.notificationsStore.addSnackbarNotification({
            message: data.message,
            color: "success",
          });

          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.message) {
            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              color: "danger",
            });
          }
          reject(error);
        });
    });
  };

  requestPeople = (token: string) => {
    return new Promise(async (resolve, reject) => {
      this.modboxAPIService
        .requestPeople(token)
        .then((result: any) => {
          if (result.status === 200 && result.data) {
            resolve(
              new PeopleModel(result.data, this.i18nStore.localizedString)
            );
          } else {
            reject();
          }
        })
        .catch((error) => reject(error));
    });
  };
}

export default AdvertisingStore;
