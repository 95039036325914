// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import ResourcePageContainer from "../components/Resources/ResourcePageContainer";

@inject("informativeMessageStore")
@observer
class InformativeSetMessagesPage extends Component {
  render() {
    return (
      <Fragment>
        <PageTitle id="Menu@messages">Mensagem na tela</PageTitle>
        <ResourcePageContainer
          appMainStore={this.props.informativeMessageStore}
          resourceType="message"
        />
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: InformativeSetMessagesPage,
  pageAllowedRule: "informative",
});
