// @ts-nocheck

import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../../HOC";
import AreaChart from "../../Charts/AreaChart";

// Utils
import { secondsToMinutesAndHours } from "../../../utils/DateUtils";

// Styles
const styles = {};

const UptimeChart = ({ localizedString, dateFormat, chartData }) => {
  const result = chartData.map(({ date, uptime }) => [date * 1000, uptime]);
  return (
    <AreaChart
      height={500}
      data={[
        {
          name: localizedString({
            id: "Device@uptimeInfo",
            defaultString: "Tempo ligado",
          }),
          data: result,
        },
      ]}
      showToolbar
      customChartOptions={{
        animations: { enabled: false },
        toolbar: {
          tools: {
            download: false,
          },
        },
      }}
      customOptions={{
        yaxis: {
          min: 0,
          labels: {
            formatter: secondsToMinutesAndHours,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: dateFormat,
            datetimeUTC: false,
          },
        },
        tooltip: {
          x: {
            format: dateFormat,
          },
          y: {
            formatter: secondsToMinutesAndHours,
          },
        },
        dataLabels: { enabled: false },
      }}
    ></AreaChart>
  );
};

export default withStyles(styles)(withLocalized(UptimeChart));
