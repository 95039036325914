// @ts-nocheck

import { Netmask } from "netmask";

class Network {
  constructor(addr, netmask) {
    this.netmask = new Netmask(addr, netmask);
  }

  get size() {
    try {
      return this.netmask.size;
    } catch (_) {
      return null;
    }
  }
  get first() {
    try {
      return this.netmask.first;
    } catch (_) {
      return null;
    }
  }
  get last() {
    try {
      return this.netmask.last;
    } catch (_) {
      return null;
    }
  }

  contains = (addr) => {
    try {
      return this.netmask.contains(addr);
    } catch (_) {
      return false;
    }
  };

  isPartOf = (addr) => {
    const onlyNumbers = (ipValue) => parseInt(ipValue.replace(/\./g, ""), 10);
    const first = onlyNumbers(this.first);
    const last = onlyNumbers(this.last);
    const addrToTest = onlyNumbers(addr);

    return addrToTest >= first && addrToTest <= last;
  };
}

export default Network;
