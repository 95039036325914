// @ts-nocheck
import { observable, computed, action } from "mobx";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

// Utils
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class ResourcesStore {
  @observable isFetching = false;
  @observable resourceRulesList = [];
  @observable searchString = "";

  constructor(
    internalEventsStore,
    groupsStore,
    notificationsStore,
    modboxAPIService
  ) {
    this.groupsStore = groupsStore;
    this.notificationsStore = notificationsStore;
    this.modboxAPIService = modboxAPIService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.resourceRulesList = [];
    this.searchString = "";
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setSearchString = (newValue) => {
    this.searchString = newValue;
  };
  @action
  setResourceRulesList = (newValue) => {
    this.resourceRulesList = newValue;
  };

  getResourceRulesList = async (resourceType, withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);
    if (this.groupsStore.groupsList.length < 1) {
      await this.groupsStore.getGroupsList();
    }

    let newResourcesList = [];

    return this.modboxAPIService
      .requestResources({
        resourceType,
      })
      .then((response) => {
        response.data[resourceType].forEach((resource) => {
          let terminalsList = [];

          if (
            resourceType === "playlist" &&
            resource.terminals instanceof Array
          ) {
            resource.terminals.forEach((terminalToken) => {
              terminalsList.push(terminalToken);
            });
          }

          const group = this.groupsStore.getWithToken(resource.group_token);

          newResourcesList.push({
            token: resource.token,
            resourceType,
            resourceToken: resource[`${resourceType}_token`],
            secondResourceToken: resource[`${resourceType}_second_token`],
            groupToken: resource.group_token,
            group: group,
            groupName: group.name,
            isActive: resource.is_active,
            order: resource.order,
            schedule:
              resource.schedule instanceof Array ? resource.schedule : [],
            label: resource.label,
            secondLabel: resource.second_label,
            terminalsList:
              resourceType === "playlist" ? terminalsList : undefined,
          });
        });

        const list = newResourcesList.sort(
          dynamicSortByProperty("groupName")
        );

        this.setResourceRulesList(list);
        this.setIsFetching(false);
      })
      .catch((error) => {
        window.debugError("Error in getResourceRulesList", error);
        if (error && error.statusCode === 429) {
          setTimeout(this.getResourceRulesList, 400);
        }
      });
  };

  @computed
  get filteredResourcesRuleList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.resourceRulesList,
        (resourceRule, textIncludesSearchString) =>
          textIncludesSearchString(resourceRule.label) ||
          textIncludesSearchString(resourceRule.group.name)
      );
    }

    return this.resourceRulesList;
  }

  @computed
  get resourcesRuleListByGroup() {
    let tempResult = {};
    let result = {};
    this.filteredResourcesRuleList.forEach((resourceRule) => {
      try {
        tempResult[resourceRule.groupToken].push(resourceRule);
      } catch (_) {
        tempResult[resourceRule.groupToken] = [resourceRule];
      }
    });

    // Apply order
    Object.keys(tempResult).forEach((id) => {
      result[id] = tempResult[id].sort(dynamicSortByProperty("order"));
    });

    return result;
  }

  @computed
  get singleResourceRule() {
    if (this.resourceRulesList.length > 0) {
      return this.resourceRulesList[0];
    }

    return null;
  }

  submit = ({
    resourceType,
    groupToken,
    resourceToken,
    secondResourceToken,
    schedule,
  }) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitResource({
          resourceType,
          resourceToken,
          groupToken,
          secondResourceToken,
          schedule: [schedule],
          isActive: true,
          order: this.resourceRulesList.length + 1,
        })
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: this.i18nStore.localizedString({
              id: "Schedule@ruleSubmitSuccessMessage",
              defaultString: "Configurou regra do recurso!",
            }),
            timeout: 2000,
            color: "success",
          });
          this.getResourceRulesList(resourceType, false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  update = (data) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .updateResource(data)
        .then((response) => {
          this.getResourceRulesList(data.resourceType, false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  delete = (data) => {
    this.modboxAPIService
      .deleteResource({
        token: data.token,
        resourceType: data.resourceType,
      })
      .then(() => {
        this.getResourceRulesList(data.resourceType, false);
      });
  };

  reorder = (data) => {
    const { resourcesList: updatedList } = data;
    const orderedList = this.resourceRulesList.map((resourceRule) => {
      let updatedItem = null;
      updatedList.forEach((item) => {
        if (resourceRule.token === item.token) {
          updatedItem = {
            ...resourceRule,
            order: item.order,
          };
        }
      });

      return updatedItem ? updatedItem : resourceRule;
    });

    this.setResourceRulesList(orderedList);

    this.applyReorderToApi(data);
  };

  applyReorderToApi = (data) => this.modboxAPIService.reorderResource(data);

  getWithToken = (token) => {
    if (this.resourceRulesList.length > 0) {
      return this.resourceRulesList.find(
        (resourceRule) => resourceRule.token === token
      );
    }
    return null;
  };
}

export default ResourcesStore;
