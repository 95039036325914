import { ReactElement } from "react";
import { observable, computed, action, reaction } from "mobx";
import { v4 as uuid } from "uuid";

// Translate
import { FormErrorString } from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

import AuthStore from "../AuthStore/AuthStore";
import SoundStore, { FEEDBACK_SOUND } from "../SoundStore/SoundStore";
import InternalEventsStore, {
  EInternalEvents,
} from "../InternalEventsStore/InternalEventsStore";

import ModboxAPIService from "../../services/ModboxAPIService";

import NotificationModel, {
  INotificationModel,
} from "../../Model/NotificationModel";

export interface ISnackbarNotification {
  timeout?: number;
  color?: "primary" | "success" | "danger" | "info" | "warning";
  title?: string | ReactElement;
  message: string | ReactElement;
  children?: any;
}

class NotificationsStore {
  authStore: AuthStore;
  soundStore: SoundStore;
  internalEventsStore: InternalEventsStore;
  modboxAPIService: ModboxAPIService;

  @observable
  notificationsList: INotificationModel[] = [];

  @observable
  snackbarNotifications: any[] = [];

  @observable
  snackbarNotificationsToRemove: any[] = [];

  @observable
  isFetching = false;
  @observable
  isDrawerOpen = false;

  intervalToRequestNotifications: any = null;

  constructor(modboxAPIService: ModboxAPIService) {
    this.modboxAPIService = modboxAPIService;
  }

  setStores = (
    authStore: AuthStore,
    soundStore: SoundStore,
    internalEventsStore: InternalEventsStore
  ) => {
    this.authStore = authStore;
    this.soundStore = soundStore;
    this.internalEventsStore = internalEventsStore;

    reaction(
      () => this.authStore.isAuthenticated,
      (isAuthenticated: boolean) => {
        if (isAuthenticated) {
          this.requestNotifications();

          this.intervalToRequestNotifications = setInterval(
            this.requestNotifications,
            600000
          );
        } else {
          this.clearStore();
        }
      },
      { fireImmediately: true }
    );
  };

  @action
  setSnackbarNotifications = (newValue) => {
    this.snackbarNotifications = newValue;
  };
  @action
  setSnackbarNotificationsToRemove = (newValue) => {
    this.snackbarNotificationsToRemove = newValue;
  };

  @action
  setNotificationsList = (newList: INotificationModel[]) => {
    this.notificationsList = newList;
  };

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setIsDrawerOpen = (newValue: boolean) => {
    this.isDrawerOpen = newValue;
  };

  @action
  clearStore = () => {
    this.notificationsList = [];
    this.snackbarNotifications = [];
    this.snackbarNotificationsToRemove = [];

    if (this.intervalToRequestNotifications !== null) {
      clearInterval(this.intervalToRequestNotifications);
      this.intervalToRequestNotifications = null;
    }
  };

  @computed
  get unreadNotificationsCount() {
    return this.notificationsList.filter(({ isRead }) => !isRead).length;
  }

  @computed
  get notShelvedNotificationsList() {
    return this.notificationsList.filter(({ isShelved }) => !isShelved);
  }

  @computed
  get shelvedNotificationsList() {
    return this.notificationsList.filter(({ isShelved }) => isShelved);
  }

  @action
  addSnackbarNotification = ({
    timeout = 4000,
    color = "success",
    title,
    message,
    children,
  }: ISnackbarNotification) => {
    const token = uuid();
    this.snackbarNotifications.push({
      token,
      timeout,
      color,
      title,
      message,
      children,
    });

    this.soundStore.playFeedbackSound(
      color === "danger" ? FEEDBACK_SOUND.Error : FEEDBACK_SOUND.Notification
    );

    return token;
  };

  @action
  removeSnackbarNotification = (notificationToken) => {
    this.snackbarNotificationsToRemove.push(notificationToken);
  };

  openDrawer = () => this.setIsDrawerOpen(true);
  closeDrawer = () => this.setIsDrawerOpen(false);
  toggleIsDrawerOpen = () => {
    this.setIsDrawerOpen(!this.isDrawerOpen);
  };

  getNotificationWithToken = (tokenToFind: string) =>
    this.notificationsList.find(({ token }) => token === tokenToFind);

  didReadNotification = async (token: string) => {
    this.closeDrawer();
    const notification = this.getNotificationWithToken(token);
    if (!notification) {
      return;
    }
    const { data, contractHash } = notification;
    if (data && data.length > 1) {
      this.internalEventsStore.notify(EInternalEvents.triggerActionOnContract, {
        action: data,
        contractHash,
      });
    }

    if (notification.isRead) {
      return;
    }
    try {
      await this.modboxAPIService.didReadNotification(token);
      this.requestNotifications();
    } catch (error) {
      window.debugError("error in didReadNotification", error);
    }
  };

  didShelveNotification = async (token: string) => {
    try {
      await this.modboxAPIService.didShelveNotification(token);
      this.requestNotifications();
    } catch (error) {
      window.debugError("error in didReadNotification", error);
    }
  };

  requestNotifications = async () => {
    this.setIsFetching(true);
    try {
      const response: any = await this.modboxAPIService.requestNotifications();
      if (response.status === 200) {
        let list: INotificationModel[] = [];
        response.data.forEach((notificationData: any) => {
          const notification = new NotificationModel(notificationData);
          list.push(notification);
        });

        this.setNotificationsList(list);
      }
    } catch (error) {
      window.debugError("error in requestNotifications", error);
    } finally {
      this.setIsFetching(false);
    }
  };

  addFormHasErrorNotification = () => {
    this.addSnackbarNotification({
      message: <FormErrorString />,
      timeout: 2000,
      color: "danger",
    });
  };

  addServerErrorNotification = () => {
    this.addSnackbarNotification({
      message: (
        //@ts-ignore
        <Translate id="Error@serverIsOverloaded">
          Servidores estão sobrecarregados. Por favor tente novamente mais
          tarde...
        </Translate>
      ),
      timeout: 10000,
      color: "danger",
    });
  };
}

export default NotificationsStore;
