//@ts-nocheck
import RadioGroup from "../../Form/RadioGroup";
import WizardUploadImage from "../Image";
import { WizarUploadRadioButtonBox, WizardUploadFor2Box } from "../style";
import { WizardUploadComponentProps } from "../type";

const WizardUploadVideoWall = ({
  payload,
  updateRadio,
}: WizardUploadComponentProps) => {
  return (
    <WizardUploadFor2Box>
      <div className="title">
        <h3>Seu conteúdo será exibido em um Videowall?</h3>
      </div>
      <div className="content">
        <div className="left">
          <p>
            Dica: Um videowall é uma exibição de vídeo que usa várias telas
            juntas para criar uma tela maior. Isso pode ser útil para exibir
            conteúdo em um espaço grande, como um saguão ou uma loja. Se você
            planeja usar um videowall, certifique-se de que seu conteúdo seja
            compatível com essa exibição escolha a orientação correta para a sua
            exibição.
          </p>

          <WizarUploadRadioButtonBox>
            <RadioGroup
              name="isVideowall"
              value={payload?.isVideowall}
              onChange={updateRadio}
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </WizarUploadRadioButtonBox>
        </div>
        <div className="right">
          <WizardUploadImage payload={payload} />
        </div>
      </div>
    </WizardUploadFor2Box>
  );
};

export default WizardUploadVideoWall;
