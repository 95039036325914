import useMediaQueryMUI from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

type QuerySizes = "xs" | "sm" | "md" | "lg" | "xl";

const useMediaQueryDown = (down: QuerySizes) => {
  const theme = useTheme();
  return useMediaQueryMUI(theme.breakpoints.down(down));
};
const useMediaQueryUp = (up: QuerySizes) => {
  const theme = useTheme();
  return useMediaQueryMUI(theme.breakpoints.up(up));
};

const useMediaQuery = ({
  down,
  up,
}: {
  down?: QuerySizes;
  up?: QuerySizes;
}) => {
  const mediaQueryD = useMediaQueryDown(down);
  const mediaQueryU = useMediaQueryUp(up);
  if (down !== null) {
    return mediaQueryD;
  } else if (up !== null) {
    return mediaQueryU;
  }
  return false;
};

export default useMediaQuery;
