// @ts-nocheck

class ModboxFileService {
  getFileExtensionByType = (fileType) => {
    switch (fileType) {
      case "logotype":
        return "png";
      case "wifiBanner":
      default:
        return "jpg";
    }
  };

  getUrlMediaTypeForFileType = (fileType) => {
    switch (fileType) {
      case "logotype":
        return "logo";
      case "wifiBanner":
        return "logo";
      case "video":
        return "video/1920x1080";
      case "videoVertical":
        return "video/1080x1920";
      default:
        return "image/320x180";
    }
  };

  mountFileUrl = ({ hash: fileHash, type: fileType }) => {
    if (fileHash.length < 1) {
      return "";
    }
    const { selectedContract } = this.contractStore;
    const { fileServer, path } = selectedContract;
    return `https://${fileServer}/media/${path}/${this.getUrlMediaTypeForFileType(
      fileType
    )}/${fileHash}.${this.getFileExtensionByType(fileType)}`;
  };

  mountMovieUrl = ({ hash: fileHash, isVertical = false, isCustom = false }) => {
    if (fileHash.length < 1) {
      return "";
    }

    let typeFile = "video/custom";
    if(!isCustom){
      typeFile = this.getUrlMediaTypeForFileType(
        isVertical ? "videoVertical" : "video"
      );
    }

    const { selectedContract } = this.contractStore;
    const { fileServer, path } = selectedContract;
    return `https://${fileServer}/media/${path}/${typeFile}/${fileHash}.mp4`;
  };
}

export default ModboxFileService;
