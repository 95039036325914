// @ts-nocheck
import { PureComponent, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Card, CardContent, CardActions } from "@material-ui/core";

import { Button } from "../../components/Button";
import IfExistsImage from "../../components/IfExistsImage/IfExistsImage";

// Icons
import { AddIcon, RemoveIcon, DeleteIcon } from "../../components/Icon";

// Style
import {
  dangerColor,
  successColor,
  primaryColor,
} from "../../assets/jss/app-js-styles";
const cardActionsButtonsDefaultStyle = {
  margin: 0,
};

const cardTitleDefaultStyle = {
  textAlign: "center",
  fontWeight: 500,
  margin: 0,
  fontSize: "0.9em",
  padding: "2px 0",
};

const styles = (theme) => ({
  card: {
    width: 150,
    margin: 16,
  },
  cardContent: {
    background: "#E2E2E2",
    textAlign: "center",
    padding: "0",
    minHeight: 110,
  },
  cardTitle: {
    ...cardTitleDefaultStyle,
    background: "#fff",
  },
  cardTitleSelected: {
    ...cardTitleDefaultStyle,
    background: "#1C85E1",
    color: "#fff",
  },
  cardImage: { maxWidth: "100%", minWidth: 80, maxHeight: 80 },
  cardImagePlaceholder: {
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardActions: {
    display: "flex",
    padding: 4,
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardSelectedNumber: {
    fontWeight: 500,
    fontSize: 22,
  },
  cardActionsPlusButton: {
    ...cardActionsButtonsDefaultStyle,
    color: successColor,
  },
  cardActionMinusButton: {
    ...cardActionsButtonsDefaultStyle,
    color: dangerColor,
  },
  cardActionDeleteButton: {
    ...cardActionsButtonsDefaultStyle,
    color: dangerColor,
  },
  cardActionViewButton: {
    ...cardActionsButtonsDefaultStyle,
    color: primaryColor,
  },
  grayscale: {
    filter: "grayscale(100%)",
  },
});

class MediaGridItem extends PureComponent {
  didIncreaseQty = () => {
    const { mediaItem, didChangeSelectedQty } = this.props;
    didChangeSelectedQty({
      ...mediaItem,
      selectedQty: mediaItem.selectedQty + 1,
    });
  };

  didDecreaseQty = () => {
    const { mediaItem, didChangeSelectedQty } = this.props;
    didChangeSelectedQty({
      ...mediaItem,
      selectedQty: mediaItem.selectedQty - 1,
    });
  };

  didSelect = () => this.props.didSelect(this.props.mediaItem, false);

  didWantToEditTags = () => this.props.didSelect(this.props.mediaItem, true);

  render() {
    const { classes, mediaItem, justMediaLibrary } = this.props;
    const isSelected = mediaItem.selectedQty > 0;

    return (
      <Fragment>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent} onClick={this.didSelect}>
            <p
              className={
                !justMediaLibrary && isSelected
                  ? classes.cardTitleSelected
                  : classes.cardTitle
              }
            >
              {mediaItem.name.substring(0, 17)}
              {mediaItem.name.length > 17 && "..."}
            </p>
            {mediaItem.type === "uploads" ? (
              !mediaItem.isProcessingFinished ? (
                <IfExistsImage
                  className={
                    isSelected || justMediaLibrary
                      ? classes.cardImage
                      : `${classes.cardImage} ${classes.grayscale}`
                  }
                  src={mediaItem.imageUrl}
                  placeholderClassName={classes.cardImagePlaceholder}
                  alt={mediaItem.name}
                />
              ) : (
                <img
                  className={
                    isSelected || justMediaLibrary
                      ? classes.cardImage
                      : `${classes.cardImage} ${classes.grayscale}`
                  }
                  src={mediaItem.imageUrl}
                  alt={mediaItem.name}
                />
              )
            ) : (
              <img
                className={classes.cardImage}
                src={
                  isSelected || justMediaLibrary
                    ? mediaItem.imageUrl
                    : mediaItem.imageUrl.replace(".jpg", "-alt.jpg")
                }
                alt={mediaItem.name}
              />
            )}
          </CardContent>
          <CardActions className={classes.cardActions}>
            {!justMediaLibrary && isSelected ? (
              <Button
                justIcon
                small
                color="transparent"
                className={classes.cardActionMinusButton}
                onClick={this.didDecreaseQty}
              >
                <RemoveIcon />
              </Button>
            ) : (
              <Button
                justIcon
                small
                color="transparent"
                className={classes.cardActionDeleteButton}
                onClick={() => this.props.didWantToDelete(mediaItem)}
                style={{ opacity: mediaItem.type === "extras" ? 0 : 1 }}
                disabled={mediaItem.type === "extras"}
              >
                <DeleteIcon />
              </Button>
            )}
            {!justMediaLibrary && (
              <span
                className={classes.cardSelectedNumber}
                style={{ opacity: isSelected ? 1 : 0.2 }}
              >
                {mediaItem.selectedQty}
              </span>
            )}
            {justMediaLibrary ? (
              <Button
                justIcon
                small
                color="transparent"
                className={classes.cardActionViewButton}
                onClick={this.didWantToEditTags}
              >
                <i className="fas fa-tags" />
              </Button>
            ) : (
              <Button
                justIcon
                small
                color="transparent"
                className={classes.cardActionsPlusButton}
                onClick={this.didIncreaseQty}
              >
                <AddIcon />
              </Button>
            )}
          </CardActions>
        </Card>
      </Fragment>
    );
  }
}

export default withStyles(styles)(MediaGridItem);
