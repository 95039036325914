import MediaModel from "./MediaModel";

import { IMAGE_PATH } from "../utils/EnvUtils";

import { MediaTypeEnum } from "../store/MediaLibraryStore/MediaLibraryStore";

class StreamingMediaModel extends MediaModel {
  creationDate;
  url;
  md5;
  isLive;
  isOffline;
  isLiveContent;
  expireTime;
  description;

  constructor(data) {
    super(
      MediaTypeEnum.STREAMING,
      data,
      `${IMAGE_PATH}/contents/${data.image}.jpg`
    );
    this.creationDate = data.creation_date;
    this.url = data.url;
    this.md5 = data.md5;
    this.isLive = data.live === "True";
    this.isOffline = data.offline === "True";
    this.isLiveContent = data.type === "live" || data.content_type === "live";
    this.expireTime = data.expire_time || "0";
    this.description = data.description;
  }
}

export default StreamingMediaModel;
