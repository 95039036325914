// @ts-nocheck
import { Component } from "react";
import * as Sentry from "@sentry/browser";

import { saveComponentErrorLog } from "../store/FirebaseStore/FirebaseStore";
import Translate from "./I18n/Translate";

import { GridFullScreen } from "./Grid";
import { Button } from "./Button";

interface IErrorCatcherProps {
  children: any;
  showErrorOnTheScreen: boolean;
}

class ErrorCatcher extends Component<IErrorCatcherProps> {
  constructor(props: IErrorCatcherProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState(
      {
        hasError: true,
      },
      () => {
        saveComponentErrorLog({
          error: error && error.toString(),
          ...errorInfo,
        });

        Sentry.configureScope((scope) => {
          Object.keys(errorInfo).forEach((key) => {
            scope.setExtra(key, errorInfo[key]);
          });
        });
        Sentry.captureException(error);
      }
    );
  }

  render() {
    if (this.props.showErrorOnTheScreen && this.state.hasError) {
      return (
        <GridFullScreen>
          <p style={{ fontSize: "1.2em" }}>
            <i
              className="fa fa-exclamation-triangle"
              style={{ marginRight: 6 }}
            />
            <Translate id="Common@interfaceCatchErrorMessage">
              Ocorreu um erro inesperado. Em caso de dúvidas entre em contato
              com o suporte.
            </Translate>
          </p>
          <Button color="primary" onClick={() => Sentry.showReportDialog()}>
            <Translate id="Debug@sendFeedbackButtonLabel">
              Enviar feedback
            </Translate>
          </Button>
        </GridFullScreen>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorCatcher.defaultProps = {
  showErrorOnTheScreen: true,
};

export default ErrorCatcher;
