import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FC, ReactElement } from "react";
import ReactDOM from "react-dom";

import useMediaQuery from "../../hooks/useMediaQuery";

import { Button } from "../Button";

const useStyles = makeStyles({
  dialog60vh: {
    height: "60vh",
  },
  dialog80vh: {
    height: "80vh",
  },
  dialog100vh: {
    height: "100vh",
  },
  modalCloseButton: {
    position: "absolute",
    margin: 0,
    right: 0,
    top: 0,
    zIndex: 1000000,
  },
  content: {
    borderBottom: "none",
  },
  overflowVisible: {
    overflow: "visible",
  },
  actions: {
    background: "hsl(210, 9%, 96%)",
  },
});

interface ModalProps {
  isOpen: boolean;
  didCloseModal?: () => void;
  disableEscapeKeyDown?: boolean;
  modalTitle?: string | ReactElement | ReactElement[];
  hideCloseButton?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  height?: 60 | 80 | 100;
  fullScreen?: boolean;
  actions?: ReactElement | ReactElement[];
  contentOverflowVisible?: boolean;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  didCloseModal,
  disableEscapeKeyDown = false,
  modalTitle,
  hideCloseButton,
  maxWidth = "md",
  height,
  fullScreen = false,
  actions,
  contentOverflowVisible = false,
  children,
}) => {
  const classes = useStyles();
  const ariaLabelledby =
    modalTitle && typeof modalTitle === "string"
      ? encodeURI(modalTitle)
      : "modal-title";

  const isMobileMediaQuery = useMediaQuery({ down: "sm" });

  return ReactDOM.createPortal(
    <Dialog
      fullScreen={fullScreen || isMobileMediaQuery}
      open={isOpen}
      onClose={() => {
        didCloseModal?.();
      }}
      maxWidth={maxWidth}
      fullWidth
      aria-labelledby={ariaLabelledby}
      disableEscapeKeyDown={disableEscapeKeyDown || !didCloseModal}
      classes={{
        paper: classnames({
          [classes.dialog60vh]: height === 60,
          [classes.dialog80vh]: height === 80,
          [classes.dialog100vh]: height === 100,
          [classes.overflowVisible]: contentOverflowVisible,
        }),
      }}
    >
      {!hideCloseButton && didCloseModal && (
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={didCloseModal}
        >
          <i className="fas fa-times" />
        </Button>
      )}
      {modalTitle && (
        <DialogTitle id={ariaLabelledby}>{modalTitle}</DialogTitle>
      )}
      <DialogContent
        classes={{
          root: classnames({
            [classes.content]: true,
            [classes.overflowVisible]: contentOverflowVisible,
          }),
        }}
        dividers
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions classes={{ root: classes.actions }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>,
    document.getElementById("modal-root")
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  didCloseModal: PropTypes.func,
  disableEscapeKeyDown: PropTypes.bool,
  contentOverflowVisible: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  height: PropTypes.oneOf([60, 80, 100]),
  fullScreen: PropTypes.bool,
};

export default Modal;
