// @ts-nocheck
import { Fragment, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";

import Tag from "./Tag";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import { GridContainer, GridItem } from "../Grid";
import Translate from "../I18n/Translate";
import TagForm from "../AppForm/TagForm";

// Utils
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      marginBottom: 16,
    },
    label: {
      display: "block",
      marginBottom: 0,
      marginTop: 16,
      color: "#AAAAAA !important",
      fontWeight: "500",
      textTransform: "uppercase",
      fontSize: 12,
      lineHeight: "1.42857",
    },
    button: {
      width: "24px !important",
      height: "24px !important",
      minWidth: "24px !important",
      margin: 4,
    },
    tagItemContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tagItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "90%",
      height: 30,
      borderRadius: 8,
      color: "#fff !important",
      fontWeight: "bold",
      textTransform: "uppercase",
      padding: "0 8px",
      cursor: "pointer",
    },
  })
);

const TagsContainer = observer(
  ({ hideLabel, isOpen, tagsList, selectedTags, onChangeSelectedTags }) => {
    const classes = useStyles();

    const selectedTagsTokens = selectedTags.map(({ token }) => token);
    const [isOpenModal, setIsOpenModal] = useState(isOpen);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedTagToEdit, setSelectedTagToEdit] = useState(null);

    const openModal = () => setIsOpenModal(true);
    const closeModal = () => {
      setIsOpenModal(false);
      didCancelForm();
    };

    const didSelectTag = (tag) => () => {
      const newTagsList = selectedTags.find(({ token }) => token === tag.token)
        ? selectedTags.filter(({ token }) => token !== tag.token)
        : [...selectedTags, tag];

      onChangeSelectedTags(newTagsList.sort(dynamicSortByProperty("label")));
    };

    const didWantToEditTag = (tag) => () => {
      setSelectedTagToEdit(tag);
      setIsFormOpen(true);
    };

    const didCancelForm = () => {
      setSelectedTagToEdit(null);
      setIsFormOpen(false);
    };

    const didSaveForm = (tag) => {
      if (!selectedTagToEdit) {
        didSelectTag(tag)();
      }
      didCancelForm();
    };

    const haveSelectedTag = selectedTags.length > 0;
    const isEditingTag = isFormOpen && selectedTagToEdit !== null;

    return (
      <Fragment>
        <Modal
          isOpen={isOpenModal}
          modalTitle={
            isEditingTag ? (
              <Translate id="Tags@editTagTitle">Editar etiqueta</Translate>
            ) : isFormOpen ? (
              <Translate id="Tags@createNewButtonLabel">
                Criar nova etiqueta
              </Translate>
            ) : (
              <Translate id="Common@tags">Etiquetas</Translate>
            )
          }
          maxWidth="xs"
          didCloseModal={closeModal}
        >
          {isFormOpen ? (
            <TagForm
              tag={selectedTagToEdit}
              didCancel={didCancelForm}
              didSave={didSaveForm}
            />
          ) : (
            <GridContainer>
              <GridItem xs={12}>
                {tagsList.map((tag) => (
                  <div key={tag.token} className={classes.tagItemContainer}>
                    <div
                      className={classes.tagItem}
                      style={{ backgroundColor: tag.color }}
                      onClick={didSelectTag(tag)}
                    >
                      <span>
                        <i className="fas fa-tag" style={{ marginRight: 8 }} />
                        {tag.label}
                      </span>
                      {selectedTagsTokens.includes(tag.token) && (
                        <i className="fas fa-check" />
                      )}
                    </div>
                    <Button
                      color="primary"
                      outline
                      justIcon
                      small
                      onClick={didWantToEditTag(tag)}
                    >
                      <i className="fas fa-pen" />
                    </Button>
                  </div>
                ))}
              </GridItem>

              <GridItem xs={12} style={{ marginTop: 16 }}>
                <Button
                  color="primary"
                  outline
                  block
                  small
                  onClick={() => setIsFormOpen(true)}
                >
                  <Translate id="Tags@createNewButtonLabel">
                    Criar nova etiqueta
                  </Translate>{" "}
                  <i className="fas fa-plus" />
                </Button>
              </GridItem>
            </GridContainer>
          )}
        </Modal>
        <GridContainer>
          {!hideLabel && (
            <GridItem xs={12}>
              <p className={classes.label}>
                <Translate id="Common@tags">Etiquetas</Translate>
              </p>
            </GridItem>
          )}
          <GridItem xs={12}>
            <div className={classes.root}>
              {selectedTags.map((tag, index) => (
                <Tag key={index} {...tag} withMargin onClick={openModal} />
              ))}
              <Button
                color="default"
                justIcon={haveSelectedTag}
                small
                onClick={openModal}
                className={haveSelectedTag ? classes.button : null}
              >
                {!haveSelectedTag && (
                  <span style={{ marginRight: 8 }}>
                    <Translate id="Tags@selectFirstTagButtonLabel">
                      Adicione etiqueta(s)
                    </Translate>
                  </span>
                )}
                <i className="fas fa-plus" />
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
);

TagsContainer.defaultProps = {
  isOpen: false,
};

export default TagsContainer;
