// @ts-nocheck
import { Component, Fragment } from "react";
import { observer } from "mobx-react";

import { withLocalized } from "../../components/HOC";
import { GridContainer } from "../../components/Grid";

import MetricChartSectionTitle from "../../components/Charts/MetricChartSectionTitle";
import MetricChartBody from "../../components/Charts/MetricChartBody";

import VisitorsChartWidget from "./VisitorsChartWidget";
import VisitorsPublicityChartWidget from "./VisitorsPublicityChartWidget";

// Translate
import Translate from "../../components/I18n/Translate";

@withLocalized
@observer
class VisitorsChartContainer extends Component {
  prepareChartData = (result) => {
    if (!result) return null;

    let publicity = null;
    const accessByDeviceVendor = result.access_by_device_vendor || null;
    const connectionsPerType = result.connections_by_type || null;

    if (accessByDeviceVendor && connectionsPerType) {
      const iOS = accessByDeviceVendor["Apple"] || 0;
      let others = 0;

      Object.keys(accessByDeviceVendor).forEach((vendor) => {
        if (vendor !== "Apple") {
          others += accessByDeviceVendor[vendor];
        }
      });

      publicity = {
        connectionsPerType: {
          identified: connectionsPerType.identified || 0,
          authenticated: connectionsPerType.authenticated || 0,
          authenticating: connectionsPerType.authenticating || 0,
          connected: connectionsPerType.connected || 0,
        },
        accessByDeviceVendor: {
          iOS,
          others,
        },
      };
    }

    const statistics = result.users_statistics || null;
    const peopleByGroup = result.people_by_group || null;

    let peopleStatistics = null;

    if (statistics) {
      peopleStatistics = {
        nearbyTimeAverage: statistics.all_time_mean,
        authenticatedTimeAverage: statistics.authenticated_time_mean,
        recurrent: statistics.recurrent,
        new: statistics.new,
      };
    }

    return {
      peopleStatistics,
      publicity,
      peopleByGroup,
    };
  };

  renderVisitorsProfile = (connectionType) => {
    if (connectionType === 0) {
      return (
        <Translate id="Connectivity@visitorsAnonymousChartTitle">
          Perfil dos visitantes anônimos
        </Translate>
      );
    }
    if (connectionType === 1) {
      return (
        <Translate id="Connectivity@visitorsConnectedChartTitle">
          Perfil dos visitantes conectados
        </Translate>
      );
    }
    if (connectionType === 2) {
      return (
        <Translate id="Connectivity@visitorsAuthenticatedChartTitle">
          Perfil dos visitantes autenticados
        </Translate>
      );
    }
    return (
      <Translate id="Connectivity@visitorsChartTitle">
        Perfil dos visitantes
      </Translate>
    );
  };

  render() {
    const {
      isFetching,
      error,
      chartData: result,
      groupsList,
      datePeriod,
      connectionType,
    } = this.props;
    const chartData = this.prepareChartData(result);

    return (
      <GridContainer justify="center">
        <MetricChartSectionTitle
          groupsList={groupsList}
          datePeriod={datePeriod}
        >
          {this.renderVisitorsProfile(connectionType)}
        </MetricChartSectionTitle>
        <MetricChartBody isFetching={isFetching} hasError={error}>
          <VisitorsChartWidget
            chartData={chartData}
            isDisabled={this.props.isDisabled}
          />
        </MetricChartBody>
        {chartData && chartData.publicity && (
          <Fragment>
            <MetricChartSectionTitle
              groupsList={groupsList}
              datePeriod={datePeriod}
              showDivider
            >
              <Translate id="Connectivity@visitorsPublicityChartTitle">
                Publicidade
              </Translate>
            </MetricChartSectionTitle>
            <MetricChartBody isFetching={isFetching} hasError={error}>
              <VisitorsPublicityChartWidget chartData={chartData.publicity} />
            </MetricChartBody>
          </Fragment>
        )}
      </GridContainer>
    );
  }
}

export default VisitorsChartContainer;
