import { FC } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";

// Styles
const useStyles = makeStyles({
  base: {
    marginBottom: 0,
  },
  normal: {
    fontSize: "1.1em",
  },
  large: {
    fontSize: "1.4em",
  },
});

interface IInfoLabelProps {
  className?: string;
  large?: boolean;
  hideIcon?: boolean;
  style?: object;
}

const InfoLabel: FC<IInfoLabelProps> = ({
  className,
  children,
  large = false,
  hideIcon = false,
  style,
}) => {
  const classes = useStyles();

  return (
    <p
      className={classnames({
        [classes.base]: true,
        [classes.normal]: !large,
        [classes.large]: large,
        [className]: className,
      })}
      style={style}
    >
      {!hideIcon && (
        <i className="fa fa-info-circle" style={{ marginRight: 6 }} />
      )}{" "}
      {children}
    </p>
  );
};

InfoLabel.defaultProps = {
  className: null,
};

export default InfoLabel;
