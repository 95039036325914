// @ts-nocheck

import animationData from "./DrinkMachine.json";
import LottieAnimation from "../LottieAnimation";

const DrinkMachine = props => (
  <LottieAnimation animationData={animationData} {...props} />
);

export default DrinkMachine;
