import { observable, action } from "mobx";

class ContractBusinessModel {
  name;
  activity;
  documents;
  owner;
  contacts;
  address;
  @observable isChecked;
  @observable isWaitingApproval;
  @observable status;

  constructor(data) {
    this.name = data?.business_name || "";
    this.activity = data?.business_activity || "";
    this.documents = data?.business_documents || [];
    this.owner = data?.business_owner || "";
    this.contacts = data?.business_contacts || [];
    this.address = data?.business_address || {
      zipCode: "",
      address: "",
      number: "",
      neighborhood: "",
      city: "",
      state: "",
      country: "",
    };
    this.setStatusWithIsChecked(data.is_checked === "True");
  }

  hasData = () =>
    this.name.length > 0 && this.owner.length > 0 && this.contacts.length > 0;

  @action
  setStatusWithIsChecked = (isChecked) => {
    this.isChecked = isChecked;
    this.isWaitingApproval = !isChecked && this.hasData();
    this.status = isChecked
      ? "approved"
      : this.isWaitingApproval
      ? "waitingApproval"
      : "notChecked";
  };
}

export default ContractBusinessModel;
