// @ts-nocheck


import Translate from "../I18n/Translate";

const AdvertisingMessageHelpText = ({ messageLength, maxLength }) => {
  if (messageLength >= maxLength) {
    return (
      <Translate id="Advertising@messageMaxLengthHint">
        Tamanho máximo da mensagem atingido
      </Translate>
    );
  }
  if (messageLength === maxLength - 1) {
    return (
      <Translate id="Advertising@messageLengthHintOneLeft">
        Apenas 1 caractere restante
      </Translate>
    );
  }

  return (
    <Translate
      id="Advertising@messageLengthHint"
      variables={{ characteresLeft: maxLength - messageLength }}
    >
      $characteresLeft caracteres restantes
    </Translate>
  );
};

AdvertisingMessageHelpText.defaultProps = {
  maxLength: 120,
};

export default AdvertisingMessageHelpText;
