// @ts-nocheck


import GridContainer from "./GridContainer";
import GridItem from "./GridItem";

const GridFullScreen = ({ children }) => (
  <GridContainer
    justify="center"
    alignItems="center"
    style={{ height: "100vh", textAlign: "center" }}
  >
    <GridItem xs={12}>{children}</GridItem>
  </GridContainer>
);

export default GridFullScreen;
