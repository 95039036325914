class ConnectivityDeviceModel {
  token;
  label;
  status;
  code;
  phoneNumber;
  macAddress;
  groups;
  expires;
  validityAfterActivation;
  isExpired;
  createdAt;

  constructor(data, groupsStore) {
    this.token = data.token || data.devices_network_token;
    this.label = data.label;
    this.status = data.status;
    this.code = data.code || null;
    this.phoneNumber = data.phone_number || null;
    this.macAddress = data.mac_address || null;
    this.groups = data.groups
      .map(groupsStore.getWithToken)
      .filter(($0) => $0 !== undefined && $0 !== null);
    this.expires = data.expires || 0;
    this.validityAfterActivation = data.validity_after_activation || null;
    this.isExpired = data.is_expired === "True" || false;
    this.createdAt = data.created_at;
  }
}

export default ConnectivityDeviceModel;
