// @ts-nocheck

import { inject } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";

import { withLocalized } from "../HOC";

// Translate
import Translate from "../I18n/Translate";

// Utils Store
import predefinedMessage, {
  PREDEFINED_MESSAGES_KEYS,
} from "../../store/ChatStore/PredefinedMessages";

// Style
const styles = {
  insufficientCreditsContainer: {
    marginTop: 60,
    marginBottom: 60,
    textAlign: "center",
  },
  insufficientCreditsLabel: {
    fontSize: "1.4em",
  },
};

const AdvertisingInsufficientCreditsGrid = inject("chatStore")(
  ({ chatStore, localizedString, classes }) => (
    <GridContainer justify="center">
      <GridItem xs={12} className={classes.insufficientCreditsContainer}>
        <p className={classes.insufficientCreditsLabel}>
          <Translate id="Advertising@insufficientCreditsMessage">
            Você não possui créditos para enviar mensagem no momento.
          </Translate>
        </p>
        <Button
          onClick={() => {
            chatStore.didWantToStartNewChatWithSubjectAndMessage(
              localizedString({
                id: "ContractLimit@requestSmsCreditsSubject",
                defaultString: "Aumentar créditos SMS",
              }),
              predefinedMessage(
                PREDEFINED_MESSAGES_KEYS.requestSmsCredits,
                localizedString
              )
            );
          }}
        >
          <Translate id="Advertising@didWantToBuySMSCreditsButtonLabel">
            Solicite créditos aqui
          </Translate>
        </Button>
      </GridItem>
    </GridContainer>
  )
);

export default withLocalized(
  withStyles(styles)(AdvertisingInsufficientCreditsGrid)
);
