export const displayInteger = (value, leadingZero = true) => {
  if (value < 10) {
    return leadingZero ? `0${value}` : value;
  } else if (value < 1000) return value;

  const valueString = value.toString();
  let resultString = "";

  let conditionalIndex = 1;
  for (let index = valueString.length - 1; index >= 0; index--) {
    if (conditionalIndex === 4) {
      resultString += ".";
      conditionalIndex = 2;
    } else {
      conditionalIndex++;
    }

    resultString += valueString[index];
  }

  return resultString
    .split("")
    .reverse()
    .join("");
};
