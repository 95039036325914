import ModboxAPIService from "./ModboxAPIService";
import ModboxFileService from "./ModboxFileService";
import PagSeguroAPIService from "./PagSeguroAPIService";
import TelegramAPIService from "./TelegramAPIService";
import ViacepAPIService from "./ViacepAPIService";
import WordPressAPIService from "./WordPressAPIService";
import DiscourseAPIService from "./DiscourseAPIService";
import AutonomediaService from "./AutonomediaService";
import KeyboardEventsService from "./KeyboardEventsService";

import { IAnalyticsService } from "../interfaces/IAnalyticsService";
import GoogleAnalyticsService from "./GoogleAnalyticsService";

class MainServices {
  analyticsService: IAnalyticsService;
  modboxAPIService: ModboxAPIService;
  modboxFileService: ModboxFileService;
  pagSeguroAPIService: PagSeguroAPIService;
  telegramAPIService: TelegramAPIService;
  viacepAPIService: ViacepAPIService;
  wordPressAPIService: WordPressAPIService;
  discourseAPIService: DiscourseAPIService;
  autonomediaService: AutonomediaService;
  keyboardEventsService: KeyboardEventsService;

  constructor() {
    this.analyticsService = new GoogleAnalyticsService();
    this.modboxAPIService = new ModboxAPIService();
    this.modboxFileService = new ModboxFileService();
    this.pagSeguroAPIService = new PagSeguroAPIService();
    this.telegramAPIService = new TelegramAPIService();
    this.viacepAPIService = new ViacepAPIService();
    this.wordPressAPIService = new WordPressAPIService();
    this.discourseAPIService = new DiscourseAPIService();
    this.autonomediaService = new AutonomediaService();
    this.keyboardEventsService = new KeyboardEventsService();
  }
}

export default MainServices;
