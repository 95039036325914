// @ts-nocheck

export const PREDEFINED_MESSAGES_KEYS = Object.freeze({
  requestDeleteDevice: "requestDeleteDeviceMessage",
  requestLicences: "requestLicencesMessage",
  requestStorage: "requestStorageMessage",
  requestWifiAuthentication: "requestWifiAuthenticationMessage",
  requestSmsCredits: "requestSmsCreditsMessage",
  requestCameraFacesCredits: "requestCameraFacesCreditsMessage",
  requestCustomPlan: "requestCustomPlanMessage",
  wifiNotification: "wifiNotificationMessage",
  storageNotification: "storageNotificationMessage",
  smsNotification: "smsNotificationMessage",
  energyNotification: "energyNotificationMessage",
  temperatureNotification: "temperatureNotificationMessage",
});

const predefinedMessage = (key, localizedString, variables = {}) => {
  let localizedMessageId = "";
  let localizedMessageDefaultString = "";
  switch (key) {
    case PREDEFINED_MESSAGES_KEYS.requestDeleteDevice:
      localizedMessageId = "Chat@requestDeleteDeviceMessage";
      localizedMessageDefaultString =
        "Solicito a exclusão do seguinte dispositivo: $deviceName ($deviceToken)";
      break;
    case PREDEFINED_MESSAGES_KEYS.requestLicences:
      localizedMessageId = "Chat@requestLicencesMessage";
      localizedMessageDefaultString =
        "Solicito contato comercial pois preciso ativar mais dispositivos no meu contrato.";
      break;
    case PREDEFINED_MESSAGES_KEYS.requestStorage:
      localizedMessageId = "Chat@requestStorageMessage";
      localizedMessageDefaultString =
        "Solicito contato comercial pois preciso de mais espaço em disco.";
      break;
    case PREDEFINED_MESSAGES_KEYS.requestWifiAuthentication:
      localizedMessageId = "Chat@requestWifiAuthenticationMessage";
      localizedMessageDefaultString =
        "Solicito contato comercial pois preciso de mais autenticações inteligentes para minha Wi-Fi.";
      break;
    case PREDEFINED_MESSAGES_KEYS.requestSmsCredits:
      localizedMessageId = "Chat@requestSmsCreditsMessage";
      localizedMessageDefaultString =
        "Solicito contato comercial pois preciso de créditos SMS.";
      break;
    case PREDEFINED_MESSAGES_KEYS.requestCameraFacesCredits:
      localizedMessageId = "Chat@requestCameraFacesCreditsMessage";
      localizedMessageDefaultString =
        "Solicito contato comercial pois preciso de mais créditos para capturas de faces com câmeras.";
      break;
    case PREDEFINED_MESSAGES_KEYS.wifiNotification:
      localizedMessageId = "Notification@wifiNotificationMessage";
      localizedMessageDefaultString =
        "Ops! Você precisa solicitar mais créditos para continuar autenticando usuários na sua Wi-Fi, o limite está próximo.";
      break;
    case PREDEFINED_MESSAGES_KEYS.storageNotification:
      localizedMessageId = "Notification@storageNotificationMessage";
      localizedMessageDefaultString =
        "Que tal aumentar o espaço de armazenamento das suas midias na nuvem?";
      break;
    case PREDEFINED_MESSAGES_KEYS.smsNotification:
      localizedMessageId = "Notification@smsNotificationMessage";
      localizedMessageDefaultString =
        "Você precisará de mais créditos para continuar o envio de mensagens aos seus usuários!";
      break;
    case PREDEFINED_MESSAGES_KEYS.energyNotification:
      localizedMessageId = "Notification@energyNotificationMessage";
      localizedMessageDefaultString =
        "Atenção, para o bom funcionamento do sistema, verifique as condições de energia do(s) dispositivo(s) abaixo.";
      break;
    case PREDEFINED_MESSAGES_KEYS.temperatureNotification:
      localizedMessageId = "Notification@temperatureNotificationMessage";
      localizedMessageDefaultString =
        "Ai que calor! Esta é a mensagem enviada pelo(s) dispositivo(s) abaixo. Por favor, verifique imediatamente antes que ...";
      break;
    case PREDEFINED_MESSAGES_KEYS.requestCustomPlan:
    default:
      localizedMessageId = "Chat@requestCustomPlanMessage";
      localizedMessageDefaultString =
        "Solicito contato comercial para discutir detalhes do plano personalizado para minha empresa.";
  }

  return localizedString(
    {
      id: localizedMessageId,
      defaultString: localizedMessageDefaultString,
    },
    variables
  );
};

export default predefinedMessage;
