// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import ResourcePageContainer from "../components/Resources/ResourcePageContainer";

import PageTitle from "../components/PageTitle/PageTitle";

@inject("informativeLogotypeStore")
@observer
class InformativeSetLogotypePage extends Component {
  render() {
    return (
      <Fragment>
        <PageTitle id="Menu@logotype">Logotipo</PageTitle>
        <ResourcePageContainer
          appMainStore={this.props.informativeLogotypeStore}
          resourceType="logotype"
        />
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: InformativeSetLogotypePage,
  pageAllowedRule: "informative",
});
