import MediaModel from "./MediaModel";

import { MediaTypeEnum } from "../store/MediaLibraryStore/MediaLibraryStore";
import ModboxFileService from "../services/ModboxFileService";

class UploadMediaModel extends MediaModel {
  md5: string;
  creationDate: string;
  duration: string;
  expireTime: string;
  isCustom: boolean;
  videoUrl: string;

  constructor(data: any, modboxFileService: ModboxFileService) {
    super(
      MediaTypeEnum.UPLOAD,
      data,
      data.url_img
      // modboxFileService.mountFileUrl({
      //   hash: data.token,
      //   type: "file",
      // })
    );
    this.videoUrl = data.url_video;
    this.isCustom = data.is_custom;
    this.md5 = data.md5;
    this.creationDate = data.creation_date;
    this.duration = data.duration;
    this.expireTime = data.expire_time || "0";
  }
}

export default UploadMediaModel;
