// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import { Component, Fragment } from "react";

import SelectTags from "../../components/Form/SelectTags";
import { GridContainer, GridItem } from "../../components/Grid";
import { withLocalized } from "../../components/HOC";

import ConfirmModal from "../../components/Modal/ConfirmModal";
import ModalMediaDetails from "../../components/Modal/ModalMediaDetails";
import ModalUploadMediaFile from "../../components/Modal/ModalUploadMediaFile";
import Paginate from "../../components/Paginate/Paginate";
import SearchBar from "../../components/SearchBar/SearchBar";

import Button from "../../components/Button/Button";
import MediaGridAddButton from "./MediaGridAddButton";
import MediaGridItem from "./MediaGridItem";
import MediaGroupPanel from "./MediaGroupPanel";

// Translate
import {
  CancelString,
  ConfirmActionString,
} from "../../components/I18n/CommonStrings";
import Plural from "../../components/I18n/Plural";
import Translate from "../../components/I18n/Translate";

// Utils Stores
import { MediaTypeEnum } from "../../store/MediaLibraryStore/MediaLibraryStore";
import { FEEDBACK_SOUND } from "../../store/SoundStore/SoundStore";

// Flags
import brFlag from "../../assets/img/flags/4x3/br.svg";
import usFlag from "../../assets/img/flags/4x3/us.svg";

// Style
const styles = {
  bodyContainer: {
    marginBottom: 30,
  },
  selectedMediaContainerTitle: {
    margin: 0,
    fontSize: "1.2em",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  searchTextInfo: {
    fontSize: "1.2em",
  },
};

@withLocalized
@inject(
  "alfredStore",
  "mediaLibraryStore",
  "soundStore",
  "tagsStore",
  "contractStore"
)
@observer
class SelectMediaContainer extends Component {
  state = {
    alert: null,
    selectedMediasList: [],
    expandedContainer: null,
    isAddingNewMedia: false,
    selectedMediaItem: null,
    didWantToEditMedia: false,
    wantToEditTags: false,
    modalUploadMediaFileIndexToOpen: 0,
  };

  get isBroadcastEnabled(): boolean {
    return !!this.props.contractStore.selectedContract.resources
      ?.liveOnlineContent;
  }

  componentDidMount() {
    const { mediaLibraryStore } = this.props;
    mediaLibraryStore.updateMediasList();
    mediaLibraryStore.setSearchString("");

    const alfredAction = (title, mediaType) => ({
      id: `add${mediaType}`,
      title: title,
      subtitle: this.getMediaGroupSubtitle(mediaType),
      icon: "plus",
      callback: this.toggleAddNewMedia(mediaType),
    });

    this.props.alfredStore.addActions({
      id: "uploadMediaActions",
      label: "Biblioteca de mídias",
      options: [
        alfredAction("Fazer upload", MediaTypeEnum.UPLOAD),
        alfredAction("Novo vídeo da web", MediaTypeEnum.STREAMING),
        alfredAction("Adicionar conteúdo", MediaTypeEnum.CONTENT),
      ],
    });
  }

  componentWillUnmount() {
    this.props.alfredStore.removeActions("uploadMediaActions");
  }

  toggleAddNewMedia = (mediaGroupPanelName) => () => {
    let indexToOpen = 0;

    if (mediaGroupPanelName === MediaTypeEnum.STREAMING) {
      indexToOpen = 1;
    } else if (mediaGroupPanelName === MediaTypeEnum.CONTENT) {
      indexToOpen = 2;
    }

    this.setState({
      modalIsOpen: true,
      isAddingNewMedia: !this.state.isAddingNewMedia,
      modalUploadMediaFileIndexToOpen: indexToOpen,
    });
  };

  didCloseModal = () => {
    this.setState(
      {
        modalIsOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isAddingNewMedia: false,
            selectedMediaItem: null,
            didWantToEditMedia: false,
            modalUploadMediaFileIndexToOpen: 0,
          });
        }, 200);
      }
    );
  };

  didSelectMediaItem = (selectedMediaItem, wantToEditTags) => {
    if (selectedMediaItem.type !== MediaTypeEnum.EXTRAS) {
      this.setState({
        selectedMediaItem,
        didWantToEditMedia: false,
        wantToEditTags,
        modalIsOpen: true,
      });
    }
  };

  changeExpandedContainer = (groupType) => {
    const expanded =
      groupType === this.state.expandedContainer ? null : groupType;
    this.setState(
      {
        expandedContainer: expanded,
      },
      () => {
        if (expanded) {
          this.props.soundStore.playFeedbackSound(FEEDBACK_SOUND.Expand);
        } else {
          this.props.soundStore.playFeedbackSound(FEEDBACK_SOUND.Collapse);
        }
      }
    );
  };

  getMediaGroupTitle = (groupType) => {
    const { localizedString } = this.props;
    switch (groupType) {
      case MediaTypeEnum.UPLOAD:
        return localizedString({
          id: "Informative@mediaGroupUploadTitle",
          defaultString: "Seus Uploads",
        });

      case MediaTypeEnum.STREAMING:
        const id = this.isBroadcastEnabled
          ? "Informative@mediaGroupStreamingTitleWithBroadcast"
          : "Informative@mediaGroupStreamingTitleWithoutBroadcast";
        const defaultString = this.isBroadcastEnabled
          ? "Vídeos da web e Transmissões"
          : "Vídeos da web";
        return localizedString({
          id,
          defaultString,
        });

      case MediaTypeEnum.CONTENT:
        return localizedString({
          id: "Informative@mediaGroupContentTitle",
          defaultString: "Conteúdos Externos",
        });

      case MediaTypeEnum.EXTRAS:
      default:
        return localizedString({
          id: "Informative@mediaGroupExtrasTitle",
          defaultString: "Recursos Extras",
        });
    }
  };

  getMediaGroupSubtitle = (groupType) => {
    const { localizedString } = this.props;
    switch (groupType) {
      case MediaTypeEnum.UPLOAD:
        return localizedString({
          id: "Informative@mediaGroupUploadSubtitle",
          defaultString: "Imagens e vídeos enviados do seu computador.",
        });

      case MediaTypeEnum.STREAMING:
        const id = this.isBroadcastEnabled
          ? "Informative@mediaGroupStreamingTitleWithBroadcast"
          : "Informative@mediaGroupStreamingTitleWithoutBroadcast";
        const defaultString = this.isBroadcastEnabled
          ? "Vídeos da web e Transmissões"
          : "Vídeos da web";
        return localizedString({
          id,
          defaultString,
        });

      case MediaTypeEnum.CONTENT:
        return localizedString({
          id: "Informative@mediaGroupContentSubtitle",
          defaultString: "RSS e Screenshot Web.",
        });

      case MediaTypeEnum.EXTRAS:
      default:
        return localizedString({
          id: "Informative@mediaGroupExtrasSubtitle",
          defaultString: "Notícias, Clima, Loterias e muito mais!",
        });
    }
  };

  getMediaListToGroupType = (groupType) => {
    const {
      uploadsList,
      streamingsList,
      contentsList,
      filteredExtrasList,
    } = this.props.mediaLibraryStore;

    switch (groupType) {
      case MediaTypeEnum.UPLOAD:
        return uploadsList;

      case MediaTypeEnum.STREAMING:
        return streamingsList;

      case MediaTypeEnum.CONTENT:
        return contentsList;

      case MediaTypeEnum.EXTRAS:
      default:
        return filteredExtrasList;
    }
  };

  didChangeMediaItemSelectedQty = (updatedMediaItem) => {
    this.props.mediaLibraryStore.didChangeMediaSelectedQty(updatedMediaItem);
  };

  didWantToDeleteMedia = (mediaItemToDelete) => {
    this.setState({
      modalIsOpen: false,
      selectedMediaItem: null,
      didWantToEditMedia: false,
      alert: (
        <ConfirmModal
          isOpen
          title={<ConfirmActionString />}
          actions={
            <GridContainer>
              <GridItem xs={12}>
                <Button
                  color="default"
                  small
                  block
                  onClick={this.didCancelDeleteAction}
                >
                  <CancelString />
                </Button>
              </GridItem>
              <GridItem xs={12}>
                <Button
                  color="info"
                  small
                  block
                  onClick={() =>
                    this.setState({
                      modalIsOpen: true,
                      selectedMediaItem: mediaItemToDelete,
                      didWantToEditMedia: true,
                      alert: null,
                    })
                  }
                >
                  {this.props.localizedString({
                    id: "Informative@scheduleMediaExclusionButtonLabel",
                    defaultString: "Agendar exclusão",
                  })}{" "}
                  <i className="fas fa-clock" />
                </Button>
              </GridItem>
              <GridItem xs={12}>
                <Button
                  color="danger"
                  block
                  onClick={() => this.didConfirmDeleteAction(mediaItemToDelete)}
                >
                  {this.props.localizedString({
                    id: "Informative@deleteMediaButtonLabel",
                    defaultString: "Apagar agora",
                  })}{" "}
                  <i className="fas fa-trash-alt" />
                </Button>
              </GridItem>
            </GridContainer>
          }
        >
          <p>
            <Translate
              id="Informative@deleteMediaMessage"
              variables={{ mediaName: mediaItemToDelete.name }}
            >
              Você realmente quer apagar <strong>$mediaName</strong>?
            </Translate>
          </p>
          <p>
            {this.props.localizedString({
              id: "Informative@scheduleMediaExclusionMessage",
              defaultString:
                "Se preferir, agende uma data para exclusão automática desta mídia.",
            })}
          </p>
        </ConfirmModal>
      ),
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      alert: null,
    });
  };

  didConfirmDeleteAction = (mediaItemToDelete) => {
    this.setState(
      {
        alert: null,
      },
      () => {
        this.props.mediaLibraryStore.didDeleteMedia(mediaItemToDelete);
      }
    );
  };

  renderMediaGroupPanel = (groupType) => {
    const { expandedContainer } = this.state;
    return (
      <MediaGroupPanel
        isExpanded={expandedContainer ? expandedContainer === groupType : false}
        title={this.getMediaGroupTitle(groupType)}
        subtitle={this.getMediaGroupSubtitle(groupType)}
        panelName={groupType}
        justMediaLibrary={this.props.justMediaLibrary}
        didSelectPanel={this.changeExpandedContainer}
        mediaList={this.getMediaListToGroupType(groupType)}
        onClickAddButton={this.toggleAddNewMedia}
        didChangeMediaItemSelectedQty={this.didChangeMediaItemSelectedQty}
        didSelectMediaItem={this.didSelectMediaItem}
        didWantToDeleteMedia={this.didWantToDeleteMedia}
      />
    );
  };

  didSelectMedias = () => {
    const { mediaLibraryStore } = this.props;
    this.props.didAddNewMediaList(mediaLibraryStore.getSelectedMedias);

    mediaLibraryStore.resetMediasSelectedQty();
  };

  countTotalSelectedMedias = (mediasList) => {
    let count = 0;
    mediasList.forEach((mediaItem) => {
      for (let index = 0; index < mediaItem.selectedQty; index++) {
        count++;
      }
    });
    return count;
  };

  didUploadDuplicatedFiles = (duplicatedFiles) => {
    const { mediaLibraryStore } = this.props;
    let duplicatedFilesNameSearchString = "";

    duplicatedFiles.forEach((file, index) => {
      const mediaItem = mediaLibraryStore.getWithToken(
        MediaTypeEnum.UPLOAD,
        file
      );
      duplicatedFilesNameSearchString += mediaItem.name;

      if (index !== duplicatedFiles.length - 1) {
        duplicatedFilesNameSearchString += ", ";
      }
    });

    mediaLibraryStore.setSearchString(duplicatedFilesNameSearchString);

    this.didCloseModal();
  };

  languageFilterOptions = [
    {
      value: "all",
      label: this.props.localizedString({
        id: "I18n@any",
        defaultString: "Qualquer",
      }),
      icon: "globe",
    },
    {
      value: "en",
      label: this.props.localizedString({
        id: "I18n@english",
        defaultString: "Inglês",
      }),
      flag: usFlag,
    },
    {
      value: "pt",
      label: this.props.localizedString({
        id: "I18n@portuguese",
        defaultString: "Português",
      }),
      flag: brFlag,
    },
  ];

  render() {
    const {
      classes,
      mediaLibraryStore,
      tagsStore,
      localizedString,
      justMediaLibrary,
      contractStore,
    } = this.props;

    const {
      isAddingNewMedia,
      selectedMediaItem,
      didWantToEditMedia,
    } = this.state;

    const {
      searchString,
      selectedTags,
      filteredMediaList,
      getSelectedMedias,
    } = mediaLibraryStore;

    const totalSelectedMedias = this.countTotalSelectedMedias(
      getSelectedMedias
    );

    const {
      dynamicContent,
      complementaryContent,
    } = contractStore.selectedContract.resources;

    return (
      <Fragment>
        {this.state.alert}
        {isAddingNewMedia && (
          <ModalUploadMediaFile
            isOpen={this.state.modalIsOpen}
            didCloseModal={this.didCloseModal}
            indexToOpen={this.state.modalUploadMediaFileIndexToOpen}
            didUploadDuplicatedFiles={this.didUploadDuplicatedFiles}
          />
        )}
        {selectedMediaItem && (
          <ModalMediaDetails
            isOpen={this.state.modalIsOpen}
            didCloseModal={this.didCloseModal}
            isEditing={didWantToEditMedia}
            mediaItem={selectedMediaItem}
            wantToEditTags={this.state.wantToEditTags}
            didWantToDelete={this.didWantToDeleteMedia}
          />
        )}
        {this.props.children({
          body: (
            <Fragment>
              <GridContainer alignItems="flex-end">
                <GridItem xs={12} sm={6}>
                  <SelectTags
                    inputLabel={localizedString({
                      id: "Common@filterByTags",
                      defaultString: "Filtrar por etiquetas",
                    })}
                    tags={selectedTags}
                    options={tagsStore.mediaTags}
                    onChange={mediaLibraryStore.setSelectedTags}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <SearchBar
                    marginBottom
                    value={searchString}
                    onChange={mediaLibraryStore.setSearchString}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.bodyContainer}>
                {searchString.length > 0 || selectedTags.length > 0 ? (
                  <Fragment>
                    <GridItem xs={12}>
                      <Plural
                        id="Informative@mediasSearchResult"
                        value={filteredMediaList.length}
                        zero={
                          <span>
                            <strong>Nenhuma mídia encontrada</strong> com a
                            pesquisa atual.
                          </span>
                        }
                        one={
                          <span>
                            <strong># mídia encontrada</strong> com a pesquisa
                            atual
                          </span>
                        }
                        other={
                          <span>
                            <strong># mídias encontradas</strong> com a pesquisa
                            atual
                          </span>
                        }
                      />
                    </GridItem>
                    <Paginate itemsList={filteredMediaList} itemsPerPage={14}>
                      {({ slicedList }) => (
                        <Fragment>
                          <MediaGridAddButton
                            onClick={this.toggleAddNewMedia(
                              MediaTypeEnum.UPLOAD
                            )}
                          />
                          {slicedList.map((mediaItem) => (
                            <MediaGridItem
                              key={mediaItem.token}
                              mediaItem={mediaItem}
                              didChangeSelectedQty={
                                this.didChangeMediaItemSelectedQty
                              }
                              justMediaLibrary={justMediaLibrary}
                              didSelect={this.didSelectMediaItem}
                              didWantToDelete={this.didWantToDeleteMedia}
                            />
                          ))}
                        </Fragment>
                      )}
                    </Paginate>
                  </Fragment>
                ) : (
                  <GridItem xs>
                    {this.renderMediaGroupPanel(MediaTypeEnum.UPLOAD)}
                    {this.renderMediaGroupPanel(MediaTypeEnum.STREAMING)}
                    {dynamicContent &&
                      this.renderMediaGroupPanel(MediaTypeEnum.CONTENT)}
                    {!justMediaLibrary &&
                      complementaryContent &&
                      this.renderMediaGroupPanel(MediaTypeEnum.EXTRAS)}
                  </GridItem>
                )}
              </GridContainer>
            </Fragment>
          ),
          action: !justMediaLibrary && (
            <Button
              color="primary"
              block
              onClick={this.didSelectMedias}
              disabled={totalSelectedMedias < 1}
            >
              <Plural
                id="Informative@selectMediasButtonlabel"
                value={totalSelectedMedias}
                zero="Selecione mídias"
                one="# mídia selecionada"
                other="# mídias selecionadas"
              />
            </Button>
          ),
        })}
      </Fragment>
    );
  }
}

export default withStyles(styles)(SelectMediaContainer);
