// @ts-nocheck

import { observable, reaction, computed, action } from "mobx";
import sumBy from "lodash/sumBy";

// Utils Stores
import {
  uploadImageFile,
  deleteStorageFile,
  connectedRef,
  rootRef,
  chatRef,
  chatMessagesRef,
  userChatsListRef,
  usersRef,
  serverTimestamp,
} from "../FirebaseStore/FirebaseStore";
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";
import { FEEDBACK_SOUND } from "../SoundStore/SoundStore";

import predefinedMessage, {
  PREDEFINED_MESSAGES_KEYS,
} from "./PredefinedMessages";

// Utils
import { deviceInfo } from "../../utils/DeviceUtils";
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

export const didChangeUserToken = (oldUserToken, newUserToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updates = {};

      const usersSnapshot = await usersRef.child(oldUserToken).once("value");
      if (usersSnapshot.exists()) {
        const userChatsListSnapshot = await userChatsListRef
          .child(oldUserToken)
          .once("value");
        if (userChatsListSnapshot.exists()) {
          userChatsListSnapshot.forEach((chatKeySnapshot) => {
            const isClosed = chatKeySnapshot.val()
              ? chatKeySnapshot.val().isClosed || false
              : false;
            // update userId on chats
            updates[`/${chatKeySnapshot.key}/userId`] = newUserToken;
            updates[
              `/${chatKeySnapshot.key}/userId_isClosed`
            ] = `${newUserToken}_${isClosed}`;
          });

          // set join list for new token
          await userChatsListRef
            .child(newUserToken)
            .set(userChatsListSnapshot.val());
          // remove join list of old token
          await userChatsListRef.child(oldUserToken).remove();
        }

        // set user node with new token
        await usersRef.child(newUserToken).set(usersSnapshot.val());
        // remove old user node
        await usersRef.child(oldUserToken).remove();
      }

      // Apply updates
      await chatRef.update(updates);
      resolve();
    } catch (error) {
      window.debugError("didChangeUserToken error", error);
      reject(error);
    }
  });
};

export const MESSAGE_TYPE = Object.freeze({
  TEXT: "text",
  IMAGE: "image",
  AUDIO: "audio",
  VIDEO: "video",
  QUESTION: "question",
});

export const CHAT_VIEW = Object.freeze({
  ADMIN: "admin",
  USER: "user",
  UNDEFINED: "undefined",
});

export enum QUESTION_MESSAGE_ID {
  didRequestAccessToContract = "didRequestAccessToContractMessage",
}

class ChatStore {
  @observable
  isConnectedToFirebase = false;

  @observable
  view = CHAT_VIEW.UNDEFINED;

  @observable isAttachmentModalOpen = false;
  @observable isUploadingAttachment = false;

  @observable message = "";

  @observable
  newChatTitle = "";

  @observable adminChatsList = [];

  @observable userChats = [];

  @observable
  searchString = "";

  @observable
  selectedChatMessagesList = [];

  @observable
  selectedMessageId = null;

  lastUnreadMessagesCount = null;

  adminChatsListRef = null;
  userChatsRef = null;
  selectedChatRef = null;
  selectedChatMessagesRef = null;
  onDisconnectRef = null;

  constructor(
    authStore,
    contractStore,
    internalEventsStore,
    i18nStore,
    routingStore,
    firebaseAuthStore,
    soundStore
  ) {
    this.authStore = authStore;
    this.contractStore = contractStore;
    this.i18nStore = i18nStore;
    this.routingStore = routingStore;
    this.firebaseAuthStore = firebaseAuthStore;
    this.soundStore = soundStore;
    this.internalEventsStore = internalEventsStore;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.didChangeSelectedContract,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.triggeredAction,
      observer: this,
      callback: (params: any) => {
        if (!params) return;

        if (params?.includes("chat_")) {
          const chatAction = params.replace("chat_", "");
          this.didUseChatAction(chatAction);
        }
      },
    });

    this.setConnectedRef();
    this.checkDependenciesStoresAreReady();
    this.listenToSelectedChat();
    this.listenSelectedUserForSupport();

    reaction(
      () => this.selectedMessageForEditing,
      (_selectedMessage) => {
        if (_selectedMessage) {
          const { type, message } = _selectedMessage;
          this.onChangeMessage(message);
          if (type === MESSAGE_TYPE.TEXT) {
            //
          } else if (type === MESSAGE_TYPE.IMAGE) {
            this.didWantToAddAttachment();
          }
        } else {
          this.onChangeMessage("");
          this.didCancelAddingAttachment();
        }
      }
    );
  }

  setRouteParams = ({ userId, chatId }) => {
    this.routingStore.pushObjectToSearchQuery({
      chatuid: userId,
      chatid: chatId,
    });
  };

  @action setSearchString = (newValue) => (this.searchString = newValue);

  @action setUserChats = (newValue) => (this.userChats = newValue);

  @action setView = (newValue) => (this.view = newValue);

  @action setIsConnectedToFirebase = (newValue) =>
    (this.isConnectedToFirebase = newValue);
  @action setIsAttachmentModalOpen = (newValue) =>
    (this.isAttachmentModalOpen = newValue);
  @action setIsUploadingAttachment = (newValue) =>
    (this.isUploadingAttachment = newValue);

  @action setAdminChatsList = (newValue) => (this.adminChatsList = newValue);

  @action setSelectedChatMessagesList = (newValue) =>
    (this.selectedChatMessagesList = newValue);

  @action setSelectedMessageId = (newValue) =>
    (this.selectedMessageId = newValue);

  @action cancelEditingMessage = () => {
    this.selectedMessageId = null;
  };

  @action
  onChangeMessage = (newValue) => {
    this.message = newValue;

    this.setIsTypingStatus(newValue.length > 0);
  };

  @action
  onChangeNewChatTitle = (newTitle: string) => {
    this.newChatTitle = newTitle;
  };

  @computed
  get chatDataFromUrl() {
    const { chatuid: userId, chatid: chatId } = this.routingStore.searchQuery;

    return {
      chatId,
      userId,
    };
  }

  @computed
  get selectedChatMessagesListLastMessage() {
    if (this.selectedChatMessagesList.length > 0) {
      return this.selectedChatMessagesList.slice(-1)[0];
    }
    return null;
  }

  @computed
  get selectedMessageForEditing() {
    if (this.selectedMessageId) {
      return this.selectedChatMessagesList.find(
        ({ id }) => id === this.selectedMessageId
      );
    }
    return null;
  }

  @computed
  get isEditingMessageOfImageType() {
    return this.selectedMessageForEditing
      ? this.selectedMessageForEditing.type === MESSAGE_TYPE.IMAGE
      : false;
  }

  @computed
  get currentConnectedUserId() {
    return this.firebaseAuthStore.firebaseUserId;
  }

  setConnectedRef = () => {
    connectedRef.on("value", (connectedSnap) =>
      this.setIsConnectedToFirebase(connectedSnap.val() === true)
    );
  };

  @computed
  get isReady() {
    return this.isConnectedToFirebase && this.firebaseAuthStore.firebaseUser;
  }
  @computed
  get isAvailable() {
    return this.isConnectedToFirebase && this.view !== CHAT_VIEW.UNDEFINED;
  }

  @computed
  get isAddingNewChat() {
    return this.selectedChatId === "new";
  }

  cancelAddingNewChat = () => {
    this.setRouteParams({ userId: this.chatDataFromUrl.userId });
  };

  didWantToStartNewChatWithSubjectAndMessage = async (subject, message) => {
    this.onChangeNewChatTitle(subject);
    this.onChangeMessage(message);
    this.didSelectChat("new");
  };

  isUserMessage = ({ author }) => author === CHAT_VIEW.USER;
  isAdminMessage = ({ author }) => author === CHAT_VIEW.ADMIN;

  checkDependenciesStoresAreReady = () => {
    if (this.authStore && this.firebaseAuthStore) {
      this.setChatViewListener();
    } else {
      setTimeout(() => {
        this.checkDependenciesStoresAreReady();
      }, 50);
    }
  };

  setChatViewListener = () => {
    reaction(
      () => this.isReady,
      (_isReady) => {
        if (_isReady) {
          // is modbox login
          if (this.authStore.isAuthenticated) {
            const view = this.authStore.currentUserCanSupport
              ? CHAT_VIEW.ADMIN
              : CHAT_VIEW.USER;
            this.setView(view);
          } else {
            // is anonymous login
            this.setView(CHAT_VIEW.USER);
          }

          this.requestChatsList();
        } else {
          this.clearStore();
        }
      }
    );
  };

  requestChatsList = () => {
    if (this.currentAuthor === CHAT_VIEW.ADMIN) {
      this.requestAdminChatsList();
    } else if (this.currentAuthor === CHAT_VIEW.USER) {
      this.requestUserChatsList(this.currentConnectedUserId);
    }
  };

  didSetChatClosed = async () => {
    if (this.selectedChatId) {
      await chatRef.child(this.selectedChatId).update({
        isClosed: true,
        userId_isClosed: `${this.selectedChat.userId}_true`,
      });
    }
    this.closeChat();
  };

  didWantToReopenChat = async (chatId: string) => {
    chatRef.child(chatId).update({
      isClosed: false,
      userId_isClosed: `${this.currentConnectedUserId}_false`,
    });
  };

  didSelectChat = (chatId: string) => {
    this.setRouteParams({ userId: this.chatDataFromUrl.userId, chatId });
  };

  openChatDrawerToUser = () => {
    this.setRouteParams({ userId: "self" });
  };

  closeChat = () => {
    this.setRouteParams({});
  };

  didSelectUser = (userId: string) => {
    this.setRouteParams({ userId });
  };

  @computed
  get shouldOpenChatDrawer() {
    return this.selectedUserId !== null;
  }

  @computed
  get selectedChatId() {
    return this.chatDataFromUrl.chatId;
  }
  @computed
  get selectedUserId() {
    const uid = this.chatDataFromUrl.userId;
    if (uid) {
      return uid === "self" ? this.firebaseAuthStore.firebaseUserId : uid;
    }
    return null;
  }

  @computed
  get selectedChat() {
    if (!this.selectedChatId || this.selectedChatId === "new") return null;

    const findChat = ({ id }) => id === this.selectedChatId;

    if (this.currentAuthor === CHAT_VIEW.ADMIN) {
      return this.adminChatsList.find(findChat) || null;
    } else if (this.currentAuthor === CHAT_VIEW.USER) {
      return this.userChats.find(findChat) || null;
    }
    return null;
  }

  @computed
  get selectedChatSubject() {
    if (!this.selectedChat) return "";
    return this.selectedChat.subject ? this.selectedChat.subject : "";
  }

  localizedString = (data, variables) =>
    this.i18nStore.localizedString(data, variables);

  removeSelectedChat = () => {
    this.setRouteParams({ userId: this.chatDataFromUrl.userId });

    if (this.selectedChatRef) {
      this.setAllCurrentAuthorChatStatus(false);
      this.selectedChatRef = null;
    }

    this.setSelectedChatMessagesList([]);
  };

  prepareChatObjectWithData = async (chatData) => {
    if (chatData?.userId || false) {
      try {
        const userSnapshot = await usersRef
          .child(chatData.userId)
          .once("value");
        const firebaseUser = userSnapshot.exists() ? userSnapshot.val() : null;

        return {
          lastMessage: null,
          lastMessageTimestamp: null,
          ...chatData,
          user: {
            name: this.localizedString({
              id: "Chat@anonymous",
              defaultString: "Anônimo",
            }),
            email: "",
            currentUrl: "",
            id: userSnapshot.exists() ? userSnapshot.key : null,
            ...firebaseUser,
            ...chatData.user,
          },
        };
      } catch (error) {
        window.debugError(
          "Error in prepareChatObjectWithData",
          chatData,
          error
        );
      }
    }
    return null;
  };

  listenToSelectedChat = () => {
    reaction(
      () => {
        if (
          this.isReady &&
          this.selectedChatId &&
          this.selectedChatId !== "new"
        ) {
          return this.selectedChatId;
        }
        return null;
      },
      async (_selectedChatId) => {
        if (_selectedChatId) {
          this.selectedChatRef = chatRef.child(_selectedChatId);

          this.selectedChatMessagesRef = chatMessagesRef.child(_selectedChatId);

          this.selectedChatMessagesRef.on("value", (messagesSnapshot) => {
            let messagesList = [];
            if (messagesSnapshot.val()) {
              messagesSnapshot.forEach((messageSnapshot) => {
                messagesList.push({
                  id: messageSnapshot.key,
                  ...messageSnapshot.val(),
                });
              });
            }
            this.setSelectedChatMessagesList(messagesList);
          });

          this.onDisconnectRef = chatRef.child(
            `${_selectedChatId}/${this.currentAuthor}`
          );

          await this.onDisconnectRef.onDisconnect().update({
            isConnected: false,
            isReading: false,
            isTyping: false,
          });
          await this.onDisconnectRef.update({
            isConnected: true,
            isReading: true,
          });
        } else {
          if (this.selectedChatRef) {
            this.selectedChatRef.off();
            this.selectedChatRef = null;
          }
          if (this.selectedChatMessagesRef) {
            this.selectedChatMessagesRef.off();
            this.selectedChatMessagesRef = null;
          }
          if (this.selectedMessageForEditing) {
            this.setSelectedMessageId(null);
          }
        }
      },
      { name: "listenToSelectedChat", fireImmediately: true }
    );
  };

  listenSelectedUserForSupport = () => {
    reaction(
      () => (this.isReady ? this.selectedUserId : null),
      (_selectedUserId) => {
        if (!_selectedUserId) {
          if (this.userChatsRef) {
            this.userChatsRef.off();
            this.userChatsRef = null;
          }

          this.setUserChats([]);
        } else {
          this.requestUserChatsList(_selectedUserId);
        }
      },
      { name: "listenSelectedUserForSupport", fireImmediately: true }
    );
  };

  getChatsListFromSnapshot = async (snapshot) => {
    let resultList = [];
    if (snapshot.val()) {
      await Promise.all(
        Object.keys(snapshot.val()).map(async (key) => {
          const chatData = snapshot.val()[key];
          if (chatData) {
            const chat = await this.prepareChatObjectWithData({
              id: key,
              ...chatData,
            });

            // Add to the list only if have any message
            if (chat && chat.lastMessage) {
              resultList.push(chat);
            }
          }
        })
      );
    }

    return resultList.sort(
      dynamicSortByProperty("lastMessageTimestamp", false)
    );
  };

  requestAdminChatsList = () => {
    if (this.adminChatsListRef) {
      this.adminChatsListRef.off();
      this.adminChatsListRef = null;
      this.setAdminChatsList([]);
    }
    this.adminChatsListRef = chatRef;
    this.adminChatsListRef.on("value", async (snapshot) => {
      const list = await this.getChatsListFromSnapshot(snapshot);
      if (!document.hasFocus()) {
        const messagesCount = sumBy(list, "messagesCount");
        if (!this.lastUnreadMessagesCount) {
          this.lastUnreadMessagesCount = messagesCount;
        } else if (this.lastUnreadMessagesCount < messagesCount) {
          this.lastUnreadMessagesCount = messagesCount;
          this.soundStore.playFeedbackSound(FEEDBACK_SOUND.NewMessage);
        }
      }

      this.setAdminChatsList(list);
    });
  };

  requestUserChatsList = (userId) => {
    if (this.userChatsRef) {
      this.userChatsRef.off();
      this.userChatsRef = null;
      this.setUserChats([]);
    }
    this.userChatsRef = chatRef.orderByChild("userId").equalTo(userId);

    this.userChatsRef.on("value", async (snapshot) => {
      const chatsList = await this.getChatsListFromSnapshot(snapshot);
      this.setUserChats(chatsList);
    });
  };

  createNewChat = async ({ userId, shouldSelectChat }) => {
    const newChatRef = await chatRef.push({
      subject: this.newChatTitle,
      userId,
      createdAt: serverTimestamp,
      isClosed: false,
      userId_isClosed: `${userId}_false`,
      messagesCount: 0,
      admin: {
        isConnected: false,
        isReading: false,
        isTyping: false,
        unreadMessagesCount: 0,
      },
      user: {
        isConnected: false,
        isReading: false,
        isTyping: false,
        unreadMessagesCount: 0,
      },
    });

    if (newChatRef) {
      const chatId = newChatRef.key;
      userChatsListRef
        .child(`${this.currentConnectedUserId}/${chatId}`)
        .set(true);

      if (shouldSelectChat) {
        this.didSelectChat(chatId);
      }

      return chatId;
    } else {
      return null;
    }
  };

  onChangeSubject = (subject) => {
    if (this.selectedChatRef) {
      this.selectedChatRef.child("subject").set(subject);
    }
  };

  addWelcomeMessage = () => {
    this.addAdminTextMessage(
      this.localizedString({
        id: "Chat@welcomeMessage",
        defaultString: "Olá! Como podemos te ajudar?",
      })
    );
  };

  @computed
  get isAdminView() {
    return this.view === CHAT_VIEW.ADMIN;
  }

  @computed
  get filteredUsersListForSupport() {
    const usersList = {};

    this.adminChatsList.forEach((chat) => {
      const userId = chat.user.id;
      if (!usersList[userId]) {
        usersList[userId] = {
          ...chat.user,
          unreadMessagesCount: 0,
          chatsList: [],
        };
      }
      usersList[userId].chatsList.push(chat);
      usersList[userId].unreadMessagesCount += chat.admin.unreadMessagesCount
        ? chat.admin.unreadMessagesCount
        : 0;
    });

    if (this.searchString.length > 0)
      return didSearchList(
        this.searchString,
        Object.values(usersList),
        (user, textIncludesSearchString) => {
          const matchName = textIncludesSearchString(user.name);
          const matchEmail = textIncludesSearchString(user.email);

          return matchName || matchEmail;
        }
      );

    return Object.values(usersList);
  }

  @computed
  get currentAuthor() {
    if (this.view === CHAT_VIEW.UNDEFINED) return null;
    return this.view;
  }

  getStatusUpdateValue = (status, newValue) => {
    const update = {};
    if (this.currentAuthor) {
      update[`/${this.currentAuthor}/${status}`] = newValue;
    }
    return update;
  };

  setIsConnectedAndIsReadingStatus = (newValue) => {
    return this.applyUpdatesToSelectedChat({
      ...this.getStatusUpdateValue("isConnected", newValue),
      ...this.getStatusUpdateValue("isReading", newValue),
    });
  };

  setAllCurrentAuthorChatStatus = (newValue) => {
    return this.applyUpdatesToSelectedChat({
      ...this.getStatusUpdateValue("isConnected", newValue),
      ...this.getStatusUpdateValue("isReading", newValue),
      ...this.getStatusUpdateValue("isTyping", newValue),
    });
  };

  setIsConnectedStatus = (newValue) => {
    return this.applyUpdatesToSelectedChat(
      this.getStatusUpdateValue("isConnected", newValue)
    );
  };

  setIsReadingStatus = (newValue) => {
    return this.applyUpdatesToSelectedChat(
      this.getStatusUpdateValue("isReading", newValue)
    );
  };

  setIsTypingStatus = (newValue) => {
    return this.applyUpdatesToSelectedChat(
      this.getStatusUpdateValue("isTyping", newValue)
    );
  };

  applyUpdatesToSelectedChat = (updates) => {
    if (this.selectedChatRef) {
      return this.selectedChatRef.update(updates);
    }
  };

  @computed
  get oppositeAuthor() {
    return this.currentAuthor === CHAT_VIEW.ADMIN
      ? CHAT_VIEW.USER
      : CHAT_VIEW.ADMIN;
  }

  @computed
  get unreadMessagesCount() {
    if (!this.currentAuthor) return 0;

    const chatsList = this.isAdminView ? this.adminChatsList : this.userChats;

    let count = 0;
    chatsList.forEach(($0) => {
      if ($0[this.currentAuthor].unreadMessagesCount)
        count += $0[this.currentAuthor].unreadMessagesCount;
    });

    return count;
  }

  @computed
  get isTyping() {
    if (!this.currentAuthor) return false;
    return this.selectedChat[this.oppositeAuthor].isTyping;
  }

  didReadMessages = () => {
    if (this.selectedChatRef) {
      this.selectedChatRef
        .child(`${this.currentAuthor}/unreadMessagesCount`)
        .set(0);
    }
  };

  addAdminTextMessage = (message) => {
    this.addTextMessage({
      author: CHAT_VIEW.ADMIN,
      message,
    });
  };

  addUserTextMessage = (message) => {
    this.addTextMessage({
      author: CHAT_VIEW.USER,
      message,
    });
  };

  didWantToAddAttachment = () => {
    this.setIsAttachmentModalOpen(true);
  };

  didCancelAddingAttachment = () => {
    this.setIsAttachmentModalOpen(false);
    this.setIsUploadingAttachment(false);

    if (this.selectedMessageId) {
      this.setSelectedMessageId(null);
    }
  };

  didWantToUploadImage = ({ base64, fileExtension }, callback) => {
    const newMessageRef = this.selectedMessageForEditing
      ? this.selectedChatMessagesRef.child(this.selectedMessageForEditing.id)
      : this.selectedChatMessagesRef.push();

    uploadImageFile(
      {
        fullPath: `chats/${this.selectedChatId}/${newMessageRef.key}.${fileExtension}`,
        fileExtension,
        base64,
        ref: newMessageRef,
      },
      callback
    );
  };

  didUploadImage = ({ isEditing, downloadUrl, fileExtension, ref }) => {
    if (isEditing) {
      const { id, type, ...rest } = this.selectedMessageForEditing;
      this.updateMessage({
        ...rest,
        id,
        type,
        message: this.message,
        imageUrl: downloadUrl,
      });
    } else {
      this.addImageMessage({
        ref,
        message: this.message,
        imageUrl: downloadUrl,
        fileExtension,
        author: this.currentAuthor,
      });
    }

    this.onChangeNewChatTitle("");
    this.onChangeMessage("");
    this.didCancelAddingAttachment();
  };

  didUpdateChatMessage = () => {
    const { id, type, ...rest } = this.selectedMessageForEditing;

    this.updateMessage({
      ...rest,
      id,
      type,
      message: this.message,
    });
  };

  didDeleteChatMessage = async ({ id, type, ...rest }) => {
    try {
      if (type === MESSAGE_TYPE.IMAGE) {
        await deleteStorageFile(
          `chats/${this.selectedChatId}`,
          `${id}.${rest.fileExtension}`
        );
      }
      await this.selectedChatMessagesRef.child(id).remove();
    } catch (error) {
      window.debugError("error in didDeleteChatMessage", error);
    }
  };

  addTextMessageForCurrentAuthor = () => {
    if (this.currentAuthor === CHAT_VIEW.ADMIN) {
      this.addAdminTextMessage(this.message);
    } else if (this.currentAuthor === CHAT_VIEW.USER) {
      this.addUserTextMessage(this.message);
    }

    this.onChangeNewChatTitle("");
    this.onChangeMessage("");
  };

  checkIfAuthorIsConnectedAndReading = (author) => {
    if (this.selectedChat) {
      const isConnected = this.selectedChat[author].isConnected || false;
      const isReading = this.selectedChat[author].isReading || false;
      return isConnected && isReading;
    }
    return false;
  };

  isValidToSubmit = ({ author, message, imageUrl, fileExtension, type }) => {
    if (!author) return false;
    if (type === MESSAGE_TYPE.TEXT) {
      return message.trim().length > 0;
    }
    if (type === MESSAGE_TYPE.IMAGE) {
      return (
        imageUrl &&
        imageUrl.length > 0 &&
        fileExtension &&
        fileExtension.length > 0
      );
    }
    if (type === MESSAGE_TYPE.QUESTION) {
      return message.trim().length > 0;
    }
    return false;
  };

  addMessage = async ({
    ref,
    message,
    imageUrl,
    fileExtension,
    type,
    author,
    customChatId,
    customData,
  }) => {
    if (
      !this.isValidToSubmit({ author, message, imageUrl, fileExtension, type })
    ) {
      return Promise.reject();
    }

    const isImageType = type === MESSAGE_TYPE.IMAGE;
    const chatId = customChatId ? customChatId : this.selectedChatId;
    const newMessageRef = ref ? ref : this.selectedChatMessagesRef.push();

    if (!chatId || !newMessageRef) {
      return;
    }

    // Feedback Sound
    this.soundStore.playFeedbackSound(FEEDBACK_SOUND.SendChatMessage, false);

    let updates = {};

    // Set typing false
    updates[`/chat/${chatId}/${this.currentAuthor}/isTyping`] = false;

    // Set new Message
    updates[`/chatMessages/${chatId}/${newMessageRef.key}`] = {
      author,
      adminName: author === "admin" ? this.authStore.currentUserName : null,
      message: message.trim(),
      imageUrl: isImageType ? imageUrl : null,
      fileExtension: isImageType ? fileExtension : null,
      timestamp: serverTimestamp,
      type,
      isRead: this.checkIfAuthorIsConnectedAndReading(this.oppositeAuthor),
      customData: customData ?? null,
    };

    // Set chat last message
    updates[`/chat/${chatId}/lastMessage`] = isImageType
      ? this.localizedString({
          id: "Common@image",
          defaultString: "Imagem",
        })
      : message;
    updates[`/chat/${chatId}/lastMessageAuthor`] = author;
    updates[`/chat/${chatId}/lastMessageTimestamp`] = serverTimestamp;

    if (author === CHAT_VIEW.USER) {
      updates = {
        ...updates,
        ...this.updateUserInformations(),
      };
    }

    rootRef.update(updates);
  };

  updateMessage = async ({
    id,
    message,
    imageUrl,
    fileExtension,
    type,
    author,
  }) => {
    if (
      !this.isValidToSubmit({ author, message, imageUrl, fileExtension, type })
    ) {
      return false;
    }

    try {
      this.setSelectedMessageId(null);
      await chatMessagesRef
        .child(this.selectedChatId)
        .child(id)
        .update({
          message: message.trim(),
          imageUrl: type === MESSAGE_TYPE.IMAGE ? imageUrl : null,
          fileExtension: type === MESSAGE_TYPE.IMAGE ? fileExtension : null,
          updatedAt: serverTimestamp,
        });
      return true;
    } catch (error) {
      window.debugError("error in updateMessage", error);
      return false;
    }
  };

  addTextMessage = ({ message, author, ...rest }) => {
    const sanitizedMessage = message.trim();
    if (sanitizedMessage.length > 0) {
      return this.addMessage({
        ...rest,
        message,
        author,
        type: MESSAGE_TYPE.TEXT,
      });
    }
    return Promise.resolve();
  };

  addImageMessage = ({ ref, ...rest }) => {
    return this.addMessage({
      ...rest,
      ref,
      type: MESSAGE_TYPE.IMAGE,
    });
  };

  addQuestionMessage = ({ ref, ...rest }) => {
    return this.addMessage({
      ...rest,
      ref,
      type: MESSAGE_TYPE.QUESTION,
    });
  };

  updateUserInformations = () => {
    let updates = {};
    updates[`/users/${this.currentConnectedUserId}/deviceInfo`] = deviceInfo();

    if (this.authStore.isAuthenticated) {
      const { token, name, email } = this.authStore.currentUser;
      updates[`/users/${token}/name`] = name;
      updates[`/users/${token}/email`] = email;
    }

    return updates;
  };

  didChangeUserCurrentUrl = (currentUrl) => {
    if (currentUrl.includes("logout")) return false;

    // only do this for users
    if (this.isAdminView) return false;

    if (this.selectedChat && this.currentConnectedUserId) {
      usersRef
        .child(`${this.currentConnectedUserId}/currentUrl`)
        .set(currentUrl);
    }
  };

  didChangeSelectedContract = () => {
    // only do this for users
    if (this.isAdminView) return false;
    else if (
      this.contractStore.selectedContract &&
      this.currentConnectedUserId
    ) {
      const contractData = this.contractStore.selectedContract.exportToFirebase();
      usersRef
        .child(`${this.currentConnectedUserId}/currentContract`)
        .set(contractData);
    }
  };

  clearRefs = () => {
    if (this.adminChatsListRef) {
      this.adminChatsListRef.off();
      this.adminChatsListRef = null;
    }

    if (this.userChatsRef) {
      this.userChatsRef.off();
      this.userChatsRef = null;
    }

    if (this.selectedChatRef) {
      this.selectedChatRef.off();
      this.selectedChatRef = null;
    }

    if (this.onDisconnectRef) {
      this.onDisconnectRef.off();
      this.onDisconnectRef = null;
    }
  };

  didUseChatAction = (action: string) => {
    if (this.authStore.currentUserCanSupport) {
      return;
    }

    let messageKey = "";
    let subject = "";

    if (action === "requestLicences") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestLicences;
      subject = this.localizedString({
        id: "ContractLimit@requestLicencesSubject",
        defaultString: "Aumentar licenças",
      });
    } else if (action === "requestStorage") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestStorage;
      subject = this.localizedString({
        id: "ContractLimit@requestStorageSubject",
        defaultString: "Aumentar espaço em disco",
      });
    } else if (action === "requestWifiAuthentication") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestWifiAuthentication;
      subject = this.localizedString({
        id: "ContractLimit@requestWifiAuthSubject",
        defaultString: "Aumentar autenticações Wi-Fi",
      });
    } else if (action === "requestSmsCredits") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestSmsCredits;
      subject = this.localizedString({
        id: "ContractLimit@requestSmsCreditsSubject",
        defaultString: "Aumentar créditos SMS",
      });
    } else if (action === "requestCameraFacesCredits") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestCameraFacesCredits;
      subject = this.localizedString({
        id: "ContractLimit@requestCameraFacesCreditsSubject",
        defaultString: "Aumentar capturas de ambiente",
      });
    }

    this.didWantToStartNewChatWithSubjectAndMessage(
      subject,
      predefinedMessage(messageKey, this.localizedString)
    );
  };

  didRequestAccessToContract = () => {
    if (this.authStore.currentUserIsSupportOperator) {
      this.addQuestionMessage({
        message: this.localizedString(
          {
            id: "Chat@didRequestAccessToContractMessage",
            defaultString:
              "$name está solicitando acesso ao seu contrato. Ao aceitar você concederá à ele acesso completo e temporário para ajuda-lo a resolver o problema em questão.",
          },
          {
            name: this.authStore.currentUserName,
          }
        ),
        author: this.currentAuthor,
        customData: {
          didAnswer: false,
          questionId: QUESTION_MESSAGE_ID.didRequestAccessToContract,
          didConfirmAction: `api_grantTemporaryAccessToContract_${this.authStore.currentUserToken}`,
        },
      });
    }
  };

  didConfirmQuestion = (id: string, action: string) => {
    this.internalEventsStore.notify(
      EInternalEvents.triggeredAction,
      action,
      async (response: boolean) => {
        const didSuccess = await response;
        if (didSuccess) {
          await this.selectedChatMessagesRef
            .child(id)
            .child("customData")
            .update({
              didAnswer: true,
              answer: true,
            });
        }
      }
    );
  };

  didCancelQuestion = async (id: string) => {
    try {
      await this.selectedChatMessagesRef
        .child(id)
        .child("customData")
        .update({
          didAnswer: true,
          answer: false,
        });
    } catch (error) {
      window.debugError("error in didCancelQuestion", error);
    }
  };

  @action
  clearStore = () => {
    this.clearRefs();

    this.userChats = [];
    this.adminChatsList = [];
    this.selectedChatMessagesList = [];
    this.searchString = "";
    this.view = CHAT_VIEW.UNDEFINED;
    this.selectedMessageId = null;
  };
}

export default ChatStore;
