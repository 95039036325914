// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";
import { v4 as uuid } from "uuid";

// Components
import { InputText, InputColorPicker } from "../Form";
import { Button } from "../Button";
import { GridContainer, GridItem } from "../Grid";
import { withLocalized } from "../HOC";
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";

import Validate, { ValidationType } from "../Form/Validate";

// Translate
import Translate from "../I18n/Translate";
import {
  UpdateString,
  SaveString,
  CancelString,
  DeleteString,
} from "../I18n/CommonStrings";

// Utils
import { tagColors } from "../../utils/ColorUtils";
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";

// Utils Stores
import { EInternalEvents } from "../../store/InternalEventsStore/InternalEventsStore";

// Icon
import { SaveIcon, DeleteIcon } from "../Icon";

@withLocalized
@inject("tagsStore", "internalEventsStore", "notificationsStore")
class TagForm extends Component {
  constructor(props) {
    super(props);

    if (props.tag) {
      this.state = this.getStateFromExistingTag(props.tag);
    } else {
      this.state = this.getDefaultState();
    }
  }

  getDefaultState = () => {
    return {
      label: "",
      color: tagColors[0],
      formWasSubmit: false,
      isWaitingForm: false,
      didWantToDelete: false,
      isValid: {
        label: false,
        color: true,
      },
    };
  };

  getStateFromExistingTag = (tag) => {
    return {
      ...this.getDefaultState(),
      label: tag.label,
      color: tag.color,
      isValid: {
        label: Validate(tag.label, ValidationType.REQUIRED),
        color: Validate(tag.color, ValidationType.REQUIRED),
      },
    };
  };

  onChangeLabel = ({ target }) => {
    const { value } = target;

    this.setState((prevState) => ({
      label: value,
      isValid: {
        ...prevState.isValid,
        label: Validate(value, ValidationType.REQUIRED),
      },
    }));
  };

  onChangeInputColorPicker = ({ color }) => {
    this.setState((prevState) => ({
      color,
      isValid: {
        ...prevState.isValid,
        key: Validate(color, ValidationType.REQUIRED),
      },
    }));
  };

  canSubmitForm = () => {
    const { isValid } = this.state;
    return isValid.label && isValid.color;
  };

  getFormDataToSubmit = ({ label, color }) => ({
    label,
    color,
  });

  submitForm = () => {
    const data = this.getFormDataToSubmit(this.state);

    let mediaPreferences = this.props.tagsStore.mediaPreferences;
    let newTag = null;
    if (this.props.tag) {
      mediaPreferences = {
        ...mediaPreferences,
        tags: mediaPreferences.tags
          .map(($0) =>
            $0.token === this.props.tag.token
              ? {
                  ...$0,
                  ...data,
                }
              : $0
          )
          .sort(dynamicSortByProperty("label")),
      };
    } else {
      newTag = {
        ...data,
        token: uuid(),
      };
      mediaPreferences = {
        ...mediaPreferences,
        tags: [...mediaPreferences.tags, newTag].sort(
          dynamicSortByProperty("label")
        ),
      };
    }

    this.updateMediaPreferences(mediaPreferences, newTag);
  };

  updateMediaPreferences = (mediaPreferences, newTag) => {
    this.props.tagsStore.updateMediaPreferences(mediaPreferences);
    this.submitHasSuccess(newTag);
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  didWantToDelete = () => {
    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    const tokenToDelete = this.props.tag.token;
    const { mediaPreferences } = this.props.tagsStore;
    const tags = mediaPreferences.tags.filter(
      ({ token }) => token !== tokenToDelete
    );
    const tagTokens = tags.map(({ token }) => token);
    let taggedMedia = {};

    Object.entries(mediaPreferences.taggedMedia).forEach(([key, values]) => {
      taggedMedia[key] = values.filter(($0) => tagTokens.includes($0));
    });

    const newPreferences = {
      ...mediaPreferences,
      tags,
      taggedMedia,
    };

    this.props.internalEventsStore.notify(
      EInternalEvents.mediaTagWasDeleted,
      tokenToDelete
    );

    this.updateMediaPreferences(newPreferences);
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.canSubmitForm()) {
      this.setState(
        { isWaitingForm: true, formHasError: false },
        this.submitForm
      );
    } else {
      this.setState(
        {
          formWasSubmit: true,
          formHasError: true,
        },
        this.props.notificationsStore.addFormHasErrorNotification()
      );
    }
  };

  submitHasSuccess = (newTag) => {
    this.props.didSave(newTag);
  };

  render() {
    const isEditing = this.props.tag;
    const {
      label,
      color,
      isWaitingForm,
      formWasSubmit,
      didWantToDelete,
      isValid,
    } = this.state;

    return (
      <Fragment>
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteConfiguration}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <InputText
              label={<Translate id="Common@name">Nome</Translate>}
              name="label"
              hasError={formWasSubmit && !isValid.label}
              hasSuccess={formWasSubmit && isValid.label}
              autoFocus
              value={label}
              onChange={this.onChangeLabel}
            />
          </GridItem>
          <GridItem xs={12}>
            <InputColorPicker
              label={<Translate id="Common@color">Cor</Translate>}
              name="color"
              color={color}
              options={tagColors}
              onChange={this.onChangeInputColorPicker}
            />
          </GridItem>
        </GridContainer>

        <GridContainer style={{ marginTop: 20 }}>
          <GridItem xs sm>
            <Button
              color="default"
              small
              block
              disabled={isWaitingForm}
              onClick={this.didCancel}
            >
              <i className="fas fa-chevron-left" /> <CancelString />
            </Button>
          </GridItem>
          {isEditing && (
            <GridItem xs sm>
              <Button
                color="danger"
                small
                block
                disabled={isWaitingForm}
                onClick={this.didWantToDelete}
              >
                <DeleteString /> <DeleteIcon />
              </Button>
            </GridItem>
          )}
          <GridItem xs sm>
            <Button
              color="primary"
              small
              block
              isLoading={isWaitingForm}
              onClick={this.onSubmit}
            >
              {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
            </Button>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default TagForm;
