// @ts-nocheck
import { Component } from "react";

import { withLocalized } from "../../components/HOC";
import { GridContainer, GridItem } from "../../components/Grid";
import { ListItem, ListItemText } from "@material-ui/core";

import { labelForDocumentType } from "../../utils/ContractBusinessUtils";

@withLocalized
class BusinessDocumentsForm extends Component {
  renderContractItem = ({ value, description }) => (
    <ListItem>
      <ListItemText primary={value} secondary={description} />
    </ListItem>
  );

  render() {
    const { localizedString, documents } = this.props;

    return (
      <GridContainer alignItems="center">
        {documents.map(($0) => (
          <GridItem xs={12} sm={6} md={4} lg={6}>
            {this.renderContractItem({
              value: labelForDocumentType($0.type, localizedString),
              description: $0.value,
            })}
          </GridItem>
        ))}
      </GridContainer>
    );
  }
}

export default BusinessDocumentsForm;
