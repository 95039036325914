// @ts-nocheck
import { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

class UpdateDevicesProgressCounter extends Component {
  state = {
    refreshDevicesProgress: 0,
  };

  componentDidMount() {
    this.progressIndicatorTimer = setInterval(() => {
      const { refreshDevicesProgress } = this.state;
      if (refreshDevicesProgress >= 100) {
        this.setState(
          {
            refreshDevicesProgress: 0,
          },
          this.props.callback
        );
      } else {
        this.setState({
          refreshDevicesProgress: refreshDevicesProgress + 0.334,
        });
      }
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.progressIndicatorTimer);
  }

  render() {
    return (
      <LinearProgress
        variant="determinate"
        value={this.state.refreshDevicesProgress}
      />
    );
  }
}

export default UpdateDevicesProgressCounter;
