// @ts-nocheck
import { Component } from "react";
import { inject } from "mobx-react";

import { GridContainer, GridItem } from "../Grid";
import LoadingView from "../LoadingView/LoadingView";
import { ButtonGroup } from "../Form";

import Modal from "./Modal";

import TemperatureChart from "./terminalMetricsModal/TemperatureChart";
import DiskSpaceChart from "./terminalMetricsModal/DiskSpaceChart";
import PowerSupplyChart from "./terminalMetricsModal/PowerSupplyChart";
import RamMemoryChart from "./terminalMetricsModal/RamMemoryChart";
import UptimeChart from "./terminalMetricsModal/UptimeChart";

@inject("i18nStore")
class TerminalMetricsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMetric: "uptime",
    };

    this.chartOptions = this.getChartOptions();
  }

  getChartOptions = () => {
    const { isEnterprise, i18nStore } = this.props;
    const { localizedString } = i18nStore;
    if (isEnterprise) {
      return [
        {
          value: "uptime",
          label: localizedString({
            id: "Device@uptimeInfo",
            defaultString: "Tempo ligado",
          }),
        },
        {
          value: "free_mem",
          label: localizedString({
            id: "Device@hardwareFreeMemorySpaceInfo",
            defaultString: "Memória RAM",
          }),
        },
        {
          value: "free_space",
          label: localizedString({
            id: "Device@diskFreeSpaceInfo",
            defaultString: "Espaço em disco",
          }),
        },
      ];
    }
    return [
      {
        value: "uptime",
        label: localizedString({
          id: "Device@uptimeInfo",
          defaultString: "Tempo ligado",
        }),
      },
      {
        value: "temperature",
        label: localizedString({
          id: "Common@temperature",
          defaultString: "Temperatura",
        }),
      },
      {
        value: "free_mem",
        label: localizedString({
          id: "Device@hardwareFreeMemorySpaceInfo",
          defaultString: "Memória RAM",
        }),
      },
      {
        value: "power_supply",
        label: localizedString({
          id: "Device@powerSupplyInfo",
          defaultString: "Energia",
        }),
      },
      {
        value: "free_space",
        label: localizedString({
          id: "Device@diskFreeSpaceInfo",
          defaultString: "Espaço em disco",
        }),
      },
    ];
  };

  didChangeCurrentMetric = (_, metric) => {
    this.setState({ currentMetric: metric });
  };

  yAnnotation = ({ value, color, label }) => ({
    y: value,
    borderColor: color,
    strokeDashArray: 8,
    label: {
      show: true,
      text: label,
      style: {
        color: "#fff",
        background: color,
      },
    },
  });

  dateFormat = () => {
    const { apexChartDateFormatShort, timeFormat } = this.props.i18nStore;
    return `${apexChartDateFormatShort} ${timeFormat}`;
  };

  render() {
    const {
      i18nStore,
      isOpen,
      didCloseModal,
      isFetching,
      chartData,
    } = this.props;
    const { currentMetric } = this.state;
    const {
      localizedString,
      temperatureSymbol,
      currentTemperatureUnit,
    } = i18nStore;

    const dateFormat = this.dateFormat();

    return (
      <Modal isOpen={isOpen} didCloseModal={didCloseModal} maxWidth="xl">
        <GridContainer>
          <GridItem xs={12}>
            <ButtonGroup
              name="currentMetric"
              value={currentMetric}
              color="success"
              small
              onChange={this.didChangeCurrentMetric}
              options={this.chartOptions}
            />
          </GridItem>
          {isFetching ? (
            <GridItem
              xs={12}
              style={{
                height: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingView></LoadingView>
            </GridItem>
          ) : (
            <GridItem xs={12}>
              {currentMetric === "temperature" && (
                <TemperatureChart
                  chartData={chartData}
                  localizedString={localizedString}
                  dateFormat={dateFormat}
                  temperatureSymbol={temperatureSymbol}
                  currentTemperatureUnit={currentTemperatureUnit}
                  yAnnotation={this.yAnnotation}
                ></TemperatureChart>
              )}
              {currentMetric === "power_supply" && (
                <PowerSupplyChart
                  chartData={chartData}
                  localizedString={localizedString}
                  dateFormat={dateFormat}
                  yAnnotation={this.yAnnotation}
                ></PowerSupplyChart>
              )}
              {currentMetric === "free_space" && (
                <DiskSpaceChart
                  chartData={chartData}
                  localizedString={localizedString}
                  dateFormat={dateFormat}
                  yAnnotation={this.yAnnotation}
                ></DiskSpaceChart>
              )}
              {currentMetric === "free_mem" && (
                <RamMemoryChart
                  chartData={chartData}
                  localizedString={localizedString}
                  dateFormat={dateFormat}
                  yAnnotation={this.yAnnotation}
                ></RamMemoryChart>
              )}
              {currentMetric === "uptime" && (
                <UptimeChart
                  chartData={chartData}
                  localizedString={localizedString}
                  dateFormat={dateFormat}
                ></UptimeChart>
              )}
            </GridItem>
          )}
        </GridContainer>
      </Modal>
    );
  }
}

export default TerminalMetricsModal;
