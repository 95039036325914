// @ts-nocheck
import { Component } from "react";
import classnames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import { Divider } from "@material-ui/core";
import Tooltip from "../Tooltip/Tooltip";
import { Button } from "../Button";

// Style
const styles = {
  container: {
    position: "absolute",
    top: 8,
    height: "calc(100% - 16px)",
  },
  stickyContainer: {
    position: "sticky",
    top: 8,
    background: "#40464d",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    willChange: "transform",
  },
  divider: {
    background: "#676767",
    width: "80%",
    margin: "6px 0",
  },
  buttonBase: {
    color: "#fff!important",
    margin: 4,
  },
  disabledButton: {
    opacity: 0.5,
    background: "transparent!important",
    color: "#999999!important",
    border: "none!important",
    boxShadow: "none!important",
  },
};

export const calculateToolboxHeightForActionsList = (actionsList) => {
  const toolboxPadding = 26;
  return (
    actionsList.reduce((prevValue, action) => {
      const sum =
        action.type === "hidden" ? 0 : action.type === "divider" ? 13 : 40;
      return prevValue + sum;
    }, 0) + toolboxPadding
  );
};

class ToolboxBar extends Component {
  renderActionButton = (action, index) => {
    const { classes } = this.props;
    const color = action.isActive
      ? action.activeColor || "success"
      : action.inactiveColor || "transparent";
    const isDisabled = this.props.isDisabled ? true : action.disabled || false;
    const button = (
      <Button
        key={index}
        justIcon
        small
        color={color}
        disabled={isDisabled}
        disableRipple={isDisabled}
        isLoading={action.isLoading}
        className={classnames({
          [classes.buttonBase]: true,
          [classes.disabledButton]: isDisabled,
        })}
        onClick={action.onClick}
      >
        <i className={`far fa-${action.icon}`} />
      </Button>
    );
    if (!isDisabled && action.label) {
      return (
        <Tooltip
          key={index}
          placement="right"
          title={action.label}
          disableFocusListener
        >
          {button}
        </Tooltip>
      );
    }
    return button;
  };

  render() {
    const { classes, actions, leftPosition } = this.props;

    return (
      <div className={classes.container} style={{ left: leftPosition }}>
        <div className={classes.stickyContainer}>
          {actions.map((action, index) => {
            if (action.type === "hidden" || action.isHidden) {
              return null;
            }
            if (action.type === "divider") {
              return <Divider key={index} light className={classes.divider} />;
            }
            if (action.type === "submit") {
              return this.renderActionButton(
                {
                  ...action,
                  activeColor: "primary",
                },
                index
              );
            }
            return this.renderActionButton(action, index);
          })}
        </div>
      </div>
    );
  }
}

ToolboxBar.defaultProps = {
  leftPosition: -30,
  isDisabled: false,
};

export default withStyles(styles)(ToolboxBar);
