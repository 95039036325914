// @ts-nocheck
import { Component } from "react";
import { observer } from "mobx-react";

import { SimpleCard } from "../../Card";
import { GridContainer, GridItem } from "../../Grid";

import DatePicker from "../../Form/DatePicker";

// Translate
import Translate from "../../I18n/Translate";

// Utils
import { momentDateFromUnixDate } from "../../../utils/DateUtils";

@observer
class DateConfigDateInOut extends Component {
  getMomentDayInOutDates() {
    const { dayIn, dayOut } = this.props;
    let startDate = momentDateFromUnixDate(dayIn);
    const endDate = momentDateFromUnixDate(dayOut);
    return { startDate, endDate };
  }

  onChangeDate = (name, newValue) => {
    const result = {
      [name]: newValue.format("X"),
    };
    if (name === "dayIn") {
      this.props.onChangeDateIn(result);
    } else {
      this.props.onChangeDateOut(result);
    }
  };

  renderDatePicker = ({ name, label, value }) => (
    <GridItem xs={12} sm={6}>
      <SimpleCard cardStyle={{ marginTop: 0 }}>
        <DatePicker
          label={label}
          name={name}
          value={value}
          hideTime
          onChange={this.onChangeDate}
          fullWidth
          removeMargin
        />
      </SimpleCard>
    </GridItem>
  );

  render() {
    const { startDate, endDate } = this.getMomentDayInOutDates();

    return (
      <GridContainer>
        {this.renderDatePicker({
          name: "dayIn",
          label: <Translate id="Schedule@startDate">Data início</Translate>,
          value: startDate,
        })}
        {this.renderDatePicker({
          name: "dayOut",
          label: <Translate id="Schedule@endDate">Data fim</Translate>,
          value: endDate,
        })}
      </GridContainer>
    );
  }
}

export default DateConfigDateInOut;
