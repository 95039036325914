// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../HOC";
import { GridItem } from "../Grid";
import CustomLinearProgress from "../CustomLinearProgress/CustomLinearProgress";
import { Button } from "../Button";

//Utils
import predefinedMessage, {
  PREDEFINED_MESSAGES_KEYS,
} from "../../store/ChatStore/PredefinedMessages";
import { humanFileSizeFromBytes } from "../../utils/Utils";

// Translate
import Translate from "../I18n/Translate";

// Style
const styles = {
  container: { marginTop: 16, position: "relative" },
  stats: {
    margin: 0,
  },
  of: { margin: "0 8px" },
  icon: { marginRight: 10, width: 20 },
  upgradeButton: {
    position: "absolute",
    top: -8,
    right: 8,
  },
};

@withLocalized
@inject("contractStore", "chatStore", "productsStore")
@observer
class UsageGridWidget extends Component {
  getUsageAndLimitForType = (type) => {
    const { usage, limit } = this.props.contractStore.selectedContract[type];
    const percentage = (usage * 100) / limit;
    if (type === "storage") {
      return {
        percentage,
        usageLabel: humanFileSizeFromBytes(usage),
        limitLabel: humanFileSizeFromBytes(limit),
      };
    }
    return {
      percentage,
      usageLabel: usage,
      limitLabel: limit,
    };
  };

  getIconForType = (type) => {
    switch (type) {
      case "licences":
        return "hdd";
      case "storage":
        return "play";
      case "wifiAuthentication":
        return "wifi";
      case "sms":
        return "bullhorn";
      case "cameraFaces":
        return "video";
      case "email":
      default:
        return "envelope";
    }
  };

  affiliateOnClickUpdateButton = () => {
    const { type, productsStore } = this.props;
    let selectedCategory = null;

    if (type === "licences") selectedCategory = "other";
    if (type === "sms") selectedCategory = "smsCredit";
    if (type === "storage") selectedCategory = "storage";
    if (type === "wifiAuthentication") selectedCategory = "wifiAuthentication";

    productsStore.toggleProductsListModal(selectedCategory);
  };

  onClickUpgradeButton = async () => {
    const { type, chatStore, localizedString } = this.props;
    let messageKey = "";
    let subject = "";

    if (type === "licences") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestLicences;
      subject = localizedString({
        id: "ContractLimit@requestLicencesSubject",
        defaultString: "Aumentar licenças",
      });
    }
    if (type === "storage") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestStorage;
      subject = localizedString({
        id: "ContractLimit@requestStorageSubject",
        defaultString: "Aumentar espaço em disco",
      });
    }
    if (type === "wifiAuthentication") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestWifiAuthentication;
      subject = localizedString({
        id: "ContractLimit@requestWifiAuthSubject",
        defaultString: "Aumentar autenticações Wi-Fi",
      });
    }
    if (type === "sms") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestSmsCredits;
      subject = localizedString({
        id: "ContractLimit@requestSmsCreditsSubject",
        defaultString: "Aumentar créditos SMS",
      });
    }
    if (type === "cameraFaces") {
      messageKey = PREDEFINED_MESSAGES_KEYS.requestCameraFacesCredits;
      subject = localizedString({
        id: "ContractLimit@requestCameraFacesCreditsSubject",
        defaultString: "Aumentar capturas de ambiente",
      });
    }

    chatStore.didWantToStartNewChatWithSubjectAndMessage(
      subject,
      predefinedMessage(messageKey, localizedString)
    );
  };

  render() {
    const { classes, contractStore, type, showIcon } = this.props;

    if (
      !contractStore.selectedContract ||
      !contractStore.selectedContract[type]
    ) {
      return null;
    }

    const { percentage, usageLabel, limitLabel } = this.getUsageAndLimitForType(
      type
    );
    return (
      <GridItem xs={12} className={classes.container}>
          <p className={classes.stats}>
            {showIcon && (
              <i
                className={`fas fa-${this.getIconForType(type)} ${
                  classes.icon
                }`}
              />
            )}{" "}
            {usageLabel}{" "}
            <span className={classes.of}>
              <Translate id="Common@of">de</Translate>
            </span>{" "}
            {limitLabel}
          </p>
          <CustomLinearProgress
            variant="determinate"
            color={
              percentage < 50
                ? "success"
                : percentage > 80
                ? "danger"
                : "warning"
            }
            value={percentage}
          />
      </GridItem>
    );
  }
}

UsageGridWidget.defaultProps = {
  showIcon: false,
};

export default withStyles(styles)(UsageGridWidget);
