import { observable, action, computed } from "mobx";

import { IMAGE_PATH } from "../../utils/EnvUtils";

import { settingsRef } from "../FirebaseStore/FirebaseStore";

export enum EDisplayMode {
  grid = "grid",
  table = "table",
}

class SettingsStore {
  @observable
  sidebarBgImage: string = "";

  @observable
  authRedirectToDefault: string =
    window.localStorage.getItem("authRedirectToDefault") ||
    "https://google.com";

  @observable
  maxTimeDefault: string =
    window.localStorage.getItem("maxTimeDefault") || "60";

  @observable
  mediaDurationToUpload: number = window.localStorage.getItem(
    "mediaDurationToUpload"
  )
    ? parseInt(window.localStorage.getItem("mediaDurationToUpload"), 10)
    : 10;

  @observable
  informativeDurationToLiveContent: number = window.localStorage.getItem(
    "informativeDurationToLiveContent"
  )
    ? parseInt(
        window.localStorage.getItem("informativeDurationToLiveContent"),
        10
      )
    : 120;

  @observable
  currentCurrency: string = "BRL";

  @observable
  mediaUploaderService: string = "autonomedia";

  @observable
  displayMode: EDisplayMode = EDisplayMode.grid;

  constructor() {
    settingsRef.child("mediaUploaderService").on("value", (snapshot) => {
      this.setMediaUploaderService(
        snapshot.exists() ? snapshot.val() : "autonomedia"
      );
    });

    const savedDisplayMode = window.localStorage.getItem("displayMode");
    if (savedDisplayMode) {
      this.setDisplayMode(EDisplayMode[savedDisplayMode]);
    }
  }

  @action
  actionChangeSidebarBgImage = (imageName: string) => {
    this.sidebarBgImage = `${IMAGE_PATH}/sidebar/${imageName}.jpg`;
  };

  @action
  actionChangeAuthRedirectToDefault = (newValue: string) => {
    this.authRedirectToDefault = newValue;
    window.localStorage.setItem("authRedirectToDefault", newValue);
  };

  @action
  actionChangeMaxTimeDefault = (newValue: string) => {
    this.maxTimeDefault = newValue;
    window.localStorage.setItem("maxTimeDefault", newValue);
  };

  @action
  actionChangeMediaDurationToUpload = (newValue: number) => {
    const value = !isNaN(newValue) ? newValue : 10;
    this.mediaDurationToUpload = value;
    window.localStorage.setItem("mediaDurationToUpload", `${value}`);
  };

  @action
  actionChangeInformativeDurationToLiveContent = (newValue: number) => {
    const value = !isNaN(newValue) ? newValue : 10;
    this.informativeDurationToLiveContent = value;
    window.localStorage.setItem(
      "informativeDurationToLiveContent",
      `${newValue}`
    );
  };

  @action
  setMediaUploaderService = (newValue: string) => {
    this.mediaUploaderService = newValue;
  };

  @action
  setDisplayMode = (newValue: EDisplayMode) => {
    this.displayMode = newValue;
    window.localStorage.setItem("displayMode", newValue);
  };

  @computed
  get isUsingTransloadit(): boolean {
    return this.mediaUploaderService === "transloadit";
  }

  @computed
  get isGridDisplayMode(): boolean {
    return this.displayMode === EDisplayMode.grid;
  }
  @computed
  get isTableDisplayMode(): boolean {
    return this.displayMode === EDisplayMode.table;
  }
}

export default SettingsStore;
