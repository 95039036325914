//@ts-nocheck
import RadioGroup from "../../Form/RadioGroup";
import WizardUploadImage from "../Image";
import {
  NumberSecondsBox,
  WizarUploadRadioButtonBox,
  WizardUploadFor2Box,
} from "../style";
import { WizardUploadComponentProps } from "../type";
import NumberEditingWithLabelInput from "../../Form/NumberEditingWithLabelInput";
import { SecondsString } from "../../I18n/CommonStrings";

const WizardUploadMediaType = ({
  payload,
  updateRadio,
}: WizardUploadComponentProps) => {
  return (
    <WizardUploadFor2Box>
      <div className="title">
        <h3>Qual tipo de mídia você está enviando?</h3>
      </div>
      <div className="content">
        <div className="left">
          <p>
            Por favor, informe o tipo de mídia que você vai enviar para gerar o
            seu conteúdo. Se selecionar <b>"Imagem"</b>, você poderá informar a
            duração de exibição do conteúdo na playlist.
          </p>
          <WizarUploadRadioButtonBox>
            <RadioGroup
              name="isImage"
              value={payload?.isImage}
              onChange={updateRadio}
              options={[
                {
                  label: "Imagem estática",
                  value: true,
                },
                { label: "Vídeo", value: false },
              ]}
            />
          </WizarUploadRadioButtonBox>
          {!!payload?.isImage && (
            <NumberSecondsBox>
              <NumberEditingWithLabelInput
                label="Informe a duração do conteúdo na playlist:"
                typeLabel={<SecondsString />}
                onChange={(value) =>
                  updateRadio("secondDuration", Number(value) < 5 ? 5 : value)
                }
                value={payload?.secondDuration}
              />
            </NumberSecondsBox>
          )}
        </div>

        <div className="right">
          <WizardUploadImage payload={payload} />
        </div>
      </div>
    </WizardUploadFor2Box>
  );
};

export default WizardUploadMediaType;
