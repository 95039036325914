// @ts-nocheck
import { Fragment } from "react";
import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import ListLocationsContainer from "./locationsPage/ListLocationsContainer";

const LocationsPage = () => (
  <Fragment>
    <PageTitle id="Menu@locations">Localidades</PageTitle>
    <ListLocationsContainer />
  </Fragment>
);

export default withPageAllowedRules({
  component: LocationsPage,
  pageAllowedRule: "locations",
});
