// @ts-nocheck

import classnames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import { primaryColor } from "../../assets/jss/app-js-styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      background: primaryColor,
      color: "#fff !important",
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "1em",
    },
    chipWithMargin: {
      margin: 4,
    },
    fullWidth: {
      width: "100%",
    },
    icon: {
      color: "#fff !important",
      marginLeft: 8,
    },
    deleteIcon: {
      color: "#fff !important",
      marginLeft: 4,
      marginRight: 8,
    },
  })
);

const Tag = ({
  icon,
  label,
  onClick,
  onDelete,
  color,
  withMargin,
  isSelected,
  fullWidth,
}) => {
  const classes = useStyles();

  return (
    <Chip
      className={classnames({
        [classes.chip]: true,
        [classes.chipWithMargin]: withMargin,
        [classes.fullWidth]: fullWidth,
      })}
      classes={{
        icon: classes.icon,
        deleteIcon: classes.deleteIcon,
      }}
      icon={
        <i
          className={classnames({
            fas: true,
            [`fa-${icon || "tag"}`]: true,
          })}
        />
      }
      size="small"
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={isSelected ? <i className="fas fa-check" /> : null}
      // deleteIcon={
      //   <i
      //     className={classnames({
      //       fas: true,
      //       "fa-check": true
      //     })}
      //   />
      // }
      style={{ backgroundColor: color }}
    />
  );
};

Tag.defaultProps = {
  color: "inherit",
};

export default Tag;
