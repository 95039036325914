// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";
import { autorun } from "mobx";
import { inject, observer } from "mobx-react";
import { Component, Fragment } from "react";

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Card, CardBody, CardHeader, CardIcon } from "../../components/Card";
import { GridContainer, GridItem } from "../../components/Grid";
import LoadingView from "../../components/LoadingView/LoadingView";

import BusinessAddressForm from "./BusinessAddressForm";
import BusinessContactsForm from "./BusinessContactsForm";
import BusinessDocumentsForm from "./BusinessDocumentsForm";

import { ExpansionPanelContainer } from "../../components/ExpansionPanel";

import Plural from "../../components/I18n/Plural";
import Translate from "../../components/I18n/Translate";

//Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";
const styles = {
  ...chartsStyle,
  sectionTitle: {
    fontWeight: 500,
    fontSize: "0.9em",
    marginBottom: 0,
    marginTop: 20,
  },
};

@inject("authStore", "contractStore", "notificationsStore", "viacepAPIService")
@observer
class ContractBusinessDetailsWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      ownerName: "",
      activity: "",
      addressName: "",
      addressNumber: "",
      addressZipCode: "",
      addressNeighborhood: "",
      addressCity: "",
      addressState: "",
      addressCountry: "",
      documents: [],
      contacts: [],
      isChecked: false,
    };
  }

  componentDidMount() {
    const { contractStore } = this.props;

    this.disposerAutorun = autorun(() => {
      if (contractStore.selectedContractBusinessData) {
        const {
          name,
          owner,
          activity,
          address,
          contacts,
          documents,
          isChecked,
        } = contractStore.selectedContractBusinessData;

        this.setState({
          name: name ? name : "",
          ownerName: owner ? owner : "",
          activity: activity ? activity : "-",
          addressName: address.address ? address.address : "",
          addressNumber: address.number ? address.number : "",
          addressZipCode: address.zipCode ? address.zipCode : "",
          addressNeighborhood: address.neighborhood ? address.neighborhood : "",
          addressCity: address.city ? address.city : "",
          addressState: address.state ? address.state : "",
          addressCountry: address.country ? address.country : "",
          documents,
          contacts,
          isChecked,
        });
      }
    });
  }

  componentWillUnmount() {
    this.disposerAutorun();
  }

  renderSectionTitle = (title) => (
    <h5 className={this.props.classes.sectionTitle}>{title}</h5>
  );

  renderContractItem = ({ icon, value, description }) => (
    <ListItem>
      <ListItemIcon>
        <i className={`fas fa-${icon} fa-fw`} />
      </ListItemIcon>
      <ListItemText primary={value} secondary={description} />
    </ListItem>
  );

  render() {
    const { classes, contractStore } = this.props;
    const {
      selectedContractBusinessData,
      isFetchingContractBusinessData,
    } = contractStore;

    const {
      name,
      activity,
      ownerName,
      addressName,
      addressNumber,
      addressZipCode,
      addressNeighborhood,
      addressCity,
      addressState,
      addressCountry,
      isChecked,
    } = this.state;

    return (
      <div id="business">
        {isChecked ? (
          <Card style={{ marginBottom: 240 }}>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <i className="fas fa-file-invoice" style={{ fontSize: 20 }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Translate id="Contract@businessDetails">
                  Dados da empresa
                </Translate>
              </h4>
            </CardHeader>
            <CardBody>
              {!selectedContractBusinessData ||
              isFetchingContractBusinessData ? (
                <LoadingView />
              ) : (
                <Fragment>
                  <GridContainer>
                    <GridItem xs={12} sm={6} lg={4}>
                      {this.renderContractItem({
                        icon: "shield-alt",
                        value: (
                          <Translate id="Contract@businessName">
                            Nome da empresa
                          </Translate>
                        ),
                        description: name,
                      })}
                    </GridItem>
                    <GridItem xs={12} sm={6} lg={4}>
                      {this.renderContractItem({
                        icon: "user",
                        value: (
                          <Translate id="Contract@businessOwner">
                            Proprietário
                          </Translate>
                        ),
                        description: ownerName,
                      })}
                    </GridItem>
                    <GridItem xs={12} sm={6} lg={4}>
                      {this.renderContractItem({
                        icon: "briefcase",
                        value: (
                          <Translate id="Contract@businessActivitySection">
                            Ramo de atividade
                          </Translate>
                        ),
                        description: activity,
                      })}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12}>
                      <ExpansionPanelContainer
                        panels={[
                          {
                            title: (
                              <Translate id="Contract@businessDetailsDocumentsSection">
                                Documentos
                              </Translate>
                            ),
                            subtitle: (
                              <Plural
                                id="Contract@businessDetailsDocumentsQuantity"
                                value={this.state.documents.length}
                                one={"Um documento"}
                                other={"# documentos"}
                              />
                            ),
                            details: this.state.documents.length ? (
                              <BusinessDocumentsForm
                                documents={this.state.documents}
                              />
                            ) : (
                              <Translate id="Contract@businessNoDataMessage">
                                Dados incompletos, por favor entre em contato
                                com nossa equipe de Atendimento
                              </Translate>
                            ),
                          },
                          {
                            title: (
                              <Translate id="Contract@businessDetailsContactsSection">
                                Contatos
                              </Translate>
                            ),
                            subtitle: (
                              <Plural
                                id="Contract@businessDetailsContactsQuantity"
                                value={this.state.contacts.length}
                                one={"Um contato"}
                                other={"# contatos"}
                              />
                            ),
                            details: this.state.contacts.length ? (
                              <BusinessContactsForm
                                contacts={this.state.contacts}
                              />
                            ) : (
                              <Translate id="Contract@businessNoDataMessage">
                                Dados incompletos, por favor entre em contato
                                com nossa equipe de Atendimento
                              </Translate>
                            ),
                          },
                          {
                            title: (
                              <Translate id="Contract@businessDetailsAddressSection">
                                Endereço
                              </Translate>
                            ),
                            subtitle:
                              addressName.length > 0
                                ? `${addressName}, ${addressNumber} - ${addressNeighborhood}`
                                : "-",
                            details:
                              addressZipCode.length > 0 ? (
                                <BusinessAddressForm
                                  addressName={addressName}
                                  addressNumber={addressNumber}
                                  addressNeighborhood={addressNeighborhood}
                                  addressZipCode={addressZipCode}
                                  addressState={addressState}
                                  addressCity={addressCity}
                                  addressCountry={addressCountry}
                                />
                              ) : (
                                <Translate id="Contract@businessNoDataMessage">
                                  Dados incompletos, por favor entre em contato
                                  com nossa equipe de Atendimento
                                </Translate>
                              ),
                          },
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                </Fragment>
              )}
            </CardBody>
          </Card>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ContractBusinessDetailsWidget);
