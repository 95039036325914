// @ts-nocheck

import animationData from "./Typing.json";
import LottieAnimation from "../LottieAnimation";

const Typing = props => (
  <LottieAnimation animationData={animationData} {...props} />
);

export default Typing;
