// @ts-nocheck

import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Chart from "react-apexcharts";

import { withLocalized } from "../HOC";

import { theme1 } from "./ChartColors";

const LineChart = ({
  i18nStore,
  xAxisTitle,
  xAxisType,
  xAxisCategories,
  yAxisTitle,
  customTooltip,
  customChartOptions,
  customOptions,
  data,
  height,
  width,
  showToolbar,
}) => {
  const chartData = {
    options: {
      ...theme1,
      xaxis: {
        type: xAxisType,
        categories: xAxisCategories,
        labels: {
          format: i18nStore.apexChartDateFormatShort,
        },
        title: {
          text: xAxisTitle,
          style: {
            fontSize: 16,
          },
        },
      },
      yaxis: {
        title: {
          text: yAxisTitle,
          style: {
            fontSize: 16,
          },
        },
      },
      tooltip: {
        x: {
          format: i18nStore.apexChartDateFormatFull,
        },
        ...customTooltip,
      },
      legend: {
        fontSize: 16,
        offsetY: -10,
        labels: {
          useSeriesColors: true,
        },
        itemMargin: {
          vertical: 25,
        },
      },
      chart: {
        toolbar: {
          show: showToolbar,
        },
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        ...customChartOptions,
      },
      stroke: {
        width: 3,
        curve: "straight",
      },
      ...customOptions,
    },
  };
  return (
    <Chart
      options={chartData.options}
      series={data}
      type="line"
      height={height}
      width={width}
    />
  );
};

LineChart.defaultProps = {
  showToolbar: false,
  xAxisType: "category",
  customChartOptions: {},
  customOptions: {},
};

LineChart.propTypes = {
  xAxisType: PropTypes.oneOf(["category", "datetime", "numeric"]).isRequired,
  customTooltip: PropTypes.object,
  customChartOptions: PropTypes.object,
  customOptions: PropTypes.object,
};

export default withLocalized(observer(LineChart));
