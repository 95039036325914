//@ts-nocheck
import { FC, CSSProperties } from "react";
import Card from "./Card";
import CardBody from "./CardBody";

interface SimpleCardProps {
  classNames?: any;
  cardStyle?: CSSProperties;
  cardBodyStyle?: CSSProperties;
  onClick?: () => void;
}

const SimpleCard: FC<SimpleCardProps> = ({
  classNames,
  cardStyle,
  cardBodyStyle,
  children,
  onClick,
}) => (
  <Card className={classNames.card} style={cardStyle} onClick={onClick}>
    <CardBody className={classNames.cardBody} style={cardBodyStyle}>
      {children}
    </CardBody>
  </Card>
);

SimpleCard.defaultProps = {
  classNames: {
    card: "",
    cardBody: "",
  },
};

export default SimpleCard;
