// @ts-nocheck

import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Chart from "react-apexcharts";

import { withLocalized } from "../HOC";

import { theme1 } from "./ChartColors";

const AreaChart = ({
  i18nStore,
  xAxisTitle,
  xAxisType,
  xAxisCategories,
  yAxisTitle,
  customTooltip,
  customChartOptions,
  customOptions,
  data,
  height,
  width,
  showToolbar,
}) => {
  const chartData = {
    options: {
      ...theme1,
      xaxis: {
        type: xAxisType,
        categories: xAxisCategories,
        labels: {
          format: i18nStore.apexChartDateFormatShort,
        },
        title: {
          text: xAxisTitle,
          style: {
            fontSize: 16,
          },
        },
      },
      chart: {
        toolbar: {
          show: showToolbar,
        },
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        ...customChartOptions,
      },
      stroke: {
        width: 2,
        curve: "straight",
      },
      ...customOptions,
    },
  };
  return (
    <Chart
      options={chartData.options}
      series={data}
      type="area"
      height={height}
      width={width}
    />
  );
};

AreaChart.defaultProps = {
  showToolbar: false,
  xAxisType: "category",
  customChartOptions: {},
  customOptions: {},
};

AreaChart.propTypes = {
  xAxisType: PropTypes.oneOf(["category", "datetime", "numeric"]),
  customTooltip: PropTypes.object,
  customChartOptions: PropTypes.object,
  customOptions: PropTypes.object,
};

export default withLocalized(observer(AreaChart));
