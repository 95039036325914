// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

// Components
import withStyles from "@material-ui/core/styles/withStyles";
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";
import { InputText, InputSwitch, InputColorPicker } from "../Form";

import { Button } from "../Button";
import { GridContainer, GridItem } from "../Grid";
import { withLocalized } from "../HOC";

import LedAreaForm from "../../components/LedArea/LedAreaForm";

import UppyFileUpload from "../Form/UppyFileUpload";

// Transloadit
import TransloaditUppyFileUpload from "../Form/TransloaditUppyFileUpload";
// Transloadit END

import IfExistsImage from "../IfExistsImage/IfExistsImage";

// types
import { EMediaTemplate } from "../../types/Media";

// Translate
import {
  DeleteString,
  UpdateString,
  SaveString,
  LabelHintString,
  TransparentString,
  BackgroundColorString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Icon
import { SaveIcon, DeleteIcon } from "../Icon";

// Util
import { backgroundColorOptions } from "../../utils/ColorUtils";
import { generateRandomLabel } from "../../utils/StringUtils";

// Style
const styles = {
  image: {
    maxWidth: "100%",
    maxHeight: 200,
    padding: 2,
    borderRadius: 8,
    background: "#ADADAD",
  },
  imagePlaceholder: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

@withLocalized
@inject("informativeLogotypeStore", "settingsStore")
@observer
class LogotypeConfigurationForm extends Component {
  constructor(props) {
    super(props);

    if (props.logotype) {
      this.state = this.getStateFromExistingLogotype(props.logotype);
    } else {
      this.state = this.getDefaultState();
    }
  }

  getDefaultState = () => {
    return {
      label: "",
      backgroundColor: "#1D1D1D",
      imageHash: "",
      imageUrl: "",
      allowToUploadNewImage: true,
      isTransparent: false,
      isVertical: false,
      isLed: false,
      ledArea: "",
      isWaitingForm: false,
      modalIsOpen: false,
      formWasSubmit: false,
      didWantToDelete: false,
      isUploading: false,
    };
  };

  changeIsUploadingState = (newState) => {
    this.setState({
      isUploading: newState,
    });
  };

  getStateFromExistingLogotype = (logotype) => {
    return {
      ...this.getDefaultState(),
      ...logotype,
      isLed: !!logotype.ledArea,
      allowToUploadNewImage: logotype.imageHash.length < 1,
    };
  };

  didWantToUploadNewFile = (event) => {
    event.preventDefault();

    this.setState({
      allowToUploadNewImage: true,
    });
  };

  didWantToAddLedArea = () => {
    this.setState({
      modalIsOpen: true,
      isLed: true,
    });
  };

  toggleIsEditingLedArea = () => {
    this.setState((prevState) => ({
      isLed: !prevState.isLed,
    }));
  };

  onChangeLabel = ({ target }) => {
    this.setState({
      label: target.value,
    });
  };

  onChangeInputColorPicker = ({ name: key, color }) => {
    this.setState({ [key]: color });
  };

  onChangeSwitch = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  getFormDataToSubmit = ({
    label,
    backgroundColor,
    imageHash,
    isTransparent,
    isVertical,
    isLed,
    ledArea,
  }) => {
    return {
      label:
        label.length > 0
          ? label
          : generateRandomLabel(
              this.props.localizedString({
                id: "Menu@logotype",
                defaultString: "Logotipo",
              })
            ),
      backgroundColor,
      imageHash,
      isTransparent,
      isVertical,
      isLed,
      ledArea,
    };
  };

  submitForm = () => {
    const { logotype } = this.props;

    const formData = this.getFormDataToSubmit(this.state);

    if (logotype) {
      this.props.informativeLogotypeStore
        .update({
          ...formData,
          logotypeToken: logotype.token,
        })
        .then(() => {
          this.submitHasSuccess({ token: null });
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      this.props.informativeLogotypeStore
        .submit(formData)
        .then((response) => {
          this.submitHasSuccess({ token: response.data.logo_token });
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  didWantToDelete = (event) => {
    event.preventDefault();

    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.informativeLogotypeStore
      .delete({
        logotypeToken: this.props.logotype.token,
      })
      .then(() => {
        this.props.didDelete();
      });
  };

  onChangeLedArea = (ledArea) => {
    this.setState({ ledArea });
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
      this.submitForm();
    });
  };

  submitHasSuccess = (token) => {
    this.props.didSave();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  didChangeFilesUpload = (files) => {
    if (files.length > 0) {
      this.setState({
        imageHash: files[0].fileHash,
        imageUrl: files[0].url,
        allowToUploadNewImage: false,
      });
    }
  };

  render() {
    const isEditing = this.props.logotype;
    const { classes } = this.props;
    const {
      label,
      backgroundColor,
      isTransparent,
      isVertical,
      isLed,
      ledArea,
      isWaitingForm,
      didWantToDelete,
    } = this.state;

    return (
      <Fragment>
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteConfiguration}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <InputText
              label={
                <Translate id="Logotype@titleLabel">
                  Título do logotipo (opcional)
                </Translate>
              }
              name="label"
              icon="text-width"
              value={label}
              onChange={this.onChangeLabel}
              maxLength={30}
              helpText={<LabelHintString />}
            />
            <InputColorPicker
              label={<BackgroundColorString />}
              name="backgroundColor"
              options={backgroundColorOptions}
              color={backgroundColor}
              onChange={this.onChangeInputColorPicker}
            />
            <InputSwitch
              label={<TransparentString />}
              name="isTransparent"
              isActive={isTransparent}
              onChange={this.onChangeSwitch}
            />
            <InputSwitch
              label={
                <Translate id="Common@isVerticalLabel">Vertical</Translate>
              }
              name="isVertical"
              isActive={isVertical}
              onChange={this.onChangeSwitch}
            />
            <InputSwitch
              label="Led"
              name="isLed"
              isActive={isLed}
              onChange={this.toggleIsEditingLedArea}
            />
            {isLed && (
              <LedAreaForm onChange={this.onChangeLedArea} value={ledArea} />
            )}

            {this.state.allowToUploadNewImage ? (
              <Fragment>
                {this.props.settingsStore.isUsingTransloadit ? (
                  <TransloaditUppyFileUpload
                    label={
                      <Translate id="Logotype@imageLabel">
                        Imagem do logotipo
                      </Translate>
                    }
                    name="image"
                    uploadType="logotype"
                    maxNumberOfFiles={1}
                    minNumberOfFiles={1}
                    allowedFileTypes={["image/*"]}
                    didChangeFiles={this.didChangeFilesUpload}
                    hideDragDropContainer={this.state.isUploading}
                    changeIsUploadingState={this.changeIsUploadingState}
                  />
                ) : (
                  <UppyFileUpload
                    label={
                      <Translate id="Logotype@imageLabel">
                        Imagem do logotipo
                      </Translate>
                    }
                    name="image"
                    template={EMediaTemplate.logotype}
                    maxNumberOfFiles={1}
                    minNumberOfFiles={1}
                    allowedFileTypes={["image/*"]}
                    didChangeFiles={this.didChangeFilesUpload}
                    hideDragDropContainer={this.state.isUploading}
                    changeIsUploadingState={this.changeIsUploadingState}
                  />
                )}
                <p style={{ textAlign: "center", fontStyle: "italic" }}>
                  <Translate id="Logotype@imageUploadHint">
                    Para melhor enquadramento a imagem deve ser retangular (ex:
                    400px de largura e 120px de altura).
                  </Translate>
                </p>
              </Fragment>
            ) : (
              <GridContainer>
                <GridItem xs={12}>
                  <label style={{ marginRight: 8 }} className="control-label">
                    <Translate id="Logotype@imageLabel">
                      Imagem do logotipo
                    </Translate>
                  </label>
                </GridItem>
                <GridItem xs={12} style={{ marginBottom: 20 }}>
                  <IfExistsImage
                    className={classes.image}
                    src={this.state.imageUrl}
                    placeholderClassName={classes.imagePlaceholder}
                    alt="logo"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Button
                    small
                    color="warning"
                    onClick={this.didWantToUploadNewFile}
                  >
                    <Translate id="Logotype@sendNewImageButton">
                      Enviar nova imagem
                    </Translate>{" "}
                    <i className="fas fa-image" />
                  </Button>
                </GridItem>
              </GridContainer>
            )}

            <GridContainer justify="space-between">
              {isEditing && (
                <GridItem xs={12} sm={4}>
                  <Button color="danger" block onClick={this.didWantToDelete}>
                    <DeleteString /> <DeleteIcon />
                  </Button>
                </GridItem>
              )}
              <GridItem xs sm>
                <Button
                  color="primary"
                  block
                  isLoading={isWaitingForm}
                  onClick={this.onSubmit}
                  disabled={this.state.isUploading}
                >
                  {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(LogotypeConfigurationForm);
