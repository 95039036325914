//@ts-nocheck
import { WizarUploadRadioButtonBox, WizardUploadFor2Box } from "../style";
import RadioGroup from "../../Form/RadioGroup";
import { WizardUploadComponentProps } from "../type";
import WizardUploadImage from "../Image";

const WizardUploadOrientation = ({
  payload,
  updateRadio,
}: WizardUploadComponentProps) => {
  return (
    <WizardUploadFor2Box>
      <div className="title">
        <h3>Qual é a orientação do seu conteúdo?</h3>
      </div>
      <div className="content">
        <div className="left">
          <p>
            Dica: A orientação do conteúdo é importante para garantir que ele
            seja exibido corretamente na tela. Tanto imagens quanto vídeos podem
            ser exibidos na orientação horizontal ou vertical, portanto, escolha
            a orientação que melhor se adapta ao seu conteúdo.
          </p>

          <WizarUploadRadioButtonBox>
            <RadioGroup
              name="isVertical"
              value={payload?.isVertical}
              onChange={updateRadio}
              options={[
                { label: "Horizontal", value: false },
                { label: "Vertical", value: true },
              ]}
            />
          </WizarUploadRadioButtonBox>
        </div>
        <div className="right">
          <WizardUploadImage payload={payload} />
        </div>
      </div>
    </WizardUploadFor2Box>
  );
};

export default WizardUploadOrientation;
