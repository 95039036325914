// @ts-nocheck
import { observable, computed, action, autorun } from "mobx";

// Model
import ProductModel, { ProductCategory } from "../../Model/ProductModel";

// Utils Stores
import { productsRef } from "../FirebaseStore/FirebaseStore";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class ProductsStore {
  @observable productsList = [];
  @observable isFetching = null;
  @observable searchString = "";

  @observable isOpenProductsListModal = false;

  @observable productListModalSelectedPanel = null;

  productsListRef = null;

  constructor(authStore, contractStore, firebaseAuthStore) {
    this.authStore = authStore;
    this.contractStore = contractStore;

    autorun(
      () => {
        if (
          authStore.currentUser &&
          contractStore.selectedContract &&
          firebaseAuthStore.isAuthReady &&
          (contractStore.selectedContract.isAffiliateContract ||
            authStore.currentUserIsAdmin)
        ) {
          this.setFirebaseListener();
        } else {
          this.clearStore();
        }
      },
      { name: "ProductsStore setFirebaseListener" }
    );
  }

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };
  @action
  setProductsList = (newValue) => {
    this.productsList = newValue;
  };
  @action
  setSearchString = (newValue) => {
    this.searchString = newValue;
  };

  @action
  setFirebaseListener = () => {
    this.setIsFetching(true);
    this.productsListRef = productsRef;
    this.productsListRef.on("value", (productsListSnapshot) => {
      if (productsListSnapshot.val()) {
        let newList = [];
        productsListSnapshot.forEach((productSnapshot) => {
          const productData = productSnapshot.val();
          // hide upgrade contract products if is already advanced
          // but show for admin login
          if (
            !this.authStore.currentUserIsAdmin &&
            productData.afterPurchase.otherKey ===
              "upgrade-to-advanced-contract"
          )
            return;

          newList.push(
            new ProductModel({
              token: productSnapshot.key,
              ...productData,
            })
          );
        });

        const list = newList
          .sort(dynamicSortByProperty("name"))
          .sort(dynamicSortByProperty("price"))
          .sort(dynamicSortByProperty("category"));

        this.setProductsList(list);
        this.setIsFetching(false);
      }
    });
  };

  @action
  clearStore = () => {
    this.productsList = [];
    this.isFetching = false;
    this.searchString = "";
    this.isOpenProductsListModal = false;

    if (this.productsListRef) {
      this.productsListRef.off();
      this.productsListRef = null;
    }
  };

  @computed
  get filteredProductsList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.productsList,
        (product, textIncludesSearchString) =>
          textIncludesSearchString(product.name) ||
          textIncludesSearchString(product.description) ||
          textIncludesSearchString(product.category)
      );
    }

    return this.productsList;
  }

  @computed
  get productsCount() {
    return this.filteredProductsList.length;
  }

  @computed
  get upgradeToAdvancedContractProductsList() {
    return this.productsList.filter((product) => {
      return (
        [ProductCategory.OTHER, ProductCategory.COMBO].includes(
          product.category
        ) &&
        product.afterPurchase.otherKey &&
        product.afterPurchase.otherKey === "upgrade-to-advanced-contract"
      );
    });
  }

  save = (data) => {
    const { token, quantity, ...rest } = data;
    return new Promise((resolve, reject) => {
      const newProductRef = productsRef.push(rest);
      if (newProductRef) {
        resolve(newProductRef.key);
      } else {
        reject("failed creating new product");
      }
    });
  };

  update = (data) => {
    const { token, quantity, ...rest } = data;
    return productsRef.child(token).update(rest);
  };

  delete = (productToken) => {
    return productsRef.child(productToken).remove();
  };

  @action
  toggleProductsListModal = (selectedPanel = null) => {
    this.isOpenProductsListModal = !this.isOpenProductsListModal;
    this.changeProductListModalPanel(selectedPanel);
  };

  @action
  changeProductListModalPanel = (selectedPanel) => {
    this.productListModalSelectedPanel =
      this.productListModalSelectedPanel === selectedPanel
        ? null
        : selectedPanel;
  };

  getComboSuggestionsForCategories = (categories) => {
    if (categories.includes("combo")) return [];
    const result = this.productsList.filter(($0) => {
      const matchComboCategory = $0.category === ProductCategory.COMBO;
      let matchCategories = true;
      categories.forEach((category) => {
        if ($0.afterPurchase[category] < 1) {
          matchCategories = false;
        }
      });
      return matchComboCategory && matchCategories;
    });

    return result.slice(0, 3);
  };
}

export default ProductsStore;
