// @ts-nocheck
import { Component, Fragment } from "react";
import { observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import ContractUsagesLimitWidget from "../components/Widgets/ContractUsagesLimitWidget";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { GridContainer, GridItem } from "../components/Grid";

import OrdersListContainer from "./financialPage/OrdersListContainer";

// Style
const styles = {};

@observer
class FinancialPage extends Component {
  render() {
    return (
      <Fragment>
        <PageTitle id="Menu@financial">Financeiro</PageTitle>
        <GridContainer>
          <GridItem xs={12}>
            <ContractUsagesLimitWidget />
          </GridItem>
          <GridItem xs={12} id="orders">
            <OrdersListContainer />
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(FinancialPage),
  pageAllowedRule: "affiliate",
});
