// @ts-nocheck


import { InputText, InputSelect } from "../../Form";
import { GridContainer, GridItem } from "../../Grid";

import Translate from "../../I18n/Translate";

const ConfigurationForm = ({
  networkType,
  formIsDisabled,
  formWasSubmit,
  isValid,
  bandwidth,
  wifiName,
  wifiPassword,
  authRedirect,
  onChangeInputText,
  onChangeSelectInput,
}) => (
  <GridContainer>
    <GridItem xs={12}>
      <InputSelect
        withPortal
        label={
          <Translate id="Connectivity@bandwidthLabel">
            Qual a porcentagem da sua Internet você quer compartilhar?
          </Translate>
        }
        name="bandwidth"
        value={bandwidth}
        disabled={formIsDisabled}
        options={[
          { label: "100%", value: "100" },
          { label: "80%", value: "80" },
          { label: "60%", value: "60" },
          { label: "40%", value: "40" },
          { label: "20%", value: "20" },
        ]}
        onChange={onChangeSelectInput}
      />
      {networkType !== "enterprise" && (
        <InputText
          label={
            <Translate id="Connectivity@wifiNameLabel">Nome da Wi-Fi</Translate>
          }
          name="wifiName"
          isRequired
          disabled={formIsDisabled}
          hasError={formWasSubmit && !isValid.wifiName}
          hasSuccess={formWasSubmit && isValid.wifiName}
          value={wifiName}
          onChange={onChangeInputText}
          minLength={4}
          maxLength={32}
          helpText={
            <Translate id="Connectivity@wifiNameHint">
              Mínimo 4 e máximo 32 caracteres. Não utilize acentos.
            </Translate>
          }
        />
      )}
      {networkType === "openAuth" && (
        <InputText
          label={
            <Translate id="Connectivity@wifiPasswordLabel">
              Senha da Wi-Fi
            </Translate>
          }
          name="wifiPassword"
          inputProps={{
            autoComplete: "off",
          }}
          disabled={formIsDisabled}
          value={wifiPassword}
          onChange={onChangeInputText}
          minLength={8}
          maxLength={64}
          helpText={
            <Translate id="Connectivity@wifiPasswordHint">
              Mínimo de 8 e máximo 64 caracteres. Não utilize acentos.
            </Translate>
          }
        />
      )}
      {["smartAuth", "enterprise"].includes(networkType) && (
        <InputText
          label={
            <Translate id="Connectivity@wifiRedirectLabel">
              O visitante será redirecionado para este site
            </Translate>
          }
          name="authRedirect"
          isRequired
          disabled={formIsDisabled}
          hasError={formWasSubmit && !isValid.authRedirect}
          hasSuccess={formWasSubmit && isValid.authRedirect}
          value={authRedirect}
          onChange={onChangeInputText}
        />
      )}
    </GridItem>
  </GridContainer>
);

export default ConfigurationForm;
