// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import SetSinglePlaylistContainer from "./informativeSetPlaylistPage/SetSinglePlaylistContainer";
import ResourcePageContainer from "../components/Resources/ResourcePageContainer";

@inject("contractStore", "playlistsStore")
@observer
class InformativeSetPlaylistPage extends Component {
  render() {
    return (
      <Fragment>
        <PageTitle id="Menu@informativePlaylist">Programação</PageTitle>
        {this.props.contractStore.selectedContract.resources.playlistSchedule ? (
          <ResourcePageContainer
            appMainStore={this.props.playlistsStore}
            resourceType="playlist"
          />
        ) : (
          <SetSinglePlaylistContainer />
        )}
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: InformativeSetPlaylistPage,
  pageAllowedRule: "informative",
});
