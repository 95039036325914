import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import { FC } from "react";

import { EQueueManagementLayout } from "../../../Model/QueueManagementControlModel";
import { getOpacityFromAlpha } from "./QueueUtils";
import QueueValueView from "./QueueValueView";

import { LOGIN_PAGE_BG_IMAGE } from "../../../utils/EnvUtils";

// styles
const useStyles = makeStyles({
  container: {
    backgroundColor: "#000",
    backgroundImage: `url(${LOGIN_PAGE_BG_IMAGE})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    fontSize: "1.2em",
    border: "4px solid #000",
    width: 512,
    height: 288,
    transition: "all 0.3s ease-in",
  },
  verticalContainer: {
    width: 288,
    height: 512,
  },
  innerContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    transition: "all 0.3s ease-in",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

interface IQueueControlScreenPreviewProps {
  value: string;
  layout: EQueueManagementLayout;
  alpha: string;
  backgroundColor: string;
  fontColor: string;
  isVertical: boolean;
}

const QueueControlScreenPreview: FC<IQueueControlScreenPreviewProps> = ({
  value,
  layout,
  alpha,
  backgroundColor,
  fontColor,
  isVertical,
}) => {
  const classes = useStyles();
  const opacity = getOpacityFromAlpha(alpha);

  const stylesForLayout = () => {
    switch (layout) {
      case EQueueManagementLayout.fullscreen:
        return {};
      case EQueueManagementLayout.left:
        return {
          marginLeft: 0,
          marginRight: "50%",
          marginTop: 0,
          marginBottom: 0,
        };
      case EQueueManagementLayout.right:
        return {
          marginLeft: "50%",
          marginRight: 0,
          marginTop: 0,
          marginBottom: 0,
        };
      case EQueueManagementLayout.top:
        if (isVertical) {
          return {
            height: "50%",
          };
        }
        return {
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          marginBottom: "25%",
        };
      case EQueueManagementLayout.topLeft:
        return {
          marginLeft: 0,
          marginRight: "50%",
          marginTop: 0,
          marginBottom: "25%",
        };
      case EQueueManagementLayout.topRight:
        return {
          marginLeft: "50%",
          marginRight: 0,
          marginTop: 0,
          marginBottom: "25%",
        };
      case EQueueManagementLayout.centerLeft:
        return {
          marginLeft: 0,
          marginRight: "50%",
          marginTop: "15%",
          marginBottom: "15%",
        };
      case EQueueManagementLayout.centerRight:
        return {
          marginLeft: "50%",
          marginRight: 0,
          marginTop: "15%",
          marginBottom: "15%",
        };
      case EQueueManagementLayout.bottom:
        if (isVertical) {
          return {
            height: "50%",
            top: "initial",
          };
        }
        return {
          marginLeft: 0,
          marginRight: 0,
          marginTop: "25%",
          marginBottom: 0,
        };
      case EQueueManagementLayout.bottomLeft:
        return {
          marginLeft: 0,
          marginRight: "50%",
          marginTop: "25%",
          marginBottom: 0,
        };
      case EQueueManagementLayout.bottomRight:
        return {
          marginLeft: "50%",
          marginRight: 0,
          marginTop: "25%",
          marginBottom: 0,
        };
      case EQueueManagementLayout.top_center:
        return {
          marginLeft: "25%",
          marginRight: "25%",
          marginTop: 0,
          marginBottom: "25%",
        };
      case EQueueManagementLayout.bottomCenter:
        return {
          marginLeft: "25%",
          marginRight: "25%",
          marginTop: "25%",
          marginBottom: 0,
        };

      default:
        if (isVertical) {
          return {
            marginLeft: 0,
            marginRight: 0,
            marginTop: "50%",
            marginBottom: "50%",
          };
        }
        return {
          marginLeft: "25%",
          marginRight: "25%",
          marginTop: "15%",
          marginBottom: "15%",
        };
    }
  };

  const isFullScreenLayout = layout === EQueueManagementLayout.fullscreen;

  const lineStyle = {
    transition: "all 0.3s ease-in",
    margin: isFullScreenLayout ? "20px 0" : "10px 0",
  };

  return (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.verticalContainer]: isVertical,
      })}
    >
      <div
        className={classes.innerContainer}
        style={{
          background: backgroundColor,
          color: fontColor,
          opacity: opacity / 100,
          fontSize: isFullScreenLayout ? "2em" : "1.1em",
          ...stylesForLayout(),
        }}
      >
        <QueueValueView
          value={
            value.replace("||", "").length > 0
              ? value
              : "Lorem ipsum dolor||1234"
          }
          ignoreContainer
          lineStyle={lineStyle}
        />
      </div>
    </div>
  );
};

export default QueueControlScreenPreview;
