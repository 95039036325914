// @ts-nocheck
import { Component, Fragment } from "react";
import ReactTable from "react-table";
import withStyles from "@material-ui/core/styles/withStyles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";
import TableActionButton from "./TableActionButton";

// Translate
import {
  PreviousString,
  NextString,
  LoadingString,
  NothingFoundString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Style
const styles = (theme) => ({
  footer: {
    marginTop: 16,
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "start",
    },
  },
  pageText: {
    margin: "0 20px",
  },
});

export const createIconColumn = ({ icon, accessor, sortable = false }) => ({
  Header: <i className={`fas fa-${icon}`} />,
  accessor,
  sortable,
  width: 60,
  getHeaderProps: () => {
    return {
      style: {
        textAlign: "center",
      },
    };
  },
  getProps: () => {
    return {
      style: {
        textAlign: "center",
      },
    };
  },
});

class Table extends Component {
  state = { page: 1, pageSize: 10, anchor: null };

  openMenu = (event) => {
    this.setState({
      anchor: event.currentTarget,
    });
  };
  closeMenu = () => {
    this.setState({ anchor: null });
  };

  didChangePagination = ({ page, pageSize }) => {
    if (this.props.didChangePagination) {
      this.props.didChangePagination({
        page,
        pageSize,
      });
    }
  };

  didChangePageSize = (pageSize) => () => {
    this.closeMenu();
    if (this.props.manual) {
      this.didChangePagination({
        page: 1,
        pageSize,
      });
    } else {
      this.setState({ pageSize, page: 1 }, () =>
        this.didChangePagination({
          page: this.state.page,
          pageSize: this.state.pageSize,
        })
      );
    }
  };

  didChangePage = (callback) => {
    if (this.props.manual) {
      this.didChangePagination({
        page: callback(this.props.page),
        pageSize: this.props.pageSize,
      });
    } else {
      this.setState(
        (prevState) => ({ page: callback(prevState.page) }),
        () =>
          this.didChangePagination({
            page: this.state.page,
            pageSize: this.state.pageSize,
          })
      );
    }
  };

  goToFirstPage = () => this.didChangePage((_) => 1);
  previousPage = () => this.didChangePage((currentPage) => currentPage - 1);
  nextPage = () => this.didChangePage((currentPage) => currentPage + 1);
  goToLastPage = (totalPages) => () => this.didChangePage((_) => totalPages);

  renderActionsColumn = (actions) => {
    return {
      Header: <Translate id="Common@actions">Ações</Translate>,
      accessor: "actions",
      sortable: false,
      getHeaderProps: () => {
        return {
          style: {
            textAlign: "right",
          },
        };
      },
      getProps: () => {
        return {
          style: {
            textAlign: "right",
          },
        };
      },
      Cell: ({ value }) => {
        if (!value) return null;
        return (
          <div>
            {actions.map((action, actionIndex) => (
              <TableActionButton
                key={actionIndex}
                {...action}
                onClick={action.onClick(value)}
                style={{ marginLeft: 6 }}
              />
            ))}
          </div>
        );
      },
    };
  };

  renderMenuItem = (value) => {
    const pageSize = this.props.manual
      ? this.props.pageSize
      : this.state.pageSize;
    return (
      <MenuItem
        onClick={this.didChangePageSize(value)}
        selected={value === pageSize}
      >
        {value}
      </MenuItem>
    );
  };

  render() {
    const {
      classes,
      data,
      columns,
      actions,
      hidePagination,
      manual,
      isLoading,
      ...rest
    } = this.props;

    if (columns.length < 1) {
      return null;
    }

    const page = manual ? this.props.page : this.state.page;
    const pageSize = manual ? this.props.pageSize : this.state.pageSize;

    const isFirstPage = page === 1;
    const totalItems = manual ? this.props.totalItems : data.length;
    const totalPages =
      this.props.totalPages || Math.ceil(totalItems / pageSize);
    const isLastPage = page === totalPages;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <ReactTable
            data={
              data.length > 0
                ? data
                : [
                    {
                      [columns[0].accessor]: <NothingFoundString />,
                    },
                  ]
            }
            columns={
              actions
                ? [...columns, this.renderActionsColumn(actions)]
                : columns
            }
            manual={manual}
            loading={isLoading}
            page={page - 1}
            showPagination={false}
            pageSize={
              manual
                ? pageSize
                : data.length < 1
                ? 1
                : data.length > 1 && hidePagination
                ? data.length
                : pageSize
            }
            showPaginationTop={false}
            showPaginationBottom={false}
            showPageSizeOptions={false}
            className="-striped -highlight"
            previousText={<PreviousString />}
            nextText={<NextString />}
            loadingText={<LoadingString />}
            noDataText={<NothingFoundString />}
            rowsText={<Translate id="Table@rowsText">Linhas</Translate>}
            sorted={manual && this.props.sortedBy && [this.props.sortedBy]}
            onSortedChange={manual && this.props.onSortedChange}
            multiSort={false}
            {...rest}
          />
        </GridItem>
        {!hidePagination && (data.length > 10 || manual) && (
          <GridItem xs={12} className={classes.footer}>
            <GridContainer justify="center">
              <GridItem xs={12} sm md className={classes.pagination}>
                {totalPages > 0 && (
                  <Fragment>
                    {totalPages > 2 && (
                      <Button
                        outline={isFirstPage}
                        disabled={isFirstPage}
                        justIcon
                        color="primary"
                        small
                        onClick={this.goToFirstPage}
                      >
                        <i className="fas fa-chevron-double-left" />
                      </Button>
                    )}
                    <Button
                      outline={isFirstPage}
                      disabled={isFirstPage}
                      justIcon
                      color="primary"
                      small
                      onClick={this.previousPage}
                    >
                      <i className="fas fa-chevron-left" />
                    </Button>
                    <p className={classes.pageText}>
                      <Translate id="Table@pageText">Página</Translate> {page}{" "}
                      <Translate id="Table@ofText">de</Translate> {totalPages}
                    </p>
                    <Button
                      outline={isLastPage}
                      disabled={isLastPage}
                      justIcon
                      small
                      color="primary"
                      onClick={this.nextPage}
                    >
                      <i className="fas fa-chevron-right" />
                    </Button>
                    {totalPages > 2 && (
                      <Button
                        outline={isLastPage}
                        disabled={isLastPage}
                        justIcon
                        small
                        color="primary"
                        onClick={this.goToLastPage(totalPages)}
                      >
                        <i className="fas fa-chevron-double-right" />
                      </Button>
                    )}
                  </Fragment>
                )}
              </GridItem>
              <GridItem style={{ textAlign: "center" }}>
                <Button
                  aria-controls="page-size-menu"
                  aria-haspopup="true"
                  small
                  block
                  color="primary"
                  onClick={this.openMenu}
                >
                  <Translate id="Table@itemsPerPage" variables={{ pageSize }}>
                    Itens por página: $pageSize
                  </Translate>
                </Button>
                <Menu
                  id="page-size-menu"
                  keepMounted
                  anchorEl={this.state.anchor}
                  open={Boolean(this.state.anchor)}
                >
                  {this.renderMenuItem(10)}
                  {this.renderMenuItem(20)}
                  {this.renderMenuItem(40)}
                  {this.renderMenuItem(60)}
                  {this.renderMenuItem(80)}
                  {this.renderMenuItem(100)}
                </Menu>
              </GridItem>
            </GridContainer>
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

Table.defaultProps = {
  columns: [],
  data: [],
};

export default withStyles(styles)(Table);
