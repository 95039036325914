// @ts-nocheck
import { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Map from "pigeon-maps";
import Marker from "pigeon-marker";

import { Button } from "../Button";

// styles
const styles = {
  mapContainer: {
    position: "relative",
  },
  zoomButtonsContainer: {
    position: "absolute",
    bottom: 12,
    right: 4,
  },
  zoomButton: {
    backgroundColor: "#fff",
    color: "#666666",
    display: "block",
    margin: 0,
  },
};

class Maps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoom: this.props.zoom,
    };
  }

  plusZoom = () => {
    this.setState({
      zoom: this.state.zoom + 1,
    });
  };
  minusZoom = () => {
    this.setState({
      zoom: this.state.zoom - 1,
    });
  };

  renderZoomButtons = () => {
    if (this.props.hideZoomButtons) {
      return null;
    }
    const { classes } = this.props;
    return (
      <div className={classes.zoomButtonsContainer}>
        <Button
          className={classes.zoomButton}
          justIcon
          small
          style={{ marginBottom: 4 }}
          onClick={this.plusZoom}
        >
          <i className="fas fa-plus" />
        </Button>
        <Button
          className={classes.zoomButton}
          justIcon
          small
          onClick={this.minusZoom}
        >
          <i className="fas fa-minus" />
        </Button>
      </div>
    );
  };

  render() {
    const {
      classes,
      latitude,
      longitude,
      disabled,
      height,
      width,
      markers,
      onChange,
      handleMarkerClick,
    } = this.props;

    const { zoom } = this.state;
    return (
      <div className={classes.mapContainer}>
        <Map
          provider={(x, y, z) => {
            const s = String.fromCharCode(97 + ((x + y + z) % 3));
            return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
          }}
          center={[latitude, longitude]}
          zoom={zoom}
          height={height}
          width={width}
          attribution={false}
          mouseEvents={!disabled}
          touchEvents={!disabled}
          onClick={onChange}
        >
          {markers &&
            markers.map(({ latitude, longitude }, index) => (
              <Marker
                key={`${latitude}-${longitude}`}
                anchor={[latitude, longitude]}
                payload={index}
                onClick={handleMarkerClick}
              />
            ))}
        </Map>
        {this.renderZoomButtons()}
      </div>
    );
  }
}

Maps.defaultProps = {
  zoom: 12,
  disabled: false,
};

export default withStyles(styles)(Maps);
