// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";
import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import withStyles from "@material-ui/core/styles/withStyles";
import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardBody } from "../components/Card";
import { Divider } from "@material-ui/core";
import CopyToClipboard from "../components/CopyToClipboard/CopyToClipboard";

import Tooltip from "../components/Tooltip/Tooltip";

// Translate
import { ToCopyActionString } from "../components/I18n/CommonStrings";
import Translate from "../components/I18n/Translate";

// Style
const styles = {
  p: { fontSize: "1.2em", margin: 16 },
  code: {
    cursor: "pointer",
  },
  divider: { marginBottom: 20 },
};

@inject("authStore", "contractStore", "notificationsStore")
class DevelopersPage extends Component {
  didCopy = () => {
    this.props.notificationsStore.addSnackbarNotification({
      message: (
        <Translate id="Developer@copySuccessMessage">
          Pronto! Agora cole com CTRL + V
        </Translate>
      ),
    });
  };

  render() {
    const { classes, authStore, contractStore } = this.props;
    const { selectedContract } = contractStore;

    const userToken = authStore.currentUser.token;

    return (
      <Fragment>
        <PageTitle id="Menu@developer">Desenvolvedor</PageTitle>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs>
                <CopyToClipboard text={userToken} onCopy={this.didCopy}>
                  <p className={classes.p}>
                    <strong>
                      <Translate id="Developer@tokenLabel">
                        Token do Usuário
                      </Translate>
                      :
                    </strong>{" "}
                    <Tooltip title={<ToCopyActionString />}>
                      <code className={classes.code}>{userToken}</code>
                    </Tooltip>
                  </p>
                </CopyToClipboard>
                <CopyToClipboard
                  text={selectedContract.apiKey}
                  onCopy={this.didCopy}
                >
                  <p className={classes.p}>
                    <strong>
                      <Translate id="Developer@apiKeyLabel">
                        Chave da API
                      </Translate>
                      :
                    </strong>{" "}
                    <Tooltip title={<ToCopyActionString />}>
                      <code className={classes.code}>
                        {selectedContract.apiKey}
                      </code>
                    </Tooltip>
                  </p>
                </CopyToClipboard>
                <CopyToClipboard
                  text={selectedContract.hash}
                  onCopy={this.didCopy}
                >
                  <p className={classes.p}>
                    <strong>
                      <Translate id="Developer@contractHashLabel">
                        Hash do Contrato
                      </Translate>
                      :
                    </strong>{" "}
                    <Tooltip title={<ToCopyActionString />}>
                      <code className={classes.code}>
                        {selectedContract.hash}
                      </code>
                    </Tooltip>
                  </p>
                </CopyToClipboard>
                <Divider className={classes.divider} />
                <p style={{ marginLeft: 16 }}>
                  <Translate id="Developer@documentationHint">
                    Consulte a{" "}
                    <a
                      href="https://github.com/simplyas/API"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontWeight: "bold" }}
                    >
                      documentação completa da API
                    </a>{" "}
                    para saber mais.
                  </Translate>
                </p>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(DevelopersPage),
  pageAllowedRule: "api",
});
