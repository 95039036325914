//@ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";

import LoadingView from "../LoadingView/LoadingView";
import ResourcePageView from "./ResourcePageView";
import ResourceWhatWhereWhen from "./ResourceWhatWhereWhen";
import { GridContainer, GridItem } from "../Grid";
import { Card, CardBody } from "../Card";
import Modal from "../Modal/Modal";

import SetGroupTerminalsToVideoWall from "./SetGroupTerminalsToVideoWall";
import DuplicatePlaylistModal from "./DuplicatePlaylistModal";
import PlaylistConfigurationForm from "../../pages/informativeSetPlaylistPage/PlaylistConfigurationForm";
import ClockConfigurationForm from "../AppForm/ClockConfigurationForm";
import MessageConfigurationForm from "../AppForm/MessageConfigurationForm";
import LogotypeConfigurationForm from "../AppForm/LogotypeConfigurationForm";
import NetworkConfigurationForm from "../AppForm/NetworkConfigurationForm";

import { MediaTypeEnum } from "../../store/MediaLibraryStore/MediaLibraryStore";
import AlfredStore from "../../store/AlfredStore/AlfredStore";
import ContractStore from "../../store/ContractStore/ContractStore";
import PlaylistsStore from "../../store/PlaylistsStore/PlaylistsStore";
import ResourcesStore from "../../store/ResourcesStore/ResourcesStore";
import RoutingStore from "../../store/RoutingStore/RoutingStore";
import NotificationsStore from "../../store/NotificationsStore/NotificationsStore";

// Translate
import Translate from "../I18n/Translate";

import { EAppRoutes } from "../../routes/AppRoutes";

enum EModalType {
  editAppResource = "editAppResource",
  editResourceRule = "editResourceRule",
  didWantToCloneAppResource = "didWantToCloneAppResource",
  cloneAppResource = "cloneAppResource",
  configureVideoWall = "configureVideoWall",
  addAppResource = "addAppResource",
  addResourceRule = "addResourceRule",
}

interface IResourcePageContainerProps {
  appMainStore: any;
  resourceType: "network" | "clock" | "logotype" | "message" | "playlist";
}

interface InjectedProps extends IResourcePageContainerProps {
  alfredStore: AlfredStore;
  contractStore: ContractStore;
  playlistsStore: PlaylistsStore;
  resourcesStore: ResourcesStore;
  routingStore: RoutingStore;
  notificationsStore: NotificationsStore;
}

interface IResourcePageContainerState {
  isOpenModal: boolean;
  selectedAppResourceTokenToEdit: string | null;
  selectedResourceRuleToken: string | null;
  appResourceToClone: any | null;
  modalType: EModalType | null;
  selectedContracts: any[];
  isCloningToMultipleContracts: boolean;
}

@inject(
  "alfredStore",
  "contractStore",
  "playlistsStore",
  "resourcesStore",
  "routingStore",
  "notificationsStore"
)
@observer
class ResourcePageContainer extends Component<
  IResourcePageContainerProps,
  IResourcePageContainerState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  _isMounted: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: false,
      selectedAppResourceTokenToEdit: null,
      selectedResourceRuleToken: null,
      appResourceToClone: null,
      modalType: null,
      selectedContracts: [],
      isCloningToMultipleContracts: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.injected.resourcesStore.getResourceRulesList(this.props.resourceType);

    this.props.appMainStore.getDataList();

    this.props.appMainStore.setSearchString("");
    this.injected.resourcesStore.setSearchString("");

    reaction(
      () =>
        this.injected.routingStore.pathname.includes(`${this.baseUrl}/`) &&
        this.selectedToken !== null,
      (hasSelectedToken: boolean) => {
        if (hasSelectedToken) {
          this.didSelectAppResourceToEdit(this.selectedToken);
        }
      },
      {
        fireImmediately: true,
      }
    );

    this.injected.alfredStore.addActions({
      id: "resourcePageActions",
      label: "Agendamento de recursos",
      options: [
        {
          id: `addResource`,
          title: "Novo recurso",
          subtitle: "Adicionar novo recurso",
          icon: "plus",
          callback: this.didWantToAddAppResource,
        },
        {
          id: `addResource`,
          title: "Nova regra",
          subtitle: "Adicionar nova regra",
          icon: "plus",
          callback: this.didWantToAddResourceRule,
        },
      ],
    });
  }

  componentDidUpdate(
    prevProps: IResourcePageContainerProps,
    prevState: IResourcePageContainerState
  ) {
    const { selectedAppResourceTokenToEdit: appResourceToken } = this.state;
    if (
      (this.state.modalType === EModalType.editAppResource &&
        prevState.selectedAppResourceTokenToEdit === appResourceToken) ||
      this.injected.routingStore.pathname.includes(appResourceToken)
    ) {
      return;
    }
    let url = this.baseUrl;
    if (appResourceToken) {
      url += "/" + appResourceToken;
    }
    this.injected.routingStore.push(url);
  }

  componentWillUnmount() {
    this._isMounted = false;

    this.injected.alfredStore.removeActions("resourcePageActions");
  }

  get baseUrl(): string {
    switch (this.props.resourceType) {
      case "playlist":
        return EAppRoutes.INFORMATIVE_PLAYLISTS;
      case "clock":
        return EAppRoutes.INFORMATIVE_CLOCKS;
      case "message":
        return EAppRoutes.INFORMATIVE_MESSAGES;
      case "logotype":
        return EAppRoutes.INFORMATIVE_LOGOTYPES;

      case "network":
      default:
        return EAppRoutes.CONNECTIVITY_SHARE;
    }
  }

  get selectedToken(): string {
    const { pathname } = this.injected.routingStore;
    if (pathname.includes(`${this.baseUrl}/`)) {
      const [token] = pathname.replace(`${this.baseUrl}/`, "").split("/");
      if (token && token.length > 0) {
        return token;
      }
    }
    return null;
  }

  didSelectAppResourceToEdit = (appResourceToken: string) => {
    if (!this._isMounted) return;
    this.setState({
      isOpenModal: true,
      selectedAppResourceTokenToEdit: appResourceToken,
      modalType: EModalType.editAppResource,
    });
  };

  didSelectResourceRuleToEdit = (resourceRuleToken: string) => {
    if (!this._isMounted) return;
    this.setState({
      isOpenModal: true,
      selectedResourceRuleToken: resourceRuleToken,
      modalType: EModalType.editResourceRule,
    });
  };

  didWantToCloneAppResource = (appResourceToClone) => {
    if (!this._isMounted) return;

    if (this.injected.contractStore.contractsList.length > 1) {
      this.setState({
        isOpenModal: true,
        appResourceToClone,
        modalType: EModalType.didWantToCloneAppResource,
      });
    } else {
      this.setState({
        isOpenModal: true,
        modalType: EModalType.cloneAppResource,
        appResourceToClone,
        selectedContracts: [],
      });
    }
  };

  cloneAppResourceOn = (selectedContracts) => () => {
    if (!this._isMounted) return;
    if (selectedContracts.length < 1) {
      this.setState({
        isOpenModal: true,
        modalType: EModalType.cloneAppResource,
        selectedContracts,
      });
    } else {
      this.setState(
        {
          isCloningToMultipleContracts: true,
        },
        async () => {
          const playlist = this.state.appResourceToClone;
          try {
            await this.injected.playlistsStore.submitToMultipleContracts(
              {
                ...playlist,
                label: `${playlist.label} (COPY)`,
                files: playlist.mediasList
                  .filter(($0) => $0.type !== MediaTypeEnum.UPLOAD)
                  .map(({ token }) => token),
              },
              selectedContracts
            );
          } catch (error) {
            window.debugError("error in submitToMultipleContracts", error);
          } finally {
            this.didCloseModal();
          }
        }
      );
    }
  };

  didSelectResourceRuleToConfigureVideoWall = (resourceRuleToken) => {
    if (!this._isMounted) return;
    this.setState({
      isOpenModal: true,
      selectedResourceRuleToken: resourceRuleToken,
      modalType: EModalType.configureVideoWall,
    });
  };

  didWantToAddAppResource = () => {
    if (!this._isMounted) return;
    this.setState({
      isOpenModal: true,
      modalType: EModalType.addAppResource,
    });
  };

  didWantToAddResourceRule = () => {
    if (!this._isMounted) return;
    this.setState({
      isOpenModal: true,
      modalType: EModalType.addResourceRule,
    });
  };

  didCloseModal = () => {
    if (!this._isMounted) return;
    this.setState(
      {
        isOpenModal: false,
      },
      () => {
        setTimeout(() => {
          if (!this._isMounted) return;
          this.setState({
            isCloningToMultipleContracts: false,
            selectedAppResourceTokenToEdit: null,
            selectedResourceRuleToken: null,
            appResourceToClone: null,
            modalType: null,
            selectedContracts: [],
          });
        }, 200);
      }
    );
  };

  didSaveResourceForm = () => {
    this.didCloseModal();
  };

  didCancelResourceForm = () => {
    this.didCloseModal();
  };

  didDeleteResourceForm = () => {
    this.didCloseModal();
  };

  didSetTerminalsListSequence = (updatedResourceRule) => {
    this.injected.resourcesStore.update(updatedResourceRule).then(() => {
      this.injected.notificationsStore.addSnackbarNotification({
        message: (
          <Translate id="Playlist@updatedVideoWallMessage">
            Atualizou Video wall!
          </Translate>
        ),
        timeout: 2000,
        color: "success",
      });
      this.didCloseModal();
    });
  };

  didReorderResourceRulesOfGroup = (groupToken, reorderedList) => {
    this.injected.resourcesStore.reorder({
      resourceType: this.props.resourceType,
      resourcesList: reorderedList.map((item, index) => ({
        token: item.token,
        order: index,
      })),
    });
  };

  getAppResourceForm = () => {
    const { resourceType, appMainStore } = this.props;
    const { selectedAppResourceTokenToEdit } = this.state;

    if (resourceType === "playlist") {
      const isCloning = this.state.appResourceToClone !== null;
      return (
        <PlaylistConfigurationForm
          // @ts-ignore
          playlist={
            isCloning
              ? this.state.appResourceToClone
              : appMainStore.getWithToken(selectedAppResourceTokenToEdit)
          }
          isCloning={isCloning}
          didSave={this.didSaveResourceForm}
          didDelete={this.didDeleteResourceForm}
          didClose={this.didCloseModal}
        />
      );
    }
    if (resourceType === "clock") {
      return (
        <ClockConfigurationForm
          // @ts-ignore
          clock={appMainStore.getWithToken(selectedAppResourceTokenToEdit)}
          didSave={this.didSaveResourceForm}
          didDelete={this.didDeleteResourceForm}
        />
      );
    }
    if (resourceType === "message") {
      return (
        <MessageConfigurationForm
          // @ts-ignore
          message={appMainStore.getWithToken(selectedAppResourceTokenToEdit)}
          didSave={this.didSaveResourceForm}
          didDelete={this.didDeleteResourceForm}
        />
      );
    }
    if (resourceType === "logotype") {
      return (
        <LogotypeConfigurationForm
          // @ts-ignore
          logotype={appMainStore.getWithToken(selectedAppResourceTokenToEdit)}
          didSave={this.didSaveResourceForm}
          didDelete={this.didDeleteResourceForm}
        />
      );
    }
    if (resourceType === "network") {
      return (
        <NetworkConfigurationForm
          // @ts-ignore
          network={appMainStore.getWithToken(selectedAppResourceTokenToEdit)}
          didSave={this.didSaveResourceForm}
          didDelete={this.didDeleteResourceForm}
          didClose={this.didCloseModal}
        />
      );
    }
    return null;
  };

  getModalWidth = () => {
    const { resourceType } = this.props;
    const { modalType } = this.state;

    if (
      [EModalType.addResourceRule, EModalType.editResourceRule].includes(
        modalType
      ) ||
      ["playlist", "network"].includes(resourceType)
    ) {
      return "lg";
    }

    if (["clock", "logotype"].includes(resourceType)) {
      return "sm";
    }

    return "md";
  };

  getModalBody = () => {
    const { resourceType, appMainStore } = this.props;
    const { modalType } = this.state;
    if (
      [
        EModalType.addAppResource,
        EModalType.editAppResource,
        EModalType.cloneAppResource,
      ].includes(modalType)
    ) {
      return this.getAppResourceForm();
    } else if (
      [EModalType.addResourceRule, EModalType.editResourceRule].includes(
        modalType
      )
    ) {
      const { selectedResourceRuleToken } = this.state;

      const selectedResourceRule = this.injected.resourcesStore.getWithToken(
        selectedResourceRuleToken
      );

      const getWhat = () => {
        const findItemWithToken = (token) =>
          appMainStore.dataList.find(
            (resourceItem) => resourceItem.token === token
          );
        if (selectedResourceRule) {
          if (resourceType === "playlist") {
            let result = [
              findItemWithToken(selectedResourceRule.resourceToken),
            ];
            if (selectedResourceRule.secondResourceToken) {
              result.push(
                findItemWithToken(selectedResourceRule.secondResourceToken)
              );
            }

            return result;
          }
          return findItemWithToken(selectedResourceRule.resourceToken);
        }
        return null;
      };

      return (
        <ResourceWhatWhereWhen
          // @ts-ignore
          originalResourceRule={selectedResourceRule}
          what={getWhat()}
          whatList={appMainStore.dataList}
          where={selectedResourceRule ? selectedResourceRule.group : null}
          when={selectedResourceRule ? selectedResourceRule.schedule[0] : null}
          isActive={selectedResourceRule ? selectedResourceRule.isActive : true}
          terminalsList={
            selectedResourceRule ? selectedResourceRule.terminalsList : null
          }
          resourceType={resourceType}
          didSubmit={this.didCloseModal}
          didDelete={this.didCloseModal}
        />
      );
    }
    return null;
  };

  renderConfigureVideoWallModal = (resourceRulesList) => {
    if (
      this.props.resourceType === "playlist" &&
      this.state.modalType === EModalType.configureVideoWall
    ) {
      const { selectedResourceRuleToken, isOpenModal } = this.state;

      let selectedResourceRule = null;

      Object.keys(resourceRulesList).forEach((groupKey) => {
        if (!selectedResourceRule) {
          const result = resourceRulesList[groupKey].filter(
            (resourceRule) => resourceRule.token === selectedResourceRuleToken
          );
          if (result.length > 0) {
            selectedResourceRule = result[0];
          }
        }
      });

      return (
        <Modal isOpen={isOpenModal} didCloseModal={this.didCloseModal}>
          <SetGroupTerminalsToVideoWall
            // @ts-ignore
            resourceRule={selectedResourceRule}
            playlist={selectedResourceRule.playlist}
            didSetTerminalsListSequence={this.didSetTerminalsListSequence}
            didSelectAppResourceToEdit={this.didSelectAppResourceToEdit}
            didSelectResourceRuleToEdit={this.didSelectResourceRuleToEdit}
          />
        </Modal>
      );
    }
    return null;
  };

  renderDuplicatePlaylistModal = () => {
    if (this.state.modalType !== EModalType.didWantToCloneAppResource)
      return null;
    return (
      // @ts-ignore
      <DuplicatePlaylistModal
        cloneAppResourceOn={this.cloneAppResourceOn}
        isLoading={this.state.isCloningToMultipleContracts}
        didCloseModal={this.didCloseModal}
      />
    );
  };

  render() {
    const { appMainStore, resourceType } = this.props;
    const { isOpenModal, modalType } = this.state;

    if (this.injected.resourcesStore.isFetching || appMainStore.isFetching) {
      return <LoadingView />;
    }

    if (appMainStore.dataList.length < 1) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>{this.getAppResourceForm()}</CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    }

    if (
      appMainStore.dataList.length > 0 &&
      this.injected.resourcesStore.resourceRulesList.length < 1
    ) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <ResourceWhatWhereWhen
                  // @ts-ignore
                  isFirstExperience={true}
                  what={appMainStore.dataList[0]}
                  whatList={appMainStore.dataList}
                  resourceType={resourceType}
                  isActive={true}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    }

    let resourceRulesList = this.injected.resourcesStore
      .resourcesRuleListByGroup;

    if (resourceType === "playlist") {
      Object.keys(resourceRulesList).forEach((groupKey) => {
        resourceRulesList[groupKey] = resourceRulesList[groupKey].map(
          (resourceRule) => ({
            ...resourceRule,
            playlist: appMainStore.getWithToken(resourceRule.resourceToken),
          })
        );
      });
    }

    return (
      <Fragment>
        {this.renderDuplicatePlaylistModal()}
        {modalType &&
          modalType !== EModalType.configureVideoWall &&
          modalType !== EModalType.didWantToCloneAppResource && (
            <Modal
              isOpen={isOpenModal}
              didCloseModal={this.didCloseModal}
              fullScreen={
                ["playlist", "network"].includes(resourceType) &&
                [
                  EModalType.addAppResource,
                  EModalType.editAppResource,
                  EModalType.cloneAppResource,
                ].includes(modalType)
              }
              maxWidth={this.getModalWidth()}
            >
              {isOpenModal && this.getModalBody()}
            </Modal>
          )}
        {this.renderConfigureVideoWallModal(resourceRulesList)}
        <ResourcePageView
          // @ts-ignore
          appResourcesList={appMainStore.dataList}
          resourceRulesList={resourceRulesList}
          resourceType={resourceType}
          didSelectAppResourceToEdit={this.didSelectAppResourceToEdit}
          didWantToCloneAppResource={this.didWantToCloneAppResource}
          didSelectResourceRuleToEdit={this.didSelectResourceRuleToEdit}
          didSelectResourceRuleToConfigureVideoWall={
            this.didSelectResourceRuleToConfigureVideoWall
          }
          didWantToAddAppResource={this.didWantToAddAppResource}
          didWantToAddResourceRule={this.didWantToAddResourceRule}
          appMainStore={appMainStore}
          didReorderResourceRulesOfGroup={
            this.didReorderResourceRulesOfGroup
          }
        />
      </Fragment>
    );
  }
}

export default ResourcePageContainer;
