// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";
// Components
import { InputText } from "../Form";
import { Button } from "../Button";
import { GridContainer, GridItem } from "../Grid";
import { withLocalized } from "../HOC";

import Validate, { ValidationType } from "../Form/Validate";

// Translate
import Translate from "../I18n/Translate";
import {
  UpdateString,
  SaveString,
  FormErrorString,
} from "../I18n/CommonStrings";

// Icon
import { SaveIcon } from "../Icon";

@withLocalized
@inject("i18nStore", "notificationsStore")
class I18nForm extends Component {
  constructor(props) {
    super(props);

    if (props.stringText) {
      this.state = this.getStateFromExistingStringText(props.stringText);
    } else {
      this.state = this.getDefaultState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.stringText !== this.props.stringText) {
      this.updateForm();
    }
  }

  updateForm = () => {
    if (this.props.stringText) {
      this.setState({
        ...this.getStateFromExistingStringText(this.props.stringText),
      });
    } else {
      this.setState({ ...this.getDefaultState() });
    }
  };

  getDefaultState = () => {
    return {
      key: "",
      pt: "",
      en: "",
      isPlural: false,
      isWaitingForm: false,
      formHasError: false,
      formWasSubmit: false,
      errorMessage: "",
      isValid: {
        key: false,
      },
    };
  };

  defaultPluralObject = () => ({
    one: "",
    other: "",
    zero: "",
  });

  getStateFromExistingStringText = (stringText) => {
    return {
      ...this.getDefaultState(),
      key: stringText.id,
      pt: stringText.pt
        ? stringText.isPlural
          ? { ...this.defaultPluralObject(), ...stringText.pt }
          : stringText.pt
        : stringText.isPlural
        ? this.defaultPluralObject()
        : "",
      en: stringText.en
        ? stringText.isPlural
          ? { ...this.defaultPluralObject(), ...stringText.en }
          : stringText.en
        : stringText.isPlural
        ? this.defaultPluralObject()
        : "",
      isPlural: stringText.isPlural !== undefined ? stringText.isPlural : false,
      isValid: {
        key: Validate(stringText.key, ValidationType.REQUIRED),
      },
    };
  };

  onChangeKeyInput = ({ target }) => {
    const { value } = target;

    this.setState({
      key: value,
      isValid: {
        ...this.state.isValid,
        key: Validate(value, ValidationType.REQUIRED),
      },
    });
  };

  onChangeInputText = ({ target }) => {
    const { name, value } = target;

    this.setState({
      [name]: value,
    });
  };

  onChangePluralInputText = ({ target }) => {
    const { name, value } = target;
    const nameObject = name.split("@");

    this.setState({
      [nameObject[0]]: {
        ...this.state[nameObject[0]],
        [nameObject[1]]: value,
      },
    });
  };

  canSubmitForm = () => {
    const { isValid } = this.state;
    return isValid.key;
  };

  getFormDataToSubmit = ({ key, pt, en, isPlural }) => ({
    key,
    pt: isPlural ? pt : pt.trim(),
    en: isPlural ? en : en.trim(),
    isPlural,
  });

  submitForm = () => {
    this.props.i18nStore
      .save(this.getFormDataToSubmit(this.state))
      .then(() => {
        this.submitHasSuccess();
      })
      .catch(() => {
        this.submitHasError();
      });
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.canSubmitForm()) {
      this.setState(
        { isWaitingForm: true, formHasError: false, errorMessage: "" },
        this.submitForm
      );
    } else {
      this.setState(
        {
          formWasSubmit: true,
          formHasError: true,
          errorMessage: <FormErrorString />,
        },
        () => {
          this.props.notificationsStore.addFormHasErrorNotification();
        }
      );
    }
  };

  submitHasSuccess = () => {
    this.props.didSave();
  };

  submitHasError = () => {
    this.setState({
      formHasError: true,
      isWaitingForm: false,
    });
  };

  renderPluralLanguageInputs = (languageId, languageString, textObject) => (
    <GridItem xs={12} sm={6}>
      <h4>{languageString}</h4>
      <InputText
        label={<Translate id="I18n@zero">Zero</Translate>}
        name={`${languageId}@zero`}
        value={textObject.zero}
        onChange={this.onChangePluralInputText}
      />
      <InputText
        label={<Translate id="I18n@one">Um</Translate>}
        name={`${languageId}@one`}
        value={textObject.one}
        onChange={this.onChangePluralInputText}
      />
      <InputText
        label={<Translate id="I18n@other">Outros</Translate>}
        name={`${languageId}@other`}
        value={textObject.other}
        onChange={this.onChangePluralInputText}
      />
    </GridItem>
  );

  render() {
    const isEditing = this.props.stringText !== null;
    const { localizedString } = this.props;
    const {
      key,
      pt,
      en,
      isPlural,
      isWaitingForm,
      formWasSubmit,
      isValid,
    } = this.state;

    return (
      <Fragment>
        <InputText
          label={<Translate id="I18n@key">Chave</Translate>}
          name="key"
          hasError={formWasSubmit && !isValid.key}
          hasSuccess={formWasSubmit && isValid.key}
          autoFocus={!isEditing}
          value={key}
          disabled={isEditing}
          onChange={this.onChangeKeyInput}
        />
        {isPlural ? (
          <GridContainer>
            {this.renderPluralLanguageInputs(
              "pt",
              localizedString({
                id: "I18n@portuguese",
                defaultString: "Português",
              }),
              pt
            )}
            {this.renderPluralLanguageInputs(
              "en",
              localizedString({ id: "I18n@english", defaultString: "Inglês" }),
              en
            )}
          </GridContainer>
        ) : (
          <Fragment>
            <InputText
              label={<Translate id="I18n@portuguese">Português</Translate>}
              name="pt"
              value={pt}
              autoFocus={isEditing}
              onChange={this.onChangeInputText}
            />
            <InputText
              label={<Translate id="I18n@english">Inglês</Translate>}
              name="en"
              value={en}
              onChange={this.onChangeInputText}
            />
          </Fragment>
        )}

        <GridContainer>
          <GridItem xs>
            <Button
              color="primary"
              block
              isLoading={isWaitingForm}
              onClick={this.onSubmit}
            >
              {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
            </Button>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default I18nForm;
