export enum EAnalyticsEventCategory {
  user = "User",
  chat = "Chat",
  navigation = "Navigation",
  select = "Select",
  add = "Add",
  edit = "Edit",
  delete = "Delete",
  update = "Update",
  search = "Search",
}

export interface IAnalyticsEvent {
  category: EAnalyticsEventCategory;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

export interface IAnalyticsService {
  setUser(userId: string): void;

  sendEvent(event: IAnalyticsEvent): void;
}
