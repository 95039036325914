// @ts-nocheck
import { Component } from "react";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import uniq from "lodash/uniq";
import withStyles from "@material-ui/core/styles/withStyles";
import { Divider, CircularProgress } from "@material-ui/core";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

// @material-ui/core components
import Modal from "../components/Modal/Modal";
import Price from "../components/Price/Price";
import PriceContainer from "../components/Price/PriceContainer";

import ComboSuggestionItem from "./checkouPage/ComboSuggestionItem";

// core components
import LoadingView from "../components/LoadingView/LoadingView";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import { Button } from "../components/Button";

// Translate
import Translate from "../components/I18n/Translate";

// Utils
import { textNewLineToBr } from "../utils/StringUtils";

// Style
import { primaryColor } from "../assets/jss/app-js-styles";
const styles = {
  productContainer: {
    paddingTop: 8,
    paddingBottom: 8,
    position: "relative",
    paddingLeft: 46,
  },
  productRemoveButton: {
    left: 15,
    margin: 0,
    position: "absolute",
  },
  productTitle: {
    fontSize: "1.2em",
    fontWeight: 500,
    marginBottom: 0,
  },
  productDescription: {
    marginBottom: 0,
  },
  summaryTitleContainer: {
    marginBottom: 20,
  },
  summaryTitle: {
    marginBottom: 0,
  },
  summaryTotalPriceLabel: {
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  summaryPrice: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: primaryColor,
    textAlign: "right",
  },
  summaryButtonContainer: {
    marginTop: 10,
  },
};

@inject("ordersStore", "productsStore", "notificationsStore")
@observer
class CheckoutPage extends Component {
  state = {
    isRedirecting: false,
    shouldRedirectToFinancial: false,
  };

  componentDidMount() {
    const shouldRedirectToFinancial =
      window.sessionStorage.getItem("shouldRedirectToFinancial") === "true";
    this.setState(
      {
        shouldRedirectToFinancial,
      },
      () => {
        if (shouldRedirectToFinancial) {
          window.sessionStorage.removeItem("shouldRedirectToFinancial");
        }
      }
    );

    const urlSearch = this.props.location.search;

    if (urlSearch.includes("?order=")) {
      const orderId = urlSearch.replace("?order=", "");
      this.props.ordersStore.setAbandonedCartOrder(orderId);
    }
  }

  submitCheckout = () => {
    this.setState({ isRedirecting: true }, () => {
      this.props.ordersStore.submitCheckout().catch((error) => {
        this.setState({ isRedirecting: false }, () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: (
              <Translate id="Error@submitCheckoutErrorMessage">
                Ocorreu um erro inesperado. Se o problema persistir solicite
                suporte através do chat.
              </Translate>
            ),
            color: "danger",
          });
        });
      });
    });
  };

  render() {
    if (this.state.shouldRedirectToFinancial) {
      return <Redirect to="/financial#orders" />;
    }

    const { classes, ordersStore, productsStore } = this.props;
    const { selectedOrder } = ordersStore;

    if (!selectedOrder) {
      return <LoadingView />;
    }

    if (selectedOrder.products && selectedOrder.products.length > 0) {
      const categories = uniq(selectedOrder.products.map(($0) => $0.category));
      const comboSuggestions = productsStore.getComboSuggestionsForCategories(
        categories
      );

      return (
        <div>
          <Modal
            isOpen={this.state.isRedirecting}
            hideCloseButton
            maxWidth={"sm"}
          >
            <GridContainer justify="center">
              <GridItem xs style={{ textAlign: "center" }}>
                <p>
                  <Translate id="Checkout@redirectingMessage">
                    Aguarde, redirecionando para a plataforma de pagamento...
                  </Translate>
                </p>
                <CircularProgress size={30} variant="indeterminate" />
              </GridItem>
            </GridContainer>
          </Modal>
          <PageTitle id="Menu@checkout">Carrinho</PageTitle>
          <GridContainer>
            <GridItem xs={12} sm={7} md={8}>
              <GridContainer>
                <GridItem xs={12} style={{ marginBottom: 30 }}>
                  <SimpleCard cardStyle={{ margin: 0 }}>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        className={classes.summaryTitleContainer}
                      >
                        <h4 className={classes.summaryTitle}>
                          <Translate id="Checkout@yourOrderTitle">
                            Seu pedido
                          </Translate>
                        </h4>
                        <Divider light />
                      </GridItem>
                      {selectedOrder.products.map((product, index) => (
                        <GridItem key={product.token} xs={12}>
                          {index > 0 && <Divider light />}
                          <GridContainer
                            alignItems="center"
                            className={classes.productContainer}
                          >
                            <Button
                              justIcon
                              color="danger"
                              outline
                              small
                              className={classes.productRemoveButton}
                              onClick={() => {
                                ordersStore.removeProductFromSelectedOrder(
                                  product
                                );
                              }}
                            >
                              <i className="fas fa-minus" />
                            </Button>
                            <GridItem xs={12} sm={6}>
                              <p className={classes.productTitle}>
                                {product.name}
                              </p>
                              <p className={classes.productDescription}>
                                {textNewLineToBr(product.description)}
                              </p>
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                              <PriceContainer
                                price={product.price}
                                offerPrice={product.offerPrice}
                                actualPriceColor="#333"
                                containerStyle={{
                                  fontSize: "1.2em",
                                  textAlign: "right",
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      ))}
                    </GridContainer>
                    <GridContainer style={{ marginTop: 20 }}>
                      <GridItem xs={12} style={{ textAlign: "center" }}>
                        <Button
                          color="primary"
                          outline
                          small
                          block
                          onClick={productsStore.toggleProductsListModal}
                        >
                          Adicionar outros produtos{" "}
                          <i className="fas fa-plus" />
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </SimpleCard>
                </GridItem>
                {comboSuggestions.length > 0 && (
                  <GridItem xs={12} style={{ marginBottom: 30 }}>
                    <h4 className={classes.summaryTitle}>
                      <Translate id="Checkout@sugestionsTitle">
                        Sugestões baseadas no seu pedido
                      </Translate>
                    </h4>
                    <Divider light />
                    <GridContainer>
                      {comboSuggestions.map((combo) => (
                        <ComboSuggestionItem key={combo.token} combo={combo} />
                      ))}
                    </GridContainer>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={5} md={4}>
              <SimpleCard cardStyle={{ margin: 0 }}>
                <GridContainer>
                  <GridItem xs={12} className={classes.summaryTitleContainer}>
                    <h4 className={classes.summaryTitle}>
                      <Translate id="Checkout@orderResumeTitle">
                        Resumo do pedido
                      </Translate>
                    </h4>
                    <Divider light />
                  </GridItem>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <p className={classes.summaryTotalPriceLabel}>Total:</p>
                      </GridItem>
                      <GridItem xs={6}>
                        <p className={classes.summaryPrice}>
                          <Price>{ordersStore.selectedOrderTotalPrice}</Price>
                        </p>
                      </GridItem>
                    </GridContainer>
                    <Divider light />
                  </GridItem>
                  <GridItem xs={12} className={classes.summaryButtonContainer}>
                    <Button
                      color="success"
                      block
                      large
                      isLoading={this.state.isRedirecting}
                      onClick={this.submitCheckout}
                    >
                      <Translate id="Common@continue">Continuar</Translate>{" "}
                      <i className="fas fa-credit-card" />
                    </Button>
                  </GridItem>
                </GridContainer>
              </SimpleCard>
            </GridItem>
          </GridContainer>
        </div>
      );
    }

    return (
      <GridContainer>
        <GridItem xs>
          <SimpleCard
            cardBodyStyle={{ padding: "60px 0", textAlign: "center" }}
          >
            <h4 className={classes.summaryTitle} style={{ marginBottom: 20 }}>
              Seu carrinho está vazio no momento.
            </h4>
            <Button
              color="primary"
              onClick={productsStore.toggleProductsListModal}
            >
              Veja aqui opções para melhorar seu contrato
            </Button>
          </SimpleCard>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(CheckoutPage),
  pageAllowedRule: "affiliate",
});
