//@ts-nocheck
import { List } from "@material-ui/core";

import { GridItem } from "../../Grid";
import Translate from "../../I18n/Translate";

import TerminalModel from "../../../Model/TerminalModel";

interface DeviceAdvancedActionsProps {
  renderListItem: any;
  terminal: TerminalModel;
  sendCommand: (command: string) => () => void;
}

const DeviceAdvancedActions = ({
  renderListItem,
  terminal,
  sendCommand,
}: DeviceAdvancedActionsProps) => {
  const { isActive, isOnline } = terminal;

  return (
    <>
        {renderListItem({
          button: true,
          divider: true,
          onClick: sendCommand("pingofdeath"),
          icon: isActive ? "pause" : "play",
          primary: isActive ? (
            <Translate id="Device@disableButtonLabel">
              Desabilitar dispositivo
            </Translate>
          ) : (
            <Translate id="Device@enableButtonLabel">
              Habilitar dispositivo
            </Translate>
          ),
        })}
        {renderListItem({
          button: true,
          divider: true,
          onClick: sendCommand("btsync"),
          icon: "file-upload",
          disabled: !isOnline,
          primary: (
            <Translate id="Device@resetMediaFilesButtonLabel">
              Resetar arquivos de mídia
            </Translate>
          ),
        })}
        {renderListItem({
          button: true,
          divider: true,
          onClick: sendCommand("remote"),
          icon: "life-ring",
          disabled: !isOnline,
          primary: (
            <Translate id="Device@requestRemoteAccessButtonLabel">
              Suporte remoto
            </Translate>
          ),
        })}
        {renderListItem({
          button: true,
          divider: true,
          onClick: sendCommand("getlogs"),
          icon: "upload",
          disabled: !isOnline,
          primary: (
            <Translate id="Device@requestLogsButtonLabel">
              Coletar logs
            </Translate>
          ),
        })}
      </>
  );
};

export default DeviceAdvancedActions;
