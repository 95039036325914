// @ts-nocheck
import { Component } from "react";
import { inject } from "mobx-react";

import ExpansionPanel from "./ExpansionPanel";

import { FEEDBACK_SOUND } from "../../store/SoundStore/SoundStore";

@inject("soundStore")
class ExpansionPanelContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: props.selectedIndex,
    };
  }

  didTogglePanel = (index) => () => {
    const selectedIndex = index === this.state.selectedIndex ? null : index;

    this.setState(
      {
        selectedIndex,
      },
      () => {
        this.props.soundStore.playFeedbackSound(
          selectedIndex !== null
            ? FEEDBACK_SOUND.Expand
            : FEEDBACK_SOUND.Collapse
        );
      }
    );
  };

  render() {
    const { panels, ...rest } = this.props;
    const { selectedIndex } = this.state;
    return panels.map((item, index) => {
      return (
        <ExpansionPanel
          key={index}
          isExpanded={selectedIndex === index}
          didTogglePanel={this.didTogglePanel(index)}
          {...item}
          {...rest}
        />
      );
    });
  }
}

ExpansionPanelContainer.defaultProps = {
  selectedIndex: null,
  panels: [],
};

export default ExpansionPanelContainer;
