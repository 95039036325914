// @ts-nocheck
import { Fragment } from "react";
import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

const AdminReportsPage = () => {
  return (
    <Fragment>
      <PageTitle id="Menu@adminReports">Relatórios globais</PageTitle>
      <p style={{ margin: 40, textAlign: "center" }}>Em desenvolvimento...</p>
    </Fragment>
  );
};

export default withPageAllowedRules({
  component: AdminReportsPage,
  pageAllowedRule: "simplyas",
});
