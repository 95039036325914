// @ts-nocheck

import animationData from "./SquareDropLoader.json";
import LottieAnimation from "../LottieAnimation";

const SquareDropLoader = props => (
  <LottieAnimation animationData={animationData} {...props} />
);

export default SquareDropLoader;
