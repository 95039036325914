// @ts-nocheck

import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../../HOC";
import AreaChart from "../../Charts/AreaChart";

// Styles
import { warningColor, dangerColor } from "../../../assets/jss/app-js-styles";
const styles = {};

const PowerSupplyChart = ({
  localizedString,
  yAnnotation,
  dateFormat,
  chartData,
}) => (
  <AreaChart
    height={500}
    data={[
      {
        name: localizedString({
          id: "Device@powerSupplyInfo",
          defaultString: "Energia",
        }),
        data: chartData.map(({ date, power_supply }) => {
          const value =
            power_supply === "0x50005"
              ? 9
              : power_supply.includes("0x5000")
              ? 5
              : 1;
          return [date * 1000, value];
        }),
      },
    ]}
    showToolbar
    customChartOptions={{
      animations: { enabled: false },
      toolbar: {
        tools: {
          download: false,
        },
      },
    }}
    customOptions={{
      annotations: {
        yaxis: [
          yAnnotation({
            value: 4,
            color: warningColor,
            label: localizedString({
              id: "DeviceAlert@issueIdentifiedStateMessage",
              defaultString: "Problema identificado",
            }),
          }),
          yAnnotation({
            value: 8,
            color: dangerColor,
            label: localizedString({
              id: "DeviceAlert@criticalStateMessage",
              defaultString: "Estado crítico",
            }),
          }),
        ],
      },
      xaxis: {
        type: "datetime",
        labels: {
          format: dateFormat,
          datetimeUTC: false,
        },
      },
      yaxis: {
        min: 0,
        max: 10,
        show: false,
      },
      tooltip: {
        x: {
          format: dateFormat,
        },
        y: {
          formatter: (value) => {
            if (value > 4) {
              return localizedString({
                id: "DeviceAlert@issueIdentifiedStateMessage",
                defaultString: "Problema identificado",
              });
            } else if (value > 8) {
              return localizedString({
                id: "DeviceAlert@criticalStateMessage",
                defaultString: "Estado crítico",
              });
            } else {
              return localizedString({
                id: "DeviceAlert@normalStateMessage",
                defaultString: "Condição normal",
              });
            }
          },
        },
      },
      dataLabels: { enabled: false },
    }}
  ></AreaChart>
);

export default withStyles(styles)(withLocalized(PowerSupplyChart));
