import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Payload } from "./type";

export const WizardUploadBox = styled.div`
  width: 100%;
  min-height: 350px;
`;

export const WizardUploadActionsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const WizardUploadFor2Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;

  .title {
    width: 100%;
    h3 {
      font-weight: bold;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .left {
      width: 70%;
    }
    .right {
      width: 30%;
    }
  }
`;

export const WizarUploadUPBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;

  ul {
    display: flex;
    flex-direction: row;
    gap: 26px;
    list-style: none;
    li {
      b {
        margin-left: 5px;
      }
    }
  }
`;

export const WizarUploadRadioButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  padding-left: 150px;

  .btn-group {
    display: flex;
    div {
      min-width: 150px;
      margin: 0;
    }
  }
`;
export const NumberSecondsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
`;

type WizardUploadImageBoxProps = {
  children: ReactNode;
  payload: Payload;
};

export const WizardUploadImageBox = styled.div<WizardUploadImageBoxProps>`
  width: 250px;
  height: 250px;
  padding: 15px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) => {
      if (props.payload?.isLed) {
        return css`
          width: 50%;
          height: 100%;
        `;
      }

      if (props.payload?.isVertical) {
        return css`
          width: 70%;
          height: 100%;
        `;
      }

      return css`
        width: 100%;
        height: 70%;
      `;
    }}

    gap: 10px;

    ${(props) => {
      if (props.payload?.isVertical) {
        return css`
          flex-direction: column;
        `;
      }

      return css`
        flex-direction: row;
      `;
    }}

    .image {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      background-color: #dce1e360;
      justify-content: center;
      align-items: center;

      ${({ payload }) => {
        if (!!payload?.isFade) {
          return css`
            &::before {
              position: absolute;
              content: " ";
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.15);
              border-radius: 2px;
              z-index: 2;
            }
          `;
        }

        return css``;
      }}

      figure {
        width: 30px;
        height: 30px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
`;
