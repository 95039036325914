// @ts-nocheck
import { inject } from "mobx-react";
import { Component, Fragment } from "react";

// ../../Components
import { Button } from "../../components/Button";
import { InputMap, InputText } from "../../components/Form";
import { GridContainer, GridItem } from "../../components/Grid";

import { IMAGE_PATH } from "../../utils/EnvUtils";

import Validate, { ValidationType } from "../../components/Form/Validate";

// Translate
import {
  CancelString,
  DeleteString,
  SaveString,
  UpdateString,
} from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

// Icon
import {
  ChevronLeftIcon,
  DeleteIcon,
  SaveIcon,
} from "../../components/Icon/MaterialIcon";
import CepToCoordinatesService from "../../services/CepToCoordinatesService";

@inject("locationStore", "notificationsStore")
class LocationForm extends Component {
  constructor(props) {
    super(props);
    this.coordinateService = new CepToCoordinatesService();

    if (props.location) {
      this.state = this.getStateFromExistingLocation(props.location);
    } else {
      this.state = this.getDefaultState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      this.updateForm();
    }
  }

  updateForm = () => {
    if (this.props.location) {
      this.setState({
        ...this.getStateFromExistingLocation(this.props.location),
      });
    } else {
      this.setState({ ...this.getDefaultState() });
    }
  };

  getDefaultState = () => {
    return {
      name: "",
      cnpj: "",
      cep: "",
      latitude: "",
      longitude: "",
      isWaitingForm: false,
      formWasSubmit: false,
      isValid: {
        name: false,
        cnpj: false,
        latitude: false,
        longitude: false,
      },
    };
  };

  getStateFromExistingLocation = (location) => {
    return {
      ...this.getDefaultState(),
      name: location.name,
      cnpj: location.cnpj,
      latitude: location.latitude,
      longitude: location.longitude,
      isValid: {
        name: Validate(location.name, ValidationType.REQUIRED),
        cnpj: Validate(location.cnpj, ValidationType.REQUIRED),
        latitude: Validate(location.latitude, ValidationType.REQUIRED),
        longitude: Validate(location.longitude, ValidationType.REQUIRED),
      },
    };
  };

  onChange = ({ target }) => {
    const { name, value } = target;

    this.setState({
      [name]: value,
    });

    if (name === "cep" && value.length === 8) {
      return this.getCoordinates(value);
    }

    this.setState({
      isValid: {
        ...this.state.isValid,
        [name]: Validate(value, ValidationType.REQUIRED),
      },
    });
  };

  getCoordinates = async (cep) => {
    try {
      const response = await this.coordinateService.getCoordinates(cep);
      if (response.status === 200) {
        const { lat, lng } = response.data;
        this.setState({ latitude: lat, longitude: lng });
      }
    } catch (e) {
      const { message } = e.response.data;
      this.props.notificationsStore.addSnackbarNotification({
        message,
        color: "danger",
      });
    }
  };

  canSubmitForm = () => {
    const { name, cnpj, latitude, longitude } = this.state.isValid;
    return name && cnpj && latitude && longitude;
  };

  fieldsWithError = () => {
    return Object.keys(this.state.isValid).filter(
      (field) => !this.state.isValid[field]
    );
  };

  errorMessages = () => {
    return {
      name: {
        id: "Location@nameErrorMessage",
        message: "Por favor preencha o nome da localidade.",
      },
      cnpj: {
        id: "Location@cnpjErrorMessage",
        message: "Por favor preencha o cnpj da localidade.",
      },
      latitude: {
        id: "Location@latitudeErrorMessage",
        message: "Por favor preencha a latitude da localidade.",
      },
      longitude: {
        id: "Location@longitudeErrorMessage",
        message: "Por favor preencha a longitude da localidade.",
      },
    };
  };

  submitForm = () => {
    const formData = {
      name: this.state.name,
      cep: this.state.cep,
      cnpj: this.state.cnpj,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };

    if (this.props.location) {
      this.props.locationStore
        .updateLocation({
          ...this.props.location,
          ...formData,
        })
        .then(() => {
          this.submitHasSuccess({ token: null });
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      this.props.locationStore
        .submitLocation(formData)
        .then((response) => {
          this.submitHasSuccess({ token: response.data.token });
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  didWantToDelete = (event) => {
    event.preventDefault();

    this.props.didWantToDelete();
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      const fieldsWithError = this.fieldsWithError();
      const errorMessages = this.errorMessages();
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          for (const field of fieldsWithError) {
            this.props.notificationsStore.addSnackbarNotification({
              message: (
                <Translate id={errorMessages[field].id}>
                  {errorMessages[field].message}
                </Translate>
              ),
              color: "danger",
            });
          }
        }
      );
    }
  };

  submitHasSuccess = (token) => {
    if (this.props.didSave) {
      this.props.didSave();
    }
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  render() {
    const isEditing = this.props.location !== null;
    const {
      name,
      cnpj,
      cep,
      latitude,
      longitude,
      isWaitingForm,
      formWasSubmit,
      isValid,
    } = this.state;

    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <InputText
              label={
                <Translate id="Location@nameLabel">
                  Nome da Localidade
                </Translate>
              }
              name="name"
              hasError={formWasSubmit && !isValid.name}
              hasSuccess={formWasSubmit && isValid.name}
              value={name}
              onChange={this.onChange}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <InputText
              label={<Translate id="Location@cnpjLabel">CNPJ</Translate>}
              name="cnpj"
              hasError={formWasSubmit && !isValid.cnpj}
              hasSuccess={formWasSubmit && isValid.cnpj}
              value={cnpj}
              onChange={this.onChange}
              isRequired
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <InputText
              label={
                <Translate id="Location@cepLabel">Informe o CEP</Translate>
              }
              name="cep"
              value={cep}
              onChange={this.onChange}
              maxLength={8}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <InputText
              label={
                <Translate id="Location@latitudeLabel">Latitude</Translate>
              }
              name="latitude"
              hasError={formWasSubmit && !isValid.latitude}
              hasSuccess={formWasSubmit && isValid.latitude}
              value={String(latitude)}
              onChange={this.onChange}
              isRequired
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <InputText
              label={
                <Translate id="Location@labelForLongitude">Longitude</Translate>
              }
              name="longitude"
              hasError={formWasSubmit && !isValid.longitude}
              hasSuccess={formWasSubmit && isValid.longitude}
              value={String(longitude)}
              onChange={this.onChange}
              isRequired
              er
            />
          </GridItem>
        </GridContainer>
        <GridContainer style={{ maxHeight: "50vh", marginBottom: 20 }}>
          {parseFloat(latitude) && parseFloat(longitude) ? (
            <InputMap
              name="location"
              latitude={latitude}
              longitude={longitude}
            />
          ) : (
            <img
              style={{
                maxWidth: "100%",
                margin: "0 auto",
                filter: "grayscale(100%)",
                maxHeight: "inherit",
              }}
              src={`${IMAGE_PATH}/default-locations-map.jpg`}
              alt="Imagem padrão de localização, um mapa com filtro de escala de cinza."
            />
          )}
        </GridContainer>
        <GridContainer justify="space-between">
          <Button onClick={this.didCancel}>
            <ChevronLeftIcon /> <CancelString />
          </Button>
          {isEditing && !this.props.location.isDefault && (
            <Button color="danger" onClick={this.didWantToDelete}>
              <DeleteString /> <DeleteIcon />
            </Button>
          )}
          <Button
            color="primary"
            isLoading={isWaitingForm}
            onClick={this.onSubmit}
          >
            {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
          </Button>
        </GridContainer>
      </Fragment>
    );
  }
}

export default LocationForm;
