// @ts-nocheck

import Select from "react-select";

import { withLocalized } from "../HOC";

const SelectGroupFilter = ({
  localizedString,
  groupsList,
  isFetchingGroups,
  getSelectedGroups,
  onChange,
  allowMultipleGroups,
}) => (
  <Select
    value={getSelectedGroups}
    isLoading={isFetchingGroups}
    placeholder={localizedString({
      id: "Metrics@selectGroup",
      defaultString: "Selecione grupo(s)...",
    })}
    options={groupsList.map(($0) => ({
      label: $0.name,
      value: $0.token,
    }))}
    autoFocus={true}
    noOptionsMessage={() =>
      localizedString({
        id: "Metrics@noGroupFound",
        defaultString: "Nenhum grupo encontrado",
      })
    }
    onChange={onChange}
    isMulti={allowMultipleGroups}
  />
);

export default withLocalized(SelectGroupFilter);
