// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import ResourcePageContainer from "../components/Resources/ResourcePageContainer";
import ConnectivitySingleShareInternetContainer from "./connectivityPage/ConnectivitySingleShareInternetContainer";

@inject("contractStore", "networksStore")
@observer
class ConnectivityPage extends Component {
  render() {
    if (this.props.contractStore.selectedContract.resources.connectivity) {
      return (
        <Fragment>
          <PageTitle id="Menu@connectivityShareInternet">
            Compartilhar Internet
          </PageTitle>
          <ResourcePageContainer
            appMainStore={this.props.networksStore}
            resourceType="network"
          />
        </Fragment>
      );
    }

    return <ConnectivitySingleShareInternetContainer />;
  }
}

export default withPageAllowedRules({
  component: ConnectivityPage,
  pageAllowedRule: "connectivity",
});
