// @ts-nocheck

import { SortableElement } from "react-sortable-hoc";
import withStyles from "@material-ui/core/styles/withStyles";

import { Button } from "../../Button";

// Style
const styles = {
  thumbnail: {
    display: "inline-flex",
    position: "relative",
    width: 100,
    margin: 8,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 100,
  },
  image: {
    width: "100%",
    height: 100,
    display: "block",
    backgroundPosition: "center center",
    backgroundSize: "contain",
  },
  imagePlaceholder: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const WifiBannerThumbnail = ({ classes, src, didWantToRemove }) => (
  <div className={classes.thumbnail}>
    <div className={classes.image} style={{ backgroundImage: `url(${src})` }} />
    <Button small block color="danger" onClick={didWantToRemove}>
      <i className="fas fa-trash-alt" />
    </Button>
  </div>
);

export default withStyles(styles)(
  SortableElement((props) => <WifiBannerThumbnail {...props} />)
);
