// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";

import Modal from "./Modal";

import UppyFileUpload from "../Form/UppyFileUpload";
import { InputSwitch, ButtonGroup } from "../Form";
import NumberEditingWithLabelInput from "../Form/NumberEditingWithLabelInput";
import AddStreamingsWebContentForm from "../AppForm/AddStreamingsWebContentForm";
import AddMediaContentForm from "../AppForm/AddMediaContentForm";
import { LoaderRing, Success } from "../Animations";
import { Button } from "../Button";
import { withLocalized } from "../HOC";
import TagsContainer from "../Tags/TagsContainer";

import { GridContainer, GridItem } from "../Grid";

// Translate
import { BackString, SecondsString } from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Transloadit
import TransloaditUppyFileUpload from "../Form/TransloaditUppyFileUpload";
import {
  templateIdFiles,
  templateIdFilesWithoutEncoding,
} from "../../services/TransloaditService";
// Transloadit END

// Services
import { EMediaTemplate } from "../../types/Media";

// Utils
import { humanFileSizeFromBytes } from "../../utils/Utils";
import WizardUpload from "../WizardUpload";

@withLocalized
@inject(
  "contractStore",
  "tagsStore",
  "notificationsStore",
  "mediaLibraryStore",
  "settingsStore"
)
@observer
class ModalUploadMediaFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabType: this.getTabTypeFromIndex(props.indexToOpen),
      isUploading: false,
      isSendingToServer: false,
      isCompleted: false,
      duplicatedFiles: [],
      duplicatedErrorMessage: "",
      selectedTags: [],
      isCustom: false,
      isVertical: false,
      isFade: false,
      isVideowall: false,
    };
  }

  getTabTypeFromIndex = (index) => {
    switch (index) {
      case 0:
        return "uploadMedia";
      case 1:
        return "streaming";

      default:
        return "contents";
    }
  };

  onChangeTabType = (_, selectedValue) => {
    this.setState({
      tabType: selectedValue,
    });
  };

  changeIsUploadingState = (newState) => {
    this.setState({
      isUploading: newState,
    });
  };

  didChangeMediaDuration = (value) => {
    this.props.settingsStore.actionChangeMediaDurationToUpload(value);
  };

  didChangeFilesUpload = (files) => {
    console.log({ files });
    this.setState(
      {
        isSendingToServer: true,
      },
      () => {
        this.props.mediaLibraryStore
          .submitFiles(
            files,
            this.state.selectedTags,
            this.state.isVertical,
            this.state.isFade,
            this.state.isCustom,
            this.state.isVideowall
          )
          .then(() => {
            this.setState(
              {
                isSendingToServer: false,
                isCompleted: true,
              },
              () => {
                setTimeout(() => {
                  this.props.didCloseModal();
                }, 1500);
              }
            );
          })
          .catch((error) => {
            this.setState(
              {
                isSendingToServer: false,
              },
              () => {
                if (error && error.statusCode === 409) {
                  this.setState({
                    duplicatedErrorMessage: error.error,
                    duplicatedFiles: error.files,
                  });
                } else {
                  this.props.notificationsStore.addSnackbarNotification({
                    message: (
                      <Translate id="Upload@errorMessage">
                        Ocorreu um erro ao salvar sua mídia. Por favor faça
                        upload novamente.
                      </Translate>
                    ),
                    timeout: 4000,
                    color: "danger",
                  });
                }
              }
            );
          });
      }
    );
  };

  didCloseModal = () => {
    this.props.didCloseModal();
  };

  renderUploadMedia = () => {
    const { settingsStore, tagsStore, contractStore } = this.props;
    const { resources: { videowall, ledPanelSupport } } = contractStore.selectedContract;

    const {
      mediaDurationToUpload: mediaDuration,
      isUsingTransloadit,
    } = settingsStore;
    const { isUploading, isVertical, isCustom, isFade } = this.state;
    return (
      <GridContainer alignItems="center" style={{ width: "100%", margin: 0 }}>
        <GridItem xs={12}>
          <WizardUpload
            didChangeFilesUpload={this.didChangeFilesUpload}
            changeIsUploadingState={this.changeIsUploadingState}
            isUploading={this.state.isUploading}
            tagsList={tagsStore.mediaTags}
            selectedTags={this.state.selectedTags}
            onChangeSelectedTags={this.onChangeSelectedTags}
            updateVideowall={(v) => {
              this.setState({
                isVideowall: v,
              });
            }}
            videowallSupport={videowall}
            ledSupport={ledPanelSupport}
          />
        </GridItem>
      </GridContainer>
    );
  };

  renderStreaming = () => (
    <AddStreamingsWebContentForm
      didSave={this.didCloseModal}
      selectedTags={this.state.selectedTags}
    />
  );

  renderContents = () => (
    <AddMediaContentForm
      didSave={this.didCloseModal}
      selectedTags={this.state.selectedTags}
    />
  );

  onChangeSelectedTags = (selectedTags) => {
    this.setState({
      selectedTags,
    });
  };

  render() {
    const { isOpen, contractStore, tagsStore, localizedString } = this.props;
    const { storage, resources: { liveOnlineContent } } = contractStore.selectedContract;
    if (storage.usage > storage.limit) {
      return (
        <Modal isOpen={isOpen} didCloseModal={this.didCloseModal} maxWidth="sm">
          <GridContainer style={{ marginTop: 20, textAlign: "center" }}>
            <GridItem xs={12}>
              <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                <Translate id="Upload@insufficientDiskSpaceTitle">
                  Espaço insuficiente
                </Translate>{" "}
                <i className="fas fa-exclamation-triangle" />
              </p>
              <p style={{ whiteSpace: "pre-wrap" }}>
                <Translate id="Upload@insufficientDiskSpaceMessage">
                  Você utilizou todo espaço em disco disponível. Apagando mídias
                  poderá inserir novos recursos no dia seguinte.
                </Translate>
              </p>
              <p>
                <Translate
                  id="Upload@diskSpaceUsageInfo"
                  variables={{
                    storageUsage: humanFileSizeFromBytes(storage.usage),
                    maxStorage: humanFileSizeFromBytes(storage.limit),
                  }}
                >
                  $storageUsage utilizados - $maxStorage disponíveis
                </Translate>
              </p>
            </GridItem>
          </GridContainer>
        </Modal>
      );
    }
    const {
      isSendingToServer,
      isCompleted,
      duplicatedFiles,
      duplicatedErrorMessage,
      tabType,
    } = this.state;

    if (duplicatedFiles.length > 0) {
      return (
        <Modal isOpen={isOpen} didCloseModal={this.didCloseModal}>
          <GridContainer style={{ margin: "60px 0" }}>
            <GridItem xs style={{ textAlign: "center" }}>
              <Success loop={false} height={120} />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3em",
                  margin: "30px 0",
                }}
              >
                {duplicatedErrorMessage}
              </p>
              <Button
                onClick={this.didCloseModal}
                small
                style={{ margin: "0 12px" }}
              >
                <i className="fas fa-chevron-left" /> <BackString />
              </Button>
              <Button
                color="primary"
                small
                style={{ margin: "0 12px" }}
                onClick={() =>
                  this.props.didUploadDuplicatedFiles(duplicatedFiles)
                }
              >
                <Translate id="Upload@showDuplicatedFilesButtonLabel">
                  Clique aqui para visualizar os duplicados.
                </Translate>
              </Button>
            </GridItem>
          </GridContainer>
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={isOpen}
        didCloseModal={!this.state.isUploading ? this.didCloseModal : null}
        maxWidth={isSendingToServer || isCompleted ? "sm" : "md"}
      >
        {isSendingToServer || isCompleted ? (
          <div style={{ margin: "120px 0" }}>
            {isSendingToServer && <LoaderRing height={120} />}
            {isCompleted && <Success loop={false} height={120} />}
          </div>
        ) : (
          <GridContainer>
            <GridItem xs={12}>
              <ButtonGroup
                name="tabType"
                value={tabType}
                color="primary"
                large
                onChange={this.onChangeTabType}
                options={[
                  {
                    value: "uploadMedia",
                    label: localizedString({
                      id: "Upload@uploadMediaTabTitle",
                      defaultString: "Enviar arquivos do seu dispositivo",
                    }),
                  },
                  {
                    value: "streaming",
                    label: localizedString({
                      id: liveOnlineContent ? "Upload@addStreamingTabTitleWithBroadcast" : "Upload@addStreamingTabTitleWithoutBroadcast",
                      defaultString:liveOnlineContent ? "Incluir videos/transmissões da Internet" : "Incluir videos da Internet",
                    }),
                  },
                  {
                    value: "contents",
                    label: localizedString({
                      id: "Upload@addContentTabTitle",
                      defaultString: "Incluir Conteúdos Externos",
                    }),
                  },
                ]}
              />
            </GridItem>
            {tabType !== "uploadMedia" && (
              <GridItem xs={12}>
                <TagsContainer
                  tagsList={tagsStore.mediaTags}
                  selectedTags={this.state.selectedTags}
                  onChangeSelectedTags={this.onChangeSelectedTags}
                />
              </GridItem>
            )}
            {tabType === "uploadMedia" && this.renderUploadMedia()}
            {tabType === "streaming" && this.renderStreaming()}
            {tabType === "contents" && this.renderContents()}
          </GridContainer>
        )}
      </Modal>
    );
  }
}

ModalUploadMediaFile.defaultProps = {
  indexToOpen: 0,
};

export default ModalUploadMediaFile;
