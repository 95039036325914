// @ts-nocheck

import PropTypes from "prop-types";

import { Button } from "../Button";

// Translate
import { LoadingString } from "../I18n/CommonStrings";

const SubmitButton = ({
  children,
  isLoading,
  block,
  large,
  disabled,
  ...rest
}) => (
  <Button
    color="primary"
    outline={isLoading}
    large={large}
    block={block}
    disabled={disabled}
    {...rest}
  >
    {isLoading ? <LoadingString /> : children}
  </Button>
);

SubmitButton.defautProps = {
  large: true,
};

SubmitButton.propTypes = {
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default SubmitButton;
