import axios from "axios";

const BASE_URL = "https://cep.awesomeapi.com.br/json/";

class CepToCoordinatesService {
  getCoordinates = (cep) => {
    const publicApi = axios.create({
      baseURL: BASE_URL,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return new Promise((resolve, reject) => {
      publicApi
        .get(cep)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          reject(this.handleError(error));
        });
    });
  };

  handleError = (error) => {
    return error;
  };
}

export default CepToCoordinatesService;
