// @ts-nocheck

import { withLocalized } from "../HOC";

// Utils
import { getActiveDaysFromArray } from "../../utils/DateUtils";

const DaysWeekString = ({ localizedString, daysWeek }) => {
  const { sun, mon, tue, wed, thu, fri, sat } = getActiveDaysFromArray(
    daysWeek
  );

  let resultString = "";

  if (sun && sat && !mon && !tue && !wed && !thu && !fri) {
    resultString = localizedString({
      id: "Schedule@weekends",
      defaultString: "Finais de semana",
    });
  } else if (mon && tue && wed && thu && fri && !sun && !sat) {
    resultString = localizedString({
      id: "Schedule@daysOfTheWeek",
      defaultString: "Dias da Semana",
    });
  } else if (sun && mon && tue && wed && thu && fri && sat) {
    resultString = localizedString({
      id: "Schedule@everyday",
      defaultString: "Todos os dias",
    });
  } else if (!sun && !mon && !tue && !wed && !thu && !fri && !sat) {
    resultString = localizedString({
      id: "Schedule@never",
      defaultString: "Nunca",
    });
  } else {
    resultString = daysWeek
      .map((day) =>
        localizedString({
          id: `Date@${day}`,
          defaultString: day,
        })
      )
      .join(", ");
  }

  return (
    <span>
      <i className="fas fa-calendar-check" /> {resultString}
    </span>
  );
};

export default withLocalized(DaysWeekString);
