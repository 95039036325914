import { EMediaTemplate } from "../../../types/Media";
import UppyFileUpload from "../../Form/UppyFileUpload";
import { WizarUploadUPBox } from "../style";
import { Payload } from "../type";

type WizardUploadUPProps = {
  didChangeFilesUpload: (files: File[]) => void;
  changeIsUploadingState: (value: boolean) => void;
  isUploading: boolean;
  payload: Payload;
};

const WizardUploadUP = ({
  didChangeFilesUpload,
  changeIsUploadingState,
  isUploading,
  payload,
}: WizardUploadUPProps) => {
  const isVertical = payload.isVertical;
  const isFade = payload.isFade;
  const isCustom = payload.isLed;
  const isVideowall = payload.isVideowall;

  return (
    <WizarUploadUPBox>
      <ul>
        <li>
          <span>Orientação:</span>
          <b>{payload.isVertical ? "Vertical" : "Horizontal"}</b>
        </li>
        <li>
          <span>Videowall:</span>
          <b>{payload.isVideowall ? "Sim" : "Não"}</b>
        </li>
        <li>
          <span>Led:</span>
          <b>{payload.isLed ? "Sim" : "Não"}</b>
        </li>

        <li>
          <span>Tipo:</span>
          <b>{!!payload?.isImage ? "Imagem" : "Vídeo"}</b>
        </li>

        {payload.isImage && (
          <li>
            <span>Duração:</span>
            <b>{payload.secondDuration}s</b>
          </li>
        )}

        <li>
          <span>Fade:</span>
          <b>{payload.isFade ? "Sim" : "Não"}</b>
        </li>
      </ul>

      <UppyFileUpload
        //@ts-ignore
        name="mediaFiles"
        allowedFileTypes={!!payload?.isImage ? ["image/*"] : ["video/*"]}
        didChangeFiles={didChangeFilesUpload}
        changeIsUploadingState={changeIsUploadingState}
        hideDragDropContainer={isUploading}
        mediaDuration={payload?.secondDuration ?? 10}
        additionalMeta={{
          is_vertical: isVertical ? "True" : "False",
          is_fade: isFade ? "True" : "False",
          is_custom: isCustom ? "True" : "False",
          is_videowall: isVideowall ? "True" : "False",
        }}
        template={EMediaTemplate.videoOriginal}
      />
    </WizarUploadUPBox>
  );
};

export default WizardUploadUP;
