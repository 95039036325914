// @ts-nocheck
import { Component } from "react";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import ErrorCatcher from "../components/ErrorCatcher";

// Utils Stores
import { ContractAvailableResources } from "../store/ContractStore/ContractStore";

const withPageAllowedRules = ({
  component: WrappedPageComponent,
  pageAllowedRule,
}) => {
  @inject("authStore", "contractStore")
  @observer
  class PageAllowedRulesHOC extends Component {
    render() {
      const { selectedContract } = this.props.contractStore;

      const matchContractResources = ContractAvailableResources.includes(
        pageAllowedRule
      )
        ? selectedContract.resources[pageAllowedRule]
        : true;
      const matchContractRules =
        pageAllowedRule === "all" || selectedContract.rules[pageAllowedRule];

      const matchSupportOperator =
        pageAllowedRule === "support_operator" &&
        this.props.authStore.currentUserCanSupport;

      const matchSimplyAsRule =
        pageAllowedRule === "simplyas" &&
        this.props.authStore.currentUserIsAdmin;

      const affiliateRule =
        pageAllowedRule === "affiliate" && selectedContract.isAffiliateContract
          ? true
          : false;

      if (
        (matchContractResources &&
          (matchSimplyAsRule || matchSupportOperator || matchContractRules)) ||
        affiliateRule
      ) {
        return (
          <ErrorCatcher showErrorOnTheScreen={false}>
            <WrappedPageComponent {...this.props} />
          </ErrorCatcher>
        );
      }

      return <Redirect to="/" />;
    }
  }

  return PageAllowedRulesHOC;
};

export default withPageAllowedRules;
