// @ts-nocheck
import { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";

import InputText from "./InputText";
import InputSelect from "./InputSelect";
import { GridContainer, GridItem } from "../Grid";
import Translate from "../I18n/Translate";

// Utils
import countryCodes from "../../utils/CountryCodes";

// Styles
const styles = {
  dddGrid: {
    paddingRight: "0px !important",
  },
};

const countryCodesList = Object.keys(countryCodes).map((country) => {
  const value = countryCodes[country];
  return {
    value,
    label: `${country} +${value}`,
  };
});

@inject("i18nStore")
@observer
class InputPhoneNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode:
        props.i18nStore.currentLanguage === "pt"
          ? { value: "55", label: "Brazil +55" }
          : { value: "1", label: "United States +1" },
      phoneNumber: "",
      ddd: "",
    };
  }

  onChange = () => {
    const { countryCode, ddd, phoneNumber } = this.state;
    this.props.onChange(`+${countryCode.value}${ddd}${phoneNumber}`);
  };

  onChangeCountryCode = (_, countryCode) =>
    this.setState({ countryCode, ddd: "" }, this.onChange);

  onChangePhoneNumber = ({ target }) =>
    this.setState(
      {
        phoneNumber: target.value,
      },
      this.onChange
    );

  onChangeDDD = ({ target }) => {
    const { value } = target;

    this.setState(
      {
        ddd: value,
      },
      () => {
        if (value.length === 2) {
          this.phoneNumberInput.focus();
        }
        this.onChange();
      }
    );
  };

  render() {
    const { countryCode, ddd, phoneNumber } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} md={5} style={{ zIndex: 100 }}>
          <InputSelect
            withPortal
            name="countryCode"
            value={countryCode}
            onChange={this.onChangeCountryCode}
            placeholder={
              <Translate id="SMSAuth@countryCodeInputLabel">
                Código do país
              </Translate>
            }
            options={countryCodesList}
          />
        </GridItem>
        {countryCode.value === "55" && (
          <GridItem xs={12} md={1} className={this.props.classes.dddGrid}>
            <InputText
              name="ddd"
              label="DDD"
              value={ddd}
              onChange={this.onChangeDDD}
              maxLength={2}
              autoFocus
              inputProps={{
                autoComplete: "off",
                style: { textAlign: "right" },
              }}
            />
          </GridItem>
        )}
        <GridItem xs md>
          <InputText
            name="phoneNumber"
            label={
              <Translate id="Advertising@phoneNumberLabel">Celular</Translate>
            }
            value={phoneNumber}
            onChange={this.onChangePhoneNumber}
            type="tel"
            autoFocus={!(countryCode && countryCode.value === "55")}
            inputProps={{
              autoComplete: "off",
              ref: (phoneNumberInput) =>
                (this.phoneNumberInput = phoneNumberInput),
            }}
            hasError={this.props.hasError}
            hasSuccess={this.props.hasSuccess}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(InputPhoneNumber);
