// @ts-nocheck
import { observable, autorun, action, computed } from "mobx";
import axios from "axios";

import { WHITE_LABEL_COMPANY_DOMAIN } from "../../utils/EnvUtils";

export const TUTORIALS_KEYS = Object.freeze({
  LOGIN_PAGE: "login-page",
  FORGOT_PASSWORD: "forgot-password",
  USER_PROFILE: "user-profile",
  ADMINISTRATIVE: "administrative",
  DEVICES: "devices",
  MEDIA_LIBRARY: "media-library",
  PLAYLIST_BASIC: "playlist-basic",
  PLAYLIST_ADVANCED: "playlist-advanced",
  VIDEO_WALL: "video-wall",
  CLOCK_LOGO_MESSAGES: "clock-logo-messages",
  WIFI_BASIC: "wifi-basic",
  WIFI_ADVANCED: "wifi-advanced",
  AUTH_REPORT: "authentications-report",
  ADVERTISING_SEND_MESSAGE: "advertising-send-message",
});

class TutorialsStore {
  @observable
  modalIsOpen = false;

  @observable
  youtubeTutorialsJson = null;

  @observable
  selectedTutorial = null;

  constructor(i18nStore) {
    autorun(() => {
      this.verifyIfJsonExistsInCompanyFolder(i18nStore.currentLanguage);
    });
  }

  @action setYoutubeTutorialsJson = (newValue) => {
    this.youtubeTutorialsJson = newValue;
  };
  @action setSelectedTutorial = (newValue) => {
    this.selectedTutorial = newValue;
  };

  @action
  toggleModal = () => {
    const newState = !this.modalIsOpen;
    this.modalIsOpen = newState;

    if (!newState) {
      setTimeout(() => this.setSelectedTutorial(null), 100);
    }
  };

  @computed
  get isAvailable() {
    return this.youtubeTutorialsJson;
  }

  didSelectTutorialKey = (key) => {
    // Verify if key exists
    if (this.isAvailable && this.youtubeTutorialsJson[key]) {
      this.setSelectedTutorial(this.youtubeTutorialsJson[key]);

      this.toggleModal();
    }
  };

  verifyIfJsonExistsInCompanyFolder = (currentLanguage) => {
    axios
      .get(
        `/files/modbox/youtube-tutorials_${currentLanguage}.json`
      )
      .then((response) => {
        this.setYoutubeTutorialsJson(response.data);
      })
      .catch(() => {
        this.setYoutubeTutorialsJson(null);
      });
  };
}

export default TutorialsStore;
