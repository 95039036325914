// @ts-nocheck
import { Component } from "react";
import GridContainer from "../components/Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { Textfit } from "react-textfit";
import PageTitle from "../components/PageTitle/PageTitle";
import { GridItem } from "../components/Grid";
import { WhatsAppIcon, EmailIcon } from "../components/Icon/FontAwesome";

// Style
import { successColor, primaryColor } from "../assets/jss/app-js-styles";

const styles = {
  userBubleItem: {
    textAlign: "center",
    cursor: "pointer",
    marginBottom: 30,
  },
  userBubleName: {
    fontWeight: "bold",
    marginTop: 8,
  },
  avatarContainer: {
    position: "relative",
    width: "fit-content",
    margin: "0 auto",
  },
  avatar: {
    fontSize: "2.5rem",
    "&:hover": {
      boxShadow:
        "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    },
  },
};

const contactOptions = [
  {
    name: "Suporte",
    phone: "5511963100040",
    color: successColor,
    icon: "whatsapp",
  },
  {
    name: "Financeiro",
    phone: "5561981007272",
    color: successColor,
    icon: "whatsapp",
  },
  { name: "LGPD", color: primaryColor, icon: "email" },
];

class Contact extends Component {
  redirectTo = (phone) => {
    if (phone) {
      window.open(`https://api.whatsapp.com/send?phone=${phone}`, "_blank");
    } else {
      window.location.href = "mailto:lgpd@simplyas.com";
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <PageTitle>Atendimento</PageTitle>
        <GridContainer>
          {Object.keys(contactOptions).map((user, index) => {
            return (
              <GridItem
                key={index}
                lg={2}
                md
                className={classes.userBubleItem}
                onClick={() => {
                  this.redirectTo(contactOptions[user].phone);
                }}
              >
                <IconButton
                  className={classes.avatar}
                  style={{ color: contactOptions[user].color }}
                >
                  {contactOptions[user].icon === "whatsapp" ? (
                    <WhatsAppIcon />
                  ) : (
                    <EmailIcon />
                  )}
                </IconButton>

                <Textfit mode="single" max={16}>
                  <p className={classes.userBubleName}>
                    {contactOptions[user].name}
                  </p>
                </Textfit>
              </GridItem>
            );
          })}
        </GridContainer>
      </>
    );
  }
}

export default withStyles(styles)(Contact);
