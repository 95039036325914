// @ts-nocheck

import withStyles from "@material-ui/core/styles/withStyles";
import classnames from "classnames";
import MaterialExpansionPanel from "@material-ui/core/ExpansionPanel";
import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";

// Style
const styles = (theme) => ({
  panel: {
    background: "#f4f4f4",
  },
  summary: {
    alignItems: "center",
  },
  expandedPanel: {
    background: "#E3F2FD",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    textTransform: "uppercase",
    fontWeight: 700,
    flexBasis: "30.0%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
});

const ExpansionPanel = ({
  classes,
  customClasses,
  isExpanded,
  didTogglePanel,
  title,
  subtitle,
  details,
}) => (
  <MaterialExpansionPanel
    className={classes.panel}
    expanded={isExpanded}
    onChange={didTogglePanel}
  >
    <ExpansionPanelSummary
      classes={{
        content: classnames({
          [classes.summary]: true,
          [customClasses.summary]: true,
        }),
      }}
      expandIcon={
        <i className="fas fa-chevron-down" style={{ fontSize: 14 }} />
      }
    >
      <Typography className={classes.heading}>{title}</Typography>
      {subtitle && (
        <Typography className={classes.secondaryHeading}>{subtitle}</Typography>
      )}
    </ExpansionPanelSummary>
    {isExpanded && (
      <ExpansionPanelDetails style={{ background: "#fff" }}>
        {details}
      </ExpansionPanelDetails>
    )}
  </MaterialExpansionPanel>
);

ExpansionPanel.defaultProps = {
  customClasses: {
    summary: {},
  },
};

export default withStyles(styles)(ExpansionPanel);
