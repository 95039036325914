// @ts-nocheck

import { FC, ReactElement } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../HOC";
import { InputText } from "../Form";
import { InputAdornment } from "@material-ui/core";
import { Button } from "../Button";

// Style
const styles = {
  formControl: {
    padding: 0,
    margin: 0,
  },
  marginBottom: {
    marginBottom: 20,
  },
};

interface SearchBarProps {
  classes?: any;
  localizedString?: any;
  label?: string | ReactElement;
  value: string;
  marginBottom?: boolean;
  autoFocus?: boolean;
  onChange: (search: string) => void;
}

const SearchBar: FC<SearchBarProps> = ({
  classes,
  localizedString,
  label,
  value,
  marginBottom = false,
  autoFocus = true,
  onChange,
}) => (
  <InputText
    formControlProps={{
      className: marginBottom
        ? `${classes.formControl} ${classes.marginBottom}`
        : classes.formControl,
    }}
    name="search-bar"
    placeholder={
      label
        ? label
        : localizedString({ id: "Common@search", defaultString: "buscar..." })
    }
    value={value}
    autoFocus={autoFocus}
    inputProps={{ autoComplete: "off" }}
    leftIcon={
      <InputAdornment position="start">
        <i className="fas fa-search" />
      </InputAdornment>
    }
    rightIcon={
      value.length > 0 && (
        <InputAdornment position="start">
          <Button
            color="transparent"
            justIcon
            small
            onClick={() => onChange("")}
          >
            <i className="fas fa-times" />
          </Button>
        </InputAdornment>
      )
    }
    onChange={(event) => onChange(event.target.value)}
  />
);

export default withLocalized(withStyles(styles)(SearchBar));
