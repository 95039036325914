export const ValidationType = Object.freeze({
  REQUIRED: "required",
  EMAIL: "email",
  NUMBER: "number",
  LENGTH: "length",
  URL: "url",
  IP_ADDRESS: "ipAddress",
  MAC_ADDRESS: "macAddress",
});

const verifyRequired = (value) => {
  return value !== "";
};

// function that returns true if value is email, false otherwise
const verifyEmail = (value) => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
// function that verifies if a string has a given length or not
const verifyLength = ({ value, length }) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};
// function that verifies if two strings are equal
export const compareStrings = (string1, string2) => {
  if (string1 === string2) {
    return true;
  }
  return false;
};

// verifies if value is a valid URL
const verifyUrl = (value) => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};

// function that verifies if value contains only numbers
const verifyNumber = (value) => {
  const numberRex = new RegExp("^[0-9]+$");
  return numberRex.test(value);
};

const verifyIpAddress = (value) => {
  const ipAddressRex = new RegExp(
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
  );
  return ipAddressRex.test(value);
};

export const verifyMacAddress = (value) => {
  const macAddressRex = new RegExp(/^([0-9a-f]{2}[:]){5}([0-9a-f]{2})$/);
  return macAddressRex.test(value);
};

const Validate = (data, type) => {
  switch (type) {
    case ValidationType.URL:
      return verifyUrl(data);

    case ValidationType.EMAIL:
      return verifyEmail(data);

    case ValidationType.NUMBER:
      return verifyNumber(data);

    case ValidationType.LENGTH:
      return verifyLength(data);

    case ValidationType.IP_ADDRESS:
      return verifyIpAddress(data);

    case ValidationType.MAC_ADDRESS:
      return verifyMacAddress(data);

    case ValidationType.REQUIRED:
    default:
      return verifyRequired(data);
  }
};

export default Validate;
