// @ts-nocheck

import classnames from "classnames";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Card,
  CardHeader,
  CardActions,
  IconButton,
  Tooltip,
  Box,
} from "@material-ui/core";

// Translate
import Translate from "../I18n/Translate";

// Utils
import { limitForSelectedUnit } from "../../utils/TemperatureUtils";
import defaultImg from "../../assets/img/default-device-img.jpeg";

// Icons
import {
  BoltIcon,
  FireIcon,
  SignalIcon,
  ExclamationTriangleIcon,
} from "../Icon/FontAwesome";

// Style
import { dangerColor } from "../../assets/jss/app-js-styles";
import { useState } from "react";

const styles = {
  card: {
    width: "100%",
    marginBottom: 16,
    position: "relative",
    cursor: "pointer",
  },
  cardHeader: {
    padding: 0,
    textAlign: "center",
    color: "#fff",
  },
  cardHeaderTitle: {
    fontSize: "1.2em",
    fontWeight: 500,
    color: "#333",
  },
  cardHeaderSubtitle: {
    fontSize: "0.9em",
    marginTop: -2,
  },
  media: {
    height: "120px",
    position: "center center",
    transition: "all 600ms",
  },
  mediaVertical: {
    transform: "rotate(-90deg) scale(0.6)",
  },
  grayscale: {
    filter: "grayscale(100%)",
  },
  floatingActions: {
    position: "absolute",
    bottom: 34,
    padding: 0,
    width: "100%",
    backgroundColor: "#fff",
    "& > button": {
      backgroundColor: "#ffffffb3",
    },
  },
  floatingActionsVertical: {
    width: 34,
    height: "calc(100% - 72px)",
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    padding: 4,
  },
  iconButton: {
    width: 30,
    height: 30,
    fontSize: "1.0em",
    padding: 0,
  },
  imageContainer: {
    padding: 10,
    objectFit: "contain",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  floatRightIcon: {
    marginLeft: "auto",
  },
  tooltip: {
    fontSize: "1.2em",
  },
};

const DeviceItemContainer = ({
  classes,
  i18nStore,
  deviceItem,
  displayGroupName,
  didSelect,
}) => {
  const { temperatureSymbol, currentTemperatureUnit } = i18nStore;
  const {
    isOnline,
    name,
    category,
    token,
    group,
    stats,
    screenshot,
    isVerticalScreen,
    network,
    hasTemperatureAlert,
    hasWifiAlert,
    hasEnergyAlert,
    hasDiskSpeedAlert,
    hasCriticalAlert,
  } = deviceItem;
  const [image, setImage] = useState(screenshot)

  return (
    <Card className={classes.card} onClick={didSelect(token)}>
      <CardHeader
        classes={{
          title: classes.cardHeaderTitle,
          subheader: classes.cardHeaderSubtitle,
        }}
        className={classes.cardHeader}
        title={name}
        subheader={
          displayGroupName ? (group && group.name ? group.name : "-") : null
        }
      />
      <Box className={classes.imageContainer}>
        <img
          className={classnames({
            [classes.media]: true,
            [classes.mediaVertical]: isVerticalScreen,
          })}
          src={image}
          onError={handleError}
          alt="screenshot"
        />
      </Box>
      <CardActions
        className={classnames({
          [classes.floatingActions]: true,
          [classes.floatingActionsVertical]: isVerticalScreen,
        })}
        disableSpacing
      >
        {isOnline &&
          network.internet_interface.includes("wlan") &&
          hasWifiAlert && (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                <Translate id="Device@wifiAlertMessage">
                  Wi-Fi com qualidade inferior a 40%!
                </Translate>
              }
            >
              <IconButton
                className={classes.iconButton}
                style={{ color: dangerColor }}
              >
                <SignalIcon />
              </IconButton>
            </Tooltip>
          )}
        {isOnline && hasTemperatureAlert && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Translate
                id="Device@temperatureAlertMessage"
                variables={{
                  temperature: `${limitForSelectedUnit(
                    75,
                    currentTemperatureUnit === "celcius"
                  )}${temperatureSymbol}`,
                }}
              >
                Temperatura do processador superior a $temperature!
              </Translate>
            }
          >
            <IconButton
              className={classes.iconButton}
              style={{ color: dangerColor }}
            >
              <FireIcon />
            </IconButton>
          </Tooltip>
        )}
        {isOnline && hasEnergyAlert && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Translate id="Device@energyAlertMessage">
                Fonte de energia inferior a 4.65V!
              </Translate>
            }
          >
            <IconButton
              className={classes.iconButton}
              style={{ color: dangerColor }}
            >
              <BoltIcon />
            </IconButton>
          </Tooltip>
        )}
        {isOnline && hasDiskSpeedAlert && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Translate id="Device@diskSpeedAlertMessage">
                Cartão SD com baixa velocidade de leitura / gravação!
              </Translate>
            }
          >
            <IconButton
              className={classes.iconButton}
              style={{ color: dangerColor }}
            >
              <i className="fas fa-folder-open" />
            </IconButton>
          </Tooltip>
        )}
        {isOnline && hasCriticalAlert && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Translate id="Device@criticalConditionAlertMessage">
                Dispositivo em estado crítico de funcionamento. Verificar
                imediatamente!
              </Translate>
            }
          >
            <IconButton
              className={classes.iconButton}
              style={{ color: dangerColor }}
            >
              <ExclamationTriangleIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
      <CardActions className={classes.actions} disableSpacing>
        <span>{stats.last}</span>
        <IconButton
          className={classnames({
            [classes.iconButton]: true,
            [classes.floatRightIcon]: true,
          })}
          style={{ color: stats.color }}
        >
          <i
            className={`fas fa-${category === "standard" ? "hdd" : "server"}`}
          />
        </IconButton>
      </CardActions>
    </Card>
  );

  function handleError(){
    setImage(defaultImg)
  }
};

export default withStyles(styles)(
  inject("i18nStore")(observer(DeviceItemContainer))
);
