//@ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../components/HOC";

// core components
import { Box, Modal, Typography } from "@material-ui/core";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import { ButtonGroup, InputText, InputSelect } from "../components/Form";
import { Button } from "../components/Button";
import Validate, { ValidationType } from "../components/Form/Validate";

import { USER_KEY } from "../store/AuthStore/AuthStore";

import UserSystemPreferencesContainer from "./userProfilePage/UserSystemPreferencesContainer";

import PasswordStrengthMeter from "../components/Form/PasswordStrengthMeter";

// Translate
import {
  NameString,
  EmailString,
  UpdateString,
  FormErrorString,
  IdiomString,
  TemperatureString,
  MetricString,
  TimezoneString,
} from "../components/I18n/CommonStrings";
import Translate from "../components/I18n/Translate";

// Utils
import { timezoneOptionsList } from "../utils/TimezoneUtils";

// Style
import { cardTitle } from "../assets/jss/app-js-styles";
const userProfileStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400",
    },
  },
  cardCategory: {
    marginTop: "10px",
    color: "#999999 !important",
    textAlign: "center",
  },
  description: {
    color: "#999999",
  },
};

@withLocalized
@inject("authStore", "notificationsStore", "modboxAPIService")
@observer
class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    const {
      name,
      email,
      temperature,
      newsletter,
      metric,
      language,
      timezone,
    } = props.authStore.currentUser;

    this.state = {
      isModalOpen: false,
      name,
      email,
      password: "",
      newPassword: "",
      confirmPassword: "",
      temperature,
      newsletter,
      metric,
      language,
      timezone,
      isWaitingForm: false,
      formWasSubmit: false,
      isValid: {
        name: true,
        email: true,
        newPassword: true,
        confirmPassword: true,
      },
    };
  }

  onChangeButtonGroup = (name, selectedValue) => {
    this.setState({
      [name]: selectedValue,
    });
  };

  onChangeInputText = ({ target }) => {
    const { name: key, value } = target;
    this.setState((prevState) => ({
      [key]: value,
      isValid: {
        ...prevState.isValid,
        [key]: Validate(value, ValidationType.REQUIRED),
      },
    }));
  };

  onChangePassword = ({ target }) => {
    const { value, name } = target;

    this.setState((prevState) => {
      let password = prevState.password;
      let newPassword = prevState.newPassword;
      let confirmPassword = prevState.confirmPassword;
      let isValidNewPassword = true;
      let isValidConfirmPassword = true;

      if (name === "password") {
        password = value;
      }
      if (name === "new-password") {
        const isValid =
          value.length > 5 &&
          value.length < 13 &&
          prevState.confirmPassword === value;
        newPassword = value;
        isValidNewPassword = isValid;
        isValidConfirmPassword = isValid;
      }
      if (name === "password-confirmation") {
        const isValid =
          value.length > 5 &&
          value.length < 13 &&
          prevState.newPassword === value;
        confirmPassword = value;
        isValidNewPassword = isValid;
        isValidConfirmPassword = isValid;
      }

      return {
        password,
        newPassword,
        confirmPassword,
        isValid: {
          ...prevState.isValid,
          newPassword: isValidNewPassword,
          confirmPassword: isValidConfirmPassword,
        },
      };
    });
  };

  onChangeInputEmail = ({ target }) => {
    const { name: key, value } = target;
    this.setState((prevState) => ({
      [key]: value,
      isValid: {
        ...prevState.isValid,
        [key]:
          Validate(value, ValidationType.REQUIRED) &&
          Validate(value, ValidationType.EMAIL),
      },
    }));
  };

  onChangeTimezone = (_, selectedValue) => {
    this.setState({
      timezone: selectedValue.value,
    });
  };

  handleModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  submitPassword = () => {
    const { password, newPassword, confirmPassword } = this.state;
    this.props.modboxAPIService
      .updatePassword({
        password,
        newPassword,
        confirmPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState((prev) => ({
            ...prev,
            isModalOpen: false,
          }));

          const user = JSON.parse(window.localStorage.getItem(USER_KEY));
          window.localStorage.setItem(
            USER_KEY,
            JSON.stringify({ ...user, jwtToken: response.data.access_token })
          );

          this.props.authStore.getCurrentUserProfile(user.token).then(() => {
            this.props.notificationsStore.addSnackbarNotification({
              message: "Senha atualizada com sucesso!",
              timeout: 4000,
              color: "success",
            });
          });

          this.setState((prev) => ({
            ...prev,
            password: "",
            newPassword: "",
            confirmPassword: "",
          }));
        }
      })
      .catch(() => {
        this.setState({
          isWaitingForm: false,
        });
      });
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const {
      isValid,
      name,
      email,
      password,
      newsletter,
      temperature,
      metric,
      language,
      timezone,
    } = this.state;
    if (
      isValid.name &&
      isValid.email &&
      isValid.password &&
      isValid.confirmPassword
    ) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        const userToken = this.props.authStore.currentUser.token;
        this.props.modboxAPIService
          .updateUser({
            userToken,
            name,
            email,
            password: password.length > 0 ? password : undefined,
            newsletter,
            temperature,
            metric,
            language,
            timezone,
          })
          .then((response) => {
            const successMessage = response.data.message;
            if (password.length > 0) {
              this.setState(
                {
                  isModalOpen: true,
                },
                () => {
                  setTimeout(() => {
                    this.props.authStore.logout();
                  }, 4000);
                }
              );
            } else {
              this.setState(
                {
                  isWaitingForm: false,
                },
                () => {
                  this.props.authStore
                    .getCurrentUserProfile(userToken)
                    .then(() => {
                      this.props.notificationsStore.addSnackbarNotification({
                        message: successMessage,
                        timeout: 4000,
                        color: "success",
                      });
                    });
                }
              );
            }
          })
          .catch(() => {
            this.setState({
              isWaitingForm: false,
            });
          });
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: <FormErrorString />,
            color: "danger",
          });
        }
      );
    }
  };

  render() {
    const { localizedString } = this.props;
    const {
      isModalOpen,
      name,
      email,
      password,
      newPassword,
      confirmPassword,
      newsletter,
      temperature,
      metric,
      language,
      timezone,
      isValid,
      isWaitingForm,
      formWasSubmit,
    } = this.state;

    return (
      <Fragment>
        <PageTitle id="Menu@profile">Meu perfil</PageTitle>

        <GridContainer>
          <GridItem xs={12} md={6}>
            <SimpleCard cardStyle={{ margin: 0 }}>
              <h4>
                <Translate id="Profile@userPreferencesTitle">
                  Perfil do usuário
                </Translate>
              </h4>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <InputText
                    label={<NameString />}
                    name="name"
                    value={name}
                    hasSuccess={formWasSubmit && isValid.name}
                    hasError={formWasSubmit && !isValid.name}
                    onChange={this.onChangeInputText}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <InputText
                    label={<EmailString />}
                    name="email"
                    value={email}
                    disabled
                    hasSuccess={formWasSubmit && isValid.email}
                    hasError={formWasSubmit && !isValid.email}
                    onChange={this.onChangeInputEmail}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <Button color="primary" outline onClick={this.handleModal}>
                    <Translate id="Profile@changePasswordLabel">
                      Trocar Senha
                    </Translate>
                  </Button>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <ButtonGroup
                    small
                    label={<IdiomString />}
                    name="language"
                    value={language}
                    color="success"
                    onChange={this.onChangeButtonGroup}
                    options={[
                      {
                        value: "en",
                        label: localizedString({
                          id: "I18n@english",
                          defaultString: "Inglês",
                        }),
                      },
                      {
                        value: "pt",
                        label: localizedString({
                          id: "I18n@portuguese",
                          defaultString: "Português",
                        }),
                      },
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <ButtonGroup
                    small
                    label={
                      <Translate id="Profile@acceptNewsletterLabel">
                        Newsletter
                      </Translate>
                    }
                    name="newsletter"
                    value={newsletter}
                    color="success"
                    onChange={this.onChangeButtonGroup}
                    options={[
                      {
                        value: true,
                        label: localizedString({
                          id: "Profile@acceptNewsletterOptionLabel",
                          defaultString: "Receber",
                        }),
                      },
                      {
                        value: false,
                        label: localizedString({
                          id: "Profile@refuseNewsletterOptionLabel",
                          defaultString: "Desativar",
                        }),
                      },
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <ButtonGroup
                    small
                    label={<TemperatureString />}
                    name="temperature"
                    value={temperature}
                    color="success"
                    onChange={this.onChangeButtonGroup}
                    options={[
                      {
                        value: "celcius",
                        label: "Celsius",
                      },
                      {
                        value: "fahrenheit",
                        label: "Fahrenheit",
                      },
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <ButtonGroup
                    small
                    label={<MetricString />}
                    name="metric"
                    value={metric}
                    color="success"
                    onChange={this.onChangeButtonGroup}
                    options={[
                      {
                        value: "kilometers",
                        label: localizedString({
                          id: "Common@kilometers",
                          defaultString: "Quilômetros",
                        }),
                      },
                      {
                        value: "miles",
                        label: localizedString({
                          id: "Common@miles",
                          defaultString: "Milhas",
                        }),
                      },
                    ]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <InputSelect
                    withPortal
                    label={<TimezoneString />}
                    name="timezone"
                    value={{ label: timezone, value: timezone }}
                    options={timezoneOptionsList}
                    onChange={this.onChangeTimezone}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer justify="space-between">
                <GridItem xs>
                  <Button
                    color={"primary"}
                    onClick={this.onSubmit}
                    block
                    isLoading={isWaitingForm}
                  >
                    <UpdateString />
                    <i className="fas fa-save" />
                  </Button>
                </GridItem>
              </GridContainer>
            </SimpleCard>
          </GridItem>
          <GridItem xs={12} md={6}>
            <UserSystemPreferencesContainer />
          </GridItem>
        </GridContainer>

        <Modal open={this.state.isModalOpen} onClose={this.handleModal}>
          <Box margin={6} display="flex" justifyContent="center">
            <Box padding={6} borderRadius={15} bgcolor="white" width={500}>
              <Typography variant="h4">
                <Box display="flex" justifyContent="center">
                  <Translate id="Profile@changePasswordLabel">
                    Trocar Senha
                  </Translate>
                </Box>
              </Typography>
              <Box>
                <InputText
                  label={
                    <Translate id="Profile@oldPasswordLabel">
                      Senha Atual
                    </Translate>
                  }
                  name="password"
                  inputProps={{ type: "password" }}
                  value={password}
                  onChange={this.onChangePassword}
                  hasError={
                    password.length > 0 && formWasSubmit && !isValid.password
                  }
                  hasSuccess={
                    password.length > 0 && formWasSubmit && isValid.password
                  }
                />
                <InputText
                  label={
                    <Translate id="Profile@newPasswordLabel">
                      Nova Senha
                    </Translate>
                  }
                  name="new-password"
                  inputProps={{ type: "password" }}
                  value={newPassword}
                  onChange={this.onChangePassword}
                  hasError={
                    newPassword.length > 0 &&
                    formWasSubmit &&
                    !isValid.newPassword
                  }
                  hasSuccess={
                    newPassword.length > 0 &&
                    formWasSubmit &&
                    isValid.newPassword
                  }
                />
                {newPassword.length > 0 && (
                  <PasswordStrengthMeter
                    password={newPassword}
                    userInputs={[name, email]}
                  />
                )}
              </Box>
              <Box>
                <InputText
                  label={
                    <Translate id="RecoverPassword@confirmPasswordLabel">
                      Confirme a nova senha
                    </Translate>
                  }
                  name="password-confirmation"
                  value={confirmPassword}
                  inputProps={{ type: "password", autoComplete: "off" }}
                  hasError={formWasSubmit && !isValid.confirmPassword}
                  hasSuccess={formWasSubmit && isValid.confirmPassword}
                  onChange={this.onChangePassword}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Button color="primary" onClick={this.handleModal}>
                  <Translate id="Profile@backPasswordLabel">Voltar</Translate>
                </Button>
                <Box marginLeft={4}>
                  <Button color="success" onClick={this.submitPassword}>
                    <Translate id="Profile@changePasswordLabel">
                      Trocar Senha
                    </Translate>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(userProfileStyles)(UserProfilePage),
  pageAllowedRule: "all",
});
