// @ts-nocheck


import { Button } from "../Button";
import Tooltip from "../Tooltip/Tooltip";

const TableActionButton = ({
  justIcon,
  isDisabled,
  color,
  icon,
  label,
  onClick,
  style,
}) => {
  const isJustIcon = justIcon;
  const button = (
    <Button
      justIcon={isJustIcon}
      disabled={isDisabled}
      onClick={onClick}
      color={color}
      small
      style={style}
    >
      <i
        className={`fas fa-${icon}`}
        style={{ marginRight: isJustIcon ? "inherit" : 6 }}
      />
      {!isJustIcon && label}
    </Button>
  );

  return isJustIcon ? <Tooltip title={label}>{button}</Tooltip> : button;
};

TableActionButton.defaultProps = {
  justIcon: true,
  color: "primary",
};

export default TableActionButton;
