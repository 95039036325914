import ReactGA from "react-ga";
import {
  IAnalyticsEvent,
  IAnalyticsService,
} from "../interfaces/IAnalyticsService";

class GoogleAnalyticsService implements IAnalyticsService {
  sendEvent(event: IAnalyticsEvent): void {
    ReactGA.event({
      category: event.category,
      action: event.action,
      label: event.label,
      value: event.value,
      nonInteraction: event.nonInteraction,
    });
  }
  setUser(userId: string): void {
    ReactGA.set({ userId });
  }
}

export default GoogleAnalyticsService;
