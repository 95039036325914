// @ts-nocheck

import classnames from "classnames";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import withStyles from "@material-ui/core/styles/withStyles";

import { GridContainer, GridItem } from "../Grid";

// Utils
import { IMAGE_PATH } from "../../utils/EnvUtils";

// Style
import { successColor, dangerColor } from "../../assets/jss/app-js-styles";
const styles = {
  devicesListContainer: {
    border: "1px dashed #eeeeee",
    borderRadius: 6,
    padding: 12,
    display: "flex",
    justifyContent: "center",
  },
  deviceContainer: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: 64,
    width: 64,
    margin: "8px 16px",
    position: "relative",
  },
  title: {
    fontSize: 10,
  },
  icon: {
    color: successColor,
    position: "absolute",
    top: 5,
    right: 20,
    fontSize: 20,
    opacity: 0,
  },
  checkIcon: {
    color: successColor,
  },
  banIcon: {
    color: dangerColor,
  },
  isSelectedIcon: {
    opacity: 1,
  },
  helperClass: {
    zIndex: 2147483647,
  },
};

const SortableList = SortableContainer(
  ({ classes, terminalsList, videoWallScreensCount }) => {
    return (
      <div className={classes.devicesListContainer} justify="center">
        {terminalsList.map((terminal, index) => (
          <DeviceItem
            key={index}
            index={index}
            title={terminal.name}
            classes={classes}
            isSelected={index < videoWallScreensCount}
          />
        ))}
      </div>
    );
  }
);

const DeviceItem = SortableElement(({ classes, title, isSelected }) => (
  <div
    className={classes.deviceContainer}
    style={{
      background: `url("${IMAGE_PATH}/tv.png")`,
    }}
  >
    <span className={classes.title}>{title}</span>
    <i
      className={classnames({
        fa: true,
        "fa-check": isSelected,
        "fa-ban": !isSelected,
        [classes.icon]: true,
        [classes.checkIcon]: isSelected,
        [classes.banIcon]: !isSelected,
        [classes.isSelectedIcon]: true,
      })}
    />
  </div>
));

const TerminalsToVideoWallDragNDropContainer = ({
  classes,
  terminalsList,
  videoWallScreensCount,
  didChangeTerminalsListOrder,
}) => (
  <GridContainer>
    <GridItem xs={12}>
      <SortableList
        terminalsList={terminalsList}
        videoWallScreensCount={videoWallScreensCount}
        onSortEnd={({ oldIndex, newIndex }) => {
          didChangeTerminalsListOrder(
            arrayMove(terminalsList, oldIndex, newIndex)
          );
        }}
        classes={classes}
        helperClass={classes.helperClass}
        axis={"xy"}
      />
    </GridItem>
  </GridContainer>
);

export default withStyles(styles)(TerminalsToVideoWallDragNDropContainer);
