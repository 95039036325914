// @ts-nocheck
import { Fragment } from "react";
import PropTypes from "prop-types";

import { Button } from "../Button";
import Translate from "../I18n/Translate";

import Modal from "./Modal";

const ConfirmModal = ({
  isOpen,
  title,
  children,
  cancelLabel,
  confirmLabel,
  cancelButtonColor,
  confirmButtonColor,
  hideCancel,
  confirmLeft,
  didCancel,
  didConfirm,
  actions,
}) => {
  const confirmButton = (
    <Button
      onClick={didConfirm}
      color={confirmButtonColor}
      style={{ marginRight: confirmLeft ? 16 : 0 }}
    >
      {confirmLabel}
    </Button>
  );
  return (
    <Modal
      isOpen={isOpen}
      modalTitle={title}
      maxWidth="sm"
      actions={
        actions ? (
          actions
        ) : (
          <Fragment>
            {confirmLeft && confirmButton}
            {!hideCancel && (
              <Button
                color={cancelButtonColor}
                onClick={didCancel}
                style={{ marginRight: confirmLeft ? 0 : 16 }}
              >
                {cancelLabel}
              </Button>
            )}
            {!confirmLeft && confirmButton}
          </Fragment>
        )
      }
    >
      {children}
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  cancelButtonColor: "default",
  confirmButtonColor: "primary",
  cancelLabel: <Translate id="Common@cancel">Cancelar</Translate>,
  confirmLabel: (
    <Translate id="Common@yesIConfirm">Sim, eu confirmo!</Translate>
  ),
};

ConfirmModal.propTypes = {
  cancelButtonColor: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "transparent",
  ]),
  confirmButtonColor: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "transparent",
  ]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  cancelLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  confirmLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideCancel: PropTypes.bool,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ConfirmModal;
