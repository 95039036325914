// @ts-nocheck

import animationData from "./Success.json";
import LottieAnimation from "../LottieAnimation";

const Success = props => (
  <LottieAnimation animationData={animationData} {...props} />
);

export default Success;
