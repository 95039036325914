import { FunctionComponent } from "react";
import classnames from "classnames";
import { inject, observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

import { Button } from "../Button";

import MessageText from "./message/MessageText";
import MessageTimestamp from "./message/MessageTimestamp";

import ChatStore, {
  QUESTION_MESSAGE_ID,
} from "../../store/ChatStore/ChatStore";
import I18nStore from "../../store/I18nStore/I18nStore";

import { successColor, dangerColor } from "../../assets/jss/app-js-styles";
const useStyles = makeStyles({
  container: {
    position: "relative",
    fontSize: 14,
    margin: "12px 25px",
    borderRadius: 6,
    backgroundColor: "#222f3e",
    color: "#fff",
    padding: "8px 15px",
    width: "calc(100% - 50px)",
    fontWeight: 500,
  },
  containerForAdmin: {},
  containerDidAnswer: {
    backgroundColor: dangerColor,
  },
  containerDidConfirm: {
    backgroundColor: successColor,
    color: "inherit",
  },
  denyButton: {
    marginRight: 8,
  },
});

interface QuestionMessageItemProps {
  chatStore?: ChatStore;
  i18nStore?: I18nStore;
  id: string;
  message: string;
  timestamp: number;
  adminName?: string;
  customData: any;
}

const QuestionMessageItem: FunctionComponent<QuestionMessageItemProps> = ({
  chatStore,
  i18nStore,
  id,
  message,
  timestamp,
  adminName,
  customData,
}) => {
  const classes = useStyles();
  const { localizedString } = i18nStore;
  const { isAdminView } = chatStore;

  const questionId = customData.questionId;
  const didAnswer = customData.didAnswer ?? false;
  const didConfirm = didAnswer && customData.answer ? customData.answer : false;

  let messageToDisplay = message;

  if (isAdminView) {
    if (questionId === QUESTION_MESSAGE_ID.didRequestAccessToContract) {
      if (didConfirm) {
        messageToDisplay = localizedString({
          id: "Chat@didRequestAccessToContract_didConfirmMessageForAdmin",
          defaultString: "Acesso ao contrato concedido pelo cliente.",
        });
      } else if (didAnswer) {
        messageToDisplay = localizedString({
          id: "Chat@didRequestAccessToContract_didAnswerMessageForAdmin",
          defaultString:
            "Cliente negou acesso ao contrato dele. Caso necessário explique melhor a situação e solicite novamente.",
        });
      } else {
        messageToDisplay = localizedString({
          id: "Chat@didRequestAccessToContract_waitingQuestionResponseForAdmin",
          defaultString:
            "Solicitou acesso ao contrato do cliente, aguardando aprovação.",
        });
      }
    }
  } else {
    if (didConfirm) {
      messageToDisplay = localizedString({
        id: "Chat@didRequestAccessToContract_didConfirmMessageForUser",
        defaultString: "Você concedeu acesso ao seu contrato.",
      });
    } else if (didAnswer) {
      messageToDisplay = localizedString({
        id: "Chat@didRequestAccessToContract_didAnswerMessageForUser",
        defaultString: "Você negou acesso ao seu contrato.",
      });
    }
  }

  return (
    <li
      key={id}
      className={classnames({
        [classes.container]: true,
        [classes.containerForAdmin]: isAdminView,
        [classes.containerDidAnswer]: didAnswer,
        [classes.containerDidConfirm]: didConfirm,
      })}
    >
      <MessageText message={messageToDisplay} />
      {isAdminView && (
        <MessageTimestamp
          timestamp={timestamp}
          isMineMessage={false}
          adminName={adminName}
        />
      )}
      {!isAdminView && !didAnswer && (
        <div>
          <Button
            className={classes.denyButton}
            color="danger"
            outline
            small
            onClick={() => chatStore.didCancelQuestion(id)}
          >
            Negar <i className="fal fa-thumbs-down" />
          </Button>
          <Button
            color="success"
            small
            onClick={() =>
              chatStore.didConfirmQuestion(id, customData.didConfirmAction)
            }
          >
            Conceder acesso <i className="fal fa-thumbs-up" />
          </Button>
        </div>
      )}
    </li>
  );
};

export default inject("chatStore", "i18nStore")(observer(QuestionMessageItem));
