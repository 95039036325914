// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { SimpleCard } from "../../components/Card";
import { Button } from "../../components/Button";
import { List, ListItem } from "../../components/List";

import AgeGroupRangeInput from "./AgeGroupRangeInput";

// Translate
import { AddString, UpdateString } from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

// Style
const styles = {
  label: {
    color: "#AAAAAA",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "uppercase",
  },
};

@inject("authStore", "notificationsStore")
@observer
class UserSystemPreferencesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaitingForm: false,
      ageRanges: this.props.authStore.ageRanges,
    };
  }

  onChangeAgeGroupRange = (changeIndex) => ({ title, startAge, finalAge }) => {
    this.setState((prevState) => {
      return {
        ageRanges: prevState.ageRanges.map(($0, index) => {
          if (changeIndex === index) {
            return {
              name: title,
              range: [startAge, finalAge],
            };
          }
          return $0;
        }),
      };
    });
  };

  didWantToAddAgeGroupRange = () => {
    this.setState((prevState) => {
      const last = prevState.ageRanges[prevState.ageRanges.length - 1];
      const startAge = last.range[1] + 1;
      return {
        ageRanges: [
          ...prevState.ageRanges,
          { name: `${startAge}+`, range: [startAge, 90], isEditing: true },
        ],
      };
    });
  };

  didDeleteAgeGroupRange = (deletedIndex) => () => {
    this.setState((prevState) => {
      return {
        ageRanges: prevState.ageRanges.filter(
          (_, index) => index !== deletedIndex
        ),
      };
    });
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({ isWaitingForm: true }, () => {
      const { ageRanges } = this.state;

      this.props.authStore
        .saveCurrentUserWebPreferences({
          age_ranges: ageRanges.map(($0) => ({
            name: $0.name,
            range: $0.range,
          })),
        })
        .then((response) => {
          const successMessage = response.data.message;
          this.setState(
            {
              isWaitingForm: false,
            },
            () => {
              this.props.notificationsStore.addSnackbarNotification({
                message: successMessage,
                timeout: 4000,
                color: "success",
              });
            }
          );
        })
        .catch(() => {
          this.setState({
            isWaitingForm: false,
          });
        });
    });
  };

  render() {
    const { isWaitingForm, ageRanges } = this.state;

    return (
      <SimpleCard cardStyle={{ margin: 0 }}>
        <h4>
          <Translate id="Profile@systemPreferencesTitle">
            Preferências do sistema
          </Translate>
        </h4>
        <p className={this.props.classes.label} style={{ margin: 0 }}>
          <Translate id="AgeGroup@ageGroupRangeConfigurationLabel">
            Grupos de faixa etária
          </Translate>
        </p>
        <List>
          {ageRanges.map((ageRange, index) => {
            return (
              <AgeGroupRangeInput
                key={index}
                index={index}
                title={ageRange.name}
                startAge={ageRange.range[0]}
                finalAge={ageRange.range[1]}
                onChange={this.onChangeAgeGroupRange(index)}
                didDelete={
                  ageRanges.length > 1
                    ? this.didDeleteAgeGroupRange(index)
                    : null
                }
                isEditing={ageRange.isEditing}
              />
            );
          })}
          <ListItem
            title={
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: 8,
                  textTransform: "uppercase",
                }}
              >
                <AddString /> <i className="fas fa-plus" />
              </div>
            }
            dense
            button
            onClick={this.didWantToAddAgeGroupRange}
          />
        </List>
        <Button
          color={"primary"}
          onClick={this.onSubmit}
          block
          isLoading={isWaitingForm}
        >
          <UpdateString />
          <i className="fas fa-save" />
        </Button>
      </SimpleCard>
    );
  }
}

export default withStyles(styles)(UserSystemPreferencesContainer);
