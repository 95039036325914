export const defaultAdditionalDataJson = (
  ageRanges: any,
  firstKeyName: string
) => {
  const per_age = {};
  ageRanges.forEach(($0) => {
    per_age[$0.name] = { male: 100, female: 100 };
  });
  return {
    [firstKeyName]: {
      location: {
        emotion: { neutral: 33, happy: 34, stressed: 33 },
        total: { male: 100, female: 100 },
        per_age,
      },
    },
  };
};
