// @ts-nocheck
import { observable, computed, action } from "mobx";

// Model
import PlaylistModel from "../../Model/PlaylistModel";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

export const isLiveContent = (mediaItem) => mediaItem.isLiveContent;
export const hasLiveContent = (mediasList) =>
  mediasList.filter(isLiveContent).length > 0;

export const isLiveStreaming = (mediaItem) => mediaItem.isLive;
export const hasLiveStreaming = (mediaList) =>
  mediaList.filter(isLiveStreaming).length > 0;

class PlaylistsStore {
  @observable dataList = [];
  @observable singlePlaylist = null;
  @observable isFetching = false;
  @observable searchString = "";

  constructor(
    contractStore,
    internalEventsStore,
    resourcesStore,
    notificationsStore,
    settingsStore,
    modboxAPIService,
    modboxFileService
  ) {
    this.contractStore = contractStore;
    this.resourcesStore = resourcesStore;
    this.notificationsStore = notificationsStore;
    this.settingsStore = settingsStore;
    this.modboxAPIService = modboxAPIService;
    this.modboxFileService = modboxFileService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.mediaWasDeleted,
      observer: this,
      callback: this.mediaWasDeleted,
    });
  }

  @action
  clearStore = () => {
    this.dataList = [];
    this.singlePlaylist = null;
    this.searchString = "";
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setDataList = (newValue) => {
    this.dataList = newValue;
  };
  @action
  setSinglePlaylist = (newValue) => {
    this.singlePlaylist = newValue;
  };

  @action
  setSearchString = (searchString) => {
    this.searchString = searchString;
  };

  mediaWasDeleted = ({ deletedMediaToken }) => {
    const newList = this.dataList.map((playlist) => {
      const filteredMediasList = playlist.mediasList.filter(
        (mediaItem) => mediaItem.token !== deletedMediaToken
      );

      if (filteredMediasList.length !== playlist.mediasList.length) {
        return {
          ...playlist,
          mediasList: filteredMediasList,
        };
      }
      return playlist;
    });

    this.setDataList(newList);
  };

  @computed
  get filteredDataList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.dataList,
        (playlist, textIncludesSearchString) =>
          textIncludesSearchString(playlist.label)
      );
    }

    return this.dataList;
  }

  getDataList = (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);
    let newPlaylistsList = [];

    this.modboxAPIService
      .requestPlaylists()
      .then((response) => {
        if (response.status === 200) {
          response.data.forEach((playlist) => {
            const newPlaylist = this.createPlaylistModel(playlist);
            newPlaylistsList.push(newPlaylist);
          });
        }
        this.setDataList(newPlaylistsList.sort(dynamicSortByProperty("label")));
        this.setIsFetching(false);
      })
      .catch((error) => {
        window.debugError("error in requestPlaylists", error);
        if (error && error.statusCode === 429) {
          setTimeout(this.getDataList, 2000);
        }
      });
  };

  getSinglePlaylist = async () => {
    let playlistResource = this.resourcesStore.singleResourceRule;

    if (!playlistResource || playlistResource.resourceType !== "playlist") {
      this.resourcesStore.clearStore();
      await this.resourcesStore.getResourceRulesList("playlist", false);
      playlistResource = this.resourcesStore.singleResourceRule;
    }

    try {
      const response = await this.modboxAPIService.requestPlaylist(
        playlistResource.resourceToken
      );
      if (response.status === 200 && response.data) {
        const playlist = this.createPlaylistModel(response.data);
        this.setSinglePlaylist(playlist);
      }
    } catch (error) {
      window.debugError("error in getSinglePlaylist", error);
      if (error && error.statusCode === 429) {
        setTimeout(this.getSinglePlaylist, 4000);
      }
    }
  };

  updateSinglePlaylistResource = (newSinglePlaylistResource) => {
    this.resourcesStore.update(newSinglePlaylistResource);
  };

  refreshResourcesStore = () => {
    this.resourcesStore.getResourceRulesList("playlist", false);
  };

  submit = (playlist) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitPlaylist(playlist)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: this.i18nStore.localizedString({
              id: "Schedule@readyToCreateRulesMessage",
              defaultString:
                "Configure uma regra para começar a utilizar o recurso.",
            }),
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  update = (playlist) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .updatePlaylist(playlist)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          if (this.contractStore.selectedContract.resources.playlistSchedule) {
            this.refreshResourcesStore();
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  delete = (playlist) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .deletePlaylist(playlist)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          this.refreshResourcesStore();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  clone = async (playlist, customContractHash) => {
    try {
      const response = await this.modboxAPIService.clonePlaylist(
        playlist,
        customContractHash
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  submitToMultipleContracts = async (data, contractsList) => {
    const sleep = (m) => new Promise((r) => setTimeout(r, m));
    try {
      await Promise.all(
        contractsList.map(async ({ hash }, index) => {
          await sleep(index * 200);
          await this.clone(data, hash);
        })
      );
      this.notificationsStore.addSnackbarNotification({
        message: this.i18nStore.localizedString({
          id: "Playlist@duplicateToMultipleContractsSuccessMessage",
          defaultString: "Playlist duplicada nos contratos selecionados!",
        }),
        color: "success",
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getWithToken = (token) => {
    if (this.dataList.length > 0) {
      return this.dataList.find((playlist) => playlist.token === token);
    }
    return null;
  };

  createPlaylistModel = (playlistData) => {
    return new PlaylistModel(
      {
        ...playlistData,
        durationToLiveContent:
          playlistData.duration_live ||
          this.settingsStore.informativeDurationToLiveContent,
      },
      this.modboxFileService
    );
  };
}

export default PlaylistsStore;
