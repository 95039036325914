// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";

// core components
import { GridContainer, GridItem } from "../../components/Grid";
import { Card, CardBody } from "../../components/Card";
import LoadingView from "../../components/LoadingView/LoadingView";
import NetworkConfigurationForm from "../../components/AppForm/NetworkConfigurationForm";
import UsageGridWidget from "../../components/Widgets/UsageGridWidget";

@inject("networksStore", "resourcesStore")
@observer
class ConnectivitySingleShareInternetContainer extends Component {
  componentDidMount() {
    this.props.networksStore.getSingleNetwork();
  }

  toggleIsActive = () => {
    const networkResource = this.props.resourcesStore.singleResourceRule;

    this.props.networksStore.updateSingleNetworkResource({
      ...networkResource,
      isActive: !networkResource.isActive,
    });
  };

  render() {
    const network = this.props.networksStore.singleNetwork;
    const networkResource = this.props.resourcesStore.singleResourceRule;

    if (
      this.props.networksStore.isFetching ||
      !network ||
      !networkResource ||
      networkResource.resourceType !== "network"
    ) {
      return <LoadingView />;
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ marginTop: 0 }}>
            <CardBody style={{ padding: "0 16px" }}>
              <GridContainer>
                <UsageGridWidget type="wifiAuthentication" />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card style={{ marginTop: 0 }}>
            <CardBody>
              <NetworkConfigurationForm
                network={network}
                isActive={networkResource.isActive}
                toggleIsActive={this.toggleIsActive}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default ConnectivitySingleShareInternetContainer;
