// @ts-nocheck
import axios from "axios";

const BASE_URL = "https://simplyas.com/modbox/wp-json/wp/v2/";

class WordPressAPIService {
  get = (method, data = {}) => {
    const publicApi = axios.create({
      baseURL: BASE_URL,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return new Promise((resolve, reject) => {
      publicApi
        .get(method, data)
        .then((response) => resolve(response))
        .catch((error) => {
          window.debugError("WordPress API error", error);
          reject(error);
        });
    });
  };

  getPosts = () => {
    return this.get("posts?per_page=4");
  };

  getFeaturedMedia = (mediaId) => {
    return this.get(`media/${mediaId}`);
  };

  getCategory = (categoryId) => {
    return this.get(`categories/${categoryId}`);
  };
}

export default WordPressAPIService;
