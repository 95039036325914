// @ts-nocheck

import { inject, observer } from "mobx-react";

import Translate from "../I18n/Translate";

import DateUnixString from "../Date/DateUnixString";
import DaysWeekString from "../Date/DaysWeekString";

// Utils
import { startOfTheDayMoment } from "../../utils/DateUtils";

const ScheduleText = inject("i18nStore")(
  observer(({ i18nStore, schedule, style }) => {
    const { currentLanguage, timeFormat } = i18nStore;
    const [timeStartHour, timeStartMinute] = schedule.time[0].split(":");
    const [timeEndHour, timeEndMinute] = schedule.time[1].split(":");
    const startTime = startOfTheDayMoment({
      hour: timeStartHour,
      minute: timeStartMinute,
    }).format(timeFormat);
    const endTime = startOfTheDayMoment({
      hour: timeEndHour,
      minute: timeEndMinute,
    }).format(timeFormat);

    return (
      <p
        style={{
          margin: 0,
          ...style,
        }}
      >
        {schedule["day_in"] ? (
          <span>
            <i className="fas fa-calendar" />{" "}
            {currentLanguage === "en" && "from "}
            <DateUnixString unixDate={schedule["day_in"]} />{" "}
            <Translate id="Date@until">à</Translate>{" "}
            <DateUnixString unixDate={schedule["day_out"]} />
          </span>
        ) : (
          <DaysWeekString daysWeek={schedule["days_week"]} />
        )}{" "}
        {currentLanguage === "pt" && (
          <span>
            {startTime}h às {endTime}h
          </span>
        )}
        {currentLanguage === "en" && (
          <span>
            from {startTime} to {endTime}
          </span>
        )}
      </p>
    );
  })
);

export default ScheduleText;
