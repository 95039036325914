// @ts-nocheck


import Translate from "../../components/I18n/Translate";

const NoMediasInPlaylistHint = () => (
  <p style={{ marginTop: 40, textAlign: "center" }}>
    <Translate id="Playlist@noMediasHint">
      Adicione as primeiras mídias nesta playlist utilizando o botão
    </Translate>{" "}
    <i className="fas fa-plus-square" />
  </p>
);

export default NoMediasInPlaylistHint;
