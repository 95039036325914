// @ts-nocheck

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";

import { GridContainer } from "../../components/Grid";
import Paginate from "../../components/Paginate/Paginate";
import MediaGridItem from "./MediaGridItem";
import MediaGridAddButton from "./MediaGridAddButton";

const styles = (theme) => ({
  panel: {
    background: "#EEEEEE",
  },
  expandedPanel: {
    background: "#E3F2FD",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    textTransform: "uppercase",
    fontWeight: 700,
    flexBasis: "30.0%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
});

const MediaGroupPanel = ({
  classes,
  title,
  subtitle,
  isExpanded,
  panelName,
  justMediaLibrary,
  didSelectPanel,
  mediaList,
  didChangeMediaItemSelectedQty,
  didSelectMediaItem,
  didWantToDeleteMedia,
  onClickAddButton,
}) => {
  const addButton = (
    <MediaGridAddButton onClick={onClickAddButton(panelName)} />
  );

  return (
    <ExpansionPanel
      className={classes.panel}
      expanded={isExpanded}
      onChange={() => didSelectPanel(panelName)}
    >
      <ExpansionPanelSummary
        expandIcon={
          <i className="fas fa-chevron-down" style={{ fontSize: 14 }} />
        }
      >
        <Typography className={classes.heading}>{title}</Typography>
        <Typography className={classes.secondaryHeading}>{subtitle}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails name={`${panelName}-group-panel`}>
        {mediaList.length > 0 ? (
          <GridContainer>
            {panelName !== "extras" && addButton}
            <Paginate itemsList={mediaList} itemsPerPage={14}>
              {({ slicedList }) =>
                slicedList.map((mediaItem, index) => (
                  <MediaGridItem
                    isLastItem={mediaList.length - 1 === index}
                    key={mediaItem.token}
                    mediaItem={mediaItem}
                    justMediaLibrary={justMediaLibrary}
                    didChangeSelectedQty={didChangeMediaItemSelectedQty}
                    didSelect={didSelectMediaItem}
                    didWantToDelete={didWantToDeleteMedia}
                  />
                ))
              }
            </Paginate>
          </GridContainer>
        ) : (
          addButton
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

MediaGroupPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaGroupPanel);
