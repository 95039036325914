// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import { withLocalized } from "../components/HOC";
import withStyles from "@material-ui/core/styles/withStyles";

import { GridContainer, GridItem } from "../components/Grid";
import { Button } from "../components/Button";
import { InputText } from "../components/Form";
import Modal from "../components/Modal/Modal";

import InfoLabel from "../components/Label/InfoLabel";
import LoadingView from "../components/LoadingView/LoadingView";
import { SimpleCard } from "../components/Card";
import UsageGridWidget from "../components/Widgets/UsageGridWidget";

import ChartWizard from "../components/Charts/ChartWizard";

import PageTitle from "../components/PageTitle/PageTitle";
import AccessByGroupChartContainer from "./connectivityMetricsReportPage/AccessByGroupChartContainer";
import HourlyTrafficChartContainer from "./connectivityMetricsReportPage/HourlyTrafficChartContainer";
import VisitorsChartContainer from "./connectivityMetricsReportPage/VisitorsChartContainer";
import WifiSavedChartView from "./connectivityMetricsReportPage/WifiSavedChartView";

import Translate from "../components/I18n/Translate";

import {
  WifiChartType,
  ModalType,
} from "../store/ReportsStore/MetricsReportStore";

// Style
const styles = {
  button: {
    padding: "6px 12px",
    marginRight: 4,
    marginLeft: 4,
  },
  buttonLabelBase: {
    display: "block",
    padding: "10px 8px",
    width: 120,
  },
};

@withLocalized
@inject("groupsStore", "wifiMetricsReportStore")
@observer
class ConnectivityMetricsReportPage extends Component {
  componentDidMount() {
    this.props.wifiMetricsReportStore.updateContractGroupsList();
  }

  componentWillUnmount() {
    this.props.wifiMetricsReportStore.clearStore();
  }

  onChangeChartTitle = ({ target }) => {
    this.props.wifiMetricsReportStore.onChangeSelectedChartName(target.value);
  };

  renderReportForType = (type, props, viewId = null) => {
    switch (type) {
      case WifiChartType.accessByGroup:
        return <AccessByGroupChartContainer {...props} />;
      case WifiChartType.hourlyTraffic:
        return (
          <HourlyTrafficChartContainer
            {...props}
            didSelectChartItem={this.didSelectHourlyTrafficChartItem(
              viewId,
              props.chartData
            )}
          />
        );
      case WifiChartType.visitors:
      default:
        return <VisitorsChartContainer {...props} />;
    }
  };

  renderSavedChartView = (option) => {
    return (
      <WifiSavedChartView
        {...option}
        titleForChartType={this.props.wifiMetricsReportStore.titleForChartType(
          option.type
        )}
      />
    );
  };

  didSelectHourlyTrafficChartItem = (viewId, chartData) => ({ x, y }) => {
    const daysOfWeekKeys = [
      "sun",
      "mon",
      "tue",
      "wed",
      "thu",
      "fri",
      "sat",
    ].reverse();
    const dayOfTheWeek = daysOfWeekKeys[y];
    const valueForSelectedPeriod = chartData.hourly_traffic[dayOfTheWeek][x];

    this.props.wifiMetricsReportStore.requestAdditionalChartDataForWifiHourlyTraffic(
      viewId,
      dayOfTheWeek,
      x,
      valueForSelectedPeriod
    );
  };

  renderChart = (viewId) => {
    const { wifiMetricsReportStore } = this.props;
    const chartView = wifiMetricsReportStore[viewId] || null;

    if (!chartView) return null;
    const groupsList = wifiMetricsReportStore.filteredGroupsList;

    const {
      type: chartType,
      isFetching,
      dateRange,
      fromDate,
      toDate,
      wizardCurrentStep,
      selectedGroupsList,
      chartData,
      targetAudience,
      macaddressRule,
      connectionTimeLimit,
      connectionType,
      error,
    } = chartView;
    const isSingleGroup = wifiMetricsReportStore.isSingleGroupForChartType(
      chartType
    );

    return (
      <ChartWizard
        color={viewId === "mainChartView" ? "primary" : "success"}
        allowToContinue={wifiMetricsReportStore.allowToContinue(viewId)}
        allowToExportData={wifiMetricsReportStore.allowToExportData(viewId)}
        savedCharts={wifiMetricsReportStore.userSavedCharts}
        isCompareChartActive={wifiMetricsReportStore.compareChartView !== null}
        didWantToManageSavedCharts={
          wifiMetricsReportStore.didWantToManageSavedCharts
        }
        didSelectSavedChart={wifiMetricsReportStore.didSelectSavedChart(viewId)}
        currentStep={wizardCurrentStep}
        disabledSteps={wifiMetricsReportStore.disabledStepsForView(viewId)}
        onChangeCurrentStep={wifiMetricsReportStore.didChangeChartWizardCurrentStep(
          viewId
        )}
        reportTypes={[
          {
            id: WifiChartType.accessByGroup,
            title: wifiMetricsReportStore.titleForChartType(
              WifiChartType.accessByGroup
            ),
          },
          {
            id: WifiChartType.hourlyTraffic,
            title: wifiMetricsReportStore.titleForChartType(
              WifiChartType.hourlyTraffic
            ),
          },
          {
            id: WifiChartType.visitors,
            title: wifiMetricsReportStore.titleForChartType(
              WifiChartType.visitors
            ),
          },
        ]}
        chartType={chartType}
        groupsList={groupsList}
        dateRange={dateRange}
        fromDate={fromDate}
        toDate={toDate}
        targetAudience={targetAudience}
        macaddressRule={macaddressRule}
        connectionTimeLimit={connectionTimeLimit}
        connectionType={connectionType}
        singleGroup={isSingleGroup}
        onChangeChartType={wifiMetricsReportStore.onChangeChartTypeForView(
          viewId
        )}
        onChangeGroupsListForView={wifiMetricsReportStore.onChangeGroupsListForView(
          viewId
        )}
        onChangeDateRange={wifiMetricsReportStore.onChangeDateRangeForView(
          viewId
        )}
        onChangeFromDate={wifiMetricsReportStore.onChangeFromDateForView(
          viewId
        )}
        onChangeTargetAudience={wifiMetricsReportStore.onChangeTargetAudience(
          viewId
        )}
        onChangeMacaddressRule={wifiMetricsReportStore.onChangeMacaddressRule(
          viewId
        )}
        onChangeConnectionTimeLimit={wifiMetricsReportStore.onChangeConnectionTimeLimit(
          viewId
        )}
        onChangeConnectionType={wifiMetricsReportStore.onChangeConnectionType(
          viewId
        )}
        onChangeToDate={wifiMetricsReportStore.onChangeToDateForView(viewId)}
        selectedGroupsList={selectedGroupsList}
        isFetchingGroups={this.props.groupsStore.isFetching}
        renderReport={this.renderReportForType(
          chartType,
          {
            isFetching,
            error,
            chartData,
            connectionType,
            groupsList: selectedGroupsList,
            datePeriod: { dateRange, fromDate, toDate },
          },
          viewId
        )}
        renderSavedChartView={this.renderSavedChartView}
        didWantToSaveChart={() =>
          wifiMetricsReportStore.didWantToSaveNewChart(viewId)
        }
        didWantToResetChart={() =>
          wifiMetricsReportStore.resetChartView(viewId)
        }
        didWantToStartNewChart={wifiMetricsReportStore.didWantToStartNewChart}
        didSelectAllGroups={() => {
          if (!isSingleGroup) {
            wifiMetricsReportStore.onChangeGroupsListForView(viewId)(
              groupsList.map(($0) => ({
                label: $0.name,
                value: $0.token,
              }))
            );
          }
        }}
        didWantToExportCsv={() =>
          wifiMetricsReportStore.exportChartViewToCsv(viewId)
        }
      />
    );
  };

  render() {
    const { wifiMetricsReportStore } = this.props;
    const {
      modal,
      didCloseModal,
      saveChartView,
      userSavedCharts,
      selectedSavedChartToEdit,
      filteredGroupsList,
      additionalChart,
    } = wifiMetricsReportStore;

    return (
      <Fragment>
        <PageTitle id="Menu@wifiMetricsReports">Relatórios Avançados</PageTitle>
        {modal.type !== null && (
          <Modal
            isOpen={modal.isOpen}
            modalTitle={modal.title}
            didCloseModal={didCloseModal}
            maxWidth={
              modal.type === ModalType.showAdditionalChartData
                ? "lg"
                : modal.type === ModalType.manageSavedCharts
                ? "md"
                : "sm"
            }
          >
            <GridContainer>
              {modal.type === ModalType.manageSavedCharts &&
                userSavedCharts.map((savedChart) => (
                  <GridItem key={savedChart.id} xs={12} sm={6}>
                    <SimpleCard cardStyle={{ marginTop: 0 }}>
                      <WifiSavedChartView
                        {...savedChart}
                        titleForChartType={wifiMetricsReportStore.titleForChartType(
                          savedChart.type
                        )}
                        isEditing
                        didWantToEdit={() =>
                          wifiMetricsReportStore.didWantToEditSavedChart(
                            savedChart
                          )
                        }
                        didWantToDelete={() =>
                          wifiMetricsReportStore.didWantToDeleteSavedChart(
                            savedChart.id
                          )
                        }
                      />
                    </SimpleCard>
                  </GridItem>
                ))}
              {modal.type === ModalType.chooseNewChartOption && (
                <GridItem xs={12}>
                  <Button
                    color="primary"
                    block
                    onClick={() => {
                      wifiMetricsReportStore.addCompareChart();
                      wifiMetricsReportStore.didCloseModal();
                    }}
                    style={{ marginBottom: 20 }}
                  >
                    <Translate id="Metrics@addCompareChartViewButtonLabel">
                      Manter relatório atual para comparação
                    </Translate>{" "}
                    <i className="fal fa-clone" />
                  </Button>
                  <Button
                    color="warning"
                    block
                    onClick={() => {
                      wifiMetricsReportStore.resetChartView("mainChartView");
                      wifiMetricsReportStore.didCloseModal();
                    }}
                  >
                    <Translate id="Metrics@resetChartViewButtonLabel">
                      Resetar relatório atual
                    </Translate>{" "}
                    <i className="fas fa-undo-alt" />
                  </Button>
                </GridItem>
              )}
              {(modal.type === ModalType.addSavedChart ||
                modal.type === ModalType.editSavedChart) && (
                <GridItem xs={12}>
                  {selectedSavedChartToEdit && (
                    <InputText
                      label={
                        <Translate id="Metrics@setSavedChartTitleLabel">
                          Defina um nome
                        </Translate>
                      }
                      autoFocus
                      name="chartTitle"
                      value={selectedSavedChartToEdit.label}
                      inputProps={{ autoComplete: "off" }}
                      onChange={this.onChangeChartTitle}
                    />
                  )}
                  <Button
                    color="primary"
                    block
                    disabled={!selectedSavedChartToEdit}
                    onClick={saveChartView}
                  >
                    {ModalType.editSavedChart ? (
                      <Translate id="Common@update">Atualizar</Translate>
                    ) : (
                      <Translate id="Common@save">Salvar</Translate>
                    )}{" "}
                    <i className="fas fa-save" />
                  </Button>
                </GridItem>
              )}
              {modal.type === ModalType.showAdditionalChartData && (
                <GridItem xs={12}>
                  {this.renderReportForType(additionalChart.type, {
                    ...additionalChart,
                    groupsList: additionalChart.selectedgroupsList,
                    datePeriod: {
                      dateRange: additionalChart.dateRange,
                      fromDate: additionalChart.fromDate,
                      toDate: additionalChart.toDate,
                      fromHour: additionalChart.fromHour,
                      toHour: additionalChart.toHour,
                    },
                  })}
                </GridItem>
              )}
            </GridContainer>
          </Modal>
        )}
        <SimpleCard
          cardStyle={{ marginTop: 0 }}
          cardBodyStyle={{ padding: "0 16px" }}
        >
          <GridContainer>
            <UsageGridWidget type="wifiAuthentication" />
          </GridContainer>
        </SimpleCard>

        {this.props.groupsStore.isFetching ? (
          <LoadingView />
        ) : filteredGroupsList.length < 1 ? (
          <InfoLabel large>
            <Translate id="Connectivity@noAvailableGroup">
              Você não possui nenhum grupo com o recurso Wi-Fi ativo no
              momento.
            </Translate>
          </InfoLabel>
        ) : (
          <GridContainer justify="center">
            <GridItem xs={12}>{this.renderChart("compareChartView")}</GridItem>
            <GridItem xs={12}>{this.renderChart("mainChartView")}</GridItem>
          </GridContainer>
        )}
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(ConnectivityMetricsReportPage),
  pageAllowedRule: "connectivity",
});
