// @ts-nocheck

import animationData from "./LoaderRing.json";
import LottieAnimation from "../LottieAnimation";

const LoaderRing = props => (
  <LottieAnimation animationData={animationData} {...props} />
);

export default LoaderRing;
