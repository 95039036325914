// @ts-nocheck


import InputSelect from "./InputSelect";
import Tag from "../Tags/Tag";

import { textIncludesSearchString } from "../../utils/Utils";

const SelectTags = ({ inputLabel, tags, options, onChange }) => (
  <InputSelect
    withPortal
    label={inputLabel}
    name="tags"
    value={tags}
    options={options}
    multiple
    onChange={(_, tags) => onChange(tags)}
    customOptionLabel={(option) => <Tag {...option} />}
    filterOption={(candidate, input) =>
      textIncludesSearchString(candidate.data.label, input)
    }
    valueKey="token"
  />
);

export default SelectTags;
