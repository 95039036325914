// @ts-nocheck
import { Component } from "react";
import { observer } from "mobx-react";
import LoadingView from "../components/LoadingView/LoadingView";

const withFetchingStore = (WrappedPageComponent) => {
  @observer
  class WithFetchingStoreHOC extends Component {
    render() {
      if (this.props.mainStore.isFetching) {
        return <LoadingView />;
      }

      return <WrappedPageComponent {...this.props} />;
    }
  }

  return WithFetchingStoreHOC;
};

export default withFetchingStore;
