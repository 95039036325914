import { Redirect, Route } from "react-router-dom";
import { computeBlockedRoutes } from "./routesUtils";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  selectedContract,
  ...rest
}) => {
  const blockedRoutes = computeBlockedRoutes(selectedContract);
  const currentPath = window.location.pathname;
  const baseRoute = currentPath.split("/")[1];

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) return <Redirect to="/login" />;

        if (blockedRoutes.includes(baseRoute)) {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
