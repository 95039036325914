// @ts-nocheck
import { Component } from "react";
import ReactDOM from "react-dom";
import { inject, observer } from "mobx-react";

import SimpleSound from "./SimpleSound";

@inject("soundStore")
@observer
class SoundManagerContainer extends Component {
  constructor(props) {
    super(props);

    soundManager.setup({ debugMode: false });
  }

  didFinishedPlaying = () => {
    this.props.soundStore.removeFeedbackSound();
  };

  render() {
    const { feedbackSound } = this.props.soundStore;
    if (!feedbackSound) return null;

    ReactDOM.createPortal(
      <SimpleSound
        src={feedbackSound}
        onFinishedPlaying={this.didFinishedPlaying}
      />,
      document.getElementById("sound-root")
    );

    return <div/>
  }
}

export default SoundManagerContainer;
