// @ts-nocheck

import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../../HOC";
import AreaChart from "../../Charts/AreaChart";

// Utils
import { limitForSelectedUnit } from "../../../utils/TemperatureUtils";

// Styles
import { warningColor, dangerColor } from "../../../assets/jss/app-js-styles";
const styles = {};

const TemperatureChart = ({
  classes,
  yAnnotation,
  localizedString,
  dateFormat,
  temperatureSymbol,
  currentTemperatureUnit,
  chartData,
}) => (
  <AreaChart
    height={500}
    data={[
      {
        name: localizedString({
          id: "Common@temperature",
          defaultString: "Temperatura",
        }),
        data: chartData.map(($0) => [$0.date * 1000, $0.temperature]),
      },
    ]}
    showToolbar
    customChartOptions={{
      animations: { enabled: false },
      toolbar: {
        tools: {
          download: false,
        },
      },
    }}
    customOptions={{
      yaxis: {
        min: limitForSelectedUnit(50, currentTemperatureUnit === "celcius"),
        max: limitForSelectedUnit(100, currentTemperatureUnit === "celcius"),
        labels: {
          formatter: (value) => `${value}${temperatureSymbol}`,
        },
      },
      annotations: {
        yaxis: [
          yAnnotation({
            value: limitForSelectedUnit(
              75,
              currentTemperatureUnit === "celcius"
            ),
            color: warningColor,
            label: localizedString({
              id: "DeviceAlert@issueIdentifiedStateMessage",
              defaultString: "Problema identificado",
            }),
          }),
          yAnnotation({
            value: limitForSelectedUnit(
              90,
              currentTemperatureUnit === "celcius"
            ),
            color: dangerColor,
            label: localizedString({
              id: "DeviceAlert@criticalStateMessage",
              defaultString: "Estado crítico",
            }),
          }),
        ],
      },
      xaxis: {
        type: "datetime",
        labels: {
          format: dateFormat,
          datetimeUTC: false,
        },
      },
      tooltip: {
        x: {
          format: dateFormat,
        },
        y: {
          formatter: (value) => `${value}${temperatureSymbol}`,
        },
      },
      dataLabels: { enabled: false },
    }}
  ></AreaChart>
);

export default withStyles(styles)(withLocalized(TemperatureChart));
