// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withPageAllowedRules from "./withPageAllowedRules";
import _debounce from "lodash/debounce";
import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../components/HOC";

import PageTitle from "../components/PageTitle/PageTitle";
import LoadingView from "../components/LoadingView/LoadingView";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import InfoLabel from "../components/Label/InfoLabel";
import SearchBar from "../components/SearchBar/SearchBar";
import { Button } from "../components/Button";
import UsageGridWidget from "../components/Widgets/UsageGridWidget";
import DatePicker from "../components/Form/DatePicker";
import Table from "../components/Table/Table";

// Translate
import Translate from "../components/I18n/Translate";

// Utils
import { secondsToMinutesAndHours } from "../utils/DateUtils";
import { objectListToCsv } from "../utils/ArrayUtils";
import {
  startOfTheDayMoment,
  endOfTheDayMoment,
  stringDateFromUnixDate,
  getNowMoment,
} from "../utils/DateUtils";

// Style
import { successColor } from "../assets/jss/app-js-styles";
const styles = (theme) => ({
  exportButton: {
    position: "absolute",
    left: 15,
    top: -10,
    [theme.breakpoints.down("sm")]: {
      position: "initial",
    },
  },
  footerContainer: {
    textAlign: "center",
    marginTop: 16,
    position: "relative",
  },
});

@withLocalized
@inject("contractStore", "reportsStore", "i18nStore", "routingStore")
@observer
class ConnectivityAuthenticationReportPage extends Component {
  state = {
    // fromDate: startOfTheDayMoment().subtract(1, "months"),
    fromDate: startOfTheDayMoment().subtract(7, "days"),
    toDate: endOfTheDayMoment(),
  };

  componentDidMount() {
    this.requestNewLogData();
    this.requestNewLogData.flush();
  }

  componentWillUnmount() {
    this.requestNewLogData.cancel();
  }

  didWantToUpdateLogData = () => {
    this.props.reportsStore.setIsFetching(true);

    this.requestNewLogData();
  };

  requestNewLogData = _debounce(() => {
    const { fromDate, toDate } = this.state;
    this.props.reportsStore.getConnectivityAuthenticationsLogs(
      fromDate.format("X"),
      toDate.format("X")
    );
  }, 2000);

  setNewDataFilter = (fromDate, toDate) => {
    this.setState(
      {
        fromDate,
        toDate,
      },
      () => {
        this.didWantToUpdateLogData();
      }
    );
  };

  onChangeFromDate = (_, newValue) => {
    const toDate = this.state.toDate.isBefore(newValue)
      ? newValue
      : this.state.toDate;
    this.setNewDataFilter(newValue, toDate);
  };

  onChangeToDate = (_, newValue) => {
    const fromDate = this.state.fromDate.isAfter(newValue)
      ? newValue
      : this.state.fromDate;
    this.setNewDataFilter(fromDate, newValue);
  };

  _exportFile = (reportLogs) => {
    const csvString = objectListToCsv(
      reportLogs.map((log) => ({
        date: log.date,
        identifier: log.phone,
        duration: log.duration,
        group: log.group,
      }))
    );
    let element = document.createElement("a");
    const file = new Blob([csvString], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `logs_wifi_${getNowMoment().format(
      this.props.i18nStore.dateFormatToExportFileName
    )}.csv`;
    element.click();
  };

  renderIcon = (icon, isValid, marginLeft = 0) => (
    <i
      className={`${icon} fa-fw`}
      style={{
        marginLeft,
        color: isValid ? "#4CD366" : "#dcdbdb",
      }}
    />
  );

  render() {
    const {
      classes,
      reportsStore,
      routingStore,
      contractStore,
      i18nStore,
      localizedString,
      localizedPlural,
    } = this.props;
    const reportLogs = reportsStore.connectivityAuthenticationLogs;
    const filteredReportLogs =
      reportsStore.filteredConnectivityAuthenticationLogs;

    const { connectivity } = contractStore.selectedContract.resources;

    return (
      <Fragment>
        <PageTitle id="Menu@authenticationsReport">
          Relatório de autenticação
        </PageTitle>
        <SimpleCard
          cardStyle={{ marginTop: 0 }}
          cardBodyStyle={{ padding: "0 16px" }}
        >
          <GridContainer>
            <UsageGridWidget type="wifiAuthentication" />
          </GridContainer>
        </SimpleCard>
        <SimpleCard cardStyle={{ zIndex: 1000 }}>
          <GridContainer style={{ marginBottom: 16 }}>
            <GridItem xs={12} sm={6}>
              <DatePicker
                label={
                  <Translate id="Advertising@startDateFilter">
                    Data início
                  </Translate>
                }
                name="fromDate"
                value={this.state.fromDate}
                onChange={this.onChangeFromDate}
                fullWidth
                timeFormat
                removeMargin
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <DatePicker
                label={
                  <Translate id="Advertising@endDateFilter">Data fim</Translate>
                }
                name="toDate"
                value={this.state.toDate}
                onChange={this.onChangeToDate}
                fullWidth
                timeFormat
                removeMargin
              />
            </GridItem>
            <GridItem xs={12} style={{ marginTop: 20 }}>
              <SearchBar
                value={reportsStore.searchString}
                onChange={reportsStore.setSearchString}
              />
            </GridItem>
          </GridContainer>
        </SimpleCard>
        {reportsStore.isFetching ? (
          <LoadingView />
        ) : (
          <SimpleCard cardStyle={{ marginTop: 8, marginBottom: 8 }}>
            <GridContainer>
              <GridItem xs={12}>
                {reportLogs.length > 0 ? (
                  filteredReportLogs.length > 0 ? (
                    <Table
                      data={filteredReportLogs.map((log) => {
                        const {
                          duration: durationInSeconds,
                          operator,
                          people_token,
                          phone,
                        } = log;

                        const shouldDisplayModal = connectivity && people_token;

                        return {
                          id: log.mac_address,
                          hardware: log.vendor !== "None" ? log.vendor : "",
                          date: stringDateFromUnixDate(
                            log.date,
                            `${i18nStore.dateFormatWithFourDigitsYear} ${i18nStore.timeFormat}`
                          ),
                          identifier: log.phone || "",
                          maxTime:
                            log.status === "online" ? (
                              <i
                                className="fas fa-circle"
                                style={{ fontSize: 10, color: successColor }}
                              />
                            ) : durationInSeconds ? (
                              secondsToMinutesAndHours(durationInSeconds)
                            ) : (
                              ""
                            ),
                          group: log.group,
                          status: (
                            <Fragment>
                              <div
                                onClick={() =>
                                  showPeople({
                                    operator,
                                    people: people_token,
                                    phone,
                                  })
                                }
                                style={{
                                  cursor: shouldDisplayModal
                                    ? "pointer"
                                    : undefined,
                                }}
                              >
                                {this.renderIcon(
                                  "fas fa-user",
                                  log.people_token.length > 0
                                )}
                                {this.renderIcon(
                                  "fab fa-facebook",
                                  log.facebook === "True"
                                )}
                                {this.renderIcon(
                                  "fab fa-whatsapp",
                                  log.whatsapp === "True"
                                )}
                              </div>
                            </Fragment>
                          ),
                        };
                      })}
                      columns={[
                        {
                          Header: localizedString({
                            id: "Common@date",
                            defaultString: "Data",
                          }),
                          accessor: "date",
                        },
                        {
                          Header: localizedString({
                            id: "Connectivity@identifier",
                            defaultString: "Identificador",
                          }),
                          accessor: "identifier",
                          sortable: false,
                        },
                        {
                          Header: localizedString({
                            id: "Connectivity@maxTime",
                            defaultString: "Tempo Máximo",
                          }),
                          accessor: "maxTime",
                          sortable: false,
                        },
                        {
                          Header: localizedString({
                            id: "Connectivity@hardwareHeaderLabel",
                            defaultString: "Equipamento",
                          }),
                          accessor: "hardware",
                        },
                        {
                          Header: localizedString({
                            id: "Connectivity@group",
                            defaultString: "Grupo",
                          }),
                          accessor: "group",
                        },
                        {
                          Header: localizedString({
                            id: "Connectivity@statusHeaderLabel",
                            defaultString: "Status",
                          }),
                          accessor: "status",
                          sortable: false,
                          getHeaderProps: () => {
                            return {
                              style: {
                                textAlign: "left",
                              },
                            };
                          },
                          getProps: () => {
                            return {
                              style: {
                                textAlign: "left",
                              },
                            };
                          },
                        },
                      ]}
                    />
                  ) : (
                    <p>
                      <Translate id="Connectivity@noResultsForCurrentFiltersLabel">
                        Nada encontrado para a pesquisa atual.
                      </Translate>
                    </p>
                  )
                ) : (
                  <InfoLabel
                    large
                    style={{ textAlign: "center", margin: "30px 0" }}
                  >
                    <Translate id="Connectivity@noReportDataMessage">
                      Não encontramos informações relevantes para exibir neste
                      momento.
                    </Translate>
                  </InfoLabel>
                )}
              </GridItem>
            </GridContainer>
          </SimpleCard>
        )}
        {!reportsStore.isFetching && filteredReportLogs.length > 0 && (
          <GridContainer>
            <GridItem xs={12} className={classes.footerContainer}>
              <Button
                small
                className={classes.exportButton}
                onClick={() => this._exportFile(filteredReportLogs)}
              >
                <Translate id="Common@export">Exportar</Translate>{" "}
                <i className="fas fa-file-export" />
              </Button>
              <InfoLabel hideIcon>
                <Translate
                  id="Connectivity@reportQuantityLabel"
                  variables={{
                    logsCount: localizedPlural({
                      id: "Connectivity@logsQtyLabel",
                      value: filteredReportLogs.length,
                      zero: "-",
                      one: "# registro",
                      other: "# registros",
                    }),
                    phonesCount: localizedPlural({
                      id: "Connectivity@phonesQtyLabel",
                      value: [
                        ...new Set(filteredReportLogs.map(($0) => $0.phone)),
                      ].length,
                      zero: "sem nenhum número telefônico",
                      one: "sendo apenas um telefone",
                      other: "sendo # telefones únicos",
                    }),
                  }}
                >
                  Total de $logsCount, $phonesCount.
                </Translate>
              </InfoLabel>
            </GridItem>
          </GridContainer>
        )}
      </Fragment>
    );

    function showPeople({ operator = "", people = "", phone = "" }) {
      if (people && connectivity) {
        routingStore.openPeopleModal({
          current: routingStore.location.state,
          ...{
            phoneNumber: phone,
            phoneOperator: operator,
            peopleToken: people,
          },
        });
      }
    }
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(ConnectivityAuthenticationReportPage),
  pageAllowedRule: "connectivity",
});
