// @ts-nocheck
import { Component, Fragment } from "react";

import { ListItem } from "../../components/List";
import { InputText } from "../../components/Form";
import Modal from "../../components/Modal/Modal";
import { Button } from "../../components/Button";

import Slider from "../../components/Slider/Slider";

import Translate from "../../components/I18n/Translate";
import { SaveString } from "../../components/I18n/CommonStrings";

class AgeGroupRangeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: props.isEditing || false,
    };
  }

  toggleIsEditing = () => {
    this.setState((prevState) => {
      return { isEditing: !prevState.isEditing };
    });
  };

  didChange = ({ title, startAge, finalAge }) => {
    this.props.onChange({
      title,
      startAge: parseInt(startAge, 10),
      finalAge: parseInt(finalAge, 10),
    });
  };

  render() {
    const { index, title, startAge, finalAge, didDelete } = this.props;
    const { isEditing } = this.state;
    return (
      <>
        <Modal
          isOpen={isEditing}
          maxWidth={"sm"}
          didCloseModal={this.toggleIsEditing}
        >
          <InputText
            autoFocus
            label={
              <Translate id="AgeGroup@nameInputLabel">Nome do grupo</Translate>
            }
            name={`title${index}`}
            value={title}
            onChange={({ target }) => {
              this.didChange({
                title: target.value,
                startAge,
                finalAge,
              });
            }}
          />
          <p style={{ marginBottom: 40 }}>
            <Translate id="AgeGroup@sliderInputLabel">
              Defina a faixa etária:
            </Translate>
          </p>
          <Slider
            values={[startAge, finalAge]}
            min={0}
            max={90}
            step={1}
            connect={[false, true, false]}
            onChange={(render, handle, value, un, percent) => {
              this.didChange({
                title,
                startAge: value[0].toFixed(0),
                finalAge: value[1].toFixed(0),
              });
            }}
          />
          <Button
            color="success"
            block
            onClick={this.toggleIsEditing}
            style={{ marginTop: 20 }}
          >
            <SaveString /> <i className="fas fa-save" />
          </Button>
        </Modal>
        <ListItem
          dense
          title={title}
          divider
          description={
            <span>
              {startAge}{" "}
              <i
                className="fas fa-arrow-alt-right"
                style={{ margin: "0 8px" }}
              />{" "}
              {finalAge}
            </span>
          }
          icon={"chevron-right"}
          trailing={
            <Fragment>
              <Button
                color="info"
                justIcon
                small
                onClick={this.toggleIsEditing}
                style={{ marginRight: 6 }}
              >
                <i className="fas fa-pen" />
              </Button>
              {didDelete && (
                <Button color="danger" justIcon small onClick={didDelete}>
                  <i className="fas fa-trash-alt" />
                </Button>
              )}
            </Fragment>
          }
        />
      </>
    );
  }
}

export default AgeGroupRangeInput;
