// @ts-nocheck


import { withLocalized } from "../HOC";
import { ButtonGroup } from "../Form";
import NumberEditingWithLabelInput from "../Form/NumberEditingWithLabelInput";

import Translate from "../I18n/Translate";

const ConnectionTimeLimitFilter = ({
  localizedString,
  connectionTimeLimit,
  onChange,
}) => {
  const filter =
    connectionTimeLimit === null
      ? "all"
      : connectionTimeLimit >= 0
      ? "more"
      : "less";

  const didChange = ({ newFilter, newMinutes }) => {
    const minutes = newMinutes
      ? newMinutes
      : connectionTimeLimit !== null
      ? connectionTimeLimit
      : 10;
    const fnFilter = newFilter ? newFilter : filter;

    if (fnFilter === "all") {
      onChange(null);
    } else {
      onChange(fnFilter === "more" ? Math.abs(minutes) : -Math.abs(minutes));
    }
  };

  return (
    <>
      <ButtonGroup
        small
        name="connectionTimeLimit"
        value={filter}
        color="primary"
        onChange={(_, selectedValue) => {
          didChange({ newFilter: selectedValue });
        }}
        options={[
          {
            value: "less",
            label: localizedString({
              id: "Metrics@connectionTimeLimitLessOption",
              defaultString: "Menor ou igual",
            }),
          },
          {
            value: "more",
            label: localizedString({
              id: "Metrics@connectionTimeLimitMoreOption",
              defaultString: "Maior ou igual",
            }),
          },
          {
            value: "all",
            label: localizedString({
              id: "Common@all",
              defaultString: "Todos",
            }),
          },
        ]}
      />
      {filter !== "all" && (
        <NumberEditingWithLabelInput
          label={
            <Translate id="Metrics@connectionTimeLimitInputLabel">
              Limitar o filtro em:
            </Translate>
          }
          typeLabel={<Translate id="Common@minutesString">minutos</Translate>}
          onChange={(value) => didChange({ newMinutes: value })}
          value={
            connectionTimeLimit === null ? 0 : Math.abs(connectionTimeLimit)
          }
        />
      )}
    </>
  );
};

export default withLocalized(ConnectionTimeLimitFilter);
