// @ts-nocheck

import _isString from "lodash/isString";

import { HOST_URL } from "../../utils/EnvUtils";

const TextToHTML = (props) => {
  const detectLinksFromText = (text) => {
    const tokens = text.split(/\s/);

    return tokens.map((token, i) => {
      const hasSpace = i !== tokens.length - 1;
      const maybeSpace = hasSpace ? " " : "";

      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#%?=~_|!:,.;]*[-A-Z0-9+&@#%=~_|])/gi;
      if (token.match(urlRegex)) {
        return (
          <a
            key={i}
            href={token}
            onClick={(event) => {
              if (event) event.preventDefault();
              const target = token.includes(HOST_URL) ? "_self" : "_blank";
              window.open(token, target);
            }}
            style={{ cursor: "pointer" }}
            className={props.classes.link}
          >
            {token}
            {maybeSpace}
          </a>
        );
      } else {
        return token + maybeSpace;
      }
    });
  };

  if (_isString(props.children)) {
    return props.children.split("\n").map((item, index) => (
      <p key={index} {...props}>
        {detectLinksFromText(item)}
        <br />
      </p>
    ));
  }
  console.warn(
    "Attempted to use <HyperText> with nested components. " +
      "This component only supports plain text children."
  );
  return <p {...props} />;
};

TextToHTML.defaultProps = {
  classes: {
    link: {},
  },
};

export default TextToHTML;
