import { Fragment, CSSProperties, FC } from "react";
import withLocalized from "../../../components/HOC/withLocalized";
import { makeStyles } from "@material-ui/styles";

import Badge from "../../../components/Badge/Badge";
import FormattedLabelWithVariableProps from "../../../components/Label/FormattedLabelWithVariableProps";

import { readQueueValue } from "./QueueUtils";

// Styles
const useStyles = makeStyles({
  container: {
    border: "4px solid #000",
    position: "relative",
    paddingTop: "56.25%",
  },
  valueContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    minHeight: 100,
    fontSize: "1.2em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  valueLine: {
    textAlign: "center",
    margin: "2px 0",
    fontWeight: "bold",
  },
});

interface QueueValueViewProps {
  localizedString?: any;
  value: string;
  backgroundColor?: string;
  fontColor?: string;
  ignoreContainer?: boolean;
  lineStyle?: CSSProperties;
}

const QueueValueView: FC<QueueValueViewProps> = ({
  localizedString,
  value,
  backgroundColor,
  fontColor,
  ignoreContainer = false,
  lineStyle = {},
}) => {
  const classes = useStyles();
  const [firstLineValue, secondLineValue] = readQueueValue(value);

  const renderBadge = (key: string, label: string) => (
    <Badge key={key} color="gray" style={{ margin: "0 4px" }}>
      {label}
    </Badge>
  );

  const renderFormattedValue = (lineValue: string) => (
    <FormattedLabelWithVariableProps label={lineValue}>
      {(key: string, value: string) =>
        renderBadge(
          key,
          value.replace(
            "var",
            localizedString({
              id: "Common@value",
              defaultString: "valor",
            })
          )
        )
      }
    </FormattedLabelWithVariableProps>
  );

  const calculateLineFontSize = (lineText: string): string => {
    const textLength = lineText.length;
    if (textLength < 6) {
      return "2em";
    } else if (textLength < 11) {
      return "1.5em";
    } else if (textLength > 20) {
      return "0.8em";
    } else if (textLength > 14) {
      return "0.9em";
    }
    return "1em";
  };

  const renderedValues = (
    <Fragment>
      <p
        className={classes.valueLine}
        style={{
          ...lineStyle,
          fontSize: calculateLineFontSize(firstLineValue),
        }}
      >
        {renderFormattedValue(firstLineValue)}
      </p>
      {secondLineValue && (
        <p
          className={classes.valueLine}
          style={{
            ...lineStyle,
            fontSize: calculateLineFontSize(secondLineValue),
          }}
        >
          {renderFormattedValue(secondLineValue)}
        </p>
      )}
    </Fragment>
  );

  if (ignoreContainer) return renderedValues;

  return (
    <div className={classes.container}>
      <div
        className={classes.valueContainer}
        style={{
          background: backgroundColor,
          color: fontColor,
        }}
      >
        {renderedValues}
      </div>
    </div>
  );
};

export default withLocalized(QueueValueView);
