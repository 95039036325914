// @ts-nocheck
import { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import LoadingView from "../components/LoadingView/LoadingView";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import SearchBar from "../components/SearchBar/SearchBar";
import Modal from "../components/Modal/Modal";
import { InputSwitch } from "../components/Form";
import { Button } from "../components/Button";
import Table from "../components/Table/Table";

import I18nForm from "../components/AppForm/I18nForm";

import { isProductionServer } from "../utils/EnvUtils";

// Translate
import Translate from "../components/I18n/Translate";
import Plural from "../components/I18n/Plural";

// Icon
import { AddIcon } from "../components/Icon";

const kEmptyLanguageString = "------------------";

@inject("i18nStore")
@observer
class I18nPage extends Component {
  state = {
    isOpenModal: false,
    selectedStringText: null,
  };

  getLanguageRowProps = (state, rowInfo, column) => {
    if (!rowInfo) return {};
    if (rowInfo.row[column.id] === kEmptyLanguageString) {
      return {
        style: {
          textAlign: "center",
          color: "red",
          border: "1px dashed red",
        },
      };
    }
    return {
      style: {
        textAlign: "left",
      },
    };
  };

  didCloseModal = () => {
    this.setState(
      {
        isOpenModal: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            selectedStringText: null,
          });
        }, 200);
      }
    );
  };

  didWantToAddNewString = () => {
    this.setState({
      isOpenModal: true,
    });
  };

  didWantToEditString = (textStringId) => {
    const selectedStringText = this.props.i18nStore.i18nList.find(
      (stringText) => stringText.id === textStringId
    );

    this.setState({
      selectedStringText,
      isOpenModal: true,
    });
  };

  readLanguage = (textObject, isPlural) => {
    // Verify if is valid
    if (textObject === null || textObject === undefined) {
      return kEmptyLanguageString;
    }
    if (textObject.length < 1) {
      return kEmptyLanguageString;
    }

    // Verify if is Plural
    if (isPlural) {
      return (
        <span>
          <strong>Zero:</strong> {textObject.zero || kEmptyLanguageString}
          <br />
          <strong>Um:</strong> {textObject.one || kEmptyLanguageString}
          <br />
          <strong>Outros:</strong> {textObject.other || kEmptyLanguageString}
        </span>
      );
    }

    return textObject;
  };

  render() {
    if (isProductionServer) {
      return <Redirect to="/" />;
    }

    const { i18nStore } = this.props;
    if (i18nStore.isFetching) {
      return <LoadingView />;
    }
    const filteredI18nList = i18nStore.filteredI18nList;

    const { selectedStringText, isOpenModal } = this.state;

    return (
      <Fragment>
        <PageTitle id="Menu@i18n">I18n</PageTitle>
        <Modal isOpen={isOpenModal} didCloseModal={this.didCloseModal}>
          <I18nForm
            stringText={selectedStringText}
            didSave={this.didCloseModal}
            didCancel={this.didCloseModal}
          />
        </Modal>
        <SimpleCard>
          <GridContainer>
            <GridItem xs={12} sm>
              <Button
                color="primary"
                small
                disabled={
                  this.props.authStore.currentUser.email !==
                  "darlan.caten@simplyas.com"
                }
                onClick={this.didWantToAddNewString}
                style={{ marginRight: 50 }}
              >
                <Translate id="I18n@addTextButton">Adicionar texto</Translate>{" "}
                <AddIcon />
              </Button>
              <InputSwitch
                label={
                  <Translate id="I18n@filterOnlyPending">
                    Mostrar apenas pendentes
                  </Translate>
                }
                name="filterOnlyPending"
                isActive={i18nStore.filterOnlyPending}
                onChange={i18nStore.toggleFilterOnlyPending}
              />
            </GridItem>
            <GridItem xs={12} style={{ marginTop: 20 }}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <SearchBar
                    value={i18nStore.searchString}
                    onChange={i18nStore.setSearchString}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <p
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      fontSize: "1.2em",
                    }}
                  >
                    #{" "}
                    <Plural
                      id="I18n@textsCount"
                      value={i18nStore.textsCount}
                      zero="Nada encontrado"
                      one="Um texto encontrado"
                      other="Exibindo # textos"
                    />
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <Table
                data={filteredI18nList.map((textString) => ({
                  id: textString.id,
                  key: textString.id,
                  pt: this.readLanguage(textString.pt, textString.isPlural),
                  en: this.readLanguage(textString.en, textString.isPlural),
                }))}
                columns={[
                  {
                    Header: <Translate id="I18n@key">Chave</Translate>,
                    accessor: "key",
                  },
                  {
                    Header: (
                      <Translate id="I18n@portuguese">Português</Translate>
                    ),
                    accessor: "pt",
                    getProps: this.getLanguageRowProps,
                  },
                  {
                    Header: <Translate id="I18n@english">Inglês</Translate>,
                    accessor: "en",
                    getHeaderProps: () => ({
                      style: {
                        textAlign: "left",
                      },
                    }),
                    getProps: this.getLanguageRowProps,
                  },
                ]}
                getTrProps={(state, rowInfo) => ({
                  style: {
                    cursor: rowInfo && rowInfo.original ? "pointer" : "auto",
                  },
                })}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo && rowInfo.original) {
                        this.didWantToEditString(rowInfo.original.id);
                      }
                      if (handleOriginal) {
                        handleOriginal();
                      }
                    },
                  };
                }}
              />
            </GridItem>
          </GridContainer>
        </SimpleCard>
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: I18nPage,
  pageAllowedRule: "simplyas",
});
