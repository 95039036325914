// @ts-nocheck


import Translate from "../I18n/Translate";

const MessageCharactersRemaining = ({ messageLength, maxLength }) => {
  const variables = {
    messageLength,
    characteresLeft: maxLength - messageLength,
  };

  if (messageLength >= maxLength) {
    return (
      <Translate id="Common@messageMaxLengthHint" variables={variables}>
        Tamanho máximo atingido
      </Translate>
    );
  }
  if (messageLength === maxLength - 1) {
    return (
      <Translate id="Common@messageLengthHintOneLeft" variables={variables}>
        Apenas 1 caractere restante
      </Translate>
    );
  }

  return (
    <Translate id="Common@messageLengthHint" variables={variables}>
      $characteresLeft caracteres restantes
    </Translate>
  );
};

MessageCharactersRemaining.defaultProps = {
  maxLength: 120,
};

export default MessageCharactersRemaining;
