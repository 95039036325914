// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import {
  backgroundColorOptions,
  fontColorOptions,
} from "../../utils/ColorUtils";

// Components
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";
import { InputText, InputSwitch, InputColorPicker } from "../Form";
import { Button } from "../Button";
import { GridContainer, GridItem } from "../Grid";
import { withLocalized } from "../HOC";
import LedAreaForm from "../../components/LedArea/LedAreaForm";

// Translate
import {
  DeleteString,
  UpdateString,
  SaveString,
  LabelHintString,
  BackgroundColorString,
  FontColorString,
  TransparentString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Icon
import { SaveIcon, DeleteIcon } from "../Icon";

// Util
import { generateRandomLabel } from "../../utils/StringUtils";

@withLocalized
@inject("informativeClocksStore")
@observer
class ClockConfigurationForm extends Component {
  constructor(props) {
    super(props);

    if (props.clock) {
      this.state = this.getStateFromExistingClock(props.clock);
    } else {
      this.state = this.getDefaultState();
    }
  }

  getDefaultState = () => {
    return {
      label: "",
      backgroundColor: "#1D1D1D",
      fontColor: "#FFFFFF",
      isTransparent: false,
      isVertical: false,
      isLed: false,
      ledArea: "",
      modalIsOpen: false,
      isWaitingForm: false,
      didWantToDelete: false,
    };
  };

  getStateFromExistingClock = (clock) => {
    return {
      ...this.getDefaultState(),
      ...clock,
      isLed: !!clock.ledArea,
    };
  };

  onChangeInputText = ({ target }) => {
    this.setState({ label: target.value });
  };

  onChangeInputColorPicker = ({ name: key, color }) => {
    this.setState({ [key]: color });
  };

  onChangeSwitch = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  didWantToAddLedArea = () => {
    this.setState({
      modalIsOpen: true,
      isLed: true,
    });
  };

  toggleIsEditingLedArea = () => {
    this.setState(prevState => ({
      isLed: !prevState.isLed,
    }));
  };

  getFormDataToSubmit = ({
    label,
    backgroundColor,
    fontColor,
    isTransparent,
    isVertical,
    isLed,
    ledArea,
  }) => {
    return {
      label:
        label.length > 0
          ? label
          : generateRandomLabel(
            this.props.localizedString({
              id: "Menu@clock",
              defaultString: "Relógio",
            })
          ),
      backgroundColor,
      fontColor,
      isTransparent,
      isVertical,
      isLed,
      ledArea,
    };
  };

  submitForm = () => {
    const { clock } = this.props;
    const formData = this.getFormDataToSubmit(this.state);

    if (clock) {
      this.props.informativeClocksStore
        .update({
          ...formData,
          clockToken: clock.token,
        })
        .then(() => {
          this.submitHasSuccess({ token: null });
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      this.props.informativeClocksStore
        .submit(formData)
        .then((response) => {
          this.submitHasSuccess({ token: response.data.token });
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  didWantToDelete = (event) => {
    event.preventDefault();

    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.informativeClocksStore
      .delete({
        clockToken: this.props.clock.token,
      })
      .then(() => {
        this.props.didDelete();
      });
  };

  onChangeLedArea = (ledArea) => {
    this.setState({ ledArea });
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.setState({ isWaitingForm: true }, () => {
      this.submitForm();
    });
  };

  submitHasSuccess = (token) => {
    this.props.didSave();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  render() {
    const isEditing = this.props.clock;
    const {
      label,
      backgroundColor,
      fontColor,
      isTransparent,
      isVertical,
      isLed,
      ledArea,
      isWaitingForm,
      didWantToDelete,
    } = this.state;

    return (
      <Fragment>
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteConfiguration}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <InputText
              label={
                <Translate id="Clock@titleLabel">
                  Título da configuração (opcional)
                </Translate>
              }
              name="label"
              icon="text-width"
              isRequired
              value={label}
              onChange={this.onChangeInputText}
              maxLength={30}
              helpText={<LabelHintString />}
            />
            <InputColorPicker
              label={<BackgroundColorString />}
              name="backgroundColor"
              options={backgroundColorOptions}
              color={backgroundColor}
              onChange={this.onChangeInputColorPicker}
            />
            <InputColorPicker
              label={<FontColorString />}
              name="fontColor"
              options={fontColorOptions}
              color={fontColor}
              onChange={this.onChangeInputColorPicker}
            />
            <InputSwitch
              name="isTransparent"
              isActive={isTransparent}
              onChange={this.onChangeSwitch}
              label={<TransparentString />}
            />
            <InputSwitch
              label={
                <Translate id="Common@isVerticalLabel">Vertical</Translate>
              }
              name="isVertical"
              isActive={isVertical}
              onChange={this.onChangeSwitch}
            />

            <InputSwitch
              label="Led"
              name="isLed"
              isActive={isLed}
              onChange={this.toggleIsEditingLedArea}
            />
            {isLed && (
              <LedAreaForm onChange={this.onChangeLedArea} value={ledArea} />
            )}

            <GridContainer justify="space-between">
              {isEditing && (
                <GridItem xs={12} sm={4}>
                  <Button color="danger" block onClick={this.didWantToDelete}>
                    <DeleteString /> <DeleteIcon />
                  </Button>
                </GridItem>
              )}
              <GridItem xs sm>
                <Button
                  color="primary"
                  block
                  isLoading={isWaitingForm}
                  onClick={this.onSubmit}
                >
                  {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default ClockConfigurationForm;
