import moment from "moment";

export class PostCategory {
  id: string;
  link: string;
  title: string;

  constructor(json: any) {
    this.id = json.id;
    this.link = json.link;
    this.title = json.title;
  }
}

class PostModel {
  id: string;
  link: string;
  title: string;
  date: moment.Moment;
  excerpt: string;
  imageUrl?: string;
  category: PostCategory;

  constructor(json: any) {
    this.id = json.id;
    this.link = json.link;
    this.title = json.title;
    this.date = json.date;
    this.excerpt = json.excerpt;
    this.imageUrl = json.imageUrl;
    this.category = new PostCategory(json.category);
  }

  get displayDate(): string {
    return this.date.format("DD/MM/YYYY");
  }
}

export default PostModel;
