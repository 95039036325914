// @ts-nocheck

import animationData from "./EmptyStatus.json";
import LottieAnimation from "../LottieAnimation";

const EmptyStatus = props => (
  <LottieAnimation animationData={animationData} {...props} />
);

export default EmptyStatus;
