// @ts-nocheck
import { Component, createRef } from "react";
import Cropper from "cropperjs";

import withStyles from "@material-ui/core/styles/withStyles";

import Button from "../Button/Button";

// Styles
import "cropperjs/dist/cropper.min.css";
const styles = {
  img: {
    display: "block",
    width: "100%",
  },
};

const CustomButton = ({ icon, ...rest }) => (
  <Button color="primary" small justIcon {...rest}>
    <i className={`fas fa-${icon}`} />
  </Button>
);

class ImageCropper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dragMode: "crop",
    };

    this.imgRef = createRef();
  }

  componentDidMount() {
    this.createCropper();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.resizable !== this.props.resizable ||
      prevProps.aspectRatio !== this.props.aspectRatio
    ) {
      this.cropper.destroy();
      this.createCropper();
    }
  }

  componentWillUnmount() {
    if (this.imgRef) {
      // Destroy the cropper, this makes sure events such as resize are cleaned up and do not leak
      this.cropper.destroy();
      delete this.imgRef;
      delete this.cropper;
    }
  }

  createCropper = () => {
    const { aspectRatio, resizable } = this.props;
    this.cropper = new Cropper(this.imgRef.current, {
      autoCropArea: 1,
      aspectRatio,
      cropBoxResizable: resizable,
    });
  };

  getCanvasData = () => {
    return this.cropper.getCanvasData();
  };

  getCroppedCanvas = (options) => {
    return this.cropper.getCroppedCanvas(options);
  };

  setDragMode = (dragMode) => () => {
    this.setState(
      {
        dragMode,
      },
      () => this.cropper.setDragMode(dragMode)
    );
  };
  addZoom = () => {
    this.cropper.zoom(0.1);
  };
  removeZoom = () => {
    this.cropper.zoom(-0.1);
  };
  rotate = (degree) => () => {
    this.cropper.rotate(degree);
  };
  move = (direction) => () => {
    if (direction === "left") {
      this.cropper.move(-10, 0);
    } else if (direction === "right") {
      this.cropper.move(10, 0);
    } else if (direction === "up") {
      this.cropper.move(0, -10);
    } else if (direction === "down") {
      this.cropper.move(0, 10);
    }
  };

  renderButtons = () => {
    const { dragMode } = this.state;

    return (
      <div style={{ width: "100%", display: "block" }}>
        <CustomButton
          outline={dragMode !== "move"}
          icon="arrows-alt"
          onClick={this.setDragMode("move")}
        />
        <CustomButton
          outline={dragMode !== "crop"}
          icon="crop-alt"
          onClick={this.setDragMode("crop")}
          style={{ marginRight: 16 }}
        />
        <CustomButton icon="arrow-left" onClick={this.move("left")} />
        <CustomButton icon="arrow-right" onClick={this.move("right")} />
        <CustomButton icon="arrow-up" onClick={this.move("up")} />
        <CustomButton
          icon="arrow-down"
          onClick={this.move("down")}
          style={{ marginRight: 16 }}
        />
        <CustomButton icon="search-minus" onClick={this.addZoom} />
        <CustomButton
          icon="search-plus"
          onClick={this.removeZoom}
          style={{ marginRight: 16 }}
        />
        <CustomButton icon="undo-alt" onClick={this.rotate(-90)} />
        <CustomButton
          icon="redo-alt"
          onClick={this.rotate(90)}
          style={{ marginRight: 16 }}
        />
      </div>
    );
  };

  render() {
    const { classes, width, height, src, alt } = this.props;
    return (
      <div style={{ maxWidth: width, height }}>
        {this.renderButtons()}
        <img ref={this.imgRef} src={src} alt={alt} className={classes.img} />
      </div>
    );
  }
}

ImageCropper.defaultProps = {
  width: "100%",
  height: 500,
  resizable: false,
};

export default withStyles(styles)(ImageCropper);
