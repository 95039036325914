// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import ResourcePageContainer from "../components/Resources/ResourcePageContainer";

@inject("informativeClocksStore")
@observer
class InformativeSetClockPage extends Component {
  render() {
    return (
      <Fragment>
        <PageTitle id="Menu@clock">Relógio</PageTitle>
        <ResourcePageContainer
          appMainStore={this.props.informativeClocksStore}
          resourceType="clock"
        />
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: InformativeSetClockPage,
  pageAllowedRule: "informative",
});
