// @ts-nocheck

import { inject, observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import AdvertisingMessageHelpText from "../Advertising/AdvertisingMessageHelpText";

// Translate
import Translate from "../I18n/Translate";

// Styles
const styles = {
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.2em",
  },
  messageTextArea: {
    width: "100%",
    resize: "none",
    borderRadius: 6,
    padding: 6,
    borderColor: "#D2D1D2",
  },
  messageHelpText: {
    fontSize: "0.9em",
  },
};

const AdvertisingMessageTextarea = inject("advertisingStore")(
  observer(({ classes, advertisingStore }) => {
    const { message, onChangeMessage } = advertisingStore;
    return (
      <>
        <p className={classes.sessionTitle}>
          <Translate id="Advertising@messageQuestion">
            Qual é a sua mensagem?
          </Translate>
        </p>
        <textarea
          className={classes.messageTextArea}
          name="message"
          value={message}
          maxLength={120}
          autoFocus={true}
          rows={6}
          onChange={onChangeMessage}
        />
        <span className={classes.messageHelpText}>
          <i className="fas fa-info-circle" />{" "}
          <AdvertisingMessageHelpText messageLength={message.length} />
        </span>
      </>
    );
  })
);

export default withStyles(styles)(AdvertisingMessageTextarea);
