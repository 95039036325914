export const ProductCategory = Object.freeze({
  COMBO: "combo",
  OTHER: "other",
  SMS_CREDIT: "smsCredit",
  STORAGE: "storage",
  WIFI_AUTH: "wifiAuthentication",
  CAMERA_FACES: "cameraFaces",
});

class ProductModel {
  token;
  name;
  category;
  description;
  price;
  offerPrice;
  quantity;
  afterPurchase;
  daysToExpire = 0;

  constructor(product) {
    this.token = product.token;
    this.name = product.name;
    this.category = product.category;
    this.description = product.description;
    this.price = product.price;
    this.offerPrice = product.offerPrice;
    this.quantity = product.quantity;
    this.afterPurchase = product.afterPurchase;
    this.daysToExpire = product.daysToExpire || 0;
  }
}

export default ProductModel;
