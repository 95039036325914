// @ts-nocheck


import { withLocalized } from "../HOC";
import { ButtonGroup } from "../Form";

const MacaddressRuleFilter = ({
  localizedString,
  macaddressRule,
  onChange,
}) => (
  <ButtonGroup
    small
    name="macaddressRule"
    value={macaddressRule === null ? 0 : macaddressRule}
    color="primary"
    onChange={(_: string, selectedValue: number) => onChange(selectedValue)}
    options={[
      {
        value: "only",
        label: localizedString({
          id: "Metrics@macaddressRuleOnlyOption",
          defaultString: "Somente dispositivos registrados",
        }),
      },
      {
        value: "except",
        label: localizedString({
          id: "Metrics@macaddressRuleExceptOption",
          defaultString: "Ignorar dispositivos registrados",
        }),
      },
      {
        value: "all",
        label: localizedString({
          id: "Metrics@macaddressRuleAllOption",
          defaultString: "Todos",
        }),
      },
    ]}
  />
);

export default withLocalized(MacaddressRuleFilter);
