//@ts-nocheck
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import ChartModel, { EChartType } from "../../Model/ChartModel";

import { Card, CardBody, CardHeader, CardIcon } from "../Card";
import { GridContainer, GridItem } from "../Grid";

import { List, ListItem } from "../List";
import TopItemsList from "../List/TopItemsList";

import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";

import { isObject } from "../../utils/Utils";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";
// @ts-ignore
const useStyles = makeStyles(chartsStyle);

interface ChartContainerProps {
  chartsList: ChartModel[];
  didSelect?: (action: string) => void;
}

const ChartContainer: FC<ChartContainerProps> = ({ chartsList, didSelect }) => {
  const classes = useStyles();

  const renderUsageLimitChart = (data: any) => {
    return (
      <div className={classes.usageLimitContainer}>
        <div className={classes.usageLimitInnerContainer}>
          <span className={classes.usage}>{data.usage}</span> /{" "}
          <span className={classes.limit}>{data.limit}</span>
        </div>
      </div>
    );
  };
  const renderBarChart = (data: any) => {
    const isDataObject = isObject(data);
    const hasAction = isDataObject
      ? false
      : data.some(({ action }) => action !== null && action !== undefined);
    return (
      <BarChart
        stacked={isDataObject && data?.stacked}
        showDataLabels
        height={265}
        xAxisCategories={
          isDataObject ? data.categories : data.map(({ label }) => label)
        }
        data={
          isDataObject
            ? data.data_list
            : [
                {
                  name: "",
                  data: data.map(({ value }) => value),
                },
              ]
        }
        customChartOptions={{
          events: {
            dataPointSelection: (_, __, config: any) => {
              if (hasAction) {
                const index = config.dataPointIndex;
                const selected = data[index];
                if (selected) {
                  didSelect(selected?.action);
                }
              }
            },
          },
        }}
      />
    );
  };

  const renderPieChart = (data: any) => (
    <PieChart
      //   height={400}
      legend={{
        position: "bottom",
        horizontalAlign: "center",
        fontSize: 16,
      }}
      data={data.map(({ label, value }) => ({
        name: label,
        y: value,
      }))}
    />
  );

  const renderLineChart = ({ x_label, y_label, data }: any) => {
    return (
      // @ts-ignore
      <LineChart
        xAxisTitle={x_label}
        xAxisCategories={data.map(([key]) => `${key}`)}
        yAxisTitle={y_label}
        data={[
          {
            name: "#",
            data: data.map(([_, value]) => value),
          },
        ]}
        height={400}
      />
    );
  };

  const renderListChart = (data: any) => {
    return (
      // @ts-ignore
      <List>
        {data.list.map((item: any, index: number) => {
          const indexToPrint = index + 1;
          return (
            // @ts-ignore
            <ListItem
              key={index}
              divider={indexToPrint < data.list.length}
              dense
              title={item}
            />
          );
        })}
      </List>
    );
  };

  const renderNumericListChart = (data: any) => {
    return (
      <TopItemsList
        itemsList={data}
        renderTitle={({ value }) => value}
        renderDescription={({ label }) => label}
      />
    );
  };

  const renderMessageChart = (data: any) => {
    return <p>{data}</p>;
  };

  const renderChart = (type: EChartType, data: any) => {
    if (type === EChartType.UsageLimit) {
      return renderUsageLimitChart(data);
    } else if (type === EChartType.Bar) {
      return renderBarChart(data);
    } else if (type === EChartType.Pie) {
      return renderPieChart(data);
    } else if (type === EChartType.Line) {
      return renderLineChart(data);
    } else if (type === EChartType.List) {
      return renderListChart(data);
    } else if (type === EChartType.NumericList) {
      return renderNumericListChart(data);
    } else if (type === EChartType.Message) {
      return renderMessageChart(data);
    }
    return null;
  };

  const renderChartItem = (
    { type, layout, title, subtitle, data }: ChartModel,
    index: number
  ) => {
    if (!type || !data) {
      return null;
    }
    try {
      return (
        <GridItem
          key={index}
          className={classes.chartGrid}
          xs={layout?.xs || 12}
          sm={layout?.sm || 12}
          md={layout?.md || 12}
          lg={layout?.lg || 12}
        >
          <Card className={classes.chartCard}>
            <CardHeader color="info" icon>
              {layout?.icon && (
                <CardIcon color="info">
                  <i className={`fas fa-${layout.icon}`} />
                </CardIcon>
              )}
              <h4 className={classes.cardIconTitle}>{title}</h4>
              {subtitle && (
                <p className={classes.cardIconSubtitle}>{subtitle}</p>
              )}
            </CardHeader>
            <CardBody>{renderChart(type, data)}</CardBody>
          </Card>
        </GridItem>
      );
    } catch (error) {
      window.debugError("error renderingChart", title, error);
      return null;
    }
  };

  return <GridContainer>{chartsList.map(renderChartItem)}</GridContainer>;
};

export default ChartContainer;
