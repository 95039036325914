// @ts-nocheck


import { withLocalized } from "../HOC";
import { ButtonGroup, DatePicker } from "../Form";
import { GridContainer, GridItem } from "../Grid";
import DateRange, { labelForDataRange } from "./DateRange";

const DateRangeFilter = ({
  localizedString,
  viewId,
  dateRange,
  fromDate,
  toDate,
  onChangeDateRange,
  onChangeFromDate,
  onChangeToDate,
}) => (
  <GridContainer>
    <GridItem xs={12}>
      <ButtonGroup
        small
        name={`${viewId}_dateRange`}
        value={dateRange}
        color="primary"
        onChange={onChangeDateRange}
        options={[
          {
            value: DateRange.currentWeek,
            label: labelForDataRange(DateRange.currentWeek, localizedString),
          },
          {
            value: DateRange.lastWeek,
            label: labelForDataRange(DateRange.lastWeek, localizedString),
          },
          {
            value: DateRange.currentMonth,
            label: labelForDataRange(DateRange.currentMonth, localizedString),
          },
          {
            value: DateRange.lastMonth,
            label: labelForDataRange(DateRange.lastMonth, localizedString),
          },
          {
            value: DateRange.lastThirtyDays,
            label: labelForDataRange(DateRange.lastThirtyDays, localizedString),
          },
          {
            value: DateRange.lastSixtyDays,
            label: labelForDataRange(DateRange.lastSixtyDays, localizedString),
          },
          {
            value: DateRange.lastNinetyDays,
            label: labelForDataRange(DateRange.lastNinetyDays, localizedString),
          },
          {
            value: DateRange.custom,
            label: labelForDataRange(DateRange.custom, localizedString),
          },
        ]}
      />
    </GridItem>
    {dateRange === DateRange.custom && (
      <>
        <GridItem xs={12} sm={6} style={{ marginTop: 10 }}>
          <DatePicker
            label={localizedString({
              id: "Metrics@fromDateFilter",
              defaultString: "Data início",
            })}
            name="fromDate"
            value={fromDate}
            onChange={onChangeFromDate}
            fullWidth
            timeFormat
          />
        </GridItem>
        <GridItem xs={12} sm={6} style={{ marginTop: 10 }}>
          <DatePicker
            label={localizedString({
              id: "Metrics@toDateFilter",
              defaultString: "Data fim",
            })}
            name="toDate"
            value={toDate}
            onChange={onChangeToDate}
            fullWidth
            timeFormat
          />
        </GridItem>
      </>
    )}
  </GridContainer>
);

DateRangeFilter.defaultProps = {
  viewId: "",
};

export default withLocalized(DateRangeFilter);
