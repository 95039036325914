import { observable, computed, action } from "mobx";

// Util
import { didSearchList } from "../../../utils/Utils";

import QueueManagementControlModel from "../../../Model/QueueManagementControlModel";
import ModboxAPIService from "../../../services/ModboxAPIService";
import InternalEventsStore, {
  EInternalEvents,
} from "../../../store/InternalEventsStore/InternalEventsStore";
import NotificationsStore from "../../../store/NotificationsStore/NotificationsStore";
import TerminalsStore from "../../../store/TerminalsStore/TerminalsStore";
import I18nStore from "../../../store/I18nStore/I18nStore";

import { ERequestTerminalMetadata } from "../../../types/Terminal";

enum ControlModalType {
  none,
  options,
  form,
  openToConnect,
}

class InformativeQueueManagementStore {
  internalEventsStore: InternalEventsStore;
  terminalsStore: TerminalsStore;
  notificationsStore: NotificationsStore;
  i18nStore: I18nStore;
  modboxAPIService: ModboxAPIService;

  @observable controlsList: QueueManagementControlModel[] = [];
  @observable isFetching = false;
  @observable isFetchingDevice = false;
  @observable modalType = ControlModalType.none;
  @observable selectedControlToken: string | null = null;
  @observable searchString = "";

  constructor(
    internalEventsStore: InternalEventsStore,
    terminalsStore: TerminalsStore,
    notificationsStore: NotificationsStore,
    i18nStore: I18nStore,
    modboxAPIService: ModboxAPIService
  ) {
    this.terminalsStore = terminalsStore;
    this.notificationsStore = notificationsStore;
    this.i18nStore = i18nStore;
    this.modboxAPIService = modboxAPIService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.isFetchingDevice = false;
    this.controlsList = [];
    this.searchString = "";
  };

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setIsFetchingDevice = (newValue: boolean) => {
    this.isFetchingDevice = newValue;
  };

  @action
  setControlsList = (newList: QueueManagementControlModel[]) => {
    this.controlsList = newList;
  };

  @action
  setSearchString = (searchString: string) => {
    this.searchString = searchString;
  };

  @action
  didSelectControl = (token: string) => {
    this.selectedControlToken = token;
    this.modalType = ControlModalType.options;
  };
  @action
  didWantToEditControl = () => {
    this.modalType = ControlModalType.form;
  };
  @action
  didWantToAddControl = () => {
    this.modalType = ControlModalType.form;
  };

  @action
  didWantToOpenRemoteConnection = async () => {
    this.modalType = ControlModalType.openToConnect;

    if (this.selectedControl.isOpenToConnect) {
      return;
    }

    try {
      const response: any = await this.modboxAPIService.setQueueManagementControlOpenToConnect(
        this.selectedControlToken
      );
      if (response.status === 200 && response.data) {
        const { code, expires_date } = response.data;
        this.selectedControl.setConnectionCode(code);
        this.selectedControl.setConnectionExpiresDate(expires_date);
      }
    } catch (error) {
      window.debugError("error in didWantToOpenRemoteConnection", error);
    }
  };

  @action
  didCloseModal = () => {
    this.modalType = ControlModalType.none;
    setTimeout(
      action(() => {
        this.selectedControlToken = null;
      }),
      500
    );
  };

  @computed
  get isFormOpen(): boolean {
    return this.modalType === ControlModalType.form;
  }
  @computed
  get isOptionsModal(): boolean {
    return this.modalType === ControlModalType.options;
  }
  @computed
  get isOpenToConnectModal(): boolean {
    return this.modalType === ControlModalType.openToConnect;
  }

  @computed
  get isEditingControl(): boolean {
    return this.isFormOpen && this.selectedControlToken !== null;
  }

  @computed
  get selectedControl(): QueueManagementControlModel {
    if (!this.selectedControlToken) return null;

    return this.controlsList.find(
      ({ token }) => token === this.selectedControlToken
    );
  }

  @computed
  get isOpenModal(): boolean {
    return this.modalType !== ControlModalType.none;
  }

  @computed
  get filteredControlsList(): QueueManagementControlModel[] {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.controlsList,
        (
          control: QueueManagementControlModel,
          textIncludesSearchString: (key: string) => boolean
        ) => {
          const matchName = textIncludesSearchString(control.name);
          const matchValue = textIncludesSearchString(control.value);

          return matchName || matchValue;
        }
      );
    }

    return this.controlsList;
  }

  @action
  getControlsList = async () => {
    this.setIsFetching(true);
    let newList = [];

    const response: any = await this.modboxAPIService.requestQueueManagementControls();
    try {
      if (response.status === 200) {
        response.data.forEach((data: any) => {
          const item = new QueueManagementControlModel(data);
          newList.push(item);
        });

        this.setControlsList(newList);
      }
    } catch (error:any) {
      window.debugError("error in getControlsList", error);
      if (error && error.statusCode === 429) {
        setTimeout(this.getControlsList, 2000);
      }
    } finally {
      this.setIsFetching(false);
    }
  };

  submit = async (control: QueueManagementControlModel) => {
    try {
      const response: any = await this.modboxAPIService.submitQueueManagementControl(
        control
      );
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.didCloseModal();
      this.getControlsList();
    } catch (error) {
      window.debugError("error in submitQueueManagementControl", error);
    }
  };

  update = async (control: QueueManagementControlModel) => {
    try {
      const response: any = await this.modboxAPIService.updateQueueManagementControl(
        control
      );
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.didCloseModal();
      this.getControlsList();
    } catch (error) {
      window.debugError("error in updateQueueManagementControl", error);
    }
  };

  delete = async () => {
    try {
      const response: any = await this.modboxAPIService.deleteQueueManagementControl(
        this.selectedControlToken
      );
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.didCloseModal();
      this.getControlsList();
    } catch (error) {
      window.debugError("error in deleteQueueManagementControl", error);
    }
  };

  getWithToken = (token: string) => {
    if (this.controlsList.length > 0) {
      return this.controlsList.find((control) => control.token === token);
    }
    return null;
  };

  getSelectedControlDevice = async () => {
    if (this.selectedControl?.terminalToken) {
      return await this.terminalsStore.requestTerminal(
        this.selectedControl.terminalToken,
        ERequestTerminalMetadata.network
      );
    }
    return null;
  };

  didWantToUseControlOnLocalhost = async () => {
    this.setIsFetchingDevice(true);
    const device = await this.getSelectedControlDevice();
    if (
      this.selectedControl &&
      device &&
      device?.network?.local_address?.length > 0
    ) {
      const { token, type } = this.selectedControl;
      const url = `http://${device.network.local_address}/local/remote-control/${token}&type=${type}`;
      window.open(url);
    } else {
      this.notificationsStore.addSnackbarNotification({
        message: this.i18nStore.localizedString({
          id: "Informative@cannotConnectToDeviceErrorMessage",
          defaultString:
            "Erro ao conectar ao dispositivo. Verifique se ele está ligado e conectado na rede.",
        }),
        color: "warning",
      });
    }

    this.setIsFetchingDevice(false);
  };
}

export default InformativeQueueManagementStore;
