// @ts-nocheck

import Nouislider from "react-nouislider";

const Slider = ({
  values,
  min,
  max,
  step,
  connect,
  onSlide,
  onChange,
  renderTooltip,
}) => (
  <Nouislider
    start={values}
    connect={connect}
    tooltips={values.map((_) => ({
      to: renderTooltip,
    }))}
    step={step}
    range={{ min, max }}
    onSlide={onSlide}
    onChange={onChange}
  />
);

Slider.defaultProps = {
  connect: [true, false],
  renderTooltip: ($0) => $0.toFixed(0),
};

export default Slider;
