import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/styles";

import TextToHTML from "../../TextToHTML/TextToHTML";

const useStyles = makeStyles({
  link: {
    // color: "#cacaca",
    "&:hover,&:focus": {
      color: "#4b83c0",
    },
  },
});

interface MessageTextProps {
  message: string;
}

const MessageText: FunctionComponent<MessageTextProps> = ({ message }) => {
  const classes = useStyles();
  if (message.length < 1) return null;
  return <TextToHTML classes={{ link: classes.link }}>{message}</TextToHTML>;
};

export default MessageText;
