// @ts-nocheck
import { Fragment } from "react";
import classnames from "classnames";
import { observer } from "mobx-react";

import PieChart from "../../components/Charts/PieChart";
import GenderAndAgeGroupChart from "../../components/Charts/GenderAndAgeGroupChart";
import { GridContainer, GridItem } from "../../components/Grid";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";
import BigCounter from "../../components/BigCounter/BigCounter";

// Translate
import Translate from "../../components/I18n/Translate";

// Style
import { warningColor } from "../../assets/jss/app-js-styles";
const styles = {
  container: {
    position: "relative",
  },
  disabledMessageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 10,
  },
  message: {
    background: warningColor,
    padding: 16,
    borderRadius: 8,
    fontSize: "1.6em",
    color: "#fff",
  },
  disabledContainer: {
    filter: "blur(4px) grayscale(1) opacity(0.4)",
    pointerEvents: "none",
    zIndex: 0,
  },
  chartTitle: {
    fontSize: "1.2em",
    textAlign: "center",
  },
};

const VisitorsChartWidget = observer(
  ({ classes, localizedString, chartData, isDisabled }) => {
    if (!chartData) return null;

    const paramExists = (param) => param !== null && param !== undefined;

    const bigCounterStyle = {
      fontSize: "4em",
      marginTop: 20,
    };

    return (
      <div className={classes.container}>
        {isDisabled && (
          <div className={classes.disabledMessageContainer}>
            <p className={classes.message}>
              <Translate id="Metrics@insufficientData">
                Dados insuficientes para exibir estas informações no momento
              </Translate>
            </p>
          </div>
        )}

        <div
          className={classnames({
            [classes.disabledContainer]: isDisabled,
          })}
        >
          {chartData.peopleByGroup && (
            <GenderAndAgeGroupChart
              chartData={Object.values(chartData.peopleByGroup)[0]}
            />
          )}
          {chartData.peopleStatistics && (
            <GridContainer
              alignItems="center"
              style={{ marginTop: chartData.peopleByGroup ? 30 : 0 }}
            >
              {paramExists(chartData.peopleStatistics.new) &&
                paramExists(chartData.peopleStatistics.recurrent) && (
                  <GridItem xs={12} md={6}>
                    <PieChart
                      legend={{
                        position: "bottom",
                        horizontalAlign: "center",
                        fontSize: 16,
                      }}
                      height={260}
                      data={[
                        {
                          name: localizedString({
                            id: "Connectivity@peopleStatisticsVisitorsNew",
                            defaultString: "Novos",
                          }),
                          y: chartData.peopleStatistics.new,
                        },
                        {
                          name: localizedString({
                            id:
                              "Connectivity@peopleStatisticsVisitorsRecurrent",
                            defaultString: "Recorrentes",
                          }),
                          y: chartData.peopleStatistics.recurrent,
                        },
                      ]}
                    />
                  </GridItem>
                )}
              <GridItem xs={12} md={6}>
                {chartData.peopleStatistics.nearbyTimeAverage && (
                  <Fragment>
                    <h2 className={classes.chartTitle}>
                      <Translate id="Connectivity@nearbyTimeAverageTitle">
                        Tempo médio de permanência dos visitantes no local
                      </Translate>
                    </h2>
                    <BigCounter
                      style={bigCounterStyle}
                      value={
                        chartData.peopleStatistics.nearbyTimeAverage > 0
                          ? parseInt(
                              chartData.peopleStatistics.nearbyTimeAverage / 60,
                              10
                            )
                          : 0
                      }
                      label={
                        <Translate id="Common@minutesString">minutos</Translate>
                      }
                    />
                  </Fragment>
                )}
                {chartData.peopleStatistics.authenticatedTimeAverage && (
                  <Fragment>
                    <h2 className={classes.chartTitle}>
                      <Translate id="Connectivity@authenticatedTimeAverageTitle">
                        Tempo de uso da Internet
                      </Translate>
                    </h2>
                    <BigCounter
                      style={bigCounterStyle}
                      value={
                        chartData.peopleStatistics.authenticatedTimeAverage > 0
                          ? parseInt(
                              chartData.peopleStatistics
                                .authenticatedTimeAverage / 60,
                              10
                            )
                          : 0
                      }
                      label={
                        <Translate id="Common@minutesString">minutos</Translate>
                      }
                    />
                  </Fragment>
                )}
              </GridItem>
            </GridContainer>
          )}
        </div>
      </div>
    );
  }
);

export default withLocalized(withStyles(styles)(VisitorsChartWidget));
