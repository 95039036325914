// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Divider,
} from "@material-ui/core";

// Components
import { InputText } from "../Form";

import { GridContainer, GridItem } from "../Grid";
import { withLocalized } from "../HOC";
import { Button } from "../Button";
import InfoLabel from "../Label/InfoLabel";
import { SimpleCard } from "../Card";

import Validate, { ValidationType } from "../Form/Validate";

import ConfirmModal from "../Modal/ConfirmModal";

import InterfaceNetworkForm from "./modWifiTerminalConfigureNetworkForm/InterfaceNetworkForm";

// Translate
import { FormErrorString, SetupString } from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Utils
import Network from "../../utils/NetworkUtils";

// Icons
import { SaveIcon } from "../Icon";

// Styles
import {
  primaryColor,
  dangerColor,
  grayColor,
} from "../../assets/jss/app-js-styles";
const styles = {
  expansionPanelSummary: {
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const validateIpAddress = (value) => Validate(value, ValidationType.IP_ADDRESS);

const DEFAULT_WAN_INET = {
  dhcp: "yes",
  addr: "",
  netmask: "",
  gateway: "",
  nameservers: [],
};

const DEFAULT_LAN_INET = {
  dhcp: "no",
  addr: "172.31.254.1",
  netmask: "255.255.255.0",
  dhcp_server: "yes",
  dhcp_beginning: "172.31.254.5",
  dhcp_ending: "172.31.254.254",
  dhcp_lease: "1h",
  static_addresses: [],
};

@withLocalized
@inject("notificationsStore", "terminalsStore")
class ModWifiTerminalConfigureNetworkForm extends Component {
  constructor(props) {
    super(props);
    const { preferences } = props.terminal.network;
    const interfaces = preferences.interfaces || [];

    this.state = {
      interfaces: interfaces.map(($0) => {
        const type = $0.type || "lan";
        const networks =
          type === "lan"
            ? $0.networks
                .filter((network) => network.inet.dhcp === "no")
                .map((network, networkIndex) => ({
                  ...network,
                  label:
                    network.label || this.getNetworkLabel(null, networkIndex),
                  inet: {
                    dhcp: "no",
                    addr: network.inet.addr || "",
                    netmask: network.inet.netmask || "",
                    dhcp_server: network.inet.dhcp_server || "no",
                    dhcp_beginning: network.inet.dhcp_beginning || "",
                    dhcp_ending: network.inet.dhcp_ending || "",
                    dhcp_lease: network.inet.dhcp_lease || "1h",
                    static_addresses: network.inet.static_addresses || [],
                  },
                }))
            : $0.networks.map((network, networkIndex) => ({
                ...network,
                label:
                  network.label || this.getNetworkLabel(null, networkIndex),
                inet: {
                  dhcp: network.inet.dhcp || "yes",
                  addr: network.inet.addr || "",
                  netmask: network.inet.netmask || "",
                  gateway: network.inet.gateway || "",
                  nameservers: network.inet.nameservers || [],
                },
              }));
        return { ...$0, type, networks };
      }),
      selectedInterfaceIndex: null,
      selectedNetworkIndex: null,
      formWasSubmit: false,
      isWaitingForm: false,
      isWaitingConfirmation: false,
      confirmationObjective: null,
    };
  }

  onChangeNetwork = (interfaceIndex, networkIndex, network) => {
    this.setState((prevState) => {
      const { interfaces } = prevState;

      const selectedInterface = interfaces[interfaceIndex];

      return {
        interfaces: interfaces.map(($0, i0) =>
          i0 === interfaceIndex
            ? {
                ...selectedInterface,
                networks: selectedInterface.networks.map(($1, i1) =>
                  i1 === networkIndex ? network : $1
                ),
              }
            : $0
        ),
      };
    });
  };

  canSubmitForm = () => {
    let result = true;
    const { interfaces } = this.state;

    interfaces.forEach(({ networks, type }) => {
      const isWan = type === "wan";
      networks.forEach(({ inet }, networkIndex) => {
        const {
          dhcp,
          addr,
          gateway,
          netmask,
          nameservers,
          dhcp_server,
          dhcp_beginning,
          dhcp_ending,
        } = inet;
        let matchAddr = true;
        let matchGateway = true;
        let matchNetmask = true;
        let matchNameservers = true;
        let matchDhcpServer = true;
        let matchDhcpBeginning = true;
        let matchDhcpEnding = true;

        if (nameservers) {
          nameservers.forEach((dnsValue) => {
            matchNameservers =
              dnsValue.length < 1 || validateIpAddress(dnsValue);
          });
        }

        if (dhcp === "no") {
          matchAddr = addr && validateIpAddress(addr);
          matchGateway = isWan ? gateway && validateIpAddress(gateway) : true;
          matchNetmask = netmask && validateIpAddress(netmask);
        }

        if (dhcp_server === "yes" && matchAddr && matchNetmask) {
          const network = new Network(addr, netmask);

          matchDhcpBeginning = isWan
            ? true
            : dhcp_beginning && network.isPartOf(dhcp_beginning);
          matchDhcpEnding = isWan
            ? true
            : dhcp_ending && network.isPartOf(dhcp_ending);

          if (!matchDhcpBeginning || !matchDhcpEnding) {
            this.props.notificationsStore.addSnackbarNotification({
              message: this.props.localizedString(
                {
                  id: "Device@modWifiDHCPSizeErrorMessage",
                  defaultString:
                    "Faixa DHCP precisa estar entre $first e $last",
                },
                {
                  first: network.first,
                  last: network.last,
                }
              ),
              color: "warning",
            });
          }

          const sumIp = (ipValue) => parseInt(ipValue.replace(/\./g, ""), 10);
          if (sumIp(dhcp_beginning) > sumIp(dhcp_ending)) {
            matchDhcpServer = false;
            this.props.notificationsStore.addSnackbarNotification({
              message: this.props.localizedString({
                id: "Device@modWifiDHCPErrorMessage",
                defaultString: "DHCP INÍCIO precisa ser menor do que o FIM",
              }),
              color: "warning",
            });
          }
        }

        result =
          matchAddr &&
          matchGateway &&
          matchNetmask &&
          matchNameservers &&
          matchDhcpServer &&
          matchDhcpBeginning &&
          matchDhcpEnding;
      });
    });
    return result;
  };

  submitForm = () => {
    const { interfaces } = this.state;
    const data = {
      ...this.props.terminal,
      networkPreferences: {
        interfaces: interfaces.map(($0) => {
          const isWan = $0.type === "wan";
          return {
            ...$0,
            networks: $0.networks.map((network) => {
              const {
                dhcp,
                addr,
                netmask,
                gateway,
                nameservers,
                dhcp_server,
                dhcp_beginning,
                dhcp_ending,
                dhcp_lease,
                static_addresses,
              } = network.inet;
              const isManualDHCP = dhcp === "no";
              const isDHCPServer = !isWan && dhcp_server === "yes";

              const isLanAndDHCPServer = (value) =>
                !isWan ? (isDHCPServer ? value : "") : undefined;

              return {
                ...network,
                inet: {
                  addr: isManualDHCP ? addr : "",
                  dhcp,
                  netmask: isManualDHCP ? netmask : "",
                  gateway: isWan ? (isManualDHCP ? gateway : "") : undefined,
                  nameservers: isWan
                    ? nameservers.filter((dnsValue) => dnsValue.length > 0)
                    : undefined,
                  dhcp_server: !isWan ? dhcp_server : undefined,
                  dhcp_beginning: isLanAndDHCPServer(dhcp_beginning),
                  dhcp_ending: isLanAndDHCPServer(dhcp_ending),
                  dhcp_lease: isLanAndDHCPServer(dhcp_lease),
                  static_addresses: !isWan
                    ? isDHCPServer
                      ? static_addresses
                      : []
                    : undefined,
                },
              };
            }),
          };
        }),
      },
    };

    this.props.terminalsStore.update(data).then(() => this.props.didSave());
  };

  onSubmit = (event) => {
    if (event) event.preventDefault();

    if (this.canSubmitForm()) {
      this.setState({
        isWaitingConfirmation: true,
        confirmationObjective: "submit",
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: <FormErrorString />,
            color: "danger",
          });
        }
      );
    }
  };

  getConfirmationMessage = () => {
    if (this.state.confirmationObjective === "submit") {
      return (
        <Translate id="Device@modWifiSubmitConfirmationMessage">
          O sistema tentará atribuir as novas configurações de rede. Caso ocorra
          algum problema de conectividade as configurações anteriores serão
          restauradas.
        </Translate>
      );
    }
    return (
      <Translate id="Device@modWifiChangeWanConfirmationMessage">
        Atenção! Você perderá todas as configurações da interface WAN atual.
        Esta operação não poderá ser desfeita!
      </Translate>
    );
  };

  didCancelConfirmation = () => {
    this.setState(
      {
        isWaitingConfirmation: false,
      },
      () => {
        setTimeout(() => this.setState({ confirmationObjective: null }), 300);
      }
    );
  };

  didConfirmSubmit = () => {
    this.setState(
      {
        isWaitingConfirmation: false,
        confirmationObjective: null,
        isWaitingForm: true,
        formWasSubmit: true,
      },
      this.submitForm
    );
  };

  addNetwork = (interfaceIndex) => () => {
    this.setState((prevState) => {
      const selectedInterface = prevState.interfaces[interfaceIndex];
      return {
        interfaces: prevState.interfaces.map(($0, i) =>
          interfaceIndex === i
            ? {
                ...selectedInterface,
                networks: [
                  ...selectedInterface.networks,
                  {
                    label: this.getNetworkLabel(
                      null,
                      selectedInterface.networks.length
                    ),
                    inet: DEFAULT_LAN_INET,
                  },
                ],
              }
            : $0
        ),
      };
    });
  };

  removeNetwork = (interfaceIndex, indexToRemove) => () => {
    this.setState((prevState) => ({
      selectedInterfaceIndex: null,
      selectedNetworkIndex: null,
      interfaces: prevState.interfaces.map(($0, i) =>
        interfaceIndex === i
          ? {
              ...prevState.interfaces[interfaceIndex],
              networks: prevState.interfaces[interfaceIndex].networks.filter(
                (_, i) => i !== indexToRemove
              ),
            }
          : $0
      ),
    }));
  };

  didWantToEditNetwork = (
    selectedInterfaceIndex,
    selectedNetworkIndex
  ) => () => {
    this.setState({ selectedInterfaceIndex, selectedNetworkIndex });
  };

  didWantToEditInterface = (selectedInterfaceIndex) => () => {
    this.setState({ selectedInterfaceIndex, selectedNetworkIndex: null });
  };

  getInterfaceLabel = (label, index) => label || `Interface ${index + 1}`;
  getNetworkLabel = (label, index) => label || `LAN ${index + 1}`;

  setInterfaceLabel = ({ target }) => {
    this.setState((prevState) => ({
      interfaces: prevState.interfaces.map(($0, i) =>
        prevState.selectedInterfaceIndex === i
          ? {
              ...prevState.interfaces[prevState.selectedInterfaceIndex],
              label: target.value,
            }
          : $0
      ),
    }));
  };

  didWantToTurnIntoWan = () => {
    this.setState({
      isWaitingConfirmation: true,
      confirmationObjective: "turnIntoWan",
    });
  };

  didTurnIntoWan = () => {
    this.setState((prevState) => ({
      isWaitingConfirmation: false,
      confirmationObjective: null,
      selectedInterfaceIndex: null,
      interfaces: prevState.interfaces.map(($0, i) =>
        prevState.selectedInterfaceIndex === i
          ? {
              ...prevState.interfaces[prevState.selectedInterfaceIndex],
              type: "wan",
              networks: [
                {
                  label: "WAN",
                  inet: DEFAULT_WAN_INET,
                },
              ],
            }
          : $0.type === "wan"
          ? {
              ...$0,
              type: "lan",
              networks: [
                {
                  label: "LAN 1",
                  inet: DEFAULT_LAN_INET,
                },
              ],
            }
          : $0
      ),
    }));
  };

  renderSelectedNetwork = () => {
    const {
      selectedInterfaceIndex,
      selectedNetworkIndex,
      interfaces,
    } = this.state;

    if (selectedInterfaceIndex === null) {
      return null;
    }
    const { label, networks, type } = interfaces[selectedInterfaceIndex];
    if (selectedNetworkIndex !== null) {
      const network = networks[selectedNetworkIndex];
      return (
        <GridItem xs={12} md={8}>
          <SimpleCard>
            <InterfaceNetworkForm
              network={network}
              isWan={type === "wan"}
              interfaceIndex={selectedInterfaceIndex}
              networkIndex={selectedNetworkIndex}
              formWasSubmit={this.state.formWasSubmit}
              onChange={this.onChangeNetwork}
            ></InterfaceNetworkForm>
          </SimpleCard>
        </GridItem>
      );
    }
    return (
      <GridItem xs={12} md={8}>
        <SimpleCard>
          <InputText
            label={
              <Translate id="Device@modWifiInterfaceLabel">
                Nome da interface
              </Translate>
            }
            name="label"
            value={label || ""}
            onChange={this.setInterfaceLabel}
          />
          {type === "lan" && (
            <Fragment>
              <Button
                color="primary"
                block
                small
                onClick={this.didWantToTurnIntoWan}
              >
                <Translate id="Device@modWifiTurnIntoWanButtonLabel">
                  Ativar esta interface como WAN
                </Translate>{" "}
                <i className="fas fa-retweet" />
              </Button>
            </Fragment>
          )}
        </SimpleCard>
      </GridItem>
    );
  };

  render() {
    if (this.state.interfaces.length < 1) {
      return (
        <InfoLabel style={{ margin: "40px 0", textAlign: "center" }}>
          <Translate id="Device@modWifiNoInterfaceFoundMessage">
            Nenhuma interface de rede encontrada.
          </Translate>
        </InfoLabel>
      );
    }

    const {
      interfaces,
      isWaitingForm,
      selectedInterfaceIndex,
      selectedNetworkIndex,
    } = this.state;

    return this.props.children({
      body: (
        <GridContainer>
          <ConfirmModal
            isOpen={this.state.isWaitingConfirmation}
            title={
              <Translate id="Common@confirmAction">
                Confirmar esta ação
              </Translate>
            }
            didCancel={this.didCancelConfirmation}
            didConfirm={
              this.state.confirmationObjective === "submit"
                ? this.didConfirmSubmit
                : this.didTurnIntoWan
            }
          >
            {this.getConfirmationMessage()}
          </ConfirmModal>
          <GridItem xs={12} md={4}>
            <List>
              {interfaces.map(
                (
                  { interface: iface, label, type, networks },
                  interfaceIndex
                ) => {
                  return (
                    <Fragment key={interfaceIndex}>
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              backgroundColor:
                                type === "wan" ? primaryColor : grayColor,
                            }}
                          >
                            <span
                              style={{
                                fontSize: 12,
                                textTransform: "uppercase",
                              }}
                            >
                              {type}
                            </span>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={this.getInterfaceLabel(
                            label,
                            interfaceIndex
                          )}
                          secondary={iface}
                        />
                        <ListItemSecondaryAction>
                          <Button
                            small
                            justIcon
                            color="transparent"
                            onClick={this.didWantToEditInterface(
                              interfaceIndex
                            )}
                            style={{ margin: 0 }}
                          >
                            <i className="fas fa-cog" />
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                      {networks.map(({ label }, networkIndex) => {
                        const isSelected =
                          interfaceIndex === selectedInterfaceIndex &&
                          networkIndex === selectedNetworkIndex;
                        return (
                          <ListItem
                            key={networkIndex}
                            button
                            divider
                            dense
                            onClick={this.didWantToEditNetwork(
                              interfaceIndex,
                              networkIndex
                            )}
                            style={{
                              backgroundColor: isSelected
                                ? primaryColor
                                : "inherit",
                              color: isSelected ? "#fff" : "inherit",
                            }}
                          >
                            <ListItemText
                              primary={this.getNetworkLabel(
                                label,
                                networkIndex
                              )}
                            />
                            {type === "lan" && (
                              <ListItemSecondaryAction>
                                <Button
                                  small
                                  justIcon
                                  color="transparent"
                                  onClick={this.removeNetwork(
                                    interfaceIndex,
                                    networkIndex
                                  )}
                                >
                                  <i
                                    className="fas fa-trash-alt"
                                    style={{ color: dangerColor, fontSize: 12 }}
                                  />
                                </Button>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        );
                      })}
                      {type === "lan" && networks.length < 10 && (
                        <ListItem
                          button
                          dense
                          onClick={this.addNetwork(interfaceIndex)}
                          style={{ textAlign: "center" }}
                        >
                          <ListItemText
                            primary={
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  fontSize: 12,
                                }}
                              >
                                <Translate id="Common@add">Adicionar</Translate>{" "}
                                <i className="fas fa-plus" />
                              </span>
                            }
                          />
                        </ListItem>
                      )}
                      {interfaceIndex < interfaces.length - 1 && (
                        <Divider light style={{ marginTop: 30 }} />
                      )}
                    </Fragment>
                  );
                }
              )}
            </List>
          </GridItem>
          {this.renderSelectedNetwork()}
        </GridContainer>
      ),
      action: (
        <Button
          color="primary"
          onClick={this.onSubmit}
          block
          isLoading={isWaitingForm}
        >
          <SetupString /> <SaveIcon></SaveIcon>
        </Button>
      ),
    });
  }
}

export default withStyles(styles)(ModWifiTerminalConfigureNetworkForm);
