export const humanFileSizeFromBytes = (bytes: number, depth: number = 1) => {
  const thresh = 1000;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** depth;
  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(depth) + " " + units[u];
};

export const textIncludesSearchString = (
  textToSearch: string,
  searchString: string
) => {
  return textToSearch
    ? textToSearch.toLowerCase().includes(searchString.toLowerCase())
    : false;
};

export const didSearchList = (
  searchString: string,
  itemsToSearchList: any[],
  compare: (
    item: any,
    textIncludesSearchString: (key: string) => boolean,
    searchStringItem: string
  ) => boolean
) => {
  const splitSearchString = searchString
    .toLowerCase()
    .split(",")
    .filter((item) => item.trim().length > 0);

  return itemsToSearchList.filter(
    (item) =>
      splitSearchString.filter((searchStringItem) =>
        compare(
          item,
          (textString) =>
            textIncludesSearchString(textString, searchStringItem.trim()),
          searchStringItem.trim()
        )
      ).length > 0
  );
};

export const isObject = (param: any): boolean =>
  typeof param === "object" && param instanceof Object && !Array.isArray(param);

export const isArray = (param: any): boolean =>
  typeof param === "object" && param instanceof Object && Array.isArray(param);

export const hexToRGBA = (hex: string, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r},${g},${b},${alpha})`;
};

export const removeUndefinedFromArray = (arrayToSanitize: any[]) => {
  return arrayToSanitize
    .filter((element: any) => element !== undefined)
    .map((element: any) => {
      if (isObject(element)) {
        return removeUndefinedFromObject(element);
      }
      return element;
    });
};

export const removeUndefinedFromObject = (objectToSanitize: object) => {
  if (!isObject(objectToSanitize)) {
    return null;
  }
  const sanitizedObject = {};

  Object.keys(objectToSanitize).forEach((key) => {
    if (objectToSanitize[key] && isObject(objectToSanitize[key])) {
      sanitizedObject[key] = removeUndefinedFromObject(objectToSanitize[key]); // recurse
    } else if (objectToSanitize[key] && isArray(objectToSanitize[key])) {
      sanitizedObject[key] = removeUndefinedFromArray(objectToSanitize[key]);
    } else if (objectToSanitize[key] !== undefined) {
      sanitizedObject[key] = objectToSanitize[key]; // copy value
    }
  });

  return sanitizedObject;
};
