// @ts-nocheck
import { Fragment } from "react";
import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import AdminDevicesGlobalSearchContainer from "./adminToolsPage/AdminDevicesGlobalSearchContainer";

const AdminToolsPage = () => {
  return (
    <Fragment>
      <PageTitle id="Menu@adminTools">Admin Tools</PageTitle>
      <AdminDevicesGlobalSearchContainer />
    </Fragment>
  );
};

export default withPageAllowedRules({
  component: AdminToolsPage,
  pageAllowedRule: "simplyas",
});
