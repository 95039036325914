// @ts-nocheck

import { observer } from "mobx-react";
import BarChart from "../../components/Charts/BarChart";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";

import { GridContainer, GridItem } from "../../components/Grid";

// Translate
import Translate from "../../components/I18n/Translate";

// Style
import { primaryColor, grayColor } from "../../assets/jss/app-js-styles";
const styles = {
  statsTitle: {
    color: grayColor,
    textAlign: "center",
  },
  statsLabel: {
    color: primaryColor,
    textAlign: "center",
    fontSize: "1.4em",
    fontWeight: "bold",
  },
};

const CameraAccessByGroupTotalChartWidget = observer(
  ({ classes, chartData }) => {
    if (!chartData) return null;
    const accessByGroup = chartData.accessByGroup;
    const groupsCount = Object.keys(accessByGroup).length;
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <BarChart
              horizontal
              showDataLabels
              height={groupsCount * 46 + 60}
              xAxisCategories={Object.keys(accessByGroup)}
              customTooltip={{
                enabled: false,
              }}
              data={[
                {
                  data: Object.values(accessByGroup).map((data) => data.total),
                },
              ]}
            />{" "}
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" style={{ marginTop: 30 }}>
          <GridItem xs={6} md={4}>
            <p className={classes.statsTitle}>
              <Translate id="Metrics@wifiBusiestGroupLabel">
                Mais acessos
              </Translate>
            </p>
            <p className={classes.statsLabel}>{chartData.wifiBusiestGroup}</p>
          </GridItem>
          <GridItem xs={6} md={4}>
            <p className={classes.statsTitle}>
              <Translate id="Metrics@wifiLessBusyGroupLabel">
                Menos acessos
              </Translate>
            </p>
            <p className={classes.statsLabel} style={{ color: grayColor }}>
              {chartData.wifiLessBusyGroup}
            </p>
          </GridItem>
        </GridContainer>
      </>
    );
  }
);

export default withLocalized(
  withStyles(styles)(CameraAccessByGroupTotalChartWidget)
);
