//@ts-nocheck
import { ChangeEvent, useEffect, useState } from "react";
import { ModboxArea } from "../../Model/PlaylistModel";
import { InputText } from "../Form";
import { GridContainer, GridItem } from "../Grid";

interface ILedAreaFormProps {
  onChange: (newValue: ModboxArea) => void;
  value: ModboxArea;
}

const LedAreaForm = ({ onChange, value }: ILedAreaFormProps) => {
  const [ledArea, setLedArea] = useState({
    ledX1: "",
    ledY1: "",
    ledX2: "",
    ledY2: "",
  });

  useEffect(() => {
    if (value) {
      const splitLedArea = value.split(" ");
      setLedArea({
        ledX1: splitLedArea?.[0] || "",
        ledY1: splitLedArea?.[1] || "",
        ledX2: splitLedArea?.[2] || "",
        ledY2: splitLedArea?.[3] || "",
      });
    }
  }, [setLedArea, value]);

  useEffect(() => {
    const { ledX1, ledY1, ledX2, ledY2 } = ledArea;
    let newValue = "";
    if (ledX1 && ledY1 && ledX2 && ledY2) {
      newValue = `${ledX1} ${ledY1} ${ledX2} ${ledY2}`;
    }
    onChange(newValue);
  }, [ledArea, onChange]);

  return (
    <GridContainer>
      {["X1", "Y1", "X2", "Y2"].map((label) => {
        const stateKey = `led${label}`;
        return (
          <GridItem key={label} xs={12} sm={3}>
            <InputText
              label={`Led ${label}`}
              name={stateKey}
              onChange={onChangeLedArea}
              value={ledArea[stateKey]}
            />
          </GridItem>
        );
      })}
    </GridContainer>
  );

  function onChangeLedArea({ target }: ChangeEvent<HTMLInputElement>) {
    const { name: key, value } = target;
    setLedArea((prevState) => ({
      ...prevState,
      [key]: value.replace(/[^0-9-]/g, ""),
    }));
  }
};

export default LedAreaForm;
