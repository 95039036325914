// @ts-nocheck


import EmptyStatus from "./EmptyStatus/EmptyStatus";
import DrinkMachine from "./DrinkMachine/DrinkMachine";
import SquareDropLoader from "./SquareDropLoader/SquareDropLoader";

const RandomAnimation = props => {
  const animations = [
    <EmptyStatus {...props} />,
    <DrinkMachine {...props} />,
    <SquareDropLoader {...props} />
  ];

  const randomIndex = Math.floor(Math.random() * animations.length);

  return animations[randomIndex];
};

RandomAnimation.defaultProps = {
  height: 200
};

export default RandomAnimation;
