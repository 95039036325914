// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import { Component, Fragment } from "react";

import { InputText } from "../../components/Form";
import InputSlider from "../../components/Form/InputSlider";
import MaterialSelectInput from "../../components/Form/MaterialSelectInput";
import NumberEditingWithLabelInput from "../../components/Form/NumberEditingWithLabelInput";
import { withLocalized } from "../../components/HOC";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";

import ToolboxBar, {
  calculateToolboxHeightForActionsList,
} from "../../components/Toolbox/ToolboxBar";

import { Button } from "../../components/Button";
import { GridContainer, GridItem } from "../../components/Grid";
import Modal from "../../components/Modal/Modal";

import LedAreaForm from "../../components/LedArea/LedAreaForm";
import GridListMediaContainer from "./GridListMediaContainer";
import NoMediasInPlaylistHint from "./NoMediasInPlaylistHint";
import SelectMediaContainer from "./SelectMediaContainer";

import { MinutesString, SaveString } from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

import { EInternalEvents } from "../../store/InternalEventsStore/InternalEventsStore";
import { MediaTypeEnum } from "../../store/MediaLibraryStore/MediaLibraryStore";
import {
  hasLiveContent,
  hasLiveStreaming,
} from "../../store/PlaylistsStore/PlaylistsStore";
import { TUTORIALS_KEYS } from "../../store/TutorialsStore/TutorialsStore";

import PlaylistModel from "../../Model/PlaylistModel";
import { IMAGE_PATH } from "../../utils/EnvUtils";
import { generateRandomLabel } from "../../utils/StringUtils";

// Style
const styles = (theme) => ({
  sectionTitle: {
    marginBottom: 0,
  },
  actionButtonsContainer: {
    position: "relative",
  },
  leftActionButtons: {
    position: "sticky",
    top: 8,
    zIndex: 1000000,
  },
  leftActionButtonsManagingMedia: {
    top: 105,
  },
  rightActionButtons: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  videoWallHintContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  videoWallHintImage: {
    maxWidth: "100%",
    alignSelf: "center",
  },
  openVideoWallTutorialButtonContainer: {
    position: "absolute",
    top: 4,
    right: 80,
    zIndex: 10,
  },
  openMediaLibraryTutorialButtonContainer: {
    position: "absolute",
    top: 10,
    right: 80,
    zIndex: 10,
  },
});

@withLocalized
@inject(
  "playlistsStore",
  "internalEventsStore",
  "contractStore",
  "notificationsStore",
  "settingsStore",
  "tutorialsStore"
)
@observer
class PlaylistConfigurationForm extends Component {
  constructor(props) {
    super(props);

    // Subscribe to internal event
    this.mediaWasDeletedEventToken = this.props.internalEventsStore.subscribeTo(
      {
        eventKey: EInternalEvents.mediaWasDeleted,
        observer: this,
        callback: this.mediaWasDeleted,
      }
    );

    if (props.playlist) {
      this.state = this.getStateFromExistingPlaylist(props.playlist);
    } else {
      this.state = this.getDefaultState();
    }
  }

  componentWillUnmount() {
    this.props.internalEventsStore.unsubscribe(
      this,
      EInternalEvents.mediaWasDeleted
    );
  }

  getDefaultState = () => {
    return {
      label: "",
      isRandom: false,
      isMuted: true,
      isVertical: false,
      isVideoWall: false,
      videoWallScreensCount: 0,
      hasLiveContent: false,
      hasLiveStreaming: false,
      recentlyAddedMediaCount: 0,
      isEditingDurationToLiveContent: false,
      durationToLiveContent: this.props.settingsStore
        .informativeDurationToLiveContent,
      mediasList: [],
      ledArea: "",
      screenBrightness: "100",
      isEditingScreenBrightness: false,
      isEditingLedArea: false,
      isAddingMedia: false,
      modalIsOpen: false,
      isManagingMedia: false,
      isWaitingForm: false,
      formWasSubmit: false,
      didWantToDelete: false,
      shouldPresentVideoWallHint: false,
      wasEdited: false,
    };
  };

  getStateFromExistingPlaylist = (playlist: PlaylistModel) => ({
    ...this.getDefaultState(),
    ...playlist,
    hasLiveContent: hasLiveContent(playlist.mediasList),
    hasLiveStreaming: hasLiveStreaming(playlist.mediasList),
    label: this.props.isCloning ? "" : playlist.label,
    wasEdited: this.props.isCloning,
  });

  mediaWasDeleted = ({ deletedMediaToken }) => {
    const filteredMediasList = this.state.mediasList.filter(
      ({ token }) => token !== deletedMediaToken
    );

    if (filteredMediasList.length !== this.state.mediasList.length) {
      this.setState({
        mediasList: filteredMediasList,
        wasEdited: true,
      });
    }
  };

  toggleIsEditingDurationToLiveContentModal = () => {
    this.setState({
      isEditingDurationToLiveContent: true,
      modalIsOpen: true,
    });
  };

  toggleIsEditingScreenBrightness = () => {
    this.setState({
      isEditingScreenBrightness: true,
      modalIsOpen: true,
    });
  };

  toggleIsEditingLedArea = () => {
    this.setState({
      isEditingLedArea: true,
      modalIsOpen: true,
    });
  };

  didChangeDurationToLiveContent = (durationToLiveContent) => {
    this.setState({
      durationToLiveContent: durationToLiveContent * 60,
      wasEdited: true,
    });
  };

  onChangeInputText = (event) => {
    const { name: key, value, dataset, minLength, maxLength } = event.target;
    const validateIsRequired = dataset
      ? dataset.required === "true"
        ? value !== ""
        : true
      : true;
    const validateMinLength = minLength > 0 ? value.length >= minLength : true;
    const validateMaxLength = maxLength > 0 ? value.length <= maxLength : true;

    this.setState((prevState) => ({
      [key]: value,
      isValid: {
        ...prevState.isValid,
        [`${key}`]:
          validateIsRequired && validateMinLength && validateMaxLength,
      },
      wasEdited: true,
    }));
  };

  convertScreenBrightnessStringToNumber = (
    screenBrightness: string
  ): number => {
    const screenBrightnessInt = parseInt(screenBrightness, 10);
    return Math.round(screenBrightnessInt);
  };

  onChangeScreenBrightness = ({ value }: { value: number }) => {
    this.setState({
      screenBrightness: `${parseInt(value, 10)}`,
      wasEdited: true,
    });
  };

  handleSwitchChange = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
      wasEdited: true,
    }));
  };

  toggleIsRandom = () => {
    this.handleSwitchChange("isRandom");
  };
  toggleIsMuted = () => {
    this.handleSwitchChange("isMuted");
  };
  toggleIsVertical = () => {
    this.handleSwitchChange("isVertical");
  };
  toggleLedArea = () => {
    this.handleSwitchChange("ledArea");
  };

  toggleIsVideoWall = () => {
    const shouldPresentVideoWallHint =
      window.localStorage.getItem("didPresentVidelWallHint") === null;

    this.setState(
      (prevState) => {
        const newState = !prevState.isVideoWall;

        return {
          isVideoWall: newState,
          videoWallScreensCount: newState ? 2 : 0,
          shouldPresentVideoWallHint,
          modalIsOpen: shouldPresentVideoWallHint,
          wasEdited: true,
        };
      },
      () => {
        if (shouldPresentVideoWallHint) {
          window.localStorage.setItem("didPresentVidelWallHint", true);
        }
      }
    );
  };

  onChangeSelectInput = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
      wasEdited: true,
    });
  };

  onChangeLedArea = (ledArea) => {
    this.setState({
      ledArea,
      wasEdited: true,
    });
  };

  getFormDataToSubmit = ({
    label,
    isRandom,
    isMuted,
    isVertical,
    videoWallScreensCount,
    durationToLiveContent,
    mediasList,
    screenBrightness,
    ledArea,
  }) => {
    return {
      label: label.length > 0 ? label : generateRandomLabel("Playlist"),
      isRandom,
      isMuted,
      isVertical,
      videoWallScreensCount,
      durationToLiveContent,
      files: mediasList.map(({ token }) => token),
      screenBrightness,
      ledArea,
    };
  };

  submitForm = () => {
    const { playlist, isCloning } = this.props;
    const formData = this.getFormDataToSubmit(this.state);

    if (playlist && !isCloning) {
      this.props.playlistsStore
        .update({
          ...formData,
          token: playlist.token,
        })
        .then(() => {
          this.submitHasSuccess();
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      this.props.playlistsStore
        .submit(formData)
        .then(() => {
          this.submitHasSuccess();
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  didWantToDelete = (event) => {
    event.preventDefault();

    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.playlistsStore
      .delete({
        token: this.props.playlist.token,
      })
      .then(() => {
        this.props.didDelete();
      });
  };

  didWantToAddNewMedia = () => {
    this.setState({
      modalIsOpen: true,
      isAddingMedia: true,
    });
  };

  didCloseModal = () => {
    this.setState(
      {
        modalIsOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isAddingMedia: false,
            isEditingDurationToLiveContent: false,
            isEditingScreenBrightness: false,
            isEditingLedArea: false,
            shouldPresentVideoWallHint: false,
          });
        }, 200);
      }
    );
  };

  toggleManageMedias = () => {
    if (this.state.isManagingMedia) {
      const newMediasList = this.state.mediasList.map((mediaItem) => {
        return {
          ...mediaItem,
          isSelectedToDelete: false,
        };
      });
      this.didChangePlaylistMediaList(newMediasList, {
        isManagingMedia: false,
        wasEdited: this.state.wasEdited,
      });
    } else {
      this.setState({
        isManagingMedia: true,
      });
    }
  };

  didAddNewMediaList = (newMediasList) => {
    let newList = [];
    let recentlyAddedMediaCount = 0;
    newMediasList.forEach((mediaItem) => {
      for (let index = 0; index < mediaItem.selectedQty; index++) {
        newList.push(mediaItem);
        recentlyAddedMediaCount++;
      }
    });

    this.didChangePlaylistMediaList([...newList, ...this.state.mediasList], {
      isAddingMedia: false,
      recentlyAddedMediaCount,
    });
  };

  didSelectMedia = (itemIndex) => {
    if (this.state.isManagingMedia) {
      this.didSelectMediaToDelete(itemIndex);
    }
  };

  didSelectMediaToDelete = (itemIndex) => {
    let newMediasList = [];
    this.state.mediasList.forEach((mediaItem, index) => {
      if (index === itemIndex) {
        newMediasList.push({
          ...mediaItem,
          isSelectedToDelete: mediaItem.isSelectedToDelete
            ? !mediaItem.isSelectedToDelete
            : true,
        });
      } else {
        newMediasList.push(mediaItem);
      }
    });
    this.didChangePlaylistMediaList(newMediasList, {
      wasEdited: this.state.wasEdited,
    });
  };

  didReorderMediaItemsList = (newMediasList) => {
    this.didChangePlaylistMediaList(newMediasList);
  };

  deleteSelectedMediasFromPlaylist = () => {
    const newMediasList = this.state.mediasList.filter(
      (mediaItem) => !mediaItem.isSelectedToDelete
    );

    this.didChangePlaylistMediaList(newMediasList, {
      isAddingMedia: false,
      isManagingMedia: false,
    });
  };

  didChangePlaylistMediaList = (newMediasList, othersStateToChange = {}) => {
    this.setState({
      mediasList: newMediasList,
      hasLiveContent: hasLiveContent(newMediasList),
      hasLiveStreaming: hasLiveStreaming(newMediasList),
      recentlyAddedMediaCount: 0,
      wasEdited: true,
      ...othersStateToChange,
    });
  };

  validateVideoWall = () => {
    const { localizedString } = this.props;
    const { isVideoWall, mediasList } = this.state;
    if (!isVideoWall) return true;

    let videoWallMediasAreUploadType = true;
    const mediasAreEnoughToVideoWall = mediasList.length >= 1; //videoWallScreensCount;

    if (mediasAreEnoughToVideoWall) {
      if (mediasList.find((f) => f.type !== MediaTypeEnum.UPLOAD)) {
        videoWallMediasAreUploadType = false;
      }
    } else {
      this.props.notificationsStore.addSnackbarNotification({
        title: localizedString({
          id: "Common@invalidConfiguration",
          defaultString: "Configuração inválida",
        }),
        message: localizedString({
          id: "VideoWall@insufficientMedias",
          defaultString:
            "Mídias insuficientes para a quantidade de telas deste Vídeo wall.",
        }),
        color: "danger",
      });
      return false;
    }

    if (!videoWallMediasAreUploadType) {
      this.props.notificationsStore.addSnackbarNotification({
        title: localizedString({
          id: "Common@invalidConfiguration",
          defaultString: "Configuração inválida",
        }),
        message: localizedString({
          id: "VideoWall@invalidMediaType",
          defaultString:
            "Mídias para tela(s) de Vídeo wall precisam ser do tipo Upload.",
        }),
        color: "danger",
      });
    }

    return videoWallMediasAreUploadType;
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.validateVideoWall()) {
      this.setState(
        {
          isWaitingForm: true,
          formWasSubmit: true,
        },
        this.submitForm
      );
    } else {
      this.setState({
        formWasSubmit: true,
      });
    }
  };

  submitHasSuccess = () => {
    this.props.didSave();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  playlistToolboxActions = () => {
    const { localizedString, localizedPlural } = this.props;
    const {
      isRandom,
      isMuted,
      isVertical,
      isVideoWall,
      mediasList,
      screenBrightness,
      durationToLiveContent,
      isManagingMedia,
      hasLiveContent,
      hasLiveStreaming,
      wasEdited,
      ledArea,
    } = this.state;
    const isEditing = this.props.playlist && !this.props.isCloning;
    const hasLedArea = !!ledArea;

    const {
      ledPanelSupport,
      videowall,
    } = this.props.contractStore.selectedContract.resources;

    const list = [
      {
        type: "button",
        label: localizedString({
          id: "Informative@addMediasButtonLabel",
          defaultString: "Adicionar mídias",
        }),
        onClick: this.didWantToAddNewMedia,
        icon: "plus",
      },
      {
        type: "button",
        label: localizedString({
          id: "Informative@removeMediasButtonLabel",
          defaultString: "Remover mídias",
        }),
        disabled: mediasList.length < 1,
        onClick: this.toggleManageMedias,
        icon: "minus",
      },
      {
        type: "divider",
      },
      {
        type: "button",
        label: isRandom
          ? localizedString({
              id: "Informative@randomIsActiveLabel",
              defaultString: "Randômico ligado",
            })
          : localizedString({
              id: "Informative@randomIsInactiveLabel",
              defaultString: "Randômico desligado",
            }),
        isActive: isRandom,
        onClick: this.toggleIsRandom,
        icon: "random",
      },
      {
        type: "button",
        label: isMuted
          ? localizedString({
              id: "Informative@muteIsActiveLabel",
              defaultString: "Sem áudio",
            })
          : localizedString({
              id: "Informative@muteIsInactiveLabel",
              defaultString: "Com áudio",
            }),
        isActive: !isMuted,
        onClick: this.toggleIsMuted,
        icon: isMuted ? "volume-off" : "volume",
      },
      {
        type: "button",
        label: isVertical ? (
          <Translate id="Informative@verticalIsActiveLabel">
            Vertical ativo. A mídia precisa estar na resolução{" "}
            <strong>1080px de largura e 1920px de altura</strong>.
          </Translate>
        ) : (
          localizedString({
            id: "Informative@verticalIsInactiveLabel",
            defaultString: "Vertical inativo",
          })
        ),
        isActive: isVertical,
        onClick: this.toggleIsVertical,
        icon: "arrows-alt-v",
      },
      {
        type: "button",
        label: isVideoWall
          ? localizedString({
              id: "Informative@videoWallIsActiveLabel",
              defaultString: "Vídeo wall ativo",
            })
          : localizedString({
              id: "Informative@videoWallIsInactiveLabel",
              defaultString: "Vídeo wall inativo",
            }),
        isActive: isVideoWall,
        onClick: this.toggleIsVideoWall,
        icon: "th-large",
        shouldBeExcluded: !videowall,
      },
      {
        type: hasLiveContent || hasLiveStreaming ? "button" : "hidden",
        label: localizedPlural({
          id: "Playlist@durationToLiveContentLabel",
          value: durationToLiveContent / 60,
          one: "Duração para conteúdos ao vivo: um minuto",
          other: "Duração para conteúdos ao vivo: # minutos",
        }),
        isActive: true,
        onClick: this.toggleIsEditingDurationToLiveContentModal,
        icon: "clock",
      },
      {
        type: "button",
        label: localizedString({
          id: "Informative@ledAreaLabel",
          defaultString: "Led",
        }),
        isActive: hasLedArea,
        onClick: this.toggleIsEditingLedArea,
        icon: "draw-square",
        shouldBeExcluded: !ledPanelSupport,
      },
      {
        type: "divider",
      },
      {
        type: "button",
        label: localizedString({
          id: "Informative@screenBrightnessLabel",
          defaultString: "Luminosidade da tela",
        }),
        isActive: screenBrightness !== "100",
        onClick: this.toggleIsEditingScreenBrightness,
        icon: "lightbulb-on",
      },
      {
        type: "divider",
      },
      {
        type: isEditing ? "button" : "hidden",
        label: localizedString({
          id: "Common@delete",
          defaultString: "Apagar",
        }),
        isActive: true,
        activeColor: "danger",
        onClick: this.didWantToDelete,
        icon: "trash-alt",
      },
      {
        type: "submit",
        label: isEditing
          ? localizedString({
              id: "Common@update",
              defaultString: "Atualizar",
            })
          : localizedString({
              id: "Common@save",
              defaultString: "Salvar",
            }),
        isActive: wasEdited,
        onClick: this.onSubmit,
        disabled: !wasEdited,
        isLoading: this.state.isWaitingForm,
        icon: "save",
      },
      {
        type: "submit",
        label: localizedString({
          id: "Common@close",
          defaultString: "Fechar",
        }),
        isHidden: !this.props.didClose,
        onClick: this.props.didClose,
        icon: "times",
      },
    ].filter(({ shouldBeExcluded }) => !shouldBeExcluded);

    const isManagingMediaList = [
      {
        type: "button",
        label: localizedString({
          id: "Common@back",
          defaultString: "Voltar",
        }),
        onClick: this.toggleManageMedias,
        icon: "chevron-left",
      },
      {
        type: "submit",
        label: localizedString({
          id: "Common@save",
          defaultString: "Salvar",
        }),
        isActive: true,
        onClick: this.deleteSelectedMediasFromPlaylist,
        icon: "save",
      },
    ];

    return {
      list: isManagingMedia ? isManagingMediaList : list,
      height: calculateToolboxHeightForActionsList(
        isManagingMedia ? isManagingMediaList : list
      ),
    };
  };

  openVideoWallTutorial = () => {
    this.props.tutorialsStore.didSelectTutorialKey(TUTORIALS_KEYS.VIDEO_WALL);
  };
  openMediaLibraryTutorial = () => {
    this.props.tutorialsStore.didSelectTutorialKey(
      TUTORIALS_KEYS.MEDIA_LIBRARY
    );
  };

  render() {
    const { classes, localizedString } = this.props;
    const {
      label,
      isVideoWall,
      videoWallScreensCount,
      mediasList,
      screenBrightness,
      isAddingMedia,
      isManagingMedia,
      isEditingDurationToLiveContent,
      isEditingScreenBrightness,
      isEditingLedArea,
      formWasSubmit,
      didWantToDelete,
      ledArea,
    } = this.state;
    const isTutorialsAvailable = this.props.tutorialsStore.isAvailable;

    const {
      list: toolboxActionsList,
      height: toolboxHeight,
    } = this.playlistToolboxActions();

    const screenBrightnessNumber = this.convertScreenBrightnessStringToNumber(
      screenBrightness
    );
    return (
      <Fragment>
        {isTutorialsAvailable && isVideoWall && (
          <div className={classes.openVideoWallTutorialButtonContainer}>
            <Button
              color="transparent"
              justIcon
              onClick={this.openVideoWallTutorial}
            >
              <i className="fas fa-question-circle" />
            </Button>
          </div>
        )}
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteConfiguration}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        {isAddingMedia && (
          <SelectMediaContainer didAddNewMediaList={this.didAddNewMediaList}>
            {({ body, action }) => (
              <Modal
                isOpen={this.state.modalIsOpen}
                didCloseModal={this.didCloseModal}
                modalTitle={
                  <Fragment>
                    {isTutorialsAvailable && (
                      <div
                        className={
                          classes.openMediaLibraryTutorialButtonContainer
                        }
                      >
                        <Button
                          color="transparent"
                          justIcon
                          onClick={this.openMediaLibraryTutorial}
                        >
                          <i className="fas fa-question-circle" />
                        </Button>
                      </div>
                    )}
                    <Translate id="Informative@mediaLibraryTitle">
                      Biblioteca de mídias
                    </Translate>
                  </Fragment>
                }
                maxWidth="xl"
                fullScreen
                actions={action}
              >
                {body}
              </Modal>
            )}
          </SelectMediaContainer>
        )}
        {isEditingDurationToLiveContent && (
          <Modal
            isOpen={this.state.modalIsOpen}
            didCloseModal={this.didCloseModal}
            maxWidth="sm"
          >
            <GridContainer justify="center">
              <GridItem xs={12} sm={11}>
                <NumberEditingWithLabelInput
                  label={
                    <Translate id="Playlist@durationToLiveContent">
                      Duração para conteúdos ao vivo:
                    </Translate>
                  }
                  typeLabel={<MinutesString />}
                  onChange={this.didChangeDurationToLiveContent}
                  value={this.state.durationToLiveContent / 60}
                />
              </GridItem>
              <GridItem xs={12} sm={11}>
                <Button
                  small
                  block
                  color="primary"
                  onClick={this.didCloseModal}
                >
                  <SaveString /> <i className="fas fa-save" />
                </Button>
              </GridItem>
            </GridContainer>
          </Modal>
        )}
        {isEditingLedArea && (
          <Modal
            isOpen={this.state.modalIsOpen}
            didCloseModal={this.didCloseModal}
            maxWidth="sm"
          >
            <GridContainer>
              <GridItem xs={12}>
                <LedAreaForm onChange={this.onChangeLedArea} value={ledArea} />
              </GridItem>
              <GridItem xs={12}>
                <Button
                  small
                  block
                  color="primary"
                  onClick={this.didCloseModal}
                >
                  <SaveString /> <i className="fas fa-save" />
                </Button>
              </GridItem>
            </GridContainer>
          </Modal>
        )}

        {isEditingScreenBrightness && (
          <Modal
            isOpen={this.state.modalIsOpen}
            didCloseModal={this.didCloseModal}
            maxWidth="sm"
          >
            <GridContainer
              justify="center"
              style={{ marginTop: 40, marginBottom: 40 }}
            >
              <GridItem xs={12} sm={11}>
                <InputSlider
                  label={
                    <Translate id="Playlist@screenBrightnessContent">
                      Luminosidade da tela
                    </Translate>
                  }
                  name="screenBrightness"
                  min={10}
                  step={5}
                  value={screenBrightnessNumber}
                  renderTooltip={(_) => `${screenBrightnessNumber}%`}
                  onSlide={this.onChangeScreenBrightness}
                />
              </GridItem>
              <GridItem xs={12} sm={11}>
                <Button
                  small
                  block
                  color="primary"
                  onClick={this.didCloseModal}
                >
                  <SaveString /> <i className="fas fa-save" />
                </Button>
              </GridItem>
            </GridContainer>
          </Modal>
        )}
        <div
          style={{
            position: "relative",
            paddingLeft: 50,
            minHeight: toolboxHeight,
          }}
        >
          <ToolboxBar
            leftPosition={-24}
            actions={toolboxActionsList}
            isDisabled={this.state.isWaitingForm}
          />
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                label={
                  <Translate id="Playlist@titleLabel">
                    Título da playlist (opcional)
                  </Translate>
                }
                name="label"
                hasSuccess={formWasSubmit && label.length > 0}
                value={label}
                onChange={this.onChangeInputText}
                maxLength={60}
              />
            </GridItem>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12} style={{ marginTop: 20, marginBottom: 12 }}>
                  {mediasList.length > 0 ? (
                    <GridListMediaContainer
                      didWantToAddNewMedia={this.didWantToAddNewMedia}
                      isManagingMedia={isManagingMedia}
                      mediaItemsList={mediasList}
                      didSelectMedia={this.didSelectMedia}
                      didReorderMediaItemsList={this.didReorderMediaItemsList}
                      recentlyAddedMediaCount={
                        this.state.recentlyAddedMediaCount
                      }
                    />
                  ) : (
                    <NoMediasInPlaylistHint />
                  )}
                </GridItem>

                {isVideoWall && this.state.shouldPresentVideoWallHint && (
                  <Modal
                    isOpen={this.state.modalIsOpen}
                    didCloseModal={this.didCloseModal}
                    modalTitle={
                      <Translate id="Playlist@openVideoWallHint">
                        Como organizar o Vídeo Wall
                      </Translate>
                    }
                    maxWidth="xl"
                  >
                    <div className={classes.videoWallHintContainer}>
                      <img
                        src={`${IMAGE_PATH}/how-to/video-wall.png`}
                        className={classes.videoWallHintImage}
                        alt={
                          <Translate id="Playlist@videoWall">
                            Vídeo wall
                          </Translate>
                        }
                      />
                    </div>
                  </Modal>
                )}
                {isVideoWall && (
                  <GridItem xs={12}>
                    <MaterialSelectInput
                      label={
                        <Translate id="Playlist@videoWallScreenCountLabel">
                          Quantas telas este vídeo wall irá utilizar?
                        </Translate>
                      }
                      name="videoWallScreensCount"
                      value={videoWallScreensCount}
                      options={[
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountOne",
                            defaultString: "Uma tela (fixa)",
                          }),
                          value: 1,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountTwo",
                            defaultString: "Duas telas",
                          }),
                          value: 2,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountThree",
                            defaultString: "Três telas",
                          }),
                          value: 3,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountFour",
                            defaultString: "Quatro telas",
                          }),
                          value: 4,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountFive",
                            defaultString: "Cinco telas",
                          }),
                          value: 5,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountSix",
                            defaultString: "Seis telas",
                          }),
                          value: 6,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountSeven",
                            defaultString: "Sete telas",
                          }),
                          value: 7,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountEight",
                            defaultString: "Oito telas",
                          }),
                          value: 8,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountNine",
                            defaultString: "Nove telas",
                          }),
                          value: 9,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountTen",
                            defaultString: "Dez telas",
                          }),
                          value: 10,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountEleven",
                            defaultString: "Onze telas",
                          }),
                          value: 11,
                        },
                        {
                          label: localizedString({
                            id: "VideoWall@screenCountTwelve",
                            defaultString: "Doze telas",
                          }),
                          value: 12,
                        },
                      ]}
                      onChange={this.onChangeSelectInput}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(PlaylistConfigurationForm);
