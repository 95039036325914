// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "../../components/Tooltip/Tooltip";

import Translate from "../../components/I18n/Translate";

// Icons
import { infoColor } from "../../assets/jss/app-js-styles";

// Style
const styles = {
  card: {
    cursor: "pointer",
    margin: 16,
    height: "fit-content",
  },
  button: {
    width: "100%",
    height: "100%",
    fontSize: 80,
    borderRadius: 6,
    minHeight: 162,
    color: infoColor,
  },
};

const AddLocationButton = ({ classes, onClick }) => (
  <Tooltip
    title={
      <Translate id="LocationsPage@addModalTitle">Adicionar localidade</Translate>
    }
    placement="right"
  >
    <div className={classes.card}>
      <IconButton className={classes.button} onClick={onClick}>
        <i className="fas fa-globe" />
      </IconButton>
    </div>
  </Tooltip>
);

export default withStyles(styles)(AddLocationButton);
