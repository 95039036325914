// @ts-nocheck

import {
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// Translate
import Plural from "../../components/I18n/Plural";

// UTILS
import { IMAGE_PATH } from "../../utils/EnvUtils";

const styles = (theme) => ({
  card: {
    width: "100%",
    marginBottom: 20,
    position: "relative",
    cursor: "pointer",
  },
  cardHeader: {
    padding: 0,
    textAlign: "center",
    color: "#fff",
    minHeight: 100,
  },
  cardHeaderTitle: {
    fontSize: "1.2em",
    fontWeight: 500,
    color: "#333",
  },
  cardHeaderSubtitle: {
    fontSize: "0.9em",
    marginTop: -2,
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    height: 90,
    textAlign: "center",
    marginBottom: 5,
  },
  groupImg: {
    maxWidth: "150px",
    margin: "0 auto",
    filter: "grayscale(100%)",
  },
});

const GroupItemContainer = ({ classes, group, didWantToOpenGroupModal }) => (
  <Card
    className={classes.card}
    onClick={() => didWantToOpenGroupModal(group.token)}
  >
    <CardHeader
      classes={{
        title: classes.cardHeaderTitle,
        subheader: classes.cardHeaderSubtitle,
      }}
      className={classes.cardHeader}
      title={group.exhibitionName}
      subheader={
        group.terminalsList.length > 0 ? (
          <Plural
            id="DevicesPage@devicesQtyLabel"
            value={group.terminalsList.length}
            one={"# dispositivo"}
            other={"# dispositivos"}
          />
        ) : (
          "-"
        )
      }
    />
    <CardContent className={classes.cardContent}>
      <img
        src={`${IMAGE_PATH}/${group.icon}.jpeg`}
        alt={group.icon}
        className={classes.groupImg}
      />
    </CardContent>
  </Card>
);

export default withStyles(styles)(GroupItemContainer);
