// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Avatar, List, ListItem, ListItemText } from "@material-ui/core";

import withPageAllowedRules from "./withPageAllowedRules";
import { withLocalized } from "../components/HOC";

// @material-ui/core components
import PageTitle from "../components/PageTitle/PageTitle";
import { LinkButton } from "../components/Button";

// core components
import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardHeader, CardText, CardBody } from "../components/Card";

import { LoaderRing } from "../components/Animations";

import ContractUsagesLimitWidget from "../components/Widgets/ContractUsagesLimitWidget";

import ContractBusinessDetailsWidget from "./administrativeContractPage/ContractBusinessDetailsWidget";

// Translate
import Translate from "../components/I18n/Translate";

// Utils
import { stringDateFromUnixDate } from "../utils/DateUtils";

//Style
import chartsStyle from "../assets/jss/js-styles/views/chartsStyle";

@withLocalized
@inject("contractStore", "i18nStore")
@observer
class AdministrativeContractPage extends Component {
  renderContractItem = ({ icon, value, description }) => (
    <ListItem>
      <Avatar style={{ marginRight: 10 }}>
        <i className={`fas fa-${icon} fa-fw`} />
      </Avatar>
      <ListItemText primary={value} secondary={description} />
    </ListItem>
  );

  getContractStatus = (data, isFetching) => {
    if (isFetching || !data) return null;
    const { isChecked, isWaitingApproval, status } = data;

    const icon = isChecked
      ? "badge-check"
      : isWaitingApproval
      ? "hourglass-end"
      : "puzzle-piece";
    const value = (
      <Translate id={`Contract@business_${status}`}>
        {isChecked
          ? "Cadastro completo"
          : isWaitingApproval
          ? "Aguardando verificação"
          : "Cadastro incompleto"}
      </Translate>
    );

    return this.renderContractItem({
      icon,
      value,
      description: "Status",
    });
  };

  render() {
    const { classes, contractStore, i18nStore } = this.props;
    const {
      selectedContract,
      selectedContractBusinessData,
      isFetchingContractBusinessData,
    } = contractStore;

    if (contractStore.isFetching) {
      return <LoaderRing height={100} />;
    }

    return (
      <div>
        <PageTitle id="Menu@administrativeContract">Contrato</PageTitle>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm={6}>
            <Card className={classes.chartCard}>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitleWhite}>
                    {selectedContract.name}
                  </h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <List>
                  {this.renderContractItem({
                    icon: "key",
                    value: selectedContract.licenseNumber,
                    description: (
                      <Translate id="Contract@licence">Licença</Translate>
                    ),
                  })}
                  {this.renderContractItem({
                    icon: "calendar",
                    value: stringDateFromUnixDate(
                      selectedContract.activationDate,
                      i18nStore.dateFormatWithFourDigitsYear
                    ),
                    description: (
                      <Translate id="Contract@sinceDate">Desde</Translate>
                    ),
                  })}
                  {this.renderContractItem({
                    icon: `star${selectedContract.isPro ? "" : "-half-alt"}`,
                    value: selectedContract.plan.name,
                    description: (
                      <Translate id="Contract@contractType">
                        Tipo de contrato
                      </Translate>
                    ),
                  })}
                  {this.getContractStatus(
                    {
                      ...selectedContractBusinessData,
                      isChecked: selectedContract.isChecked,
                    },
                    isFetchingContractBusinessData
                  )}
                  {!selectedContract.isMasterContract &&
                    this.renderContractItem({
                      icon: "shield-alt",
                      value: selectedContract.name,
                      description: (
                        <Translate id="Contract@masterContract">
                          Contrato principal
                        </Translate>
                      ),
                    })}
                </List>
                {!selectedContract.isPro &&
                  selectedContract.isAffiliateContract && (
                    <LinkButton color="success" block small to="/upgrade">
                      Upgrade <i className="fas fa-level-up-alt" />
                    </LinkButton>
                  )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ContractUsagesLimitWidget />
          </GridItem>
          <GridItem xs={12}>
            <ContractBusinessDetailsWidget />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(chartsStyle)(AdministrativeContractPage),
  pageAllowedRule: "administrative",
});
