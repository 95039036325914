// @ts-nocheck
import { inject } from "mobx-react";
import { Component } from "react";

// ../../Components
import { Button } from "../../components/Button";
import { InputSelect, InputText } from "../../components/Form";
import { GridContainer, GridItem } from "../../components/Grid";

import Validate, { ValidationType } from "../../components/Form/Validate";

// Translate
import {
  CancelString,
  DeleteString,
  SaveString,
  UpdateString,
} from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

// Icon
import {
  ChevronLeftIcon,
  DeleteIcon,
  SaveIcon,
} from "../../components/Icon/MaterialIcon";

@inject("groupsStore", "locationStore", "notificationsStore")
class GroupForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  componentDidMount(): void {
    if (this.props.group) {
      this.getStateFromExistingGroup(this.props.group);
    } else {
      this.getDropdownOptions();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.group !== this.props.group) {
      this.updateForm();
    }
  }

  updateForm = async () => {
    if (this.props.group) {
      await this.getStateFromExistingGroup(this.props.group);
    } else {
      this.setState({ ...this.getDefaultState() });
    }
  };

  getDefaultState = () => {
    return {
      name: "",
      isWaitingForm: false,
      formWasSubmit: false,
      isValid: {
        name: false,
        groupType: false,
      },
    };
  };

  getStateFromExistingGroup = async ({ name, icon, locationToken }) => {
    await this.getDropdownOptions();
    const location = this.state?.locationOptions?.find(
      ({ value }) => value === locationToken
    );
    const groupType = this.state?.groupTypeOptions?.find(
      ({ value }) => value === icon
    );

    this.setState({
      ...this.getDefaultState(),
      name: name,
      location: location,
      groupType: groupType,
      isValid: {
        name: Validate(name, ValidationType.REQUIRED),
        groupType: Validate(groupType, ValidationType.REQUIRED),
      },
    });
  };

  locationOptions = () => {
    const locationOptions = [];
    this.props.locationStore.locationsList.forEach(({ name, token }) => {
      locationOptions.push({ label: name, value: token });
    });
    return locationOptions;
  };

  groupTypeOptions = () => {
    return [
      { label: "Tv", value: "tv" },
      { label: "Videowall", value: "videowall" },
      { label: "Led", value: "led" },
      { label: "Wifi", value: "wifi" },
    ];
  };

  getDropdownOptions = async () => {
    await this.props.locationStore.getLocationsList();
    this.setState({
      locationOptions: this.locationOptions(),
      groupTypeOptions: this.groupTypeOptions(),
    });
  };

  onChangeName = ({ target }) => {
    const { value } = target;

    this.setState({
      name: value,
      isValid: {
        ...this.state.isValid,
        name: Validate(value, ValidationType.REQUIRED),
      },
    });
  };

  onSelect = (name, value) => {
    this.setState({
      [name]: value,
    });

    if (name === "groupType") {
      this.setState({
        isValid: {
          ...this.state.isValid,
          groupType: Validate(value, ValidationType.REQUIRED),
        },
      });
    }
  };

  canSubmitForm = () => {
    const { name, groupType } = this.state.isValid;
    return name && groupType;
  };

  fieldsWithError = () => {
    return Object.keys(this.state.isValid).filter(
      (field) => !this.state.isValid[field]
    );
  };

  errorMessages = () => {
    return {
      name: {
        id: "Group@nameErrorMessage",
        message: "Por favor preencha o nome do grupo.",
      },
      groupType: {
        id: "Group@typeErrorMessage",
        message: "Por favor preencha o tipo do grupo.",
      },
    };
  };

  submitForm = () => {
    const formData = {
      name: this.state.name,
      locationToken: this.state.location?.value,
      icon: this.state.groupType.value,
    };

    if (this.props.group) {
      this.props.groupsStore
        .updateGroup({
          ...this.props.group,
          ...formData,
        })
        .then(() => {
          this.submitHasSuccess({ token: null });
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      this.props.groupsStore
        .submitGroup(formData)
        .then((response) => {
          this.submitHasSuccess({ token: response.data.token });
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  didWantToDelete = (event) => {
    event.preventDefault();

    this.props.didWantToDelete();
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      const fieldsWithError = this.fieldsWithError();
      const errorMessages = this.errorMessages();
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          for (const field of fieldsWithError) {
            this.props.notificationsStore.addSnackbarNotification({
              message: (
                <Translate id={errorMessages[field].id}>
                  {errorMessages[field].message}
                </Translate>
              ),
              color: "danger",
            });
          }
        }
      );
    }
  };

  submitHasSuccess = (token) => {
    if (this.props.didSave) {
      this.props.didSave();
    }
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  render() {
    const isEditing = this.props.group !== null;
    const {
      name,
      location,
      groupType,
      isWaitingForm,
      formWasSubmit,
      locationOptions,
      groupTypeOptions,
      isValid,
    } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <InputText
            label={<Translate id="Group@labelForName">Nome do Grupo</Translate>}
            name="name"
            hasError={formWasSubmit && !isValid.name}
            hasSuccess={formWasSubmit && isValid.name}
            value={name}
            onChange={this.onChangeName}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <InputSelect
            label={
              <Translate id="Group@labelForLocation">Localidade</Translate>
            }
            name="location"
            value={location}
            onChange={this.onSelect}
            options={locationOptions || []}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <InputSelect
            label={
              <Translate id="Group@labelForGroupType">Tipo de Grupo</Translate>
            }
            name="groupType"
            hasError={formWasSubmit && !isValid.groupType}
            hasSuccess={formWasSubmit && isValid.groupType}
            value={groupType}
            onChange={this.onSelect}
            options={groupTypeOptions || []}
          />
        </GridItem>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm={4}>
            <Button block onClick={this.didCancel}>
              <ChevronLeftIcon /> <CancelString />
            </Button>
          </GridItem>
          {isEditing && !this.props.group.isDefault && (
            <GridItem xs={12} sm={4}>
              <Button color="danger" block onClick={this.didWantToDelete}>
                <DeleteString /> <DeleteIcon />
              </Button>
            </GridItem>
          )}
          <GridItem xs={12} sm={4}>
            <Button
              color="primary"
              block
              isLoading={isWaitingForm}
              onClick={this.onSubmit}
            >
              {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
            </Button>
          </GridItem>
        </GridContainer>
      </GridContainer>
    );
  }
}

export default GroupForm;
