// @ts-nocheck
import { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";
import withStyles from "@material-ui/core/styles/withStyles";

import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";

// Icons
import { CheckIcon, ClearIcon } from "../Icon/MaterialIcon";

// Style
import customInputStyle from "../../assets/jss/js-styles/components/customInputStyle";

class InputCurrency extends Component {
  onChangePrice = (values) => {
    this.props.onChange({
      name: this.props.name,
      value: values.value === "" ? 0 : values.floatValue,
    });
  };

  render() {
    const {
      classes,
      value,
      name,
      label,
      placeholder,
      labelProps,
      inputProps,
      formControlProps,
      formHelperTextProps,
      fullWidth = true,
      showPrefix = true,
      disabled,
      isRequired,
      autoFocus,
      helpText,
      hasError,
      hasSuccess,
      leftIcon,
      rightIcon,
    } = this.props;

    const labelClasses = classnames({
      [" " + classes.labelRootError]: hasError,
      [" " + classes.labelRootSuccess]: hasSuccess && !hasError,
    });
    const underlineClasses = classnames({
      [classes.underlineError]: hasError,
      [classes.underlineSuccess]: hasSuccess && !hasError,
      [classes.underline]: true,
      // [classes.whiteUnderline]: white
    });
    const inputClasses = classnames({
      [classes.input]: true,
      // [classes.whiteInput]: white
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
      formControlClasses = classnames(
        formControlProps.className,
        classes.formControl
      );
    } else {
      formControlClasses = classes.formControl;
    }
    const feedbackClasses = classes.feedback;
    const helperTextId = helpText && name ? `${name}-helper-text` : undefined;

    const endAdornment = hasError ? (
      <ClearIcon className={feedbackClasses + " " + classes.labelRootError} />
    ) : hasSuccess ? (
      <CheckIcon className={feedbackClasses + " " + classes.labelRootSuccess} />
    ) : (
      rightIcon
    );

    return (
      <FormControl
        aria-describedby={helperTextId}
        {...formControlProps}
        fullWidth={fullWidth}
        disabled={disabled}
        className={formControlClasses}
      >
        {label !== undefined && (
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            htmlFor={name}
            {...labelProps}
          >
            {label}
          </InputLabel>
        )}
        <NumberFormat
          customInput={Input}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          required={isRequired}
          autoFocus={autoFocus}
          classes={{
            input: inputClasses,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          inputProps={{
            ...inputProps,
          }}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          prefix={showPrefix ? "R$ " : ""}
          onValueChange={this.onChangePrice}
          disabled={disabled}
          startAdornment={leftIcon}
          endAdornment={endAdornment}
        />
        {helpText && (
          <FormHelperText id={helperTextId} {...formHelperTextProps}>
            <i className="fa fa-info-circle" /> {helpText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

InputCurrency.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  formHelperTextProps: PropTypes.object,
  placeholder: PropTypes.string,
  showPrefix: PropTypes.bool,
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  hasSuccess: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
};

export default withStyles(customInputStyle)(InputCurrency);
