export enum EChartType {
  UsageLimit = "usage-limit",
  Bar = "bar",
  Pie = "pie",
  Line = "line",
  List = "list",
  NumericList = "numeric-list",
  Message = "message",
}
export interface IChartLayout {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  icon?: string;
}

export interface IChartModel {
  type: EChartType;
  title: string;
  subtitle?: string;
  layout: IChartLayout;
  data: any;
}

class ChartModel implements IChartModel {
  type: EChartType;
  title: string;
  subtitle?: string;
  layout: IChartLayout;
  data: any;

  constructor(jsonData: any) {
    this.type = EChartType[jsonData.type];
    this.title = jsonData.title;
    this.subtitle = jsonData.subtitle;
    this.layout = jsonData.layout;
    this.data = jsonData.data;
  }
}

export default ChartModel;
