// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import PageTitle from "../components/PageTitle/PageTitle";
import { GridContainer, GridItem } from "../components/Grid";
import LoadingView from "../components/LoadingView/LoadingView";
import InfoLabel from "../components/Label/InfoLabel";
import ChartContainer from "../components/Charts/ChartContainer";

import Translate from "../components/I18n/Translate";

import HowToActivateDevice from "../components/HowTo/HowToActivateDevice";
import RandomAnimation from "../components/Animations/RandomAnimation";

import ReportsStore from "../store/ReportsStore/ReportsStore";

interface IDashboardPageProps {
  classes: any;
  className: any;
}

interface InjectedProps extends IDashboardPageProps {
  reportsStore: ReportsStore;
}

@inject("reportsStore")
@observer
class DashboardPage extends Component<IDashboardPageProps> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this.injected.reportsStore.getDashboardChartData();
  }

  render() {
    const {
      isFetching,
      dashboardChartData: chartData,
      didSelectAction,
    } = this.injected.reportsStore;

    return (
      <Fragment>
        <PageTitle id="Menu@dashboard">Dashboard</PageTitle>
        {isFetching ? (
          <LoadingView />
        ) : !chartData ? (
          <GridContainer justify="center" style={{ marginTop: 40 }}>
            <GridItem xs={12}>
              <InfoLabel
                large
                style={{ textAlign: "center", marginBottom: 30 }}
              >
                <Translate id="ConnectivityBI@noReports">
                  Não encontramos informações relevantes para exibir neste
                  momento.
                </Translate>
              </InfoLabel>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <RandomAnimation />
            </GridItem>
          </GridContainer>
        ) : chartData.length < 1 ? (
          <HowToActivateDevice
            title={
              <Translate id="Dashboard@noDevicesFound">
                Vamos ativar seu primeiro dispositivo?
              </Translate>
            }
          />
        ) : (
          <ChartContainer chartsList={chartData} didSelect={didSelectAction} />
        )}
      </Fragment>
    );
  }
}

export default DashboardPage;
