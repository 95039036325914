// @ts-nocheck
import { Component } from "react";
import { SortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import withStyles from "@material-ui/core/styles/withStyles";

import { GridContainer } from "../../components/Grid";
import GridListMediaItem from "./GridListMediaItem";

const styles = {
  helperClass: {
    zIndex: 2147483647,
  },
};

const SortableList = SortableContainer(
  ({
    mediaItemsList,
    isManagingMedia,
    didSelectMedia,
    randomIsActive,
    disabledPlaylist,
    recentlyAddedMediaCount,
  }) => (
    <GridContainer>
      {mediaItemsList.map((mediaItem, index) => (
        <GridListMediaItem
          key={index}
          index={index}
          itemIndex={index}
          mediaItem={mediaItem}
          disabled={disabledPlaylist || isManagingMedia || randomIsActive}
          disabledPlaylist={disabledPlaylist}
          randomIsActive={randomIsActive}
          isManagingMedia={isManagingMedia}
          didSelect={didSelectMedia}
          recentlyAdded={index < recentlyAddedMediaCount}
        />
      ))}
    </GridContainer>
  )
);

class GridListMediaContainer extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const reordenedList = arrayMove(
      this.props.mediaItemsList,
      oldIndex,
      newIndex
    );

    const left = JSON.stringify(this.props.mediaItemsList);
    const right = JSON.stringify(reordenedList);

    if (left !== right) {
      this.props.didReorderMediaItemsList(reordenedList);
    }
  };

  render() {
    const { classes, randomIsActive, disabledPlaylist } = this.props;
    return (
      <SortableList
        mediaItemsList={this.props.mediaItemsList}
        onSortEnd={this.onSortEnd}
        axis={"xy"}
        distance={10}
        isManagingMedia={this.props.isManagingMedia}
        didSelectMedia={this.props.didSelectMedia}
        helperClass={classes.helperClass}
        disabledPlaylist={disabledPlaylist}
        randomIsActive={randomIsActive}
        recentlyAddedMediaCount={this.props.recentlyAddedMediaCount}
      />
    );
  }
}

export default withStyles(styles)(GridListMediaContainer);
