// @ts-nocheck
import { observable, autorun, computed, action } from "mobx";
import sumBy from "lodash/sumBy";

// Model
import ProductModel from "../../Model/ProductModel";

// Utils Stores
import {
  ordersRef,
  contractsRef,
  serverTimestamp,
  connectedRef,
} from "../FirebaseStore/FirebaseStore";

// Utils
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";

export const OrderStatus = Object.freeze({
  ABANDONED: "abandoned",
  OPEN: "open",
  CANCELED: "canceled",
  WAITING_PAYMENT: "waitingPayment",
  UNDER_ANALYSIS: "underAnalysis",
  PAID: "paid",
});

class OrdersStore {
  @observable
  selectedOrderId = null;

  @observable
  selectedOrder = null;

  @observable
  ordersList = [];

  selectedOrderRef = null;
  onDisconnectRef = null;

  myConnectionsRef = null;

  constructor(
    authStore,
    firebaseAuthStore,
    contractStore,
    modboxAPIService,
    pagSeguroAPIService,
    telegramAPIService
  ) {
    this.authStore = authStore;
    this.contractStore = contractStore;
    this.modboxAPIService = modboxAPIService;
    this.pagSeguroAPIService = pagSeguroAPIService;
    this.telegramAPIService = telegramAPIService;

    autorun(
      () => {
        if (this.selectedOrderId !== null) {
          this.myConnectionsRef = ordersRef.child(
            `${this.selectedOrderId}/isConnected`
          );
          this.setFirebaseListeners();
        } else {
          this.clearStore();
        }
      },
      { name: "OrdersStore setFirebaseListener" }
    );

    autorun(
      () => {
        if (
          firebaseAuthStore.isAuthReady &&
          contractStore.selectedContract &&
          contractStore.selectedContract.isAffiliateContract &&
          contractStore.affiliateContractIsReady
        ) {
          this.getOrderToContract();
        } else {
          this.removeSelectedOrderId();
        }
      },
      { name: "OrdersStore getOrderToContract" }
    );
  }

  @action
  removeSelectedOrderId = () => {
    this.selectedOrderId = null;
  };

  @action
  clearStore = () => {
    this.selectedOrder = null;
    this.ordersList = [];

    if (this.selectedOrderRef) {
      this.selectedOrderRef.off();
      this.selectedOrderRef = null;
    }
    if (this.onDisconnectRef) {
      this.onDisconnectRef.off();
      this.onDisconnectRef = null;
    }
  };

  @action
  setAbandonedCartOrder = async (orderId) => {
    // Check if orderId exists and is abandoned
    const snapshot = await ordersRef.child(orderId).once("value");
    if (snapshot.exists() && snapshot.val().status === "abandoned") {
      this.removeSelectedOrderId();
      this.selectedOrderId = orderId;
    }
  };

  @action
  setFirebaseListeners = async () => {
    connectedRef.on("value", (connectedSnapshot) => {
      if (connectedSnapshot.val() === true) {
        this.onDisconnectRef = this.myConnectionsRef.push();
        this.onDisconnectRef.onDisconnect().remove();
        this.onDisconnectRef.set(true);
      }
    });

    this.selectedOrderRef = ordersRef.child(this.selectedOrderId);
    this.selectedOrderRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        this.selectedOrder = this.getOrderFromSnapshot(snapshot);
      }
    });

    ordersRef
      .orderByChild("contractHash")
      .equalTo(this.contractStore.selectedContract.hash)
      .on("value", (ordersSnapshot) => {
        let newList = [];
        if (ordersSnapshot.val()) {
          ordersSnapshot.forEach((orderSnapshot) => {
            newList.push(this.getOrderFromSnapshot(orderSnapshot));
          });
        }
        this.ordersList = newList
          .sort(dynamicSortByProperty("timestamp"))
          .reverse();
      });
  };

  getOrderFromSnapshot = (snapshot) => {
    const orderData = snapshot.val();
    let productsList = [];

    if (orderData.products) {
      productsList = Object.keys(orderData.products).map((productToken) => {
        const productData = orderData.products[productToken];
        return new ProductModel({
          token: productToken,
          ...productData,
        });
      });
    }

    return {
      id: snapshot.key,
      ...orderData,
      products: productsList,
    };
  };

  @computed
  get notOpenOrAbandonedOrders() {
    return this.ordersList.filter(
      ($0) => ![OrderStatus.ABANDONED, OrderStatus.OPEN].includes($0.status)
    );
  }

  @computed
  get selectedOrderTotalPrice() {
    if (this.selectedOrder && this.selectedOrder.products.length > 0) {
      return this.getOrderTotalPrice(this.selectedOrder);
    }

    return 0;
  }

  @computed
  get selectedOrderProductsCount() {
    if (this.selectedOrder) {
      return this.selectedOrder.products.length;
    }
    return 0;
  }

  getOrderTotalPrice = (order) => {
    let total = 0;
    if (order && order.products.length > 0) {
      order.products.forEach((product) => {
        const price = product.offerPrice ? product.offerPrice : product.price;
        total += price * product.quantity;
      });
    }
    return total;
  };

  getOrderStatusString = (order) => {
    const { localizedString } = this.i18nStore;
    switch (order.status) {
      case OrderStatus.ABANDONED:
        return localizedString({
          id: "Order@abandonedStatusLabel",
          defaultString: "Abandonado",
        });
      case OrderStatus.OPEN:
        return localizedString({
          id: "Order@openStatusLabel",
          defaultString: "Novo",
        });
      case OrderStatus.CANCELED:
        return localizedString({
          id: "Order@canceledStatusLabel",
          defaultString: "Cancelado",
        });
      case OrderStatus.WAITING_PAYMENT:
        return localizedString({
          id: "Order@waitingPaymentStatusLabel",
          defaultString: "Aguardando pagamento",
        });
      case OrderStatus.UNDER_ANALYSIS:
        return localizedString({
          id: "Order@underAnalysisStatusLabel",
          defaultString: "Em análise",
        });
      case OrderStatus.PAID:
      default:
        return localizedString({
          id: "Order@paidStatusLabel",
          defaultString: "Pago",
        });
    }
  };

  verifyIfSelectedOrderHasProduct = (productToken) => {
    if (this.selectedOrder && this.selectedOrder.products) {
      return (
        this.selectedOrder.products.filter(
          (product) => product.token === productToken
        ).length > 0
      );
    }
    return false;
  };

  createNewDefaultOrder = async () => {
    const { token, name, email } = this.authStore.currentUser;
    const contractHash = this.contractStore.selectedContract.hash;
    const snapshot = await contractsRef
      .child(`${contractHash}/commissionFeePercentage`)
      .once("value");
    const commissionFeePercentage = snapshot.val();

    const defaultData = {
      apiKey: this.contractStore.selectedContract.apiKey,
      contractHash,
      commissionFeePercentage,
      userName: name,
      userEmail: email,
      userToken: token,
      status: "open",
      products: [],
      timestamp: serverTimestamp,
    };

    return this.createNewOrder(defaultData);
  };

  createNewOrder = (data) => {
    return new Promise((resolve, reject) => {
      const newOrderRef = ordersRef.push(data);
      if (newOrderRef) {
        resolve(newOrderRef.key);
      } else {
        reject("failed creating new order");
      }
    });
  };

  addProductToSelectedOrder = (product) => {
    // if is adding products we can clear the redirect from session
    window.sessionStorage.removeItem("shouldRedirectToFinancial");
    return new Promise((resolve, reject) => {
      if (this.selectedOrder) {
        let newRef = ordersRef
          .child(`${this.selectedOrderId}/products/${product.token}`)
          .set({
            ...product,
            quantity: 1,
            token: null,
          });
        if (newRef) {
          resolve(newRef.key);
        } else {
          reject("Failed adding new product to order 2");
        }
      } else {
        reject("selectedOrder is null");
      }
    });
  };

  removeProductFromSelectedOrder = (product) => {
    if (!this.selectedOrder) return false;

    ordersRef
      .child(`${this.selectedOrderId}/products/${product.token}`)
      .remove();
  };

  increaseProductQuantity = (product) => {
    this.updateProductQuantity(product.token, product.quantity + 1);
  };

  decreaseProductQuantity = (product) => {
    this.updateProductQuantity(product.token, product.quantity - 1);
  };

  updateProductQuantity = (productToken, newQuantity) => {
    ordersRef
      .child(`${this.selectedOrderId}/products/${productToken}/quantity`)
      .set(newQuantity);
  };

  setSelectedOrderStatus = (newStatus, data) => {
    if (!this.selectedOrder) return false;

    ordersRef.child(this.selectedOrderId).update({
      status: newStatus,
      lastStatusDateTimestamp: serverTimestamp,
      ...data,
    });
  };

  getOrderToContract = () => {
    if (this.selectedOrderId === null) {
      ordersRef
        .orderByChild("contractHash")
        .equalTo(this.contractStore.selectedContract.hash)
        .once("value", (snapshot) => {
          var existsOpenOrder = false;

          snapshot.forEach((orderSnapshot) => {
            // Check if the status is open
            if (orderSnapshot.val().status === "open") {
              existsOpenOrder = true;
              this.selectedOrderId = orderSnapshot.key;
            }
          });

          // if no open order is found, let's create a new one
          if (!existsOpenOrder) {
            this.createNewDefaultOrder().then((orderId) => {
              this.selectedOrderId = orderId;
            });
          }
        });
    }
  };

  getOrderWithTransactionId = async (transactionId) => {
    try {
      const snapshot = await ordersRef
        .orderByChild("transactionId")
        .equalTo(transactionId)
        .once("value");
      if (snapshot.exists) {
        let order = null;
        snapshot.forEach((orderSnapshot) => {
          order = this.getOrderFromSnapshot(orderSnapshot);
        });
        return order;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  getOrderWithId = async (orderId) => {
    try {
      const snapshot = await ordersRef.child(orderId).once("value");
      if (snapshot.exists) {
        let order = null;
        snapshot.forEach((orderSnapshot) => {
          order = this.getOrderFromSnapshot(orderSnapshot);
        });
        return order;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  postSubmitCheckoutToAnalytics = (reference, productsList) => {
    const { selectedContract } = this.contractStore;

    let message = `**submitCheckout #${reference}**\n**Contrato:** ${selectedContract.name} (${selectedContract.hash})\n`;

    productsList.forEach((product) => {
      message += `- ${product.quantity}x ${product.name}\n`;
    });

    message += `\n**TOTAL:** R$ ${sumBy(productsList, "price")}`;

    this.telegramAPIService.postButterflyAnalyticsToThread(message);
  };

  submitCheckout = () => {
    return new Promise((resolve, reject) => {
      if (!this.selectedOrder) {
        reject();
        return false;
      }
      const productsList = this.selectedOrder.products.map((product) => {
        const price = product.offerPrice ? product.offerPrice : product.price;
        return {
          id: product.token,
          name: product.name,
          price,
          quantity: product.quantity,
        };
      });

      const checkoutParameters = this.pagSeguroAPIService.prepareCheckoutParameters(
        {
          productsList,
          reference: this.selectedOrder.id,
          senderEmail: this.selectedOrder.userEmail,
          senderName: this.selectedOrder.userName,
        }
      );
      this.pagSeguroAPIService
        .checkout(checkoutParameters)
        .then((result) => {
          const { paymentUrl } = result.data;
          this.setSelectedOrderStatus("waitingPayment", { paymentUrl });

          this.postSubmitCheckoutToAnalytics(
            this.selectedOrder.id,
            productsList
          );

          window.sessionStorage.setItem("shouldRedirectToFinancial", "true");
          resolve();
          window.location.href = paymentUrl;
        })
        .catch((error) => {
          window.debugError("error in submitCheckou", error);
          reject(error);
        });
    });
  };
}

export default OrdersStore;
