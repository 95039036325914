// @ts-nocheck

import classnames from "classnames";
import PropTypes from "prop-types";

const InputPassword = ({
  value,
  name,
  label,

  placeholder,
  isRequired,
  helpText,
  hasError,
  hasSuccess,
  onChange,
}) => (
  <div
    className={classnames({
      "form-group": true,
      "has-error": hasError,
      "has-success": hasSuccess,
    })}
  >
    <label
      htmlFor={name}
      className="control-label"
      style={{
        position: "relative",
      }}
    >
      {label}{" "}
      {isRequired && (
        <i
          className="fa fa-asterisk"
          style={{
            position: "absolute",
            top: 4,
            right: -12,
            fontSize: 8,
          }}
        />
      )}
    </label>
    <div className="input-group">
      <span className="input-group-addon">
        <i className="fa fa-fw fa-key" />
      </span>
      <input
        className="form-control"
        type="password"
        id={name}
        name={name}
        placeholder={placeholder}
        style={{ zIndex: 0 }}
        data-required={isRequired ? "true" : "false"}
        value={value}
        aria-describedby={`${name}-help-block`}
        onChange={onChange}
      />
    </div>
    {helpText && (
      <span id={`${name}-help-block`} className="help-block">
        <i className="fa fa-info-circle" /> {helpText}
      </span>
    )}
  </div>
);

InputPassword.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  hasError: PropTypes.bool,
  hasSuccess: PropTypes.bool,
  helpText: PropTypes.string,
};

export default InputPassword;
