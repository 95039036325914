import { FunctionComponent, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import { Button } from "../Button";
import LoadingView from "../LoadingView/LoadingView";

// Translate
import Translate from "../I18n/Translate";

// Style
const useStyles = makeStyles({
  container: {
    marginTop: 16,
    marginBottom: 20,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pageText: {
    margin: "0 20px",
  },
});

type ControlledPaginateProps = {
  itemsPerPage?: number;
  currentPage: number;
  itemsCount: number;
  isFetching?: boolean;
  onChange: (page: number) => void;
  rightComponent?: React.ReactNode;
};

interface ICustomButtonProps {
  isDisabled?: boolean;
  onClick: () => void;
}

const CustomButton: FunctionComponent<ICustomButtonProps> = ({
  children,
  isDisabled,
  onClick,
}) => (
  <Button
    small
    justIcon
    color="primary"
    outline={isDisabled}
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </Button>
);

const ControlledPaginate: FunctionComponent<ControlledPaginateProps> = ({
  itemsPerPage = 20,
  currentPage,
  itemsCount,
  children,
  rightComponent,
  isFetching,
  onChange,
}) => {
  const classes = useStyles();

  const isFirstPage = currentPage === 1;
  const totalPages = Math.ceil(itemsCount / itemsPerPage);
  const isLastPage = currentPage === totalPages;

  const changeCurrentPage = (page: number) => {
    onChange(page);
  };

  const goToPreviousPage = () => {
    if (!isFirstPage) changeCurrentPage(currentPage - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) changeCurrentPage(currentPage + 1);
  };
  const goToFirstPage = () => changeCurrentPage(1);
  const goToLastPage = () => changeCurrentPage(totalPages);

  return (
    <Fragment>
      {totalPages > 1 && (
        <div className={classes.container}>
          {totalPages > 2 && (
            <CustomButton isDisabled={isFirstPage} onClick={goToFirstPage}>
              <i className="fas fa-chevron-double-left" />
            </CustomButton>
          )}
          <CustomButton isDisabled={isFirstPage} onClick={goToPreviousPage}>
            <i className="fas fa-chevron-left" />
          </CustomButton>
          <p className={classes.pageText}>
            <Translate id="Table@pageText">Página</Translate> {currentPage}{" "}
            <Translate id="Table@ofText">de</Translate> {totalPages}
          </p>
          <CustomButton isDisabled={isLastPage} onClick={goToNextPage}>
            <i className="fas fa-chevron-right" />
          </CustomButton>
          {totalPages > 2 && (
            <CustomButton isDisabled={isLastPage} onClick={goToLastPage}>
              <i className="fas fa-chevron-double-right" />
            </CustomButton>
          )}
          {rightComponent}
        </div>
      )}
      {isFetching ? (
        <div style={{ width: "100%", marginTop: 20 }}>
          <LoadingView />
        </div>
      ) : (
        children
      )}
    </Fragment>
  );
};

ControlledPaginate.propTypes = {
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  isFetching: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ControlledPaginate;
