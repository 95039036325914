// @ts-nocheck
import { Component } from "react";
import { observer } from "mobx-react";

import { GridContainer } from "../../components/Grid";

import MetricChartSectionTitle from "../../components/Charts/MetricChartSectionTitle";
import MetricChartBody from "../../components/Charts/MetricChartBody";

import CameraAccessByGroupPerDaysChartWidget from "./CameraAccessByGroupPerDaysChartWidget";
import CameraAccessByGroupTotalChartWidget from "./CameraAccessByGroupTotalChartWidget";

// Translate
import Translate from "../../components/I18n/Translate";

@observer
class CameraAccessByGroupChartContainer extends Component {
  prepareChartData = (result) => {
    if (!result) return null;

    const accessByGroup = result.access_by_group;

    let highestValue = 0;
    let lowerValue = Number.MAX_SAFE_INTEGER;
    let wifiBusiestGroup = "";
    let wifiLessBusyGroup = "";

    Object.keys(accessByGroup).forEach((groupName) => {
      const groupTotal = accessByGroup[groupName].total;
      // Verify busiest group
      if (groupTotal > highestValue) {
        highestValue = groupTotal;
        wifiBusiestGroup = groupName;
      }
      // Verify less busy group
      if (groupTotal < lowerValue) {
        lowerValue = groupTotal;
        wifiLessBusyGroup = groupName;
      }
    });

    // Reset highestValue & lowerValue
    highestValue = 0;
    lowerValue = Number.MAX_SAFE_INTEGER;

    let wifiBusiestDay = "";
    let wifiLessBusyDay = "";

    const accessByGroupValues = Object.values(accessByGroup);

    // Find all day strings
    const perDaysKeys = Object.keys(accessByGroupValues[0].per_days);

    // Loop every day
    perDaysKeys.forEach((dayKeyToCompare) => {
      let countForDay = 0;

      // Loop groups to sum all days
      accessByGroupValues
        .map(($0) => $0.per_days)
        .forEach(($1) => {
          Object.keys($1).forEach((dayKey) => {
            if (dayKey === dayKeyToCompare) {
              countForDay += $1[dayKey];
            }
          });
        });

      // Verify busiest day
      if (countForDay > highestValue) {
        highestValue = countForDay;
        wifiBusiestDay = dayKeyToCompare;
      }
      // Verify less busy day
      if (countForDay > 0 && countForDay < lowerValue) {
        lowerValue = countForDay;
        wifiLessBusyDay = dayKeyToCompare;
      }
    });

    return {
      accessByGroup,
      wifiBusiestGroup,
      wifiLessBusyGroup,
      wifiBusiestDay,
      wifiLessBusyDay,
    };
  };

  render() {
    const {
      isFetching,
      error,
      chartData: result,
      groupsList,
      datePeriod,
    } = this.props;
    const chartData = this.prepareChartData(result);

    return (
      <GridContainer>
        <MetricChartSectionTitle
          groupsList={groupsList}
          datePeriod={datePeriod}
        >
          <Translate id="Connectivity@accessByGroupPerDayTitle">
            Acessos por dia
          </Translate>
        </MetricChartSectionTitle>
        <MetricChartBody isFetching={isFetching} hasError={error}>
          <CameraAccessByGroupPerDaysChartWidget chartData={chartData} />
        </MetricChartBody>
        {chartData && Object.keys(chartData).length > 0 && (
          <>
            <MetricChartSectionTitle
              groupsList={groupsList}
              datePeriod={datePeriod}
              showDivider
            >
              <Translate id="Connectivity@accessByGroupTotalTitle">
                Total de acessos no período
              </Translate>
            </MetricChartSectionTitle>
            <MetricChartBody isFetching={isFetching} hasError={error}>
              <CameraAccessByGroupTotalChartWidget chartData={chartData} />
            </MetricChartBody>
          </>
        )}
      </GridContainer>
    );
  }
}

export default CameraAccessByGroupChartContainer;
