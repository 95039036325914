// @ts-nocheck

import { inject, observer } from "mobx-react";
import classnames from "classnames";
import Datetime from "react-datetime";

import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

//Style
const styles = {
  container: {
    zIndex: 100000000,
  },
  containerWithoutMargin: {
    marginBottom: 20,
  },
  label: {
    display: "block",
    marginBottom: -10,
    marginTop: 16,
    color: "#AAAAAA !important",
    fontWeight: "500",
    textTransform: "uppercase",
    fontSize: 12,
    lineHeight: "1.42857",
  },
};

const DatePicker = inject("i18nStore")(
  observer(
    ({
      classes,
      i18nStore,
      label,
      name,
      value,
      hideDate,
      hideTime,
      timeFormat,
      fullWidth,
      removeMargin,
      onChange,
      isValidDate,
      alwaysOpenWithoutInput,
    }) => (
      <div
        className={classnames({
          [classes.container]: true,
          [classes.containerWithoutMargin]: !removeMargin,
        })}
      >
        <InputLabel className={classes.label}>{label}</InputLabel>
        <FormControl fullWidth={fullWidth} style={{ position: "initial" }}>
          <Datetime
            locale={i18nStore.currentLanguage}
            dateFormat={
              hideDate ? false : i18nStore.dateFormatWithFourDigitsYear
            }
            timeFormat={
              timeFormat ? timeFormat : hideTime ? false : i18nStore.timeFormat
            }
            value={value}
            isValidDate={isValidDate}
            input={!alwaysOpenWithoutInput}
            open={alwaysOpenWithoutInput}
            onChange={(newValue) => {
              if (newValue && typeof newValue === "object") {
                onChange(name, newValue);
              }
            }}
          />
        </FormControl>
      </div>
    )
  )
);

export default withStyles(styles)(DatePicker);
