// @ts-nocheck
import { Component } from "react";

import { withLocalized } from "../../components/HOC";
import { GridContainer, GridItem } from "../../components/Grid";
import { ListItem, ListItemText } from "@material-ui/core";

import Translate from "../../components/I18n/Translate";

import { brazilStateNameForCode } from "../../utils/ContractBusinessUtils";

@withLocalized
class BusinessAddressForm extends Component {
  renderContractItem = ({ value, description }) => (
    <ListItem>
      <ListItemText primary={value} secondary={description} />
    </ListItem>
  );

  render() {
    const {
      addressName,
      addressNumber,
      addressZipCode,
      addressNeighborhood,
      addressCity,
      addressState,
      addressCountry,
    } = this.props;

    return (
      <GridContainer alignItems="center">
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} lg={4}>
            {this.renderContractItem({
              value: (
                <Translate id="Contract@businessAddressName">
                  Logradouro
                </Translate>
              ),
              description: addressName,
            })}
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={4}>
            {this.renderContractItem({
              value: (
                <Translate id="Contract@businessAddressNumber">
                  Número
                </Translate>
              ),
              description: addressNumber,
            })}
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={4}>
            {this.renderContractItem({
              value: (
                <Translate id="Contract@businessAddressNeighborhood">
                  Bairro
                </Translate>
              ),
              description: addressNeighborhood,
            })}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} lg={4}>
            {this.renderContractItem({
              value: (
                <Translate id="Contract@businessAddressCity">Cidade</Translate>
              ),
              description: addressCity,
            })}
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={4}>
            {this.renderContractItem({
              value: (
                <Translate id="Contract@businessAddressState">Estado</Translate>
              ),
              description: brazilStateNameForCode(addressState),
            })}
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={4}>
            {this.renderContractItem({
              value: (
                <Translate id="Contract@businessAddressCountry">País</Translate>
              ),
              description: addressCountry,
            })}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} lg={4}>
            {this.renderContractItem({
              value: (
                <Translate id="Contract@businessAddressZipCode">CEP</Translate>
              ),
              description: addressZipCode,
            })}
          </GridItem>
        </GridContainer>
      </GridContainer>
    );
  }
}

export default BusinessAddressForm;
