// @ts-nocheck
import { Component, Fragment } from "react";

// Components
import { withLocalized } from "../../HOC";
import { ButtonGroup, InputText, InputSelect } from "../../Form";

import { GridContainer, GridItem } from "../../Grid";
import Validate, { ValidationType } from "../../Form/Validate";
import { Button } from "../../Button";

// Translate
import Translate from "../../I18n/Translate";

// Styles
import { dangerColor } from "../../../assets/jss/app-js-styles";

const validateIpAddress = (value) => Validate(value, ValidationType.IP_ADDRESS);

@withLocalized
class InterfaceNetworkForm extends Component {
  constructor(props) {
    super(props);
    const { network } = props;
    this.state = {
      label: network.label || "",
      dhcp: network.inet.dhcp || "yes",
      addr: network.inet.addr || "",
      netmask: network.inet.netmask || "",
      gateway: network.inet.gateway || "",
      nameservers: network.inet.nameservers || [],
      dhcp_server: network.inet.dhcp_server,
      dhcp_beginning: network.inet.dhcp_beginning,
      dhcp_ending: network.inet.dhcp_ending,
      dhcp_lease: network.inet.dhcp_lease || "1h",
      static_addresses: network.inet.static_addresses || [],
    };
  }

  componentDidUpdate(prevProps) {
    const leftProp = JSON.stringify(this.props.network);
    const rightProp = JSON.stringify(prevProps.network);
    if (leftProp !== rightProp) {
      this.readNetwork(this.props.network);
    }
  }

  componentDidMount() {
    this.readNetwork(this.props.network);
  }

  readNetwork = (network) => {
    this.setState({
      label: network.label,
      dhcp: network.inet.dhcp || "yes",
      addr: network.inet.addr || "",
      netmask: network.inet.netmask || "",
      gateway: network.inet.gateway || "",
      nameservers: network.inet.nameservers || [],
      dhcp_server: network.inet.dhcp_server,
      dhcp_beginning: network.inet.dhcp_beginning,
      dhcp_ending: network.inet.dhcp_ending,
      dhcp_lease: network.inet.dhcp_lease,
      static_addresses: network.inet.static_addresses,
    });
  };

  didChange = () => {
    const { interfaceIndex, networkIndex, onChange } = this.props;
    const {
      label,
      dhcp,
      addr,
      netmask,
      gateway,
      nameservers,
      dhcp_server,
      dhcp_beginning,
      dhcp_ending,
      dhcp_lease,
      static_addresses,
    } = this.state;
    onChange(interfaceIndex, networkIndex, {
      label,
      inet: {
        dhcp,
        addr,
        netmask,
        gateway,
        nameservers,
        dhcp_server,
        dhcp_beginning,
        dhcp_ending,
        dhcp_lease,
        static_addresses,
      },
    });
  };

  setLabel = (label) => {
    this.setState({ label }, this.didChange);
  };
  setDHCP = (name, value) => {
    this.setState({ [name]: value }, this.didChange);
  };
  setAddr = (addr) => {
    this.setState({ addr }, this.didChange);
  };
  setNetmask = (netmask) => {
    this.setState({ netmask }, this.didChange);
  };
  setGateway = (gateway) => {
    this.setState({ gateway }, this.didChange);
  };

  onChangeDNS = (dnsIndex) => ({ target }) => {
    this.setState(
      (prevState) => ({
        nameservers: prevState.nameservers.map(($0, i) =>
          i === dnsIndex ? target.value : $0
        ),
      }),
      this.didChange
    );
  };

  onChangeDHCPServer = ({ target }) => {
    const { name: key, value } = target;
    this.setState((prevState) => {
      const dhcp_beginning =
        key === "dhcp_beginning" ? value : prevState.dhcp_beginning;
      const dhcp_ending = key === "dhcp_ending" ? value : prevState.dhcp_ending;
      return {
        dhcp_beginning,
        dhcp_ending,
      };
    }, this.didChange);
  };
  onChangeDHCPLease = (_, { value }) => {
    this.setState(
      {
        dhcp_lease: value,
      },
      this.didChange
    );
  };

  addDNS = () => {
    this.setState(
      (prevState) => ({
        nameservers: [...prevState.nameservers, ""],
      }),
      this.didChange
    );
  };

  removeDNS = (dnsIndex) => () => {
    this.setState(
      (prevState) => ({
        nameservers: prevState.nameservers.filter((_, i) => i !== dnsIndex),
      }),
      this.didChange
    );
  };

  setStaticAddress = (index) => ({ target }) => {
    const { name, value } = target;
    this.setState((prevState) => {
      const changedItem = Object.entries(prevState.static_addresses[index])[0];
      const key = name === "mac_address" ? value : changedItem[0];
      const newValue = name === "ip_address" ? value : changedItem[1];
      return {
        static_addresses: prevState.static_addresses.map(($0, i) =>
          i === index
            ? {
                [key]: newValue,
              }
            : $0
        ),
      };
    }, this.didChange);
  };

  addStaticAddress = () => {
    this.setState(
      (prevState) => ({
        static_addresses: [...prevState.static_addresses, { "": "" }],
      }),
      this.didChange
    );
  };

  removeStaticAddress = (index) => () => {
    this.setState(
      (prevState) => ({
        static_addresses: prevState.static_addresses.filter(
          (_, i) => i !== index
        ),
      }),
      this.didChange
    );
  };

  render() {
    const { localizedString, isWan, formWasSubmit } = this.props;
    const {
      label,
      dhcp,
      addr,
      netmask,
      gateway,
      nameservers,
      dhcp_server,
      dhcp_beginning,
      dhcp_ending,
      dhcp_lease,
      static_addresses,
    } = this.state;

    const isManualDHCP = dhcp === "no";
    const isDHCPServer = dhcp_server === "yes";
    const hasErrorIp = formWasSubmit && !validateIpAddress(addr);
    const hasErrorNetmask =
      formWasSubmit && (netmask.length < 1 || !validateIpAddress(netmask));
    const hasErrorGateway = isWan
      ? formWasSubmit && (gateway.length < 1 || !validateIpAddress(gateway))
      : false;

    return (
      <GridContainer>
        {isWan && (
          <GridItem xs={12} style={{ marginTop: 20 }}>
            <p style={{ fontWeight: "bold" }}>
              <Translate id="Device@modWifiWanInterfaceExplanation">
                Esta interface de rede deve ser configurada e conectada como
                cliente do seu serviço de Internet (WAN).
              </Translate>
            </p>
          </GridItem>
        )}
        <GridItem xs={12}>
          <InputText
            label={
              <Translate id="Device@modWifiLabel">Nome do serviço</Translate>
            }
            name="label"
            value={label || ""}
            onChange={({ target }) => this.setLabel(target.value)}
          />
        </GridItem>
        {isWan && (
          <GridItem xs={12}>
            <ButtonGroup
              small
              color="success"
              label="DHCP"
              name="dhcp"
              value={dhcp}
              onChange={this.setDHCP}
              options={[
                {
                  value: "yes",
                  label: localizedString({
                    id: "Common@auto",
                    defaultString: "Automático",
                  }),
                },
                {
                  value: "no",
                  label: localizedString({
                    id: "Common@manual",
                    defaultString: "Manual",
                  }),
                },
              ]}
            />
          </GridItem>
        )}
        {isManualDHCP && (
          <Fragment>
            <GridItem xs={12}>
              <InputText
                label={
                  <Translate id="Device@ipAddressLabel">Endereço IP</Translate>
                }
                name="addr"
                value={addr}
                onChange={({ target }) => this.setAddr(target.value)}
                hasError={hasErrorIp}
                hasSuccess={formWasSubmit && validateIpAddress(addr)}
                helpText={
                  hasErrorIp && (
                    <Translate id="Device@ipAddressErrorMessage">
                      Informe um endereço IP válido contendo números e pontos.
                      Exemplo: 192.168.0.2
                    </Translate>
                  )
                }
              />
            </GridItem>
            <GridItem xs={12}>
              <InputText
                label={<Translate id="Device@subnetLabel">Subnet</Translate>}
                name="netmask"
                value={netmask}
                onChange={({ target }) => this.setNetmask(target.value)}
                hasError={hasErrorNetmask}
                hasSuccess={formWasSubmit && validateIpAddress(netmask)}
                helpText={
                  hasErrorNetmask && (
                    <Translate id="Device@subnetErrorMessage">
                      Informe um endereço de máscara válido contendo números e
                      pontos. Exemplo: 255.255.255.0
                    </Translate>
                  )
                }
              />
            </GridItem>
            {isWan && (
              <GridItem xs={12}>
                <InputText
                  label={
                    <Translate id="Device@routerLabel">Roteador</Translate>
                  }
                  name="gateway"
                  value={gateway}
                  onChange={({ target }) => this.setGateway(target.value)}
                  hasError={hasErrorGateway}
                  hasSuccess={formWasSubmit && validateIpAddress(gateway)}
                  helpText={
                    hasErrorGateway && (
                      <Translate id="Device@routerErrorMessage">
                        Informe um endereço do roteador válido contendo números
                        e pontos. Exemplo: 192.168.0.1
                      </Translate>
                    )
                  }
                />
              </GridItem>
            )}
          </Fragment>
        )}
        {isWan && (
          <GridItem xs={12}>
            {nameservers.map((dns, dnsIndex) => {
              const isValid = dns.length < 1 || validateIpAddress(dns);
              const hasError = isWan ? formWasSubmit && !isValid : false;
              return (
                <InputText
                  key={dnsIndex}
                  label={`DNS${dnsIndex + 1}`}
                  name="dns"
                  value={dns}
                  onChange={this.onChangeDNS(dnsIndex)}
                  hasError={hasError}
                  hasSuccess={isWan ? formWasSubmit && isValid : false}
                  helpText={
                    hasError && (
                      <Translate id="Device@dnsErrorMessage">
                        Informe um endereço DNS válido contendo números e
                        pontos. Exemplo: 8.8.8.8
                      </Translate>
                    )
                  }
                  rightIcon={
                    <Button
                      color="transparent"
                      justIcon
                      small
                      onClick={this.removeDNS(dnsIndex)}
                    >
                      <i
                        className="fas fa-trash-alt"
                        style={{ color: dangerColor }}
                      />
                    </Button>
                  }
                />
              );
            })}
            {nameservers.length <= 10 && (
              <Button color="primary" outline block small onClick={this.addDNS}>
                <Translate id="Device@modWifiAddDnsButtonLabel">
                  Adicionar DNS
                </Translate>{" "}
                <i className="fas fa-plus" />
              </Button>
            )}
          </GridItem>
        )}
        {!isWan && dhcp_server && (
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={12} md={3}>
                <ButtonGroup
                  small
                  color="success"
                  label="DHCP Server"
                  name="dhcp_server"
                  value={dhcp_server}
                  onChange={this.setDHCP}
                  options={[
                    {
                      value: "yes",
                      label: localizedString({
                        id: "Common@isActive",
                        defaultString: "Habilitado",
                      }),
                    },
                    {
                      value: "no",
                      label: localizedString({
                        id: "Common@isInactive",
                        defaultString: "Inativo",
                      }),
                    },
                  ]}
                />
              </GridItem>
              {isDHCPServer && (
                <Fragment>
                  <GridItem xs={12} md={3}>
                    <InputText
                      label={<Translate id="Common@start">Início</Translate>}
                      name="dhcp_beginning"
                      isRequired
                      hasError={
                        formWasSubmit && !validateIpAddress(dhcp_beginning)
                      }
                      hasSuccess={
                        formWasSubmit && validateIpAddress(dhcp_beginning)
                      }
                      value={dhcp_beginning}
                      onChange={this.onChangeDHCPServer}
                    />
                  </GridItem>
                  <GridItem xs={12} md={3}>
                    <InputText
                      label={<Translate id="Common@end">Fim</Translate>}
                      name="dhcp_ending"
                      isRequired
                      hasError={
                        formWasSubmit && !validateIpAddress(dhcp_ending)
                      }
                      hasSuccess={
                        formWasSubmit && validateIpAddress(dhcp_ending)
                      }
                      value={dhcp_ending}
                      onChange={this.onChangeDHCPServer}
                    />
                  </GridItem>

                  <GridItem xs={12} md={3}>
                    <InputSelect
                      label={
                        <Translate id="Connectivity@dhcpLeaseLabel">
                          Período de concessão (Lease time)
                        </Translate>
                      }
                      withPortal
                      name="dhcp_lease"
                      value={{ value: dhcp_lease, label: dhcp_lease }}
                      options={[
                        { value: "5m", label: "5m" },
                        { value: "15m", label: "15m" },
                        { value: "30m", label: "30m" },
                        { value: "1h", label: "1h" },
                        { value: "6h", label: "6h" },
                        { value: "12h", label: "12h" },
                        { value: "48h", label: "48h" },
                        { value: "72h", label: "72h" },
                      ]}
                      onChange={this.onChangeDHCPLease}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    {static_addresses.map((item, index) => {
                      const [mac_address, ip_address] = Object.entries(item)[0];

                      return (
                        <GridContainer key={index} alignItems="center">
                          <GridItem xs={5}>
                            <InputText
                              label={`Mac address ${index + 1}`}
                              name="mac_address"
                              value={mac_address}
                              onChange={this.setStaticAddress(index)}
                            />
                          </GridItem>
                          <GridItem xs={5}>
                            <InputText
                              label={
                                <span>
                                  <Translate id="Device@ipAddressLabel">
                                    Endereço IP
                                  </Translate>{" "}
                                  {index + 1}
                                </span>
                              }
                              name="ip_address"
                              value={ip_address}
                              onChange={this.setStaticAddress(index)}
                            />
                          </GridItem>
                          <GridItem xs>
                            <Button
                              color="transparent"
                              justIcon
                              small
                              onClick={this.removeStaticAddress(index)}
                            >
                              <i
                                className="fas fa-trash-alt"
                                style={{ color: dangerColor }}
                              />
                            </Button>
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                    <Button
                      color="primary"
                      outline
                      block
                      small
                      onClick={this.addStaticAddress}
                    >
                      <Translate id="Device@modWifiAddStaticAddressButtonLabel">
                        Adicionar endereço estático
                      </Translate>{" "}
                      <i className="fas fa-plus" />
                    </Button>
                  </GridItem>
                </Fragment>
              )}
            </GridContainer>
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

export default InterfaceNetworkForm;
