import { Fragment, useState, useEffect, FC } from "react";
import { makeStyles } from "@material-ui/styles";
import { InputLabel } from "@material-ui/core";
import { inject, observer } from "mobx-react";

import DevicesListPageContainer from "./DevicesListPageContainer";
import { List, ListItem } from "../List";
import Modal from "../Modal/Modal";
import { Button } from "../Button";

import Translate from "../I18n/Translate";
import Plural from "../I18n/Plural";

import TerminalModel from "../../Model/TerminalModel";
import TerminalsStore from "../../store/TerminalsStore/TerminalsStore";
import LoadingView from "../LoadingView/LoadingView";

// Style
const useStyles = makeStyles({
  label: {
    display: "block",
    color: "#AAAAAA !important",
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: 16,
    lineHeight: "1.42857",
  },
});

interface DeviceSelectorInputProps {
  name: string;
  label?: string | JSX.Element;
  terminalsStore?: TerminalsStore;
  ignoredTokens?: string[];
  selectedTokens: string[];
  isMultiple?: boolean;
  didSelect: (name: string, tokens: string[]) => void;
}

const DeviceSelectorInput: FC<DeviceSelectorInputProps> = ({
  name,
  label,
  terminalsStore,
  ignoredTokens,
  selectedTokens,
  isMultiple = false,
  didSelect,
}) => {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFetchingDevices, setIsFetchingDevices] = useState(false);
  const [devicesList, setDevicesList] = useState([]);
  const [selectedTokensOnModal, setSelectedTokensOnModal] = useState<string[]>(
    []
  );

  useEffect(() => {
    const fetchDevices = async () => {
      setIsFetchingDevices(true);

      let list: TerminalModel[] = [];
      let tokensToSearch = [...selectedTokens];

      selectedTokens.forEach((token) => {
        const device = devicesList.find(($0) => $0.token === token);
        if (device) {
          list.push(device);
          tokensToSearch = tokensToSearch.filter(($0) => $0 !== token);
        }
      });

      try {
        const result = await terminalsStore.requestListOfTerminals(
          tokensToSearch
        );
        list = [...list, ...result];
      } finally {
        setIsFetchingDevices(false);
        setDevicesList(list);
      }
    };

    if (
      selectedTokens.filter(($0) => $0.length > 0).length > 0 &&
      JSON.stringify(devicesList.map(({ token }) => token)) !==
        JSON.stringify(selectedTokens)
    ) {
      fetchDevices();
      setSelectedTokensOnModal(selectedTokens);
    } else if (selectedTokens.length < 1) {
      setDevicesList([]);
      setSelectedTokensOnModal([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTokens]);

  const didOpenModal = () => {
    setIsOpenModal(true);
  };
  const didCloseModal = () => {
    setIsOpenModal(false);
    setSelectedTokensOnModal(selectedTokens);
  };

  const didSaveModal = () => {
    setIsOpenModal(false);
    didSelect(name, selectedTokensOnModal);
  };

  const didRemove = (token: string) => () => {
    didSelect(
      name,
      selectedTokensOnModal.filter(($0) => $0 !== token)
    );
  };

  const didSelectDevice = (device: TerminalModel) => {
    let newList: string[] = [];
    if (!isMultiple) {
      newList = [device.token];
    } else if (selectedTokensOnModal.includes(device.token)) {
      newList = selectedTokensOnModal.filter((token) => token !== device.token);
    } else {
      newList = [...selectedTokensOnModal, device.token];
    }
    setSelectedTokensOnModal(newList);
  };
  // @ts-ignore
  return (
    <Fragment>
      <Modal
        isOpen={isOpenModal}
        didCloseModal={didCloseModal}
        fullScreen
        modalTitle={
          <Plural
            // @ts-ignore
            id="Devices@selectedDevicesQtyLabel"
            value={selectedTokensOnModal.length}
            zero="Selecione..."
            one="Um dispositivo selecionado"
            other="# dispositivos selecionados"
          />
        }
        actions={
          <Fragment>
            <Button small color="primary" outline onClick={didCloseModal}>
              <Translate id="Common@cancel">Cancelar</Translate>
            </Button>
            <Button small color="primary" onClick={didSaveModal}>
              <Translate id="Common@save">Salvar</Translate>{" "}
              <i className="fas fa-save" />
            </Button>
          </Fragment>
        }
      >
        <DevicesListPageContainer
          isSelectorView
          ignoredTokens={ignoredTokens}
          selectedTokens={selectedTokensOnModal}
          didSelectDevice={didSelectDevice}
        />
      </Modal>
      {label && (
        <InputLabel className={classes.label} htmlFor={name} shrink={true}>
          {label}
        </InputLabel>
      )}
      {isFetchingDevices ? (
        <div style={{ width: "fit-content" }}>
          <LoadingView height={40} />
        </div>
      ) : (
        <Fragment>
          <List style={{ padding: 0 }}>
            {devicesList.map(({ token, name, group }) => (
              //@ts-ignore
              <ListItem
                key={token}
                title={name}
                description={group?.name}
                divider
                dense
                leading={
                  <Button
                    color="danger"
                    small
                    justIcon
                    onClick={didRemove(token)}
                    // @ts-ignore
                    style={{ marginRight: 8 }}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                }
                style={{ padding: 0 }}
              />
            ))}
          </List>
          {(isMultiple || devicesList.length < 1) && (
            <Button color="primary" small outline block onClick={didOpenModal}>
              <Translate id="Common@selectPlaceholder">Selecione...</Translate>{" "}
              <i className="fas fa-plus" />
            </Button>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default inject("terminalsStore")(observer(DeviceSelectorInput));
