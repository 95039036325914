// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";

import { Card, CardHeader, CardIcon, CardBody } from "../../components/Card";
import { GridContainer, GridItem } from "../../components/Grid";
import Tooltip from "../../components/Tooltip/Tooltip";
import { formatPriceToText } from "../../components/Price/Price";
import { Button } from "../../components/Button";
import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";
import { CircularProgress } from "@material-ui/core";

// Translate
import Translate from "../../components/I18n/Translate";

// Utils Stores
import { OrderStatus } from "../../store/OrdersStore/OrdersStore";

// Utils
import { stringDateFromTimestamp } from "../../utils/DateUtils";

// Styles
import { cardTitle, primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    fontSize: "1.6em",
    marginTop: 16,
    marginLeft: 16,
    marginBottom: 0,
  },
  cardIcon: {
    fontSize: 22,
  },
  statsTitle: {
    color: primaryColor,
    fontWeight: 500,
    fontSize: "1.2em",
    margin: 0,
  },
  statsDescription: {
    fontWeight: 300,
    margin: 0,
  },
  modalStats: {
    border: "1px solid #D2D1D2",
    background: "#F4F6F8",
    borderRadius: 6,
    padding: 20,
    textAlign: "center",
    margin: "2% 10%",
  },
};

@inject("i18nStore", "ordersStore")
@observer
class OrdersListContainer extends Component {
  state = {
    modalIsOpen: false,
    selectedOrderId: null,
    isRedirecting: false,
  };

  renderDate = (timestamp) => {
    const { i18nStore } = this.props;
    return stringDateFromTimestamp(
      timestamp,
      `${i18nStore.dateFormatWithFourDigitsYear} ${i18nStore.timeFormat}`
    );
  };

  renderOrderStatus = (order) => {
    return `${this.props.ordersStore.getOrderStatusString(
      order
    )} - ${this.renderDate(order.lastStatusDateTimestamp)}`;
  };

  didSelectOrder = (orderId) => {
    this.setState({
      modalIsOpen: true,
      selectedOrderId: orderId,
    });
  };

  didCloseModal = () => {
    this.setState(
      {
        modalIsOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            selectedOrderId: null,
          });
        }, 200);
      }
    );
  };

  setRedirecting = () => {
    this.setState({
      isRedirecting: true,
    });
  };

  renderModalGridStats = ({ title, description }) => {
    const { classes } = this.props;
    return (
      <GridItem xs={12} sm={4}>
        <div className={classes.modalStats}>
          <p className={classes.statsTitle}>{title}</p>
          <p className={classes.statsDescription}>{description}</p>
        </div>
      </GridItem>
    );
  };

  render() {
    const { classes, i18nStore, ordersStore } = this.props;
    const { selectedOrderId } = this.state;
    const { localizedString } = i18nStore;

    const ordersList = ordersStore.notOpenOrAbandonedOrders;

    const selectedOrder = selectedOrderId
      ? ordersList.filter(($0) => $0.id === selectedOrderId)[0]
      : null;

    return (
      <Fragment>
        {selectedOrder && (
          <Modal
            isOpen={this.state.modalIsOpen}
            didCloseModal={this.didCloseModal}
            modalTitle={
              this.state.isRedirecting ? null : (
                <Translate id="Order@orderDetailsModalTitle">
                  Detalhes do pedido
                </Translate>
              )
            }
            hideCloseButton={this.state.isRedirecting}
            maxWidth={this.state.isRedirecting ? "sm" : "lg"}
          >
            {this.state.isRedirecting ? (
              <GridContainer
                justify="center"
                style={{ marginTop: 80, marginBottom: 80 }}
              >
                <GridItem xs style={{ textAlign: "center" }}>
                  <p>
                    <Translate id="Checkout@redirectingMessage">
                      Aguarde, redirecionando para a plataforma de pagamento...
                    </Translate>
                  </p>
                  <CircularProgress size={30} variant="indeterminate" />
                </GridItem>
              </GridContainer>
            ) : (
              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    data={selectedOrder.products.map((product) => {
                      return {
                        ...product,
                        qty: `${product.quantity}x`,
                        name: (
                          <Tooltip
                            placement="top-start"
                            title={product.description}
                          >
                            <p
                              style={{
                                margin: 0,
                              }}
                            >
                              {product.name}{" "}
                              <i className="fas fa-info-circle" />
                            </p>
                          </Tooltip>
                        ),
                        price: (
                          <p
                            style={{
                              margin: 0,
                            }}
                          >
                            {formatPriceToText(
                              product.offerPrice
                                ? product.offerPrice
                                : product.price,
                              true
                            )}
                          </p>
                        ),
                        dayToExpire:
                          product.daysToExpire > 0 &&
                          selectedOrder.status === OrderStatus.PAID
                            ? moment(selectedOrder.lastStatusDateTimestamp)
                                .add(product.daysToExpire, "days")
                                .format(i18nStore.dateFormatWithFourDigitsYear)
                            : "-",
                      };
                    })}
                    columns={[
                      {
                        Header: "#",
                        sortable: false,
                        accessor: "qty",
                        width: 40,
                        resizable: false,
                      },
                      {
                        Header: localizedString({
                          id: "Common@name",
                          defaultString: "Nome",
                        }),
                        sortable: false,
                        resizable: false,
                        accessor: "name",
                      },
                      {
                        Header: localizedString({
                          id: "Order@expiresInLabel",
                          defaultString: "Expira em",
                        }),
                        sortable: false,
                        resizable: false,
                        width: 100,
                        accessor: "dayToExpire",
                      },
                      {
                        Header: localizedString({
                          id: "Order@totalPriceLabel",
                          defaultString: "Total",
                        }),
                        sortable: false,
                        resizable: false,
                        accessor: "price",
                        width: 160,
                        getHeaderProps: () => ({
                          style: {
                            textAlign: "right",
                            marginRight: 16,
                          },
                        }),
                        getProps: () => ({
                          style: {
                            textAlign: "right",
                            marginRight: 16,
                          },
                        }),
                      },
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30, marginBottom: 20 }}>
                  <GridContainer justify="center">
                    {this.renderModalGridStats({
                      title: this.renderDate(selectedOrder.timestamp),
                      description: <Translate id="Common@date">Data</Translate>,
                    })}
                    {this.renderModalGridStats({
                      title: this.renderOrderStatus(selectedOrder),
                      description: (
                        <Translate id="Order@orderDetailStatusTitle">
                          Status
                        </Translate>
                      ),
                    })}
                    {this.renderModalGridStats({
                      title: formatPriceToText(
                        ordersStore.getOrderTotalPrice(selectedOrder),
                        true
                      ),
                      description: (
                        <Translate id="Order@totalPriceLabel">Total</Translate>
                      ),
                    })}
                  </GridContainer>
                </GridItem>
                {selectedOrder.status === OrderStatus.WAITING_PAYMENT && (
                  <Button
                    color="warning"
                    block
                    href={selectedOrder.paymentUrl}
                    onClick={this.setRedirecting}
                  >
                    <Translate id="Order@paymentUrlButtonLabel">
                      Efetuar pagamento
                    </Translate>{" "}
                    <i className="fas fa-credit-card" />
                  </Button>
                )}
              </GridContainer>
            )}
          </Modal>
        )}
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <i className={`fas fa-clipboard-list ${classes.cardIcon}`} />
            </CardIcon>
            <h2 className={classes.cardIconTitle}>
              <Translate id="Order@ordersListTitle">Seus pedidos</Translate>
            </h2>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            {ordersList.length > 0 && (
              <Table
                data={ordersList.map((order) => {
                  return {
                    ...order,
                    date: this.renderDate(order.timestamp),
                    totalPrice: (
                      <p
                        style={{
                          margin: 0,
                        }}
                      >
                        {formatPriceToText(
                          ordersStore.getOrderTotalPrice(order),
                          true
                        )}
                      </p>
                    ),
                    status: (
                      <Fragment>
                        <p style={{ margin: 0 }}>
                          {this.renderOrderStatus(order)}
                          {order.status === OrderStatus.WAITING_PAYMENT && (
                            <Button
                              color="warning"
                              small
                              href={order.paymentUrl}
                              style={{ marginLeft: 16 }}
                              onClick={this.setRedirecting}
                            >
                              <Translate id="Order@paymentUrlButtonLabel">
                                Efetuar pagamento
                              </Translate>{" "}
                              <i className="fas fa-credit-card" />
                            </Button>
                          )}
                        </p>
                      </Fragment>
                    ),
                    actions: (
                      <Tooltip
                        title={
                          <Translate id="Order@infoButtonLabel">
                            Detalhar pedido
                          </Translate>
                        }
                      >
                        <Button justIcon small>
                          <i className="fas fa-info" />
                        </Button>
                      </Tooltip>
                    ),
                  };
                })}
                columns={[
                  {
                    Header: localizedString({
                      id: "Common@date",
                      defaultString: "Data",
                    }),
                    sortable: false,
                    accessor: "date",
                    width: 150,
                    resizable: false,
                  },
                  {
                    Header: localizedString({
                      id: "Order@totalPriceLabel",
                      defaultString: "Total",
                    }),
                    sortable: false,
                    resizable: false,
                    accessor: "totalPrice",
                    width: 100,
                    getHeaderProps: () => ({
                      style: {
                        textAlign: "right",
                        marginRight: 16,
                      },
                    }),
                    getProps: () => ({
                      style: {
                        textAlign: "right",
                        marginRight: 16,
                      },
                    }),
                  },
                  {
                    Header: "Status",
                    sortable: false,
                    resizable: false,
                    accessor: "status",
                  },
                  {
                    Header: "",
                    sortable: false,
                    accessor: "actions",
                    width: 80,
                    style: { textAlign: "right" },
                  },
                ]}
                getTrProps={(_, rowInfo) => ({
                  style: {
                    cursor: rowInfo && rowInfo.original ? "pointer" : "auto",
                  },
                })}
                getTdProps={(_, rowInfo) => ({
                  onClick: () => {
                    if (rowInfo && rowInfo.original) {
                      this.didSelectOrder(rowInfo.original.id);
                    }
                  },
                })}
              />
            )}
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrdersListContainer);
