// @ts-nocheck
import axios from "axios";

const BASE_URL = "https://viacep.com.br/ws/";

class ViacepAPIService {
  requestCEP = (cep) => {
    const publicApi = axios.create({
      baseURL: BASE_URL,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return new Promise((resolve, reject) => {
      publicApi
        .get(`${cep}/json/`)
        .then((response) => resolve(response))
        .catch((error) => {
          window.debugError("ViacepAPIService error", error);
          reject(error);
        });
    });
  };
}

export default ViacepAPIService;
