import { observable, action, computed, reaction } from "mobx";
import { secondsToMinutesAndHours } from "../utils/DateUtils";

export enum QueueManagementControlType {
  constant = 1,
  oneVariable = 2,
  twoVariables = 3,
  sequential = 4,
}

export enum EQueueManagementLayout {
  center = "center",
  fullscreen = "fullscreen",
  left = "left",
  right = "right",
  top = "top",
  topLeft = "top_left",
  topRight = "top_right",
  centerLeft = "center_left",
  centerRight = "center_right",
  bottom = "bottom",
  bottomLeft = "bottom_left",
  bottomRight = "bottom_right",
  top_center = "top_center",
  bottomCenter = "bottom_center",
}

class QueueManagementControlModel {
  token: string;
  name: string;
  value: string;
  type: QueueManagementControlType;
  terminalToken?: string;
  layout?: EQueueManagementLayout;
  useVoice: boolean;
  alpha?: string;
  displayTime?: number;
  backgroundColor?: string;
  fontColor?: string;
  @observable
  connectionCode?: number;
  @observable
  connectionExpiresDate?: number;
  @observable
  hasRemoteControl: boolean;
  isVertical: boolean;
  useInputStream: boolean;
  inputStreamPosition?: string;
  @observable
  nowTimestamp: number;

  nowTimestampInterval: any;

  constructor(map: any) {
    this.token = map.token;
    this.name = map.name;
    this.value = map.value;
    this.type = map.type;
    this.terminalToken = map.terminal_token || map.terminalToken;
    this.layout = map.layout;
    this.useVoice = map.use_voice || map.useVoice;
    this.alpha = map.alpha;
    this.displayTime = map.display_time || map.displayTime;
    this.backgroundColor = map.background_color || map.backgroundColor;
    if (!this.backgroundColor.includes("#")) {
      this.backgroundColor = `#${this.backgroundColor}`;
    }
    this.fontColor = map.font_color || map.fontColor;
    if (!this.fontColor.includes("#")) {
      this.fontColor = `#${this.fontColor}`;
    }
    this.connectionCode = map.connection_code;
    this.connectionExpiresDate = map.connection_expires_date;
    this.hasRemoteControl = map.has_remote_control;
    this.isVertical =
      map.isVertical !== undefined ? map.isVertical : map.orientation === "90";
    this.useInputStream = map.use_input_stream
      ? map.use_input_stream
      : map.useInputStream
      ? map.useInputStream
      : false;
    this.inputStreamPosition = map.use_input_stream_position
      ? map.use_input_stream_position
      : map.inputStreamPosition
      ? map.inputStreamPosition
      : null;

    this.updateNowTimestamp();

    reaction(
      () => this.isOpenToConnect,
      (shouldStart: boolean) => {
        if (shouldStart) {
          this.didStartUpdatingNowTimestamp();
        } else {
          this.stopUpdatingNowTimestamp();
        }
      },
      { fireImmediately: true }
    );
  }

  @action
  setConnectionCode = (newValue: number) => {
    this.connectionCode = newValue;
  };
  @action
  setConnectionExpiresDate = (newValue: number) => {
    this.connectionExpiresDate = newValue;
  };

  get isConstant(): boolean {
    return this.type === QueueManagementControlType.constant;
  }
  get isOneVariable(): boolean {
    return this.type === QueueManagementControlType.oneVariable;
  }
  get isTwoVariables(): boolean {
    return this.type === QueueManagementControlType.twoVariables;
  }
  get isSequential(): boolean {
    return this.type === QueueManagementControlType.sequential;
  }

  @computed
  get hasConnectedDevice(): boolean {
    return this.terminalToken !== null;
  }

  @computed
  get isOpenToConnect(): boolean {
    if (!this.connectionExpiresDate) return false;

    return this.connectionExpiresDate > this.nowTimestamp;
  }

  @computed
  get displayTimeToExpire(): string {
    if (!this.connectionExpiresDate) return "";
    const timeToExpire = this.connectionExpiresDate - this.nowTimestamp;

    return secondsToMinutesAndHours(timeToExpire);
  }

  didStartUpdatingNowTimestamp = () => {
    this.updateNowTimestamp();
    this.nowTimestampInterval = setInterval(this.updateNowTimestamp, 1000);
  };

  stopUpdatingNowTimestamp = () => {
    if (this.nowTimestampInterval) {
      clearInterval(this.nowTimestampInterval);
    }
  };

  @action
  updateNowTimestamp = () => {
    this.nowTimestamp = Math.floor(Date.now() / 1000);
  };
}

export default QueueManagementControlModel;
