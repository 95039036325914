export enum EMediaTemplate {
  videoOriginal = "image_video_original",
  bannerWifi = "banner-wifi",
  logotype = "logotipo-tv",
}

export interface IMediaParams {
  template: EMediaTemplate;
  path: string;
  mediaFile: string;
  host: string;
  duration: number;
  framerate: number;
}
