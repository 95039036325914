// @ts-nocheck

// Utils
import { isSimplyasServer } from "../utils/EnvUtils";

// Pages
import AdministrativeContractPage from "../pages/AdministrativeContractPage";
import AdminReportsPage from "../pages/AdminReportsPage";
import AdminToolsPage from "../pages/AdminToolsPage";
import AdvertisingSendMessagePage from "../pages/AdvertisingSendMessagePage";
import CameraMetricsReportPage from "../pages/CameraMetricsReportPage";
import CheckoutPage from "../pages/CheckoutPage";
import ConnectivityAllowedDeniedDevicesPage from "../pages/ConnectivityAllowedDeniedDevicesPage";
import ConnectivityAuthenticationReportPage from "../pages/ConnectivityAuthenticationReportPage";
import ConnectivityMetricsReportPage from "../pages/ConnectivityMetricsReportPage";
import ConnectivityPage from "../pages/ConnectivityPage";
import Contact from "../pages/Contact";
import ContractUsersPage from "../pages/ContractUsersPage";
import DashboardPage from "../pages/DashboardPage";
import DevicesListPage from "../pages/DevicesListPage";
import DevelopersPage from "../pages/DevelopersPage";
import FinancialPage from "../pages/FinancialPage";
import InformativeQueueManagementPage from "../modules/informativeQueueManagement/page/InformativeQueueManagementPage";
import InformativeReportPage from "../pages/InformativeReportPage";
import InformativeSetPlaylistPage from "../pages/InformativeSetPlaylistPage";
import InformativeSetClockPage from "../pages/InformativeSetClockPage";
import InformativeSetLogotypePage from "../pages/InformativeSetLogotypePage";
import InformativeSetMessagesPage from "../pages/InformativeSetMessagesPage";
import I18nPage from "../pages/I18nPage";
import GroupsPage from "../pages/GroupsPage";
import LocationsPage from "../pages/LocationsPage";
import MediaLibraryPage from "../pages/MediaLibraryPage";
import PurchaseSuccessPage from "../pages/PurchaseSuccessPage";
import SetSinglePlaylistContainer from "../pages/informativeSetPlaylistPage/SetSinglePlaylistContainer";
import UserProfilePage from "../pages/UserProfilePage";

// Utils Stores
import { ContractAvailableResources } from "../store/ContractStore/ContractStore";
import { TUTORIALS_KEYS } from "../store/TutorialsStore/TutorialsStore";

export enum EAppRoutes {
  DASHBOARD = "/dashboard",
  DEVICES = "/devices",
  LOCATIONS = "/locations",
  GROUPS = "/groups",
  INFORMATIVE = "/informative",
  INFORMATIVE_PLAYLISTS = "/informative/set-playlist",
  INFORMATIVE_CLOCKS = "/informative/set-clock",
  INFORMATIVE_MESSAGES = "/informative/set-messages",
  INFORMATIVE_LOGOTYPES = "/informative/set-logotype",
  CONNECTIVITY = "/connectivity",
  CONNECTIVITY_SHARE = "/connectivity/share",
  CONTACT = "/contact",
  ADVERTISING = "/advertising",
  CAMERA = "/camera",
  ADMINISTRATIVE = "/administrative",
  SUPPORT = "/support",
  SIMPLYAS = "/simplyas",
}

export const simplyAsMenuRoutes = [
  {
    showOnSidebar: isSimplyasServer,
    allowedRule: "simplyas",
    collapse: true,
    path: EAppRoutes.SIMPLYAS,
    name: "Simply As",
    nameId: "Menu@simplyAs",
    state: "openSimplyAsMenu",
    icon: "ellipsis-h",
    views: [
      {
        path: EAppRoutes.SIMPLYAS + "/reports",
        name: "Relatórios globais",
        nameId: "Menu@adminReports",
        icon: "chart-area",
        component: AdminReportsPage,
      },
      {
        path: EAppRoutes.SIMPLYAS + "/admin",
        name: "Ferramentas",
        nameId: "Menu@adminTools",
        icon: "wrench",
        component: AdminToolsPage,
      },
      {
        path: EAppRoutes.SIMPLYAS + "/manage-translates",
        name: "i18n",
        nameId: "i18n",
        icon: "language",
        component: I18nPage,
      },
    ],
  },
];

const shoppingStoreRoutes = [
  {
    showOnSidebar: false,
    allowedRule: "affiliate",
    path: "/checkout",
    name: "Carrinho",
    nameId: "Menu@checkout",
    icon: "shopping-basket",
    component: CheckoutPage,
  },
  {
    showOnSidebar: true,
    allowedRule: "affiliate",
    path: "/financial",
    name: "Financeiro",
    nameId: "Menu@financial",
    icon: "dollar-sign",
    component: FinancialPage,
  },
  {
    showOnSidebar: false,
    allowedRule: "affiliate",
    path: "/purchase-success",
    component: PurchaseSuccessPage,
  },
];
const redirectAppRoutes = [
  {
    redirect: true,
    from: "/dev",
    to: "/developer",
  },
  {
    redirect: true,
    from: "/profile",
    to: "/my-profile",
  },
  {
    redirect: true,
    from: EAppRoutes.SIMPLYAS,
    to: EAppRoutes.SIMPLYAS + "/admin",
  },
  {
    redirect: true,
    from: EAppRoutes.CONNECTIVITY,
    to: EAppRoutes.CONNECTIVITY_SHARE,
  },
  {
    redirect: true,
    from: EAppRoutes.ADVERTISING,
    to: EAppRoutes.ADVERTISING + "/send-message",
  },
  {
    redirect: true,
    from: "/",
    to: EAppRoutes.DASHBOARD,
  },
];
const redirectAdvancedAppRoutes = [
  {
    redirect: true,
    from: EAppRoutes.ADMINISTRATIVE,
    to: EAppRoutes.ADMINISTRATIVE + "/contract",
  },
  {
    redirect: true,
    from: EAppRoutes.INFORMATIVE,
    to: EAppRoutes.INFORMATIVE + "/media-library",
  },
];

export const filterAppRoutesByContractPlanAndResources = (
  plan,
  {
    accessAutonomidia,
    administrative,
    adsEarnings,
    advertising,
    aiContentSuggestion,
    camera,
    clock,
    connectivity,
    developer,
    equipmentMonitor,
    footerMessages,
    hdmiIntegration,
    humanSupport,
    informative,
    logo,
    groupControl,
    locationControl,
    networking,
    playlist,
    playlistSchedule,
    queueManager,
    videoReports,
    viewerCounting,
  },
  rules
) => {
  return [
    {
      showOnSidebar: false,
      allowedRule: "all",
      path: "/my-profile",
      name: "Perfil",
      nameId: "Menu@profile",
      icon: "user",
      component: UserProfilePage,
      tutorialKey: TUTORIALS_KEYS.USER_PROFILE,
    },
    {
      showOnSidebar: true,
      allowedRule: "all",
      path: EAppRoutes.DASHBOARD,
      name: "Dashboard",
      nameId: "Menu@dashboard",
      icon: "chart-line",
      component: DashboardPage,
    },
    {
      showOnSidebar: true,
      allowedRule: "all",
      path: EAppRoutes.CONTACT,
      name: "Atendimento",
      nameId: "Menu@contact",
      icon: "comments",
      component: Contact,
    },
    {
      showOnSidebar: equipmentMonitor,
      allowedRule: "devices",
      path: EAppRoutes.DEVICES,
      name: "Dispositivos",
      nameId: "Menu@devices",
      icon: "hdd",
      component: DevicesListPage,
      tutorialKey: TUTORIALS_KEYS.DEVICES,
    },
    {
      showOnSidebar: groupControl,
      allowedRule: "groups",
      path: EAppRoutes.GROUPS,
      name: "Grupos",
      nameId: "Menu@groups",
      icon: "layer-group",
      component: GroupsPage,
      tutorialKey: TUTORIALS_KEYS.GROUPS,
    },
    {
      showOnSidebar: locationControl,
      allowedRule: "locations",
      path: EAppRoutes.LOCATIONS,
      name: "Locations",
      nameId: "Menu@locations",
      icon: "globe",
      component: LocationsPage,
      tutorialKey: TUTORIALS_KEYS.LOCATIONS,
    },
    {
      showOnSidebar: informative && rules.informative,
      allowedRule: "informative",
      path: EAppRoutes.INFORMATIVE,
      name: "Informativo",
      nameId: "Menu@informative",
      state: "openInformativeMenu",
      icon: "play",
      ...dynamicInformativeAttributes(),
    },
    {
      showOnSidebar: connectivity && rules.connectivity,
      allowedRule: "connectivity",
      collapse: true,
      path: EAppRoutes.CONNECTIVITY,
      name: "Conectividade",
      nameId: "Menu@connectivity",
      state: "openConnectivityMenu",
      icon: "wifi",
      views: [
        {
          showOnSidebar: connectivity,
          path: EAppRoutes.CONNECTIVITY_SHARE,
          name: "Compartilhar internet",
          nameId: "Menu@connectivityShareInternet",
          icon: "share-alt",
          component: ConnectivityPage,
          tutorialKey: TUTORIALS_KEYS.WIFI_ADVANCED,
        },
        {
          showOnSidebar: connectivity,
          path: EAppRoutes.CONNECTIVITY + "/allowed-and-denied-devices",
          name: "Liberados e bloqueados",
          nameId: "Menu@connectivityDevices",
          icon: "tablet-alt",
          component: ConnectivityAllowedDeniedDevicesPage,
        },
        {
          showOnSidebar: connectivity,
          path: EAppRoutes.CONNECTIVITY + "/authentications",
          name: "Relatório de autenticação",
          nameId: "Menu@authenticationsReport",
          icon: "mobile-alt",
          component: ConnectivityAuthenticationReportPage,
          tutorialKey: TUTORIALS_KEYS.AUTH_REPORT,
        },
        {
          showOnSidebar: viewerCounting,
          path: EAppRoutes.CONNECTIVITY + "/reports",
          name: "Relatórios avançados",
          nameId: "Menu@wifiMetricsReports",
          icon: "chart-area",
          component: ConnectivityMetricsReportPage,
        },
      ],
    },
    {
      showOnSidebar: camera && rules.camera,
      allowedRule: "camera",
      collapse: true,
      path: EAppRoutes.CAMERA,
      name: "Ambiente",
      nameId: "Menu@camera",
      state: "openCameraMenu",
      icon: "video",
      views: [
        {
          showOnSidebar: true,
          path: EAppRoutes.CAMERA + "/reports",
          name: "Relatórios avançados",
          nameId: "Menu@cameraMetricsReports",
          icon: "chart-bar",
          component: CameraMetricsReportPage,
        },
      ],
    },
    {
      showOnSidebar: advertising && rules.advertising,
      allowedRule: "advertising",
      collapse: true,
      path: EAppRoutes.ADVERTISING,
      name: "Publicidade",
      nameId: "Menu@advertising",
      state: "openAdvertisingMenu",
      icon: "bullhorn",
      views: [
        {
          showOnSidebar: true,
          path: EAppRoutes.ADVERTISING + "/send-message",
          name: "Enviar mensagem",
          nameId: "Menu@advertisingSendMessage",
          icon: "mobile-alt",
          component: AdvertisingSendMessagePage,
          tutorialKey: TUTORIALS_KEYS.ADVERTISING_SEND_MESSAGE,
        },
      ],
    },
    {
      showOnSidebar: administrative,
      allowedRule: "administrative",
      collapse: true,
      path: EAppRoutes.ADMINISTRATIVE,
      name: "Administrativo",
      nameId: "Menu@administrative",
      state: "openAdministrativeMenu",
      icon: "briefcase",
      views: [
        {
          showOnSidebar: true,
          path: EAppRoutes.ADMINISTRATIVE + "/contract",
          name: "Contrato",
          nameId: "Menu@administrativeContract",
          icon: "file-alt",
          component: AdministrativeContractPage,
          tutorialKey: TUTORIALS_KEYS.ADMINISTRATIVE,
        },
        {
          showOnSidebar: true,
          path: EAppRoutes.ADMINISTRATIVE + "/users",
          name: "Usuários",
          nameId: "Menu@administrativeUsers",
          icon: "users",
          component: ContractUsersPage,
          tutorialKey: TUTORIALS_KEYS.ADMINISTRATIVE,
        },
      ],
    },
    ...shoppingStoreRoutes,
    {
      showOnSidebar: developer,
      allowedRule: "api",
      path: "/developer",
      name: "Desenvolvedor",
      nameId: "Menu@developer",
      icon: "code",
      component: DevelopersPage,
    },
    {
      showOnSidebar: true,
      allowedRule: "all",
      path: "/logout",
      name: "Sair do sistema",
      nameId: "Menu@logout",
      icon: "sign-out-alt",
    },
    ...redirectAdvancedAppRoutes,
    ...redirectAppRoutes,
  ];

  function dynamicInformativeAttributes() {
    if (plan.name === "FREE") {
      return {
        component: SetSinglePlaylistContainer,
      };
    }
  
    return {
      collapse: true,
      views: [
        {
          showOnSidebar: true,
          path: EAppRoutes.INFORMATIVE + "/media-library",
          name: "Biblioteca de mídias",
          nameId: "Menu@mediaLibrary",
          icon: "images",
          component: MediaLibraryPage,
          tutorialKey: TUTORIALS_KEYS.MEDIA_LIBRARY,
        },
        {
          showOnSidebar: playlist && playlistSchedule,
          path: EAppRoutes.INFORMATIVE_PLAYLISTS,
          name: "Programação",
          nameId: "Menu@informativePlaylist",
          icon: "tv",
          component: InformativeSetPlaylistPage,
          tutorialKey: TUTORIALS_KEYS.PLAYLIST_ADVANCED,
        },
        {
          showOnSidebar: queueManager,
          path: EAppRoutes.INFORMATIVE + "/queue-management",
          name: "Gerenciador de filas",
          nameId: "Menu@informativeQueueManagement",
          icon: "ticket-alt",
          component: InformativeQueueManagementPage,
        },
        {
          showOnSidebar: clock,
          path: EAppRoutes.INFORMATIVE_CLOCKS,
          name: "Relógio",
          nameId: "Menu@clock",
          icon: "clock",
          component: InformativeSetClockPage,
          tutorialKey: TUTORIALS_KEYS.CLOCK_LOGO_MESSAGES,
        },
        {
          showOnSidebar: footerMessages,
          path: EAppRoutes.INFORMATIVE_MESSAGES,
          name: "Mensagem na tela",
          nameId: "Menu@messages",
          icon: "comment",
          component: InformativeSetMessagesPage,
          tutorialKey: TUTORIALS_KEYS.CLOCK_LOGO_MESSAGES,
        },
        {
          showOnSidebar: logo,
          path: EAppRoutes.INFORMATIVE_LOGOTYPES,
          name: "Logo",
          nameId: "Menu@logotype",
          icon: "draw-polygon",
          component: InformativeSetLogotypePage,
          tutorialKey: TUTORIALS_KEYS.CLOCK_LOGO_MESSAGES,
        },
        {
          showOnSidebar: videoReports,
          path: EAppRoutes.INFORMATIVE + "/reports",
          name: "Relatório de exibições",
          nameId: "Menu@informativeMediaReports",
          icon: "film",
          component: InformativeReportPage,
          tutorialKey: TUTORIALS_KEYS.CLOCK_LOGO_MESSAGES,
        },
      ],
    };
  }
};

export const sidebarRoutesForCurrentUser = (
  selectedContract,
  currentUserCanSupport,
  currentUserIsAdmin
) => {
  const appRoutes = filterAppRoutesByContractPlanAndResources(
    selectedContract.plan,
    selectedContract.resources,
    selectedContract.rules
  );

  return appRoutes.filter((route) => {
    // 0- showOnSidebar prop is mandatory
    if (!route.showOnSidebar) return false;

    if (route.views)
      route.views = route.views.filter((view) => view.showOnSidebar);

    // 1- Generic routes
    if (route.allowedRule === "all") return true;

    // 2- Support operator routes
    if (route.allowedRule === "support_operator" && currentUserCanSupport)
      return true;

    // 3- Simply As internal routes
    if (route.allowedRule === "simplyas" && currentUserIsAdmin) return true;

    // 4- Affiliate routes
    if (
      route.allowedRule === "affiliate" &&
      selectedContract.isAffiliateContract
    )
      return true;

    if (
      route.allowedRule === "administrative" &&
      selectedContract.access_administrative
    )
      return true;

    // 4- Contract resources & rules
    const matchContractResources = ContractAvailableResources.includes(
      route.allowedRule
    )
      ? selectedContract.resources[route.allowedRule]
      : true;
    const matchContractRules = selectedContract.rules[route.allowedRule];

    return matchContractResources && matchContractRules;
  });
};
