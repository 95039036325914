export const DOCUMENT_TYPE = Object.freeze({
  CPF: "CPF",
  CNPJ: "CNPJ",
  EIN: "EIN",
  TFN: "TFN",
  ABN: "ABN",
  OTHER: "OTHER",
});

export const DOCUMENT_TYPE_LIST = Object.values(DOCUMENT_TYPE);

export const labelForDocumentType = (type, localizedString) => {
  switch (type) {
    case DOCUMENT_TYPE.OTHER:
      return localizedString({
        id: "Contract@BusinessDocumentTypeOther",
        defaultString: "Outro",
      });
    case DOCUMENT_TYPE.EIN:
    case DOCUMENT_TYPE.TFN:
    case DOCUMENT_TYPE.ABN:
    case DOCUMENT_TYPE.CPF:
    case DOCUMENT_TYPE.CNPJ:
    default:
      return type;
  }
};

export const CONTACT_TYPE = Object.freeze({
  PHONE: "PHONE",
  EMAIL: "EMAIL",
  WEBSITE: "WEBSITE",
  FACEBOOK: "FACEBOOK",
  LINKEDIN: "LINKEDIN",
  TWITTER: "TWITTER",
  WHATSAPP: "WHATSAPP",
});

export const CONTACT_TYPE_LIST = Object.values(CONTACT_TYPE);

export const labelForContactType = (type, localizedString) => {
  switch (type) {
    case CONTACT_TYPE.PHONE:
      return localizedString({
        id: "Common@phone",
        defaultString: "Telefone",
      });
    case CONTACT_TYPE.EMAIL:
      return localizedString({
        id: "Common@email",
        defaultString: "E-mail",
      });
    case CONTACT_TYPE.WEBSITE:
      return "Site";
    case CONTACT_TYPE.FACEBOOK:
      return "Facebook";
    case CONTACT_TYPE.LINKEDIN:
      return "LinkedIn";
    case CONTACT_TYPE.TWITTER:
      return "Twitter";
    case CONTACT_TYPE.WHATSAPP:
      return "WhatsApp";
    default:
      return type;
  }
};

const getWorkingHours = () => {
  let hours = [];

  for (let index = 0; index < 24; index++) {
    hours.push(`${index < 10 ? `0${index}` : index}:00`);
    hours.push(`${index < 10 ? `0${index}` : index}:15`);
    hours.push(`${index < 10 ? `0${index}` : index}:30`);
    hours.push(`${index < 10 ? `0${index}` : index}:45`);
  }
  hours.push("23:59");

  return hours;
};

export const WORKING_HOURS_LIST = getWorkingHours().map((hour) => ({
  label: hour,
  value: hour,
}));

export const COUNTRY_CODES_LIST = ["AR", "AU", "BR", "CA", "PY", "US"];

export const countryListForSelect = (localizedString) => {
  return COUNTRY_CODES_LIST.map((code) => ({
    label: localizedString({
      id: `Country@${code}`,
      defaultString: code,
    }),
    value: code,
  }));
};

export const BRAZIL_STATES_LIST = [
  { label: "Acre", value: "AC" },
  { label: "Alagoas", value: "AL" },
  { label: "Amapá", value: "AP" },
  { label: "Amazonas", value: "AM" },
  { label: "Bahia", value: "BA" },
  { label: "Ceará", value: "CE" },
  { label: "Distrito Federal", value: "DF" },
  { label: "Espírito Santo", value: "ES" },
  { label: "Goiás", value: "GO" },
  { label: "Maranhão", value: "MA" },
  { label: "Mato Grosso", value: "MT" },
  { label: "Mato Grosso do Sul", value: "MS" },
  { label: "Minas Gerais", value: "MG" },
  { label: "Pará", value: "PA" },
  { label: "Paraíba", value: "PB" },
  { label: "Paraná", value: "PR" },
  { label: "Pernambuco", value: "PE" },
  { label: "Piauí", value: "PI" },
  { label: "Rio de Janeiro", value: "RJ" },
  { label: "Rio Grande do Norte", value: "RN" },
  { label: "Rio Grande do Sul", value: "RS" },
  { label: "Rondônia", value: "RO" },
  { label: "Roraima", value: "RR" },
  { label: "Santa Catarina", value: "SC" },
  { label: "São Paulo", value: "SP" },
  { label: "Sergipe", value: "SE" },
  { label: "Tocantins", value: "TO" },
  { label: "", value: "" },
];

export const brazilStateNameForCode = (code) => {
  return BRAZIL_STATES_LIST.find(($0) => $0.value === code).label;
};
