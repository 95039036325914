// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";

import {
  backgroundColorOptions,
  fontColorOptions,
} from "../../utils/ColorUtils";

// Components
import Tooltip from "../Tooltip/Tooltip";
import Badge from "../Badge/Badge";
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";
import { InputText, InputSwitch, InputColorPicker } from "../Form";
import { Button } from "../Button";
import { GridContainer, GridItem } from "../Grid";

import MessageCharactersRemaining from "../MessageCharactersRemaining/MessageCharactersRemaining";

import { withLocalized } from "../HOC";

import LedAreaForm from "../../components/LedArea/LedAreaForm";

// Translate
import {
  DeleteString,
  UpdateString,
  SaveString,
  RandomString,
  LabelHintString,
  BackgroundColorString,
  FontColorString,
  TransparentString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Icon
import { SaveIcon, DeleteIcon } from "../Icon";

// Util
import { generateRandomLabel } from "../../utils/StringUtils";

@withLocalized
@inject("informativeMessageStore", "notificationsStore")
@observer
class MessageConfigurationForm extends Component {
  constructor(props) {
    super(props);

    if (props.message) {
      this.state = this.getStateFromExistingMessage(props.message);
    } else {
      this.state = this.getDefaultState();
    }

    this.messagesCustomKeysList = [
      {
        key: "{USDBRL=X}",
        description: props.localizedString({
          id: "Messages@customKeyDescriptionUSDBRL",
          defaultString:
            "Exibe a cotação do dólar em comparação com o Real brasileiro.",
        }),
      },
      {
        key: "{EURBRL=X}",
        description: props.localizedString({
          id: "Messages@customKeyDescriptionEURBRL",
          defaultString:
            "Exibe a cotação do Euro em comparação com o Real brasileiro.",
        }),
      },
      {
        key: "{FORECAST_TEMPERATURE}",
        description: props.localizedString({
          id: "Messages@customKeyDescriptionForecastTemperature",
          defaultString:
            "Exibe a temperatura atual de acordo com a localização do dispositivo.",
        }),
      },
      {
        key: "{FORECAST_ICON}",
        description: props.localizedString({
          id: "Messages@customKeyDescriptionForecastIcon",
          defaultString:
            "Exibe um ícone referente a temperatura atual de acordo com a localização do dispositivo.",
        }),
      },
      {
        key: "{FORECAST_CITY}",
        description: props.localizedString({
          id: "Messages@customKeyDescriptionForecastCity",
          defaultString:
            "Exibe o nome da cidade de acordo com a localização do dispositivo.",
        }),
      },
    ];
  }

  getDefaultState = () => {
    return {
      label: "",
      backgroundColor: "#1D1D1D",
      fontColor: "#FFFFFF",
      isTransparent: false,
      isRandom: false,
      isExtended: false,
      isVertical: false,
      isLed: false,
      ledArea: "",
      informationsList: [""],
      modalIsOpen: false,
      isWaitingForm: false,
      formWasSubmit: false,
      didWantToDelete: false,
    };
  };

  getStateFromExistingMessage = (message) => {
    return {
      ...this.getDefaultState(),
      ...message,
      isLed: !!message.ledArea,
    };
  };

  onChangeInputText = (event) => {
    const { name: key, value, dataset, minLength, maxLength } = event.target;
    const validateIsRequired = dataset
      ? dataset.required === "true"
        ? value !== ""
        : true
      : true;
    const validateMinLength = minLength > 0 ? value.length >= minLength : true;
    const validateMaxLength = maxLength > 0 ? value.length <= maxLength : true;

    let newState = {
      [key]: value,
      isValid: {
        ...this.state.isValid,
        [`${key}`]:
          validateIsRequired && validateMinLength && validateMaxLength,
      },
    };

    this.setState(newState);
  };

  onChangeMessage = (event) => {
    const { name: key, value: newMessageValue } = event.target;
    const changedIndex = parseInt(key.replace("message", ""), 10);

    this.setState((state) => ({
      informationsList: state.informationsList.map((message, index) =>
        index === changedIndex ? newMessageValue : message
      ),
    }));
  };

  onChangeInputColorPicker = ({ name: key, color }) => {
    this.setState({ [key]: color });
  };

  onChangeSwitch = (name) => {
    this.setState((state) => ({
      [name]: !state[name],
    }));
  };

  didWantToAddLedArea = () => {
    this.setState({
      modalIsOpen: true,
      isLed: true,
    });
  };

  toggleIsEditingLedArea = () => {
    this.setState((prevState) => ({
      isLed: !prevState.isLed,
    }));
  };

  didWantToAddMessage = () => {
    this.setState((state) => ({
      informationsList: [...state.informationsList, ""],
    }));
  };

  canSubmitForm = () => {
    const { informationsList } = this.state;
    return (
      informationsList.length > 0 &&
      informationsList.filter(($0) => $0.length > 0).length > 0
    );
  };

  getFormDataToSubmit = ({
    label,
    backgroundColor,
    fontColor,
    isTransparent,
    isRandom,
    isExtended,
    isVertical,
    isLed,
    ledArea,
    informationsList,
  }) => {
    return {
      label:
        label.length > 0
          ? label
          : generateRandomLabel(
              this.props.localizedString({
                id: "Common@messages",
                defaultString: "Mensagens",
              })
            ),
      backgroundColor,
      fontColor,
      isTransparent,
      isRandom,
      isExtended,
      isVertical,
      isLed,
      ledArea,
      informationsList: informationsList.filter(($0) => $0.length > 0),
    };
  };

  submitForm = () => {
    const { message } = this.props;

    const formData = this.getFormDataToSubmit(this.state);

    if (message) {
      this.props.informativeMessageStore
        .update({
          ...formData,
          messageToken: message.token,
        })
        .then(() => {
          this.submitHasSuccess({ token: null });
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      this.props.informativeMessageStore
        .submit(formData)
        .then((response) => {
          this.submitHasSuccess({ token: response.data.message_token });
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  didWantToDelete = (event) => {
    event.preventDefault();

    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.informativeMessageStore
      .delete({
        messageToken: this.props.message.token,
      })
      .then(() => {
        this.props.didDelete();
      });
  };

  onChangeLedArea = (ledArea) => {
    this.setState({ ledArea });
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: (
              <Translate id="Messages@submitErrorMessage">
                Informe pelo menos uma mensagem para ser exibida.
              </Translate>
            ),
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = (token) => {
    this.props.didSave();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };
  messageLabelString = (number) => {
    return `${this.props.localizedString({
      id: "Common@message",
      defaultString: "Mensagem",
    })} ${number}`;
  };

  didCopyToClipboard = () => {
    this.props.notificationsStore.addSnackbarNotification({
      message: (
        <Translate id="Developer@copySuccessMessage">
          Cole em uma mensagem com CTRL + V :)
        </Translate>
      ),
    });
  };

  renderMessageCustomKey = ({ key, description }) => {
    return (
      <Tooltip key={key} title={description}>
        <div style={{ display: "inline-block", cursor: "pointer" }}>
          <CopyToClipboard text={key} onCopy={this.didCopyToClipboard}>
            <div>
              <Badge
                color="gray"
                style={{
                  marginRight: 6,
                }}
              >
                {key}
              </Badge>
            </div>
          </CopyToClipboard>
        </div>
      </Tooltip>
    );
  };

  render() {
    const isEditing = this.props.message;
    const {
      label,
      backgroundColor,
      fontColor,
      isTransparent,
      isRandom,
      isExtended,
      isVertical,
      isLed,
      ledArea,
      informationsList,
      isWaitingForm,
      formWasSubmit,
      didWantToDelete,
    } = this.state;

    return (
      <Fragment>
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteConfiguration}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <InputText
              label={
                <Translate id="Messages@titleLabel">
                  Título da configuração (opcional)
                </Translate>
              }
              name="label"
              icon="text-width"
              hasSuccess={formWasSubmit && label.length > 0}
              value={label}
              onChange={this.onChangeInputText}
              maxLength={60}
              helpText={<LabelHintString />}
            />
          </GridItem>
          <GridItem xs={6}>
            <InputColorPicker
              label={<BackgroundColorString />}
              name="backgroundColor"
              options={backgroundColorOptions}
              color={backgroundColor}
              onChange={this.onChangeInputColorPicker}
            />
          </GridItem>
          <GridItem xs={6}>
            <InputColorPicker
              label={<FontColorString />}
              name="fontColor"
              options={fontColorOptions}
              color={fontColor}
              onChange={this.onChangeInputColorPicker}
            />
          </GridItem>
          <GridItem xs={12}>
            <GridItem xs={12}>
              <InputSwitch
                label={<TransparentString />}
                name="isTransparent"
                isActive={isTransparent}
                onChange={this.onChangeSwitch}
              />
              <InputSwitch
                label={<RandomString />}
                name="isRandom"
                isActive={isRandom}
                onChange={this.onChangeSwitch}
              />
              <InputSwitch
                label={
                  <Translate id="Messages@extendedLabel">
                    Área reservada
                  </Translate>
                }
                name="isExtended"
                isActive={isExtended}
                onChange={this.onChangeSwitch}
              />
              <InputSwitch
                label={
                  <Translate id="Common@isVerticalLabel">Vertical</Translate>
                }
                name="isVertical"
                isActive={isVertical}
                onChange={this.onChangeSwitch}
              />
              <InputSwitch
                label="Led"
                name="isLed"
                isActive={isLed}
                onChange={this.toggleIsEditingLedArea}
              />
            </GridItem>
            {isLed && (
              <LedAreaForm onChange={this.onChangeLedArea} value={ledArea} />
            )}
            <GridItem xs={12} style={{ marginTop: 20 }}>
              <p style={{ marginBottom: 0 }}>
                <Tooltip
                  title={
                    <Translate id="Messages@customKeysExplanation">
                      Adicione qualquer uma das variáveis abaixo na sua mensagem
                      e ela será substituída pela informação que representa no
                      momento que a mensagem for exibida no televisor.
                    </Translate>
                  }
                >
                  <i className="fas fa-question-circle" />
                </Tooltip>{" "}
                <Translate id="Messages@customKeysLabel">
                  Variáveis disponíveis para uso nas mensagens:
                </Translate>
              </p>
              {this.messagesCustomKeysList.map((customKey) =>
                this.renderMessageCustomKey(customKey)
              )}
              {informationsList.map((message, index) => (
                <InputText
                  key={index}
                  label={this.messageLabelString(`${index + 1}`)}
                  name={`message${index}`}
                  icon="text-width"
                  value={message}
                  onChange={this.onChangeMessage}
                  maxLength={200}
                  multiline
                  helpText={
                    <MessageCharactersRemaining
                      messageLength={message.length}
                      maxLength={200}
                    />
                  }
                />
              ))}
              <Button color="success" small onClick={this.didWantToAddMessage}>
                <Translate id="Messages@addNewMessageButtonLabel">
                  Adicionar mensagem
                </Translate>{" "}
                <i className="fas fa-plus" />
              </Button>
            </GridItem>
            <GridContainer justify="space-between" style={{ marginTop: 30 }}>
              {isEditing && (
                <GridItem xs={12} sm={4}>
                  <Button color="danger" block onClick={this.didWantToDelete}>
                    <DeleteString /> <DeleteIcon />
                  </Button>
                </GridItem>
              )}
              <GridItem xs sm>
                <Button
                  color="primary"
                  block
                  isLoading={isWaitingForm}
                  onClick={this.onSubmit}
                >
                  {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default MessageConfigurationForm;
