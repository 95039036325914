// @ts-nocheck

import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../../HOC";
import AreaChart from "../../Charts/AreaChart";

// Styles
import { warningColor } from "../../../assets/jss/app-js-styles";
const styles = {};

const RamMemoryChart = ({
  yAnnotation,
  localizedString,
  dateFormat,
  chartData,
}) => (
  <AreaChart
    height={500}
    data={[
      {
        name: localizedString({
          id: "Device@hardwareFreeMemorySpaceInfo",
          defaultString: "Memória RAM",
        }),
        data: chartData.map(($0) => [$0.date * 1000, $0.free_mem]),
      },
    ]}
    showToolbar
    customChartOptions={{
      animations: { enabled: false },
      toolbar: {
        tools: {
          download: false,
        },
      },
    }}
    customOptions={{
      annotations: {
        yaxis: [
          yAnnotation({
            value: 25,
            color: warningColor,
            label: localizedString({
              id: "DeviceAlert@mininumLimitStateMessage",
              defaultString: "Limite mínimo",
            }),
          }),
        ],
      },
      yaxis: {
        labels: {
          formatter: (value) => `${value}MB`,
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          format: dateFormat,
          datetimeUTC: false,
        },
      },
      tooltip: {
        x: {
          format: dateFormat,
        },
        y: {
          formatter: (value) => `${value}MB`,
        },
      },
      dataLabels: { enabled: false },
    }}
  ></AreaChart>
);

export default withStyles(styles)(withLocalized(RamMemoryChart));
