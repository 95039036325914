//@ts-nocheck
import { FC, useState } from "react";
import ControlledPaginate from "../Paginate/ControlledPaginate";
import TerminalModel from "../../Model/TerminalModel";
import { GridItem } from "../Grid";
import DeviceItemContainer from "./DeviceItemContainer";
import { Button } from "../Button";
import Translate from "../I18n/Translate";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

interface DevicesGridViewProps {
  displayGroupName?: boolean;
  isFetchingPaginate: boolean;
  devicesPerPage: number;
  currentPage: number;
  totalDevices: number;
  onChangePaginate: (page: number, pageSize: number) => void;
  devicesList: TerminalModel[];
  didSelect: (token: string) => () => void;
}

const DevicesGridView: FC<DevicesGridViewProps> = ({
  displayGroupName,
  isFetchingPaginate,
  devicesPerPage,
  currentPage,
  totalDevices,
  onChangePaginate,
  devicesList,
  didSelect,
}) => {
  const [pageSize, setPageSize] = useState(devicesPerPage);
  const [anchor, setAnchor] = useState(null);

  function openMenu(event) {
    setAnchor(event.currentTarget);
  }

  function closeMenu() {
    setAnchor(null);
  }

  function didChangePagination(newPageSize) {
    closeMenu();
    setPageSize(newPageSize);
    onChangePaginate(currentPage, newPageSize);
  }

  return (
    <ControlledPaginate
      isFetching={isFetchingPaginate}
      itemsPerPage={devicesPerPage}
      currentPage={currentPage}
      itemsCount={totalDevices}
      onChange={(page) => onChangePaginate(page, devicesPerPage)}
      rightComponent={
        <GridItem style={{ textAlign: "center" }}>
          <Button
            aria-controls="page-size-menu"
            aria-haspopup="true"
            small
            block
            color="primary"
            onClick={openMenu}
          >
            <Translate id="Table@itemsPerPage" variables={{ pageSize }}>
              Itens por página: $pageSize
            </Translate>
          </Button>
          <Menu
            id="page-size-menu"
            keepMounted
            anchorEl={anchor}
            open={Boolean(anchor)}
          >
            {[10, 20, 40, 60, 80, 100].map((quantity) => (
              <MenuItem
                key={`menu-item-${quantity}`}
                onClick={() => didChangePagination(quantity)}
                selected={quantity === pageSize}
              >
                {quantity}
              </MenuItem>
            ))}
          </Menu>
        </GridItem>
      }
    >
      {devicesList.map((device) => (
        <GridItem key={device.token} xs={12} sm={6} md={4} lg={3} xl={2}>
          {/* @ts-ignore */}
          <DeviceItemContainer
            deviceItem={device}
            displayGroupName={displayGroupName}
            didSelect={didSelect}
          />
        </GridItem>
      ))}
    </ControlledPaginate>
  );
};

export default DevicesGridView;
