// @ts-nocheck
import { Component } from "react";
import PropTypes from "prop-types";

import { CircularProgress } from "@material-ui/core";

//Utils
import { makeCancelable } from "../../utils/PromiseUtils";

class IfExistsImage extends Component {
  state = { imageExists: false };

  checkImageExistenceTimeout = null;

  componentDidMount() {
    this.checkImageExistence();

    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.checkImageExistenceTimeout) {
      clearTimeout(this.checkImageExistenceTimeout);
      this.checkImageExistenceTimeout = null;
    }

    this.checkImageExistencePromise.cancel();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.imageExists !== nextState.imageExists) {
      return true;
    }

    if (this.props.src !== nextProps.src) {
      return true;
    }
    return false;
  }

  checkImageExistence = () => {
    this.checkImageExistencePromise = makeCancelable(
      new Promise((resolve) => {
        let img = new Image();
        img.onload = () => {
          resolve(true);
        };
        img.onerror = () => {
          resolve(false);
        };
        img.src = this.props.src;
      }).then((imageExists) => {
        if (!imageExists && this.props.loopUntilExists) {
          this.checkImageExistenceTimeout = setTimeout(
            this.checkImageExistence,
            5000
          );
        } else {
          if (this._isMounted) {
            this.setState({ imageExists });
          }
        }
      })
    );

    this.checkImageExistencePromise.promise.catch((error) => {
      window.debugError("IfExistsImage promise error:", error);
    });
  };

  render() {
    const {
      alt,
      src,
      className,
      placeholderClassName,
      customPlaceholder,
    } = this.props;

    if (this.state.imageExists) {
      return <img src={src} alt={alt} className={className} />;
    }

    return (
      <div className={placeholderClassName}>
        {customPlaceholder ? (
          { customPlaceholder }
        ) : (
          <CircularProgress size={30} variant="indeterminate" />
        )}
      </div>
    );
  }
}

IfExistsImage.defaultProps = {
  loopUntilExists: true,
};

IfExistsImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  placeholderClassName: PropTypes.string,
};

export default IfExistsImage;
