// @ts-nocheck
import { Component } from "react";
import classnames from "classnames";
import { DragSource, DropTarget } from "react-dnd";

import { Button } from "../../components/Button";
import { SimpleCard } from "../../components/Card";
import { GridContainer, GridItem } from "../../components/Grid";
import Tooltip from "../../components/Tooltip/Tooltip";

import ScheduleText from "../../components/ScheduleText/ScheduleText";

// Translate
import { EditString } from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

// Style
const inativeLabelStyle = (isActive) => ({
  textDecoration: isActive ? "none" : "line-through",
  color: isActive ? "inherit" : "#ADADAD",
});

// React DND
const dndItemType = ({ resourceRule }) => {
  const { group } = resourceRule;

  return `DND_ITEM_${group.token}`;
};

const dndItemSource = {
  beginDrag(props) {
    return {
      token: props.resourceRule.token,
      index: props.index,
    };
  },
  canDrag(props, monitor) {
    return props.canDrag;
  },
};

const dndItemTarget = {
  hover(props, monitor, component) {
    // Proceed only if there is a valid component
    if (!component) {
      return null;
    }

    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveItem({
      dragIndex,
      hoverIndex,
    });

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

@DropTarget(dndItemType, dndItemTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource(dndItemType, dndItemSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class ResourceRuleItem extends Component {
  render() {
    const {
      classes,
      isDragging,
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      resourceType,
      resourceRule,
      didSelectResourceRuleToEdit,
      didSelectResourceRuleToConfigureVideoWall,
    } = this.props;
    const opacity = isDragging ? 0 : 1;

    return (
      connectDragPreview &&
      connectDragSource &&
      connectDropTarget &&
      connectDragPreview(
        connectDropTarget(
          <div>
            <SimpleCard
              key={resourceRule.token}
              classNames={{
                card: classes.card,
                cardBody: classnames({
                  [classes.container]: true,
                  [classes.isInactiveContainer]: !resourceRule.isActive,
                }),
              }}
              style={{ opacity }}
            >
              <GridContainer>
                <GridItem xs={12} className={classes.titleContainer}>
                  <h3
                    className={classes.title}
                    style={inativeLabelStyle(resourceRule.isActive)}
                  >
                    {resourceRule.label}
                    {resourceRule.secondLabel &&
                      ` & ${resourceRule.secondLabel}`}
                  </h3>
                </GridItem>
                <GridItem xs={12}>
                  <ScheduleText
                    style={inativeLabelStyle(resourceRule.isActive)}
                    schedule={resourceRule.schedule[0]}
                  />
                </GridItem>
              </GridContainer>
              {resourceType === "playlist" &&
                resourceRule.playlist &&
                resourceRule.playlist.isVideoWall && (
                  <Tooltip
                    title={
                      <Translate id="VideoWall@setupTitle">
                        Configurar Vídeo wall
                      </Translate>
                    }
                  >
                    <Button
                      small
                      justIcon
                      color={
                        resourceRule.playlist.videoWallScreensCount >
                        resourceRule.group.terminalsList.length
                          ? "danger"
                          : "success"
                      }
                      style={{ marginRight: 8 }}
                      onClick={() =>
                        didSelectResourceRuleToConfigureVideoWall(
                          resourceRule.token
                        )
                      }
                    >
                      <i className="fa fa-desktop" />
                    </Button>
                  </Tooltip>
                )}
              <Tooltip title={<EditString />}>
                <Button
                  small
                  justIcon
                  color="info"
                  onClick={() =>
                    didSelectResourceRuleToEdit(resourceRule.token)
                  }
                >
                  <i className="fa fa-pen" />
                </Button>
              </Tooltip>
              {connectDragSource(
                <div>
                  <Button
                    small
                    justIcon
                    disableRipple
                    color="transparent"
                    style={{
                      marginLeft: 8,
                      cursor: this.props.canDrag ? "ns-resize" : "initial",
                      opacity: this.props.canDrag ? 1 : 0,
                    }}
                  >
                    <i className="fa fa-bars" />
                  </Button>
                </div>
              )}
            </SimpleCard>
          </div>
        )
      )
    );
  }
}

export default ResourceRuleItem;
