// @ts-nocheck
import { observable, autorun, computed, action } from "mobx";
import moment from "moment";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

import {
  contractsRef,
  affiliatesRef,
  ordersRef,
  serverTimestamp,
} from "../FirebaseStore/FirebaseStore";

// Utils
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import {
  endOfTheDayMoment,
  getPreviousMonthForDay,
  startOfTheDayMoment,
} from "../../utils/DateUtils";

class AffiliateStore {
  @observable
  isFetching = false;
  @observable
  orders = [];
  @observable
  financialHistoryList = [];
  @observable
  childContracts = [];

  affiliateContractRef = null;

  constructor(affiliateAuthStore, internalEventsStore, ordersStore) {
    this.affiliateAuthStore = affiliateAuthStore;
    this.ordersStore = ordersStore;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedAffiliate,
      observer: this,
      callback: this.clearStore,
    });

    autorun(
      () => {
        if (
          affiliateAuthStore.isAuthenticated &&
          affiliateAuthStore.hasValidContract
        ) {
          this.setFirebaseListeners();
        } else {
          this.clearStore();
        }
      },
      { name: "AffiliateStore setFirebaseListeners" }
    );
  }

  @action
  setFirebaseListeners = () => {
    this.isFetching = true;
    const contractToken = this.affiliateAuthStore.currentUser.contractToken;

    this.affiliateContractRef = affiliatesRef.child(contractToken);

    this.affiliateContractRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        const value = snapshot.val();
        if (value.financialHistoryList) {
          this.financialHistoryList = Object.keys(value.financialHistoryList)
            .map((key) => ({
              id: key,
              ...value.financialHistoryList[key],
            }))
            .sort(dynamicSortByProperty("timestamp"))
            .reverse();
        }

        if (value.childContracts) {
          this.childContracts = Object.keys(value.childContracts).map(
            (key) => ({
              contractHash: key,
              ...value.childContracts[key],
            })
          );
        }

        this.isFetching = false;
        if (this.childContracts.length > 0) {
          this.getOrders();
        }
      }
    });
  };

  //queryPaidByHashAndTimestamp
  //325160f4-54f1-41b6-a125-e477cdb24d86_1543407094000

  @action
  getOrders = () => {
    let newList = [];
    this.childContracts.forEach((childContract) => {
      ordersRef
        .orderByChild("queryHashAndStatus")
        .equalTo(`${childContract.contractHash}_paid`)
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            snapshot.forEach((orderSnapshot) => {
              const order = this.ordersStore.getOrderFromSnapshot(
                orderSnapshot
              );
              const totalPrice = this.ordersStore.getOrderTotalPrice(order);
              newList.push({
                ...order,
                price: totalPrice,
              });
            });
          }

          this.orders = newList
            .sort(dynamicSortByProperty("timestamp"))
            .reverse();
        });
    });
  };

  @computed
  get getNextPaymentInfo() {
    let total = 0;

    const startDay = getPreviousMonthForDay(20);
    const endDay = endOfTheDayMoment({ day: 10 });

    this.orders.forEach((item) => {
      const registrationDate = moment(item.timestamp);
      if (
        registrationDate.isSameOrAfter(startDay) &&
        registrationDate.isSameOrBefore(endDay)
      ) {
        total += item.price * 0.2;
      }
    });
    return total;
  }

  @computed
  get ordersList() {
    return this.orders;
  }

  @computed
  get getNextBillPeriodValue() {
    let total = 0;

    const startDay = startOfTheDayMoment({ day: 10 });
    const endDay = getPreviousMonthForDay(10);

    this.ordersList.forEach((item) => {
      const registrationDate = moment(item.timestamp);
      if (
        registrationDate.isSameOrAfter(startDay) &&
        registrationDate.isSameOrBefore(endDay)
      ) {
        total += item.price * 0.2;
      }
    });
    return total;
  }

  verifyCodeToGetAffiliateData = (affiliateCode) => {
    return new Promise((resolve, reject) => {
      affiliatesRef.once("value", (snapshot) => {
        if (snapshot.exists() && snapshot.val()) {
          let codeIsValid = false;
          snapshot.forEach((affiliateSnapshot) => {
            if (affiliateSnapshot.val().validCodes[affiliateCode]) {
              // Code is valid, resolve with affiliate token & masterUserToken.
              codeIsValid = true;
              resolve({
                affiliateToken: affiliateSnapshot.key,
                masterUserToken: affiliateSnapshot.val().masterUserToken,
              });
            }
          });

          // If code not found
          if (!codeIsValid) {
            reject();
          }
        } else {
          reject();
        }
      });
    });
  };

  didRegisterNewChildConctract = ({
    affiliateToken,
    masterUserToken,
    contractHash,
  }) => {
    affiliatesRef
      .child(`${affiliateToken}/childContracts/${contractHash}`)
      .set({
        registrationTimestamp: serverTimestamp,
      });
    contractsRef.child(`${contractHash}`).set({
      affiliateToken,
      masterUserToken,
      registrationTimestamp: serverTimestamp,
      didFinishedWelcomeStep: false,
      didFinishedInstallSystemStep: false,
      didFinishedActivateDeviceStep: false,
    });
  };

  didSetChildContractCommissionFeePercentage = (
    contractHash,
    commissionFeePercentage
  ) => {
    contractsRef
      .child(`${contractHash}/commissionFeePercentage`)
      .set(commissionFeePercentage);
  };

  @action
  clearStore = () => {
    this.isFetching = false;
    this.orders = [];
    this.financialHistoryList = [];

    if (this.affiliateContractRef) {
      this.affiliateContractRef.off();
      this.affiliateContractRef = null;
    }
  };
}

export default AffiliateStore;
