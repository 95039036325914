export const isProductionServer = process.env.NODE_ENV === "production" && !process.env?.REACT_APP_DEV;

const hostname = isProductionServer
  ? window.location.hostname
  : "hml-modbox.simplyas.com";

const hostnameList = hostname.split(".");

export const isSimplyasServer = hostname.includes("modbox");

export const HOST_URL =  window.location.host; 

export const IMAGE_PATH = `/files/modbox`;
export const SOUND_PATH = `/files/modbox/sound`;
export const WHITE_LABEL_COMPANY_DOMAIN = hostnameList[1]; 

export const LOGO_IMAGE = `${IMAGE_PATH}/logotipo-100x30.png`;
export const LOGIN_PAGE_BG_IMAGE = `${IMAGE_PATH}/bg-login-page.jpg`;
