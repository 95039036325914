// @ts-nocheck
import { observable, computed, action } from "mobx";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class InformativeMessageStore {
  @observable dataList = [];
  @observable isFetching = false;
  @observable searchString = "";

  constructor(
    internalEventsStore,
    resourcesStore,
    notificationsStore,
    modboxAPIService
  ) {
    this.resourcesStore = resourcesStore;
    this.notificationsStore = notificationsStore;
    this.modboxAPIService = modboxAPIService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.dataList = [];
    this.searchString = "";
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setDataList = (newValue) => {
    this.dataList = newValue;
  };

  @action
  setSearchString = (searchString) => {
    this.searchString = searchString;
  };

  @computed
  get filteredDataList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.dataList,
        (message, textIncludesSearchString) =>
          textIncludesSearchString(message.label)
      );
    }

    return this.dataList;
  }

  getDataList = (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);
    let newList = [];

    this.modboxAPIService
      .requestMessages()
      .then((response) => {
        if (response.status === 200) {
          response.data.messages.forEach((message) => {
            newList.push({
              label: message.label,
              ledArea: message.led_area,
              backgroundColor: `#${message["background_color"]}`,
              fontColor: `#${message["font_color"]}`,
              token: message["message_token"],
              isRandom: message.random === "True",
              isExtended: message.extended === "True",
              isTransparent: message.transparent === "True",
              isVertical: message.orientation === "90",
              informationsList: [...message.informations],
            });
          });
        }

        this.setDataList(newList.sort(dynamicSortByProperty("label")));

        this.setIsFetching(false);
      })
      .catch((error) => {
        window.debugError("error in requestMessages", error);
        if (error && error.statusCode === 429) {
          setTimeout(this.getMessagesList, 2000);
        }
      });
  };

  refreshResourcesStore = () => {
    this.resourcesStore.getResourceRulesList("message", false);
  };

  submit = (message) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitMessage(message)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: this.i18nStore.localizedString({
              id: "Schedule@readyToCreateRulesMessage",
              defaultString:
                "Configure uma regra para começar a utilizar o recurso.",
            }),
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (message) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .updateMessage(message)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          this.refreshResourcesStore();
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (message) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .deleteMessage(message)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          this.refreshResourcesStore();
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getWithToken = (token) => {
    if (this.dataList.length > 0) {
      return this.dataList.find((group) => group.token === token);
    }
    return null;
  };
}

export default InformativeMessageStore;
