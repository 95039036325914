// @ts-nocheck

import PropTypes from "prop-types";
import Chart from "react-apexcharts";

import { theme1, theme2, theme3 } from "./ChartColors";

const PieChart = ({
  data,
  height,
  legend,
  theme,
  showToolbar,
  customTooltip,
  customOptions,
}) => {
  const selectedTheme =
    theme === "theme2" ? theme2 : theme === "theme3" ? theme3 : theme1;
  const chartData = {
    options: {
      ...selectedTheme,
      labels: data.map((item) => item.name),
      legend: legend,
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -20,
          },
        },
      },
      chart: {
        toolbar: {
          show: showToolbar,
        },
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      },
      tooltip: {
        ...customTooltip,
      },
      ...customOptions,
    },
  };
  return (
    <Chart
      options={chartData.options}
      series={data.map((item) => item.y)}
      type="pie"
      height={height}
    />
  );
};

PieChart.defaultProps = {
  height: 300,
  legend: {
    position: "bottom",
  },
  theme: "theme1",
  showToolbar: false,
};

PieChart.propTypes = {
  customOptions: PropTypes.object,
  customTooltip: PropTypes.object,
};

export default PieChart;
