// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";
import { inject } from "mobx-react";
import { Component, Fragment } from "react";

// Components
import { Button } from "../Button";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";
import { ButtonGroup, InputSelect, InputText } from "../Form";
import DatePicker from "../Form/DatePicker";
import InputPhoneNumber from "../Form/InputPhoneNumber";
import NumberEditingWithLabelInput from "../Form/NumberEditingWithLabelInput";
import Validate, { ValidationType } from "../Form/Validate";
import { GridContainer, GridItem } from "../Grid";
import { withLocalized } from "../HOC";
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";

// Translate
import {
  CancelString,
  DeleteString,
  MinutesString,
  SaveString,
  UpdateString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Icon
import { ChevronLeftIcon, DeleteIcon, SaveIcon } from "../Icon";

// Utils
import {
  momentDateFromUnixDate,
  startOfTheDayMoment,
} from "../../utils/DateUtils";
import { phoneIsValid } from "../../utils/PhoneUtils";
import { generateRandomLabel } from "../../utils/StringUtils";

// Styles
const styles = {
  label: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.42857,
    textTransform: "uppercase",
  },
  anonymousCodeLabel: {
    textAlign: "center",
  },
  anonymousCode: {
    cursor: "pointer",
    fontSize: "2em",
    fontWeight: "bold",
    background: "#FFD000",
    color: "#333",
    padding: "20px 16px",
    borderRadius: 8,
    margin: "10px auto",
    width: "fit-content",
  },
};

@withLocalized
@inject(
  "groupsStore",
  "connectivityDevicesStore",
  "notificationsStore",
  "connectivityDevicesStore"
)
class ConnectivityDeviceForm extends Component {
  constructor(props) {
    super(props);

    if (props.connectivityDevice) {
      this.state = {
        ...this.defaultState,
        ...this.getStateFromExistingItem(props.connectivityDevice),
      };
    } else {
      this.state = this.defaultState;
    }
  }

  defaultState = {
    label: "",
    identifierType: "phoneNumber",
    dateType: "neverExpires",
    phoneNumber: "",
    macAddress: "",
    groups: [],
    expires: startOfTheDayMoment().add(7, "days"),
    validityAfterActivation: 20,
    isWaitingForm: false,
    formWasSubmit: false,
    anonymousCode: null,
    duplicatedToken: null,
    didWantToDelete: false,
  };

  getStateFromExistingItem = (item) => {
    return {
      label: item.label,
      identifierType:
        item.phoneNumber && item.phoneNumber.length > 1
          ? "phoneNumber"
          : item.macAddress && item.macAddress.length > 1
          ? "macAddress"
          : "anonymous",
      anonymousCode: item.code,
      phoneNumber: item.phoneNumber || "",
      macAddress: item.macAddress || "",
      expires: item.expires > 0 ? momentDateFromUnixDate(item.expires) : 0,
      dateType: item.validityAfterActivation
        ? "afterActivation"
        : item.expires === 0
        ? "neverExpires"
        : "untilDate",
      validityAfterActivation: item.validityAfterActivation || 20,
      groups: item.groups,
    };
  };

  onChangeLabel = ({ target }) => {
    const { value } = target;

    this.setState({
      label: value,
    });
  };

  onChangePhoneNumber = (phoneNumber) => {
    this.setState({
      phoneNumber,
    });
  };
  onChangeMacAddress = ({ target }) => {
    const { value } = target;

    this.setState({
      macAddress: value.toLowerCase().replace(/-/g, ":"),
    });
  };

  onChangeButtonGroup = (name, selectedValue) => {
    this.setState({
      [name]: selectedValue,
    });
  };

  onChangeExpireDate = (_, newValue) => {
    this.setState({ expires: newValue });
  };

  onChangeValidityAfterActivation = (validityAfterActivation) => {
    this.setState({ validityAfterActivation });
  };

  onChangeGroups = (_, groups) => {
    this.setState({
      groups,
    });
  };

  didSelectAllGroups = () => {
    this.setState({
      groups: this.props.groupsStore.groupsList,
    });
  };

  macAddressIsValid = (value) => {
    return Validate(value, ValidationType.MAC_ADDRESS);
  };

  didWantToDelete = (event) => {
    event.preventDefault();

    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.connectivityDevicesStore
      .delete(this.props.connectivityDevice.token)
      .then(() => {
        this.props.didDelete();
      });
  };

  canSubmitForm = () => {
    let canSubmit = true;

    const { addSnackbarNotification } = this.props.notificationsStore;
    const {
      groups,
      dateType,
      identifierType,
      phoneNumber,
      macAddress,
      expires,
    } = this.state;
    if (groups.length < 1) {
      addSnackbarNotification({
        message: (
          <Translate id="ConnectivityDevice@groupsIsRequiredMessage">
            Selecione grupo(s).
          </Translate>
        ),
        color: "warning",
      });
      canSubmit = false;
    }
    if (identifierType === "phoneNumber" && !phoneIsValid(phoneNumber)) {
      addSnackbarNotification({
        message: (
          <Translate id="ConnectivityDevice@phoneIsRequired">
            Telefone inválido, por favor verifique.
          </Translate>
        ),
        color: "warning",
      });
      canSubmit = false;
    }
    if (
      identifierType === "macAddress" &&
      !this.macAddressIsValid(macAddress)
    ) {
      addSnackbarNotification({
        message: (
          <Translate id="ConnectivityDevice@macAddressIsRequired">
            Endereço MAC inválido, por favor verifique.
          </Translate>
        ),
        color: "warning",
      });
      canSubmit = false;
    }

    if (dateType === "untilDate" && expires.isBefore(startOfTheDayMoment())) {
      addSnackbarNotification({
        message: (
          <Translate id="ConnectivityDevice@invalidDateMessage">
            Data de validade inválida.
          </Translate>
        ),
        color: "warning",
      });
      canSubmit = false;
    }
    return canSubmit;
  };

  getFormDataToSubmit = ({
    label,
    identifierType,
    phoneNumber,
    macAddress,
    expires: stateExpires,
    dateType,
    validityAfterActivation: stateValidityAfterActivation,
    groups,
  }) => {
    let expires = null;
    let validityAfterActivation = null;
    if (dateType === "neverExpires") {
      expires = 0;
    } else if (dateType === "untilDate") {
      expires = stateExpires.format("X");
    } else {
      validityAfterActivation =
        stateValidityAfterActivation > 10 ? stateValidityAfterActivation : 10;
    }

    return {
      label: label.length > 0 ? label : generateRandomLabel("Device"),
      phoneNumber:
        identifierType === "phoneNumber"
          ? phoneNumber.trim().replace(/ /g, "")
          : undefined,
      macAddress: identifierType === "macAddress" ? macAddress : undefined,
      expires,
      validityAfterActivation,
      groups: groups.map(($0) => $0.token),
      isBanned: this.props.isBanned,
    };
  };

  submitCatch = (error) => {
    if (error && error.statusCode === 409 && error.token) {
      this.setState(
        {
          duplicatedToken: error.token,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: error.error,
            color: "danger",
          });
        }
      );
    } else {
      this.submitHasError();
    }
  };

  submitForm = () => {
    const formData = this.getFormDataToSubmit(this.state);

    if (this.props.connectivityDevice) {
      this.props.connectivityDevicesStore
        .update({
          ...formData,
          token: this.props.connectivityDevice.token,
        })
        .then(this.didSave)
        .catch(this.submitCatch);
    } else {
      this.props.connectivityDevicesStore
        .submit(formData)
        .then((code) => {
          if (code) {
            this.setState({
              anonymousCode: `${code}`,
            });
          } else {
            this.didSave();
          }
        })
        .catch(this.submitCatch);
    }
  };

  didFindDuplicatedToken = () => {
    const token = this.state.duplicatedToken;
    this.setState(
      {
        duplicatedToken: null,
      },
      () => {
        this.props.didFindDuplicatedToken(token);
      }
    );
  };

  resetState = () => {
    setTimeout(() => {
      this.setState(this.defaultState);
    }, 400);
  };

  didSave = () => {
    this.props.didSave();
    this.resetState();
  };

  didCancel = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.props.didCancel();

    this.resetState();
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState({
        formWasSubmit: true,
      });
    }
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  getIdentifierTypeOptions = () => {
    const { localizedString, isBanned } = this.props;
    let options = [
      {
        value: "phoneNumber",
        label: localizedString({
          id: "ConnectivityDevice@definedTypeLabel",
          defaultString: "Número específico",
        }),
      },
      {
        value: "macAddress",
        label: localizedString({
          id: "ConnectivityDevice@macAddressTypeLabel",
          defaultString: "Endereço MAC",
        }),
      },
    ];
    if (!isBanned) {
      options.push({
        value: "anonymous",
        label: localizedString({
          id: "ConnectivityDevice@anonymous",
          defaultString: "Anônimo",
        }),
      });
    }
    return options;
  };

  getDataTypeOptions = () => {
    const { localizedString, isBanned, connectivityDevice } = this.props;
    const isEditing = connectivityDevice !== null;
    let options = [
      {
        value: "neverExpires",
        label: localizedString({
          id: "ConnectivityDevice@expireUnlimitedDateLabel",
          defaultString: "Ilimitado",
        }),
      },
      {
        value: "untilDate",
        label: localizedString({
          id: "ConnectivityDevice@untilDateLabel",
          defaultString: "Data específica",
        }),
      },
    ];
    if (
      !isBanned &&
      (!isEditing ||
        connectivityDevice.status === "defined" ||
        connectivityDevice.status === "anonymous" ||
        connectivityDevice.status === "undefined")
    ) {
      options.push({
        value: "afterActivation",
        label: localizedString({
          id: "ConnectivityDevice@afterActivationDateLabel",
          defaultString: "Tempo de uso",
        }),
        isDisabled: isBanned,
      });
    }
    return options;
  };

  renderDuplicatedToken = () => {
    return (
      <GridContainer
        alignItems="center"
        justify="center"
        style={{ marginTop: 40, marginBottom: 40 }}
      >
        <GridItem xs={12} style={{ textAlign: "center" }}>
          <p>
            <i className="fas fa-exclamation-triangle" />{" "}
            <Translate id="ConnectivityDevice@foundDuplicatedDevice">
              O número ou endereço MAC informado já está em uso.
            </Translate>
          </p>
          <Button
            color="primary"
            outline
            block
            onClick={this.didFindDuplicatedToken}
          >
            <Translate id="ConnectivityDevice@editDuplicatedDevice">
              Editar registro existente
            </Translate>{" "}
            <i className="fas fa-pen" />
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  didCopyCode = () => {
    this.props.notificationsStore.addSnackbarNotification({
      message: (
        <Translate id="Developer@copySuccessMessage">
          Pronto! Agora cole com CTRL + V
        </Translate>
      ),
    });
  };

  render() {
    if (this.state.duplicatedToken) {
      return this.renderDuplicatedToken();
    }
    if (this.state.anonymousCode) {
      return (
        <GridContainer
          alignItems="center"
          justify="center"
          style={{ marginTop: 40, marginBottom: 40 }}
        >
          <GridItem xs={12} style={{ textAlign: "center" }}>
            <p className={this.props.classes.anonymousCodeLabel}>
              <Translate id="ConnectivityDevice@takeNoteAnonymousCode">
                Anote o código do voucher:
              </Translate>
            </p>
            <CopyToClipboard
              text={this.state.anonymousCode}
              onCopy={this.didCopyCode}
            >
              <p className={this.props.classes.anonymousCode}>
                {this.state.anonymousCode}
              </p>
            </CopyToClipboard>
            {this.props.connectivityDevice && (
              <Button
                color="primary"
                outline
                small
                onClick={() => this.setState({ anonymousCode: null })}
              >
                <Translate id="Common@edit">Editar</Translate>{" "}
                <i className="fas fa-pen" />
              </Button>
            )}
          </GridItem>
        </GridContainer>
      );
    }
    const { classes, connectivityDevice } = this.props;
    const isEditing = connectivityDevice !== null;

    const {
      label,
      identifierType,
      dateType,
      phoneNumber,
      macAddress,
      groups,
      expires,
      validityAfterActivation,
      isWaitingForm,
      formWasSubmit,
    } = this.state;

    return (
      <Fragment>
        {this.state.didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteConfiguration}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        <GridContainer style={{ width: "100%", margin: 0, marginBottom: 10 }}>
          <GridItem xs={12}>
            {!isEditing && (
              <GridContainer>
                <GridItem xs={12}>
                  <ButtonGroup
                    name="identifierType"
                    value={identifierType}
                    color="success"
                    small
                    onChange={this.onChangeButtonGroup}
                    options={this.getIdentifierTypeOptions()}
                  />
                </GridItem>
                {identifierType === "phoneNumber" && (
                  <GridItem xs={12} style={{ marginTop: -16 }}>
                    <InputPhoneNumber
                      isValid={phoneIsValid(phoneNumber)}
                      hasError={formWasSubmit && !phoneIsValid(phoneNumber)}
                      hasSuccess={formWasSubmit && phoneIsValid(phoneNumber)}
                      onChange={this.onChangePhoneNumber}
                    />
                  </GridItem>
                )}
                {identifierType === "macAddress" && (
                  <GridItem xs={12} style={{ marginTop: -16 }}>
                    <InputText
                      label={
                        <Translate id="Connectivity@macAddress">
                          Endereço MAC
                        </Translate>
                      }
                      name="macAddress"
                      value={macAddress}
                      hasError={
                        formWasSubmit && !this.macAddressIsValid(macAddress)
                      }
                      hasSuccess={
                        formWasSubmit && this.macAddressIsValid(macAddress)
                      }
                      onChange={this.onChangeMacAddress}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} style={{ marginBottom: 10 }}>
                <InputSelect
                  withPortal
                  label={
                    <Translate id="Advertising@selectGroupsLabel">
                      Grupos
                    </Translate>
                  }
                  name="groups"
                  value={groups}
                  options={this.props.groupsStore.groupsList}
                  onChange={this.onChangeGroups}
                  valueKey="token"
                  multiple
                  hasError={formWasSubmit && groups.length < 1}
                  hasSuccess={formWasSubmit && groups.length > 0}
                />
                <Button
                  outline
                  small
                  onClick={this.didSelectAllGroups}
                  style={{
                    marginTop: -15,
                    padding: "4px 6px",
                    opacity:
                      groups.length < this.props.groupsStore.groupsList.length
                        ? 1
                        : 0,
                  }}
                >
                  <Translate id="Metrics@selectAllGroupsButtonLabel">
                    Selecionar tudo
                  </Translate>{" "}
                  <i className="far fa-arrows-h" />
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <label className={classes.label}>
                  <Translate id="ConnectivityDevice@expireDateLabel">
                    Valido até
                  </Translate>
                </label>
              </GridItem>
              <GridItem xs={12}>
                <ButtonGroup
                  name="dateType"
                  value={dateType}
                  color={"success"}
                  small
                  onChange={this.onChangeButtonGroup}
                  options={this.getDataTypeOptions()}
                />
              </GridItem>
              {dateType === "untilDate" && (
                <GridItem xs={12}>
                  <DatePicker
                    name="expires"
                    value={expires}
                    onChange={this.onChangeExpireDate}
                    fullWidth
                    timeFormat
                    removeMargin
                    alwaysOpenWithoutInput
                    isValidDate={(currentDate) =>
                      currentDate.isSameOrAfter(startOfTheDayMoment())
                    }
                  />
                </GridItem>
              )}
              {dateType === "afterActivation" && (
                <GridItem xs={12}>
                  <NumberEditingWithLabelInput
                    label={
                      <Translate id="ConnectivityDevice@afterActivationInputLabel">
                        Tempo de uso após ativação:
                      </Translate>
                    }
                    value={validityAfterActivation}
                    typeLabel={<MinutesString />}
                    onChange={this.onChangeValidityAfterActivation}
                  />
                </GridItem>
              )}
            </GridContainer>

            <GridContainer>
              <GridItem xs={12}>
                <InputText
                  label={
                    <Translate id="ConnectivityDevice@titleLabel">
                      Identificação (opcional)
                    </Translate>
                  }
                  name="label"
                  value={label}
                  onChange={this.onChangeLabel}
                />
              </GridItem>
            </GridContainer>

            <GridContainer justify="space-between">
              <GridItem xs={12} sm={4}>
                <Button block onClick={this.didCancel}>
                  <ChevronLeftIcon /> <CancelString />
                </Button>
              </GridItem>
              {isEditing && (
                <GridItem xs={12} sm={4}>
                  <Button color="danger" block onClick={this.didWantToDelete}>
                    <DeleteString /> <DeleteIcon />
                  </Button>
                </GridItem>
              )}
              <GridItem xs={12} sm={4}>
                <Button
                  color="primary"
                  block
                  isLoading={isWaitingForm}
                  onClick={this.onSubmit}
                >
                  {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ConnectivityDeviceForm);
