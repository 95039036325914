class NetworkModel {
  label;
  token;
  category;
  wifiName;
  maxTime;
  bandwidth;
  smartAuth;
  authUrl;
  authRedirect;
  wifiPassword;
  proxyFilter;
  wifiBanners;
  smsCustomMessage;
  denyReauth;
  denyCaptive;
  denyNetworks;
  onlyAllowed;

  constructor(network, modboxFileService) {
    this.label = network.label;
    this.token = network.network_token;
    this.category = network.category;
    this.wifiName = network.name || "";
    this.maxTime = network.max_time;
    this.bandwidth = network.bandwidth;
    this.smartAuth = network.auth === "True";
    this.authUrl = network.auth_url || "";
    this.authRedirect = network.redirect;
    this.wifiPassword = network.password || "";
    this.proxyFilter = network.filter;
    this.wifiBanners = network.wifi_banners
      ? network.wifi_banners.map((token) => ({
          token,
          url: modboxFileService.mountFileUrl({
            hash: token,
            type: "wifiBanner",
          }),
        }))
      : [];
    this.smsCustomMessage = network.sms_custom;
    this.denyReauth = network.deny_reauth === "True";
    this.denyCaptive = network.deny_captive === "True";
    this.denyNetworks = network.deny_networks === "True";
    this.onlyAllowed = network.only_guest === "True";
  }
}

export default NetworkModel;
