// @ts-nocheck

import { inject, observer } from "mobx-react";
import { ComponentType, FC } from "react";

interface WithLocalizedProps {
  localizedString?: any;
  localizedPlural?: any;
}

const withLocalized = <P extends object>(
  WrappedComponent: ComponentType<P>
): FC<P & WithLocalizedProps> =>
  inject("i18nStore")(
    observer(({ i18nStore, ...rest }) => {
      const { localizedString, localizedPlural } = i18nStore;
      return (
        <WrappedComponent
          localizedString={localizedString}
          localizedPlural={localizedPlural}
          i18nStore={i18nStore}
          {...rest}
        />
      );
    })
  );

export default withLocalized;
