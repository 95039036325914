// @ts-nocheck

import ReactSound from "react-sound";

const SimpleSound = ({ src, status, onFinishedPlaying }) => {
  const getStatus = () => {
    switch (status) {
      case "playing":
        return ReactSound.status.PLAYING;
      case "paused":
        return ReactSound.status.PAUSED;
      case "stopped":
      default:
        return ReactSound.status.STOPPED;
    }
  };
  return (
    <ReactSound
      url={src}
      playStatus={getStatus()}
      autoLoad
      onFinishedPlaying={onFinishedPlaying}
    />
  );
};

SimpleSound.defaultProps = {
  status: "playing",
};

export default SimpleSound;
