import { parsePhoneNumberFromString } from "libphonenumber-js";

export const phoneObject = (phoneNumber) => {
  return parsePhoneNumberFromString(phoneNumber, "BR");
};

export const phoneIsValid = (phoneNumber) => {
  const phone = parsePhoneNumberFromString(phoneNumber, "BR");
  return phone ? phone.isValid() : false;
};

export const phoneIfValid = (phoneNumber) => {
  const phone = parsePhoneNumberFromString(phoneNumber, "BR");
  return phone && phone.isValid() ? phone : null;
};

export const showPhoneIfValid = (phoneNumber, placeholder = "") => {
  const phone = phoneIfValid(phoneNumber);
  return phone ? phone.formatInternational() : placeholder;
};
