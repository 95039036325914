//@ts-nocheck
import { inject, observer } from "mobx-react";
import { Component, Fragment } from "react";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button } from "../../components/Button";
import DeviceSelectorInput from "../../components/Devices/DeviceSelectorInput";
import { GridContainer, GridItem } from "../../components/Grid";

import GroupsPageDeviceItem from "./GroupsPageDeviceItem";

import TerminalModel from "../../Model/TerminalModel";

// Translate
import { CancelString } from "../../components/I18n/CommonStrings";
import Plural from "../../components/I18n/Plural";
import Translate from "../../components/I18n/Translate";

// Icons
import { CheckIcon, ChevronLeftIcon } from "../../components/Icon/MaterialIcon";
import LoadingView from "../../components/LoadingView/LoadingView";

import GroupModel from "../../Model/GroupModel";
import GroupsStore from "../../store/GroupsStore/GroupsStore";
import TerminalsStore from "../../store/TerminalsStore/TerminalsStore";

// Style
const styles = (theme: Theme) =>
  createStyles({
    panel: { background: "#EEEEEE" },
    devicesContainer: {
      display: "flex",
      justifyContent: "center",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 700,
      flexShrink: 0,
    },
  });

interface IAddDeviceToGroupContainerProps {
  classes: any;
  group: GroupModel;
  didSave: () => void;
  didCancel: () => void;
}

interface IAddDeviceToGroupContainerState {
  selectedDevicesList: any[];
  isFetchingAvailableDevices: boolean;
  availableDevices: any[];
  expandedPanel: string | null;
  isWaitingServer: boolean;
}

interface InjectedProps extends IAddDeviceToGroupContainerProps {
  groupsStore: GroupsStore;
  terminalsStore: TerminalsStore;
}

@inject("groupsStore", "terminalsStore")
@observer
class AddDeviceToGroupContainer extends Component<
  IAddDeviceToGroupContainerProps,
  IAddDeviceToGroupContainerState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  constructor(props: IAddDeviceToGroupContainerProps) {
    super(props);

    this.state = {
      selectedDevicesList: [],
      isFetchingAvailableDevices: false,
      availableDevices: [],
      expandedPanel: "availableDevices",
      isWaitingServer: false,
    };
  }

  componentDidMount() {
    this.getAvailableDevices();
  }

  didSelectExpandPanel = (selectedPanel: string) => {
    this.setState({
      expandedPanel:
        selectedPanel === this.state.expandedPanel ? null : selectedPanel,
    });
  };

  deviceIsSelected = (deviceToken: string) => {
    return (
      this.state.selectedDevicesList.filter(
        (device) => device.token === deviceToken
      ).length > 0
    );
  };

  didSelectConnectedDevices = (_: string, tokens: string[]) => {
    this.updateGroup([...this.props.group.terminalsList, ...tokens]);
  };

  didSelectDevice = (selectedDevice) => {
    if (this.deviceIsSelected(selectedDevice.token)) {
      this.setState({
        selectedDevicesList: this.state.selectedDevicesList.filter(
          (device) => device.token !== selectedDevice.token
        ),
      });
    } else {
      this.setState({
        selectedDevicesList: [
          ...this.state.selectedDevicesList,
          selectedDevice,
        ],
      });
    }
  };

  updateGroup = (terminalsList: any[]) => {
    this.setState(
      {
        isWaitingServer: true,
      },
      async () => {
        await this.injected.groupsStore.updateGroup({
          ...this.props.group,
          terminalsList,
        });
        this.props.didSave();
      }
    );
  };

  didAddDevices = () => {
    const { selectedDevicesList } = this.state;

    this.updateGroup([
      ...this.props.group.terminalsList,
      ...selectedDevicesList,
    ]);
  };

  getAvailableDevices = () => {
    this.setState({ isFetchingAvailableDevices: true }, async () => {
      const devices = await this.injected.terminalsStore.requestTerminalsNotConnectedToGroup();

      this.setState({
        isFetchingAvailableDevices: false,
        availableDevices: devices,
      });
    });
  };

  render() {
    const { classes } = this.props;
    if (this.state.isWaitingServer) {
      return (
        <div style={{ margin: "80px 0" }}>
          <LoadingView />
        </div>
      );
    }

    const {
      isFetchingAvailableDevices,
      availableDevices,
      expandedPanel,
      selectedDevicesList,
    } = this.state;

    return (
      <Fragment>
        <ExpansionPanel
          className={classes.panel}
          expanded={expandedPanel === "availableDevices"}
          onChange={() => this.didSelectExpandPanel("availableDevices")}
        >
          <ExpansionPanelSummary
            expandIcon={
              <i className="fas fa-chevron-down" style={{ fontSize: 14 }} />
            }
          >
            <Typography className={classes.heading}>
              <Translate id="Group@availableDevicesTitle">
                Dispositivos desconectados
              </Translate>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.devicesContainer}>
            <GridContainer justify="center">
              <GridItem xs={12} style={{ textAlign: "center" }}>
                {isFetchingAvailableDevices ? (
                  <LoadingView />
                ) : (
                  availableDevices.map((terminal: TerminalModel) => (
                    <GroupsPageDeviceItem
                      key={terminal.token}
                      token={terminal.token}
                      name={terminal.name}
                      tooltipTitle={terminal.network?.internet_address}
                      didSelect={this.didSelectDevice}
                      iconType="check"
                      isSelected={this.deviceIsSelected(terminal.token)}
                    />
                  ))
                )}
                {!isFetchingAvailableDevices && availableDevices.length < 1 && (
                  <p>
                    <Translate id="Group@noAvailableDevicesMessage">
                      Todos os dispositivos estão conectados a outros grupos.
                    </Translate>
                  </p>
                )}
              </GridItem>
            </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.panel}
          expanded={expandedPanel === "connectedDevices"}
          onChange={() => this.didSelectExpandPanel("connectedDevices")}
        >
          <ExpansionPanelSummary
            expandIcon={
              <i className="fas fa-chevron-down" style={{ fontSize: 14 }} />
            }
          >
            <Typography className={classes.heading}>
              <Translate id="Group@addConnectedToAnotherGroupDevicesTitle">
                Dispositivos conectados a outros grupos
              </Translate>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.devicesContainer}>
            <GridContainer justify="center">
              <GridItem xs={12} style={{ textAlign: "center" }}>
                <p>
                  <i className="fa fa-exclamation-triangle" />{" "}
                  <Translate id="Group@addConnectedToAnotherGroupDevicesMessage">
                    Dispositivos selecionados nesta lista serão desconectados do
                    grupo atual.
                  </Translate>
                </p>
              </GridItem>
              <GridItem xs={12} style={{ textAlign: "center" }}>
                <DeviceSelectorInput
                  name="connectedDevices"
                  selectedTokens={[]}
                  ignoredTokens={this.props.group.terminalsList.map(
                    ({ token }) => token
                  )}
                  didSelect={this.didSelectConnectedDevices}
                  isMultiple
                />
              </GridItem>
            </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <GridContainer justify="space-between" style={{ marginTop: 20 }}>
          <GridItem xs>
            <Button color="primary" onClick={this.props.didCancel}>
              <ChevronLeftIcon /> <CancelString />
            </Button>
          </GridItem>
          <GridItem xs style={{ textAlign: "right" }}>
            <Button
              color="primary"
              disabled={selectedDevicesList.length < 1}
              outline={selectedDevicesList.length < 1}
              onClick={this.didAddDevices}
            >
              <Plural
                // @ts-ignore
                id="Group@addDevicesSubmitButtonLabel"
                value={selectedDevicesList.length}
                zero="Selecione dispositivos..."
                one="Adicionar dispositivo selecionado"
                other="Adicionar # dispositivos selecionados"
              />{" "}
              {selectedDevicesList.length > 0 && <CheckIcon />}
            </Button>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(AddDeviceToGroupContainer);
