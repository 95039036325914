// @ts-nocheck


import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  bigCounterContainer: { margin: 30 },
  bigCounterNumber: {
    display: "block",
    fontSize: "3em",
    textAlign: "center",
    marginBottom: 16,
  },
  bigCounterLabel: {
    fontSize: "1.2em",
    textAlign: "center",
  },
};

const BigCounter = ({
  classes,
  value,
  label,
  customLabel,
  containerStyle,
  style,
}) => (
  <div className={classes.bigCounterContainer} style={{ containerStyle }}>
    <p className={classes.bigCounterNumber} style={style}>
      {value}
    </p>
    {!customLabel && label && (
      <p className={classes.bigCounterLabel}>{label}</p>
    )}
    {customLabel && customLabel(classes.bigCounterLabel)}
  </div>
);

export default withStyles(styles)(BigCounter);
