import { observable, action } from "mobx";
class UserModel {
  name: string;
  email: string;
  token: string;
  language: string;
  metric: string;
  temperature: string;
  newsletter: boolean;
  timezone: string;
  isActive: boolean;
  createdAt: string;
  level: string;
  @observable webPreferences: Object;

  constructor(user: any) {
    this.name = user.name;
    this.email = user.username;
    this.token = user.token;
    this.language = user.locale_language;
    this.metric = user.locale_metric;
    this.temperature = user.locale_temperature;
    this.newsletter = user.newsletter === "True";
    this.timezone = user.locale_timezone;
    this.isActive = user.status === "True";
    this.level = user.level ?? "default";
    this.createdAt = user.created_at;

    try {
      this.setWebPreferences(JSON.parse(user.web_preferences));
    } catch (error) {
      this.setWebPreferences({});
    }
  }

  @action setWebPreferences = (newValue: Object) => {
    this.webPreferences = newValue;
  };

  get isSimplyAsEmployee(): boolean {
    return !!this.email?.includes("@simplyas.com");
  }
  get isAdmin(): boolean {
    return this.level === "admin";
  }
  get isSupportOperator(): boolean {
    return this.level === "support_operator";
  }
}

export default UserModel;
