// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import DragDrop from "@uppy/react/lib/DragDrop";
import StatusBar from "@uppy/react/lib/StatusBar";

import { withLocalized } from "../HOC";

import {
  transloaditAuthKey,
  templateIdLogo,
  templateIdLogoConnectivity,
} from "../../services/TransloaditService";

import { GridContainer, GridItem } from "../Grid";

import LoadingView from "../LoadingView/LoadingView";

// Utils
import { getTomorrowMoment } from "../../utils/DateUtils";

const getTransloaditExpireTime = () => {
  const tomorrow = getTomorrowMoment();

  return `${tomorrow.format("YYYY/MM/DD HH:mm:ss")}+00:00`;
};

@withLocalized
@inject("contractStore", "i18nStore", "notificationsStore")
@observer
class TransloaditUppyFileUpload extends Component {
  state = { uppy: null };

  componentDidUpdate(prevProps) {
    if (
      prevProps.templateIdFiles !== this.props.templateIdFiles ||
      prevProps.mediaDuration !== this.props.mediaDuration
    ) {
      this.resetUppy();
    }
  }

  componentDidMount() {
    this.setUppy();
  }

  componentWillUnmount() {
    this.closeUppy();
  }

  resetUppy = () => {
    this.closeUppy();

    this.setState(
      {
        uppy: null,
      },
      () => {
        this.setUppy();
      }
    );
  };

  changeIsUploadingState = (newState) => {
    if (this.props.changeIsUploadingState) {
      this.props.changeIsUploadingState(newState);
    }
  };

  closeUppy = () => {
    if (this.state.uppy) {
      this.state.uppy.close();
    }
  };

  setUppy = () => {
    const locale =
      this.props.i18nStore.currentLanguage === "pt"
        ? {
            strings: {
              creatingAssembly: "Preparando para enviar...",
              preparingUpload: "Preparando para enviar...",
              youCanOnlyUploadX: {
                0: "Você pode enviar %{smart_count} arquivo",
                1: "Você pode enviar até %{smart_count} arquivos",
              },
              youHaveToAtLeastSelectX: {
                0: "Você precisa enviar pelo menos %{smart_count} arquivo",
                1: "Você precisa enviar pelo menos %{smart_count} arquivos",
              },
              exceedsSize: "Esse arquivo excede o tamanho máximo permitido de",
              youCanOnlyUploadFileTypes:
                "Você pode enviar os seguintes tipos de arquivos: %{types}",
              uppyServerError:
                "Erro ao comunicar com o servidor. Por favor tente novamente.",
            },
          }
        : null;
    const {
      contractStore,
      uploadType,
      maxNumberOfFiles,
      minNumberOfFiles,
      mediaDuration,
      allowedFileTypes,
    } = this.props;
    const { selectedContract } = contractStore;
    const uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: this.maxSizeForUploadType(uploadType),
        maxNumberOfFiles: maxNumberOfFiles,
        minNumberOfFiles: minNumberOfFiles,
        allowedFileTypes: allowedFileTypes,
      },
      locale,
    })
      .use(Transloadit, {
        params: {
          auth: {
            expires: getTransloaditExpireTime(),
            max_size: this.maxSizeForUploadType(uploadType),
            key: transloaditAuthKey,
          },
          template_id: this.templateIdForUploadType(uploadType),
        },
        waitForEncoding: true,
        waitForMetadata: true,
        alwaysRunAssembly: false,
        fields: {
          host: selectedContract.fileServer,
          path: selectedContract.path,
          error_msg: this.errorMsgMetaForUploadType(uploadType),
          duration: mediaDuration,
          frame: mediaDuration * 25 - 10,
          audio: mediaDuration - 2,
        },
      })
      .on("upload-progress", (file, progress) => {
        if (this.props.uploadProgressDidChange) {
          this.props.uploadProgressDidChange(file, progress);
        }
      })
      .on("upload", (data) => {
        this.changeIsUploadingState(true);
      })
      .on("cancel-all", () => {
        this.changeIsUploadingState(false);
      })
      .on("error", (error) => {
        this.changeIsUploadingState(false);
        window.debugError("error uploading file", error);
      })
      .on("info-visible", () => {
        const info = uppy.getState().info;
        if (info.type === "error") {
          this.props.notificationsStore.addSnackbarNotification({
            color: "warning",
            message: info.message,
          });
        }
      })
      .on("complete", (result) => {
        this.changeIsUploadingState(false);
        let files = [];
        const resultTransloadit =
          (result && result.transloadit && result.transloadit[0]) || null;
        if (resultTransloadit && resultTransloadit.uploads) {
          const uploads = resultTransloadit.uploads;

          uploads.forEach((upload) => {
            files.push({
              name: upload.basename.replace(/[^a-z0-9]/gi, "_").toLowerCase(),
              fileHash: upload.md5hash,
              duration: this.props.mediaDuration || undefined,
              url: upload.url,
            });
          });
        }
        this.props.didChangeFiles(files);

        if (this.props.resetAfterUpload) {
          this.resetUppy();
        }
      });

    this.setState({
      uppy,
    });
  };

  templateIdForUploadType = (uploadType) => {
    switch (uploadType) {
      case "mediaFiles":
        return this.props.templateIdFiles;

      case "connectivityLogo":
        return templateIdLogoConnectivity;

      case "logotype":
      default:
        return templateIdLogo;
    }
  };

  errorMsgMetaForUploadType = (uploadType) => {
    switch (uploadType) {
      case "mediaFiles":
        return this.props.localizedString(
          {
            id: "Upload@mediaFilesLabel",
            defaultString:
              " Aceitamos arquivos de vídeo ou imagem com até $maxFileSize.",
          },
          {
            maxFileSize: "200MB",
          }
        );

      case "connectivityLogo":
      case "logotype":
      default:
        return this.props.localizedString({
          id: "Upload@1mbImagesLabel",
          defaultString: "Aceitamos arquivos de imagem com até 1MB.",
        });
    }
  };

  maxSizeForUploadType = (uploadType) => {
    switch (uploadType) {
      case "mediaFiles":
        return 209715200;

      case "connectivityLogo":
      case "logotype":
      default:
        return 1000000;
    }
  };

  getStatusBarLocale = () => {
    if (this.props.i18nStore.currentLanguage === "pt") {
      return {
        strings: {
          // Shown in the status bar while files are being uploaded.
          uploading: "Enviando...",
          // Shown in the status bar once all files have been uploaded.
          complete: "Terminou!",
          // Shown in the status bar if an upload failed.
          uploadFailed: "Falha ao enviar",
          // Shown next to `uploadFailed`.
          pleasePressRetry: "Pressione repetir para tentar novamente",
          // Shown in the status bar while the upload is paused.
          paused: "Pausado",
          error: "Erro",
          // Used as the label for the button that retries an upload.
          retry: "Repetir",
          // Used as the label for the button that cancels an upload.
          cancel: "Cancelar",
          // Used as the screen reader label for the button that retries an upload.
          retryUpload: "Repetir upload",
          // Used as the screen reader label for the button that pauses an upload.
          pauseUpload: "Pausar upload",
          // Used as the screen reader label for the button that resumes a paused upload.
          resumeUpload: "Continuar upload",
          // Used as the screen reader label for the button that cancels an upload.
          cancelUpload: "Cancelar upload",
          // When `showProgressDetails` is set, shows the number of files that have been fully uploaded so far.
          filesUploadedOfTotal: {
            0: "%{complete} de %{smart_count} arquivo enviado",
            1: "%{complete} de %{smart_count} arquivos enviados",
          },
          // When `showProgressDetails` is set, shows the amount of bytes that have been uploaded so far.
          dataUploadedOfTotal: "%{complete} de %{total}",
          // When `showProgressDetails` is set, shows an estimation of how long the upload will take to complete.
          xTimeLeft: "%{time} restante",
          preparingUpload: "Preparando para enviar...",
          // Used as the label for the button that starts an upload.
          uploadXFiles: {
            0: "Upload %{smart_count} arquivo",
            1: "Upload %{smart_count} arquivos",
          },
          // Used as the label for the button that starts an upload, if another upload has been started in the past
          // and new files were added later.
          uploadXNewFiles: {
            0: "Upload +%{smart_count} arquivo",
            1: "Upload +%{smart_count} arquivos",
          },
        },
      };
    }

    return null;
  };

  render() {
    const {
      label,
      uploadType,
      localizedString,
      dropHereText,
      browseText,
      customNote,
    } = this.props;
    const note = customNote || this.errorMsgMetaForUploadType(uploadType);

    const localeStrings = {
      dropHereOr:
        dropHereText ||
        localizedString({
          id: "Upload@dragAndDropMessage",
          defaultString: "Arraste e solte o(s) arquivo(s) aqui ou %{browse}",
        }),
      browse:
        browseText ||
        localizedString({
          id: "Upload@addFromDeviceMessage",
          defaultString: "adicione de seu dispositivo",
        }),
    };

    if (!this.state.uppy) {
      return <LoadingView />;
    }

    return (
      <GridContainer style={{ marginTop: label ? 30 : 0 }}>
        <GridItem xs={12}>
          {label && (
            <label
              style={{
                fontSize: 13,
                fontWeight: 500,
                lineHeight: 1.42857,
                textTransform: "uppercase",
              }}
            >
              {label}
            </label>
          )}
          {!this.props.hideDragDropContainer && (
            <DragDrop
              hidePauseResumeCancelButtons
              uppy={this.state.uppy}
              note={note}
              height={this.props.height}
              width={this.props.width}
              locale={{
                strings: localeStrings,
              }}
            />
          )}
          {!this.props.hideStatusBar && (
            <StatusBar
              uppy={this.state.uppy}
              hideUploadButton={true}
              hideAfterFinish={false}
              hidePauseResumeButton={true}
              showProgressDetails
              locale={this.getStatusBarLocale()}
            />
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

TransloaditUppyFileUpload.defaultProps = {
  height: "100%",
  width: "100%",
  allowedFileTypes: null,
};

export default TransloaditUppyFileUpload;
