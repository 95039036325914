//@ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { SimpleCard } from "../../components/Card";
import { GridContainer, GridItem } from "../../components/Grid";
import { InputSelect } from "../../components/Form";
import DevicesTableView from "../../components/Devices/DevicesTableView";
import DevicesSearchBar from "../../components/Devices/DevicesSearchBar";
import UpdateDevicesProgressCounter from "../../components/Devices/UpdateDevicesProgressCounter";
import ModalTerminalDetails from "../../components/Modal/ModalTerminalDetails";
import InfoLabel from "../../components/Label/InfoLabel";
import Plural from "../../components/I18n/Plural";

import I18nStore from "../../store/I18nStore/I18nStore";
import TerminalsStore from "../../store/TerminalsStore/TerminalsStore";
import {
  ETerminalStatus,
  ETerminalConnectionStatus,
  ETerminalAlertType,
} from "../../types/Terminal";
import TerminalModel from "../../Model/TerminalModel";

interface IAdminDevicesGlobalSearchContainerProps {}

interface IAdminDevicesGlobalSearchContainerState {
  isOpenModal: boolean;
  selectedTerminal: TerminalModel | null;
}

interface InjectedProps extends IAdminDevicesGlobalSearchContainerProps {
  i18nStore: I18nStore;
  terminalsStore: TerminalsStore;
}

@inject("i18nStore", "terminalsStore")
@observer
class AdminDevicesGlobalSearchContainer extends Component<
  IAdminDevicesGlobalSearchContainerProps,
  IAdminDevicesGlobalSearchContainerState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  statusOptions: Array<{ label: any; value: ETerminalStatus }>;
  connectionStatusOptions: Array<{
    label: any;
    value: ETerminalConnectionStatus;
  }>;
  alertOptions: Array<{
    label: any;
    value: ETerminalAlertType;
  }>;

  constructor(props: IAdminDevicesGlobalSearchContainerProps) {
    super(props);

    this.state = {
      selectedTerminal: null,
      isOpenModal: false,
    };

    const { localizedString } = this.injected.i18nStore;

    this.statusOptions = [
      {
        label: localizedString({ id: "Common@all", defaultString: "Todos" }),
        value: ETerminalStatus.all,
      },
      {
        label: localizedString({
          id: "Device@enabledDevicesFilterLabel",
          defaultString: "Habilitados",
        }),
        value: ETerminalStatus.enabled,
      },
      {
        label: localizedString({
          id: "Device@disabledDevicesFilterLabel",
          defaultString: "Desabilitados",
        }),
        value: ETerminalStatus.disabled,
      },
    ];
    this.connectionStatusOptions = [
      {
        label: localizedString({ id: "Common@all", defaultString: "Todos" }),
        value: ETerminalConnectionStatus.all,
      },
      {
        label: localizedString({
          id: "Dashboard@connectedDevicesLabel",
          defaultString: "Ligados",
        }),
        value: ETerminalConnectionStatus.online,
      },
      {
        label: localizedString({
          id: "Dashboard@offlineDevicesLabel",
          defaultString: "Desligados",
        }),
        value: ETerminalConnectionStatus.offline,
      },
    ];

    this.alertOptions = [
      {
        label: localizedString({
          id: "Device@criticalAlertOption",
          defaultString: "CRÍTICO",
        }),
        value: ETerminalAlertType.critical,
      },
      {
        label: localizedString({
          id: "Device@powerSupplyInfo",
          defaultString: "Energia",
        }),
        value: ETerminalAlertType.energy,
      },
      {
        label: localizedString({
          id: "Common@temperature",
          defaultString: "Temperatura",
        }),
        value: ETerminalAlertType.temperature,
      },
      {
        label: localizedString({
          id: "Device@diskSpeedInfo",
          defaultString: "Velocidade do disco",
        }),
        value: ETerminalAlertType.diskSpeed,
      },
      {
        label: "Wi-Fi",
        value: ETerminalAlertType.wifi,
      },
    ];
  }

  componentDidMount() {
    const { terminalsStore } = this.injected;

    terminalsStore.subscribe(this);

    if (terminalsStore.terminalsList.length < 1) {
      terminalsStore.getTerminalsList(true);
    }
  }

  componentWillUnmount() {
    this.injected.terminalsStore.unsubscribe(this);
  }

  refreshTerminalsList = () =>
    this.injected.terminalsStore.getTerminalsListWithoutAnimation();

  getFilterByStatusLabel = (value: ETerminalStatus) => {
    return this.statusOptions.find(($0) => $0.value === value);
  };
  getFilterByConnectionStatusLabel = (value: ETerminalConnectionStatus) => {
    return this.connectionStatusOptions.find(($0) => $0.value === value);
  };
  getFilterByAlertsLabel = (values: ETerminalAlertType[]) => {
    return this.alertOptions.filter(($0) => values.includes($0.value));
  };

  didSelectDevice = (terminalToken: string) => () => {
    const terminal = this.injected.terminalsStore.getTerminalFromListWithToken(
      terminalToken
    );
    this.setState({
      selectedTerminal: terminal,
      isOpenModal: true,
    });
  };

  didCloseTerminalModal = () => {
    this.setState(
      {
        isOpenModal: false,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              selectedTerminal: null,
            }),
          200
        )
    );
  };

  render() {
    const { localizedString } = this.injected.i18nStore;
    const { terminalsStore } = this.injected;
    const { isOpenModal, selectedTerminal } = this.state;

    const {
      isFetching,
      terminalsList: devicesList,
      isFetchingPaginate,
      devicesPerPage,
      currentPage,
      totalDevices,
      onChangePaginate,
      onChangeOrderBy,
      sortedBy,
    } = terminalsStore;

    return (
      <GridContainer>
        {selectedTerminal && (
          <ModalTerminalDetails
            // @ts-ignore
            isOpen={isOpenModal}
            didCloseModal={this.didCloseTerminalModal}
            terminal={selectedTerminal}
          />
        )}
        <GridItem xs={12} sm>
          <SimpleCard cardStyle={{ marginTop: 0 }}>
            <GridContainer>
              <GridItem xs={12} sm>
                <DevicesSearchBar />
              </GridItem>
              <GridItem xs={12} sm>
                <InputSelect
                  placeholder={localizedString({
                    id: "DevicesPage@filterByConnectionStatusLabel",
                    defaultString: "Filtre por status de conexão",
                  })}
                  name="connectionStatus"
                  value={this.getFilterByConnectionStatusLabel(
                    terminalsStore.filterByConnectionStatus
                  )}
                  options={this.connectionStatusOptions}
                  onChange={(_, status) =>
                    terminalsStore.setFilterByConnectionStatus(status.value)
                  }
                  withPortal
                  formControlStyles={{ margin: 0, padding: 0 }}
                />
              </GridItem>
              <GridItem xs={12} sm>
                <InputSelect
                  placeholder={localizedString({
                    id: "DevicesPage@filterByStatusLabel",
                    defaultString: "Filtre por status",
                  })}
                  name="status"
                  value={this.getFilterByStatusLabel(
                    terminalsStore.filterByStatus
                  )}
                  options={this.statusOptions}
                  onChange={(_, status) =>
                    terminalsStore.setFilterByStatus(status.value)
                  }
                  withPortal
                  formControlStyles={{ margin: 0, padding: 0 }}
                />
              </GridItem>
              <GridItem xs={12} sm>
                <InputSelect
                  placeholder={localizedString({
                    id: "DevicesPage@filterByAlertsLabel",
                    defaultString: "Filtre por alertas",
                  })}
                  name="alerts"
                  value={this.getFilterByAlertsLabel(
                    terminalsStore.filterByAlerts
                  )}
                  options={this.alertOptions}
                  multiple
                  onChange={(_, alerts) =>
                    terminalsStore.setFilterByAlerts(
                      alerts ? alerts.map(({ value }) => value) : []
                    )
                  }
                  withPortal
                  formControlStyles={{ margin: 0, padding: 0 }}
                />
              </GridItem>
            </GridContainer>
          </SimpleCard>
          <Fragment>
            {isFetching ? (
              <div style={{ height: 14, display: "block" }}>&nbsp;</div>
            ) : (
              <GridItem
                xs={12}
                style={{
                  marginBottom: 10,
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                }}
              >
                <UpdateDevicesProgressCounter
                  // @ts-ignore
                  callback={this.refreshTerminalsList}
                />
              </GridItem>
            )}
          </Fragment>
        </GridItem>
        <GridItem xs={12}>
          <SimpleCard cardStyle={{ marginTop: 0 }}>
            <DevicesTableView
              showGroupColumn
              currentUserCanSupport
              isAdminView
              isFetching={isFetching}
              isFetchingPaginate={isFetchingPaginate}
              devicesPerPage={devicesPerPage}
              currentPage={currentPage}
              totalDevices={totalDevices}
              onChangePaginate={onChangePaginate}
              devicesList={devicesList}
              didSelect={this.didSelectDevice}
              sortedBy={sortedBy}
              onSortedChange={onChangeOrderBy}
            />
          </SimpleCard>
        </GridItem>
        {!isFetching && totalDevices > 5 && (
          <GridItem xs={12} style={{ textAlign: "center", marginTop: 30 }}>
            <InfoLabel hideIcon>
              <Plural
                // @ts-ignore
                id="DevicesPage@devicesQtyLabel"
                value={totalDevices}
                zero="Nenhum dispositivo encontrado"
                one="# dispositivo"
                other="# dispositivos"
              />
            </InfoLabel>
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

export default AdminDevicesGlobalSearchContainer;
