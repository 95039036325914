import { FunctionComponent } from "react";
import { inject } from "mobx-react";
import classnames from "classnames";
import moment from "moment";

import { makeStyles } from "@material-ui/styles";

import Tooltip from "../../Tooltip/Tooltip";
import I18nStore from "../../../store/I18nStore/I18nStore";

const useStyles = makeStyles({
  timestamp: {
    fontSize: 12,
    fontWeight: 300,
  },
  userTimestamp: {
    textAlign: "right",
  },
});

interface MessageTimestampProps {
  i18nStore?: I18nStore;
  timestamp: number;
  isMineMessage: boolean;
  adminName?: string;
}

const MessageTimestamp: FunctionComponent<MessageTimestampProps> = ({
  i18nStore,
  timestamp,
  isMineMessage,
  adminName,
}) => {
  const classes = useStyles();
  return (
    <p
      className={classnames({
        [classes.timestamp]: true,
        [classes.userTimestamp]: isMineMessage,
      })}
    >
      {adminName && (
        // @ts-ignore
        <Tooltip title={adminName}>
          <span style={{ marginRight: 8 }}>
            <i className="fal fa-user" />
          </span>
        </Tooltip>
      )}
      {moment(timestamp).format(
        `${i18nStore.dateFormatWithTwoDigitsYear} ${i18nStore.timeFormat}`
      )}
    </p>
  );
};

export default inject("i18nStore")(MessageTimestamp);
