import { MediaTypeEnum } from "../store/MediaLibraryStore/MediaLibraryStore";

// Model
import UploadMediaModel from "./UploadMediaModel";
import StreamingMediaModel from "./StreamingMediaModel";
import ContentMediaModel from "./ContentMediaModel";
import ExtrasMediaModel from "./ExtrasMediaModel";

export type ModboxArea = string;
type MediaUnion = UploadMediaModel | StreamingMediaModel | ContentMediaModel | ExtrasMediaModel;

class PlaylistModel {
  label: string;
  token: string;
  isRandom: boolean;
  isLooping: boolean;
  isMuted: boolean;
  durationToLiveContent: number;
  isVertical: boolean;
  isVideoWall: boolean;
  ledArea: ModboxArea;
  videoWallScreensCount: number;
  mediasList: MediaUnion[];
  screenBrightness: string;

  constructor(playlist, modboxFileService) {
    let mediasList = [];
    playlist.files.forEach((fileData) => {
      let newMedia = null;

      if (fileData.media_type === MediaTypeEnum.UPLOAD) {
        newMedia = new UploadMediaModel(fileData, modboxFileService);
      } else if (fileData.media_type === MediaTypeEnum.STREAMING) {
        newMedia = new StreamingMediaModel(fileData);
      } else if (fileData.media_type === MediaTypeEnum.CONTENT) {
        newMedia = new ContentMediaModel(fileData);
      } else if (fileData.media_type === MediaTypeEnum.EXTRAS) {
        newMedia = new ExtrasMediaModel(fileData);
      }

      mediasList.push(newMedia);
    });

    this.label = playlist.label;
    this.token = playlist.playlist_token;
    this.isRandom = playlist.random === "True";
    this.isLooping = playlist.looping === "True";
    this.isMuted = playlist.mute === "True";
    this.durationToLiveContent = parseInt(playlist.durationToLiveContent) || 0;
    this.isVertical = playlist.orientation === "90";
    this.isVideoWall = playlist.videowall > 0;
    this.ledArea = playlist.led_area || playlist.ledArea || null;
    this.videoWallScreensCount = playlist.videowall
      ? parseInt(playlist.videowall, 10)
      : 0;
    this.mediasList = mediasList;
    this.screenBrightness = playlist.alpha || playlist.screenBrightness || "100";
  }
}

export default PlaylistModel;
