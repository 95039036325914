// @ts-nocheck
import cloneDeep from "lodash/cloneDeep";
import { inject } from "mobx-react";
import { Component, Fragment, createElement, isValidElement } from "react";

import { isProductionServer } from "../../utils/EnvUtils";

const pluralValueToSearch = (
  isPlural: boolean,
  pluralValue: number
): string | null =>
  isPlural
    ? pluralValue === 0
      ? "zero"
      : pluralValue === 1
      ? "one"
      : "other"
    : null;

export const textAndComponentsToTranslators = (children) => {
  if (!children || children.length < 0) return { text: null, components: [] };

  let resultString =
    typeof children === "string" || children instanceof String ? children : "";

  let components = [];
  if (children instanceof Array) {
    children.forEach((element: any) => {
      if (isValidElement(element)) {
        resultString += `<${components.length}>${element.props.children}</${components.length}>`;

        components.push(element);
      } else {
        resultString += element;
      }
    });
  }

  return {
    text: resultString,
    components,
  };
};

interface ITranslateProps {
  id: string;
  children: any;
  isPlural?: boolean;
  pluralValue?: any;
}

interface InjectedProps extends ITranslateProps {
  i18nStore: I18nStore;
}

@inject("i18nStore")
class Translate extends Component<ITranslateProps> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  fatalError = (error) => {
    // TODO: Registrar error no log.
    return isProductionServer ? null : `${this.props.id}_ERROR`;
  };

  translateChildren = () => {
    const { i18nStore } = this.injected;
    const { children, isPlural, pluralValue } = this.props;
    let translatedString = i18nStore.getTranslatedStringFromDatabase(
      this.props.id,
      pluralValueToSearch(isPlural, pluralValue)
    );

    const {
      text: stringToTranslators,
      components: kComponents,
    } = textAndComponentsToTranslators(children);

    let components = cloneDeep(kComponents);

    if (!translatedString) {
      translatedString = stringToTranslators;
    }

    if (!(children instanceof Array)) {
      return i18nStore.transformVariablesAndPlural(
        translatedString,
        this.props.variables,
        pluralValue
      );
    }

    translatedString = i18nStore.transformVariablesAndPlural(
      translatedString,
      this.props.variables,
      pluralValue
    );

    const replaceRegexPattern = /<[0-9]*>.*?<\/[0-9]*>/gi;
    // First we prepare the resultString
    let resultString = translatedString.replace(replaceRegexPattern, "$node");

    try {
      // Now we prepare the component
      components = components.map((component) => {
        let componentRegexResult = replaceRegexPattern.exec(translatedString);

        // Returning a new translated component
        return createElement(component.type, {
          ...component.props,
          children: componentRegexResult[0]
            .replace(/<[0-9]*>/g, "") // to remove <0>
            .replace(/<\/[0-9]*>/g, ""), // to remove </0>
        });
      });
    } catch (error) {
      return this.fatalError(error);
    }

    try {
      const testArray = resultString.split(/(\$node)/g);

      let componentOrderCount = 0;
      return createElement(
        "span",
        null,
        testArray.map((textString, index) => {
          if (textString === "$node") {
            const component = components[componentOrderCount++];
            return <Fragment key={index}>{component}</Fragment>;
          }
          return <Fragment key={index}>{textString}</Fragment>;
        })
      );
    } catch (error) {
      return this.fatalError(error);
    }
  };

  render() {
    return this.translateChildren();
  }
}
export default Translate;
