// @ts-nocheck

import { observer } from "mobx-react";

import { withLocalized } from "../HOC";
import { GridContainer, GridItem } from "../Grid";
import BarChart from "./BarChart";
import PieChart from "./PieChart";

import { primaryColor } from "../../assets/jss/app-js-styles";

const GenderAndAgeGroupChart = observer(({ localizedString, chartData }) => {
  const valuesPerAge = chartData.per_age;

  let maleValues = [];
  let femaleValues = [];

  const total = chartData.total.male + chartData.total.female;
  const totalMalePercent = (chartData.total.male * 100) / total;
  const totalFemalePercent = (chartData.total.female * 100) / total;

  if (valuesPerAge) {
    Object.values(valuesPerAge).forEach(($0) => {
      const malePercent = ($0.male * 100) / total;
      const femalePercent = ($0.female * 100) / total;

      maleValues.push(-Math.abs(malePercent).toFixed(1));
      femaleValues.push(femalePercent.toFixed(1));
    });
  }

  const colors = [primaryColor, "#EEACEA"];

  const chartColors = {
    fill: {
      colors: colors,
    },
    markers: {
      colors: colors,
    },
    colors: colors,
  };

  return (
    <GridContainer alignItems="center">
      <GridItem xs={12} md={6}>
        <PieChart
          legend={{
            position: "bottom",
            horizontalAlign: "center",
            fontSize: 16,
          }}
          height={260}
          customOptions={{
            ...chartColors,
            dataLabels: {
              formatter: (value) => `${value.toFixed(1)}%`,
            },
          }}
          customTooltip={{
            enabled: false,
          }}
          data={[
            {
              name: localizedString({
                id: "Common@male",
                defaultString: "Masculino",
              }),
              y: totalMalePercent,
            },
            {
              name: localizedString({
                id: "Common@female",
                defaultString: "Feminino",
              }),
              y: totalFemalePercent,
            },
          ]}
        />
      </GridItem>
      {(maleValues.length > 0 || femaleValues.length > 0) && (
        <GridItem xs={12} md={6}>
          <BarChart
            horizontal
            stacked
            showDataLabels
            height={260}
            customOptions={{
              ...chartColors,
              title: {
                text: localizedString({
                  id: "Metrics@ageGroupTitle",
                  defaultString: "Faixa etária",
                }),
                align: "center",
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeigth: "80%",
                },
              },
              grid: {
                xaxis: {
                  showLines: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: Object.keys(valuesPerAge).map((ageGroupString) =>
                  localizedString({
                    id: `Metrics@ageGroup_${ageGroupString}`,
                    defaultString: ageGroupString,
                  })
                ),
                labels: {
                  formatter: (val) => `${Math.abs(Math.round(val))}%`,
                },
              },
            }}
            customTooltip={{
              shared: true,
              fixed: {
                enabled: true,
              },
              x: {
                formatter: (val) =>
                  localizedString(
                    {
                      id: "Metrics@ageGroupTooltip",
                      defaultString: "Faixa etária $ageGroup",
                    },
                    { ageGroup: val }
                  ),
              },
              y: {
                formatter: (val) => `${Math.abs(val)}%`,
              },
            }}
            data={[
              {
                name: localizedString({
                  id: "Common@male",
                  defaultString: "Masculino",
                }),
                data: maleValues,
              },
              {
                name: localizedString({
                  id: "Common@female",
                  defaultString: "Feminino",
                }),
                data: femaleValues,
              },
            ]}
          />
        </GridItem>
      )}
    </GridContainer>
  );
});

export default withLocalized(GenderAndAgeGroupChart);
