// @ts-nocheck

import { inject, observer } from "mobx-react";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";

import LineChart from "../../components/Charts/LineChart";

import { withLocalized } from "../../components/HOC";
import { GridContainer, GridItem } from "../../components/Grid";

// Translate
import Translate from "../../components/I18n/Translate";

// Style
import { primaryColor, grayColor } from "../../assets/jss/app-js-styles";
const styles = {
  statsTitle: {
    color: grayColor,
    textAlign: "center",
  },
  statsLabel: {
    color: primaryColor,
    textAlign: "center",
    fontSize: "1.4em",
    fontWeight: "bold",
  },
};

const AccessByGroupPerDaysChartWidget = inject("i18nStore")(
  observer(({ classes, i18nStore, chartData }) => {
    if (!chartData) return null;
    const accessByGroup = chartData.accessByGroup;

    const formatDate = (date) =>
      moment(date, "MM/DD/YYYY").format(i18nStore.dateFormatWithFourDigitsYear);

    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <LineChart
              height={400}
              xAxisType="datetime"
              xAxisCategories={Object.keys(
                Object.values(accessByGroup)[0].per_days
              )}
              data={Object.keys(accessByGroup).map((groupName) => {
                const data = accessByGroup[groupName];
                return {
                  name: groupName,
                  data: Object.values(data.per_days),
                };
              })}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" style={{ marginTop: 30 }}>
          <GridItem xs={6} md={4}>
            <p className={classes.statsTitle}>
              <Translate id="Metrics@wifiBusiestDayLabel">
                Dia mais movimentado
              </Translate>
            </p>
            <p className={classes.statsLabel}>
              {formatDate(chartData.wifiBusiestDay)}
            </p>
          </GridItem>
          <GridItem xs={6} md={4}>
            <p className={classes.statsTitle}>
              <Translate id="Metrics@wifiLessBusyDayLabel">
                Dia menos movimentado
              </Translate>
            </p>
            <p className={classes.statsLabel} style={{ color: grayColor }}>
              {formatDate(chartData.wifiLessBusyDay)}
            </p>
          </GridItem>
        </GridContainer>
      </>
    );
  })
);

export default withLocalized(
  withStyles(styles)(AccessByGroupPerDaysChartWidget)
);
