// @ts-nocheck
import { useMemo, useState } from "react";
import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";
import { WizardUploadActionsBox, WizardUploadBox } from "./style";
import WizardUploadOrientation from "./steps/orientation";
import WizardUploadVideoWall from "./steps/videowall";
import WizardUploadLed from "./steps/led";
import WizardUploadFade from "./steps/fade";
import WizardUploadTag from "./steps/tag";
import WizardUploadUP from "./steps/upload";
import { WizardUploadTags } from "./type";
import WizardUploadMediaType from "./steps/mediaType";

type StepsString =
  | "orientation"
  | "videowall"
  | "led"
  | "mediaType"
  | "fade"
  | "tag"
  | "upload";

type WizardUploadProps = {
  didChangeFilesUpload: (files: File[]) => void;
  changeIsUploadingState: (value: boolean) => void;
  updateVideowall: (value: boolean) => void;
  isUploading: boolean;
  ledSupport: boolean;
  videowallSupport: boolean;
} & WizardUploadTags;

const WizardUpload = (props: WizardUploadProps) => {
  const [payload, setPayload] = useState<Payload>({ secondDuration: 10 });
  const [step, setStep] = useState<StepsString>("orientation");
  const [active, setActive] = useState(false);
  const { ledSupport, videowallSupport } = props;

  function updateRadio(field, value) {
    if (field === "isVideowall") {
      props.updateVideowall(value === "true");
    }

    const newPayload = {
      ...payload,
      [field]: field === "secondDuration" ? value : value === "true",
    };

    const validatePayload = {
      ...newPayload,
      isLed: !newPayload?.isVideowall ? newPayload?.isLed : undefined,
      isImage:
        !newPayload?.isVideowall && !newPayload?.isLed
          ? newPayload?.isImage
          : undefined,
      isFade:
        !newPayload?.isVideowall && !newPayload?.isLed
          ? newPayload?.isFade
          : undefined,
    };

    setPayload(validatePayload);
  }

  const WComponent = useMemo(() => {
    switch (step) {
      case "videowall":
        return (
          <WizardUploadVideoWall payload={payload} updateRadio={updateRadio} />
        );
      case "led":
        return <WizardUploadLed payload={payload} updateRadio={updateRadio} />;
      case "mediaType":
        return (
          <WizardUploadMediaType payload={payload} updateRadio={updateRadio} />
        );
      case "fade":
        return <WizardUploadFade payload={payload} updateRadio={updateRadio} />;
      case "tag":
        return (
          <WizardUploadTag
            tagsList={props.tagsList}
            selectedTags={props.selectedTags}
            onChangeSelectedTags={props.onChangeSelectedTags}
            payload={payload}
          />
        );
      case "upload":
        return <WizardUploadUP {...props} payload={payload} />;
      default:
        return (
          <WizardUploadOrientation
            payload={payload}
            updateRadio={updateRadio}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, payload, props.selectedTags]);

  function prevStep() {
    if (step === "upload") {
      setStep("tag");
    } else if (step === "tag") {
      if (payload?.isFade !== undefined) {
        setStep("fade");
      } else if (payload?.isLed !== undefined) {
        setStep("led");
      } else {
        setStep("videowall");
      }
    } else if (step === "mediaType") {
      if (ledSupport) {
        setStep("led");
      } else if (videowallSupport) {
        setStep("videowall");
      } else {
        setStep("orientation");
      }
    } else if (step === "fade") {
      setStep("mediaType");
    } else if (step === "led") {
      if (videowallSupport) {
        setStep("videowall");
      } else {
        setStep("orientation");
      }
    } else {
      setStep("orientation");
    }
  }

  function nextStep() {
    if (step === "orientation") {
      if (videowallSupport) {
        setStep("videowall");
      } else if (ledSupport) {
        setStep("led");
      } else {
        setStep("mediaType");
      }
    } else if (step === "videowall") {
      if (!payload?.isVideowall) {
        if (ledSupport) {
          setStep("led");
        } else {
          setStep("mediaType");
        }
      } else {
        setStep("tag");
      }
    } else if (step === "led") {
      if (!payload?.isLed) {
        setStep("mediaType");
      } else {
        setStep("tag");
      }
    } else if (step === "mediaType") {
      setStep("fade");
    } else if (step === "fade") {
      setStep("tag");
    } else {
      setStep("upload");
    }
  }

  function nextActive(): boolean {
    if (step === "orientation" && payload?.isVertical !== undefined) {
      return true;
    }
    if (step === "videowall" && payload?.isVideowall !== undefined) {
      return true;
    }
    if (step === "led" && payload?.isLed !== undefined) {
      return true;
    }
    if (step === "mediaType" && payload?.isImage !== undefined) {
      return true;
    }
    if (step === "fade" && payload?.isFade !== undefined) {
      return true;
    }
    if (step === "tag") {
      return true;
    }
    return false;
  }

  return (
    <GridContainer style={{ width: "100%", margin: 0 }}>
      <GridItem xs={12}>
        <WizardUploadBox>{WComponent}</WizardUploadBox>
      </GridItem>
      <GridItem xs={12}>
        <WizardUploadActionsBox>
          {step !== "orientation" ? (
            <Button color="default" onClick={prevStep}>
              Anterior
            </Button>
          ) : (
            <div />
          )}
          {step !== "upload" ? (
            <Button color="primary" disabled={!nextActive()} onClick={nextStep}>
              Avançar
            </Button>
          ) : (
            <div />
          )}
        </WizardUploadActionsBox>
      </GridItem>
    </GridContainer>
  );
};

export default WizardUpload;
