// ##############################
// // // IconCard styles
// #############################

import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  cardTitle,
} from "../../app-js-styles";

const chartsStyle = {
  chartGrid: {
    marginBottom: 50,
  },
  chartCard: {
    position: "relative",
    height: "100%",
    margin: 0,
  },
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
  cardIconTitle: {
    ...cardTitle,
    fontSize: "1.2em",
    marginTop: 8,
    marginBottom: 4,
    marginRight: 20,
    fontWeight: 500,
  },
  cardIconSubtitle: {
    color: grayColor,
    fontSize: "1.0em",
    marginRight: 20,
    marginBottom: 0,
  },
  legendTitle: {
    color: grayColor,
  },
  primary: {
    color: primaryColor,
  },
  warning: {
    color: warningColor,
  },
  danger: {
    color: dangerColor,
  },
  success: {
    color: successColor,
  },
  info: {
    color: infoColor,
  },
  rose: {
    color: roseColor,
  },
  gray: {
    color: grayColor,
  },
  cardFooter: {
    display: "block",
  },
  usageLimitContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  usageLimitInnerContainer: {
    fontWeight: "bold",
    fontSize: "1.6em",
    margin: "16px 0",
    color: grayColor,
  },
  usage: {
    color: primaryColor,
    fontSize: "1.1em",
  },
  limit: {
    color: grayColor,
  },
};

export default chartsStyle;
