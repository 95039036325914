// @ts-nocheck

import { inject, observer } from "mobx-react";

import { stringDateFromUnixDate } from "../../utils/DateUtils";

const DateUnixString = inject("i18nStore")(
  observer(({ i18nStore, unixDate }) => (
    <span>
      {stringDateFromUnixDate(unixDate, i18nStore.dateFormatWithTwoDigitsYear)}
    </span>
  ))
);

export default DateUnixString;
