import * as React from "react";
import Box from "@material-ui/core/Box";
import Translate from "../../components/I18n/Translate";
import { InputSelect } from "../../components/Form";

export default function LocationGroupTabs({ groups, renderTerminal }) {
  const groupOptions = groups.map(({ name, token }) => ({
    label: name,
    value: token,
  }));
  
  const [value, setValue] = React.useState<{
    label: string;
    value: string;
  } | null>(groupOptions?.[0] || null);

  const handleChange = (name: string, value: any) => {
    setValue(value);
  };

  const selectedGroup =
    groups.find(({ token }) => token === value?.value) || groups[0];

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <InputSelect
          name="Grupo"
          options={groupOptions}
          value={value}
          onChange={handleChange}
          label="Grupo"
        />
      </Box>
      <Box sx={{ paddingTop: 8, paddingX: 1, alignContent: "center" }}>
        <Box>
          {selectedGroup?.terminalsList?.length > 0 ? (
            selectedGroup.terminalsList.map(renderTerminal)
          ) : (
            <Box sx={{ p: 3 }}>
              <Translate id="Location@groupTerminalsListEmpty">
                Não há terminais neste grupo.
              </Translate>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
