// @ts-nocheck

import { inject, observer } from "mobx-react";
import { Hidden } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { Button } from "../../components/Button";
import { GridContainer, GridItem } from "../../components/Grid";
import Tooltip from "../../components/Tooltip/Tooltip";
import SearchBar from "../../components/SearchBar/SearchBar";
import { SimpleCard } from "../../components/Card";
import UsageGridWidget from "../../components/Widgets/UsageGridWidget";

import ResourceRuleGroup from "./ResourceRuleGroup";

// Translate
import {
  AddString,
  EditString,
  DuplicateString,
} from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

//Style
import {
  successColor,
  dangerColor,
  infoColor,
} from "../../assets/jss/app-js-styles";
const styles = {
  sectionTitle: {
    marginTop: "0",
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  card: { margin: 0, marginBottom: 16 },
  container: {
    padding: "8px 16px",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid transparent",
  },
  isInactiveContainer: {
    borderColor: dangerColor,
  },
  titleContainer: {
    position: "relative",
  },
  title: {
    margin: 0,
    fontSize: "1.4em",
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
  },
  videoWallLabel: {
    backgroundColor: successColor,
    color: "#FFF",
    position: "absolute",
    top: -20,
    left: 15,
    padding: "0 4px",
    borderRadius: 6,
  },
  isInactivelabel: {
    backgroundColor: dangerColor,
    color: "#FFF",
    position: "absolute",
    top: -20,
    left: 15,
    padding: "0 4px",
    borderRadius: 6,
  },
  addButton: {
    marginBottom: 20,
  },
  nothingFound: {
    textAlign: "center",
    fontWeight: "bold",
  },
  groupName: {
    fontWeight: "bold",
    color: infoColor,
  },
};

const ResourcePageView = inject("resourcesStore")(
  observer(
    ({
      classes,
      resourcesStore,
      appMainStore,
      resourceRulesList,
      resourceType,
      didSelectAppResourceToEdit,
      didWantToCloneAppResource,
      didSelectResourceRuleToEdit,
      didSelectResourceRuleToConfigureVideoWall,
      didWantToAddAppResource,
      didWantToAddResourceRule,
      didReorderResourceRulesOfGroup,
    }) => (
      <GridContainer>
        {["playlist", "network"].includes(resourceType) && (
          <GridItem xs={12}>
            <SimpleCard
              cardStyle={{ marginTop: 0 }}
              cardBodyStyle={{ padding: 0 }}
            >
              <UsageGridWidget
                type={
                  resourceType === "playlist" ? "storage" : "wifiAuthentication"
                }
              />
            </SimpleCard>
          </GridItem>
        )}
        {(appMainStore.dataList.length > 5 ||
          resourcesStore.resourceRulesList.length > 5) && (
          <GridItem xs={12}>
            <SimpleCard cardStyle={{ marginTop: 0 }}>
              <SearchBar
                value={appMainStore.searchString}
                onChange={(searchString) => {
                  appMainStore.setSearchString(searchString);
                  resourcesStore.setSearchString(searchString);
                }}
              />
            </SimpleCard>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={5}>
          <h4 className={classes.sectionTitle}>
            <Translate id="Resources@appResourceSectionTitle">
              Recursos
            </Translate>
          </h4>

          <Button
            color="primary"
            small
            block
            className={classes.addButton}
            onClick={didWantToAddAppResource}
          >
            <AddString /> <i className="fas fa-plus-circle" />
          </Button>
          {appMainStore.filteredDataList.length < 1 && (
            <p className={classes.nothingFound}>
              <i className="fas fa-exclamation-triangle" />{" "}
              <Translate id="Common@nothingFound">Nada encontrado</Translate>
            </p>
          )}
          {appMainStore.filteredDataList.map((appResource) => (
            <SimpleCard
              key={appResource.token}
              classNames={{ card: classes.card, cardBody: classes.container }}
            >
              <GridContainer>
                <GridItem xs={12} className={classes.titleContainer}>
                  <h3 className={classes.title}>{appResource.label}</h3>
                </GridItem>
              </GridContainer>
              {resourceType === "playlist" && (
                <Tooltip title={<DuplicateString />} disableFocusListener>
                  <Button
                    small
                    color="warning"
                    justIcon
                    style={{ marginRight: 8 }}
                    onClick={() => didWantToCloneAppResource(appResource)}
                  >
                    <i className="fa fa-clone" />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title={<EditString />} disableFocusListener>
                <Button
                  small
                  color="primary"
                  justIcon
                  onClick={() => didSelectAppResourceToEdit(appResource.token)}
                >
                  <i className="fa fa-pen" />
                </Button>
              </Tooltip>
            </SimpleCard>
          ))}
        </GridItem>
        <GridItem xs={12} sm={12} md={7} style={{ position: "relative" }}>
          <Hidden mdDown>
            <div
              style={{
                position: "absolute",
                height: "94%",
                left: 0,
                width: 2,
                backgroundColor: "#ddd",
              }}
            />
          </Hidden>
          <h4 className={classes.sectionTitle}>
            <Translate id="Resources@resourceRuleSectionTitle">
              Regras
            </Translate>
          </h4>
          <Button
            color="info"
            small
            block
            className={classes.addButton}
            onClick={didWantToAddResourceRule}
          >
            <AddString /> <i className="fas fa-plus-circle" />
          </Button>
          {Object.values(resourceRulesList).length > 0 ? (
            Object.keys(resourceRulesList).map((itemId) => (
              <ResourceRuleGroup
                key={itemId}
                classes={classes}
                resourceType={resourceType}
                didSelectResourceRuleToEdit={didSelectResourceRuleToEdit}
                didSelectResourceRuleToConfigureVideoWall={
                  didSelectResourceRuleToConfigureVideoWall
                }
                resourceRuleList={resourceRulesList[itemId]}
                didReorderResourceRulesOfGroup={
                  didReorderResourceRulesOfGroup
                }
              />
            ))
          ) : (
            <p className={classes.nothingFound}>
              <i className="fas fa-exclamation-triangle" />{" "}
              <Translate id="Common@nothingFound">Nada encontrado</Translate>
            </p>
          )}
        </GridItem>
      </GridContainer>
    )
  )
);

export default withStyles(styles)(ResourcePageView);
