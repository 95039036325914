// @ts-nocheck
import { useState, Fragment } from "react";
import { inject } from "mobx-react";
import classnames from "classnames";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import withStyles from "@material-ui/core/styles/withStyles";

import ImageLightbox from "../ImageLightbox/ImageLightbox";
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";

import Translate from "../I18n/Translate";

import MessageText from "./message/MessageText";
import MessageTimestamp from "./message/MessageTimestamp";

// Utils
import { MESSAGE_TYPE } from "../../store/ChatStore/ChatStore";

// Style
import { dangerColor } from "../../assets/jss/app-js-styles";
const userColor = "#dcf8c6";
const messageStyle = (theme) => ({
  position: "relative",
  fontSize: 14,
  marginLeft: 16,
  marginRight: 16,
  marginBottom: 2,
  borderRadius: 6,
  backgroundColor: "#EEEEEE",
  padding: "8px 15px",
  maxWidth: "90%",
  width: "fit-content",
  fontWeight: 500,

  "& p": {
    margin: 0,
  },

  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%",
  },
});

const messageBubbleStyle = {
  content: "",
  position: "absolute",
  border: "10px solid transparent",
  bottom: 0,
};

const IMAGE_HEIGHT = 160;

const styles = (theme) => ({
  othersMessage: {
    ...messageStyle(theme),
  },
  userMessage: {
    ...messageStyle(theme),
    marginLeft: "auto",
    backgroundColor: userColor,
  },
  image: {
    maxWidth: "100%",
    height: IMAGE_HEIGHT,
    borderRadius: 6,
    marginBottom: 12,
    boxShadow:
      "0 9px 17px 0px rgba(0, 0, 0, 0.24), 0 6px 15px 0px rgba(0, 0, 0, 0.2)",
  },
  lastMessage: {
    marginBottom: 12,
  },
  imageContainer: {
    minHeight: IMAGE_HEIGHT,
  },
  othersMessageBubble: {
    ...messageBubbleStyle,
    borderBottom: "10px solid #EEEEEE",
    left: -10,
  },
  userMessageBubble: {
    ...messageBubbleStyle,
    borderBottom: `10px solid ${userColor}`,
    right: -10,
  },
});

const MessageItem = inject("chatStore")(
  ({
    classes,
    chatStore,
    isLast,
    isAdminView,
    id,
    type,
    author,
    message,
    imageUrl,
    adminName,
    timestamp,
    fileExtension,
  }) => {
    const [didWantToDelete, setDidWantToDelete] = useState(false);

    const authorIsUser = author === "user";
    const authorIsAdmin = author === "admin";
    const isOthersMessage = isAdminView ? authorIsUser : authorIsAdmin;
    const isMineMessage = isAdminView ? authorIsAdmin : authorIsUser;

    const didWantToEditMessage = () => {
      chatStore.setSelectedMessageId(id);
    };
    const didWantToDeleteMessage = () => {
      setDidWantToDelete(true);
    };

    const cancelDeletingMessage = () => {
      setDidWantToDelete(false);
    };

    const didDelete = () => {
      setDidWantToDelete(false);
      chatStore.didDeleteChatMessage({ id, type, fileExtension });
    };

    const renderMenu = () => {
      return (
        <ContextMenu id={id} closeOnClick>
          <MenuItem onClick={didWantToEditMessage}>
            <Translate id="Common@edit">Editar</Translate>{" "}
            <i className="fas fa-pen" />
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            onClick={didWantToDeleteMessage}
            attributes={{ style: { color: dangerColor } }}
          >
            <Translate id="Common@delete">Apagar</Translate>{" "}
            <i className="fas fa-trash-alt" />
          </MenuItem>
        </ContextMenu>
      );
    };

    const liElement = (
      <li
        key={id}
        className={classnames({
          [classes.othersMessage]: isOthersMessage,
          [classes.userMessage]: isMineMessage,
          [classes.lastMessage]: isLast,
          [classes.imageContainer]: type === MESSAGE_TYPE.IMAGE,
        })}
      >
        {type === MESSAGE_TYPE.IMAGE && (
          <ImageLightbox images={[imageUrl]}>
            <img src={imageUrl} alt={message} className={classes.image} />
          </ImageLightbox>
        )}
        <MessageText message={message} />
        <MessageTimestamp
          timestamp={timestamp}
          isMineMessage={isMineMessage}
          adminName={author === "admin" && isAdminView ? adminName : null}
        />
        {isLast && (
          <span
            className={classnames({
              [classes.othersMessageBubble]: isOthersMessage,
              [classes.userMessageBubble]: isMineMessage,
            })}
          />
        )}
      </li>
    );

    return (
      <Fragment>
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={didDelete}
            didCancel={cancelDeletingMessage}
            message={
              <Translate id="Chat@confirmDeleteChatMessage">
                A mensagem não poderá ser recuperada.
              </Translate>
            }
          />
        )}
        {isMineMessage ? (
          <Fragment>
            <ContextMenuTrigger id={id}>{liElement}</ContextMenuTrigger>
            {renderMenu()}
          </Fragment>
        ) : (
          liElement
        )}
      </Fragment>
    );
  }
);

export default withStyles(styles)(MessageItem);
