// @ts-nocheck
import { observable, computed, action, autorun } from "mobx";

import {
  auth,
  authUsersRef,
  usersRef,
  serverTimestamp,
} from "../FirebaseStore/FirebaseStore";

import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

// Utils
import { deviceInfo } from "../../utils/DeviceUtils";

class FirebaseAuthStore {
  @observable anonymousUser = null;

  @observable isAuthUserReady = false;

  @observable firebaseUserId = null;
  @observable firebaseUser = null;

  constructor(authStore, internalEventsStore) {
    this.authStore = authStore;
    this.signInAnonymously();
    this.setAuthUserIfAuthenticated();
    this.setFirebaseUserListener();

    // Logout listener
    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.logout,
    });
  }

  @action
  setAnonymousUser = (newValue) => {
    this.anonymousUser = newValue;
  };
  @action
  setFirebaseUserId = (newValue) => {
    this.firebaseUserId = newValue;
  };
  @action
  setFirebaseUser = (newValue) => {
    this.firebaseUser = newValue;
  };
  @action
  setIsAuthUserReady = (newValue) => {
    this.isAuthUserReady = newValue;
  };

  setAuthUserIfAuthenticated = () =>
    autorun(() => {
      if (this.isAuthenticated) {
        const authUserRef = authUsersRef.child(this.uid);
        if (this.authStore.currentUser) {
          const {
            token: userToken,
            isSimplyAsEmployee,
            isAdmin,
            isSupportOperator,
          } = this.authStore.currentUser;

          authUserRef.onDisconnect().remove();
          authUserRef
            .set({
              userToken,
              connectedSince: serverTimestamp,
              isSimplyAsEmployee,
              isAdmin,
              isSupportOperator,
            })
            .then(() => this.setIsAuthUserReady(true));
        } else {
          authUserRef.once("value", (snapshot) => {
            if (snapshot.exists()) {
              authUserRef.remove();
            }
          });
          this.setIsAuthUserReady(false);
        }
      } else {
        this.setAnonymousUser(null);
      }
    });

  setFirebaseUserListener = () => {
    autorun(async () => {
      if (this.isAuthenticated) {
        let userId = "";
        const user = {
          createdAt: serverTimestamp,
          deviceInfo: deviceInfo(),
          currentUrl: window.location.href,
        };

        if (this.authStore.currentUser) {
          const { token, name, email } = this.authStore.currentUser;
          userId = token;
          user["name"] = name;
          user["email"] = email;
          user["isAnonymous"] = false;
        } else {
          userId = this.uid;
          user["isAnonymous"] = true;
        }

        const snapshot = await usersRef.child(userId).once("value");
        if (!snapshot.exists()) {
          await usersRef.child(userId).set(user);
        }

        this.setFirebaseUserId(userId);
      } else {
        this.setFirebaseUserId(null);
      }
    });

    autorun(() => {
      if (this.firebaseUserId) {
        usersRef.child(this.firebaseUserId).on("value", (snapshot) => {
          if (snapshot.exists()) {
            this.setFirebaseUser({
              id: snapshot.key,
              ...snapshot.val(),
            });
          }
        });
      } else {
        this.setFirebaseUser(null);
      }
    });
  };

  @computed
  get isAuthReady() {
    return this.isAuthUserReady && this.firebaseUser !== null;
  }

  @computed
  get uid() {
    if (this.isAuthenticated) {
      return this.anonymousUser.uid;
    }
    return null;
  }

  @computed
  get isAuthenticated() {
    return this.anonymousUser !== null;
  }

  signInAnonymously = () => {
    auth
      .signInAnonymously()
      .then(({ user }) => this.setAnonymousUser(user))
      .catch((_) => setTimeout(this.signInAnonymously, 2000));
  };

  logout = async () => {
    this.setAnonymousUser(null);
    await auth.signOut();
    this.signInAnonymously();
  };
}

export default FirebaseAuthStore;
