// @ts-nocheck


import {
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

// Style
import customSelectStyle from "../../assets/jss/js-styles/customSelectStyle";

const styles = {
  ...customSelectStyle,
};

const MaterialSelectInput = ({
  classes,
  name,
  label,
  disabled,
  fullWidth = true,
  options,
  value,
  onChange,
}) => (
  <FormControl
    fullWidth={fullWidth}
    className={classes.selectFormControl}
    disabled={disabled}
    style={{ cursor: disabled ? "not-allowed" : "inherit" }}
  >
    {label && (
      <InputLabel htmlFor={name} className={classes.selectLabel}>
        {label}
      </InputLabel>
    )}
    <Select
      MenuProps={{
        className: classes.selectMenu,
      }}
      classes={{
        select: classes.select,
      }}
      disabled={disabled}
      value={value}
      onChange={onChange}
      inputProps={{
        name: name,
        id: name,
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          disabled={option.isDisabled}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default withStyles(styles)(MaterialSelectInput);
