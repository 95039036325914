import { Fragment, FC } from "react";
interface FormattedLabelWithVariableProps {
  label: string;
  children: (key: string, value: string) => JSX.Element;
}

const FormattedLabelWithVariable: FC<FormattedLabelWithVariableProps> = ({
  label,
  children,
}) => {
  const startVariableRegex = /{/;
  const endVariableRegex = /}/;
  let preparedLabel = label;
  let variablesNamesList = [];
  let matchStart = startVariableRegex.exec(preparedLabel);
  let matchEnd = endVariableRegex.exec(preparedLabel);

  while (matchStart && matchEnd) {
    const value = preparedLabel.substring(matchStart.index + 1, matchEnd.index);
    variablesNamesList.push(value);
    preparedLabel = preparedLabel
      .replace(startVariableRegex, "[")
      .replace(endVariableRegex, "]")
      .replace(value, "");

    matchStart = startVariableRegex.exec(preparedLabel);
    matchEnd = endVariableRegex.exec(preparedLabel);
  }
  return (
    <Fragment>
      {preparedLabel.split("[]").reduce((prev, current, index) => {
        if (!index) return [current];

        return prev.concat(
          children(`${index}`, variablesNamesList[index - 1]),
          current
        );
      }, [])}
    </Fragment>
  );
};

export default FormattedLabelWithVariable;
