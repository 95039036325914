// @ts-nocheck
import { Component, Fragment } from "react";
import { autorun } from "mobx";
import { inject, observer } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Modal from "../../components/Modal/Modal";

import { withLocalized } from "../../components/HOC";

// core components
import { GridContainer, GridItem } from "../../components/Grid";
import { Card, CardBody } from "../../components/Card";
import LoadingView from "../../components/LoadingView/LoadingView";
import SelectMediaContainer from "./SelectMediaContainer";
import GridListMediaContainer from "./GridListMediaContainer";
import NumberEditingWithLabelInput from "../../components/Form/NumberEditingWithLabelInput";
import Button from "../../components/Button/Button";
import UsageGridWidget from "../../components/Widgets/UsageGridWidget";

import ToolboxBar, {
  calculateToolboxHeightForActionsList,
} from "../../components/Toolbox/ToolboxBar";

import NoMediasInPlaylistHint from "./NoMediasInPlaylistHint";

// Translate
import { MinutesString, SaveString } from "../../components/I18n/CommonStrings";
import Plural from "../../components/I18n/Plural";
import Translate from "../../components/I18n/Translate";

// Utils Stores
import {
  hasLiveContent,
  hasLiveStreaming,
} from "../../store/PlaylistsStore/PlaylistsStore";
import { EInternalEvents } from "../../store/InternalEventsStore/InternalEventsStore";

// Style
import { cardTitle, dangerColor } from "../../assets/jss/app-js-styles";
import customSelectStyle from "../../assets/jss/js-styles/customSelectStyle";
import customCheckboxRadioSwitch from "../../assets/jss/js-styles/customCheckboxRadioSwitch";

const styles = {
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  actionButtonsContainer: {
    position: "relative",
  },
  leftActionButtons: {
    marginTop: -40,
    marginBottom: 20,
    position: "sticky",
    top: 8,
    zIndex: 1000000,
  },
  leftActionButtonsManagingMedia: {
    top: 105,
  },
};

@withLocalized
@inject(
  "playlistsStore",
  "resourcesStore",
  "internalEventsStore",
  "notificationsStore"
)
@observer
class SetSinglePlaylistContainer extends Component {
  constructor(props) {
    super(props);

    // Subscribe to internal event
    props.internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.mediaWasDeleted,
      observer: this,
      callback: this.mediaWasDeleted,
    });

    this.state = {
      playlist: null,
      isAddingMedia: false,
      isManagingMedia: false,
      isEditingDurationToLiveContent: false,
      modalIsOpen: false,
      hasLiveContent: false,
      hasLiveStreaming: false,
      recentlyAddedMediaCount: 0,
      wasEdited: false,
    };

    this.deleteMediaHintNotificationToken = null;
  }

  componentDidMount() {
    this.props.playlistsStore.getSinglePlaylist();

    this.disposerAutorun = autorun(
      () => {
        const playlist = this.props.playlistsStore.singlePlaylist;
        this.setState({
          playlist,
          hasLiveContent: playlist
            ? hasLiveContent(playlist.mediasList)
            : false,
          hasLiveStreaming: playlist
            ? hasLiveStreaming(playlist.mediasList)
            : false,
        });
      },
      { name: "setSinglePlaylist" }
    );
  }

  componentWillUnmount() {
    this.disposerAutorun();

    this.props.internalEventsStore.unsubscribe(
      this,
      EInternalEvents.mediaWasDeleted
    );

    this.clearDeleteMediaHintNotification();
  }

  mediaWasDeleted = ({ deletedMediaToken }) => {
    const filteredMediasList = this.state.playlist.mediasList.filter(
      (mediaItem) => mediaItem.token !== deletedMediaToken
    );

    if (filteredMediasList.length !== this.state.playlist.mediasList.length) {
      this.setState({
        playlist: {
          ...this.state.playlist,
          mediasList: filteredMediasList,
        },
      });
    }
  };

  clearDeleteMediaHintNotification = () => {
    this.props.notificationsStore.removeSnackbarNotification(
      this.deleteMediaHintNotificationToken
    );
  };

  sendPlaylistUpdateToServer = () => {
    const {
      token,
      label,
      isRandom,
      isLooping,
      isMuted,
      durationToLiveContent,
      mediasList,
      terminalsList,
    } = this.state.playlist;

    this.props.playlistsStore
      .update({
        token,
        label,
        isRandom,
        isLooping,
        isMuted,
        durationToLiveContent,
        files: mediasList.map((mediaItem) => mediaItem.token),
        terminalsList,
      })
      .then(() => {
        this.setState({ wasEdited: false });
      });
  };

  didWantToAddNewMedia = () => {
    this.setState({
      modalIsOpen: true,
      isAddingMedia: true,
    });
  };

  toggleManageMedias = () => {
    if (this.state.isManagingMedia) {
      this.clearDeleteMediaHintNotification();

      const newMediasList = this.state.playlist.mediasList.map((mediaItem) => {
        return {
          ...mediaItem,
          isSelectedToDelete: false,
        };
      });
      this.didChangePlaylistMediaList(newMediasList, {
        isManagingMedia: false,
        wasEdited: this.state.wasEdited,
      });
    } else {
      this.setState(
        {
          isManagingMedia: true,
        },
        () => {
          this.deleteMediaHintNotificationToken = this.props.notificationsStore.addSnackbarNotification(
            {
              message: (
                <Translate id="Playlist@howToRemoveMedia">
                  Para remover mídias da sua programação basta seleciona-las e
                  então Salvar.
                </Translate>
              ),
              color: "danger",
              timeout: 0,
            }
          );
        }
      );
    }
  };

  toggleIsEditingDurationToLiveContentModal = () => {
    this.setState({
      isEditingDurationToLiveContent: true,
      modalIsOpen: true,
    });
  };

  didChangeDurationToLiveContent = (durationToLiveContent) => {
    this.setState((state) => ({
      playlist: {
        ...state.playlist,
        durationToLiveContent: durationToLiveContent * 60,
      },
      wasEdited: true,
    }));
  };

  handleSwitchChange = (name) => {
    this.setState((prevState) => ({
      playlist: {
        ...prevState.playlist,
        [name]: !prevState.playlist[name],
      },
      wasEdited: true,
    }));
  };

  toggleIsRandom = () => {
    this.handleSwitchChange("isRandom");
  };
  toggleIsMuted = () => {
    this.handleSwitchChange("isMuted");
  };

  toggleIsActive = () => {
    const playlistResource = this.props.resourcesStore.singleResourceRule;

    this.props.playlistsStore.updateSinglePlaylistResource({
      ...playlistResource,
      isActive: !playlistResource.isActive,
    });
  };

  didChangePlaylistMediaList = (newMediasList, othersStateToChange = {}) => {
    this.setState({
      playlist: {
        ...this.state.playlist,
        mediasList: newMediasList,
      },
      hasLiveContent: hasLiveContent(newMediasList),
      hasLiveStreaming: hasLiveStreaming(newMediasList),
      recentlyAddedMediaCount: 0,
      wasEdited: true,
      ...othersStateToChange,
    });
  };

  didAddNewMediaList = (newMediasList) => {
    let newList = [];
    let recentlyAddedMediaCount = 0;
    newMediasList.forEach((mediaItem) => {
      for (let index = 0; index < mediaItem.selectedQty; index++) {
        newList.push(mediaItem);
        recentlyAddedMediaCount++;
      }
    });

    this.didChangePlaylistMediaList(
      [...newList, ...this.state.playlist.mediasList],
      { isAddingMedia: false, recentlyAddedMediaCount }
    );
  };

  onChangePlaylistContentInput = ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      playlist: {
        ...this.state.playlist,
        [key]: value,
      },
    });
  };

  deleteSelectedMediasFromPlaylist = () => {
    this.clearDeleteMediaHintNotification();

    const newMediasList = this.state.playlist.mediasList.filter(
      (mediaItem) => !mediaItem.isSelectedToDelete
    );

    const newState = {
      isAddingMedia: false,
      isManagingMedia: false,
    };

    if (newMediasList.length !== this.state.playlist.mediasList.length) {
      this.didChangePlaylistMediaList(newMediasList, newState);
    } else {
      this.setState(newState);
    }
  };

  didSelectMedia = (itemIndex) => {
    if (this.state.isManagingMedia) {
      this.didSelectMediaToDelete(itemIndex);
    }
  };

  didCloseModal = () => {
    this.setState(
      {
        modalIsOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isAddingMedia: false,
            isEditingDurationToLiveContent: false,
          });
        }, 200);
      }
    );
  };

  didSelectMediaToDelete = (itemIndex) => {
    let newMediasList = [];
    this.state.playlist.mediasList.forEach((mediaItem, index) => {
      if (index === itemIndex) {
        newMediasList.push({
          ...mediaItem,
          isSelectedToDelete: mediaItem.isSelectedToDelete
            ? !mediaItem.isSelectedToDelete
            : true,
        });
      } else {
        newMediasList.push(mediaItem);
      }
    });

    this.didChangePlaylistMediaList(newMediasList, {
      wasEdited: this.state.wasEdited,
    });
  };

  didReorderMediaItemsList = (newMediasList) => {
    this.didChangePlaylistMediaList(newMediasList);
  };

  playlistToolboxActions = () => {
    const { localizedString } = this.props;
    const {
      playlist,
      isManagingMedia,
      hasLiveContent,
      hasLiveStreaming,
    } = this.state;
    const playlistResource = this.props.resourcesStore.singleResourceRule;

    const list = [
      {
        type: "button",
        inactiveColor: "danger",
        label: playlistResource.isActive
          ? localizedString({
              id: "Informative@isActiveLabel",
              defaultString: "Playlist habilitada",
            })
          : localizedString({
              id: "Informative@isInactiveLabel",
              defaultString: "Playlist inativa",
            }),
        isActive: playlistResource.isActive,
        onClick: this.toggleIsActive,
        icon: "power-off",
      },
      {
        type: "divider",
      },
      {
        type: "button",
        label: localizedString({
          id: "Informative@addMediasButtonLabel",
          defaultString: "Adicionar mídias",
        }),
        disabled: !playlistResource.isActive,
        onClick: this.didWantToAddNewMedia,
        icon: "plus",
      },
      {
        type: "button",
        label: localizedString({
          id: "Informative@removeMediasButtonLabel",
          defaultString: "Remover mídias",
        }),
        disabled: playlist.mediasList.length < 1 || !playlistResource.isActive,
        onClick: this.toggleManageMedias,
        icon: "minus",
      },
      {
        type: "divider",
      },
      {
        type: "button",
        label: playlist.isRandom
          ? localizedString({
              id: "Informative@randomIsActiveLabel",
              defaultString: "Randômico ligado",
            })
          : localizedString({
              id: "Informative@randomIsInactiveLabel",
              defaultString: "Randômico desligado",
            }),
        isActive: playlist.isRandom,
        onClick: this.toggleIsRandom,
        disabled: !playlistResource.isActive,
        icon: "random",
      },
      {
        type: "button",
        label: playlist.isMuted
          ? localizedString({
              id: "Informative@muteIsActiveLabel",
              defaultString: "Sem áudio",
            })
          : localizedString({
              id: "Informative@muteIsInactiveLabel",
              defaultString: "Com áudio",
            }),
        isActive: !playlist.isMuted,
        onClick: this.toggleIsMuted,
        disabled: !playlistResource.isActive,
        icon: playlist.isMuted ? "volume-off" : "volume",
      },
      {
        type: "button",
        label:
          hasLiveContent || hasLiveStreaming ? (
            <Plural
              id="Playlist@durationToLiveContentLabel"
              value={playlist.durationToLiveContent / 60}
              one={
                <span>
                  Duração para conteúdos ao vivo: <strong>um minuto</strong>
                </span>
              }
              other={
                <span>
                  Duração para conteúdos ao vivo: <strong># minutos</strong>
                </span>
              }
            />
          ) : null,
        isActive: true,
        onClick: this.toggleIsEditingDurationToLiveContentModal,
        disabled: !playlistResource.isActive,
        icon: "clock",
      },
      {
        type: "divider",
      },
      {
        type: "submit",
        label: localizedString({
          id: "Common@save",
          defaultString: "Salvar",
        }),
        isActive: playlistResource.isActive && this.state.wasEdited,
        onClick: this.sendPlaylistUpdateToServer,
        icon: "save",
        disabled: !playlistResource.isActive || !this.state.wasEdited,
      },
    ];

    const isManagingMediaList = [
      {
        type: "button",
        label: localizedString({
          id: "Common@back",
          defaultString: "Voltar",
        }),
        onClick: this.toggleManageMedias,
        icon: "chevron-left",
      },
      {
        type: "submit",
        label: localizedString({
          id: "Common@save",
          defaultString: "Salvar",
        }),
        isActive: true,
        onClick: this.deleteSelectedMediasFromPlaylist,
        icon: "save",
      },
    ];

    return {
      list: isManagingMedia ? isManagingMediaList : list,
      height: calculateToolboxHeightForActionsList(
        isManagingMedia ? isManagingMediaList : list
      ),
    };
  };

  render() {
    const { classes, resourcesStore } = this.props;
    const {
      isAddingMedia,
      isManagingMedia,
      isEditingDurationToLiveContent,
      playlist,
      modalIsOpen,
    } = this.state;
    const playlistResource = resourcesStore.singleResourceRule;

    if (
      !playlist ||
      !playlistResource ||
      playlistResource.resourceType !== "playlist"
    ) {
      return <LoadingView />;
    }

    const {
      list: toolboxActionsList,
      height: toolboxHeight,
    } = this.playlistToolboxActions();

    return (
      <Fragment>
        {isAddingMedia && (
          <SelectMediaContainer didAddNewMediaList={this.didAddNewMediaList}>
            {({ body, action }) => (
              <Modal
                isOpen={modalIsOpen}
                didCloseModal={this.didCloseModal}
                modalTitle={
                  <Translate id="Informative@mediaLibraryTitle">
                    Biblioteca de mídias
                  </Translate>
                }
                maxWidth="xl"
                fullScreen
                actions={action}
              >
                {body}
              </Modal>
            )}
          </SelectMediaContainer>
        )}
        {isEditingDurationToLiveContent && (
          <Modal
            isOpen={modalIsOpen}
            didCloseModal={this.didCloseModal}
            hideCloseButton
            maxWidth="sm"
          >
            <GridContainer
              justify="center"
              style={{ marginTop: 40, marginBottom: 40 }}
            >
              <GridItem xs={12} sm={11}>
                <NumberEditingWithLabelInput
                  label={
                    <Translate id="Playlist@durationToLiveContent">
                      Duração para conteúdos ao vivo:
                    </Translate>
                  }
                  typeLabel={<MinutesString />}
                  onChange={this.didChangeDurationToLiveContent}
                  value={playlist.durationToLiveContent / 60}
                />
              </GridItem>
              <GridItem xs={12} sm={11}>
                <Button
                  small
                  block
                  color="primary"
                  onClick={this.didCloseModal}
                >
                  <SaveString /> <i className="fas fa-save" />
                </Button>
              </GridItem>
            </GridContainer>
          </Modal>
        )}
        <Card style={{ marginTop: 0 }}>
          <CardBody style={{ padding: "0 16px" }}>
            <GridContainer>
              <UsageGridWidget type="storage" />
            </GridContainer>
          </CardBody>
        </Card>
        <GridContainer>
          <GridItem xs={12}>
            <Card
              style={{
                marginTop: 0,
                border: "2px solid transparent",
                borderColor: this.state.isManagingMedia
                  ? dangerColor
                  : "transparent",
              }}
            >
              <CardBody style={{ padding: "0 16px" }}>
                <div
                  style={{
                    position: "relative",
                    padding: "16px",
                    paddingLeft: 60,
                    minHeight: toolboxHeight,
                  }}
                >
                  <ToolboxBar leftPosition={-48} actions={toolboxActionsList} />
                  <GridContainer className={classes.actionButtonsContainer}>
                    <GridItem xs={12}>
                      {playlist.mediasList.length > 0 ? (
                        <GridListMediaContainer
                          isManagingMedia={isManagingMedia}
                          mediaItemsList={playlist.mediasList}
                          disabledPlaylist={!playlistResource.isActive}
                          randomIsActive={playlist.isRandom}
                          didSelectMedia={this.didSelectMedia}
                          didReorderMediaItemsList={
                            this.didReorderMediaItemsList
                          }
                          recentlyAddedMediaCount={
                            this.state.recentlyAddedMediaCount
                          }
                        />
                      ) : (
                        <NoMediasInPlaylistHint />
                      )}
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(SetSinglePlaylistContainer);
