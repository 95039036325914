import { IMAGE_PATH } from "../../utils/EnvUtils";
import { WizardUploadImageBox } from "./style";
import { Payload } from "./type";

type WizardUploadImageProps = {
  payload: Payload;
};
const WizardUploadImage = ({ payload }: WizardUploadImageProps) => {
  return (
    <WizardUploadImageBox payload={payload}>
      <div>
        {Array.from({ length: !!payload?.isVideowall ? 3 : 1 }).map(
          (_, index) => (
            <div className="image" key={`img-${index}`}>
              <figure>
                <img src={`${IMAGE_PATH}/image-icon.png`} alt="Ilustrando" />
              </figure>
            </div>
          )
        )}
      </div>
    </WizardUploadImageBox>
  );
};
export default WizardUploadImage;
