// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";
import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import ContractUsersPageContainer from "./contractUsersPage/ContractUsersPageContainer";

@inject("contractStore")
class ContractUsersPage extends Component {
  render() {
    return (
      <Fragment>
        <PageTitle id="Menu@administrativeUsers">Usuários</PageTitle>
        <ContractUsersPageContainer mainStore={this.props.contractStore} />
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: ContractUsersPage,
  pageAllowedRule: "administrative",
});
