// @ts-nocheck
import { observable, computed, action } from "mobx";
import {
  auth,
  affiliateUserRef,
  affiliatesRef,
} from "../FirebaseStore/FirebaseStore";

class AffiliateAuthStore {
  @observable
  currentUser = null;

  userRef = null;

  constructor() {
    this.setFirebaseOnAuthStateChangedListener();
  }

  @action
  setCurrentUser = (newValue) => (this.currentUser = newValue);

  setFirebaseOnAuthStateChangedListener = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.userRef = affiliateUserRef.child(user.uid);

        this.userRef.on("value", (snapshot) => {
          if (snapshot.val()) {
            this.setCurrentUser({
              uid: user.uid,
              ...snapshot.val(),
            });

            this.checkIfHasValidContract();
          }
        });
      } else {
        this.setCurrentUser(null);
        if (this.userRef) {
          this.userRef.off();
          this.userRef = null;
        }
      }
    });
  };

  @computed
  get isAuthenticated() {
    return this.currentUser !== null;
  }

  @computed
  get hasValidContract() {
    return this.currentUser && this.currentUser.contractToken ? true : false;
  }

  checkIfHasValidContract = () => {
    if (!this.hasValidContract) {
      const { uid, email } = this.currentUser;
      affiliatesRef.once("value", (snapshot) => {
        if (snapshot.val()) {
          snapshot.forEach((affiliateSnapshot) => {
            if (affiliateSnapshot.val().validEmails.includes(email)) {
              affiliateUserRef
                .child(`${uid}/contractToken`)
                .set(affiliateSnapshot.key);
            }
          });
        }
      });
    }
  };

  makeLogin(email, password) {
    return new Promise((resolve, reject) => {
      auth
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          if (error.code === "auth/invalid-email") {
            reject("O e-mail informado é inválido");
          } else if (error.code === "auth/user-not-found") {
            this.createAccount(email, password)
              .then((data) => {
                affiliateUserRef.child(data.user.uid).set({
                  email: email,
                });
                resolve(data.user);
              })
              .catch((error) => {
                reject(error);
              });
          } else if (error.code === "auth/wrong-password") {
            reject(
              'A senha informada é incorreta. Utilize a opção "Esqueceu a senha?" se necessário.'
            );
          } else if (error.code === "auth/too-many-requests") {
            reject(
              "Você foi bloqueado temporariamente. Aguarde 5 minutos para tentar novamente."
            );
          }
        });
    });
  }

  createAccount(email, password) {
    return new Promise((resolve, reject) => {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          if (error === "auth/invalid-email") {
            reject("O e-mail informado é inválido.");
          } else if (error === "auth/weak-password") {
            reject("A senha precisa ter pelo menos 6 caracteres.");
          } else if (error === "auth/too-many-requests") {
            reject(
              "Você foi bloqueado temporariamente. Aguarde 5 minutos para tentar novamente."
            );
          }
        });
    });
  }

  sendPasswordResetEmail(emailAddress) {
    return new Promise((resolve, reject) => {
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          window.debugError(error.message);
          reject(error.code);
        });
    });
  }

  logout() {
    return auth.signOut();
  }
}

export default AffiliateAuthStore;
