// @ts-nocheck

import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import headingStyle from "../../assets/jss/js-styles/components/headingStyle";

function Heading({ ...props }) {
  const { textAlign, category, title, classes } = props;
  if (!title && !category) return null;

  return (
    <div
      className={cx(classes.heading, {
        [classes[textAlign + "TextAlign"]]: !!textAlign,
      })}
    >
      {title && <h3 className={classes.title}>{title}</h3>}
      {category && <p className={classes.category}>{category}</p>}
    </div>
  );
}

Heading.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  category: PropTypes.node,
  textAlign: PropTypes.oneOf(["right", "left", "center"]),
};

export default withStyles(headingStyle)(Heading);
