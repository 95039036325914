//@ts-nocheck
import { Component, Fragment, ReactNode } from "react";
import { inject, observer } from "mobx-react";

// Components
import ConfirmDeleteModal from "../../../components/Modal/ConfirmDeleteModal";
import {
  InputText,
  InputSelect,
  ButtonGroup,
  InputSwitch,
  InputColorPicker,
} from "../../../components/Form";
import InputSlider from "../../../components/Form/InputSlider";
import { Button } from "../../../components/Button";
import { GridContainer, GridItem } from "../../../components/Grid";
import { withLocalized } from "../../../components/HOC";

import { generateRandomLabel } from "../../../utils/StringUtils";

import {
  backgroundColorOptions,
  fontColorOptions,
} from "../../../utils/ColorUtils";

// Translate
import {
  DeleteString,
  UpdateString,
  SaveString,
  BackgroundColorString,
  FontColorString,
} from "../../../components/I18n/CommonStrings";
import Translate from "../../../components/I18n/Translate";

// Icon
import { SaveIcon, DeleteIcon } from "../../../components/Icon";

import QueueManagementControlModel, {
  QueueManagementControlType,
  EQueueManagementLayout,
} from "../../../Model/QueueManagementControlModel";
import InformativeQueueManagementStore from "../store/InformativeQueueManagementStore";
import TerminalsStore from "../../../store/TerminalsStore/TerminalsStore";
import { readQueueValue, getOpacityFromAlpha } from "./QueueUtils";
import QueueControlScreenPreview from "./QueueControlScreenPreview";
import DeviceSelectorInput from "../../../components/Devices/DeviceSelectorInput";
import Conditional from "../../../components/Conditional/Conditional";

interface IQueueControlFormProps {
  localizedString?: any;
  store: InformativeQueueManagementStore;
  children: ({
    body,
    actions,
  }: {
    body: ReactNode;
    actions: ReactNode;
  }) => ReactNode;
}

interface IQueueControlFormState {
  name: string;
  value: string;
  type: QueueManagementControlType;
  terminalToken: string | null;
  useVoice: boolean;
  layout: EQueueManagementLayout;
  alpha: string;
  displayTime: number;
  backgroundColor: string;
  fontColor: string;
  isVertical: boolean;
  useInputStream: boolean;
  inputStreamPosition?: string;
  isWaitingForm: boolean;
  didWantToDelete: boolean;
}

interface InjectedProps extends IQueueControlFormProps {
  terminalsStore: TerminalsStore;
}

@inject("terminalsStore")
@observer
class QueueControlForm extends Component<
  IQueueControlFormProps,
  IQueueControlFormState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  constructor(props: IQueueControlFormProps) {
    super(props);

    if (props.store.selectedControl) {
      this.state = this.getStateFromExistingControl(
        props.store.selectedControl
      );
    } else {
      this.state = this.getDefaultState();
    }
  }

  getDefaultState = (): IQueueControlFormState => {
    return {
      name: "",
      value: "",
      type: QueueManagementControlType.constant,
      terminalToken: null,
      useVoice: false,
      layout: EQueueManagementLayout.center,
      alpha: "255",
      displayTime: 8,
      backgroundColor: "#1D1D1D",
      fontColor: "#FFFFFF",
      isVertical: false,
      useInputStream: false,
      inputStreamPosition: null,
      isWaitingForm: false,
      didWantToDelete: false,
    };
  };

  getStateFromExistingControl = (
    control: QueueManagementControlModel
  ): IQueueControlFormState => {
    return {
      ...this.getDefaultState(),
      name: control.name,
      value: control.value,
      type: control.type,
      useVoice: control.useVoice,
      layout: control.layout,
      alpha: control.alpha,
      displayTime: control.displayTime,
      backgroundColor: control.backgroundColor,
      fontColor: control.fontColor,
      isVertical: control.isVertical,
      useInputStream: control.useInputStream,
      inputStreamPosition: control.inputStreamPosition,
      terminalToken: control.terminalToken,
    };
  };

  onChangeInputText = ({ target }) => {
    const { name, value } = target;
    // @ts-ignore
    this.setState({ [name]: value });
  };
  onChangeValue = ({ target }) => {
    const { name, value } = target;

    this.setState((prevState) => {
      const [currentFirstLineValue, currentSecondLineValue] = readQueueValue(
        prevState.value
      );
      let newValue: string = null;
      if (name === "value1") {
        newValue = `${value}||${currentSecondLineValue}`;
      } else if (name === "value2") {
        newValue = `${currentFirstLineValue}||${value}`;
      }

      return newValue ? { value: newValue } : null;
    });
  };

  onChangeDevice = (_: string, tokens: string[]) =>
    this.setState({
      terminalToken: tokens[0] || null,
    });

  onChangeLayout = (_: string, selectedLayout: any) =>
    this.setState({
      layout: selectedLayout.value,
    });

  onChangeInputColorPicker = ({
    name,
    color,
  }: {
    name: string;
    color: string;
  }) => {
    // @ts-ignore
    this.setState({ [name]: color });
  };

  onChangeSlider = ({ name, value }: { name: string; value: number }) => {
    // @ts-ignore
    this.setState({ [name]: value });
  };

  onChangeAlpha = ({ name, value }: { name: string; value: number }) => {
    if (name !== "alpha") return;
    const max = 255;
    const alpha = Math.round((max * value) / 100);

    this.setState({
      alpha: `${alpha}`,
    });
  };

  onChangeUseVoice = () => {
    this.setState((prevState) => ({
      useVoice: !prevState.useVoice,
    }));
  };
  onChangeIsVertical = () => {
    this.setState((prevState) => ({
      isVertical: !prevState.isVertical,
      layout: EQueueManagementLayout.center,
    }));
  };
  onChangeUseInputStream = () => {
    this.setState((prevState) => {
      const newValue = !prevState.useInputStream;
      return {
        useInputStream: newValue,
        inputStreamPosition: newValue ? "0" : null,
      };
    });
  };
  onChangeInputStreamPosition = (_: string, selectedValue: any) => {
    this.setState({
      inputStreamPosition: selectedValue.value,
    });
  };

  onChangeType = (_: string, type: QueueManagementControlType) =>
    this.setState({ type });

  submitForm = async () => {
    const { store } = this.props;
    const {
      name,
      value,
      type,
      terminalToken,
      layout,
      useVoice,
      alpha,
      displayTime,
      backgroundColor,
      fontColor,
      isVertical,
      useInputStream,
      inputStreamPosition,
    } = this.state;

    const newName =
      name.length > 0
        ? name
        : generateRandomLabel(
            this.props.localizedString({
              id: "Informative@queueControl",
              defaultString: "Controle",
            })
          );

    try {
      const data = {
        name: newName,
        value: value.includes("||") ? value : `${value}||`,
        type,
        layout,
        useVoice,
        alpha,
        displayTime,
        backgroundColor,
        fontColor,
        isVertical,
        terminalToken,
        useInputStream,
        inputStreamPosition,
      };
      if (store.isEditingControl) {
        const controlToUpdate = new QueueManagementControlModel({
          ...store.selectedControl,
          ...data,
        });
        await store.update(controlToUpdate);
      } else {
        await store.submit(new QueueManagementControlModel(data));
      }
    } finally {
      if (store.isOpenModal) {
        this.setState({
          isWaitingForm: false,
        });
      }
    }
  };

  didWantToDelete = (event: MouseEvent | TouchEvent) => {
    event?.preventDefault();

    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () =>
    this.setState({
      didWantToDelete: false,
    });

  didDeleteConfiguration = () => {
    this.props.store.delete();
  };

  onSubmit = (event: MouseEvent | TouchEvent) => {
    event?.preventDefault();

    this.setState({ isWaitingForm: true }, this.submitForm);
  };

  labelForLayout = (layout: EQueueManagementLayout) => {
    switch (layout) {
      case EQueueManagementLayout.fullscreen:
        return "Tela inteira";
      case EQueueManagementLayout.left:
        return "Esquerda";
      case EQueueManagementLayout.right:
        return "Direita";
      case EQueueManagementLayout.top:
        return "Topo";
      case EQueueManagementLayout.topLeft:
        return "Topo esquerdo";
      case EQueueManagementLayout.topRight:
        return "Topo direito";
      case EQueueManagementLayout.centerLeft:
        return "Centro à esquerda";
      case EQueueManagementLayout.centerRight:
        return "Centro à direita";
      case EQueueManagementLayout.bottom:
        return "Rodapé";
      case EQueueManagementLayout.bottomLeft:
        return "Rodapé esquerdo";
      case EQueueManagementLayout.bottomRight:
        return "Rodapé direito";
      case EQueueManagementLayout.top_center:
        return "Centralizado no topo";
      case EQueueManagementLayout.bottomCenter:
        return "Centralizado no rodapé";
      case EQueueManagementLayout.center:
      default:
        return "Centralizado";
    }
  };

  getValidLayoutOptions = () => {
    const { isVertical } = this.state;
    const validLayoutList = isVertical
      ? [
          EQueueManagementLayout.center,
          EQueueManagementLayout.bottom,
          EQueueManagementLayout.top,
        ]
      : Object.values(EQueueManagementLayout);

    return validLayoutList.map((value) => ({
      label: this.labelForLayout(value),
      value,
    }));
  };

  render() {
    const isEditing = this.props.store.isEditingControl;
    const { localizedString } = this.props;
    const {
      name,
      type,
      value,
      terminalToken,
      layout,
      useVoice,
      alpha,
      displayTime,
      backgroundColor,
      fontColor,
      isVertical,
      useInputStream,
      inputStreamPosition,
      isWaitingForm,
      didWantToDelete,
    } = this.state;

    const [firstLineValue, secondLineValue] = readQueueValue(value);

    return (
      <Fragment>
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteConfiguration}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        {this.props.children({
          body: (
            <GridContainer>
              <GridItem xs={12} sm={6} md={5}>
                <InputText
                  label={
                    <Translate id="Informative@queueControlNameLabel">
                      Nome do controle (opcional)
                    </Translate>
                  }
                  name="name"
                  value={name}
                  onChange={this.onChangeInputText}
                  maxLength={30}
                />
                <ButtonGroup
                  name="type"
                  value={type}
                  color="primary"
                  disabled={isEditing}
                  label={localizedString({
                    id: "Informative@queueControlTypeLabel",
                    defaultString: "Tipo do controle",
                  })}
                  small
                  onChange={this.onChangeType}
                  options={[
                    {
                      value: QueueManagementControlType.constant,
                      label: localizedString({
                        id: "Informative@queueControlConstant",
                        defaultString: "Constante",
                      }),
                    },
                    {
                      value: QueueManagementControlType.oneVariable,
                      label: localizedString({
                        id: "Informative@queueControlOneVariable",
                        defaultString: "Uma variável",
                      }),
                    },
                    {
                      value: QueueManagementControlType.twoVariables,
                      label: localizedString({
                        id: "Informative@queueControlTwoVariables",
                        defaultString: "Duas variáveis",
                      }),
                      isDisabled: useInputStream,
                    },
                    {
                      value: QueueManagementControlType.sequential,
                      label: localizedString({
                        id: "Informative@queueControlSequential",
                        defaultString: "Sequencial",
                      }),
                      isDisabled: useInputStream,
                    },
                  ]}
                />
                <InputText
                  label={
                    <Translate id="Informative@queueControlValueFirstLineLabel">
                      Mensagem na primeira linha
                    </Translate>
                  }
                  name="value1"
                  value={firstLineValue}
                  onChange={this.onChangeValue}
                  maxLength={30}
                />
                <InputText
                  label={
                    <Translate id="Informative@queueControlValueSecondLineLabel">
                      Mensagem na segunda linha
                    </Translate>
                  }
                  name="value2"
                  value={secondLineValue}
                  onChange={this.onChangeValue}
                  maxLength={30}
                />
                <DeviceSelectorInput
                  label={<Translate id="Metrics@device">Dispositivo</Translate>}
                  name="terminalToken"
                  selectedTokens={terminalToken ? [terminalToken] : []}
                  didSelect={this.onChangeDevice}
                />
                <GridContainer>
                  <GridItem xs>
                    <InputSlider
                      label={
                        <Translate id="Informative@queueDisplayTimeLabel">
                          Tempo de exibição
                        </Translate>
                      }
                      name="displayTime"
                      min={4}
                      max={20}
                      value={displayTime}
                      renderTooltip={(value: number) => `${value}s`}
                      onChange={this.onChangeSlider}
                    />
                  </GridItem>
                  <GridItem>
                    <InputSwitch
                      label={
                        <Translate id="Common@isVerticalLabel">
                          Vertical
                        </Translate>
                      }
                      name="isVertical"
                      isActive={isVertical}
                      labelInVertical
                      onChange={this.onChangeIsVertical}
                    />
                  </GridItem>
                  <GridItem>
                    <InputSwitch
                      name="useVoice"
                      isActive={useVoice}
                      onChange={this.onChangeUseVoice}
                      labelInVertical
                      label={
                        <Translate id="Informative@queueUseVoiceLabel">
                          Usar voz
                        </Translate>
                      }
                    />
                  </GridItem>
                </GridContainer>
                <Conditional
                  when={[
                    QueueManagementControlType.constant,
                    QueueManagementControlType.oneVariable,
                  ].includes(type)}
                >
                  <GridContainer style={{ marginTop: 20 }}>
                    <GridItem>
                      <InputSwitch
                        name="useInputStream"
                        isActive={useInputStream}
                        onChange={this.onChangeUseInputStream}
                        label={
                          <Translate id="Informative@queueUseInputStreamLabel">
                            Agrupar com outros controles
                          </Translate>
                        }
                      />
                      <Conditional
                        when={
                          useInputStream &&
                          type === QueueManagementControlType.constant
                        }
                      >
                        <InputSelect
                          withPortal
                          label={
                            <Translate id="Informative@queueInputStreamPositionLabel">
                              Tecla do teclado físico
                            </Translate>
                          }
                          name="inputStreamPosition"
                          value={{ value: inputStreamPosition }}
                          options={[
                            { value: "0" },
                            { value: "1" },
                            { value: "2" },
                            { value: "3" },
                            { value: "4" },
                            { value: "5" },
                            { value: "6" },
                            { value: "7" },
                            { value: "8" },
                            { value: "9" },
                          ]}
                          onChange={this.onChangeInputStreamPosition}
                          labelKey="value"
                        />
                      </Conditional>
                    </GridItem>
                  </GridContainer>
                </Conditional>
              </GridItem>
              <GridItem xs={12} sm={6} md={7} style={{ marginBottom: 100 }}>
                <InputSlider
                  label={
                    <Translate id="Informative@queueOpacityLabel">
                      Opacidade
                    </Translate>
                  }
                  name="alpha"
                  value={getOpacityFromAlpha(alpha)}
                  min={40}
                  renderTooltip={(value: number) => `${value}%`}
                  onSlide={this.onChangeAlpha}
                />
                <QueueControlScreenPreview
                  value={value}
                  layout={layout}
                  alpha={alpha}
                  backgroundColor={backgroundColor}
                  fontColor={fontColor}
                  isVertical={isVertical}
                />
                <InputSelect
                  withPortal
                  label={
                    <Translate id="Informative@queueLayoutLabel">
                      Modo de exibição
                    </Translate>
                  }
                  name="layout"
                  value={{ label: this.labelForLayout(layout), value: layout }}
                  options={this.getValidLayoutOptions()}
                  onChange={this.onChangeLayout}
                />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <InputColorPicker
                      label={<BackgroundColorString />}
                      name="backgroundColor"
                      options={backgroundColorOptions}
                      color={backgroundColor}
                      onChange={this.onChangeInputColorPicker}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputColorPicker
                      label={<FontColorString />}
                      name="fontColor"
                      options={fontColorOptions}
                      color={fontColor}
                      onChange={this.onChangeInputColorPicker}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          ),
          actions: (
            <GridContainer justify="space-between">
              {isEditing && (
                <GridItem xs={12} sm={4}>
                  <Button
                    color="danger"
                    block={true}
                    onClick={this.didWantToDelete}
                  >
                    <DeleteString /> <DeleteIcon />
                  </Button>
                </GridItem>
              )}
              <GridItem xs sm>
                <Button
                  color="primary"
                  block={true}
                  isLoading={isWaitingForm}
                  onClick={this.onSubmit}
                >
                  {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
                </Button>
              </GridItem>
            </GridContainer>
          ),
        })}
      </Fragment>
    );
  }
}

export default withLocalized(QueueControlForm);
