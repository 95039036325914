// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";

import { GridContainer } from "../Grid";
import { Card, CardHeader, CardIcon, CardBody } from "../Card";
import UsageGridWidget from "./UsageGridWidget";

// Translate
import Translate from "../I18n/Translate";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";

@inject("contractStore")
@observer
class ContractUsagesLimitWidget extends Component {
  hasAccessTo = (resourceName) => {
    const { resources, rules } = this.props.contractStore.selectedContract;

    return resources[resourceName] && rules[resourceName];
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.chartCard}>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <i className="fas fa-file-alt" style={{ fontSize: 20 }} />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            <Translate id="Contract@usagesLimitWidgetTitle">
              Limites do contrato
            </Translate>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer style={{ paddingTop: 20, height: "100%" }}>
            <UsageGridWidget type="licences" showIcon />
            {this.hasAccessTo("informative") && (
              <UsageGridWidget type="storage" showIcon />
            )}
            {this.hasAccessTo("connectivity") && (
              <UsageGridWidget type="wifiAuthentication" showIcon />
            )}
            {this.hasAccessTo("advertising") && (
              <UsageGridWidget type="sms" showIcon />
            )}
            {this.hasAccessTo("camera") && (
              <UsageGridWidget type="cameraFaces" showIcon />
            )}
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}
export default withStyles(chartsStyle)(ContractUsagesLimitWidget);
