export const dynamicSortByProperty = (property: string, asc = true) => {
  const sortOrder = asc ? 1 : -1;
  return (a, b) => {
    if (a.isDefault && !b.isDefault) {
      return -1 * sortOrder;
    }
    if (!a.isDefault && b.isDefault) {
      return 1 * sortOrder;
    }

    if (!a.hasOwnProperty(property) || !b.hasOwnProperty(property)) {
      return 0;
    }

    const varA =
      typeof a[property] === "string" ? a[property].toUpperCase() : a[property];
    const varB =
      typeof b[property] === "string" ? b[property].toUpperCase() : b[property];

    const result = varA < varB ? -1 : varA > varB ? 1 : 0;
    return result * sortOrder;
  };
};