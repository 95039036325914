// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { Card, CardContent } from "@material-ui/core";

// Components
import { InputText, SubmitButton, ButtonGroup } from "../Form";
import NumberEditingWithLabelInput from "../Form/NumberEditingWithLabelInput";
import MaterialSelectInput from "../Form/MaterialSelectInput";
import Validate, { ValidationType } from "../Form/Validate";
import { withLocalized } from "../HOC";

import { GridContainer, GridItem } from "../Grid";

// Translate
import { SecondsString, MinutesString } from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Utils
import { getRssOptionsForLanguage } from "../../utils/RssContentUtils";
import { IMAGE_PATH } from "../../utils/EnvUtils";

// Icons
import { SaveIcon } from "../Icon";

// Styles
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  card: {
    maxWidth: 120,
    margin: "0 auto",
    border: "4px solid transparent",
  },
  selectedCard: {
    borderColor: primaryColor,
  },
  cardContent: {
    background: "#E2E2E2",
    textAlign: "center",
    padding: "0!important",
  },
  cardTitle: {
    textAlign: "center",
    fontWeight: 500,
    margin: 0,
    padding: "4px 0",
    background: "#fff",
  },
  cardImage: { maxWidth: "100%" },
};

@withLocalized
@inject("i18nStore", "settingsStore", "mediaLibraryStore", "notificationsStore")
@observer
class AddMediaContentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "",
      url: "",
      contentType: "screenshot",
      updateTime: 30,
      isValid: {
        name: false,
        url: false,
      },
      isWaitingForm: false,
      formWasSubmit: false,
      ...this.getDefaultRssOptionsForLanguage(
        this.props.i18nStore.currentLanguage
      ),
    };
  }

  didChangeMediaDuration = (value) => {
    this.props.settingsStore.actionChangeMediaDurationToUpload(value);
  };

  didChangeUpdateTime = (updateTime) => {
    this.setState({
      updateTime,
    });
  };

  getRssOptionsList = (language) => {
    return [
      ...getRssOptionsForLanguage(language),
      {
        value: "custom",
        label: this.props.localizedString({
          id: "Content@rssCustomSelectOption",
          defaultString: "Outro - digitar o link abaixo",
        }),
      },
    ];
  };

  getDefaultRssOptionsForLanguage = (language) => {
    const rssOptionsList = this.getRssOptionsList(language);
    return {
      rssOptionsLanguageFilter: language,
      rssOptions: rssOptionsList[0].value,
      rssOptionsList,
    };
  };

  onChangeRssOptionsLanguageFilter = (name, selectedLanguage) => {
    this.setState(this.getDefaultRssOptionsForLanguage(selectedLanguage));
  };

  onChangeUrlValue = ({ target }) => {
    const url = target.value;
    const isValid =
      this.state.contentType !== "" ? Validate(url, ValidationType.URL) : true;
    this.setState({
      url,
      isValid: {
        ...this.state.isValid,
        url: isValid,
      },
    });
  };

  onChangeNameValue = ({ target }) => {
    this.setState({
      name: target.value,
      isValid: {
        ...this.state.isValid,
        name: Validate(target.value, ValidationType.REQUIRED),
      },
    });
  };

  onChangeDescriptionValue = ({ target }) => {
    this.setState({
      description: target.value,
    });
  };

  onChangeContentType = (type) => {
    this.setState({
      contentType: type,
      name: "",
      description: "",
      url: "",
      ...this.getDefaultRssOptionsForLanguage(
        this.props.i18nStore.currentLanguage
      ),
    });
  };

  onChangeSelectInput = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  };

  canSubmitForm = () => {
    if (
      this.state.contentType === "rss" &&
      this.state.rssOptions !== "custom"
    ) {
      return true;
    }
    return this.state.isValid.url;
  };

  getFormDataToSubmit = ({
    name,
    description,
    contentType,
    url: urlState,
    rssOptions,
    updateTime,
  }) => {
    let url = urlState;

    if (contentType === "rss" && rssOptions !== "custom") {
      url = rssOptions;
    }

    return {
      name,
      description,
      contentType,
      url,
      updateTime: updateTime > 15 ? updateTime : 15,
      duration: this.props.settingsStore.mediaDurationToUpload,
    };
  };

  submitForm = () => {
    this.props.mediaLibraryStore
      .submitContent(
        this.getFormDataToSubmit(this.state),
        this.props.selectedTags
      )
      .then(() => {
        this.submitHasSuccess();
      })
      .catch(() => {
        this.submitHasError();
      });
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (this.canSubmitForm()) {
      this.setState(
        {
          isWaitingForm: true,
          formWasSubmit: true,
        },
        () => {
          this.submitForm();
        }
      );
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: (
              <Translate id="Content@urlErrorMessage">
                Informe uma URL completa, incluindo o protocolo. Exemplo:
                https://www.youtube.com/watch?v=lHB_G_zWTbc
              </Translate>
            ),
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = () => {
    this.props.didSave();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  render() {
    const { classes, localizedString } = this.props;
    const {
      name,
      contentType,
      url,
      rssOptionsLanguageFilter,
      rssOptions,
      rssOptionsList,
      updateTime,
      isWaitingForm,
      formWasSubmit,
      isValid,
    } = this.state;

    const { mediaDurationToUpload } = this.props.settingsStore;

    return (
      <GridContainer style={{ width: "100%", margin: 0, marginTop: 30 }}>
        <GridItem xs={12}>
          <form onSubmit={this.onSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={4}>
                    <Card
                      className={classnames({
                        [classes.card]: true,
                        [classes.selectedCard]: contentType === "screenshot",
                      })}
                      onClick={() => this.onChangeContentType("screenshot")}
                    >
                      <CardContent className={classes.cardContent}>
                        <p className={classes.cardTitle}>Screenshot</p>
                        <img
                          src={`${IMAGE_PATH}/contents/${
                            contentType === "screenshot"
                              ? "screenshot"
                              : "screenshot-alt"
                          }.jpg`}
                          className={classes.cardImage}
                          alt={
                            <Translate id="Content@screenshotImageAlt">
                              Conteúdo do tipo Screenshot
                            </Translate>
                          }
                        />
                      </CardContent>
                    </Card>
                  </GridItem>
                  <GridItem xs={4}>
                    <Card
                      className={classnames({
                        [classes.card]: true,
                        [classes.selectedCard]: contentType === "rss",
                      })}
                      onClick={() => this.onChangeContentType("rss")}
                    >
                      <CardContent className={classes.cardContent}>
                        <p className={classes.cardTitle}>RSS</p>
                        <img
                          src={`${IMAGE_PATH}/contents/${
                            contentType === "rss" ? "rss" : "rss-alt"
                          }.jpg`}
                          className={classes.cardImage}
                          alt={
                            <Translate id="Content@rssImageAlt">
                              Conteúdo do tipo RSS
                            </Translate>
                          }
                        />
                      </CardContent>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            {contentType === "rss" && (
              <Fragment>
                <ButtonGroup
                  name="rssOptionsLanguageFilter"
                  value={rssOptionsLanguageFilter}
                  color="primary"
                  small
                  onChange={this.onChangeRssOptionsLanguageFilter}
                  options={[
                    {
                      value: "en",
                      label: localizedString({
                        id: "I18n@english",
                        defaultString: "Inglês",
                      }),
                    },
                    {
                      value: "pt",
                      label: localizedString({
                        id: "I18n@portuguese",
                        defaultString: "Português",
                      }),
                    },
                    {
                      value: "all",
                      label: localizedString({
                        id: "Common@all",
                        defaultString: "Todos",
                      }),
                    },
                  ]}
                />
                <MaterialSelectInput
                  label={
                    <Translate id="Content@rssSelectLabel">
                      Escolha um RSS
                    </Translate>
                  }
                  name="rssOptions"
                  value={rssOptions}
                  options={rssOptionsList}
                  onChange={this.onChangeSelectInput}
                />
                {rssOptions === "custom" && (
                  <InputText
                    label={
                      <Translate id="Content@rssUrlLabel">
                        Link do RSS
                      </Translate>
                    }
                    placeholder={localizedString({
                      id: "Content@rssUrlHint",
                      defaultString:
                        "ex: https://www.nasa.gov/rss/dyn/breaking_news.rss",
                    })}
                    name="url"
                    hasError={formWasSubmit && !isValid.url}
                    hasSuccess={formWasSubmit && isValid.url}
                    value={url}
                    inputProps={{ autoComplete: "off" }}
                    onChange={this.onChangeUrlValue}
                  />
                )}
              </Fragment>
            )}

            {contentType === "screenshot" && (
              <GridContainer>
                <GridItem xs={12}>
                  <InputText
                    label={
                      <Translate id="Content@screenshotUrlLabel">
                        Link do site
                      </Translate>
                    }
                    placeholder={localizedString({
                      id: "Content@screenshotUrlHint",
                      defaultString: "ex: https://www.nasa.gov/index.html",
                    })}
                    name="url"
                    hasError={formWasSubmit && !isValid.url}
                    hasSuccess={formWasSubmit && isValid.url}
                    value={url}
                    inputProps={{ autoComplete: "off" }}
                    onChange={this.onChangeUrlValue}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <NumberEditingWithLabelInput
                    label={
                      <Translate id="Content@mediaDurationLabel">
                        Sites capturados terão a seguinte duração na
                        programação:
                      </Translate>
                    }
                    typeLabel={<SecondsString />}
                    onChange={this.didChangeMediaDuration}
                    value={mediaDurationToUpload}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <NumberEditingWithLabelInput
                    label={
                      <Translate id="Content@updateTimeLabel">
                        Capturar um novo screenshot a cada:
                      </Translate>
                    }
                    typeLabel={<MinutesString />}
                    onChange={this.didChangeUpdateTime}
                    value={updateTime}
                  />
                </GridItem>
              </GridContainer>
            )}

            <InputText
              label={
                <Translate id="Content@titleLabel">
                  Nome do conteúdo (opcional)
                </Translate>
              }
              name="name"
              hasSuccess={formWasSubmit}
              value={name}
              onChange={this.onChangeNameValue}
              maxLength={30}
            />

            <GridContainer justify="space-between">
              <GridItem xs>
                <SubmitButton
                  onClick={this.onSubmit}
                  block
                  large={false}
                  isLoading={isWaitingForm}
                >
                  <Translate id="Content@submitButtonlabel">
                    Incluir conteúdo Externo
                  </Translate>{" "}
                  <SaveIcon />
                </SubmitButton>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(AddMediaContentForm);
