// @ts-nocheck
import { observable, action } from "mobx";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

class AlfredStore {
  @observable isOpen = false;
  @observable actions = [];

  constructor(internalEventsStore) {
    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  filterActionsById = (id) => this.actions.filter(($0) => $0.id !== id);

  @action clearStore = () => {
    this.isOpen = false;
    this.actions = [];
  };

  @action toggle = () => (this.isOpen = !this.isOpen);

  @action open = () => (this.isOpen = true);

  @action close = () => (this.isOpen = false);

  @action addActions = (actions) => {
    this.actions = [actions, ...this.filterActionsById(actions.id)];
  };

  @action removeActions = (id) => {
    this.actions = this.filterActionsById(id);
  };

  didSelectAction = (action) => {
    action.callback();
    this.close();
  };
}

export default AlfredStore;
