import { Component } from "react";
import Tooltip from "../Tooltip/Tooltip";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Button } from "../Button";

import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles";

// Style
const defaultButtonStyle = {
  padding: "6px 8px",
  opacity: 1,
  margin: 0,
  width: "fit-content",

  "&:hover": {
    zIndex: "2",
  },
};
const styles = ({ breakpoints }: Theme) =>
  createStyles({
    label: {
      display: "block",
      color: "#AAAAAA !important",
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: 12,
      lineHeight: "1.42857",
    },
    whiteBackgroundColor: {
      backgroundColor: "#fff!important",
    },
    buttonGroup: {
      position: "relative",
      margin: "10px 1px",
      verticalAlign: "middle",
    },
    firstButton: {
      ...defaultButtonStyle,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRightWidth: "0!important",
      position: "relative",

      [breakpoints.down("md")]: {
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 0,
        borderRightWidth: "1px!important",
        borderBottomWidth: "1px!important",
        width: "100%",
      },
    },
    middleButton: {
      ...defaultButtonStyle,
      borderRadius: 0,
      borderRightWidth: "0!important",
      position: "relative",
      [breakpoints.down("md")]: {
        borderRightWidth: "1px!important",
        borderTopWidth: "0!important",
        borderBottomWidth: "1px!important",
        width: "100%",
      },
    },
    lastButton: {
      ...defaultButtonStyle,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      [breakpoints.down("md")]: {
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 8,
        borderTopWidth: "0!important",
        width: "100%",
      },
    },
    disabledContainer: {
      filter: "grayscale(100%)",
    },
  });

interface IButtonGroupProps {
  classes: any;
  name: string;
  label?: string | JSX.Element;
  value: any;
  color?: string;
  disabled?: boolean;
  small?: boolean;
  large?: boolean;
  whiteBackgroundColor?: boolean;
  options: any[];
  onChange: (name: string, value: any) => void;
}

class ButtonGroup extends Component<IButtonGroupProps> {
  onChangeValue = (selectedValue: any) => {
    const { name, onChange } = this.props;

    onChange(name, selectedValue);
  };

  renderTooltip = (title: string, children: any) => {
    // @ts-ignore
    return <Tooltip title={title}>{children}</Tooltip>;
  };

  renderOption = (
    index: number,
    { label, value, description, isDisabled },
    lastIndex: number
  ) => {
    const {
      classes,
      value: groupSelectedValue,
      color,
      disabled,
      small,
      large,
      whiteBackgroundColor,
    } = this.props;
    const isActive = value === groupSelectedValue;
    return (
      // @ts-ignore
      <Button
        key={value}
        color={color}
        outline={!isActive}
        disabled={isDisabled || disabled}
        small={small}
        large={large}
        className={classnames({
          [classes.firstButton]: index === 0,
          [classes.middleButton]: index > 0 && index < lastIndex,
          [classes.lastButton]: index === lastIndex,
          [classes.whiteBackgroundColor]: !isActive && whiteBackgroundColor,
          [classes.disabledContainer]: isDisabled || disabled,
        })}
        onClick={() => this.onChangeValue(value)}
      >
        {label}{" "}
        {description &&
          this.renderTooltip(
            description,
            <i className="fa fa-question-circle" />
          )}
      </Button>
    );
  };

  render() {
    const { classes, label, options } = this.props;
    return (
      <div className={classes.buttonGroup}>
        {label && <label className={classes.label}>{label}</label>}
        <div className="btn-group" role="group">
          {options.map((option, index) =>
            this.renderOption(index, option, options.length - 1)
          )}
        </div>
      </div>
    );
  }

  static defaultProps = {
    color: "primary",
  };

  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
    ]).isRequired,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    large: PropTypes.bool,
    whiteBackgroundColor: PropTypes.bool,
  };
}

// @ts-ignore
export default withStyles(styles)(ButtonGroup);
