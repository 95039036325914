//@ts-nocheck
import { GridItem } from "../../Grid";
import TagsContainer from "../../Tags/TagsContainer";
import WizardUploadImage from "../Image";
import { WizardUploadFor2Box } from "../style";
import { WizardUploadTags } from "../type";

const WizardUploadTag = ({
  tagsList,
  selectedTags,
  onChangeSelectedTags,
  payload,
}: WizardUploadTags) => {
  return (
    <WizardUploadFor2Box>
      <div className="title">
        <h3>Utilize etiquetas para organizar seu conteúdo na biblioteca.</h3>
      </div>
      <div className="content">
        <div className="left">
          <p>
            Dica: As etiquetas são uma ótima maneira de organizar seu conteúdo
            na biblioteca. Certifique-se de usar etiquetas descritivas e
            relevantes para que você possa encontrar seu conteúdo facilmente
            mais tarde. Por exemplo, você pode usar etiquetas para categorizar o
            conteúdo por tipo, tema, localização ou qualquer outra coisa que
            faça sentindo para você. Quanto mais você usar as etiquetas, mais
            fácil será encontrar o conteúdo que você precisa quando precisar
            dele.
          </p>
          <GridItem xs={12}>
            <TagsContainer
              tagsList={tagsList}
              selectedTags={selectedTags}
              onChangeSelectedTags={onChangeSelectedTags}
            />
          </GridItem>
        </div>
        <div className="right">
          <WizardUploadImage payload={payload} />
        </div>
      </div>
    </WizardUploadFor2Box>
  );
};

export default WizardUploadTag;
