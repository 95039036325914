// @ts-nocheck

import classnames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";
import GenderAndAgeGroupChart from "../../components/Charts/GenderAndAgeGroupChart";
import { GridContainer, GridItem } from "../../components/Grid";
import Tooltip from "../../components/Tooltip/Tooltip";

import Translate from "../../components/I18n/Translate";

// Style
import {
  successColor,
  warningColor,
  dangerColor,
} from "../../assets/jss/app-js-styles";
const styles = {
  container: {
    position: "relative",
  },
  disabledMessageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 10,
  },
  message: {
    background: warningColor,
    padding: 16,
    borderRadius: 8,
    fontSize: "1.6em",
    color: "#fff",
  },
  disabledContainer: {
    filter: "blur(4px) grayscale(1) opacity(0.4)",
    pointerEvents: "none",
    zIndex: 0,
  },
  emotionContainer: {
    padding: 20,
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emotionIcon: {
    fontSize: 60,
    opacity: 0.9,
  },
  emotionPercent: {
    fontSize: "2em",
    margin: 0,
    marginTop: 8,
  },
};

const CameraVisitorsChartWidget = ({
  classes,
  localizedString,
  chartData,
  isDisabled,
}) => {
  if (!chartData) return null;

  const { emotion, ...rest } = chartData;
  const neutral = emotion.neutral || 0;
  const happy = emotion.happy || 0;
  const stressed = emotion.stressed || 0;
  const emotionTotal = neutral + happy + stressed;

  const renderEmotion = ({ title, icon, percent, color }) => (
    <div className={classes.emotionContainer}>
      <Tooltip title={title}>
        <i
          className={classnames({
            [`fas fa-${icon}`]: true,
            [classes.emotionIcon]: true,
          })}
          style={{ color: color }}
        />
      </Tooltip>
      <p className={classes.emotionPercent}>{percent}%</p>
    </div>
  );

  const calculateEmotionPercent = (emotion) => {
    return Math.round((emotion * 100) / emotionTotal);
  };

  return (
    <div className={classes.container}>
      {isDisabled && (
        <div className={classes.disabledMessageContainer}>
          <p className={classes.message}>
            <Translate id="Metrics@insufficientData">
              Dados insuficientes para exibir estas informações no momento
            </Translate>
          </p>
        </div>
      )}

      <div
        className={classnames({
          [classes.disabledContainer]: isDisabled,
        })}
      >
        <GenderAndAgeGroupChart chartData={rest} />
        {emotionTotal > 0 && (
          <GridContainer justify="center" style={{ marginTop: 50 }}>
            <GridItem xs={12} md={6} style={{ textAlign: "center" }}>
              <p style={{ margin: 0, textAlign: "center" }}>
                <Translate id="Metrics@emotionChartTitle">
                  Análise do humor
                </Translate>
              </p>
              {renderEmotion({
                title: localizedString({
                  id: "Camera@emotionStressedTitle",
                  defaultString: "Extressado",
                }),
                icon: "frown",
                percent: calculateEmotionPercent(stressed),
                color: dangerColor,
              })}
              {renderEmotion({
                title: localizedString({
                  id: "Camera@emotionNeutralTitle",
                  defaultString: "Neutro",
                }),
                icon: "meh",
                percent: calculateEmotionPercent(neutral),
                color: "#f7ca18",
              })}
              {renderEmotion({
                title: localizedString({
                  id: "Camera@emotionHappyTitle",
                  defaultString: "Feliz",
                }),
                icon: "laugh-beam",
                percent: calculateEmotionPercent(happy),
                color: successColor,
              })}
            </GridItem>
          </GridContainer>
        )}
      </div>
    </div>
  );
};

export default withLocalized(withStyles(styles)(CameraVisitorsChartWidget));
