// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";

// Components
import { ButtonGroup, InputText, SubmitButton } from "../Form";

import { GridContainer, GridItem } from "../Grid";
import Validate, { ValidationType } from "../Form/Validate";
import { withLocalized } from "../HOC";

// Translate
import { FormErrorString, SetupString } from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Icons
import { SaveIcon } from "../Icon";

@withLocalized
@inject("notificationsStore")
class TerminalConfigureNetworkForm extends Component {
  state = {
    connectionType: "eth",
    wifiName: "",
    wifiPassword: "",
    addressType: "dhcp",
    ipAddress: "",
    subnet: "",
    router: "",
    dns: "",
    isWaitingForm: false,
    formWasSubmit: false,
    isValid: {
      wifiName: false,
      ipAddress: false,
      subnet: false,
      router: false,
      dns: false,
    },
  };

  onChangeConnectionType = (name, selectedValue) => {
    this.setState({
      connectionType: selectedValue,
      wifiName: "",
      wifiPassword: "",
    });
  };
  onChangeAddressType = (name, selectedValue) => {
    this.setState({
      addressType: selectedValue,
      ipAddress: "",
      subnet: "",
      router: "",
      dns: "",
    });
  };

  onChangeInputText = ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      [key]: value,
      isValid: {
        ...this.state.isValid,
        [key]: Validate(value, ValidationType.REQUIRED),
      },
    });
  };

  onChangeIpAddressInput = ({ target }) => {
    const { name: key, value } = target;

    this.setState({
      [key]: value,
      isValid: {
        ...this.state.isValid,
        [key]: Validate(value, ValidationType.IP_ADDRESS),
      },
    });
  };

  canSubmitForm = () => {
    const { connectionType, addressType, isValid } = this.state;

    if (connectionType === "eth" && addressType === "dhcp") {
      return true;
    } else {
      let returnIsValid = true;
      if (connectionType !== "eth" && !isValid.wifiName) {
        returnIsValid = false;
      }
      if (
        addressType !== "dhcp" &&
        (!isValid.ipAddress ||
          !isValid.subnet ||
          !isValid.router ||
          !isValid.dns)
      ) {
        returnIsValid = false;
      }

      return returnIsValid;
    }
  };

  _downloadNetworkFile = (configInfo) => {
    let element = document.createElement("a");
    let file = new Blob([`${configInfo};`], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "network.txt";
    element.click();

    this.props.didCancel();
  };

  submitForm = () => {
    const {
      connectionType,
      wifiName,
      wifiPassword,
      addressType,
      ipAddress,
      subnet,
      router,
      dns,
    } = this.state;

    const configInfo = `${connectionType};${addressType};${ipAddress};${subnet};${router};${wifiName};${wifiPassword};${dns}`;

    if (this.props.isOnline) {
      this.props.didSave({
        command: `network;${configInfo}`,
      });
    } else {
      this._downloadNetworkFile(configInfo);
    }
  };

  onSubmit = (event) => {
    if (event) event.preventDefault();

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: <FormErrorString />,
            color: "danger",
          });
        }
      );
    }
  };

  render() {
    const { localizedString } = this.props;
    const {
      connectionType,
      wifiName,
      wifiPassword,
      addressType,
      ipAddress,
      subnet,
      router,
      dns,
      formWasSubmit,
      isValid,
      isWaitingForm,
    } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        {!this.props.isOnline && (
          <p>
            <Translate id="Device@configureNetworkOfflineMessage">
              Preencha as informações de rede, baixe o arquivo de configuração
              network.txt e copie-o para a raiz do cartão de memória do seu
              dispositivos (partição BOOT).
            </Translate>
          </p>
        )}
        <ButtonGroup
          small
          color="success"
          label={
            <Translate id="Device@connectionTypeLabel">
              Conexão com a internet
            </Translate>
          }
          name="connectionType"
          value={connectionType}
          onChange={this.onChangeConnectionType}
          options={[
            {
              value: "eth",
              label: localizedString({
                id: "Device@connectionTypeOptionEth0",
                defaultString: "Cabo de rede",
              }),
            },
            {
              value: "wlan",
              label: localizedString({
                id: "Device@connectionTypeOptionWlan",
                defaultString: "Wi-Fi",
              }),
            },
          ]}
        />
        {connectionType === "wlan" && (
          <Fragment>
            <InputText
              label={
                <Translate id="Device@wifiNameLabel">Nome da Wi-Fi</Translate>
              }
              name="wifiName"
              value={wifiName}
              hasError={formWasSubmit && !isValid.wifiName}
              hasSuccess={formWasSubmit && isValid.wifiName}
              onChange={this.onChangeInputText}
            />
            <InputText
              label={
                <Translate id="Device@wifiPasswordLabel">
                  Senha da Wi-Fi
                </Translate>
              }
              name="wifiPassword"
              icon="key"
              inputProps={{
                autoComplete: "off",
              }}
              value={wifiPassword}
              onChange={this.onChangeInputText}
            />
          </Fragment>
        )}
        <ButtonGroup
          small
          color="success"
          label={
            <Translate id="Device@addressTypeLabel">
              Informações de rede
            </Translate>
          }
          name="addressType"
          value={addressType}
          onChange={this.onChangeAddressType}
          options={[
            {
              value: "dhcp",
              label: localizedString({
                id: "Device@addressTypeDhcp",
                defaultString: "DHCP (automático)",
              }),
            },
            {
              value: "manual",
              label: localizedString({
                id: "Device@addressTypeManual",
                defaultString: "Manual",
              }),
            },
          ]}
        />

        {addressType === "manual" && (
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <InputText
                label={
                  <Translate id="Device@ipAddressLabel">Endereço IP</Translate>
                }
                name="ipAddress"
                value={ipAddress}
                onChange={this.onChangeIpAddressInput}
                hasError={formWasSubmit && !isValid.ipAddress}
                hasSuccess={formWasSubmit && isValid.ipAddress}
                helpText={
                  formWasSubmit && !isValid.ipAddress ? (
                    <Translate id="Device@ipAddressErrorMessage">
                      Informe um endereço IP válido contendo números e pontos.
                      Exemplo: 192.168.0.2
                    </Translate>
                  ) : null
                }
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputText
                label={<Translate id="Device@subnetLabel">Subnet</Translate>}
                name="subnet"
                value={subnet}
                onChange={this.onChangeIpAddressInput}
                hasError={formWasSubmit && !isValid.subnet}
                hasSuccess={formWasSubmit && isValid.subnet}
                helpText={
                  formWasSubmit && !isValid.subnet ? (
                    <Translate id="Device@subnetErrorMessage">
                      Informe um endereço de máscara válido contendo números e
                      pontos. Exemplo: 255.255.255.0
                    </Translate>
                  ) : null
                }
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputText
                label={<Translate id="Device@routerLabel">Roteador</Translate>}
                name="router"
                value={router}
                onChange={this.onChangeIpAddressInput}
                hasError={formWasSubmit && !isValid.router}
                hasSuccess={formWasSubmit && isValid.router}
                helpText={
                  formWasSubmit && !isValid.router ? (
                    <Translate id="Device@routerErrorMessage">
                      Informe um endereço do roteador válido contendo números e
                      pontos. Exemplo: 192.168.0.1
                    </Translate>
                  ) : null
                }
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputText
                label={<Translate id="Device@dnsLabel">DNS</Translate>}
                name="dns"
                value={dns}
                onChange={this.onChangeIpAddressInput}
                hasError={formWasSubmit && !isValid.dns}
                hasSuccess={formWasSubmit && isValid.dns}
                helpText={
                  formWasSubmit && !isValid.dns ? (
                    <Translate id="Device@dnsErrorMessage">
                      Informe um endereço DNS válido contendo números e pontos.
                      Exemplo: 8.8.8.8
                    </Translate>
                  ) : null
                }
              />
            </GridItem>
          </GridContainer>
        )}

        <GridContainer justify="space-between">
          <GridItem xs>
            <SubmitButton
              onClick={this.onSubmit}
              block
              large={false}
              isLoading={isWaitingForm}
            >
              {this.props.isOnline ? (
                <Fragment>
                  <SetupString /> <SaveIcon />
                </Fragment>
              ) : (
                <Fragment>
                  <Translate id="Device@configureNetworkOfflineSubmitButton">
                    Baixar arquivo de configuração
                  </Translate>{" "}
                  <i className="fas fa-file-download" />
                </Fragment>
              )}
            </SubmitButton>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default TerminalConfigureNetworkForm;
