// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import { Component, Fragment } from "react";
import ConfirmModal from "./ConfirmModal";
import Modal from "./Modal";

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Button } from "../Button";
import LoadingView from "../LoadingView/LoadingView";

import { GridContainer, GridItem } from "../Grid";
import Maps from "../Maps/Maps";

// Translate
import Plural from "../I18n/Plural";
import Translate from "../I18n/Translate";

import LocationModel from "../../Model/LocationModel";
import TerminalModel from "../../Model/TerminalModel";
import AuthStore from "../../store/AuthStore/AuthStore";
import GroupsStore from "../../store/GroupsStore/GroupsStore";
import NotificationsStore from "../../store/NotificationsStore/NotificationsStore";
import RoutingStore from "../../store/RoutingStore/RoutingStore";
import TerminalsStore from "../../store/TerminalsStore/TerminalsStore";

// Utils
import { IMAGE_PATH } from "../../utils/EnvUtils";

// Style
import { grayColor } from "../../assets/jss/app-js-styles";
import LocationGroupTabs from "../../pages/locationsPage/LocationGroupTabs";
const styles = {
  media: {
    backgroundColor: "#000",
    backgroundSize: "contain",
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginBottom: 20,
    filter: "grayscale(100%)",
  },
  listItemIcon: {
    width: 26,
    marginRight: 0,
    textAlign: "center",
  },
};

interface IModalLocationDetailsProps {
  classes?: any;
  isOpen: boolean;
  location: LocationModel;
  didCloseModal: () => void;
  didWantToEdit: () => void;
  didWantToDelete: () => void;
}
interface IModalLocationDetailsState {
  isOpenTerminalModal: boolean;
  selectedTerminal: null | TerminalModel;
  isAddingTerminalsToLocation: boolean;
  isManagingDevices: boolean;
  didRequestCriticalCommand: string | null;
  isFetchingDevice: string | null;
  selectedDevicesToDelete: string[];
}

interface InjectedProps extends IModalLocationDetailsProps {
  authStore: AuthStore;
  groupsStore: GroupsStore;
  terminalsStore: TerminalsStore;
  routingStore: RoutingStore;
  notificationsStore: NotificationsStore;
}

@inject(
  "authStore",
  "groupsStore",
  "terminalsStore",
  "routingStore",
  "notificationsStore"
)
@observer
class ModalLocationDetails extends Component<
  IModalLocationDetailsProps,
  IModalLocationDetailsState
> {
  deleteDevicesHintNotificationToken: string | null;

  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  state = {
    isOpenTerminalModal: false,
    didRequestCriticalCommand: null,
  };

  sendCommandToAllDevices = async (command: string) => {
    this.props.didCloseModal();
    await this.injected.terminalsStore.sendCommandToListOfTerminals(
      this.props.location.token,
      command
    );
    this.injected.groupsStore.getGroupsList();
  };

  requestCriticalCommand = (command: string) => () =>
    this.setState({
      didRequestCriticalCommand: command,
    });

  didCancelConfirmation = () =>
    this.setState({
      didRequestCriticalCommand: null,
    });

  didCloseModal = () => {
    this.props.didCloseModal();
  };

  renderTerminalButton = ({
    token,
    color,
    name,
    category,
    isActive,
  }: TerminalModel) => {

    return (
      <Button
        key={token}
        style={{
          color: !isActive ? grayColor : color,
        }}
      >
        <i
          className={`fas fa-${
            !isActive ? "ban" : category === "standard" ? "hdd" : "server"
          }`}
          style={{ marginRight: 16 }}
        />{" "}
        {name}
      </Button>
    );
  };

  getTerminals = (groups) => {
    let totalTerminals = [];

    groups?.forEach(({ terminalsList }) => {
      terminalsList.forEach((terminal) => totalTerminals.push(terminal));
    });
    return totalTerminals;
  };

  renderIcon = (iconName: string) => <i className={`fas fa-${iconName}`} />;

  renderListItem = ({
    button,
    divider,
    disabled,
    onClick,
    icon,
    primary,
    secondary,
    primaryTypographyProps,
  }) => (
    <ListItem
      button={button}
      divider={divider}
      disabled={disabled}
      dense
      onClick={onClick}
    >
      <ListItemIcon classes={{ root: this.props.classes.listItemIcon }}>
        {this.renderIcon(icon)}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={primaryTypographyProps}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );

  render() {
    const {
      classes,
      isOpen,
      location,
      didWantToEdit,
      didWantToDelete,
    } = this.props;
    const { didRequestCriticalCommand } = this.state;

    const { terminalsList, groups } = location;

    const devicesQuantity = terminalsList.length;
    const isAdminView = this.injected.authStore.currentUserIsAdmin;

    return (
      <Fragment>
        {didRequestCriticalCommand && (
          <ConfirmModal
            isOpen
            title={
              <Translate id="Common@confirmAction">
                Confirmar esta ação
              </Translate>
            }
            didCancel={this.didCancelConfirmation}
            didConfirm={() =>
              this.sendCommandToAllDevices(didRequestCriticalCommand)
            }
          >
            <Translate id="Device@confirmSendCommandMessage">
              Atenção! Tem certeza que deseja seguir com esse comando?
            </Translate>
          </ConfirmModal>
        )}
        <Modal
          isOpen={isOpen}
          didCloseModal={this.didCloseModal}
          modalTitle={
            location && (
              <Plural
                id="Location@titleForModal"
                value={devicesQuantity}
                variables={{ locationName: location.name }}
                zero="$locationName"
                one="$locationName - # dispositivo"
                other="$locationName - # dispositivos rude"
              />
            )
          }
        >
          {location ? (
            <GridContainer>
              <GridItem xs={12} sm={6} style={{ marginBottom: 10 }}>
                {parseFloat(location.latitude) &&
                parseFloat(location.longitude) ? (
                  <Maps
                    latitude={location.latitude}
                    longitude={location.longitude}
                    zoom={14}
                    height={300}
                    markers={[
                      {
                        latitude: location.latitude,
                        longitude: location.longitude,
                      },
                    ]}
                  />
                ) : (
                  <div
                    className={classes.media}
                    style={{
                      backgroundImage: `url("${IMAGE_PATH}/default-locations-map.jpg")`,
                    }}
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={6}>
                <List>
                  <Fragment>
                    {this.renderListItem({
                      button: true,
                      divider: true,
                      onClick: didWantToEdit,
                      icon: "edit",
                      primary: (
                        <Translate id="Location@editLabelButton">
                          Editar localidade
                        </Translate>
                      ),
                    })}
                    {!this.props.location.isDefault &&
                      this.renderListItem({
                        button: true,
                        divider: true,
                        onClick: didWantToDelete,
                        icon: "trash-alt",
                        primary: (
                          <Translate id="Location@deleteLabelButton">
                            Apagar localidade
                          </Translate>
                        ),
                      })}
                    {this.renderListItem({
                      button: true,
                      divider: isAdminView,
                      onClick: () =>
                        window.open(
                          `https://maps.google.com/?daddr=${location.latitude},${location.longitude}`,
                          "_map"
                        ),
                      icon: "map-marker-alt",
                      primary: (
                        <Translate id="Location@openGoogleMapsButton">
                          Ir até o local
                        </Translate>
                      ),
                    })}
                    {isAdminView &&
                      this.renderListItem({
                        button: true,
                        divider: isAdminView && devicesQuantity > 0,
                        disabled: terminalsList.every(
                          ({ isActive }: TerminalModel) => isActive
                        ),
                        onClick: this.requestCriticalCommand("pingoflife"),
                        icon: "lock-open",
                        primary: (
                          <Translate id="Location@enableAllDevicesButtonLabel">
                            Habilitar todos os dispositivos
                          </Translate>
                        ),
                      })}
                    {isAdminView &&
                      devicesQuantity > 0 &&
                      this.renderListItem({
                        button: true,
                        onClick: this.requestCriticalCommand("pingofdeath"),
                        disabled: terminalsList.every(
                          ({ isActive }: TerminalModel) => !isActive
                        ),
                        icon: "lock",
                        primary: (
                          <Translate id="Location@disableAllDevicesButtonLabel">
                            Desabilitar todos os dispositivos
                          </Translate>
                        ),
                      })}
                  </Fragment>
                </List>
              </GridItem>
            </GridContainer>
          ) : (
            <LoadingView />
          )}
          {devicesQuantity > 0 && (
            <Fragment>
              <Divider />
              <GridContainer>
                <LocationGroupTabs groups={groups} renderTerminal={this.renderTerminalButton}/>
              </GridContainer>
            </Fragment>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ModalLocationDetails);
