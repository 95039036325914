export const readQueueValue = (value: string): string[] => {
  const splitValue = value.split("||");
  const firstLineValue = splitValue[0];
  const secondLineValue = splitValue.length > 1 ? splitValue[1] : "";
  return [firstLineValue, secondLineValue];
};

export const getOpacityFromAlpha = (alpha: string): number => {
  const alphaInt = parseInt(alpha, 10);
  const result = (alphaInt * 100) / 255;
  return Math.round(result);
};
